import React from 'react'
import { withRouter } from 'react-router-dom'


import { AuthContext } from '../main/authProvider'

import Card from '../components/card'
import EmpresaDialog from '../components/empresas/empresaDialog'
import { Button } from 'primereact/button'
import EmpresasTableSearchComponent from '../components/empresas/empresasTableSearchComponent'


class Empresas extends React.Component{

    constructor(){
        super();
        this.empresasTableSearchComponentRef = React.createRef()
    }    

    state = {

        displayUserDialog: false,

        disableSingUpButton: false,

        displayDialog: false,

        loading: false,

        disableCadastroButton: false,

        empresaToEdit: '',


    }



    handleMuiKeyUp = (event) => {
        if(event.code === "Enter" || event.code === "NumpadEnter"){
            this.callSave()
        }
    }

    showDialog = () => {
        this.setState({displayDialog: true})
    }

    hideDialog = () => {
        this.setState({displayDialog: false})
    }

    onCallCreate = () => {
        this.hideDialog()
        this.setState({loading: true})
        this.setState({disableCadastroButton: true})
    }
    
    onSuccessCreate = () => {
        this.setState({loading: false})
        this.empresasTableSearchComponentRef.current.search()
        this.setState({disableCadastroButton: false})
    }
    
    onErrorCreate = () => {
        this.setState({loading: false})
        this.setState({disableCadastroButton: false})
        this.showDialog()
    }

    edit = empresa => {
        this.setState({empresaToEdit: empresa})
        this.showDialog()
    }

    render(){

        const renderCadastrarButton = () => {
            return (
                <Button
                    label="Cadastrar Empresa"
                    icon="pi pi-plus"
                    onClick = {this.showDialog}
                    disabled = {this.state.disableCadastroButton}
                />
            )
        }

        return(
            <div className="bs-docs-section" >
            <Card 
                title = "Empresas"
                rightComponent={renderCadastrarButton()}
            >

            <div className = "col-md-12">

            <EmpresasTableSearchComponent
                ref={this.empresasTableSearchComponentRef}
                edit={this.edit}
                loading={this.state.loading}
                context={this.context}
            />

            </div>

            </Card>
            <div className="d-flex "/>

            <EmpresaDialog
                visible={this.state.displayDialog}
                hideDialog={this.hideDialog}
                isCadastro={!this.state.empresaToEdit}
                onCallCreate={this.onCallCreate}
                onSuccessCreate={this.onSuccessCreate}
                onErrorCreate={this.onErrorCreate}
                empresaToEdit={this.state.empresaToEdit}
                resetData={() => this.setState({empresaToEdit: ''})}
            />

            </div>
        )
    }

}

Empresas.contextType = AuthContext
export default withRouter( Empresas )