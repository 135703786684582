import React from 'react'
import Card from '../../components/card'
import { withRouter } from 'react-router-dom'

import { AuthContext } from '../../main/authProvider'
import GeneralServices from '../../app/service/generalServices'
import HandleErrorService from '../../app/service/handleErrorService'
import TiposDeRecebimentoService from '../../app/service/tiposDeRecebimentoService'
import { Button } from 'primereact/button'
import FichaDeRecebimentoService from '../../app/service/contasAReceber/fichaDeRecebimentoService'
import DateIntervalCalendar from '../../components/calendar/dateIntervalCalendar'
import FichaDeRecebimentoConsolidadaTable from '../../components/fichaDeRecebimento/fichaDeRecebimentoConsolidadaTable'
import SelectMenu from '../../components/selectMenu'
import ConstantsUtil from '../../context/constantsUtil'

class FichaDeRecebimentoConsolidada extends React.Component {

    constructor(){
        super();
        this.generalServices = new GeneralServices();
        this.tiposDeRecebimentoService = new TiposDeRecebimentoService();
        this.fichaDeRecebimentoService = new FichaDeRecebimentoService();

    }
    
    state = {
        loading: false,

        fichaDeRecebimento: [],

        selectedFilterType: '',
        inputFilterTypeErrorClass: '',

        selectedDateFilterType: '',
        missingFilterType: false,
        missingAnyDate: false,
        initialDate: '',
        initialDateView: '',
        finalDate: '',
        finalDateView: '',

        isSearchingLancamentos: false,
        isFinishedSearch: false,

        habilitaBusca: true,

    }


    componentDidMount(){
        this.setState({selectedFilterType: this.fichaDeRecebimentoService.getFilterTypeList()[2].value})
    }

    resetDateView = () => {
        this.setState({initialDate: ''})
        this.setState({initialDateView: ''})
        this.setState({finalDate: ''})
        this.setState({finalDateView: ''})
    }

    handleNavigatorDateChange = (event, e) => {
        e.onChange(event.originalEvent, event.value)
    }

    handleDateFilterChange = (selectedDateFilterType) => {
        this.setState({selectedDateFilterType})
    }

    handleDateChange = async (initialDate, finalDate) => {
        await this.setState({initialDate})
        
        await this.setState({initialDateView: GeneralServices.convertBrStringToJsDate(initialDate)})

        await this.setState({finalDate})
        await this.setState({finalDateView: GeneralServices.convertBrStringToJsDate(finalDate)})

    }

    resetView = () => {
        this.setState({fichaDeRecebimento: []})
        this.setState({inputFilterTypeErrorClass: ''})
        this.setState({missingFilterType: false})
        this.setState({missingAnyDate: false})

    }

    checkData = () => {

        var check = true

        if(!this.state.selectedFilterType){
            this.setState({inputFilterTypeErrorClass: ConstantsUtil.bootstrapInputErrorClass})
            check = false
        }

        if(!this.state.selectedDateFilterType){
            this.setState({missingFilterType: true})
            check=false
        }
        
        if(!this.state.initialDate || !this.state.finalDate){
            this.setState({missingAnyDate: true})
            check=false
        }

        return check
    }

    toggleButton = () => {
        this.setState({habilitaBusca: !this.state.habilitaBusca})
    }

    callSearch = () => {

        this.resetView()

        if(this.checkData()){
            this.setState({loading: true})
            this.toggleButton()
            this.setState({isSearchingLancamentos: true})
            this.setState({isFinishedSearch: false})
            this.fichaDeRecebimentoService.getFichaConsolidadaByDateInterval(
                {
                    initialLancamentoDate : this.state.initialDate,
                    finalLancamentoDate : this.state.finalDate,
                    dateFilterType : this.state.selectedFilterType,
                }
            )
            .then(response => {
                this.setState({fichaDeRecebimento: response.data})
                // console.log("ficha: ", response.data)

                this.setState({isSearchingLancamentos: false})
                this.setState({isFinishedSearch: true})

            })
            .catch(error => {
                HandleErrorService.handleError(error)
                this.setState({isSearchingLancamentos: false})
                this.setState({isFinishedSearch: true})
            })
            .finally(() => {
                this.setState({loading: false})
            })
        }
    }

    handleMuiChange = async (event, value) => {
        const realValue = value ? value.value : ''
        await this.setState({ selectedTipoDeRecebimentoValue: realValue })
        const obj = this.state.tiposDeRecebimento.find(tipoDeRecebimento => 
            tipoDeRecebimento.nomeTipoDeRecebimento === this.state.selectedTipoDeRecebimentoValue)
        this.setState({ selectedTipoDeRecebimentoObj: obj })
    }

    handleMuiKeyUp = (event) => {
        if(event.code === "Enter" || event.code === "NumpadEnter"){
            this.callSearch()
        }
    }

    handleFilterTypeChange = async (event) => {
        const value = event.target.value
        const name = event.target.name
        await this.setState({ [name]: value })
        // this.resetDateView()
    }

    render() {

        const renderCalendarFilters = () => {
            if(this.state.selectedFilterType === this.fichaDeRecebimentoService.getDataLancamentoValue() ||
            this.state.selectedFilterType === this.fichaDeRecebimentoService.getDataCreditoValue())
            {
                return (
                    <>
                        <DateIntervalCalendar
                            htmlFor="SelectDatePeriod"
                            label="Escolher período"
                            handleFilterChange={this.handleDateFilterChange}
                            handleDateChange={this.handleDateChange}
                            missingFilterType={this.state.missingFilterType}
                            missingAnyDate={this.state.missingAnyDate}
                            disabled={!this.state.habilitaBusca}
                        />
                    </>
                )
            }
        }

        const renderFichaDeRecebimentoConsolidadaTable = () => {
            if( (this.state.isFinishedSearch && !this.state.habilitaBusca) || this.state.loading )
            return (
                <>
                <div className="bs-docs-section" >
                    <FichaDeRecebimentoConsolidadaTable
                        fichaDeRecebimento = {this.state.fichaDeRecebimento}
                        search={this.callSearch}
                        loading={this.state.loading}
                    />
                </div>
                </>
            )
        }

        const renderButtons = () => {
            if(this.state.habilitaBusca){
                return (
                    <Button 
                        label="Buscar Lançamentos"
                        icon="pi pi-search"
                        onClick = {this.callSearch}
                        // style={ {maxHeight: '35px' } }
                        disabled = {this.state.isSearchingLancamentos}
                    />
                )
            }
            return (
                <>
                    <Button 
                        label="Voltar"
                        icon="pi pi-undo"
                        className="p-button-danger"
                        onClick = {this.toggleButton}
                        // style={ {maxHeight: '35px' } }
                        // disabled = {this.state.isSearchingLancamentos}
                    />
                </>
            )
        }

        return(
            <div className="bs-docs-section" >
            <Card title = "Ficha de Recebimento Consolidada">
                <div className = "col-md-12">

                <div className="row">
                <div className = "col-md-4">
                    <label htmlFor="filtro">Filtrar Por</label>
                    <SelectMenu 
                        className={"form-control " + this.state.inputFilterTypeErrorClass}
                        name="selectedFilterType"
                        list= {this.fichaDeRecebimentoService.getFilterTypeList()} 
                        value={this.state.selectedFilterType}
                        onChange={this.handleFilterTypeChange}
                        disabled={!this.state.habilitaBusca}
                        // disabled
                    />
                    <div className="invalid-feedback">Selecione o Filtro</div>
                    </div>                    
                    
                    {renderCalendarFilters()}

                </div>  
                <br />

                {renderButtons()}
                
                </div> {/* col-md-12 */}


                {renderFichaDeRecebimentoConsolidadaTable()}


            </Card>
            <div className="d-flex "/>
        </div>              
        )
    }

}

FichaDeRecebimentoConsolidada.contextType = AuthContext

export default withRouter(FichaDeRecebimentoConsolidada)