import React from 'react'

import { Button } from 'primereact/button'

import * as popUp from '../../components/toastr'
import HandleErrorService from '../../app/service/handleErrorService'
import InputChooseFornecedor from '../../components/contasAPagar/inputChooseFornecedor'
import InputChooseGrupoDePagamento from '../../components/contasAPagar/inputChooseGrupoDePagamento'
import TipoDePagamentoService from '../../app/service/contasAPagar/tipoDePagamentoService'
import TipoDePagamentoTable from '../../components/contasAPagar/tipoDePagamentoTable'
import FormGroup from '../../components/form-group'
import SelectMenu from '../../components/selectMenu'
import ConstantsUtil from '../../context/constantsUtil'
import InputChooseContaContabil from '../../components/planoDeContas/inputChooseContaContabil'
import InputChooseContaBancaria from '../../components/conciliacaoBancaria/inputChooseContaBancaria'
import GeneralServices from '../../app/service/generalServices'

class TipoDePagamentoComponent extends React.Component{

    constructor(){
        super();
        this.tipoDePagamentoService = new TipoDePagamentoService();
        this.inputNome = React.createRef();
    }    

    state = {

        tipoDePagamentoList: [],
        filteredTipoDePagamentoList: [],
        loading: false,

        nome: '',
        inputNomeErrorClass: '',
        errorNomeMessage: 'Informe o nome',

        natureza: '',
        inputNaturezaErrorClass: '',
        errorNaturezaMessage: 'Informe a natureza',

        fornecedor: {},
        inputFornecedorErrorClass: '',

        selectedContaContabil: '',
        selectedContaContabilLabel: '',
        inputContaContabilErrorClass: '',

        selectedContaBancaria: '',
        selectedContaBancariaLabel: '',
        inputContaBancariaLabelErrorClass: '',


        grupoDePagamento: '',
        inputGrupoDePagamentoErrorClass: '',

        isSaving: false,
    }

    componentDidMount(){
        this.search()
    }

    search = () => {

        this.setState({loading: true})

        this.tipoDePagamentoService.search()
        .then(response => {
            // console.log("tipos de pagamento: ", response.data)
            this.trataDados(response.data)
            this.setState({tipoDePagamentoList: response.data})
            this.setState({filteredTipoDePagamentoList: response.data})
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
        .finally(() => {
            this.setState({loading: false})
        })
    }

    trataDados = tipoDePagamentoList => {
        /*
        Altera nome dos fornecedores para ser concatenação do nome com cnpj.
        */
        tipoDePagamentoList.forEach(tipoDePagamento => {
            let fornecedor = tipoDePagamento.fornecedor
            if(fornecedor){
                fornecedor.nome = `${fornecedor.nome} (${fornecedor.cnpj})`
            }
            else{
                fornecedor = {nome: ''}
                tipoDePagamento.fornecedor = fornecedor
            }

            tipoDePagamento.situacaoLabel = tipoDePagamento.ativo ? "Ativo" : "Inativo"

       })
    //    console.log("trataDados: ", tipoDePagamentoList)
    }

    handleChange = async (event) => {
        const value = event.target.value
        const name = event.target.name
        await this.setState({[name]: value})
        this.filter()
    }

    handleNaturezaChange = async (event) => {
        const value = event.target.value
        const name = event.target.name
        await this.setState({[name]: value})
        
        this.setState({fornecedor: {}})

        this.setState({selectedContaContabil: ''})
        this.setState({selectedContaContabilLabel: ''})

        this.setState({selectedContaBancaria: ''})
        this.setState({selectedContaBancariaLabel: ''})
    }

    filter = () => {
        this.setState({
            filteredTipoDePagamentoList:
                JSON.parse(
                    JSON.stringify(
                        this.state.tipoDePagamentoList.filter(tipoDePagamento => {
                            return GeneralServices.includeString(tipoDePagamento.nome, this.state.nome)
                            // return (tipoDePagamento.nome.toUpperCase().includes(this.state.nome.toUpperCase()))
                        }
                        )
                    )
                )
        })
    }

    handleMuiKeyUp = (event) => {
        if(event.code === "Enter" || event.code === "NumpadEnter"){
            this.callSave()
        }
    }

    handleSelectionFornecedorChange = (fornecedor) => {
        this.setState({fornecedor})
    }

    handleSelectionContaContabilChange = (selectedContaContabil, selectedContaContabilLabel) => {
        this.setState({selectedContaContabil})
        this.setState({selectedContaContabilLabel})
    }

    handleSelectionContaBancariaDestinoChange = async (selectedContaBancaria, selectedContaBancariaLabel) => {
        this.setState({selectedContaBancaria})
        this.setState({selectedContaBancariaLabel})
    }

    handleSelectionGrupoDePagamentoChange = (grupoDePagamento) => {
        this.setState({grupoDePagamento})
    }

    resetForm = () => {
        this.setState({nome: ''})
        this.setState({natureza: ''})
        this.setState({fornecedor: {nome: ''}})

        this.setState({selectedContaContabil: ''})
        this.setState({selectedContaContabilLabel: ''})

        this.setState({selectedContaBancaria: ''})
        this.setState({selectedContaBancariaLabel: ''})

        this.setState({grupoDePagamento: {nome: ''}})
    }

    resetView = () => {
        this.setState({inputNomeErrorClass: ''})

        this.setState({inputNaturezaErrorClass: ''})

        this.setState({inputFornecedorErrorClass: ''})

        this.setState({inputContaContabilErrorClass: ''})

        this.setState({inputContaBancariaLabelErrorClass: ''})

        this.setState({inputGrupoDePagamentoErrorClass: ''})

    }

    checkData = () => {
        var check = true

        if(!this.state.nome){
            this.setState({inputNomeErrorClass: ConstantsUtil.bootstrapInputErrorClass})
            check = false
        }

        if(!this.state.natureza){
            this.setState({inputNaturezaErrorClass: ConstantsUtil.bootstrapInputErrorClass})
            check = false
        }
        else{ //Definiu natureza
            
            if(this.state.natureza === this.tipoDePagamentoService.naturezaFornecedorValue){
                if(!this.state.fornecedor || !this.state.fornecedor.nome){
                    this.setState({inputFornecedorErrorClass: ConstantsUtil.bootstrapInputErrorClass})
                    check = false
                }
            }
                
            else if(this.state.natureza === this.tipoDePagamentoService.naturezaOutrosValue){
                if(!this.state.selectedContaContabil){
                    this.setState({inputContaContabilErrorClass: ConstantsUtil.bootstrapInputErrorClass})
                    check = false
                }    
            }

            else if(this.state.natureza === this.tipoDePagamentoService.naturezaTarifaValue){
                if(!this.state.selectedContaBancaria){
                    this.setState({inputContaBancariaLabelErrorClass: ConstantsUtil.bootstrapInputErrorClass})
                    check = false
                }
            }

        }
        if(!this.state.grupoDePagamento || !this.state.grupoDePagamento.nome){
            this.setState({inputGrupoDePagamentoErrorClass: ConstantsUtil.bootstrapInputErrorClass})
            check = false
        }

        return check

    }

    callSave = () => {

        this.resetView()

        if(this.checkData()){
            this.save()
        }
    }

    save = () => {
        this.setState({isSaving: true})
        
        const obj =  {
            nome : this.state.nome.trim().toUpperCase(),
            natureza: this.state.natureza,
            fornecedor: this.state.fornecedor,
            idContaContabil: parseInt(this.state.selectedContaContabil.numeroDeChamada)/5,
            idContaBancaria: this.state.selectedContaBancaria.id,
            grupoDePagamento: this.state.grupoDePagamento
        }

        // console.log("obj: ", obj)

        this.tipoDePagamentoService.save(obj)
        .then(response => {
            popUp.successPopUp("Tipo de Pagamento cadastrado com sucesso!")
            this.resetForm()
            this.search()
            if(this.props.search){
                this.props.search()
            }

            if(this.props.hideDialog){
                this.props.hideDialog()
            }
            
            this.inputNome.current.focus()
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
        .finally(() => {
            this.setState({isSaving: false})
        })
    }

    render(){


        const renderFornecedorInput = () => {
            if(this.state.natureza === this.tipoDePagamentoService.naturezaFornecedorValue){
                return (
                    <div className = "col-md-3">
                        <InputChooseFornecedor 
                            noHTMLFormat
                            selectedFornecedorLabel={this.state.fornecedor.nome}
                            handleSelectionFornecedorChange={this.handleSelectionFornecedorChange}
                            inputFornecedorErrorClass={this.state.inputFornecedorErrorClass}
                            handleNewFornecedorCadastrado={this.props.handleNewFornecedorCadastrado}
                        />
                    </div>
                )
            }
        }

        const renderContaContabilInput = () => {
            if(
                (this.state.natureza === this.tipoDePagamentoService.naturezaOutrosValue)

            )
            return (
                <>
                   <InputChooseContaContabil
                        selectedContaContabilLabel={this.state.selectedContaContabilLabel}
                        // tipoLancamentoBancario={this.state.tipoDeLancamento}
                        push={this.props.push}
                        // renderDialogOnMount
                        handleSelectionContaContabilChange={this.handleSelectionContaContabilChange}
                        inputContaContabilErrorClass={this.state.inputContaContabilErrorClass}
                    />
                </>
                    
            )
        }

        const renderContaBancariaInput = () => {
            if(
                (this.state.natureza === this.tipoDePagamentoService.naturezaTarifaValue)

            )
            return (
                <div className="col-md-3">
                    <InputChooseContaBancaria
                        label="Conta Bancária"
                        readOnly
                        excludeFundosFixos
                        excludeAplicacaoFinanceira
                        // autofocus
                        // forceShowDialog={this.state.forceShowContaBancariaDialog}
                        // doNotForceContaBancariaDialog={this.doNotForceContaBancariaDialog}
                        selectedContaBancariaLabel={this.state.selectedContaBancariaLabel}
                        handleSelectionContaBancariaChange={this.handleSelectionContaBancariaDestinoChange}
                        inputContaBancariaErrorClass={this.state.inputContaBancariaLabelErrorClass}
                        // errorContaBancariaMessage={this.state.errorContaBancariaLabelMessage}
                        noHTMLFormat
                    />
                </div>
                    
            )
        }

        const renderTable = () => {
            if(!this.props.insideDialog){
                return (
                    <TipoDePagamentoTable
                        list = { this.state.filteredTipoDePagamentoList}
                        search={this.search}
                        searchText={this.state.nome}
                        push={this.props.push}
                        loading = {this.state.loading}
                    />
                )
            }
        }


        return(
                
            <div>

                <div className="row">

                <div className = "col-md-3">
                <label htmlFor="date">Nome</label>
                    <input
                        // style={{color: '#000'}}
                        type="text"
                        ref={this.inputNome}
                        className={"form-control " + this.state.inputNomeErrorClass}
                        value = {this.state.nome}
                        name="nome"
                        onChange={this.handleChange}
                        id="nome"
                        placeholder="Digite o nome do tipo de pagamento"
                    />
                    <div className="invalid-feedback">{this.state.errorNomeMessage}</div>
                </div>

                <div className = "col-md-3">
                    <FormGroup label = "Natureza" htmlFor = "inputNatureza">
                        <SelectMenu  className={"form-control " + this.state.inputNaturezaErrorClass}
                            name="natureza"
                            list={this.tipoDePagamentoService.naturezaTipoDePagamentoList} 
                            value={this.state.natureza}
                            onChange={this.handleNaturezaChange}
                            // disabled={this.state.disableTipoDeCorte}
                        /> 
                        <div className="invalid-feedback">{this.state.errorNaturezaMessage}</div>  
                    </FormGroup> 
                </div>

                
                {renderFornecedorInput()}

                {renderContaContabilInput()}

                {renderContaBancariaInput()}

                <div className = "col-md-3">
                <InputChooseGrupoDePagamento
                    noHTMLFormat
                    selectedGrupoDePagamentoLabel={this.state.grupoDePagamento.nome}
                    handleSelectionGrupoDePagamentoChange={this.handleSelectionGrupoDePagamentoChange}
                    push={this.props.push}
                    inputGrupoDePagamentoErrorClass={this.state.inputGrupoDePagamentoErrorClass}
                />
                </div>
                
                </div>

                <br />

                <Button 
                    label="Cadastrar Tipo de Pagamento"
                    icon="pi pi-plus"
                    onClick = {this.callSave}
                    // style={ {maxHeight: '35px'} }
                    disabled = {this.state.isSaving}
                />


                <br />

                <br />

                {renderTable()}

            </div>

        )
    }

}

export default TipoDePagamentoComponent