import WorkerApiService from "../../workerApiServices";

class TransferenciaBancaraiService extends WorkerApiService {

    constructor() {
        super('/api/transferenciaBancaria')
    }

    delete(object){
        return this.post("/delete", object)
    }

}

export default TransferenciaBancaraiService