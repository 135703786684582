import React from 'react'

import { Dialog } from 'primereact/dialog'

import DialogFooter from './dialogFooter'
import ConstantsUtil from '../context/constantsUtil'
import UserService from '../app/service/userService'
import FormGroup from './form-group'
import { Dropdown } from 'primereact/dropdown';
import GrupoDeEmpresasService from '../app/service/central/grupoDeEmpresasService'
import GeneralServices from '../app/service/generalServices'

class LoginEmpresaDialog extends React.Component {

    constructor(){
        super()
        this.userService = new UserService()
        this.grupoDeEmpresasService = new GrupoDeEmpresasService()
    }

    state = {
        id: null,

        gruposSelectList: [{label: 'Selecione', value: ''}],
        gruposList: [],
        selectedGrupoLabel: '',
        selectedGrupo: '',
        visibleGrupoSelectList: false,
        inputGrupoErrorClass: '',
        errorGrupoMessage: '',

        empresaSelectList: [{label: 'Selecione', value: ''}],
        empresaList: [],
        selectedEmpresa: '',
        inputEmpresaErrorClass: '',
        errorEmpresaMessage: '',

        visible: false,

    }

    componentDidUpdate(){
        this.setStates()
    }

    setStates = () => {
        if( (this.props.visible && !this.state.didUpdated) || this.props.forceUpdate ){
            this.resetView()

            this.trataGrupos()
                
            this.setState({didUpdated: true})
            if(this.props.doneForceUpdate){
                this.props.doneForceUpdate()
            }
        }
    }

    handleEmpresaChange = async (event) => {
        const value = event.target.value
        await this.setState({ selectedEmpresa: value })
    }

    handleGrupoChange = async (event) => {
        const nomeGrupo = event.target.value
        await this.setState({ selectedGrupoLabel: nomeGrupo })
        const selectedGrupo = this.state.gruposList.find(grupo => grupo.nome === nomeGrupo)
        await this.setState({selectedGrupo})
        await this.setState({selectedEmpresa: ''})

        this.getEmpresaListFromGrupo()
    }

    trataGrupos = async () => {
        const gruposList = this.props.grupos;
        let gruposSelectList = [{label: 'Selecione', value: ''}]
        gruposList.forEach(grupo => {
            const nome = grupo.nome
            gruposSelectList.push({label: nome, value: nome})
        })

        await this.setState({gruposList})
        await this.setState({gruposSelectList})

        if(gruposList.length > 1){
            this.setState({visibleGrupoSelectList: true})
            this.setState({visible: true})
        }
        else if(gruposList.length === 1){
            const event={
                target: {
                    value: gruposList[0].nome
                }
            }
            this.handleGrupoChange(event)
        }
        
    }    

    getEmpresaListFromGrupo = async () => {

        let empresaSelectList = [{ label: 'Selecione', value: '' }];

        if(this.state.selectedGrupo){

            const empresaList = this.state.selectedGrupo.empresaList;
    
            empresaList.forEach(empresa => {
              empresaSelectList.push({ label: empresa.nome, value: empresa.tenantName });
            });
      
            await this.setState({empresaSelectList, empresaList})

            if(empresaList && empresaList.length == 1){
                await this.setState({selectedEmpresa: empresaList[0].tenantName})
                this.login()
                return ;
            }

        }
        else{
            this.setState({empresaSelectList, empresaList: []}) 
        }

        await this.setState({visible: true})
    }    

    hideDialog = (shouldEnableLoginButton) => {
        this.setState({didUpdated: false})
        this.resetView()
        this.resetData()
        this.props.hideDialog(shouldEnableLoginButton)
    }

    resetData = () => {
        this.setState({selectedGrupoLabel: ''})
        this.setState({selectedGrupo: ''})
        this.setState({selectedEmpresa: ''})
    }

    resetView = () => {
        this.setState({inputGrupoErrorClass: ''})
        this.setState({errorGrupoMessage: ''})

        this.setState({inputEmpresaErrorClass: ''})
        this.setState({errorEmpresaMessage: ''})
    }

    checkData = () => {
        var check = true

        if(!this.state.selectedGrupo){
            this.setState({inputGrupoErrorClass: ConstantsUtil.primereactInputErrorClass})
            this.setState({errorGrupoMessage: "Selecione o Grupo"})
            check = false
        }

        if(!this.state.selectedEmpresa){
            this.setState({inputEmpresaErrorClass: ConstantsUtil.primereactInputErrorClass})
            this.setState({errorEmpresaMessage: "Selecione a Empresa"})
            check = false
        }

        return check

    }

    callLogin = () => {
        this.resetView()
        if(this.checkData()){
            this.login()
        }
    }

    login = () => {
        const empresa = this.state.empresaList.find(empresa => empresa.tenantName === this.state.selectedEmpresa)
        this.props.login(empresa.tenantName)
        this.hideDialog(false)
    }

    getSortedList = list => {
        /*
        Recebe a lista inicial, retira a opção "Selecione" e ordena alfabeticamente a lista restante.
        Ao final da ordenação, adiciona novamente a opção "Selecione".
        */
        let listSemOpcaoSelecione = list.filter(element => element.label !== 'Selecione')
        listSemOpcaoSelecione.sort( GeneralServices.compareGenericValues )
        listSemOpcaoSelecione.unshift({label: 'Selecione', value: ''})
        return listSemOpcaoSelecione;
    }

    render () {

        const renderGrupoSelectList = () => {
            if(this.state.visibleGrupoSelectList){
                return (
                    <FormGroup label = "Grupo">
                        <Dropdown
                            className={this.state.inputGrupoErrorClass}
                            value={this.state.selectedGrupoLabel}
                            options={this.getSortedList(this.state.gruposSelectList)}
                            onChange={this.handleGrupoChange}
                            optionLabel="label"
                            filter
                            showClear
                            filterBy="label"
                            placeholder="Selecione"
                            emptyMessage=""
                            emptyFilterMessage=""
                        />
                        <small className="p-error">{this.state.errorGrupoMessage}</small>
                    </FormGroup>                    
                )
            }
        }        

        const footer = (
            <DialogFooter save = {this.callLogin} hideDialog = {() => this.hideDialog(true)} disabled={this.props.disabled}/>
        )

        return (
            <>
            <Dialog
                visible={this.props.visible && this.state.visible} style={{ width: '450px' }}
                header={"Selecione a Empresa"}
                modal
                className="p-fluid"
                footer={footer}
                onHide={() => this.hideDialog(true)}
            >

                {renderGrupoSelectList()}

                <FormGroup label = "Empresa ">
                    <Dropdown
                        className={this.state.inputEmpresaErrorClass}
                        value={this.state.selectedEmpresa}
                        options={this.getSortedList(this.state.empresaSelectList)}
                        onChange={this.handleEmpresaChange}
                        optionLabel="label"
                        filter
                        showClear
                        filterBy="label"
                        placeholder="Selecione"
                        emptyMessage=""
                        emptyFilterMessage=""
                    />
                    <small className="p-error">{this.state.errorEmpresaMessage}</small>   
                    </FormGroup>            

            </Dialog>
            
            </>
        )    
    }

}

export default LoginEmpresaDialog