import React from 'react'
import { Toast } from 'primereact/toast'
import { Button } from 'primereact/button'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Dialog } from 'primereact/dialog'

import ContaBancariaService from '../../../app/service/conciliacaoBancaria/contaBancariaService'
// import EditContaBancariaDialog from './editContaBancariaDialog'

import '../../../css/primereactTable.css'
import CustomHighlighter from '../../customHighlighter'
import NFService from '../../../app/service/estoque/NFService'
import HandleErrorService from '../../../app/service/handleErrorService'
import ConstantsUtil from '../../../context/constantsUtil'
import GeneralServices from '../../../app/service/generalServices'
import *  as popUp from '../../toastr'
import TooltipButton from '../../tooltipButton'

class HistoricoImportacaoNFsTable extends React.Component {
    
    constructor(){
        super()
        this.dt = React.createRef()
        this.nfService = new NFService()
    }

    state = {
        loading: false,
        list: [],

    }

    componentDidMount(){
        this.search()
    }


    search = () => {
        
        this.setState({loading: true})

        this.nfService.searchImportacaoNFsFalha()
        .then(response => {
            // console.table(response.data)
            this.setState({list: response.data})
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
        .finally(() => {
            this.setState({loading: false})
        })
    }

    alteraCiencia = (rowData) => {
        this.setState({loading: true})
        const obj = {
            progressId: rowData.progressId,
            ciente: !rowData.ciente
        }
        this.nfService.alteraCiencia(obj)
        .then(response => {
            popUp.successPopUp("Ciência Alterada com Sucesso!")
            this.search()
        })
        .catch(error => {
            HandleErrorService.handleError(error)
            this.setState({loading: false})
        })
    }

    render (){

        const actionBody = (rowData) => {
            if(rowData.ciente){
                return (
                    <TooltipButton
                        className={ "p-button-danger p-button-rounded p-mr-2" }
                        tooltip="Desmarcar Ciência"
                        icon="pi pi-times"
                        onClick={() => this.alteraCiencia(rowData)}
                    />
                )
            }
            else{
                return (
                    <React.Fragment>
                        <TooltipButton
                            tooltip="Marcar Ciência"
                            icon="pi pi-check"
                            className={ "p-button-rounded p-mr-2 " }
                            onClick={() => this.alteraCiencia(rowData)}
                        />
    
                    </React.Fragment>
                );
            }
        }


        return (
            <div className="card">

                <DataTable
                    ref={this.dt}
                    value={this.state.list}
                    className="p-datatable-sm small-font"
                    rowHover
                    showGridlines
                    scrollable
                    scrollHeight="500px"
                    loading={this.state.loading}
                    dataKey="data"
                    emptyMessage="Nenhuma Importação com Erro Relatada."
                    paginator rows={ConstantsUtil.initialTableQuantityOption}
                    paginatorTemplate={GeneralServices.tablePaginatorTemplate(ConstantsUtil.initialTableQuantityOption, this.state.list ? this.state.list.length : '')}
                    currentPageReportTemplate="Mostrando de {first} ao {last} de {totalRecords} importações"
                >

                    <Column
                        header="Data"
                        field="dataHoraImportacao"
                        sortable
                        style ={ {width: '25px'} }
                    />

                    <Column
                        header="Notas com Erro"
                        field="failMessage"
                        sortable
                        style ={ {width: '160px'} }
                        body={(rowData) => (
                            <div style={{ whiteSpace: 'pre-line' }}>{rowData.failMessage}</div>
                            )}
                    />

                    <Column
                        header="Ciente"
                        field="ciente"
                        sortable
                        style ={ {width: '20px'} }
                        body={(rowData => rowData.ciente ? 'Sim' : 'Não')}
                    />

                    <Column
                        header="Ciência"
                        body={actionBody}
                        style ={ {width: '20px'} }
                    />


                </DataTable>
            </div>

        )
    }


}  

export default HistoricoImportacaoNFsTable