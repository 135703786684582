import React from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import ConstantsUtil from '../../../../context/constantsUtil'
import GeneralServices from '../../../../app/service/generalServices'
import { Button } from 'primereact/button'
import ExpansionTableButton from '../../DRE/expansionTableButton'
import { classNames } from 'primereact/utils'
import DRECompetenciaMensalDetalhamentoDialog from './DRECompetenciaMensalDetalhamentoDialog'


class DRECompetenciaMensalTable extends React.Component {

    constructor(){
        super()
        this.ref = React.createRef()
        this.generalServices = new GeneralServices()
    }

    state = {
        expanded: false,
        grupoDetalhamento: '',
        itemDetalhamento: '',

        visibleDetalhamentoDialog: false,

    }

    componentDidMount(){
        this.scroll()
    }

    scroll = async () => {
        await this.generalServices.sleep(300)
        if(
            this.props.dreCompetenciaGrupoMensal.titulo.includes(ConstantsUtil.receitaBrutaLabel)
            && this.ref.current
        )
        {
            this.ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            // this.ref.current.scrollIntoView();
        }
    }


    getValorTotalSeverity = () => {
        switch (this.props.dreCompetenciaGrupoMensal.tipo) {

            case ConstantsUtil.DRECompetenciaTipoEntradaLabel:
              return "success"

            case ConstantsUtil.DRECompetenciaTiposSaidaLabel:
                return "danger"

            case ConstantsUtil.DRECompetenciaTiposFormulaLabel:
                if(this.props.dreCompetenciaGrupoMensal.total >= 0){
                    return "success"
                }
                else{
                    return "danger"
                }

        }
    }

    handleClickValorTotalHeader = () => {
        if(this.props.dreCompetenciaGrupoMensal.habilitaDetalhamento) {
            if(
                this.props.dreCompetenciaGrupoMensal.total
                &&
                (
                    !this.props.dreCompetenciaGrupoMensal.dreCompetenciaItemList
                    || this.props.dreCompetenciaGrupoMensal.dreCompetenciaItemList.length == 0
                )
            ){
                /*
                Só deve abrir detalhamento a partir do Valor Total no Header se for um grupo com detalhamento habilitado
                e se não tiver lista de itens.
                Se tiver lista de itens, o detalhamento só será possivel ao clicar em cada item.
                */
                this.viewLancamentos(this.props.dreCompetenciaGrupoMensal, null)
            }
            else if(this.props.dreCompetenciaGrupoMensal.total)
            {
                this.toggleExpansionTable()
            }
        }
    }

    viewLancamentos = (grupo, rowData) => {
        this.setState({visibleDetalhamentoDialog: true})
        this.setState({grupoDetalhamento: grupo})
        this.setState({itemDetalhamento: rowData})
    }

    hideDetalhamentoDialog = () => {
        this.setState({visibleDetalhamentoDialog: false})
    }

    toggleExpansionTable = async () => {
        await this.setState({expanded: !this.state.expanded})
        await this.setState({selectedNome: ''})
        await this.setState({selectedTotal: ''})
        // await this.setState({selectedPorcentagem: ''})
        if(this.props.dt && this.props.dt.current){
            this.props.dt.current.reset(); //reseta filtro
        }
    }

    hasExpansion = () => {
        return (this.props.dreCompetenciaGrupoMensal.dreCompetenciaItemList && this.props.dreCompetenciaGrupoMensal.dreCompetenciaItemList.length > 0)
    }


    render() {

        const renderExpansionButton = () => {
            if(this.hasExpansion()){
                return (
                    <ExpansionTableButton
                        expanded={this.state.expanded}
                        toggleExpansionTable={this.toggleExpansionTable}
                    />                    
                )
            }

        }

        const renderViewLancamentosLink = (grupo, rowData) => {
            const valor = rowData.valor
            const valorFormatado = GeneralServices.showFormattedIfNotNull(valor, true)
            const cond1 = valor !== 0
            const cond2 = grupo.habilitaDetalhamento
            if( cond1 && cond2) {
                const classes = classNames({
                    // 'superavitValue': valor >= 0,
                    // 'deficitValue': valor < 0,
                    'linkValue': true
                    
                });
                return(
                    <a 
                        className={classes}
                        // href='/DRE'
                        onClick={() => this.viewLancamentos(grupo, rowData)}
                    >
                        {valorFormatado}
                    </a>
                )
            }
            else{
                return(
                    <>
                        {valorFormatado}
                    </>
                )
            }
        }        

        const renderTable = () => {
            if(!this.state.expanded || !this.props.dreCompetenciaGrupoMensal.dreCompetenciaItemList){
                return <></>;
            }
            return (
                <DataTable
                    ref={this.dt}
                    value={this.props.dreCompetenciaGrupoMensal.dreCompetenciaItemList}
                    className="p-datatable-sm small-font"
                    // rowClassName={this.saldoClass}
                    autoLayout
                    rowHover
                    showGridlines
                    // selection={this.state.selectedContasBancaria}
                    // onSelectionChange={this.handleSelectionChange}
                    scrollable
                    style={{ maxWidth: '100vw'}}
                    scrollHeight="500px"
                    frozenWidth={ConstantsUtil.widthColumNomeSaldoDreConsolidado}
                    loading={this.props.loading}
                    dataKey="id"
                    emptyMessage="Nenhum Resultado encontrado."
                    // paginator rows={ConstantsUtil.initialTableQuantityOption}
                    // paginatorTemplate={GeneralServices.tablePaginatorTemplate(ConstantsUtil.initialTableQuantityOption, this.props.list ? this.props.list.length : '')}
                    // currentPageReportTemplate="Mostrando de {first} ao {last} de {totalRecords} Tipos de Pagamento"
                >

                    <Column field="nome" header="Nome"
                        style ={ {width: ConstantsUtil.widthColumNomeDreConsolidado} }
                        frozen
                        body = { (rowData, rowIndex) => {
                            const uniqueId = `${rowIndex.rowIndex}`;
                            // console.log("rowIndex: ", rowIndex)
                            return (
                                <div
                                    style ={ {
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                    } }
                                    title={rowData.nome}
                                >
                                <span id={uniqueId}>{rowData.nome}</span>
        
                                </div>                        
                            )
                        }}
                    />

                    <Column field="valor" header="Valor"
                        style ={ {width: ConstantsUtil.widthColumDreConsolidado} }
                        // body = {rowData => GeneralServices.showFormattedIfNotNull(rowData.valor, true)}
                        body = {rowData => renderViewLancamentosLink(this.props.dreCompetenciaGrupoMensal, rowData)}
                    />

                    <Column field="porcentagem" header="Porcentagem"
                        style ={ {width: ConstantsUtil.widthColumDreConsolidado} }
                        body = {rowData => `${rowData.porcentagem}%`}
                    />            

                </DataTable>
            )
        }

        const renderDetalhamentoDialog = () => {
            return (
                <DRECompetenciaMensalDetalhamentoDialog
                    selectedEmpresas={this.props.selectedEmpresas}
                    unificar={this.props.unificar}
                    hideDialog = {this.hideDetalhamentoDialog}
                    visible = {this.state.visibleDetalhamentoDialog}
                    dataInicial = {this.props.dataInicial}
                    dataFinal = {this.props.dataFinal}
                    grupo = {this.state.grupoDetalhamento}
                    item = {this.state.itemDetalhamento}
                    searchAllDRE={this.props.searchAllDRE}
                />
            )
        }

        return(
            <div
                className='borda'
                // style={{marginTop: '0.8rem'}}
            >


                <div
                    ref={this.ref}
                    className = "card-header header-small-height"
                >
                <div className='col-md-12'>
                    

                    <div className='row'>
                        {renderExpansionButton()}
                        <div className='col-md-4'>
                            <h5 className='small-font-size'>{this.props.dreCompetenciaGrupoMensal.titulo}</h5>            
                        </div>

                        {/* <div className={`col-md-${this.hasExpansion() ? "4" : "5"}`}> */}
                        <div
                            className="col-md-4"
                            style={
                                this.hasExpansion() ?
                                {}
                                : {marginLeft: '1.2em'}
                            }
                        >
                        </div>
                        
                        <div
                            className='col-md-3'
                            style={{ display: 'flex', justifyContent: 'flex-end' }}
                        >
                            <Button
                                label={`Valor Total: ${GeneralServices.showFormattedIfNotNull(this.props.dreCompetenciaGrupoMensal.total , true)}`}
                                className={`small-font p-button-${this.getValorTotalSeverity()}`}
                                style={{maxHeight: '10px', minWidth: '12rem'}}
                                onClick={this.handleClickValorTotalHeader}
                            />

                            <Button
                                // label={`${ Math.abs(this.props.dreCompetenciaGrupoMensal.porcentagem)}%`}
                                label={ this.props.dreCompetenciaGrupoMensal.porcentagem ? `${this.props.dreCompetenciaGrupoMensal.porcentagem}%` : "0%"}
                                className={`small-font p-button-${this.getValorTotalSeverity()} right-button`}
                                style={{maxHeight: '10px', minWidth: '5rem'}}
                                // onClick={this.handleClickValorTotalHeader}
                            />
                        </div>

                    </div>

                    
                </div>
                </div>

                {renderTable()}

                {renderDetalhamentoDialog()}

            </div>
        )
    }

}

export default DRECompetenciaMensalTable