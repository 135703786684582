import React from 'react'
import { Toast } from 'primereact/toast'
import { Button } from 'primereact/button'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Dialog } from 'primereact/dialog'

import ContaBancariaService from '../../app/service/conciliacaoBancaria/contaBancariaService'
import EditContaBancariaDialog from './editContaBancariaDialog'

import '../../css/primereactTable.css'
import CustomHighlighter from '../customHighlighter'

class ContasBancariasTable extends React.Component {
    
    constructor(){
        super()
        this.dt = React.createRef()
        this.contaBancariaService = new ContaBancariaService()
    }

    state = {
        id: null,

        // nomeBanco: '',

        // numConta: '',
        
        // numDigitoConta: '',
        
        // numAgencia: '',
        
        contasBancarias: [],

        contaBancariaToEdit: {},
        
        nameField: 'nomeBanco',
        numContaField: 'numConta',
        numAgenciaField: 'numAgencia',

        selectedContaBancaria: null,
        editContaBancariaDialog: false,
        editContaBancariaDialogHeader: "Editar Conta Bancária"

    }

    hideDialog = () => {
        this.setState({editContaBancariaDialog: false})
    }

    exportCSV = async () => {
        this.dt.current.exportCSV();
    }

    delete = () => {
        if(this.state.selectedContaBancaria){
            this.setState({displayConfirmation: true})
        } 
    }

    confirmDelete = () => {
        this.setState({displayConfirmation: false})
        this.setState({selectedContaBancaria: null})
        if(this.state.selectedContaBancaria){
            var listOfId = []
            Array.from(this.state.selectedContaBancaria).forEach(selectedTransaction => {
                listOfId.push(selectedTransaction.id)
            })
        }
        this.props.deleteMultiple(listOfId)
    }

    editContaBancaria = (contaBancaria) => {
        this.setState({contaBancariaToEdit: JSON.parse(JSON.stringify(contaBancaria))})

        this.setState({editContaBancariaDialog: true})
    }

    handleSelectionChange = (event) => {
        this.setState({selectedContaBancaria: event.value})
        this.props.handleSelectionContaBancariaChange(event.value)
    }

    render (){

        // const leftToolbarTemplate = () => {
        //     return (
        //         <React.Fragment>
        //             {/* <FileUpload mode="basic" accept="*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="p-mr-2 p-d-inline-block" /> */}
        //             <Button label="Exportar" icon="pi pi-upload" className="p-button-help" onClick={this.exportCSV} />
        //         </React.Fragment>
        //     )
        // }

        const actionBodyTemplate = (contaBancaria) => {
            if(!contaBancaria.fundoFixo){
                return (
                    <React.Fragment>
                        <Button title = "Editar Conta Bancária"
                                icon="pi pi-pencil"
                                className={ "p-button-rounded p-mr-2 " }
                                onClick={() => this.editContaBancaria(contaBancaria)}
                                style={ {maxHeight: '30px', maxWidth: '30px'} }
                        />
                    </React.Fragment>
                );
            }
        }

        const renderDeleteConfirmationFooter = () => {
            return (
                <div>
                    <Button label="Confirmar" icon="pi pi-check"
                            onClick={this.confirmDelete} autoFocus />
                    <Button label="Cancelar" icon="pi pi-times" onClick={() => this.setState({displayConfirmation: false})}
                            className="p-button-text" />
                </div>
            );
        }

        const renderSelectionColumn = () => {
            if(this.props.selectable){
                return(
                    <Column selectionMode="single" headerStyle={{ width: '3rem' }}></Column>
                )
            }
        }

        const renderEditColumn = () => {
            if(this.props.allowEdit){
                return(
                    <Column header="Editar" body={actionBodyTemplate} style ={ {width: '140px'} }></Column>
                )
            }
        }

        const renderDescricaoColumn = () => {
            if(!this.props.simpleView){
                return (
                    <Column field="descricao" header="Descrição" sortable style ={ {width: '160px'} }
                    />
                )
            }
        }

        return (
            <div className="datatable-crud-demo">
            <Toast ref={this.toast} />

            <div className="card">
                {/* <Toolbar className="p-mb-4" left={leftToolbarTemplate} 
                // right={rightToolbarTemplate}
                /> */}

                <DataTable ref={this.dt} value={this.props.list}
                    className="p-datatable-sm small-font"
                    rowHover
                    showGridlines
                    selection={this.state.selectedContaBancaria}
                    onSelectionChange={this.handleSelectionChange}
                    // scrollable
                    // scrollHeight="500px"
                    loading={this.props.loading}
                    dataKey="uniqueId"
                    emptyMessage="Nenhuma Conta Bancária cadastrada."
                    paginator rows={30} rowsPerPageOptions={[30, 100, 400]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Mostrando de {first} ao {last} de {totalRecords} contas bancárias"
                >

                    {renderSelectionColumn()}
                    

                    <Column field={this.state.nameField} header="Banco" sortable style ={ {width: '160px'} }
                        body={rowData => 
                            <CustomHighlighter
                                searchWords={[this.props.searchNomeConta]}
                                textToHighlight={this.props.simpleView && rowData.descricao ? rowData.descricao : rowData.nomeBanco}
                            />
                        }
                    />

                    {renderDescricaoColumn()}

                    <Column field={this.state.numAgenciaField} header="Agência" sortable style ={ {width: '160px'} }
                    />

                    <Column field={this.state.numContaField} header="Conta" sortable style ={ {width: '160px'} } 
                            body={rowData => this.contaBancariaService.formatNumConta(rowData)}
                    />

                    {/* <Column header="Editar" body={actionBodyTemplate} style ={ {width: '140px'} }></Column> */}

                    {renderEditColumn()}
                    
                </DataTable>
            </div>

            <EditContaBancariaDialog  
                hideDialog={this.hideDialog}
                visible={this.state.editContaBancariaDialog}
                header={this.state.editContaBancariaDialogHeader}
                state={this.state.contaBancariaToEdit}
                search={this.props.search}
                push={this.props.push}
                // disabled={true}
            />

            <Dialog 
                header="Deletar Conta Bancária"
                visible={this.state.displayConfirmation}
                modal = {true} //congela restante da tela
                style={{ width: '350px' }}
                footer={renderDeleteConfirmationFooter()}
                onHide={() => this.setState({displayConfirmation: false})}>
                <div className="confirmation-content row" style={{marginLeft: '10px'}}>
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem', marginRight: '10px'}} />
                    <div style={{marginBottom: '10px'}}> Deseja confirmar exclusão? </div>
                </div>
            </Dialog>
        </div>
        )
    }


}  

export default ContasBancariasTable