import React from 'react'
import { Dialog } from 'primereact/dialog'
import ProductService from '../../app/service/productService'
import DialogFooter from '../dialogFooter'
import DadosNFComponent from '../nf/dadosNFComponent'
import ConstantsUtil from '../../context/constantsUtil'
import * as popUp from '../toastr'

class CheckNFDialog extends React.Component {

    state = {
        dataEntrada: '',
        dataEntradaView: '',
        inputDateErrorClass: '',
        errorDateMessage: '',

        hour: '',
        inputHourErrorClass: '',
        errorHourMessage: '',

        qtdParcelas: '',
        inputParcelasErrorClass: '',
        errorParcelasMessage: 'Informe a quantidade de Parcelas',

        pagamentoList: [],

        transactions: [],

        didUpdated: false
    }

    constructor(){
        super();
        this.productService = new ProductService();
        this.quantidadeParcelasComponentRef = React.createRef()
        this.dadosNFComponentRef = React.createRef()
        
    }

    componentDidUpdate(){
        this.setStates()
    }

    setStates = () => {
        if( (this.props.visible && !this.state.didUpdated) || this.props.forceUpdate ){
            

            this.setState({didUpdated: true})
            if(this.props.doneForceUpdate){
                this.props.doneForceUpdate()
            }
        }
    }


    hideDialog = () => {
        this.dadosNFComponentRef.current.resetView()
        this.dadosNFComponentRef.current.resetData()
        this.setState({didUpdated: false})
        this.props.hideDialog()
    }

    
    checkData = () => {
        let checkDadosNfComponent = this.dadosNFComponentRef.current.checkData()
        let checkDataValidacao = this.checkTimestamp(`${this.dadosNFComponentRef.current.state.dataEntrada} - ${this.dadosNFComponentRef.current.state.hour}`)
        return checkDadosNfComponent && checkDataValidacao
    }

    checkTimestamp(receivedTimestamp) {

        // Função auxiliar para converter a string de timestamp em um objeto Date
        function parseTimestamp(timestamp) {
            const [datePart, timePart] = timestamp.split(" - ");
            const [day, month, year] = datePart.split("-");
            const [hours, minutes] = timePart.split(":");
    
            // Cria um novo objeto Date usando os componentes extraídos
            return new Date(year, month - 1, day, hours, minutes);
        }
    
        // Obtenha a data e hora atuais
        const currentTimestamp = new Date();
    
        // Converte o timestamp recebido em um objeto Date
        const receivedTime = parseTimestamp(receivedTimestamp).getTime();
        const currentTime = currentTimestamp.getTime();
    
        // Calcule a diferença em milissegundos
        const differenceInMillis = receivedTime - currentTime;
    
        // Verifique se a diferença é maior que 10 minutos (600000 milissegundos)
        if (differenceInMillis > 600000) {
            popUp.warningPopUp("Data/Hora de Entrada está no futuro.")
            return false
        }
        return true
    }    

    callSave = () => {
        // this.resetView()
        // console.log("this.dadosNFComponentRef.current", this.dadosNFComponentRef.current)
        // console.log("this.dadosNFComponentRef.current.state", this.dadosNFComponentRef.current.state)
        if(this.checkData()){
            
            /*
            Altera obj NFToValidate recebido.
            Assim, quando o componente pai (transactionByNFTable) for salvar, o objeto já estará modificado por referência.
            */
            this.props.NFToValidate.dataEntrada = this.dadosNFComponentRef.current.state.dataEntrada
            this.props.NFToValidate.horaEntrada = this.dadosNFComponentRef.current.state.hour
            this.props.NFToValidate.valorAcrescimoDesconto = this.dadosNFComponentRef.current.getRealValorAcrescimoDesconto()
            this.props.NFToValidate.valorDescontoPorBonificacao = this.dadosNFComponentRef.current.getValorDescontoBonificacao()
            this.props.NFToValidate.provisao = this.dadosNFComponentRef.current.quantidadeParcelasComponentRef.current.getResultObject()

            // console.log("this.props.NFToValidate final: ", this.props.NFToValidate)
            // console.log("this.props.NFToValidate: ", this.props.NFToValidate)
            this.hideDialog()
            this.props.save()
        }
    }
    render () {

        const dialogFooter = (
            <DialogFooter save = {this.callSave} hideDialog = {this.hideDialog} disabled={this.props.disabled} />
        )



        return (
            <>
            <Dialog
                visible={this.props.visible}
                style={{ width: '95vw' }}
                header={`Validar NF (N° ${this.props.NFToValidate.numero})`}
                modal
                className="p-fluid"
                footer={dialogFooter}
                onHide={this.hideDialog}
            >

                <DadosNFComponent
                    ref={this.dadosNFComponentRef}
                    insideValidateNFDialog
                    NFToValidate={this.props.NFToValidate}
                />


            </Dialog>
            

            </>

        )
    }
}

export default CheckNFDialog