import React from 'react'
import CustomCalendar from '../calendar/customCalendar'
import ConstantsUtil from '../../context/constantsUtil'
import FormGroup from '../form-group'
import GeneralServices from '../../app/service/generalServices'
import * as popUp from '../../components/toastr'
import { InputNumber } from 'primereact/inputnumber'

class QuantidadeParcelasComponent extends React.Component {

    state = {
        qtdParcelas: '',
        inputParcelasErrorClass: '',
        errorParcelasMessage: 'Informe a quantidade de Parcelas',

        pagamentoList: [],

    }

    componentDidMount(){
        if(this.props.provisao){
            this.preencheParcelas()
        }
    }

    preencheParcelas = async () => {

        await this.setState({pagamentoList: this.props.provisao.parcelaProvisaoList})
        
        const event= {
            target:{
                value: this.props.provisao.quantidadeParcelas
            }
        }
        this.handleQuantidadeParcelasChange(event)

    }

    reset = () => {
        this.handleQuantidadeParcelasChange({target: {value: ''}})
    }

    handleQuantidadeParcelasChange = async (event) => {

        let currentValue = this.state.qtdParcelas
        let valorInicialParecla = null

        let value = event.target.value
        if(value && value < 0){
            value*=-1
        }

        const qtdParcelas = value;

        if( (!currentValue || currentValue === 0) && this.props.valorTotalProvisao) {
            let valorTotal = this.calculaValorFinalASerPago()
            // console.log("valorTotal: ", valorTotal)
            // console.log("qtdParcelas: ", qtdParcelas)
            valorInicialParecla = GeneralServices.formatTo2CasasDecimais(valorTotal/qtdParcelas)
        }
        
        await this.setState({ qtdParcelas })

        var pagamentoList = this.state.pagamentoList
        // console.log("pagamentoList: ", pagamentoList)
        
        if(qtdParcelas && qtdParcelas !== 0){

            var initialIndex = 0
            var shouldExecuteLoop = true

            if(pagamentoList && pagamentoList.length > 0){
                if(qtdParcelas > pagamentoList.length){
                    initialIndex = pagamentoList.length
                }
                else{
                    pagamentoList = pagamentoList.slice(0, qtdParcelas)
                    shouldExecuteLoop = false
                }

            }
            else{
                pagamentoList = []
            }
            if(shouldExecuteLoop){
                for(var i = initialIndex; i < this.state.qtdParcelas; i++){
                    pagamentoList.push(
                        {
                            parcela: i+1,
                            data: '',
                            dataView: '',
                            valor: valorInicialParecla,
                            inputDateErrorClass: '',
                            inputValorErrorClass: '',
                        }
                    )
                }
            }

        }
        else{
            pagamentoList = []
        }
        
        await this.setState({pagamentoList})
        // console.log('this.state.pagamentoList', this.state.pagamentoList)

    }

    handleDateLoopChange = async (event, index) => {

        var value = event.target.value
        const valueFormatted = GeneralServices.convertJSDateToBrDate(value)
        
        var pagamentoList = this.state.pagamentoList

        var obj = pagamentoList[index]
        obj.data = valueFormatted
        obj.dataView = value

        pagamentoList[index] = obj

        await this.setState({pagamentoList})

        // console.log("this.state.pagamentoList", this.state.pagamentoList)
    }    

    handleValorParcelaLoopChange = async (event, index) => {

        var value = event.target.value
        // value = GeneralServices.convertJSDateToBrDate(value)
        
        var pagamentoList = this.state.pagamentoList

        var obj = pagamentoList[index]
        obj.valor = value

        pagamentoList[index] = obj

        await this.setState({pagamentoList})

        // console.log("this.state.pagamentoList", this.state.pagamentoList)
    }

    resetView = () => {
        this.setState({inputParcelasErrorClass: ''})

        this.state.pagamentoList.forEach(pagamentoItem => {
            pagamentoItem.inputDateErrorClass = ''
            pagamentoItem.inputValorErrorClass = ''
        })
    }

    checkData = () => {

        this.resetView()

        if(!this.existeValorASerPago()){
            return {checkGeral: true, apenasCheckSomatorioFalhou: false}
        }
        
        let check = true
        let checkSomatorio = true

        if(!this.state.qtdParcelas || this.state.qtdParcelas <= 0){
            this.setState({inputParcelasErrorClass: ConstantsUtil.bootstrapInputErrorClass})
            check = false
        }
        else {
            var valorTotalProvisaoDigitado = parseFloat(0)
            this.state.pagamentoList.forEach(pagamentoItem => {
                if(!pagamentoItem.data){
                    pagamentoItem.inputDateErrorClass = ConstantsUtil.primereactInputErrorClass
                    check = false
                }
                if(!pagamentoItem.valor){
                    valorTotalProvisaoDigitado = undefined
                    pagamentoItem.inputValorErrorClass = ConstantsUtil.primereactInputErrorClass
                    check = false
                }

                if(valorTotalProvisaoDigitado !== undefined){
                    valorTotalProvisaoDigitado += parseFloat(pagamentoItem.valor)
                }

            })
            // console.log("valorTotalProvisaoDigitado: ", valorTotalProvisaoDigitado)
            if(valorTotalProvisaoDigitado !== undefined){
                // console.log("valorTotalProvisaoDigitado: ", GeneralServices.formatTo2CasasDecimais(valorTotalProvisaoDigitado))
                // console.log("this.props.valorTotalProvisao: ", GeneralServices.formatTo2CasasDecimais(this.props.valorTotalProvisao))
                // console.log("somatorio das parcelas digitadas pelo usuario: ", valorTotalProvisaoDigitado)
                // console.log("valor a ser pago: ", this.calculaValorFinalASerPago())
                if( 
                    GeneralServices.formatTo2CasasDecimais(valorTotalProvisaoDigitado)
                    !== this.calculaValorFinalASerPago()
                ){
                    popUp.warningPopUp("Somatório do valor das parcelas diferente do Valor Total.")
                    checkSomatorio = false
                }
            }

        }

        let checkGeral = check && checkSomatorio
        
        return {checkGeral, apenasCheckSomatorioFalhou: check && !checkSomatorio}

    }

    getResultObject = () => {
        const obj = {
            quantidadeParcelas: this.state.qtdParcelas ? this.state.qtdParcelas : 0,
            valorTotal: this.calculaValorFinalASerPago(),
            parcelaProvisaoList: this.state.pagamentoList,
        }

        // console.log("obj: ", obj)
        
        return obj
    }

    calculaValorFinalASerPago = () => {

        if(this.props.NF && this.props.NF.tipo === ConstantsUtil.tipoNFDevolucaoEntradaLabel){
            return parseFloat(0.0)
        }

        let valorTotalProvisao = this.props.valorTotalProvisao ? this.props.valorTotalProvisao : 0.0
        let valorAcrescimoDesconto = this.props.valorAcrescimoDesconto ? this.props.valorAcrescimoDesconto : 0.0
        let valorDescontoBonificacao = this.props.valorDescontoBonificacao ? this.props.valorDescontoBonificacao : 0.0
        let valorDescontoDevolucao = this.props.valorDescontoDevolucao ? this.props.valorDescontoDevolucao : 0.0
        let valorFinalASerPago = GeneralServices.formatTo2CasasDecimais(
            valorTotalProvisao + valorAcrescimoDesconto - valorDescontoBonificacao - valorDescontoDevolucao
        )
        // console.log("valorFinalASerPago: ", valorFinalASerPago)
        return parseFloat(valorFinalASerPago)
    }

    existeValorASerPago = () => {
        let valorASerPago = parseFloat(this.calculaValorFinalASerPago())
        let existeValorASerPago = (valorASerPago !== 0.00)
        return existeValorASerPago;
    }

    render(){

        const renderParcelasList = () => {
            var componentParcelaList = []
            if(this.state.qtdParcelas && this.state.qtdParcelas !== 0){
                for(var i = 0; i < this.state.qtdParcelas; i++){
                    componentParcelaList.push(renderParcela(i))
                }

                componentParcelaList.push(<br/>)

                return componentParcelaList

            }
        }

        const renderParcelaComponent = (index, dateView, inputDateErrorClass, valor, inputValorErrorClass) => {
            let colMdDataParcela = 2
            let colMdValorParcela = 2
            if(this.props.insideDialog){
                colMdDataParcela = 6
                colMdValorParcela= 6
            }
            return (
                <>
                    <div className='row'>

                    <div className = {`col-md-${colMdDataParcela}`}>
                    <CustomCalendar
                        label={`Data da Parcela ${index+1}`}
                        value={ dateView }
                        showMultipleMonths
                        noHTMLFormat
                        onChange={(event) => this.handleDateLoopChange(event, index)}
                        inputDateErrorClass={inputDateErrorClass}
                        errorDateMessage={inputDateErrorClass ? ConstantsUtil.informeDataErrorMenssage : ''}
                    />
                    </div>            

                    <div className = {`col-md-${colMdValorParcela}`}>
                        <label htmlFor="valor">{`Valor da Parcela ${index+1}`}</label>
                            <InputNumber 
                                className={inputValorErrorClass}
                                inputId="valorFechamentoBancario"
                                value={valor}
                                onValueChange={(event) => this.handleValorParcelaLoopChange(event, index)}
                                // onKeyDown={this.handleKeyPress}
                                mode="decimal" locale="pt-BR" minFractionDigits={2}
                            />                        
                    <div className="row">
                        <div className="col-md-12">
                        <small id="valorError" className="p-error">{inputValorErrorClass ? ConstantsUtil.informeValorErrorMenssage: ""}</small>
                        </div>
                    </div>          
                    </div>                
                    </div>
                </>
            )
        }

        const renderFinalParcelaComponent = (index, dateView, inputDateErrorClass, valor, inputValorErrorClass) => {
            if(this.props.insideDialog){
                return (
                    <div className='col-md-12'>
                        {renderParcelaComponent(index, dateView, inputDateErrorClass, valor, inputValorErrorClass)}
                    </div>
                )
            }
            else{
                return renderParcelaComponent(index, dateView, inputDateErrorClass, valor, inputValorErrorClass)
            }
        }
        
        const renderParcela = (index) => {

            const dateView = this.state.pagamentoList[index] ? this.state.pagamentoList[index].dataView : ''
            // console.log(`dateView ${index}:`, dateView)
            const inputDateErrorClass = this.state.pagamentoList[index] ? this.state.pagamentoList[index].inputDateErrorClass : ''
            
            const valor = this.state.pagamentoList[index] ? this.state.pagamentoList[index].valor : ''
            const inputValorErrorClass = this.state.pagamentoList[index] ? this.state.pagamentoList[index].inputValorErrorClass : ''

            return (
            <>
                {renderFinalParcelaComponent(index, dateView, inputDateErrorClass, valor, inputValorErrorClass)}
            </>          
            )
        }

        const renderQdtParcelas = () => {
            let colMd = 3
            if(this.props.insideDialog){
                colMd = 5
            }
            return (
                <>
                <div className='row'>
                <div className = {`col-md-${colMd}`}>
                    <FormGroup label = "Quantidade de Parcelas" htmlFor = "InputParcelas">
                        <input
                            type="number"
                            id="inputQtdParcelas"
                            className={"form-control " + this.state.inputParcelasErrorClass}
                            name="qtdParcelas"
                            value = {this.state.qtdParcelas}
                            onChange={this.handleQuantidadeParcelasChange}
                            // onKeyPress={this.handleKeypress}
                            placeholder="Digite a quantidade de Parcelas"
                            disabled={this.props.disabled}
                        />
                        <div className="invalid-feedback">{this.state.errorParcelasMessage}</div>
                    </FormGroup>
                </div>
                </div>
                </>
            )
        }

        const renderFinalQdtParcelas = () => {

            if(this.props.insideDialog) {
                return (
                    <div className='col-md-12'
                        style={{marginTop: '20px'}}
                    >
                        {renderQdtParcelas()}
                    </div>                    
                )
            }

            else{
                return renderQdtParcelas()
            }
        }

        const renderComponent = () => {
            if(this.existeValorASerPago())
            return (
                <>

                    {renderFinalQdtParcelas()}

                    {renderParcelasList()}
    
                </>
            )
        }

        return (
            <>
                {renderComponent()}
            </>
        )
    }


}
export default QuantidadeParcelasComponent