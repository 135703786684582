import React from 'react'

import { Dialog } from 'primereact/dialog'
import TipoDePagamentoComponent from './tiposDePagamentoComponent'

class CreateTipoDePagamentoDialog extends React.Component {

    constructor(){
        super()
    }

    hideDialog = () => {
        this.props.hideDialog()
    }

    render(){

        return(
            <Dialog 
                visible={this.props.visible}
                style={{ minWidth: '80vw' }}
                header={this.props.header ? this.props.header : 'Cadastrar Tipo de Pagamento'}
                modal
                className="p-fluid"
                // footer={editTipoRecebimentoDialogFooter}
                onHide={this.hideDialog}
            >

                <TipoDePagamentoComponent
                    insideDialog
                    search={this.props.search}
                    hideDialog={this.props.hideDialog}
                    push={this.props.push}
                    handleNewFornecedorCadastrado={this.props.handleNewFornecedorCadastrado}
                />

            </Dialog>
        )

       


    }


}

export default CreateTipoDePagamentoDialog