import WorkerApiService from "../../workerApiServices";


class FornecedorService extends WorkerApiService {

    constructor() {
        
        super('/api/fornecedor')

        this.tipoFornecedorList = [
    
            {label: 'Selecione...', value: ''},
            {label: "Mercadorias", value: "MERCADORIAS"},
            {label: "Serviços", value: "SERVICOS"},
        ]

    }

    search(filtraAtivos){
        let params = `?`
        params = `${params}&filtraAtivos=${filtraAtivos}`
        return this.get(`/search${params}`)
    }

    save(object){
        return this.post('/save', object)
    }

    update(object){
        return this.put('/update', object)
    }

    deleteFornecedor(object){
        return this.post('/delete', object)
    }

    reativar(object){
        return this.post('/reativar', object)
    }
}

export default FornecedorService