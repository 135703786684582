import CentralApiService from "../../centralApiServices";


class GrupoDeEmpresasService extends CentralApiService {

    constructor() {
        super('/api/grupoDeEmpresas')
    }

    searchAllGrupos(){
        return this.get('/grupos')
    }

    createOrUpdate(obj){
        return this.post("/createOrUpdate", obj)
    }

    sync(selectedGruposList){
        return this.post("/sync", selectedGruposList)
    }

    inativar(grupo){
        return this.put("/inativar", grupo)
    }

}

export default GrupoDeEmpresasService