import WorkerApiService from "../../workerApiServices";

class DRECompetenciaService extends WorkerApiService {
 
    constructor() {
        super('/api/DRECompetencia')
    }

    getDRECompetenciaMensal(dataInicial, dataFinal){
        let params = `?`
        params = `${params}&dataInicial=${dataInicial}`
        params = `${params}&dataFinal=${dataFinal}`
        return this.get(`/getDRECompetenciaMensal${params}`)
    }

    // getDREMensal(dataInicial, dataFinal){
    //     let params = `?`
    //     params = `${params}&dataInicial=${dataInicial}`
    //     params = `${params}&dataFinal=${dataFinal}`
    //     return this.get(`/getDREMensal${params}`)
    // }

    // getDREConsolidado(dataInicial, dataFinal){
    //     let params = `?`
    //     params = `${params}&dataInicial=${dataInicial}`
    //     params = `${params}&dataFinal=${dataFinal}`
    //     return this.get(`/getDREConsolidado${params}`)
    // }
}

export default DRECompetenciaService