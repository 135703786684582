import React from 'react'

import { Dialog } from 'primereact/dialog'
import DialogFooter from '../../dialogFooter'
import FichaBancariaTable from '../../conciliacaoBancaria/fichaBancariaTable'
import HandleErrorService from '../../../app/service/handleErrorService'
import FichaBancariaService from '../../../app/service/conciliacaoBancaria/fichaBancariaService'
import CentralService from '../../../app/service/central/centralService'

class DREFichaBancariaDialog extends React.Component {

    constructor(){
        super()
        this.fichaBancariaService = new FichaBancariaService();
        this.centralService = new CentralService();
    }

    state = {
        
        didUpdated: false,
        loading: false,
        
        fichaBancaria: {},
        teveAlteracao: false,
    }

    componentDidMount(){
        this.init()
    }

    componentDidUpdate(){
        this.init()
    }

    init = () => {
        // console.log("init: ", this.props)
        if(this.props.initialDate && (this.props.element || this.props.diferencaDRE) && (!this.state.didUpdated || this.props.forceUpdate)){
            this.searchFichaBancariaUnificada()
            this.setState({teveAlteracao: false})
            this.setState({didUpdated: true})

            if(this.props.doneForceUpdate){
                this.props.doneForceUpdate()
            }
        }
    }

    handleSearchFichaBancariaUnificada = (fichaBancariaInputDTO) => {
        if(this.props.unificarEmpresas){
            
            const fichaBancariaCentralInputDTO = {
                fichaBancariaInputDTO: fichaBancariaInputDTO,
                empresaList: this.props.selectedEmpresas
            }
            return this.centralService.getFichaBancariaUnificada(fichaBancariaCentralInputDTO)
        }
        else{
            return this.fichaBancariaService.getFichaBancariaUnificada(fichaBancariaInputDTO)
        }
    }

    searchFichaBancariaUnificada(){
        this.setState({fichaBancaria: {}})
        this.setState({loading: true})

        var obj = {
            initialDate: this.props.initialDate,
            finalDate: this.props.finalDate,
        }

        if(this.props.diferencaDRE){
            obj.diferencaDRE = true
        } else{
            obj.element = this.props.element

        }

        this.handleSearchFichaBancariaUnificada(obj)
        .then(response => {
            this.setState({fichaBancaria: response.data})

        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
        .finally(()=>{
            this.setState({loading: false})
        })
    }

    reSearch = () => {
        // this.props.search()
        this.searchFichaBancariaUnificada()
        this.setState({teveAlteracao: true})
    }

    hideDialog = () => {
        // this.setState({didUpdated: false})
        this.props.hideDialog()
        if(this.state.teveAlteracao && this.props.search){
            this.props.search()
        }
    }


    render(){

        
        const footer = (
            <DialogFooter hideDialog = {this.hideDialog} disabled/>
        )


        return(
            <Dialog
                visible={this.props.visible}
                style={{ width: '95vw',}}
                header={`${this.props.headerPrefix ? this.props.headerPrefix : "Lançamentos Bancários"} ${this.props.element ? "(" + this.props.element.nome + ")" : ""}`}
                modal
                baseZIndex={-1}
                className="p-fluid"
                footer={footer}
                onHide={this.hideDialog}
            >
                <FichaBancariaTable
                    fichaBancaria={this.state.fichaBancaria}
                    insideDialog
                    DRE
                    DRE_RECEBIMENTO={this.props.recebimento}
                    diferencaDRE={this.props.diferencaDRE}
                    fromDRECompetencia={this.props.fromDRECompetencia}
                    // tipoDeRecebimentoList={this.state.filteredTipoDeRecebimentoList}
                    // search={this.props.search}
                    search={this.reSearch}
                    saveAsExcelFile={this.props.saveAsExcelFile}
                    loading={this.state.loading}
                    disabled={this.props.unificarEmpresas || this.props.disabled}
                />
            </Dialog>
        )
    }

}

export default DREFichaBancariaDialog