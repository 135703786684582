import React from 'react'

import Card from '../../components/card'
import FormGroup from '../../components/form-group'
import * as popUp  from '../../components/toastr'
import BuyProductCrudTable from '../../components/buyProductCrudTable'
import HandleErrorService from '../../app/service/handleErrorService'
import ParametrizeService from '../../app/service/parametrizeService'
import ParametrizeProductTable from '../../components/parametrize/parametrizeProductTable'
import ProductService from '../../app/service/productService'
import GeneralServices from '../../app/service/generalServices'
import SelectMenu from '../../components/selectMenu'
import ConstantsUtil from '../../context/constantsUtil'
import { Dialog } from 'primereact/dialog'

class ParametrizeProduct extends React.Component {

    constructor(){
        super();
        this.parametrizeService = new ParametrizeService();
        this.productService = new ProductService();
        this.generalServices = new GeneralServices();
        this.buyProdutcTableRef = React.createRef()
    }

    state = {
        codigo: null,
        nomeFornecedor: '',
        cnpjFornecedor: '',
        descricao: '',
        ncm: null,
        tipo: {},
        unidadeComercializada: '',
        productList: [],
        filteredProductList: [],
        displayConfirmation: false,
        editId: null,
        loading: false,
        disableDeleteButton: false,
        requestParametrize: false,
        parametrizeCodigo: null,
        parametrizeDescricao: '',
        parametrizeProductList: [],
        parametrizeFilteredProductsList: [],
        resetSelectedBuyProducts: false,

        situacaoParametrizacao: ConstantsUtil.situacaoParametrizacaAindaNaoParametrizado,
    }

    componentDidMount(){
        this.search()
    }

    handleChange = async (event) => {
        const value = event.target.value
        const name = event.target.name
        await this.setState({ [name]: value })
        this.filter()
    }

    filter = () => {
        this.setState({loading: true})
        this.setState({resetSelectedBuyProducts: true})
        var array = []
        this.state.productList.forEach(product => {
            var situacaoParametrizacao = this.state.situacaoParametrizacao === product.situacaoParametrizacao
            if(situacaoParametrizacao) {
                array.push(product)
            }

        })
        this.setState({loading: false})
        this.setState({filteredProductList: array})
    }
    
    search = (showInfoPopUp) => {
        
        const productFilter = {
            codigo: this.state.codigo,
            descricao: this.state.descricao,
            ncm: this.state.ncm,
            tipo: this.state.tipo,
            unidadeComercializada: this.state.unidadeComercializada
        }
        this.setState({loading: true})
        this.setState({resetSelectedBuyProducts: true})
        this.parametrizeService.search(productFilter)
        .then(async response => {
            var productList = response.data
            await this.setState({productList})
            await this.setState({filteredProductList: productList})
            this.searchParametrizeProducts()
            this.filter()
            this.setState({disableDeleteButton: false})
            if(!response.data.length && showInfoPopUp){
                popUp.infoPopUp("Nenhum produto encontrado com os dados informados")
            }
            this.setState({loading: false})
        }).catch(error => {
            HandleErrorService.handleError(error)
            this.setState({loading: false})
            this.setState({disableDeleteButton: false})
        })
        
    }

    completeResetedSelectedBuyProducts = () => {
        this.setState({resetSelectedBuyProducts: false})
    }

    filterParametrizeProducts = () => {
        var array = []
        this.state.parametrizeProductList.forEach(product => {
            var codigo = (!this.state.parametrizeCodigo || product.codigo === this.state.parametrizeCodigo)
            var descricao = (!this.state.parametrizeDescricao || product.descricao.toLowerCase().includes(this.state.parametrizeDescricao.toLocaleLowerCase()))

            if(codigo && descricao) {
                array.push(product)
            }
        })
        this.setState({parametrizeFilteredProductsList: array})
    }

    searchParametrizeProducts = (showInfoPopUp) => {
        const productFilter = {
            codigo: this.state.parametrizeCodigo,
            descricao: this.state.parametrizeDescricao
        }
        this.productService.search(productFilter)
        .then(response => {
            var parametrizeProductList = response.data
            this.setState({parametrizeProductList})
            this.setState({parametrizeFilteredProductsList: parametrizeProductList})
            this.filterParametrizeProducts()
            if(!response.data.length && showInfoPopUp){
                popUp.infoPopUp("Nenhum produto encontrado com os dados informados")
            }
        }).catch(error => {
            HandleErrorService.handleError(error)
        })
        
    }
    deleteMultipleProducts = async (listOfId) => {
        if(listOfId){
            this.setState({loading: true})
            this.setState({disableDeleteButton: true})
            var object = {
                listOfProductsId: listOfId
            }
            await this.parametrizeService.deleteMultipleProducts(object)
            .then( response => {
                popUp.successPopUp("Produto(s) deletado(s) com sucesso")
                this.search()
            }).catch(error => {
                HandleErrorService.handleError(error)
                this.setState({loading: false})
                this.setState({disableDeleteButton: false})
            })
        }
    }

    deparameterize = async (listOfId) => {
        if(listOfId){
            this.setState({loading: true})
            this.setState({disableDeleteButton: true})
            var object = {
                listOfProductsId: listOfId
            }
            await this.parametrizeService.deparameterize(object)
            .then( response => {
                popUp.successPopUp("Produto(s) desparametrizados(s) com sucesso")
                this.search()
                this.setState({situacaoParametrizacao: ConstantsUtil.situacaoParametrizacaAindaNaoParametrizado})
            }).catch(error => {
                HandleErrorService.handleError(error)
                this.setState({loading: false})
                this.setState({disableDeleteButton: false})
            })
        }
    }

    setFilteredProducts = (buyProducts) => {
        this.setState({filteredProductList: buyProducts})
    }

    setParametrizeFilteredProducts = (parametrizeProducts) => {
        this.setState({parametrizeFilteredProductsList: parametrizeProducts})
    }

    resetView = () => {
        this.setState({displayConfirmation: false})
    }

    requestParametrize = (request) => {
        this.setState({requestParametrize: request})
    }

    parametrize = (parametrizeProduct, proportion) => {
        this.parametrizeService.parametrize({
            buyProducts: this.state.filteredProductList,
            parametrizeProduct,
            proportion
        })
        .then(async response => {
            popUp.successPopUp("Parametrização realizada com sucesso")
            await this.generalServices.sleep(500)
            window.location.reload()
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
    }

    markParameterizable = async (listOfId, toMarkAsNonParametrizable) => {
        if(listOfId){
            this.setState({loading: true})
            this.setState({disableDeleteButton: true})
            var object = {
                listOfProductsId: listOfId,
                toMarkAsNonParametrizable: toMarkAsNonParametrizable
            }
            await this.parametrizeService.markParameterizable(object)
            .then( response => {
                popUp.successPopUp("Produto(s) alterados(s) com sucesso")
                this.search()
                this.setState({situacaoParametrizacao: ConstantsUtil.situacaoParametrizacaAindaNaoParametrizado})
            }).catch(error => {
                HandleErrorService.handleError(error)
                this.setState({loading: false})
                this.setState({disableDeleteButton: false})
            })
        }
    }

    render() {

        const parametrizedList = [
	        {label:'Ainda Não Parametrizado', value: ConstantsUtil.situacaoParametrizacaAindaNaoParametrizado},
            {label:'Já Parametrizado', value: ConstantsUtil.situacaoParametrizacaJaParametrizado},
	        {label:'Não Parametrizável', value: ConstantsUtil.situacaoParametrizacaONaoParametrizavel}
        ]

        const renderParametrizeProductTable = () => {
            return (
                <Dialog header="Selecione o Produto de Venda"
                    visible={this.state.requestParametrize}
                    modal
                    style={{ width: '80vw' }}
                    // footer={renderConfirmationFooter()}
                    onHide={() => {
                        this.buyProdutcTableRef.current.cancelParametrize()
                    }
                }
                    >
                    
                    <ParametrizeProductTable
                        list = {this.state.parametrizeFilteredProductsList}
                        search = {this.searchParametrizeProducts}
                        loading = {this.state.loading}
                        setParametrizeFilteredProducts = {this.setParametrizeFilteredProducts}
                        selectedBuyProducts={this.state.filteredProductList}
                        parametrize = {this.parametrize}
                        insideDialog
                    />
                </Dialog>          
            )
        }

        return (
                   
            <div className="bs-docs-section" >
                <Card title = "Parametrização de Produtos">
                    <div className = "col-md-12">
                    <div className="row">
                        <div className = "col-md-5">
                        <FormGroup label = "Situação do produto de compra " htmlFor = "InputParametrized">
                        <SelectMenu
                            className={"form-control " }
                            name="situacaoParametrizacao"
                            list= {parametrizedList} 
                            value={this.state.situacaoParametrizacao}
                            onChange={this.handleChange}
                        />
                        </FormGroup>
                        </div>
                        </div>
                    </div>
                    <div className="bs-docs-section" >
                        <BuyProductCrudTable
                            ref={this.buyProdutcTableRef}
                            list = {this.state.filteredProductList}
                            deleteButton = {this.askForDeleteEntry}
                            deleteMultiple = {this.deleteMultipleProducts}
                            deparameterize = {this.deparameterize}
                            setFilteredProducts = {this.setFilteredProducts}
                            situacaoParametrizacao = {this.state.situacaoParametrizacao}
                            search = {this.search}
                            resetSelectedProducts = {this.state.resetSelectedBuyProducts}
                            completeResetedSelectedBuyProducts = {this.completeResetedSelectedBuyProducts}
                            loading = {this.state.loading}
                            disableDeleteButton = {this.state.disableDeleteButton}
                            push = {this.props.push}
                            requestParametrize={this.requestParametrize}
                            markParameterizable={this.markParameterizable}
                        />

                        <br />
                        {renderParametrizeProductTable()}
                    </div>
                </Card>
                <div className="d-flex "/>
            </div>
              
        )
    }


}

export default ParametrizeProduct