import React from 'react'
import { withRouter } from 'react-router-dom'

import Card from '../../components/card'
import { AuthContext } from '../../main/authProvider'
import GeneralServices from '../../app/service/generalServices'
import HandleErrorService from '../../app/service/handleErrorService'
import { Button } from 'primereact/button'

import * as popUp from '../../components/toastr'
import InputChooseContaBancaria from '../../components/conciliacaoBancaria/inputChooseContaBancaria'
import FichaBancariaService from '../../app/service/conciliacaoBancaria/fichaBancariaService'
import FichaBancariaTable from '../../components/conciliacaoBancaria/fichaBancariaTable'
import DateIntervalCalendar from '../../components/calendar/dateIntervalCalendar'
import SelectMenu from '../../components/selectMenu'
import FormGroup from '../../components/form-group'
import ConstantsUtil from '../../context/constantsUtil'
import InputChooseTipoDeRecebimento from '../../components/tipoDeRecebimento/inputChooseTipoDeRecebimento'
import InputChooseTipoDePagamento from '../../components/contasAPagar/inputChooseTipoDePagamento'
import LancarFechamentoBancarioService from '../../app/service/conciliacaoBancaria/lancarFechamentoBancarioService'
import TiposDeRecebimentoService from '../../app/service/tiposDeRecebimentoService'
import TipoDePagamentoService from '../../app/service/contasAPagar/tipoDePagamentoService'
import { Dialog } from 'primereact/dialog'
import DialogFooter from '../../components/dialogFooter'
import { InputNumber } from 'primereact/inputnumber'

class FichaBancaria extends React.Component {

    constructor(){
        super()
        this.fichaBancariaService = new FichaBancariaService()
        this.lancarFechamentoBancarioService = new LancarFechamentoBancarioService()
        this.tiposDeRecebimentoService = new TiposDeRecebimentoService()
        this.tipoDePagamentoService = new TipoDePagamentoService()
        this.generalServices = new GeneralServices()

        this.inputTipoDeRecebimentoRef = React.createRef()
    }

    state = {

        selectedContaBancaria: '',
        selectedContaBancariaLabel: '',
        inputContaBancariaErrorClass:'',
        errorContaBancariaMessage:'Selecione uma Conta Bancária',
        // loadingContaBancariaTable: false,

        selectedFilterType: '',
        missingFilterType: false,
        missingAnyDate: false,
        // inputFilterErrorClass: '',
        // errorFilterErrorMessgae: '',

        initialDate: '',
        initialDateView: '',
        
        finalDate: '',
        finalDateView: '',

        tipoDeLancamento: '',

        tipoDeRecebimentoList: [],
        selectedTipoDeRecebimento: '',
        selectedTipoDeRecebimentoLabel: '',
        filteredTipoDeRecebimentoList: [],
        loadingTiposDeRecebimentoTable: false,
        forceShowTipoDeRecebimentoDialog: false,

        tipoDePagamentoList: [],
        selectedTipoDePagamento: '',
        selectedTipoDePagamentoLabel: '',
        loadingTipoDePagamentoTable: false,
        forceShowTipoDePagamentoListInDialog: false,

        fichaBancaria: [],
        loadingTable: false,
        isSearchingFichaBancaria: false,
        isFinishedSearch: false,

        habilitaBusca: true,

        pesquisaValorDialogVisible: false,
        searchValue: null,
        isSearchByValue: false,
        inputSearchValueErrorClass: '',
        errorSearchValueMessage: '',
    }

    componentDidMount(){
        this.searchTiposDeRecebimento()
    }

    searchTiposDeRecebimento = () => {
        this.setState({loadingTiposDeRecebimentoTable: true})
        this.tiposDeRecebimentoService.search()
        .then(response => {
            this.setState({tipoDeRecebimentoList: response.data})
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
        .finally(() => {
            this.setState({loadingTiposDeRecebimentoTable: false})
        })
    }

    filterTipoDeRecebimento = async () => {
        //Mostra ao usuário apenas os tipos de recebimento associados à Conta Bancária selecionada.

        var filteredList = this.tiposDeRecebimentoService.filterByContaBancaria(this.state.tipoDeRecebimentoList, this.state.selectedContaBancaria)

        await this.setState({filteredTipoDeRecebimentoList: JSON.parse(JSON.stringify(filteredList)) })

    }

    searchTiposDePagamento = () => {
        this.searchTiposDePagamentoByContaBancariaId(this.state.selectedContaBancaria.id)
    }

    searchTiposDePagamentoByContaBancariaId = (idContaBancaria) => {
        // Faz Busca por conta Bancária com o objetivo de obter todos os tipos de pagamento que não estão associados a nenhuma conta bancária,
        // além de trazer os tipos de pagamento de aplicação financeira que estão associados à conta bancária selecionada  

        this.setState({loadingTipoDePagamentoTable: true})

        this.tipoDePagamentoService.searchByContaBancariaId(idContaBancaria, false)
        .then(response => {
            this.setState({tipoDePagamentoList: response.data})
            this.setState({forceShowTipoDePagamentoListInDialog: true})
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
        .finally(() => {
            this.setState({loadingTipoDePagamentoTable: false})
        })
    }

    handleTipoDeLancamentoChange = (event) => {
        const value = event.target.value
        const name = event.target.name
        this.setState({ [name]: value })

        this.setState({selectedTipoDeRecebimento: ''})
        this.setState({selectedTipoDeRecebimentoLabel: ''})
        this.setState({selectedTipoDePagamento: ''})
        this.setState({selectedTipoDePagamentoLabel: ''})
    }

    handleSelectionContaBancariaChange = async (selectedContaBancaria, selectedContaBancariaLabel) => {
        await this.setState({selectedContaBancaria})
        await this.setState({selectedContaBancariaLabel})

        await this.setState({selectedTipoDeRecebimento: ''})
        await this.setState({selectedTipoDeRecebimentoLabel: ''})
        // await this.setState({selectedContaContabil: ''})
        // await this.setState({selectedContaContabilLabel: ''})
        // await this.setState({historicoAvulso: ''})

        /*
            Quando mudar conta bancária, deve limpar o tipo de pagamento selecionado caso ele esteja vinculado a uma
            conta bancária específica.
        */
        if(this.state.selectedTipoDePagamento && this.state.selectedTipoDePagamento.idContaBancaria) {
            await this.setState({selectedTipoDePagamento: ''})
            await this.setState({selectedTipoDePagamentoLabel: ''})
        }
        

        if(this.isAplicacaoFinanceira()){
            const event = {target: {name: 'tipoDeLancamento', value: this.lancarFechamentoBancarioService.tipoDeLancamentoList[1].value}}
            this.handleTipoDeLancamentoChange(event)
            const tipoDeRecebimento = this.state.tipoDeRecebimentoList.find(item => item.nomeTipoDeRecebimento === ConstantsUtil.nomeTipoDeRecebimentoRendimentoAplicacaoAutomatica)
            await this.generalServices.sleep(100)
            this.inputTipoDeRecebimentoRef.current.handleSelectionTipoDeRecebimentoChange(tipoDeRecebimento)
        }
        else{
            this.searchTiposDePagamento()
        }

        // this.filterTipoDeRecebimento()
        

        var select = document.getElementById(ConstantsUtil.componenteEscolherPeriodoId)
        select.focus();

    }

    handleSelectionTipoDeRecebimentoChange = async (tipoDeRecebimento, label) => {

        await this.setState({selectedTipoDeRecebimento: tipoDeRecebimento})
        await this.setState({selectedTipoDeRecebimentoLabel: label})

        this.hideTipoDeRecebimentoDialog()
    }

    hideTipoDeRecebimentoDialog = () => {
        this.setState({forceShowTipoDeRecebimentoDialog: false})
    }

    handleSelectionTipoDePagamentoChange = async (tipoDePagamento, label) => {
        await this.setState({selectedTipoDePagamento: tipoDePagamento})
        await this.setState({selectedTipoDePagamentoLabel: label})

        this.hideTipoDePagamentoDialog()

    }

    hideTipoDePagamentoDialog = () => {
        this.setState({forceShowTipoDePagamentoDialog: false})
    }

    handleDateChange = async (initialDate, finalDate) => {
        
        await this.setState({initialDate})
        await this.setState({initialDateView: GeneralServices.convertBrStringToJsDate(initialDate)})

        await this.setState({finalDate})
        await this.setState({finalDateView: GeneralServices.convertBrStringToJsDate(finalDate)})

    }

    handleFilterChange = (selectedFilterType) => {
        this.setState({selectedFilterType})
    }

    

    checkData = () => {
        
        var check = true;

        if(!this.state.selectedContaBancaria){
            this.setState({inputContaBancariaErrorClass: ConstantsUtil.bootstrapInputErrorClass})
            check = false
        }

        if(!this.state.selectedFilterType){
            this.setState({missingFilterType: true})
            check=false
        }
        
        if(!this.state.initialDate || !this.state.finalDate){
            this.setState({missingAnyDate: true})
            check=false
        }

        if(this.state.initialDate && this.state.finalDate){
            if(this.state.initialDateView > this.state.finalDateView){
                check = false
                popUp.infoPopUp(ConstantsUtil.dataInicialAnteriorFinalMessage)
           }
        }

        return check;
    }

    toggleButton = () => {
        this.setState({habilitaBusca: !this.state.habilitaBusca})
        this.setState({isSearchByValue: false})
        this.setState({searchValue: null})
    }

    desabilitaBusca = () => {
        this.setState({habilitaBusca: false})
    }

    isAplicacaoFinanceira = () => {
        return this.state.selectedContaBancaria.aplicacaoFinanceira
    }
    
    resetView = () => {
        this.setState({inputContaBancariaErrorClass: ""})
        this.setState({inputContaBancariaErrorClass:''})

        this.setState({missingFilterType: false})
        this.setState({missingAnyDate: false})
        
    }

    callSearch = () => {
        this.resetView()
        if(this.checkData()){
            // this.toggleButton()
            this.search()
        }
    }

    montaObjToSearchFichaBancaria = () => {
        let obj =
        {
            contaBancariaId: this.isAplicacaoFinanceira() ? null : this.state.selectedContaBancaria.id,
            aplicacaoFinanceiraId: this.isAplicacaoFinanceira() ? this.state.selectedContaBancaria.id : null,
            dataInicial: this.state.initialDate,
            dataFinal: this.state.finalDate,
            tipoDeLancamentoBancario: this.isAplicacaoFinanceira() ? ConstantsUtil.lancamentoBancarioRecebimentoAplicacaoFinanceiraLabel : this.state.tipoDeLancamento,
            idTipoDeRecebimento: this.state.selectedTipoDeRecebimento.id,
            idTipoDePagamento: this.state.selectedTipoDePagamento.id
        }

        obj = GeneralServices.defineFiltroTipoLancamentoFichaBancaria(obj, this.isAplicacaoFinanceira, this.state.tipoDeLancamento)

        return obj
    }

    search = () => {

        this.setState({loadingTable: true})
        this.setState({isFinishedSearch: false})
        this.setState({isSearchingFichaBancaria: true})
        
        this.fichaBancariaService.getFichaBancariaByDateInterval(this.montaObjToSearchFichaBancaria())
        .then(this.handleSearchResponse)
        .catch(this.handleSearchError)
    }

    handleSearchResponse = async response => {
        await this.setState({fichaBancaria: response.data})
        this.setState({loadingTable: false})
        this.setState({isFinishedSearch: true})
        this.setState({isSearchingFichaBancaria: false})
        this.desabilitaBusca()

        if(this.state.isSearchByValue){
            this.hidePesquisaValorDialog()
        }
    }

    handleSearchError = error => {
        HandleErrorService.handleError(error)
        this.setState({loadingTable: false})
        this.setState({isFinishedSearch: true})
        this.setState({isSearchingFichaBancaria: false})
    }

    searchByValue = () => {
        this.setState({searchValue: null})
        this.setState({pesquisaValorDialogVisible: true})
    }
    
    hidePesquisaValorDialog = () => {
        this.setState({pesquisaValorDialogVisible: false})
        this.setState({inputSearchValueErrorClass: ''})
        this.setState({errorSearchValueMessage: ''})
    }

    handleSearchValueChange = (event) => {
        let value = event.value
        if(value === 0){
            value = null
        }
        const name = event.target.name
        this.setState({ [name]: value })
    }

    handleSearchValueKeyUp = async (e) => {
        /*
            A função é chamada no mesmo instante que a handleSearchValueChange.
            Precisa esperar um tempo de forma que o valor seja alterado.
        */
        await this.generalServices.sleep(1)
        
        //it triggers by pressing the enter key
        if (e.key === "Enter") {
            this.callSearchPesquisaValor()
        }
    }

    callSearchPesquisaValor = () => {
        this.setState({inputSearchValueErrorClass: ''})
        this.setState({errorSearchValueMessage: ''})
        if(this.checkPesquisaValorData()){
            this.searchPesquisaValor()
        }
    }

    checkPesquisaValorData = () => {
        var check = true;

        if(!this.state.searchValue){
            this.setState({inputSearchValueErrorClass: ConstantsUtil.primereactInputErrorClass})
            this.setState({errorSearchValueMessage: "Informe o Valor"})
            check = false
        }

        return check
    }

    searchPesquisaValor = () => {
        // this.setState({isFinishedSearch: false})
        this.setState({isSearchByValue: true})
        this.setState({loadingTable: true})
        this.fichaBancariaService.getFichaBancariaByValue(this.state.searchValue)
        .then(this.handleSearchResponse)
        .catch(this.handleSearchError)
    }

    searchFromFichaBancariaTable = () => {
        if(this.state.isSearchByValue){
            return this.searchPesquisaValor()
        }
        
        return this.search()
    }

    render() {


        const renderFichaBancariaTable = () => {
            if( (this.state.isSearchingFichaBancaria || this.state.isFinishedSearch) && !this.state.habilitaBusca ){
                return (
                    <FichaBancariaTable
                        fichaBancaria={this.state.fichaBancaria}
                        isSearchByValue={this.state.isSearchByValue}
                        tipoDeRecebimentoList={this.state.filteredTipoDeRecebimentoList}
                        search={this.searchFromFichaBancariaTable}
                        loading={this.state.loadingTable}
                        push={this.props.history.push}
                    />
                )
            }

            if(this.state.isSearchingFichaBancaria){
                return (
                    <FichaBancariaTable
                        fichaBancaria={[]}
                        // search={this.search}
                        loading={true}
                        // push={this.props.history.push}
                    />
                )
            }
        }

        const renderButtons = () => {

            if(this.state.isSearchingFichaBancaria){
                return (
                    <Button 
                        label="Buscando ..."
                        icon="pi pi-search"
                        disabled
                    />
                )
            }

            if(this.state.habilitaBusca){
                return (
                    <Button 
                        label="Buscar Lançamentos"
                        icon="pi pi-search"
                        onClick = {this.callSearch}
                        // style={ {maxHeight: '35px' } }
                    />
                )
            }
            return (
                <>
                    <Button 
                        label="Voltar"
                        icon="pi pi-undo"
                        className="p-button-danger"
                        onClick = {this.toggleButton}
                        // style={ {maxHeight: '35px' } }
                        // disabled = {this.state.isSearchingLancamentos}
                    />
                </>
            )
        }

        const renderTipoDeLancamento = () => {
            if(this.state.selectedContaBancaria)
            return (
                <>
                    <div className="col-md-2">
                        <FormGroup label = "Tipo de Lançamento (Opcional)" htmlFor = "InputTipoLancamento">
                            <SelectMenu 
                            id="selectTipoLancamento"
                            className={"form-control " + this.state.inputTipoDeLancamentoErrorClass}
                            // ref={this.tipoDeLancamentoInputRef}
                            name="tipoDeLancamento"
                            list={this.lancarFechamentoBancarioService.tipoDeLancamentoList} 
                            value={this.state.tipoDeLancamento}
                            onChange={this.handleTipoDeLancamentoChange}
                            disabled={!this.state.habilitaBusca || this.isAplicacaoFinanceira()}
                            /> 
                            {/* <div className="invalid-feedback">{this.state.errorTipoDeLancamentoMessage}</div>   */}
                        </FormGroup> 
                    </div>
                </>
            )
        }

        const renderTipoDeRecebimentoInput = () => {
            if(this.state.tipoDeLancamento === ConstantsUtil.lancamentoBancarioRecebimentoLabel)
            return (
                <>
                   <InputChooseTipoDeRecebimento
                        ref={this.inputTipoDeRecebimentoRef}
                        optional
                        selectedTipoDeRecebimentoLabel={this.state.selectedTipoDeRecebimentoLabel}
                        renderDialogOnMount={!this.isAplicacaoFinanceira()}
                        forceShowDialog={this.state.forceShowTipoDeRecebimentoDialog}
                        hideDialog={this.hideTipoDeRecebimentoDialog}
                        tipoDeRecebimentoList={this.state.filteredTipoDeRecebimentoList}
                        push={this.props.history.push}
                        handleSelectionTipoDeRecebimentoChange={this.handleSelectionTipoDeRecebimentoChange}
                        inputTipoDeRecebimentoErrorClass={this.state.inputTipoDeRecebimentoErrorClass}
                        disabled={!this.state.habilitaBusca || this.isAplicacaoFinanceira()}
                    />
                </>
                    
            )
        }

        const renderTipoDePagamentoInput = () => {
            if(this.state.tipoDeLancamento === ConstantsUtil.lancamentoBancarioPagamentoLabel)
            return (
                <>
                   <InputChooseTipoDePagamento
                        selectedTipoDePagamentoLabel={this.state.selectedTipoDePagamentoLabel}
                        optional
                        renderDialogOnMount
                        search={this.searchTiposDePagamento}
                        forceShowDialog={this.state.forceShowTipoDePagamentoDialog}
                        hideDialog={this.hideTipoDePagamentoDialog}
                        tipoDePagamentoList={this.state.tipoDePagamentoList}
                        forceUpdate={this.state.forceShowTipoDePagamentoListInDialog}
                        doneForceUpdate={() => this.setState({forceShowTipoDePagamentoListInDialog: false})}
                        push={this.props.history.push}
                        handleSelectionTipoDePagamentoChange={this.handleSelectionTipoDePagamentoChange}
                        inputTipoDePagamentoErrorClass={this.state.inputTipoDePagamentoErrorClass}
                        disabled={!this.state.habilitaBusca}
                    />
                </>
                    
            )
        }

        const renderPesquisaValorButton = () => {
            return (
                <Button 
                    label="Pesquisar Valor"
                    icon="pi pi-search"
                    onClick = {this.searchByValue}
                    disabled={!this.state.habilitaBusca}
                />
            )
        }
        
        const dialogFooter = (
            <DialogFooter save = {this.callSearchPesquisaValor} hideDialog = {this.hidePesquisaValorDialog}/>
        )

        const renderPesquisaValorDialog = () => {
            return (
                <Dialog
                    header={"Pesquisar Por Valor"}
                    visible={this.state.pesquisaValorDialogVisible}
                    modal = {true} //congela restante da tela
                    style={{ width: '350px' }}
                    footer={dialogFooter}
                    onHide={() => this.setState({pesquisaValorDialogVisible: false})}
                >
                    <div className="p-field">
                        {renderValueInput(true)}                     
                    </div>
                </Dialog>
            )
        }

        const renderStandardInputs = () => {
            return (
                <>
                <div className="row">

                <div className = "col-md-4">
                    <InputChooseContaBancaria
                        label="Conta Bancária / Aplicação Financeira"
                        readOnly
                        // excludeAplicacaoFinanceira
                        noHTMLFormat
                        selectedContaBancariaLabel={this.state.selectedContaBancariaLabel}
                        handleSelectionContaBancariaChange={this.handleSelectionContaBancariaChange}
                        inputContaBancariaErrorClass={this.state.inputContaBancariaErrorClass}
                        errorContaBancariaMessage={this.state.errorContaBancariaMessage}
                        disabled={!this.state.habilitaBusca}
                        push={this.props.history.push}
                        /> 
                </div>

                <DateIntervalCalendar
                    htmlFor="SelectDatePeriod"
                    label="Escolher período"
                    handleFilterChange={this.handleFilterChange}
                    handleDateChange={this.handleDateChange}
                    missingFilterType={this.state.missingFilterType}
                    missingAnyDate={this.state.missingAnyDate}
                    disabled={!this.state.habilitaBusca}
                />

                    

                </div>

                <br />

                <div className="row">
                {renderTipoDeLancamento()}

                {renderTipoDeRecebimentoInput()}

                {renderTipoDePagamentoInput()}

                </div>                    
                </>
            )
        }


        const renderValueInput = (insideDialog) => {
            return (
                <>
                <div className="row">
                <div className="col-md-2">
                <label htmlFor="valor">Valor</label>
                <InputNumber
                    ref={this.inputValorRef}
                    className={this.state.inputSearchValueErrorClass}
                    name="searchValue"
                    inputId="valorPesquisa"
                    autoFocus
                    value={this.state.searchValue}
                    onValueChange={this.handleSearchValueChange}
                    onKeyDown={this.handleSearchValueKeyUp}
                    // mode="currency" currency="BRL" locale="pt-BR"
                    mode="decimal" locale="pt-BR" minFractionDigits={2}
                    disabled={!insideDialog && !this.state.habilitaBusca}
                />
                </div>
                </div>
                <div className="row">
                <div className="col-md-12">
                <small id="valorError" className="p-error">{this.state.errorSearchValueMessage}</small>
                </div>
                </div>
                </>
            )
        }

        const renderInputs = () => {
            if(!this.state.isSearchByValue){
                return renderStandardInputs()
            }
            return renderValueInput(false)
        }

        return (
            <div className="bs-docs-section" >
            <Card   
                title = "Ficha Bancária"
                rightComponent={renderPesquisaValorButton()}
            >
                <div className = "col-md-12">

                    {renderInputs()}

                    <br />
                        
                    {renderButtons()}

                    <br />

                    <br />

                    {renderFichaBancariaTable()}
                    
                    {renderPesquisaValorDialog()}

                </div>
            </Card>
            <div className="d-flex "/>
        </div>     
        )
    }

}


FichaBancaria.contextType = AuthContext

export default withRouter(FichaBancaria)