import React from 'react'
import FornecedorService from '../../app/service/contasAPagar/fornecedorService'
import HandleErrorService from '../../app/service/handleErrorService'
import ChooseFornecedorDialog from './chooseFornecedorDialog'

class InputChooseFornecedor extends React.Component {

    constructor(){
        super()
        this.fornecedorService = new FornecedorService()
    }

    state = {

        fornecedorList: [],
        chooseFornecedorDialogVisible: false,
        loading: false,
        forceUpdateFornecedorDialog: false,
        
    }

    componentDidMount(){
        this.searchFornecedores()
    }

    hideDialog = () => {
        this.setState({chooseFornecedorDialogVisible: false})
        
        if(this.props.doNotForceFornecedorDialog)
            this.props.doNotForceFornecedorDialog()
    }

    handleChange = (value) => {
        const fornecedor = value
        this.props.handleSelectionFornecedorChange(fornecedor)
    }

    handleNewFornecedorCadastrado = () => {
        this.hideDialog()
        if(this.props.handleNewFornecedorCadastrado){
            this.props.handleNewFornecedorCadastrado()
        }
    }

    searchFornecedores = () => {
        this.setState({loading: true})
        this.fornecedorService.search(true)
        .then(response => {
            this.setState({fornecedorList: response.data})
            this.checkById()
            if(this.props.autofocus){
                this.inputClick()
            }
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
        .finally(() => {
            this.setState({forceUpdateFornecedorDialog: true})
            this.setState({loading: false})
        })
    }

    doneForceUpdate = () => {
        this.setState({forceUpdateFornecedorDialog: false})
    }

    checkById = () => {
        if(this.props.searchFornecedorById){
            const id = this.props.fornecedorId
            const fornecedor = this.state.fornecedorList.find(fornecedor => fornecedor.id === id)
            this.handleChange(fornecedor)
        }
    }

    handleKeyPress = async (e) => {
        if (e.key === "Enter") {
            this.inputClick()
          }
    }

    inputClick = () => {
        this.setState({chooseFornecedorDialogVisible: true})
    }

    render(){

        const renderInput = () => {
            // console.log("this.props.inputFornecedorErrorClass: ", this.props.inputFornecedorErrorClass)
            return (
                <>
                <label htmlFor="fornecedor">Fornecedor</label>
                    <input style={{color: '#000'}} type="text"
                        className={"form-control " + this.props.inputFornecedorErrorClass}
                        readOnly
                        value = {this.props.selectedFornecedorLabel}
                        name="selectedFornecedorLabel"
                        id="selectedFornecedor"
                        placeholder="Nenhum Fornecedor Selecionado..."
                        onClick={this.inputClick}
                        onKeyPress={this.handleKeyPress}
                        disabled={this.props.disabled}
                    />
                    <div className="invalid-feedback">Selecione o fornecedor</div>
                </>
            )
        }

        const renderHTMLFormat = () => {
            if(this.props.noHTMLFormat){
                return (
                    <>
                    {renderInput()}
                    </>
                )
            }
            else {
                return (
                    <>
                    <div className="row">
                    <div className="col-md-4">
                    {renderInput()}
                    </div>
                    </div>
                    </>
                )
            }
        }

        return(
            <>
            
                {renderHTMLFormat()}
                
                <ChooseFornecedorDialog 
                    header="Fornecedores"
                    visible={this.state.chooseFornecedorDialogVisible || this.props.forceShowDialog}
                    list={this.state.fornecedorList}
                    search={this.searchFornecedores}
                    hideDialog={this.hideDialog}
                    handleSelectionFornecedorChange={this.handleChange}
                    loading={this.state.loading}
                    forceUpdate={this.state.forceUpdateFornecedorDialog}
                    doneForceUpdate={this.doneForceUpdate}
                    handleNewFornecedorCadastrado={this.handleNewFornecedorCadastrado}
                />

            </>
        )

    }


}

export default InputChooseFornecedor