import { Dialog } from 'primereact/dialog'
import React from 'react'
import DialogFooter from '../dialogFooter'
import PlanoDeContasTable from './planoDeContasTable'

import * as popUp from './../toastr'
import PlanoDeContasService from '../../app/service/planoDeContasService'
import PlanoDeContasSearchTable from './planoDeContasSearchTable'

class ChooseContaContabilDialog extends React.Component{

    constructor(){
        super();
        this.planoDeContasService = new PlanoDeContasService()
    }

    state = {

        selectedContaContabil: '',
        planoDeContasList: [],

        didUpdated: false,

    }

    componentDidUpdate(){
        this.setStates()
    }

    setStates = () => {
        if( (this.props.visible && !this.state.didUpdated) || this.props.forceUpdate ){
        this.setState({planoDeContasList: this.props.list})

            
        this.setState({didUpdated: true})

        if(this.props.doneForceUpdate){
            this.props.doneForceUpdate()
        }

        }
    }

    // handleChange = async (event) => {
    //     const value = event.target.value
    //     const name = event.target.name
    //     await this.setState({ [name]: value })
    //     this.filter()
    // }

    handleKeyPress = async (filteredList) => {
        // if (e.key === "Enter") {
        //     const filteredList = JSON.parse(JSON.stringify(this.state.filteredPlanoDeContasList))
            if(filteredList.length === 1){
                await this.setState({selectedContaContabil: filteredList[0]})
                this.callSave()
            }
        //   }
    }

    // filter = () => {
        
    //     this.setState({
    //         filteredPlanoDeContasList:
    //             JSON.parse(
    //                 JSON.stringify(
    //                     this.state.planoDeContasList.filter(contaContabil => {
    //                         return (
    //                             contaContabil.nomeConta.toLowerCase().includes(this.state.searchText.toLowerCase())
    //                             ||
    //                             ((contaContabil.numeroDeChamada).toString().includes(this.state.searchText))
    //                         ) 
    //                     }
    //                     )
    //                 )
    //             )
    //     })
    // }

    handleSelectionContaContabilChange = (event) => {
        if(event.value === null){ //significa que clicou em cima da Conta Contábil que estava selecionada antes
            this.callSave()
        } else{
            this.setState({selectedContaContabil: event.value})
        }
    }

    hideDialog = async () => {
        this.props.fastHideDialog()
        this.props.hideDialog()
        this.setState({didUpdated: false})
    }

    checkIfSelectedContaContabilIsAnalitica = () => {
        if(this.state.selectedContaContabil.tipoContaContabil === this.planoDeContasService.tipoContaContabilSinteticaLabel){
            this.setState({selectedContaContabil: ''})
            popUp.infoPopUp("Selecione uma conta Analítica")
            return false
        }
        return true;
    }

    callSave = async () => {
        if(this.checkIfSelectedContaContabilIsAnalitica()){
            await this.props.handleSelectionContaContabilChange(this.state.selectedContaContabil)
            this.props.fastHideDialog()
            this.hideDialog()
        }
    }

    render () {

        const chooseContaContabilDialogFooter = (
            <DialogFooter save = {this.callSave} hideDialog = {this.hideDialog} disabled={this.props.disabled}/>
        )

        return (
            <>
            <Dialog 
                    visible={this.props.visible}
                    style={{ width: '70vw' }}
                    header={this.props.header}
                    modal
                    className="p-fluid"
                    footer={chooseContaContabilDialogFooter}
                    onHide={this.hideDialog}
            >

                {/* <div className="p-field">
                    <label htmlFor="search conta">Busca</label>
                    <input style={{color: '#000'}}
                        autoFocus   
                        className={"form-control " }
                        type="text"
                        id="searchText"
                        name="searchText"
                        value={this.state.searchText}
                        placeholder="Pesquise pelo nome ou pelo número de chamada"
                        onChange={this.handleChange}
                        onKeyPress={this.handleKeyPress}
                    />
                </div>

                <br />

                <PlanoDeContasTable
                    // list={this.state.planoDeContasList}
                    list={this.state.filteredPlanoDeContasList}
                    notFilteredList={this.state.planoDeContasList}
                    selectedConta={this.state.selectedContaContabil}
                    handleSelectionChange={this.handleSelectionContaContabilChange}
                    // search={this.search}
                    loading={this.props.loadingTable}
                    nomeContaSearch={this.state.searchText}
                    // lazy
                /> */}

                <PlanoDeContasSearchTable 
                    // planoDeContasList={this.state.planoDeContasList}
                    planoDeContasList={this.props.list}
                    selectedContaContabil={this.state.selectedContaContabil}
                    handleSelectionContaContabilChange={this.handleSelectionContaContabilChange}
                    loading={this.props.loadingTable}
                    handleKeyPress={this.handleKeyPress}
                />


            </Dialog>
            </>
        )
    }


}

export default ChooseContaContabilDialog