import React from 'react'
import { Toast } from 'primereact/toast'
import { Button } from 'primereact/button'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import EditFornecedorDialog from './editFornecedorDialog'
import Highlighter from 'react-highlight-words'
import { Dialog } from 'primereact/dialog'
import * as popUp from '../toastr'
import FornecedorService from '../../app/service/contasAPagar/fornecedorService'
import HandleErrorService from '../../app/service/handleErrorService'
import CustomHighlighter from '../customHighlighter'
import classNames from 'classnames'
import fornecedores from '../../views/fornecedores'
import TooltipButton from '../tooltipButton'

class FornecedoresTable extends React.Component {

    constructor(){
        super()
        this.fornecedorService = new FornecedorService()
    }

    state = {

        fornecedorToEdit: '',
        selectedFornecedor: null,

        fornecedorToDelete: '',

        ediFornecedorDialog: false,
        
    }

    editFornecedor = (fornecedor) => {
        this.setState({fornecedorToEdit: JSON.parse(JSON.stringify(fornecedor))})

        this.setState({ediFornecedorDialog: true})
    }

    reativar = (fornecedor) => {
        this.fornecedorService.reativar(fornecedor)
        .then(respose => {
            popUp.successPopUp("Fornecedor reativado com sucesso!")
            this.props.search()
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
    }

    hideDialog = () => {
        this.setState({ediFornecedorDialog: false})
    }

    callDelete = (fornecedorToDelete) => {
        this.setState({fornecedorToDelete})
        this.setState({displayDeleteConfirmation: true})
    }

    hideDeleteDialog = () => {
        this.setState({fornecedorToDelete: null})
        this.setState({displayDeleteConfirmation: false})
    }

    delete = () => {
        this.fornecedorService.deleteFornecedor(this.state.fornecedorToDelete)
        .then(async response => {
            popUp.successPopUp("Fornecedor deletado com sucesso!")
            this.props.search()
            this.hideDeleteDialog()
        }).catch(async error => {
            HandleErrorService.handleError(error)
        })
    }

    handleSelectionChange = (event) => {
        this.setState({selectedFornecedor: event.value})
        this.props.handleSelectionFornecedorChange(event.value)
    }

    render() {

        const actionBody = (fornecedor) => {
            if(fornecedor.ativo){
                return (
                    <React.Fragment>
                        <TooltipButton
                            tooltip="Editar Fornecedor"
                            icon="pi pi-pencil"
                            onClick={() => this.editFornecedor(fornecedor)}
                            style={ {maxHeight: '30px', maxWidth: '30px'} }
                        />
                         <TooltipButton 
                            tooltip="Deletar Fornecedor"
                            icon="pi pi-trash"
                            className={ "p-button-rounded p-mr-2 p-button-danger" }
                            style ={{marginLeft: '10px', maxHeight: '30px', maxWidth: '30px'}}
                            onClick={() => this.callDelete(fornecedor)}
                        />
                    </React.Fragment>
                );
            }
            else{
                return (
                    <React.Fragment>
                        <TooltipButton
                            tooltip="Reativar Fornecedor"
                            icon="pi pi-undo"
                            onClick={() => this.reativar(fornecedor)}
                            style={ {maxHeight: '30px', maxWidth: '30px'} }
                        />
                    </React.Fragment>
                );
            }
        }

        const renderSelectionColumn = () => {
            if(this.props.selectable){
                return(
                    <Column selectionMode="single" headerStyle={{ width: '3rem' }}></Column>
                )
            }
        }

       const renderEditColumn = () => {
            if(!this.props.selectable){
                return(
                    <Column header="Ações" body={actionBody} style ={ {width: '140px'} }></Column>
                )
            }
        }

        const renderDeleteConfirmationFooter = () => {
            return (
                <div>
                    <Button label="Confirmar" icon="pi pi-check"
                        // onClick={this.deleteLancamentoFechamentoBancario} 
                        onClick={this.delete} 
                        autoFocus
                    />
                    <Button label="Cancelar" icon="pi pi-times" onClick={this.hideDeleteDialog}
                            className="p-button-text" />
                </div>
            );
        }

        const renderStyledSituacao = (rowData) => {

            const classes = classNames({
                'superavitValue': rowData.ativo,
                'deficitValue': !rowData.ativo
            });
    
            return (
                <div className={classes}>
                    {rowData.ativo ? 'ATIVO' : 'INATIVO'}
                </div>
            );
        }        

        return (
            <div className="datatable-crud-demo">
            <Toast ref={this.toast} />

            <div className="card">
                {/* <Toolbar className="p-mb-4" left={leftToolbarTemplate} 
                // right={rightToolbarTemplate}
                /> */}

                <DataTable
                    ref={this.dt}
                    value={this.props.list}
                    className="p-datatable-sm small-font"
                    rowHover
                    emptyMessage="Não há fornecedores cadastrados."
                    selection={this.state.selectedFornecedor}
                    onSelectionChange={this.handleSelectionChange}
                    // scrollable
                    // scrollHeight="500px"
                    loading={this.props.loading}
                    dataKey="id"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    paginator rows={30} rowsPerPageOptions={[30, 100, 400]}
                    currentPageReportTemplate="Mostrando de {first} ao {last} de {totalRecords} fornecedores"
                >

                    {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column> */}

                    {renderSelectionColumn()}

                    <Column field="nome" header="Nome" sortable
                        body={rowData => 
                            <CustomHighlighter
                                searchWords={[this.props.nome]}
                                textToHighlight={rowData.nome}
                            />
                        }
                    />

                    <Column field="cnpj" header="CNPJ" sortable
                        body={rowData => 
                            <CustomHighlighter
                                searchWords={[this.props.cnpj]}
                                textToHighlight={rowData.cnpj}
                            />
                        }
                    />

                    <Column field="tipoFornecedor" header="Tipo de Fornecedor" sortable 
                    />

                    <Column field="tipoCadastro" header="Tipo de Cadastro" sortable 
                    />                    

                    <Column field="ativo" header="Situação" sortable
                        body={renderStyledSituacao}
                    />                    

                    {renderEditColumn()}

                </DataTable>
            </div>

            <EditFornecedorDialog  
                hideDialog={this.hideDialog}
                visible={this.state.ediFornecedorDialog}
                header="Editar Fornecedor"
                state={this.state.fornecedorToEdit}
                search={this.props.search}
                />

            <Dialog
                header="Deletar Fornecedor"
                visible={this.state.displayDeleteConfirmation}
                modal = {true} //congela restante da tela
                style={{ width: '350px' }}
                footer={renderDeleteConfirmationFooter()}
                onHide={this.hideDeleteDialog}
                >
                <div className="confirmation-content row" style={{marginLeft: '10px'}}>
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem', marginRight: '10px'}} />
                <div style={{marginBottom: '10px'}}> Deseja confirmar? </div>
                </div>
            </Dialog>

            </div>
        )
    }

}

export default FornecedoresTable