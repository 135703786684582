import React from 'react'
import { withRouter } from 'react-router-dom'


import { AuthContext } from '../main/authProvider'

import Card from '../components/card'
import { Button } from 'primereact/button'
import GruposDeEmpresasTable from '../components/gruposDeEmpresas/gruposDeEmpresasTable'
import GruposDeEmpresasDialog from '../components/gruposDeEmpresas/gruposDeEmpresasDialog'


class GruposDeEmpresas extends React.Component{

    constructor(){
        super();
        this.tableRef = React.createRef()
    }    

    state = {

        searchText: '',

        displayUserDialog: false,

        disableSingUpButton: false,

        displayDialog: false,

        loading: false,

        disableCadastroButton: false,

        grupoToEdit: '',


    }

    handleChange =  (event) => {
        const value = event.target.value
        const name = event.target.name
        this.setState({[name]: value})
    }

    handleMuiKeyUp = (event) => {
        if(event.code === "Enter" || event.code === "NumpadEnter"){
            this.callSave()
        }
    }

    showDialog = () => {
        this.setState({displayDialog: true})
    }

    hideDialog = () => {
        this.setState({displayDialog: false})
    }

    onCallCreate = () => {
        this.hideDialog()
        this.setState({loading: true})
        this.setState({disableCadastroButton: true})
    }
    
    onSuccessCreate = () => {
        this.setState({loading: false})
        this.tableRef.current.search()
        this.setState({disableCadastroButton: false})
    }
    
    onErrorCreate = () => {
        this.setState({loading: false})
        this.setState({disableCadastroButton: false})
        this.showDialog()
    }

    edit = grupo => {
        this.setState({grupoToEdit: grupo})
        this.showDialog()
    }

    render(){
        return(
            <div className="bs-docs-section" >
            <Card title = "Grupos de Empresas">

            <div className = "col-md-12">

            <div className="row">

                <div className = "col-md-4">

                    <label htmlFor="date">Pesquisa</label>
                    <input style={{color: '#000'}} type="text"
                        className={"form-control "}
                        value = {this.state.searchText}
                        name="searchText"
                        onChange={this.handleChange}
                        id="searchText"
                        placeholder="Pesquise pelo nome"
                    />

                    <br />

                    <Button
                        label="Cadastrar Grupo"
                        icon="pi pi-plus"
                        onClick = {this.showDialog}
                        disabled = {this.state.disableCadastroButton}
                    />

                </div>
                

                <div className = "col-md-8">
                    <GruposDeEmpresasTable
                        ref={this.tableRef}
                        loading = {this.state.loading}
                        searchText={this.state.searchText}
                        edit={this.edit}
                    />

                </div>

            </div>


            </div>

            </Card>
            <div className="d-flex "/>

            <GruposDeEmpresasDialog
                visible={this.state.displayDialog}
                hideDialog={this.hideDialog}
                isCadastro={!this.state.grupoToEdit}
                grupoToEdit={this.state.grupoToEdit}
                resetData={() => this.setState({grupoToEdit: ''})}
                onCallCreate={this.onCallCreate}
                onSuccessCreate={this.onSuccessCreate}
                onErrorCreate={this.onErrorCreate}
            />

            </div>
        )
    }

}

GruposDeEmpresas.contextType = AuthContext
export default withRouter( GruposDeEmpresas )