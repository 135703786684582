import React from 'react'
import currencyFormater from 'currency-formatter'
import { cpf } from 'cpf-cnpj-validator';
import { cnpj } from 'cpf-cnpj-validator';
import { Dropdown } from 'primereact/dropdown';
import replaceSpecialCharacters from 'replace-special-characters'
import { InputNumber } from 'primereact/inputnumber';
import ConstantsUtil from '../../context/constantsUtil';
import * as xlsx from 'xlsx'
import * as popUp from '../../components/toastr'
import { Button } from 'primereact/button';
import TooltipButton from '../../components/tooltipButton';
import ProvisaoService from './contasAPagar/provisaoService';
import HandleErrorService from './handleErrorService';
import EmpresaService from './central/empresaService';
import SystemDataService from './systemDataService';
import CentralService from './central/centralService';
import { classNames } from 'primereact/utils';

class GeneralServices extends React.Component {

    constructor(){
        super()
        this.provisaoService = new ProvisaoService()
        this.empresaService = new EmpresaService()
        this.systemDataService = new SystemDataService()
        this.centralService = new CentralService()
    }
    
    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    static showFormattedIfNonZero = (value, formatted, withColor) => {
        /*
        Se formatted for true, a visualização fica como se fosse dinheiro: R$30,00
        */
        if(value === 0 || value === null || typeof value === 'undefined' || isNaN(value))
            return ''
        if(formatted){
            if(withColor){
                const classes = classNames({
                    'superavitValue': withColor && (value && value > 0),
                    'deficitValue': withColor && (value && value < 0),
                    
                });
                return (
                    <div className={classes}>
                        {this.valueBodyTemplate(value)}
                    </div>
                )
            }
            return this.valueBodyTemplate(value)
        }
        return value
    }

    static showFormattedIfNotNull = (value, formatted, withColor) => {
        /*
        Se formatted for true, a visualização fica como se fosse dinheiro: R$30,00
        */
        if(value === null || typeof value === 'undefined' || isNaN(value)) {
            return ''

        }
        if(formatted){
            if(withColor){
                const classes = classNames({
                    'superavitValue': (value && value > 0),
                    'deficitValue': (value && value < 0),
                    // 'small-table-font': (Math.abs(value) > 1000000)
                    
                });
                return (
                    <div className={classes}>
                        {this.valueBodyTemplate(value)}
                    </div>
    
                )
            }
            return this.valueBodyTemplate(value)
        }
        return value
    }

    static valueBodyTemplate = (value) => {
        // return currencyFormater.format(value, {locale: 'pt-BR', code: false} )
        let formattedValue = currencyFormater.format(value, {locale: ''})
        formattedValue = formattedValue.replaceAll(",", "#").replace(".", ",").replaceAll("#", ".")
        return formattedValue
    }

    static getCurrentYear = () => {
        return new Date().getFullYear()
    }

    static convertToUsDate = (date) => {
        /*
        Converte "dd-MM-yyyy" em "MM-dd-yyyy"
        */
        if(!date) return ''
        var dateArray = date.split('-')
        return dateArray[1] + '-' + dateArray[0] + '-' + dateArray[2]
    }

    static convertToBrDate = (date) => {
        /*
        Converte yyyy-dd-MM em dd-MM-yyyy
        */
        var dateArray = date.split('-')
        return dateArray[1] + '-' + dateArray[2] + '-' + dateArray[0]
    }

    static getNowDateInString(){
        return this.convertJSDateToBrDate(new Date())
    }

    static convertJSDateToBrDate(primereactDate){
        if(primereactDate === null){
            return ''
        }
        var jsDate = new Date(primereactDate)
        var initialDayOfMonth = jsDate.getDate()
        var dayOfMonth = parseInt(initialDayOfMonth) < 10 ? "0" + initialDayOfMonth : initialDayOfMonth
        
        var initialMonth = parseInt(jsDate.getMonth()) + 1 //getMonth() retorna de 0-11
        var month = initialMonth < 10 ? "0" + initialMonth.toString() : initialMonth.toString()
        
        var year = jsDate.getFullYear()

        var brDate = dayOfMonth + "-" + month + "-" + year
        return brDate
    }

    static getCurrentDay(){
        let date = new Date()
        return date
    }

    static getAddedDays(daysToBeAdded){
        let date = new Date()
        date.setDate(date.getDate() + daysToBeAdded)
        return date
    }

    
    static formatDateFromFichaDeEstoque(data){
        /*
            Transforma 2023-07-01T00:00:00 em 01-07-2023
        */
        const newData = data.split("T")[0]
        var newDataArray = newData.split("-")
        newDataArray = newDataArray.reverse()
        return newDataArray.join("-")
    }

    static convertBrStringToJsDate(stringBrDate){
        /*
        Apenas chama new Date() passando data em padrão americano "MM-dd-yyyy".
        Antes disso, precisou transformar "dd-MM-yyyy" em "MM-dd-yyyy"
         */
        var dateArray = stringBrDate.split('-')
        return new Date(dateArray[1] + '-' + dateArray[0] + '-' + dateArray[2])
    }

    static convertCompleteBrStringToJsDate(stringBrDate){
        /*
        Converte string no padrão "dd-MM-yyyy - HH:mm" em Date js
        */

        // Divide a string em partes usando regex que já pega espaços, traços e dois pontos
        var partes = stringBrDate.split(/[\s:-]+/);
        // console.log("partes:", partes)

        // Obtém as partes relevantes da data e hora
        var dia = parseInt(partes[0], 10);
        var mes = parseInt(partes[1], 10) - 1; // Os meses em JavaScript são de 0 a 11
        var ano = parseInt(partes[2], 10);
        var horas = parseInt(partes[3], 10);
        var minutos = parseInt(partes[4], 10);

        // Cria um objeto Date com as partes extraídas
        // console.log("ano, mes, dia, horas, minutos: ", ano, mes, dia, horas, minutos)
        var data = new Date(ano, mes, dia, horas, minutos);
        // console.log("data final: ", data)
        return data;
    }

    static checkValidHour = (hour) => {
        var checkHour = false
        var checkMinutes = false
        var length = hour ? hour.length === 5 : null
        if(length){
            var hourArray = hour.split(':')
            var intHour  = parseInt(hourArray[0], 10)
            var minutes = parseInt(hourArray[1], 10)
            if(intHour>=0 && intHour<24){
                checkHour=true
            }
            if(minutes>=0 && minutes<=60){
                checkMinutes=true
            }
        }
        return length && checkHour && checkMinutes
    }

    static compareIntValues( product1, product2 ) {
        var value1 = parseInt(product1.value, 10)
        var value2 = parseInt(product2.value, 10)
        if ( value1 < value2 ){
          return -1;
        }
        if ( value1 > value2){
          return 1;
        }
        return 0;
    }

    static compareGenericValues(item1, item2){
        /*
        Se os valores informados forem numbers, então ordenará em ordem crescente dos valores.
        Caso sejam strings, ordenará em ordem alfabética crescente, sabendo tratar caracteres com acento agudo e etc.
        */
        var value1 = item1.label
        var value2 = item2.label

        if(typeof(value1) === 'string' && typeof(value2) === 'string'){
            return value1.localeCompare(value2, 'pt-BR')
        }

        if ( value1 < value2 ){
          return -1;
        }
        if ( value1 > value2){
          return 1;
        }
        return 0;
    }

    static compareTimestampByIncreasing(moviment1, moviment2){
        const value1 = moviment1.timestamp
        const value2 = moviment2.timestamp
        
        if ( value1 < value2 ){
            return -1;
        }
        else if ( value1 > value2){
            return 1;
        }   else { //mesmo dia (mesmo timestamp)
                const desempate1 = moviment1.desempate
                const desempate2 = moviment2.desempate

                if ( desempate1 < desempate2 ){
                    return -1;
                }
                else if ( desempate1 > desempate2){
                    return 1;
                } else{
                    // console.log('algo de errado')
                }
            }
    }

    // static sortStockSheet(productStockSheet){

    //     productStockSheet.sort(this.compareTimestampByIncreasing)

    //     return productStockSheet
    // }

    // static calculateBalance(productStockSheet){
    //     productStockSheet = this.sortStockSheet(productStockSheet)
    //     for(var i=0; i<productStockSheet.length; i++){
    //         var moviment = productStockSheet[i]
    //         if(moviment.tipoAtualizacao !== 'MANUAL') {
    //             var previousMoviment = productStockSheet[i-1]
    //             if(previousMoviment && previousMoviment.quantidadeSaldo !== null && typeof previousMoviment.quantidadeSaldo !== "undefined"){
    //                 moviment.quantidadeSaldo = previousMoviment.quantidadeSaldo + moviment.quantidadeEntrada - moviment.quantidadeSaida
                    
    //                 var previousValorTotalSaldo = 0
    //                 if(previousMoviment.valorTotalSaldo !== null && typeof previousMoviment.valorTotalSaldo !== "undefined"){
    //                     previousValorTotalSaldo = previousMoviment.valorTotalSaldo
    //                 }
    //                 moviment.valUnitSaida = moviment.quantidadeSaida ===0 ? 0 : 
    //                     parseFloat((previousValorTotalSaldo/previousMoviment.quantidadeSaldo).toFixed(2))

    //                 moviment.valUnitEntrada = moviment.quantidadeEntrada ===0 ? 0 :
    //                     parseFloat((moviment.valorTotalEntrada/moviment.quantidadeEntrada).toFixed(2))
                    
    //                 moviment.valorTotalSaldo = 
    //                     parseFloat((previousValorTotalSaldo + moviment.valUnitEntrada*moviment.quantidadeEntrada
    //                         - moviment.valUnitSaida*moviment.quantidadeSaida).toFixed(2))
    //             }
    //         } else{ //Moviment Manual
    //             moviment.valorTotalSaldo = moviment.valorTotalEntrada
    //         }
    //     }
    //     return productStockSheet.reverse()
    // }

    static adjustNCM = (ncm) => {
      if(ncm === 0){ 
          return '00000000'
      }
      else{
          return ncm
      }
    }

    static minMaxYearRange(){
        //pega ano atual e 5 pra trás
        const today = new Date()
        const nextYear = today.getFullYear()+1
        const minYear = nextYear-5
        return {minYear: minYear, nextYear: nextYear}
    }

    static yearRange(){
        const yearObj = this.minMaxYearRange()
        var yearRange= yearObj.minYear + ":" + yearObj.nextYear
        return yearRange
    }

    static selectYearRange(){
        const yearObj = this.minMaxYearRange()
        var yearSelectList=
        [
            {label: yearObj.nextYear, value: yearObj.nextYear},
        ]
        for(var i = yearObj.nextYear-1; i >= yearObj.minYear; i--){
            yearSelectList.push({label: i, value: i})
        }
        return yearSelectList
    }

    static validarCPFOuCNPJ(cpfOuCnpj){
        const CPF = cpf.isValid(cpfOuCnpj)
        const CNPJ = cnpj.isValid(cpfOuCnpj)
        return CPF || CNPJ
    }

    static tablePaginatorTemplate(initialValue, totalRecords){
        return this.tablePaginatorSpecific(initialValue, 300, totalRecords)
        // return {
        //     layout:
        //         'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown',
        //         'RowsPerPageDropdown': (options) => {
        //             const dropdownOptions = [
        //                 { label: initialValue, value: initialValue },
        //                 { label: 300, value: 300 },
        //                 { label: 'Tudo', value: totalRecords}
        //             ];

        //         return <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} appendTo={document.body} />;
        //     }
        // }
    }

    static tablePaginatorSpecific(initialValue, intermediateValue, maxValue){
        return {
            layout:
                'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown',
                'RowsPerPageDropdown': (options) => {
                    const dropdownOptions = [
                        { label: initialValue, value: initialValue },
                        { label: intermediateValue, value: intermediateValue },
                        { label: maxValue, value: maxValue}
                    ];

                return <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} appendTo={document.body} />;
            }
        }
    }

    static getContaBancariaOrigemOuDestinoLabel = (invert, isTipoDeLancamentoTransferencia, isTipoDeLancamentoTransferenciaEntrada) => {
        const defaultLabel = 'Conta Bancária'
        const destinoLabel = defaultLabel + ' de Destino'
        const origemLabel = defaultLabel + ' de Origem'

        if(isTipoDeLancamentoTransferencia()){

            if(isTipoDeLancamentoTransferenciaEntrada()){
                if(invert){
                    return destinoLabel
                }

                return origemLabel
            }
            else{
                if(invert){
                    return origemLabel
                }
                return destinoLabel
            }
    }

        return defaultLabel
    }

    static replaceSpecialCharacters(string){
        if(string){
            return replaceSpecialCharacters(string.toString())
        }
    }

    static replaceSpecialCharactersForSeach(stringToReplace, searchString){
        // console.log("searchString: ", searchString)
        // console.log("compare: ", JSON.stringify(searchString) === JSON.stringify([]), JSON.stringify(searchString) === JSON.stringify([""]))
        if(searchString === '' || searchString === undefined || searchString === null || JSON.stringify(searchString) === JSON.stringify([]) || JSON.stringify(searchString) === JSON.stringify([""])){
            // console.log("sem replace")
            if(stringToReplace){
                return stringToReplace.toString()
            }
            return stringToReplace
        }
        // console.log("replace")
        return replaceSpecialCharacters(stringToReplace.toString())
    }

    static includeString(string, substring){
        return replaceSpecialCharacters(string.toString().toLowerCase()).includes(replaceSpecialCharacters(substring.toString().toLowerCase()))
    }

    static equalsString(string1, string2){
        return (replaceSpecialCharacters(string1.toString().oLowerCase()) === replaceSpecialCharacters(string2.toString().toLowerCase()))
    }

    static contaBancariaFormattedNameInDRE(dreSaldoBancarioDTO){
        const contaBancaria = dreSaldoBancarioDTO.contaBancaria
        var label;
        if(contaBancaria.numAgencia) {
            label = contaBancaria.nomeBanco + " - AG: " + contaBancaria.numAgencia + " - Conta: " + contaBancaria.numConta + "-" + contaBancaria.numDigitoConta;
        }
        else{
            label = contaBancaria.nomeBanco;
        }

        const nomeEmpresa = dreSaldoBancarioDTO.empresa
        if(nomeEmpresa){
           label = label.concat(" (", nomeEmpresa, ")")
        }

        return label

    }

    static inputTableNumber(id, name, value, rowData, handleTableChange, placeholder, disabled, onBlurFucntion, onKeyDownFunction){
        return (
            <InputNumber
                id={id}
                name={name}
                placeholder={placeholder ? placeholder : 'Informe o valor'}
                value={value}
                mode="decimal"
                onBlur={() => {
                    // console.log("onBlur")
                    if(onBlurFucntion){
                        onBlurFucntion()
                    }
                }}
                onKeyDown={(event) => {
                    // console.log("onKeyDown")
                    if(onKeyDownFunction){
                        onKeyDownFunction(event)
                    }
                }}
                inputClassName={ConstantsUtil.inputNumberSmallClass} 
                minFractionDigits={2} maxFracionDigits={2}
                onValueChange={(event) => handleTableChange(event, rowData)}
                disabled={disabled}
            />
        )
    }

    static saveAsExcelFile(workbook, fileName) {
        import('file-saver').then(module => {
            if (module && module.default) {
                const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([excelBuffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + EXCEL_EXTENSION);
            }
        });
    }

    // static jsonToSheet(formattedList){
        
    //     const worksheet = xlsx.utils.json_to_sheet(formattedList);

    //     // Calcula a largura máxima de cada coluna de forma que nenhuma coluna fique com dados ocultos/cortados.
    //     const colWidths = formattedList.reduce((widths, row) => {
    //         return Object.keys(row).map((key, i) => {
    //             const value = row[key] ? row[key].toString().length : 10; // Define 10 como largura padrão mínima
    //             return Math.max(widths[i] || 10, value);
    //         });
    //     }, []);

    //     // Aplica as larguras calculadas no sheet
    //     worksheet['!cols'] = colWidths.map(w => ({ wch: w }));

    //     return worksheet;        
    // }

    static jsonToSheet(formattedList) {
        const worksheet = xlsx.utils.json_to_sheet(formattedList);
        
        // Obtém as chaves dos headers (primeira linha)
        const headers = Object.keys(formattedList[0] || {});
    
        // Calcula a largura máxima de cada coluna, incluindo o header de forma que nenhuma coluna fique com dados ocultos/cortados.
        const colWidths = formattedList.reduce((widths, row) => {
            return headers.map((header, i) => {
                const valueLength = row[header] ? row[header].toString().length : 10;
                const headerLength = header.length;
                
                // Considera o maior valor entre o tamanho do header e do valor da célula
                return Math.max(widths[i] || 10, valueLength, headerLength);
            });
        }, []);
    
        // Aplica as larguras calculadas no sheet
        worksheet['!cols'] = colWidths.map(w => ({ wch: w }));
    
        return worksheet;        
    }    

    static generateWorkSheet(list){
        const worksheet = GeneralServices.jsonToSheet(list);
        return worksheet
    }

    static exportPdf = (filename, exportColumns, list) => {
        // var exportColumns = this.state.columns.map(col => ({ title: col.header, dataKey: col.field }))
        // var list = this.props.notFilteredList
        import('jspdf').then(jsPDF => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default(0, 0);
                doc.autoTable(exportColumns, list);
                doc.save(filename);
            })
        })
    }

    static exportExcel = (list, sheetLabel, filename) => {
        let worksheet = this.generateWorkSheet(list)
        const sheetName = sheetLabel
        const workbook = { Sheets: { [sheetName]: worksheet }, SheetNames: [[sheetName]] };
        this.saveAsExcelFile(workbook, filename);
    }

    static copyChNFeBody(chave) {
        return (
            <React.Fragment>
                <TooltipButton
                    tooltip = {chave}
                    icon="pi pi-copy"
                    // className="p-button-rounded p-button-primary p-mr-2"
                    style={ {maxHeight: '25px', maxWidth: '25px'} }
                    onClick={() => {
                        navigator.clipboard.writeText(chave)
                        popUp.infoPopUp("Chave copiada!")
                    }}
                />
            </React.Fragment>
        );        
    }

    static trataDadosNF =  (notasFiciaisList) => {
        notasFiciaisList.forEach(notaFiscal => {
            this.trataSituacaoNF(notaFiscal)
        })
        this.dealNFListDates(notasFiciaisList)
    }

    static trataSituacaoNF = (notaFiscal) => {
        switch(notaFiscal.situacao){
            case ConstantsUtil.situacaoRegular:
                notaFiscal.situacaoLabel = notaFiscal.pendente ? ConstantsUtil.situacaoRegularAguardandoValidacaoLabel : ConstantsUtil.situacaoRegularValidadaLabel
                break;
            case ConstantsUtil.situacaoCancelada:
                notaFiscal.situacaoLabel = ConstantsUtil.situacaoCanceladaLabel
                break;
            case ConstantsUtil.situacaoExcluida:
                notaFiscal.situacaoLabel = ConstantsUtil.situacaoExcluidaLabel
                break;
            default:
                notaFiscal.situacaoLabel = notaFiscal.situacao
                break;
        }        
    }

    static mensagemComposicaoValorTotal = transaction => {
        // console.log("transaction: ", transaction)
        return `Composição do Valor Total do Produto:
        Valor do Produto: ${this.formatTo2CasasDecimais(transaction.vprod)} (+)
        Valor de Acréscimo: ${this.formatTo2CasasDecimais(transaction.vacrescimo)} (+)
        Valor de Desconto: ${this.formatTo2CasasDecimais(transaction.vdesc)} (-)
        Valor de Frete: ${this.formatTo2CasasDecimais(transaction.vfrete)} (+)
        Valor de ICMSST: ${this.formatTo2CasasDecimais(transaction.vicmsst)} (+)
        Valor de FCPST: ${this.formatTo2CasasDecimais(transaction.vfcpst)} (+)
        Valor de IPI: ${this.formatTo2CasasDecimais(transaction.vipi)} (+)
        Outros: ${this.formatTo2CasasDecimais(transaction.voutro)} (+)
        Valor Final do Produto: ${this.formatTo2CasasDecimais(transaction.valor)} (=)
        `
    }

    static dealNFListDates = (NFList) => {
        NFList.forEach(item => {
            //Seta Valores Padrão
            item.dEmiSemHorario = ''
            item.dhEmiJsDate = ''

            item.dataEntradaSemHorario = ''
            item.dataEntradaJsDate = ''

            item.dataImportacaoSemHorario = ''
            item.dataHoraImportacaoJsDate = ''

            if(item.dhEmi){
                item.dEmiSemHorario = item.dhEmi.split(" -")[0]
                item.dhEmiJsDate = this.convertCompleteBrStringToJsDate(item.dhEmi).getTime()
            }

            if(item.dataHoraEntrada){
                item.dataEntradaSemHorario = item.dataHoraEntrada.split(" -")[0]
                item.dataEntradaJsDate = this.convertCompleteBrStringToJsDate(item.dataHoraEntrada).getTime()
            }

            if(item.dataHoraImportacao){
                item.dataImportacaoSemHorario = item.dataHoraImportacao.split(" -")[0]
                item.dataHoraImportacaoJsDate = this.convertCompleteBrStringToJsDate(item.dataHoraImportacao).getTime()
            }

        })

    }

    static dealProductData = (productTransactionList) => {

        productTransactionList.forEach(item => {
            
            //Seta Valores Padrão
            item.nf.dataImportacaoSemHorario = ''
            item.nf.dataHoraImportacaoJsDate = ''

            item.nf.dEmiSemHorario = ''
            item.nf.dhEmiJsDate = ''

            item.nf.dataEntradaSemHorario = ''
            item.nf.dataHoraEntradaJsDate = ''

            //Trata código
            if(!item.transaction.productInfo.codigo){
                item.transaction.productInfo.codigo = '' //Troca null por '' para conseguir aparecer no filtro.
            }

            //Trata Tipo de Produto
            if(!item.transaction.productInfo.tipoProduto){
                item.transaction.productInfo.tipoProduto = {descricao: ''}
            }

            //Trata dataHoraImportacao
            if(item.nf.dataHoraImportacao){
                item.nf.dataImportacaoSemHorario = item.nf.dataHoraImportacao.split(" -")[0]
                item.dataHoraImportacaoJsDate = this.convertCompleteBrStringToJsDate(item.nf.dataHoraImportacao).getTime()
            }

            //Trata dhEmi
            if(item.nf.dhEmi){
                item.nf.dEmiSemHorario = item.nf.dhEmi.split(" -")[0]
                item.dhEmiJsDate = this.convertCompleteBrStringToJsDate(item.nf.dhEmi).getTime()
            }

            //Trata dataHoraEntrada
            if(item.nf.dataHoraEntrada){
                item.nf.dataEntradaSemHorario = item.nf.dataHoraEntrada.split(" -")[0]
                item.dataHoraEntradaJsDate = this.convertCompleteBrStringToJsDate(item.nf.dataHoraEntrada).getTime()
            }

        })
    }  
    
    static formatTo2CasasDecimais(valor){
        /*
            Arredonda para 2 casas decimais
        */
       if(!valor){
        valor = 0.00
       }
        return parseFloat(valor.toFixed(2))
    }

    static findTotalItem(list){
        return list.find(item => 
            item.realNome === ConstantsUtil.tipoTotaisFichaBancariaLabel 
            || item.realNome === ConstantsUtil.totalSaldoAnteriorLabel 
            || item.realNome === ConstantsUtil.totalSaldoFinalLabel
        )
    }

    static defineFiltroTipoLancamentoFichaBancaria(obj, isAplicacaoFinanceiraFunc, tipoDeLancamentoState){
        
        if(isAplicacaoFinanceiraFunc()) {
            obj.tipoDeLancamentoBancario = ConstantsUtil.lancamentoBancarioRecebimentoAplicacaoFinanceiraLabel
        }
        else if(tipoDeLancamentoState.includes(ConstantsUtil.lancamentoBancarioTransferenciaLabel)){
            obj.tipoFichaBancaria = ConstantsUtil.tipoFichaBancariaTransferenciaLabel
            if(tipoDeLancamentoState === ConstantsUtil.lancamentoBancarioTransferenciaSaidaLabel){
                obj.tipoDeLancamentoBancario = ConstantsUtil.lancamentoBancarioPagamentoLabel
            }
            else{
                obj.tipoDeLancamentoBancario = ConstantsUtil.lancamentoBancarioRecebimentoLabel
            }
        }
        else{
            obj.tipoDeLancamentoBancario = tipoDeLancamentoState
        }

        return obj
    }

    static isEmptyObject(object){
        return !object || JSON.stringify(object) === '{}'
    }


    searchProvisoes(obj, onSuccess, onFinally, unificar, selectedEmpresas) {

        // console.log("searchProvisoes: ", unificar, selectedEmpresas)

        //Define a função de busca
        let searchInternal = (obj) => {
            if(unificar){
                return this.centralService.searchLancamentosProvisao(obj, selectedEmpresas)
            }
            else{
                return this.provisaoService.searchLancamentos(obj)
            }
        }

        searchInternal(obj)
        .then(async response => {
            let responseData = this.trataDadosSearchProvisoes(response.data)
            onSuccess(responseData)
        })
        .catch(error => {
            // console.log(error)
            HandleErrorService.handleError(error)
        })
        .finally( () => onFinally() )
    }

    trataDadosSearchProvisoes = dados => {
        if(dados.filterTypeDate === ConstantsUtil.dataLancamentoValue) {
            dados.provisaoList.forEach(provisao => {
                provisao.data = provisao.dataLancamento
                provisao.valor = provisao.valorTotal

                if(provisao.notaFiscal) {
                    provisao.nomeFornecedor = `${provisao.notaFiscal.fornecedor.nome} (${provisao.notaFiscal.fornecedor.cnpj})`
                    provisao.tipoDePagamento = {
                        nome: '',
                        grupoDePagamento: {
                            nome:  ''
                        }
                    }                    
                }
                else if(provisao.tipoDePagamento && provisao.tipoDePagamento.fornecedor) {
                    provisao.nomeFornecedor = provisao.tipoDePagamento.fornecedor.nome
                }
                else {
                    provisao.nomeFornecedor = ""
                }

                this.reescreveOrigemProvisao(provisao)

            })
        }

        else if(dados.filterTypeDate === ConstantsUtil.dataPagamentoValue) {

            dados.parcelaProvisaoList.forEach(parcelaProvisao => {
                
                if(!parcelaProvisao.tipoDePagamento){
                    parcelaProvisao.tipoDePagamento = {
                        nome: '',
                        grupoDePagamento: {
                            nome:  ''
                        }
                    } 
                }

                this.reescreveOrigemProvisao(parcelaProvisao)

            })


        }

        let valorTotal = this.calculaValorTotalSearchProvisoes(dados)
        
        return {dados, valorTotal};

    }

    reescreveOrigemProvisao(provisaoOuParcela){
        if(provisaoOuParcela.origem){
            switch(provisaoOuParcela.origem){
                case ConstantsUtil.origemProvisaoImportacaoNF:
                    provisaoOuParcela.origem = 'Validação de Nota Fiscal'
                    break;
                case ConstantsUtil.origemProvisaoNFManual:
                    provisaoOuParcela.origem = 'Lançamento Manual de Nota Fiscal'
                    break;
                case ConstantsUtil.origemProvisaoOutroasProvisoesManuais:
                    provisaoOuParcela.origem = 'Outras Provisões Manuais'
                    break;
            }
        }
    }
    
    calculaValorTotalSearchProvisoes = (dados) => {

        let valorTotal = 0

        if(dados.filterTypeDate === ConstantsUtil.dataLancamentoValue){
            dados.provisaoList.forEach(provisao => valorTotal += provisao.valorTotal)
        }
        else if(dados.filterTypeDate === ConstantsUtil.dataPagamentoValue){
            dados.parcelaProvisaoList.forEach(parcelaProvisao => valorTotal += parcelaProvisao.valor)
        }

        valorTotal = GeneralServices.formatTo2CasasDecimais(valorTotal)

        return valorTotal;

    }

    getEmpresasInASelectList = (onSuccess, onError) => {
        this.empresaService.getLoggedEmpresas()
        .then(response => {
            var list = []
            response.data.forEach(empresa => {
                list.push({label: empresa.nome, value: empresa})
            });
            
            if(onSuccess){
                onSuccess(list)
            }

        })
        .catch(error => {
            HandleErrorService.handleError(error)
            if(onError){
                onError(error)
            }
        })
    }

    getLoggedSystemData(onSuccess, onError){
        this.systemDataService.getLoggedSystemData()
        .then(response => {
            onSuccess(response)
        })
        .catch(error => {
            HandleErrorService.handleError(error)
            if(onError){
                onError()
            }
        })
    }

    static renderStyledSituacaoTableColumn = (rowData) => {

        const classes = classNames({
            'superavitValue': rowData.ativo,
            'deficitValue': !rowData.ativo
        });

        return (
            <div className={classes}>
                {rowData.ativo ? 'ATIVO' : 'INATIVO'}
            </div>
        );
    }

    static renderStyledSituacaoNF = (situacaoLabel, rowIndex, onClickFunction) => {

        let isLink = situacaoLabel === ConstantsUtil.situacaoExcluidaLabel

        const classes = classNames({
            'superavitValue': situacaoLabel === ConstantsUtil.situacaoRegularValidadaLabel,
            'deficitValue': [ConstantsUtil.situacaoExcluidaLabel, ConstantsUtil.situacaoCanceladaLabel].includes(situacaoLabel),
            'linkValue': isLink
        });

        if(isLink){
            return (
                <>
                    <a 
                        className={classes}
                        onClick={onClickFunction}
                    >
                        {this.renderBodyColumnInOneLine(situacaoLabel, rowIndex)}
                    </a>
                </>
            )
        }

        return (
            <div className={classes}>
                {this.renderBodyColumnInOneLine(situacaoLabel, rowIndex)}
            </div>
        );
    }

    static renderStyledTipoNF = (notaFiscal, rowIndex, onClickFunction) => {
        
        let isLink = (notaFiscal.tipo === ConstantsUtil.tipoNFDevolucaoEntradaLabel) || (notaFiscal.valorDescontoPorDevolucao)
        // console.log("notaFiscal: ", notaFiscal)
        // console.log("isLink: ", isLink)

        const classes = classNames({
            // 'superavitValue': situacaoLabel === ConstantsUtil.situacaoRegularValidadaLabel,
            'deficitValue': isLink,
            'linkValue': isLink
        });

        if(isLink){
            return (
                <>
                    <a 
                        className={classes}
                        onClick={onClickFunction}
                    >
                        {this.renderBodyColumnInOneLine(notaFiscal.tipo, rowIndex)}
                    </a>
                </>
            )
        }

        return (
            <div className={classes}>
                {this.renderBodyColumnInOneLine(notaFiscal.tipo, rowIndex)}
            </div>
        );
    }

    static renderStyledColorTableColumn = (value, checked) => {
        const classes = classNames({
            'superavitValue': checked,
            'deficitValue': !checked
        });

        return (
            <div className={classes}>
                {value}
            </div>
        );        
    }

    static renderBodyColumnInOneLine = (value, rowIndex) => {
        /*
        Faz com que o valor da coluna da tabela fique em única linha, ocultando se passar do width da coluna.
        Ainda, caso o usuário passe o mouse sobre a linha, conseguirá ver o valor completo da coluna através de um title
        */
        const uniqueId = `name_${rowIndex.rowIndex}`;
        return(
            <div
                style ={ {
                    whiteSpace: 'nowrap', /* Impede que o texto quebre em várias linhas */
                    overflow: 'hidden', /* Esconde qualquer conteúdo que exceda o width da coluna */
                    textOverflow: 'ellipsis', /* Adiciona reticências (...) ao final do texto cortado */
                } }
                title={value}
            >

                <span id={uniqueId}>{value}</span>

            </div>
        );
    }

    static renderTableValueAsLink = (rowData, rowIndex, valor, deveRenderizarEstaLinhaComoLink, isDeficitValue, actionFunction) => {
        if( deveRenderizarEstaLinhaComoLink ){
            const classes = classNames({
                'deficitValue': isDeficitValue,
                'linkValue': true
                
            });
            // const uniqueId = `name_${rowIndex.rowIndex}`;
            return(
                <a 
                    className={classes}
                    // style ={ {
                    //     whiteSpace: 'nowrap', /* Impede que o texto quebre em várias linhas */
                    //     overflow: 'hidden', /* Esconde qualquer conteúdo que exceda o width da coluna */
                    //     textOverflow: 'ellipsis', /* Adiciona reticências (...) ao final do texto cortado */
                    // } }                    
                    onClick={() => actionFunction(rowData)}
                >
                    {valor}
                    {/* <span id={uniqueId}>{valor}</span> */}
                </a>
            )
        }
        else{
            return(
                <>
                    {valor}
                </>
            )
        }        
    }


    static filtraOcultaExcluidas = async (setStateFunction, field, dataTableRef) => {
        let sitacaoNFNaoExluidaList = [ConstantsUtil.situacaoRegularValidadaLabel, ConstantsUtil.situacaoRegularAguardandoValidacaoLabel]
        setStateFunction(sitacaoNFNaoExluidaList)
        dataTableRef.current.filter(sitacaoNFNaoExluidaList, field, 'in');

    }

    static formataTipoDeAtualizacaoFichaDeEstoque(itemFichaDeEstoque){
        if(!itemFichaDeEstoque.tipoAtualizacao){
            return ''
        }
        let deParaList = [
            {tipo: 'PROCESSAMENTO_DO_SISTEMA_MESMA_DATA_DO_MANUAL_HORARIO_ANTERIOR', tipoFormatado: 'Processamento do sistema em horário anterior à atualização manual'},
            {tipo: 'MANUAL', tipoFormatado: 'Atualização manual'},
            {tipo: 'PROCESSAMENTO_DO_SISTEMA', tipoFormatado: 'Processamento do sistema'},
            {tipo: 'AJUSTE_PERDA', tipoFormatado: 'Ajuste perda'},
            {tipo: 'AJUSTE_SOBRA', tipoFormatado: 'Ajuste sobra'},
            {tipo: 'DEVOLUCAO', tipoFormatado: 'Devolução'},
        ]

        deParaList.forEach(dePara => {
            if(itemFichaDeEstoque.tipoAtualizacao === dePara.tipo){
                itemFichaDeEstoque.tipoAtualizacaoFormatado = itemFichaDeEstoque.tipoAtualizacao.replace(dePara.tipo, dePara.tipoFormatado)
                return;
            }
        })
        return itemFichaDeEstoque.tipoAtualizacaoFormatado

    }


}

export default GeneralServices