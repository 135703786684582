import React from 'react'

import { Button } from 'primereact/button'

class ExpansionTableButton extends React.Component {

    render(){

        return (
            <div className='row'>
            <div className='col-md-1'>
                <Button
                    icon={<i className={`pi pi-${this.props.expanded ? 'minus' : 'plus'}`} style={{fontSize: '0.5em'}}></i>}
                    className={"expand-button-table small-font "}
                    style={ {maxHeight: '1em', maxWidth: '1em', marginBottom: '1em'} }
                    onClick={this.props.toggleExpansionTable}
                    tooltip={this.props.expanded ? "Ocultar" : "Expandir"}
                />
            </div>
            </div>
        )
    }

}

export default ExpansionTableButton