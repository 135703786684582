import React from 'react'
import HandleErrorService from '../../app/service/handleErrorService'
import AplicacaoFinanceiraService from '../../app/service/conciliacaoBancaria/aplicacaoFinanceiraService'
import ChooseAplicacaoFinanceiraDialog from './chooseAplicacaoFinanceiraDialog'

class InputChosseAplicacaoFinanceira extends React.Component {

    constructor(){
        super()
        this.aplicacaoFinanceiraService = new AplicacaoFinanceiraService()

    }

    state = {
        loading: false,
        aplicacaoFinanceiraList: [],

        chooseAplicacaoFinanceiraDialogVisible: false,
        loadingContaBancariaTable: false,
        forceUpdate: false,
        
    }

    componentDidMount(){
        this.searchAplicacaoFinanceira()
    }

    searchAplicacaoFinanceira = () => {
        this.setState({loading: true})
        this.setState({isSavingNewAplicacaoFinanceira: true})
        this.aplicacaoFinanceiraService.search()
        .then(response => {
            this.setState({aplicacaoFinanceiraList: response.data})
            this.setState({loading: false})
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
        .finally(() => {
            this.setState({loading: false})
            this.setState({isSavingNewAplicacaoFinanceira: false})
            this.setState({forceUpdate: true})
        })
    }

    doneForceUpdate = () => {
        this.setState({forceUpdate: false})
    }

    hideDialog = () => {
        this.setState({chooseAplicacaoFinanceiraDialogVisible: false})
        
        if(this.props.doNotForceDialog)
            this.props.doNotForceDialog()
    }

    handleChange = (value) => {
        this.props.handleSelectionChange(value, value.nome)
    }

    handleKeyPress = async (e) => {
        if (e.key === "Enter") {
            this.inputClick()
          }
    }

    inputClick = () => {
        this.setState({chooseAplicacaoFinanceiraDialogVisible: true})
    }

    render(){

        const renderInput = () => {
            return (
                <>
                <label htmlFor="aplicacaoFinanceira">{this.props.label ? this.props.label : 'Aplicação Financeira'}</label>
                    <input style={{color: '#000'}} type="text"
                        className={"form-control " + this.props.inputErrorClass}
                        readOnly
                        value = {this.props.selectedAplicacaoFinanceiraLabel}
                        name="selectedAplicacaoFinanceiraLabel"
                        id="selectedAplicacaoFinanceira"
                        placeholder="Nenhuma Aplicação Financeira Selecionada..."
                        onClick={this.inputClick}
                        onKeyPress={this.handleKeyPress}
                        disabled={this.props.disabled}
                    />
                    <div className="invalid-feedback">
                        {this.props.errorMessage ? this.props.errorMessage : "Informe a Aplicação Financeira"}
                    </div>
                </>
            )
        }

        const renderHTMLFormat = () => {
            if(this.props.noHTMLFormat){
                return (
                    <>
                    {renderInput()}
                    </>
                )
            }
            else {
                return (
                    <>
                    <div className="row">
                    <div className="col-md-4">
                    {renderInput()}
                    </div>
                    </div>
                    </>
                )
            }
        }

        return(
            <>
            
                {renderHTMLFormat()}
                
                <ChooseAplicacaoFinanceiraDialog
                    // header="Conta Bancária"
                    visible={this.state.chooseAplicacaoFinanceiraDialogVisible || this.props.forceShowDialog}
                    list={this.state.aplicacaoFinanceiraList}
                    hideDialog={this.hideDialog}
                    forceUpdate={this.state.forceUpdate}
                    doneForceUpdate={this.doneForceUpdate}
                    handleSelectionChange={this.handleChange}
                />


            </>
        )

    }


}

export default InputChosseAplicacaoFinanceira