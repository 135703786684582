import React from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import GeneralServices from '../../../../app/service/generalServices'
import { Button } from 'primereact/button'
import { classNames } from 'primereact/utils'
import * as xlsx from 'xlsx'
import ConstantsUtil from '../../../../context/constantsUtil'


class DREResultadoTable extends React.Component {

    state = {
        
        loading: false,

    }

    getTableHeaders = () => {
        
        const periodo = this.props.initialDate ? this.props.initialDate + " - " + this.props.finalDate : ""
        const periodoLabel = "Período: " + periodo

        return {periodo: periodoLabel}
    }

    saldoClass(rowData) {
        var obj ={}
        if(rowData.total > 0){
            obj = {
                'superavit': true
            }
        }
        else if(rowData.total < 0){
            obj = {
                'deficit': true
            }
        }

        return obj
    }

    formattedDREMensalResultadoToExportByExcel(){
        var obj = {'': this.props.header}
        var formattedList = [obj]
        const resultado = JSON.parse(JSON.stringify(this.props.list[0]))
        obj.Total = resultado.total
        const porcentagem = '%'
        obj[porcentagem] = `${resultado.porcentagem}%`
        return formattedList
    }

    generateWorkSheet(){
        const worksheet = GeneralServices.jsonToSheet(this.formattedDREMensalResultadoToExportByExcel());
        return worksheet
    }

    exportExcel = () => {
        const periodo = this.props.initialDate + " - " + this.props.finalDate
        const worksheet = this.generateWorkSheet()
        const sheetName = periodo
        // const sheetName2 = 'DRE Mensal 2'
        const workbook = { Sheets: { [sheetName]: worksheet }, SheetNames: [[sheetName]] };
        this.props.saveAsExcelFile(workbook, `Fluxo de Caixa Mensal ${this.props.header} - ${periodo} - VisãoRJ`);
    }

    render (){

        const renderContasBancariasColumn = () => {
            
            var contasBancariasColumnList = []

            if(this.props.list && this.props.list[0]){
                // console.log("item: ", this.props.list[0])
                this.props.list[0].resultadoList.forEach(item => {
                    contasBancariasColumnList.push(renderColumnItem(item.contaBancaria))
                });
            }


            return contasBancariasColumnList
        }

        const renderColumnItem = (contaBancaria) => {
            return (
                <Column
                    field={contaBancaria.nomeBanco}
                    header={contaBancaria.nomeBanco}
                    style ={ {width: '300px'} }
                    body={rowData => renderColumn(rowData, contaBancaria)}
                    // sortable
                />
            )
        }

        const renderColumn = (rowData, contaBancaria) => {

            return(
                <>
                <div className='row'>
                <div className='col-md-6'>
                {renderStyledValue(rowData[contaBancaria.id].valor, true)}
                </div>

                </div>
                </>
            )
        }

        const renderStyledValue = (valor) => {

            const classes = classNames({
                'superavitValue': valor >= 0,
                'deficitValue': valor<0
            });
    
            return (
                <div className={classes}>
                    {GeneralServices.showFormattedIfNotNull(valor, true)}
                </div>
            );
        }

        return (
            <>
            <div className = "card-header header-small-height">
            <div className='col-md-12'>
                
                <div className='row'>
                    <div className='col-md-4' style={{marginLeft: '1.05rem'}}>
                        <h5 className='small-font-size'>{this.props.header}</h5>            
                    </div>
                    <div className='col-md-4'>
                        {/* <h5 className='small-font-size'>{this.getTableHeaders().periodo}</h5> */}
                    </div>
                    <div className='col-md-2'>
                    </div>
                    <div className='col-md-2'>
                        {/* <Button
                            label="Exportar"
                            icon={<i className="pi pi-file-excel" style={{fontSize: '1em', marginRight: '0.5em'}}></i>}
                            className="p-button-success small-font" onClick={this.exportExcel}
                            tooltip={ConstantsUtil.exportXlsxLabel}
                            tooltipOptions={{position: 'top'}}
                            style={{maxHeight: '15px'}}
                        /> */}
                    </div>
                </div>
                
                {/* <div className='row'>
                <div className='col-md-4'>
                    <Button label="Exportar" icon="pi pi-file-excel" className="p-button-success" onClick={this.exportExcel}
                        tooltip={ConstantsUtil.exportXlsxLabel}
                        tooltipOptions={{position: 'top'}}
                    />
                </div>
                </div> */}
                
            </div>
            </div>

            
            <DataTable
                ref={this.dt}
                value={this.props.list}
                className="p-datatable-sm small-font"
                // rowClassName={this.saldoClass}
                autoLayout
                rowHover
                showGridlines
                // selection={this.state.selectedContasBancaria}
                // onSelectionChange={this.handleSelectionChange}
                scrollable
                style={{ maxWidth: '100vw'}}
                scrollHeight="500px"
                loading={this.props.loading || this.state.loading}
                dataKey="id"
                emptyMessage="Nenhum Resultado encontrado."
                // paginator rows={ConstantsUtil.initialTableQuantityOption}
                // paginatorTemplate={GeneralServices.tablePaginatorTemplate(ConstantsUtil.initialTableQuantityOption, this.props.list ? this.props.list.length : '')}
                // currentPageReportTemplate="Mostrando de {first} ao {last} de {totalRecords} Tipos de Pagamento"
            >

            <Column field="nome" header="Nome"
                style ={ {minWidth: ConstantsUtil.widthColumNomeDreConsolidado} }
                body = {rowData => "RESULTADO"}
            />

            {/* {renderContasBancariasColumn()} */}

            <Column field="total" header="Total"
                style ={ {minWidth: ConstantsUtil.widthColumNomeDreConsolidado} }
                body = {rowData => renderStyledValue(rowData.total)}
            />

            <Column field="porcentagem" header="%"
                style ={ {minWidth: ConstantsUtil.widthColumNomeDreConsolidado} }
                body = {rowData => `${rowData.porcentagem}%`}
            />            

            </DataTable>

            </>
        )
    }


}  

export default DREResultadoTable