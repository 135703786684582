import CentralApiService from "../centralApiServices"

class EmailService extends CentralApiService {

    constructor() {
        super('/api/email')
    }

    recoverPassword(userData){
        return this.post('/recoverPassword', userData)
    }

}

export default EmailService