import React from "react";
import TiposDeRecebimentoService from "../../app/service/tiposDeRecebimentoService";
import FormGroup from "../form-group";
import SelectMenu from "../selectMenu";

class DiaDaSemanaSelect extends React.Component {

    constructor(){
        super()
        this.tiposDeRecebimentoService = new TiposDeRecebimentoService()
    }

    render() {
        return (
            <div>
                <FormGroup label = "Dia da Semana " htmlFor = {this.props.htmlFor}>
                    <SelectMenu
                        className={"form-control " + this.props.inputDiaDaSemanaErrorClass}
                        name="diaDaSemana"
                        list={this.tiposDeRecebimentoService.diaDaSemanaList} 
                        value={this.props.diaDaSemana}
                        onChange={this.props.handleChange}
                        disabled={this.props.disabled}
                    />
                    <div className="invalid-feedback">{this.props.errorDiaDaSemanaMessage}</div>  
                </FormGroup>
            </div>
        )
    }

}

export default DiaDaSemanaSelect