import React from 'react'

import { Dialog } from 'primereact/dialog'
import DialogFooter from '../dialogFooter'
import TipoDePagamentoTable from './tipoDePagamentoTable'
import { Button } from 'primereact/button'
import CreateTipoDePagamentoDialog from '../../views/contasAPagar/createTipoDePagamentoDialog'
import GeneralServices from '../../app/service/generalServices'
import TipoDePagamentoService from '../../app/service/contasAPagar/tipoDePagamentoService'
import HandleErrorService from '../../app/service/handleErrorService'

class ChooseTipoDePagamentoDialog extends React.Component {

    constructor(){
        super()
        this.generalServices = new GeneralServices();
        this.tipoDePagamentoService = new TipoDePagamentoService()
    }

    state = {

        selectedTipoDePagamento: '',
        tipoDePagamentoList: [],
        filteredTipoDePagamentoList: [],

        searchText: '',

        displayCadastroTipoDePagamentoDialog: false,

        didUpdated: false,

    }

    componentDidUpdate(){
        if(!this.props.waitToDidMount){
            this.setStates()
        }
    }

    setStates = () => {
        if( (this.props.visible && !this.state.didUpdated) || this.props.forceUpdate ){

            this.setState({searchText: ''})

            var list = []
            this.setState({tipoDePagamentoList: list})
            this.setState({filteredTipoDePagamentoList: list})
            if( !this.props.isFromGrupoDePagamentoTable && (!this.props.list || this.props.list.length === 0) ){
                //Não recebeu lista de tipo de pagamento via props.
                this.autoSearch()
            }
            else {
                //Recebeu lista de tipo de pagamento via props.
                list = this.props.list
                this.setState({tipoDePagamentoList: list})
                this.setState({filteredTipoDePagamentoList: list})
            }

            if(this.props.doneForceUpdate){
                this.props.doneForceUpdate()
            }
            this.setState({didUpdated: true})
        }
    }

    autoSearch = () => {
        if(this.props.contaBancariaId){
            //Recebeu contaBancariaId via props.
            this.autoSearchWithSearchFunction(this.tipoDePagamentoService.searchByContaBancariaId(this.props.contaBancariaId))
        }
        else if(this.props.provisoesAPagar){
            /*
            Recebeu props provisoesAPagar. Busca todos os Tipos de Pagamento que são permitidos para um Lançamento de Provisão
            Os Tipos de Pagamento permitidos para o Lançamento de Provisão são todos, exceto:
            - Naturezas TARIFA_BANCARIA e APLICACAO_FINANCEIRA
            - Tipo de Pagamento com nomes "NÃO IDENTIFICADO" e "AVULSO".
            */
            this.autoSearchWithSearchFunction(this.tipoDePagamentoService.searchForProvisoesAPagar())
        }
        else{
            //Não recebeu nenhuma das props acima. Então busca todos os Tipos de Pagamento
            this.autoSearchWithSearchFunction(this.tipoDePagamentoService.search())
        }
    }

    autoSearchWithSearchFunction = (searchFunction) => {

        this.setState({loading: true})

        searchFunction
        .then(response => {
            const list = response.data
            this.setState({tipoDePagamentoList: list})
            this.setState({filteredTipoDePagamentoList: list})
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
        .finally( () => {
            this.setState({loading: false})
        } )        
    }

    handleChange = async (event) => {
        const value = event.target.value
        const name = event.target.name
        await this.setState({ [name]: value })
        this.filter()
    }

    handleKeyPress = async (e) => {
        if (e.key === "Enter") {
            const filteredList = JSON.parse(JSON.stringify(this.state.filteredTipoDePagamentoList))
            if(filteredList.length === 1){
                await this.setState({selectedTipoDePagamento: filteredList[0]})
                this.callSave()
            }
          }
    }

    search = async () => {
        if(this.props.search){
            await this.props.search()
        }
        else{
            this.autoSearch()
        }
    
        this.filter()
    }

    filter  = () => {
        
        this.setState({
            filteredTipoDePagamentoList:
                JSON.parse(
                    JSON.stringify(
                        // this.state.tipoDePagamentoList.filter(tipoDePagamento => {
                        this.state.tipoDePagamentoList.filter(tipoDePagamento => {
                            return (
                                GeneralServices.includeString(tipoDePagamento.nome, this.state.searchText)
                                // tipoDePagamento.nome.toLowerCase().includes(this.state.searchText.toLowerCase())
                                ||
                                GeneralServices.includeString(tipoDePagamento.id*5, this.state.searchText)
                                // ((tipoDePagamento.id*5).toString().includes(this.state.searchText))
                            ) 
                        }
                        )
                    )
                )
        })
    }

    handleSelectionTipoDePagamentoChange = (value) => {
        if(value === null){ //significa que clicou em cima do Tipo de Recebimento que estava selecionado antes (double click).
            this.callSave()
        } else{ //vem aqui no 1° clique.
            this.setState({selectedTipoDePagamento: value})
        }
    }

    hideDialog = () => {
        this.props.hideDialog()
        this.setState({didUpdated: false})
    }

    hideCadastroTipoDePagamentoDialog = () => {
        this.setState({displayCadastroTipoDePagamentoDialog: false})
    }

    handleNewFornecedorCadastrado = () => {
        this.hideCadastroTipoDePagamentoDialog()
        this.search()
    }

    callSave = async () => {
        await this.props.handleSelectionTipoDePagamentoChange(this.state.selectedTipoDePagamento)
        this.hideDialog()
    }


    render () {

        const chooseTipoDePagamentoDialogFooter = (
            <DialogFooter save = {this.callSave} hideDialog = {this.hideDialog} disabled={this.props.disabled}/>
        )

        const renderCadastrarButton = () => {
            if(!this.props.simpleView){
                return (
                    <>
                        <Button
                            label="Cadastrar"
                            icon="pi pi-plus"
                            onClick = {() => this.setState({displayCadastroTipoDePagamentoDialog: true})}
                            // style={ {maxHeight: '35px' } }
                            style={ {maxWidth: '200px' } }
                            // disabled = {this.state.isSavingNewFornecedor}
                        />

                        <br />

                        <br />                    
                    </>
                )
            }
        }

        return (
            <>
            <Dialog 
                visible={this.props.visible}
                style={{ width: '70vw' }}
                header={this.props.header}
                modal
                className="p-fluid"
                footer={chooseTipoDePagamentoDialogFooter}
                onHide={this.hideDialog}
            >

                <div className="p-field">
                    <label htmlFor="search conta">Busca</label>
                    <input style={{color: '#000'}}
                        autoFocus   
                        className={"form-control " }
                        type="text"
                        id="searchText"
                        name="searchText"
                        value={this.state.searchText}
                        placeholder="Pesquise pelo nome ou pelo número de chamada"
                        onChange={this.handleChange}
                        onKeyPress={this.handleKeyPress}
                    />
                </div>
                
                <br />

                {renderCadastrarButton()}

                <TipoDePagamentoTable
                    list = {this.state.filteredTipoDePagamentoList}
                    searchText={this.state.searchText}
                    selectable
                    loading = {this.state.loading}
                    handleSelectionTipoDePagamentoChange={this.handleSelectionTipoDePagamentoChange}
                    emptyMessage="Não há Tipos de Pagamento Associados"
                />

                <CreateTipoDePagamentoDialog
                    visible={this.state.displayCadastroTipoDePagamentoDialog}
                    search={this.search}
                    hideDialog={this.hideCadastroTipoDePagamentoDialog}
                    handleNewFornecedorCadastrado={this.handleNewFornecedorCadastrado}
                />


            </Dialog>
            </>
        )
    }

}

export default ChooseTipoDePagamentoDialog