import React from 'react'

import { Dialog } from 'primereact/dialog'

import DialogFooter from '../../dialogFooter'
import TipoProdutoService from '../../../app/service/tipoProdutoService'
import ConstantsUtil from '../../../context/constantsUtil'
import HandleErrorService from '../../../app/service/handleErrorService'
import * as popUp from '../../toastr'

class CreateOrUpdateTipoProdutoDialog extends React.Component {

    constructor(){
        super()
        this.tipoProdutoService = new TipoProdutoService()
    }

    state = {

        tipoDeProduto: {},
        inputErrorClass: '',

    }

    componentDidUpdate(){
        this.setStates()
    }

    setStates = () => {
        if( (this.props.visible && !this.state.didUpdated) || this.props.forceUpdate ){

            this.resetView()

            if(this.props.tipoDeProduto){
                this.setState({tipoDeProduto: this.props.tipoDeProduto})
                }
            else(
                this.setState({tipoDeProduto: {}})
            )

                
            this.setState({didUpdated: true})
            if(this.props.doneForceUpdate){
                this.props.doneForceUpdate()
            }
        }
    }

    resetView = () => {
        this.setState({inputErrorClass: ''})
    }

    handleChange = async event => {
        let tipoDeProduto = this.state.tipoDeProduto
        tipoDeProduto.descricao = event.target.value
        await this.setState({tipoDeProduto})
    }

    hideDialog = async () => {
        await this.setState({didUpdated: false})
        this.props.hideDialog()
    }

    checkData = () => {
        
        let check = true
        
        if(!this.state.tipoDeProduto || !this.state.tipoDeProduto.descricao){
            check = false
            this.setState({inputErrorClass: ConstantsUtil.bootstrapInputErrorClass})
        }

        return check

    }

    callSave = () => {
        this.resetView()
        if(this.checkData()){
            this.save()
        }
    }

    save = () => {
        this.tipoProdutoService.save(this.state.tipoDeProduto)
        .then(response => {
            this.hideDialog()
            popUp.successPopUp("Tipo de produto salvo com sucesso!")
            if(this.props.search){
                this.props.search()
            }
        })
        .catch(error => 
            HandleErrorService.handleError(error)
        )
    }

    render(){

        const dialogFooter = (
            <DialogFooter save = {this.callSave} hideDialog = {this.hideDialog}/>
        )

        return(
            <>
                <Dialog
                    header={this.props.isEdit ? 'Editar Tipo de Produto' : 'Cadastrar Tipo de Produto'}
                    visible={this.props.visible}
                    modal = {true}
                    style={{ width: '350px' }}
                    footer={dialogFooter}
                    onHide={this.hideDialog}
                >
                    <div className="p-field">
                        <label>Tipo de Produto</label>
                        <input
                            type="text"
                            autoFocus
                            className={"form-control " + this.state.inputErrorClass}
                            value = {this.state.tipoDeProduto.descricao}
                            onChange={this.handleChange}
                        />    
                        <div className="invalid-feedback">{"Informe o nome do tipo de produto"}</div>
                    </div>

                </Dialog>            
            </>
        )
    }

}

export default CreateOrUpdateTipoProdutoDialog