import React from 'react'
import GeneralServices from '../../app/service/generalServices'
import ConstantsUtil from '../../context/constantsUtil'
import SelectMenu from '../selectMenu'
import CustomCalendar from './customCalendar'
import MonthCalendar from './monthCalendar'
import { Button } from 'primereact/button'

const mesCompleto = ConstantsUtil.calendarMesCompletoFilterLabel
const anoCompleto = ConstantsUtil.calendarAnoCompletoFilterLabel
const personalizado = ConstantsUtil.calendarPersonalizadoFilterLabel
const monthSelectList = 
[
    {label: 'Selecione...', value: ''},
    {label:'Mês completo', value: mesCompleto},
    {label:'Personalizado', value: personalizado},
]

const yearSelectList = 
[
    {label: 'Selecione...', value: ''},
    {label:'Ano completo', value: anoCompleto},
    {label:'Personalizado', value: personalizado},
]

class DateIntervalCalendar extends React.Component{

    constructor(){
        super()
        this.monthDateRef = React.createRef()
        this.initialDateRef = React.createRef()
        this.finalDateRef = React.createRef()
        this.generalServices = new GeneralServices()
    }

    state = {
        selectedFilterType: '',

        selectedYear: '',

        initialDate: '',
        initialDateView: '',
        inputInitialDateErrorClass: '',
        errorInitialDateMessage: '',

        finalDate: '',
        finalDateView: '',
        inputFinalDateErrorClass: '',
        errorFinalDateMessage: '',

        monthDate: '',
        monthDateView: '',
        inputMonthDateErrorClass:'',
        errorMonthDateMessage: '',
        
        initialMonthDate: '',
        initialMonthDateView: '',
        
        finalMonthDate: '',
        finalMonthDateView: '',

        disabledFilterType: false,
        // disabledDataInicial: false,

        didUpdated: false,

    }

    componentDidMount(){
        // console.log("componentDidMount")

        if(this.props.defaultValue){
            const event = {target: {name: 'selectedFilterType', value: this.props.defaultValue}}
            this.handleFilterTypeChange(event)
        }

        this.trataPreSettedValues()

    }

    componentDidUpdate(){
        // console.log("componentDidUpdate")
        if( !this.state.didUpdated || this.props.forceUpdate){

            this.trataPreSettedValues()

            this.setState({didUpdated: true})
            
            if(this.props.doneForceUpdate){
                this.props.doneForceUpdate()
            }
        }        
    }

    trataPreSettedValues = async () => {
        if(this.props.preSetFilterType){
            const event = {target: {name: 'selectedFilterType', value: this.props.preSetFilterType}}
            await this.handleFilterTypeChange(event)
            // this.setState({disabledFilterType: true})
            this.setState({disabledFilterType: !this.props.enableChangeFilterType})
        }

        if(this.props.preSetDataInicial){
            const event = {value: this.props.preSetDataInicial, target: {name: "initialDate"}}
            await this.handleDateChange(event)
        }
        if(this.props.preSetDataFinal){
            const event = {value: this.props.preSetDataFinal, target: {name: "finalDate"}}
            await this.handleDateChange(event)
        }

        if(this.props.onFinishSetValues){
            await this.generalServices.sleep(100)
            this.props.onFinishSetValues()
        }

    }

    handleFilterTypeChange = async (event) => {
        const value = event.target.value
        const name = event.target.name
        await this.setState({ [name]: value })
        // console.log("selected: ", this.state.selectedFilterType)

        this.props.handleFilterChange(this.state.selectedFilterType)

        await this.setState({initialDate: ''})
        await this.setState({initialDateView: ''})
        await this.setState({finalDate: ''})
        await this.setState({finalDateView: ''})

        this.props.handleDateChange(this.state.initialDate, this.state.finalDate)
    
        if(this.state.selectedFilterType === mesCompleto){
            this.monthDateRef.current.inputRef.current.focus()
        }

        if(this.state.selectedFilterType === anoCompleto){
            // var select = document.getElementById('selectedYear')
            // select.focus();
        }

        else if(this.state.selectedFilterType === personalizado){
            if(this.props.preSetDataInicial && !this.props.preSetDataFinal){
                this.finalDateRef.current.inputRef.current.focus()
            }
            else if(!this.props.preSetDataInicial && !this.props.preSetDataFinal){
                this.initialDateRef.current.inputRef.current.focus()
            }
        }

        if(this.props.ano){
            const event = {target: {name: 'selectedYear', value: GeneralServices.selectYearRange()[1].value}}
            this.handleYearChange(event)
        }

    }

    handleYearChange = async (event) => {
        const value = event.target.value
        const name = event.target.name
        await this.setState({ [name]: value })

        const selectedYear = this.state.selectedYear

        this.setState({initialDate: '01-01-'+ selectedYear})
        await this.setState({finalDate: '31-12-'+ selectedYear})

        this.props.handleDateChange(this.state.initialDate, this.state.finalDate)
    }

    handleDateChange = async (event) => {
        const value = event.value
        var name = event.target.name
        
        const previousValue = JSON.parse(JSON.stringify(this.state[name]))

        let valueDate = GeneralServices.convertJSDateToBrDate(value)
        await this.setState({ [name]:  valueDate})
        // await this.setState({ initialDate:  valueDate})
        var nameView = name+"View"
        await this.setState({ [nameView]: value })
        // console.log("initial in DateIntervalCalendar: ", name, this.state.initialDate)
        // console.log("final in DateIntervalCalendar: ", nameView, this.state.finalDate)
        this.props.handleDateChange(this.state.initialDate, this.state.finalDate)

        const change = this.state[name] !== '' && (this.state[name] !== previousValue)
        if(change && name === 'initialDate' && !this.props.preSetDataFinal){
            this.finalDateRef.current.inputRef.current.focus()
        }
    }

    handleUniqueMonthDateChange = async (event) => {
        var value = event.value
        if(value !== null){
            value.setDate(1) //seta para o dia 1° do mês
        }

        var name = event.target.name

        await this.setState({ [name]: GeneralServices.convertJSDateToBrDate(value) })
        var nameView = name+"View"
        await this.setState({ [nameView]: value })
        // console.log("initialMonthDate - 0: ", this.state.initialMonthDate)
        // console.log("finalMonthDate - 0: ", this.state.finalMonthDate)
    }

    handleInitialMonthDateChange = async (event) => {
        await this.handleUniqueMonthDateChange(event)
        this.props.handleInitialMonthDateChange(this.state.initialMonthDate)
        // console.log("initialMonthDate: ", this.state.initialMonthDate)
    }

    handleFinalMonthDateChange = async (event) => {
        await this.handleUniqueMonthDateChange(event)
        this.props.handleFinalMonthDateChange(this.state.finalMonthDate)
    }

    handleMonthDate = async (event) => {
        await this.handleUniqueMonthDateChange(event)
        // console.log("monthView: ", this.state.monthDateView)
        // console.log("month: ", this.state.monthDate)

        this.setPeriodFromMonthDate()
    }

    setPeriodFromMonthDate = async () => {
        var finalDate =  this.state.monthDateView

        try{
            finalDate.setMonth(finalDate.getMonth()+1);
            finalDate.setDate(finalDate.getDate() - 1);
            await this.setState({finalDate: GeneralServices.convertJSDateToBrDate(finalDate)})

        } catch(e){
            await this.setState({finalDate: ''})
        }

        await this.setState({initialDate: this.state.monthDate})
        
        // console.log("initial date: ", this.state.initialDate)
        // console.log("final date: ", this.state.finalDate)
        this.props.handleDateChange(this.state.initialDate, this.state.finalDate)
    }

    inputSelectFilterTypeErrorClass = () => {
        if(this.props.missingFilterType)
            return "is-invalid"
    }

    inputDateErrorClasses = () => {

        var monthErrorObj={
            inputErrorClass: '',
            errorMessage: ''
        }
        
        var initialDateErrorObj={
            inputErrorClass: '',
            errorMessage: ''
        }
        
        var finalDateErrorObj={
            inputErrorClass: '',
            errorMessage: ''
        }
        
        var initialMonthErrorObj={
            inputErrorClass: '',
            errorMessage: ''
        }

        var finalMonthErrorObj={
            inputErrorClass: '',
            errorMessage: ''
        }


        if(this.props.missingAnyDate){
            
            if(this.state.selectedFilterType === mesCompleto && !this.state.monthDate){
                monthErrorObj.inputErrorClass=ConstantsUtil.primereactInputErrorClass
                monthErrorObj.errorMessage="Informe o mês"
                
            }

            if(this.state.selectedFilterType === personalizado && !this.props.ano && !this.state.initialDate){
                initialDateErrorObj.inputErrorClass=ConstantsUtil.primereactInputErrorClass
                initialDateErrorObj.errorMessage="Informe a data"
            }

            if(this.state.selectedFilterType === personalizado && !this.props.ano && !this.state.finalDate){
                finalDateErrorObj.inputErrorClass=ConstantsUtil.primereactInputErrorClass
                finalDateErrorObj.errorMessage="Informe a data"
            }

            if(this.state.selectedFilterType === personalizado && this.props.ano && !this.state.initialMonthDate){
                initialMonthErrorObj.inputErrorClass=ConstantsUtil.primereactInputErrorClass
                initialMonthErrorObj.errorMessage="Informe o mês"
            }

            if(this.state.selectedFilterType === personalizado && this.props.ano && !this.state.finalMonthDate){
                finalMonthErrorObj.inputErrorClass=ConstantsUtil.primereactInputErrorClass
                finalMonthErrorObj.errorMessage="Informe o mês"
            }

        }

        return [monthErrorObj, initialDateErrorObj, finalDateErrorObj, initialMonthErrorObj, finalMonthErrorObj]
    }

    render(){

        const renderMonthCalendar = () => {
            if(this.state.selectedFilterType === mesCompleto)
            return(
                <MonthCalendar
                    ref={this.monthDateRef}
                    id="monthpicker"
                    name="monthDate"
                    value={this.state.monthDate}
                    onChange={this.handleMonthDate}
                    htmlFor="dataLancamentoFim"
                    label="Mês"
                    disabled={this.props.disabled}
                    // inputDateErrorClass={this.state.inputMonthDateErrorClass}
                    inputDateErrorClass={this.inputDateErrorClasses()[0].inputErrorClass}
                    // errorDateMessage={this.state.errorMonthDateMessage}
                    errorDateMessage={this.inputDateErrorClasses()[0].errorMessage}
                />
            )
        }

        const renderTwoMonthsCalendar = () => {
            if(this.state.selectedFilterType === personalizado && this.props.ano)
            return(
                <>
                <MonthCalendar
                    ref={this.monthDateRef}
                    id="initialMonthDate"
                    name="initialMonthDate"
                    value={this.state.initialMonthDate}
                    onChange={this.handleInitialMonthDateChange}
                    htmlFor="initialMonthDate"
                    label="Mês Inicial"
                    disabled={this.props.disabled}
                    // inputDateErrorClass={this.state.inputMonthDateErrorClass}
                    inputDateErrorClass={this.inputDateErrorClasses()[3].inputErrorClass}
                    // errorDateMessage={this.state.errorMonthDateMessage}
                    errorDateMessage={this.inputDateErrorClasses()[3].errorMessage}
                />

                <MonthCalendar
                    ref={this.monthDateRef}
                    id="finalMonthDate"
                    name="finalMonthDate"
                    value={this.state.finalMonthDate}
                    onChange={this.handleFinalMonthDateChange}
                    htmlFor="finalMonthDate"
                    label="Mês Final"
                    disabled={this.props.disabled}
                    // inputDateErrorClass={this.state.inputMonthDateErrorClass}
                    inputDateErrorClass={this.inputDateErrorClasses()[4].inputErrorClass}
                    // errorDateMessage={this.state.errorMonthDateMessage}
                    errorDateMessage={this.inputDateErrorClasses()[4].errorMessage}
                />
                </>
            )
        }

        const renderYearCalendar = () => {
            if(this.state.selectedFilterType === anoCompleto){
                return(
                    <div className = "col-md-2">
                        <label>Ano</label>
                        <SelectMenu
                            className={"form-control " }
                            id="selectedYear"
                            name="selectedYear"
                            list= {GeneralServices.selectYearRange()}
                            value={this.state.selectedYear}
                            onChange={this.handleYearChange}
                            disabled={this.props.disabled}
                        />
                    </div>
                )
            }
        }

        const renderInitialAndFinalCalendars = () => {
            if(this.state.selectedFilterType === personalizado && !this.props.ano)
            return (
                <>
                <CustomCalendar
                    ref={this.initialDateRef}
                    id="initialLancamentoDate"
                    htmlFor="dataLancamentoInicio"
                    // label={this.props.dataInicialLabel}
                    label="Data inicial"
                    inputDateErrorClass={this.inputDateErrorClasses()[1].inputErrorClass}
                    name="initialDate"
                    value={this.state.initialDateView}
                    onChange={this.handleDateChange}
                    disabled={this.props.disabled || this.props.disabledDataInicial}
                    errorDateMessage={this.inputDateErrorClasses()[1].errorMessage}
                />

                <CustomCalendar
                    ref={this.finalDateRef}
                    id="finalLancamentoDate"
                    htmlFor="dataLancamentoFim"
                    // label={this.props.dataFinalLabel}
                    label="Data final"
                    inputDateErrorClass={this.inputDateErrorClasses()[2].inputErrorClass}
                    value={this.state.finalDateView}
                    name="finalDate"
                    onChange={this.handleDateChange}
                    disabled={this.props.disabled}
                    errorDateMessage={this.inputDateErrorClasses()[2].errorMessage}
                />
                </>
            )
        }

        const renderTooltip = () => {
            if(this.props.tooltip){
                return (
                    <Button
                        icon={<i className="pi pi-question" style={{fontSize: '0.8em'}}></i>}
                        className="p-button-rounded p-mr-2 medium-font"
                        style={ {display: this.state.selectedTipoDeRecebimentoValue === '' ? 'none' : '',
                            marginLeft: '3px', maxHeight: '25px', maxWidth: '25px'}}
                        // style={ {maxHeight: '25px', maxWidth: '25px'} }
                        display={false}
                        tooltip={this.props.tooltip}
                        tooltipOptions={{position: 'top'}}
                    />
                )
            }
        }

        return(
            <>
            <div className = "col-md-3">
            <div className = "row">
            <div className = "col-md-5">
                <label htmlFor={this.props.htmlFor}>{this.props.label ? this.props.label : ConstantsUtil.escolherPeriodoLabel}</label>
           </div>
            <div className = "col-md-1">
                {renderTooltip()}
           </div>
           </div>
            <SelectMenu 
                className={"form-control " + this.inputSelectFilterTypeErrorClass() }
                id="selectedFilterType"
                name="selectedFilterType"
                list= { this.props.ano ? yearSelectList : monthSelectList}
                value={this.state.selectedFilterType}
                onChange={this.handleFilterTypeChange}
                disabled={this.props.disabled || this.state.disabledFilterType}
            />
            {/* <div className="invalid-feedback">{this.state.errorNaturezaMessage}</div> */}
            <div className="invalid-feedback">Informe o período</div>
            </div>

            {renderInitialAndFinalCalendars()}

            {renderMonthCalendar()}

            {renderTwoMonthsCalendar()}

            {renderYearCalendar()}

            </>

        )
    }




}

export default React.forwardRef((props, ref) => <DateIntervalCalendar innerRef={ref} {...props} />);