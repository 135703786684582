import CentralApiService from "../centralApiServices"

class LoginService extends CentralApiService{
    
    constructor() {
        super('/api/auth')
    }

    auth (credentials){
        return this.post('/login', credentials)
    }

    loginToEmpresa (tenantName){
        let params = `?`
        params = `${params}&tenantName=${tenantName}`
        return this.get(`/loginToEmpresa${params}`)
    }
    
    preLogin(credentials){
        return this.post('/preLogin', credentials)
    }

    checkSession() {
        return this.get('/checkSession')
    }
}

export default LoginService