import WorkerApiService from "../../workerApiServices";

class RelatorioFechamentoCaixaService extends WorkerApiService {
 
    constructor() {
        super('/api/relatorioFechamentoCaixa')
    }

    getRelatorioFechamentoCaixaByDateInterval(obj, extraHeaderObj){
        return this.post("/searchByDateInterval", obj, extraHeaderObj)
    }
    
}

export default RelatorioFechamentoCaixaService