import React from 'react'
import { Toast } from 'primereact/toast'
import { Button } from 'primereact/button'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import EditPDVCaixaDialog from './editPDVCaixaDialog'

class PDVCaixaTable extends React.Component {

    state = {
        pdvCaixaToEdit: '',

        editPDVCaixaDialog: false,
    }

    editPDVCaixa = (pdvCaixa) => {
        this.setState({pdvCaixaToEdit: JSON.parse(JSON.stringify(pdvCaixa))})

        this.setState({editPDVCaixaDialog: true})
    }

    hideDialog = () => {
        this.setState({editPDVCaixaDialog: false})
    }

    render() {

        const actionBodyTemplate = (pdvCaixa) => {
            return (
                <React.Fragment>
                    <Button title = "Editar Ponto de Venda (Caixa)"
                            icon="pi pi-pencil"
                            className={ "p-button-rounded p-mr-2 " }
                            onClick={() => this.editPDVCaixa(pdvCaixa)}
                            style={ {maxHeight: '30px', maxWidth: '30px'} }
                    />
                </React.Fragment>
            );
        }

        return (
            <div className="datatable-crud-demo">
            <Toast ref={this.toast} />

            <div className="card">
                {/* <Toolbar className="p-mb-4" left={leftToolbarTemplate} 
                // right={rightToolbarTemplate}
                /> */}

                <DataTable ref={this.dt} value={this.props.list}
                            className="p-datatable-sm small-font"
                            rowHover
                            emptyMessage="Não há Ponto de Venda Cadastrado."
                            // selection={this.state.selectedContasBancaria}
                            // onSelectionChange={this.handleSelectionChange}
                            // scrollable
                            // scrollHeight="500px"
                            loading={this.props.loading}
                            dataKey="id"
                            // paginator rows={25} rowsPerPageOptions={[5, 10, 25]}
                            // paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            // currentPageReportTemplate="Mostrando de {first} ao {last} de {totalRecords} contas bancárias"
                    >

                    {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column> */}

                    <Column field="nome" header="Nome" sortable 
                    />

                    <Column field="descricao" header="Descrição" sortable 
                    />

                    <Column header="Editar" body={actionBodyTemplate} style ={ {width: '140px'} }></Column>
                </DataTable>
            </div>

            <EditPDVCaixaDialog  
                hideDialog={this.hideDialog}
                visible={this.state.editPDVCaixaDialog}
                header="Editar Ponto de Venda (Caixa)"
                state={this.state.pdvCaixaToEdit}
                search={this.props.search}
                push={this.props.push}
            />
            </div>
        )
    }

}

export default PDVCaixaTable