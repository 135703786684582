import WorkerApiService from "../../workerApiServices";

class LivroRazaoService extends WorkerApiService {
 
    constructor() {
        super('/api/livroRazao')
    }

    getByDateInterval(obj){
        return this.post("/searchByDateInterval", obj)
    }

    delete(obj){
        return this.post("/delete", obj)
    }
}

export default LivroRazaoService