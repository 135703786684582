import React from 'react'
import GeneralServices from '../../app/service/generalServices';
import HandleErrorService from '../../app/service/handleErrorService';
import PlanoDeContasService from '../../app/service/planoDeContasService';
import ConstantsUtil from '../../context/constantsUtil';
import ChooseContaContabilDialog from './chooseContaContabilDialog'

class InputChooseContaContabil extends React.Component {

    constructor(){
        super();
        this.planoDeContasService = new PlanoDeContasService();
        this.generalServices = new GeneralServices();
    }

    state = {
        chooseContaContabilDialogVisible: false,
        renderContaContabil: false,
        loadingTable: false,

        planoDeContasList: [],
        planoDeContasListToDialog: [],

        forceUpdateOnDialog: false,

    }
    componentDidMount(){
        this.initialize()
    }

    initialize = async () => {
        
        this.search()

        if(this.props.renderDialogOnMount){
            this.showDialog()
        }
    }

    search = () => {

        this.setState({loadingTable: true})
        // this.setState({nomeContaSearch: ''})

        this.planoDeContasService.search()
        .then(async response => {
           await this.setState({planoDeContasList: response.data})
            // this.setState({finishedSearch: true})
            this.setState({loadingTable: false})
        }).catch(error => {
            HandleErrorService.handleError(error)
            this.setState({loadingTable: false})
        })
    }

    hideContaContabilDialog = () => {
        this.setState({chooseContaContabilDialogVisible: false})
    }

    handleSelectionContaContabilChange = (contaContabil) => {
        this.props.handleSelectionContaContabilChange(contaContabil, contaContabil.nomeConta)
    }

    showDialog = () => {
        this.setState({renderContaContabil: true})
        this.setState({chooseContaContabilDialogVisible: true})
        this.setState({forceUpdateOnDialog: true})
    }

    handleKeyPress = async (e) => {
        if (e.key === "Enter") {
            this.showDialog()
          }
    }

    fastHideContaContabilDialog = () => {
        this.setState({renderContaContabil: false})
    }

    doneForceUpdate = () => {
        this.setState({forceUpdateOnDialog: false})
    }

    getLabel = () => {
        if(this.props.tipoLancamentoBancario){
            if(this.props.tipoLancamentoBancario === ConstantsUtil.lancamentoBancarioRecebimentoLabel){
                return "Conta Crédito"
            }
            else{
                return "Conta Débito"
            }
        }
        else{
            return 'Conta Contábil'
        }
        
    }

    render(){

        const renderContaContabil = () => {
            if(this.state.renderContaContabil)
            return <ChooseContaContabilDialog
                header="Plano de Contas"
                visible={this.state.chooseContaContabilDialogVisible}
                // list={this.state.planoDeContasListToDialog}
                list={this.state.planoDeContasList}
                hideDialog={this.hideContaContabilDialog}
                loadingTable={this.state.loadingTable}
                handleSelectionContaContabilChange={this.handleSelectionContaContabilChange}
                forceUpdate={this.state.forceUpdateOnDialog}
                doneForceUpdate={this.doneForceUpdate}
                fastHideDialog={this.fastHideContaContabilDialog}
            />
        }

        const renderInput = () => {
            return (
                <>
                <label htmlFor="planoDeContas">{this.getLabel()}</label>
                    <input style={{color: '#000'}} type="text"
                                    className={"form-control " + this.props.inputContaContabilErrorClass}
                                    readOnly
                                    value = {this.props.selectedContaContabilLabel}
                                    name="selectedTipoDeRecebimentoLabel"
                                    id="selectedTipoDeRecebimentoLabel"
                                    onClick={this.showDialog}
                                    onKeyPress={this.handleKeyPress}
                                    placeholder="Nenhuma Conta Contábil Selecionada..."
                                    disabled={this.props.disabled}
                    />
                    <div className="invalid-feedback">
                        {
                            this.props.errorContaContabilMessage ? this.props.errorContaContabilMessage : "Informe a Conta Contábil"
                            
                        }
                        </div> 
                    </>
            )
        }

        const renderHTMLFormat = () => {
            if(this.props.noHTMLFormat){
                return (
                    <>
                    {renderInput()}
                    </>
                )
            }
            else {
                return (
                    <>
                    {/* <div className="row"> */}
                    <div className="col-md-3">
                    {renderInput()}
                    </div>
                    {/* </div> */}
                    </>
                )
            }
        }


        return(
            <>
                {/* <div className="col-md-3">
                    
                    </div> */}

                {renderHTMLFormat()}
                    
                {renderContaContabil()}

            </>
        )

    }

}

export default InputChooseContaContabil