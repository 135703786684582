import React from 'react'

import { Dialog } from 'primereact/dialog'

import { Button } from 'primereact/button'
import TipoProdutoTable from './tipoProdutoTable'
import DialogFooter from '../../dialogFooter'
import TipoProdutoService from '../../../app/service/tipoProdutoService'
import CreateOrUpdateTipoProdutoDialog from './createOrUpdateTipoProdutoDialog'
import GeneralServices from '../../../app/service/generalServices'

class ChooseTipoProdutoDialog extends React.Component {

    constructor()
    {
        super()
        this.tipoProdutoService = new TipoProdutoService()
        this.generalServices = new GeneralServices()
        this.dt = React.createRef()
    }

    state = {

        selectedTipoProduto: '',
        tipoProdutoList: [],
        filteredTipoProdutoList: [],
        
        searchText: '',

        displayCreateDialog: false,
        
        didUpdated: false,
        

    }

    handleChange = async (event) => {
        const value = event.target.value
        const name = event.target.name
        await this.setState({ [name]: value })
        this.dt.current.filter()
    }

    handleKeyPress = async (e) => {
        if (e.key === "Enter") {
            const filteredList = JSON.parse(JSON.stringify(this.state.filteredTipoProdutoList))
            if(filteredList.length === 1){
                await this.setState({selectedTipoProduto: filteredList[0]})
                this.callSave()
            }
          }
    }

    handleSelectionChange = async (value) => {
        await this.generalServices.sleep(100)
        if(this.state.selectedTipoProduto && value === null && !this.dt.current.isEditDialogOpen()){
            /*
            Significa que clicou em cima do item que estava selecionado antes (double click), por isso ficou null.
            Mas, se preocupa com o caso de esse 2° click ter sido em cima do botão de editar. Neste caso, não significa que selecinou.
            */
            this.callSave()
        } else{
            this.setState({selectedTipoProduto: value})
        }

        // this.setState({selectedTipoProduto: value})
    }

    hideDialog = async () => {
        await this.setState({didUpdated: false})
        this.props.hideDialog()
    }

    callSave = async () => {
        if(this.state.selectedTipoProduto){
            await this.props.handleSelectionChange(this.state.selectedTipoProduto)
            this.hideDialog()
        }
    }

    search = () => {
        this.dt.current.search()
    }


    render () {

        // const renderEditDialog = {}

        const dialogFooter = (
            <DialogFooter save = {this.callSave} hideDialog = {this.hideDialog} disabled={this.props.disabled}/>
        )

        return (
            <>
            <Dialog 
                visible={this.props.visible}
                style={{ width: '70vw' }}
                header={this.props.header ? this.props.header : 'Tipo de Produto'}
                modal
                className="p-fluid"
                footer={dialogFooter}
                onHide={this.hideDialog}
            >

                <div className="p-field">
                    <label htmlFor="search conta">Busca</label>
                    <input style={{color: '#000'}}
                        autoFocus  
                        className={"form-control " }
                        type="text"
                        id="searchText"
                        name="searchText"
                        value={this.state.searchText}
                        placeholder="Pesquise por código ou descrição"
                        onChange = {this.handleChange}
                        onKeyPress={this.handleKeyPress}
                    />
                </div>

                <br />

                <div className="row">
                <div className="col-md-4">
                <Button
                    label="Cadastrar Tipo de Produto"
                    icon="pi pi-plus"
                    className=" small-font"
                    style={{maxHeight: '25px', marginLeft: '10px'}}
                    onClick={() => this.setState({displayCreateDialog: true})}
                />
                </div>
                </div>

                <br />

                <TipoProdutoTable
                    ref={this.dt}
                    list = { this.state.filteredTipoProdutoList }
                    simpleView
                    searchText={this.state.searchText}
                    isBuyProducts={this.props.isBuyProducts}
                    handleSelectionChange={this.handleSelectionChange}
                    selectable={true}
                    loading = {this.state.loadingContaBancariaTable}
                /> 


            </Dialog>

            <CreateOrUpdateTipoProdutoDialog
                visible={this.state.displayCreateDialog}
                // tipoDeProduto={}
                hideDialog={() => this.setState({displayCreateDialog: false})}
                search={this.search}
            />

            </>
        )
    }


}

export default ChooseTipoProdutoDialog