import React from 'react'
import Card from '../../components/card'
import { withRouter } from 'react-router-dom'

import { AuthContext } from '../../main/authProvider'
import GeneralServices from '../../app/service/generalServices'
import HandleErrorService from '../../app/service/handleErrorService'
import { Button } from 'primereact/button'
import DateIntervalCalendar from '../../components/calendar/dateIntervalCalendar'
import ConstantsUtil from '../../context/constantsUtil'
import InputChooseContaContabil from '../../components/planoDeContas/inputChooseContaContabil'
import LivroRazaoTable from '../../components/relatorios/livroRazaoTable'
import LivroRazaoService from '../../app/service/relatorios/livroRazaoService'

class LivroRazao extends React.Component {

    constructor(){
        super();
        this.generalServices = new GeneralServices();
        this.livroRazaoService = new LivroRazaoService();

    }
    
    state = {

        selectedContaContabil: '',
        selectedContaContabilLabel: '',
        inputContaContabilErrorClass: '',

        loading: false,

        livroRazao: [],

        selectedDateFilterType: '',
        missingFilterType: false,
        missingAnyDate: false,
        initialDate: '',
        initialDateView: '',
        finalDate: '',
        finalDateView: '',

        isSearchingLancamentos: false,
        isFinishedSearch: false,

        habilitaBusca: true,

    }


    componentDidMount(){
    }

    resetDateView = () => {
        this.setState({initialDate: ''})
        this.setState({initialDateView: ''})
        this.setState({finalDate: ''})
        this.setState({finalDateView: ''})
    }

    handleSelectionContaContabilChange = (selectedContaContabil, selectedContaContabilLabel) => {
        this.setState({selectedContaContabil})
        this.setState({selectedContaContabilLabel})
    }

    handleNavigatorDateChange = (event, e) => {
        e.onChange(event.originalEvent, event.value)
    }

    handleDateFilterChange = (selectedDateFilterType) => {
        this.setState({selectedDateFilterType})
    }

    handleDateChange = async (initialDate, finalDate) => {
        await this.setState({initialDate})
        
        await this.setState({initialDateView: GeneralServices.convertBrStringToJsDate(initialDate)})

        await this.setState({finalDate})
        await this.setState({finalDateView: GeneralServices.convertBrStringToJsDate(finalDate)})

    }

    resetView = () => {
        this.setState({inputContaContabilErrorClass: ''})

        this.setState({livroRazao: []})
        this.setState({missingFilterType: false})
        this.setState({missingAnyDate: false})

    }

    checkData = () => {

        var check = true

        if(!this.state.selectedContaContabil){
            this.setState({inputContaContabilErrorClass: ConstantsUtil.bootstrapInputErrorClass})
            check=false
        }

        if(!this.state.selectedDateFilterType){
            this.setState({missingFilterType: true})
            check=false
        }
        
        if(!this.state.initialDate || !this.state.finalDate){
            this.setState({missingAnyDate: true})
            check=false
        }

        return check
    }

    toggleButton = () => {
        this.setState({habilitaBusca: !this.state.habilitaBusca})
    }

    callSearch = () => {

        this.resetView()

        if(this.checkData()){
            console.log("ue caraiii")
            this.search()
        }
    }

    search = () => {
        this.setState({loading: true})
        this.toggleButton()
        this.setState({isSearchingLancamentos: true})
        this.setState({isFinishedSearch: false})


        const obj = {
            contaContabilId: parseInt(this.state.selectedContaContabil.numeroDeChamada)/5,
            dataInicial : this.state.initialDate,
            dataFinal : this.state.finalDate
        }

        // console.log("obj: ", obj)

        this.livroRazaoService.getByDateInterval(obj)
        .then(response => {
            this.setState({livroRazao: response.data})
            // console.log("ficha: ", response.data)

            this.setState({isSearchingLancamentos: false})
            this.setState({isFinishedSearch: true})

        })
        .catch(error => {
            HandleErrorService.handleError(error)
            this.setState({isSearchingLancamentos: false})
            this.setState({isFinishedSearch: true})
        })
        .finally(() => {
            this.setState({loading: false})
        })
    }

    handleMuiChange = async (event, value) => {
        const realValue = value ? value.value : ''
        await this.setState({ selectedTipoDeRecebimentoValue: realValue })
        const obj = this.state.tiposDeRecebimento.find(tipoDeRecebimento => 
            tipoDeRecebimento.nomeTipoDeRecebimento === this.state.selectedTipoDeRecebimentoValue)
        this.setState({ selectedTipoDeRecebimentoObj: obj })
    }

    handleMuiKeyUp = (event) => {
        if(event.code === "Enter" || event.code === "NumpadEnter"){
            this.callSearch()
        }
    }

    render() {

        const renderCalendarFilters = () => {
                return (
                    <>
                        <DateIntervalCalendar
                            htmlFor="SelectDatePeriod"
                            label="Escolher período"
                            handleFilterChange={this.handleDateFilterChange}
                            handleDateChange={this.handleDateChange}
                            missingFilterType={this.state.missingFilterType}
                            missingAnyDate={this.state.missingAnyDate}
                            disabled={!this.state.habilitaBusca}
                        />
                    </>
                )
        }

        const renderLivroRazaoTable = () => {
            if( (this.state.isFinishedSearch && !this.state.habilitaBusca) || this.state.loading )
            return (
                <>
                <div className="bs-docs-section" >
                    <LivroRazaoTable
                        livroRazao = {this.state.livroRazao}
                        search={this.callSearch}
                        loading={this.state.loading}
                        push={this.props.history.push} 
                    />
                </div>
                </>
            )
        }

        const renderButtons = () => {
            if(this.state.habilitaBusca){
                return (
                    <Button 
                        label="Buscar Lançamentos"
                        icon="pi pi-search"
                        onClick = {this.callSearch}
                        // style={ {maxHeight: '35px' } }
                        disabled = {this.state.isSearchingLancamentos}
                    />
                )
            }
            return (
                <>
                    <Button 
                        label="Voltar"
                        icon="pi pi-undo"
                        className="p-button-danger"
                        onClick = {this.toggleButton}
                        // style={ {maxHeight: '35px' } }
                        // disabled = {this.state.isSearchingLancamentos}
                    />
                </>
            )
        }

        return(
            <div className="bs-docs-section" >
            <Card title = "Livro Razão">
                <div className = "col-md-12">

                <div className="row">

                <InputChooseContaContabil
                    selectedContaContabilLabel={this.state.selectedContaContabilLabel}
                    // tipoLancamentoBancario={this.state.tipoLancamentoBancario}
                    push={this.props.history.push}
                    handleSelectionContaContabilChange={this.handleSelectionContaContabilChange}
                    inputContaContabilErrorClass={this.state.inputContaContabilErrorClass}
                    // errorContaContabilMessage={this.state.errorContaContabilMessage}
                    disabled={!this.state.habilitaBusca}
                />
                    
                    {renderCalendarFilters()}

                </div>  
                <br />

                {renderButtons()}
                
                </div> {/* col-md-12 */}


                {renderLivroRazaoTable()}


            </Card>
            <div className="d-flex "/>
        </div>              
        )
    }

}

LivroRazao.contextType = AuthContext

export default withRouter(LivroRazao)