import WorkerApiService from "../../workerApiServices";

class FluxoCaixaPrevistoService extends WorkerApiService {
 
    constructor() {
        super('/api/fluxoCaixaPrevisto')
    }

    getMensal(dataInicial, dataFinal){
        let params = `?`
        params = `${params}&dataInicial=${dataInicial}`
        params = `${params}&dataFinal=${dataFinal}`
        return this.get(`/getMensal${params}`)
    }

    getDetalhamento(obj){
        return this.post(`/getDetalhamento`, obj)
    }

}

export default FluxoCaixaPrevistoService