import React from 'react'

import { Dialog } from 'primereact/dialog'

import DialogFooter from '../dialogFooter'
import * as popUp from '../toastr'
import HandleErrorService from '../../app/service/handleErrorService'
import GrupoDePagamentoService from '../../app/service/contasAPagar/grupoDePagamentoService'
import GeneralServices from '../../app/service/generalServices'
import ConstantsUtil from '../../context/constantsUtil'
import CheckBoxComponent from '../relatorios/checkBoxComponent'

class EditOrCreateGrupoDePagamentoDialog extends React.Component {

    constructor(){
        super()
        this.grupoDePagamentoService = new GrupoDePagamentoService()
        this.generalServices = new GeneralServices()
    }

    state = {
        id: null,

        nome: '',
        inputNomeErrorClass:'',
        errorNomeMessage: 'Digite o nome',

        destacadoDRE: false,
        ausenteDreCompetencia: false,

        isSaving: false,

    }

    componentDidUpdate(){
        this.setStates()
    }

    setStates = () => {
        if( (this.props.visible && !this.state.didUpdated) || this.props.forceUpdate ){

            if(this.props.state){
                // console.log("state: ", this.props.state)
                this.setState({id: this.props.state.id})
                this.setState({nome: this.props.state.nome})
                this.setState({destacadoDRE: this.props.state.destacadoDRE})
                this.setState({ausenteDreCompetencia: this.props.state.ausenteDRECompetencia})
            }
                
            this.setState({didUpdated: true})
            if(this.props.doneForceUpdate){
                this.props.doneForceUpdate()
            }
        }
    }

    hideDialog = () => {
        this.setState({didUpdated: false})
        this.props.hideDialog()
    }

    handleChange = (event) => {
        const value = event.target.value
        const name = event.target.name
        this.setState({ [name]: value })
    }

    handleKeyUp = async (event) => {
        
        await this.generalServices.sleep(100)

        if(this.state.isSaving){
            return
        }

        if(event.code === "Enter" || event.code === "NumpadEnter"){
            this.callSave()
        }
    }

    resetView = () => {
        this.setState({inputNomeErrorClass: ''})
    }

    resetData = () => {
        this.setState({nome: ''})
    }

    checkData = () => {
        var check = true

        if(!this.state.nome){
            this.setState({inputNomeErrorClass: "is-invalid"})
            check = false
        }

        return check

    }

    callSave = async () => {
        this.resetView()
        if(this.checkData()){

            await this.generalServices.sleep(100)
            if(this.state.isSaving){
                return
            }

            if(this.props.dialogType === ConstantsUtil.tipoDialogGrupoDePagamentoCreateLabel){
                this.create()
            }
            else{
                this.update()
            }
        }
    }

    create = () => {
        this.setState({isSaving: true})
        this.grupoDePagamentoService.save(
            {
                nome : this.state.nome.trim().toUpperCase(),
                destacadoDRE: this.state.destacadoDRE,
                ausenteDreCompetencia: this.state.ausenteDreCompetencia
            }
        )
        .then(response => {
            popUp.successPopUp("Grupo de Pagamento cadastrado com sucesso!")
            this.hideDialog()
            this.resetData()
            this.props.search()
            // this.resetForm()
            // this.search()
            // this.inputNome.current.focus()
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
        .finally(async () => {
            await this.generalServices.sleep(1000)
            this.setState({isSaving: false})
        })
    }

    update = () => {

        this.setState({isSaving: true})

        this.grupoDePagamentoService.update(
        {
            id: this.state.id,
            nome: this.state.nome.trim().toUpperCase(),
            destacadoDRE: this.state.destacadoDRE,
            ausenteDRECompetencia: this.state.ausenteDreCompetencia
        }
        )
        .then(async response => {
            popUp.successPopUp("Grupo de Pagamento alterado com sucesso!")
            this.props.search()
            this.hideDialog()
            this.setState({didUpdated: false})

            await this.generalServices.sleep(1000)
            this.setState({isSaving: false})

        }).catch(async error => {
            await this.generalServices.sleep(1000)
            this.setState({isSaving: false})
            HandleErrorService.handleError(error)
        })
        this.setState({didUpdated: false})
    }

    render () {

        const editTipoRecebimentoDialogFooter = (
            <DialogFooter save = {this.callSave} hideDialog = {this.hideDialog} disabled={this.props.disabled}/>
        )

        return (
            <>
            <Dialog visible={this.props.visible} style={{ width: '450px' }}
                    header={this.props.header}
                    modal
                    className="p-fluid"
                    footer={editTipoRecebimentoDialogFooter}
                    onHide={this.hideDialog}>

                <div className="p-field">
                    <label htmlFor="nome">Nome</label>
                    <input style={{color: '#000'}}
                            type="text"
                            className={"form-control " + this.state.inputNomeErrorClass}
                            id="nome"
                            autoFocus
                            value = {this.state.nome}
                            name="nome"
                            onChange={this.handleChange}
                            onKeyDown={this.handleKeyUp}
                            // onKeyDown={this.handleKeyUp}
                            disabled={this.props.disabled}
                            />
                    <div className="invalid-feedback">{this.state.errorNomeMessage}</div>
                </div>

                <br />
                <div className="col-md-12">
                <CheckBoxComponent
                    label={ConstantsUtil.destacadoNoDRECheckBoxLabel}
                    checked={this.state.destacadoDRE}
                    handleCheckBoxChange={(checked) => this.setState({destacadoDRE: checked})}
                />
                </div>

                <br />
                <div className="col-md-12">
                <CheckBoxComponent
                    label={ConstantsUtil.ausenteNoDREPorCompetenciaCheckBoxLabel}
                    checked={this.state.ausenteDreCompetencia}
                    handleCheckBoxChange={(checked) => this.setState({ausenteDreCompetencia: checked})}
                />
                </div>

            </Dialog>
            
            </>
        )    
    }

}

export default EditOrCreateGrupoDePagamentoDialog