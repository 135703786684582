import axios from 'axios'
import AuthService from './service/authService'

class ApiFileService {

    constructor(api_url){
        this.api_url = api_url
    }

    post(url, object){
        // const token = AuthService.token() ? AuthService.token() : ''
        return axios.post(this.api_url + url, object, {
            headers: {
                // 'Authorization': `Bearer ${token}`,
                'Accept' : 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        }
            )
    }

    // put(url, object){
    //     const token = AuthService.token() ? AuthService.token() : ''
    //     return axios.put(this.api_url + url, object, {
    //         headers: {
    //             'Authorization': `Bearer ${token}`,
    //             'Accept' : 'application/json',
    //             'Content-Type': 'application/json'
    //         }
    //     })
    // }

    // delete(url){
    //     const token = AuthService.token() ? AuthService.token() : ''
    //     return axios.delete(this.api_url + url, {
    //         headers: {
    //             'Authorization': `Bearer ${token}`,
    //             'Accept' : 'application/json',
    //             'Content-Type': 'application/json'
    //         }
    //     })
    // }


    // get(url){
    //     const token = AuthService.token() ? AuthService.token() : ''
    //     return axios.get(this.api_url + url, {
    //         headers: {
    //             'Authorization': `Bearer ${token}`,
    //             'Accept' : 'application/json',
    //             'Content-Type': 'application/json'
    //         }
    //     }
    //         )
    // }
}

export default ApiFileService