import React from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { ColumnGroup } from 'primereact/columngroup'
import { Row } from 'primereact/row'
import GeneralServices from '../../app/service/generalServices'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import HandleErrorService from '../../app/service/handleErrorService'
import * as popUp from '../toastr'
import LancarFechamentoBancarioService from '../../app/service/conciliacaoBancaria/lancarFechamentoBancarioService'
import EditLancamentoBancarioDialog from '../conciliacaoBancaria/editLancamentoBancarioDialog'
import ConstantsUtil from '../../context/constantsUtil'
import FichaDeRecebimentoService from '../../app/service/contasAReceber/fichaDeRecebimentoService'


class FichaDeRecebimentoTable extends React.Component {

    state = {
        
        loading: false,

        fichaDeRecebimento: {},

        lancamentoToEdit: null,
        editLancamentoBancarioDialog: false,
        editLancamentoBancarioDialogHeader: "Editar Lançamento",
        idContaBancaria: '',

        lancamentoToDelete: null,
        displayDeleteConfirmation: false,
    }

    constructor(){
        super();
        this.toast = React.createRef();
        this.dt = React.createRef();
        this.lancarFechamentoBancarioService = new LancarFechamentoBancarioService();
        this.fichaDeRecebimentoService = new FichaDeRecebimentoService();

    }

    componentDidMount() {
        if(this.props.doAutoSearch){
            this.doAutoSearch()
        }
    }

    doAutoSearch = () => {
        this.setState({loading: true})
        this.fichaDeRecebimentoService.getLancamentosByDateInterval(
            {
                tipoDeRecebimento : this.props.fichaDeRecebimento.tipoDeRecebimento,
                dateFilterType : this.props.fichaDeRecebimento.dateFilterTypeEnum,
                initialLancamentoDate : this.props.fichaDeRecebimento.initialDate.replaceAll("/", "-"),
                finalLancamentoDate : this.props.fichaDeRecebimento.finalDate.replaceAll("/", "-")
            }
        )
        .then(response => {
            this.setState({fichaDeRecebimento: response.data})

        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
        .finally(() => {
            this.setState({loading: false})
        })
    }

    getLancamentos = () => {
        if(this.props.doAutoSearch){
            return this.state.fichaDeRecebimento.lancamentos
        }
        return this.props.fichaDeRecebimento.lancamentos
    }

    editLancamentoBancario = (lancamento) => {
        lancamento.idLancamentoFechamentoBancario = lancamento.id
        lancamento.data = lancamento.dataCredito
        lancamento.descricao = lancamento.nomeTipoDeRecebimento
        lancamento.idTipoDeRecebimento = lancamento.idTipoDeRecebimento
        lancamento.entrada = lancamento.credito

        // console.log("lanc: ", lancamento)
        this.setState({lancamentoToEdit: JSON.parse(JSON.stringify(lancamento))})
        this.setState({editLancamentoBancarioDialog: true})
        
        this.setState({idContaBancaria: lancamento.idContaBancaria})
    }

    hideDialog = () => {
        this.setState({editLancamentoBancarioDialog: false})
    }

    beginLoadingFichaDeRecebimentoTable = () => {
        this.setState({loading: true})
    }

    endLoadingFichaDeRecebimentoTable = () => {
        this.setState({loading: false})
    }

    callDelete = (lancamentoBancario) => {
        this.setState({lancamentoToDelete: lancamentoBancario})
        this.setState({displayDeleteConfirmation: true})
    }

    cancelDelete = () => {
        this.setState({lancamentoToDelete: null})
        this.setState({displayDeleteConfirmation: false})
    }

    deleteLancamentoFechamentoBancario = () => {
        // console.log("lanc to delete: ", this.state.lancamentoToDelete)
        this.setState({displayDeleteConfirmation: false})
        this.setState({loading: true})

        this.lancarFechamentoBancarioService.delete(
            {
                idLancamento: this.state.lancamentoToDelete.id 
            }
        ).then(response => {
            popUp.successPopUp("Lançamento deletado com sucesso!")
            
            if(this.props.doAutoSearch) {
                this.doAutoSearch()
            }
            else {
                this.props.search()
            }
            this.setState({loading: false})
        })
        .catch(error => {
            this.setState({loading: false})
            HandleErrorService.handleError(error)
        })
    }

    rowClass(rowData) {
        return {
            [ConstantsUtil.totaisTableLabel]: rowData.tipoLancamento === ConstantsUtil.tipoTotaisFichaDeRecebimentoLabel
        }
    }

    getTableHeaders = () => {
        //Para não mostrar "undefined" no header enquanto o backend está processando a requisição
        const tipoDeRecebimento = this.props.fichaDeRecebimento.nomeTipoDeRecebimento ? this.props.fichaDeRecebimento.nomeTipoDeRecebimento : ""
        const tipoDeRecebimentoLabel = "Tipo de Recebimento: " + tipoDeRecebimento
        
        const dateFilterType = this.props.fichaDeRecebimento.dateFilterType ? this.props.fichaDeRecebimento.dateFilterType : ''
        const dateFilterTypeLabel = "Filtrado por: " + dateFilterType

        const periodo = this.props.fichaDeRecebimento.initialDate ? this.props.fichaDeRecebimento.initialDate + " - " + this.props.fichaDeRecebimento.finalDate : ""
        const periodoLabel = "Período: " + periodo

        return {tipoDeRecebimento: tipoDeRecebimentoLabel, dateFilterType: dateFilterTypeLabel, periodo: periodoLabel}
    }

    render (){

        let headerGroup = 
        <ColumnGroup>
            <Row>
                {/* <Column header="Nome" rowSpan={2} field="nomeTipoDeRecebimento"/> */}
                <Column header="Data de Lançamento" rowSpan={2} field="dataLancamento"/>
                <Column header="Data de Crédito" rowSpan={2} field="dataCredito"/>
                <Column header="Caixa" rowSpan={2} field="pdvCaixa"/>
                <Column header="Turno" rowSpan={2} field="turno"/>
                <Column header="Pela Entrada Do Fechamento De Caixa" colSpan={3}/>
                
                
                <Column
                    header={ this.props.fichaDeRecebimento.nomeTipoDeRecebimento === 'BOLETO' ? "Geração de Boletos" : "Pela Conciliação Bancária"}
                    colSpan={3}
                />

                {/* <Column header="Ações" rowSpan={2}/> */}

            </Row>
            <Row>
                <Column header="Bruto" field="valor" />
                <Column header="Comissão" field="valorLiquido"/>
                <Column header="Líquido" field="valorLiquido" />
                <Column header="Crédito" field="valUnitSaida"/>
                <Column header="Ajuste" field="quantidadeSaldo"/>
                <Column header="Saldo" field="valorTotalSaldo"/>
            </Row>
        </ColumnGroup>
        
        const actionBody = (rowData) => {
            if(rowData.tipoLancamento === ConstantsUtil.tipoLancamentoBancarioLabel){
                /*
                    Só os lançamentos de fechamento bancário vêm com id.
                    O id do saldo inicial vem null.
                */

                return (
                    <React.Fragment>
                        <Button 
                            tooltip='Editar Lançamento'
                            tooltipOptions={{position: 'top'}}
                            icon="pi pi-pencil"
                            className={ "p-button-rounded p-mr-2 " }
                            // onClick={() => this.setState({editLancamentoBancarioDialog: true})}
                            onClick={() => this.editLancamentoBancario(rowData)}
                            style={ {maxHeight: '25px', maxWidth: '25px'} }
                        />
    
                        <Button
                            tooltip='Excluir Lançamento'
                            tooltipOptions={{position: 'top'}}
                            icon="pi pi-times"
                            className={ "p-button-rounded p-mr-2 p-button-danger" }
                            style ={{marginLeft: '10px', maxHeight: '25px', maxWidth: '25px'}}
                            onClick={() => this.callDelete(rowData)}
                        />
                    </React.Fragment>
                );
            }
        }

        const renderDeleteConfirmationFooter = () => {
            return (
                <div>
                    <Button label="Confirmar" icon="pi pi-check"
                            onClick={this.deleteLancamentoFechamentoBancario} autoFocus />
                    <Button label="Cancelar" icon="pi pi-times" onClick={this.cancelDelete}
                            className="p-button-text" />
                </div>
            );
        }

        return (
            <>
            <div className = "card-header">
            <div className='col-md-12'>
                <div className='row'>
                    <div className='col-md-4'>
                        <h5 className='medium-font-size'>{this.getTableHeaders().tipoDeRecebimento}</h5>            
                    </div>
                    <div className='col-md-4'>
                        <h5 className='medium-font-size'>{this.getTableHeaders().dateFilterType}</h5>
                    </div>
                    <div className='col-md-4'>
                        <h5 className='medium-font-size'>{this.getTableHeaders().periodo}</h5>
                    </div>
                </div>
                
            </div>
            </div>
            
            <div className="">
                <DataTable
                    ref={this.dt}
                    value={this.getLancamentos()}
                    headerColumnGroup={headerGroup}
                    className="p-datatable-sm small-font"
                    loading={this.props.loading || this.state.loading}
                    rowClassName={this.rowClass}
                    rowHover
                    scrollable
                    scrollHeight={ this.props.insideDialog ? "25rem" : "32rem"}
                    emptyMessage={ConstantsUtil.nenhumLancamentoEncontradoMessage}
                    dataKey="id"
                    paginator rows={ConstantsUtil.initialTableQuantityOption}
                    paginatorTemplate={GeneralServices.tablePaginatorTemplate(ConstantsUtil.initialTableQuantityOption, (this.props.fichaDeRecebimento || this.props.doAutoSearch) && this.getLancamentos() ? this.getLancamentos().length : '')}
                    currentPageReportTemplate="Mostrando de {first} ao {last} de {totalRecords} lançamentos"
                >

                    {/* <Column field="nomeTipoDeRecebimento"
                        sortable style ={ {width: '140px'} }
                    /> */}

                    <Column field="dataLancamento"
                        sortable style ={ {width: '140px'} }
                        body={ rowData =>
                            rowData.tipoLancamento === ConstantsUtil.tipoTotaisFichaDeRecebimentoLabel ?
                            ConstantsUtil.tipoTotaisFichaDeRecebimentoLabel
                            :
                            rowData.dataLancamento
                        }
                    />

                    <Column field="dataCredito" 
                            sortable style ={ {width: '140px'} }
                    />
                    
                    <Column field="caixa" 
                            sortable style ={ {width: '100px'} }
                    />

                    <Column field="turno"
                        sortable style ={ {width: '100px'} }
                    />

                    <Column field="valorBruto"
                            body = {rowData => GeneralServices.showFormattedIfNonZero(rowData.valorBruto, true) }
                            sortable style ={ {width: '100px'} }
                    />

                    <Column field="comissao" 
                        body = {rowData => GeneralServices.showFormattedIfNotNull(rowData.comissao, true) }
                        sortable style ={ {width: '100px'} }
                    />

                    <Column field="valorLiquido" 
                    body = {rowData => GeneralServices.showFormattedIfNonZero(rowData.valorLiquido, true) }
                            sortable style ={ {width: '100px'} }
                    />

                    <Column field="credito" 
                        body = {rowData => GeneralServices.showFormattedIfNonZero(rowData.credito, true) }
                        sortable style ={ {width: '100px'} }
                    />

                    <Column field="ajuste"
                        body = {rowData =>
                            rowData.tipoLancamento === ConstantsUtil.tipoTotaisFichaDeRecebimentoLabel ?
                            GeneralServices.showFormattedIfNotNull(rowData.ajuste, true)
                            :
                            GeneralServices.showFormattedIfNonZero(rowData.ajuste, true) }
                        sortable style ={ {width: '100px'} }
                    />

                    <Column field="saldo"
                        body = {rowData => GeneralServices.showFormattedIfNotNull(rowData.saldo, true) }
                        style ={ {width: '100px'} } 
                    />

                    <Column
                        // header="Ações"
                        body={actionBody}
                        style ={ {width: '90px'} }
                    />

                </DataTable>
            </div>

            <EditLancamentoBancarioDialog
                hideDialog={this.hideDialog}
                visible={this.state.editLancamentoBancarioDialog}
                header={this.state.editLancamentoBancarioDialogHeader}
                state={this.state.lancamentoToEdit}
                tipoDeRecebimentoList={this.props.tipoDeRecebimentoList}
                tipoLancamentoBancario={ConstantsUtil.lancamentoBancarioRecebimentoLabel}
                idSelectedContaBancaria={this.state.idContaBancaria}
                search={this.props.doAutoSearch ? this.doAutoSearch : this.props.search}
                push={this.props.push}
                beginLoadingFichaBancariaTable={this.beginLoadingFichaDeRecebimentoTable}
                endLoadingFichaBancariaTable={this.endLoadingFichaDeRecebimentoTable}
                // disabled={true}
            />


            <Dialog header="Excluir Lançamento"
                visible={this.state.displayDeleteConfirmation}
                modal = {true} //congela restante da tela
                style={{ width: '350px' }}
                footer={renderDeleteConfirmationFooter()}
                onHide={this.cancelDelete}>
                <div className="confirmation-content row" style={{marginLeft: '10px'}}>
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem', marginRight: '10px'}} />
                    <div style={{marginBottom: '10px'}}> Deseja confirmar? </div>
                </div>
            </Dialog>

            </>
        )
    }


}  

export default FichaDeRecebimentoTable