import WorkerApiService from "../../workerApiServices";

class DREService extends WorkerApiService {
 
    constructor() {
        super('/api/DRE')
    }

    getDREMensal(dataInicial, dataFinal){
        let params = `?`
        params = `${params}&dataInicial=${dataInicial}`
        params = `${params}&dataFinal=${dataFinal}`
        return this.get(`/getDREMensal${params}`)
    }

    getDREConsolidado(dataInicial, dataFinal){
        let params = `?`
        params = `${params}&dataInicial=${dataInicial}`
        params = `${params}&dataFinal=${dataFinal}`
        return this.get(`/getDREConsolidado${params}`)
    }
}

export default DREService