import React from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import GeneralServices from '../../../../app/service/generalServices'
import LancarFechamentoBancarioService from '../../../../app/service/conciliacaoBancaria/lancarFechamentoBancarioService'
import ConstantsUtil from '../../../../context/constantsUtil'
import { Button } from 'primereact/button'
import * as xlsx from 'xlsx'
import ExpansionTableButton from '../expansionTableButton'
import TableFilters from '../../../tableFilters'


class DRESaldoConsolidadoTable extends React.Component {

    constructor(){
        super();
        this.ref = React.createRef()
        this.lancarFechamentoBancarioService = new LancarFechamentoBancarioService();
        this.tableFilters = new TableFilters();
        this.generalServices = new GeneralServices()

    }

    state = {
        
        loading: false,
        expanded: false,
        selectedValoresMeses: {},
        didUpdate: false,        

    }

    componentDidMount(){
        this.scroll()
    }

    componentDidUpdate(){
        if(!this.state.didUpdate){
            let selectedValoresMeses = {}
            if(this.props.dreConsolidado.descricaoMesesList){
                this.props.dreConsolidado.descricaoMesesList.forEach(descricaoMes => {
                    selectedValoresMeses[descricaoMes] = null
                })
            }
            this.setState({selectedValoresMeses})
            this.setState({didUpdate: true})

        }
    }    

    scroll = async () => {
        await this.generalServices.sleep(300)
        if(this.props.header === ConstantsUtil.saldoInicialLabel){
            if(this.ref.current){
                this.ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
                // this.ref.current.scrollIntoView();
            }
        }
    }

    getTableHeaders = () => {
        
        const periodo = this.props.initialDate ? this.props.initialDate + " - " + this.props.finalDate : ""
        const periodoLabel = "Período: " + periodo

        return {periodo: periodoLabel}
    }

    rowClass(rowData) {
        const obj = {
            [ConstantsUtil.totaisTableLabel] : rowData.tipoDRESaldo === ConstantsUtil.tipoTotaisFichaBancariaLabel
        }

        return obj
    }

    formattedDREMensaSaldoToExportByExcel(){

        var formattedList = []

        const contaBancariaLabel = "Conta Bancária"

        var list = this.props.header === ConstantsUtil.saldoInicialLabel ? this.props.dreConsolidado.saldoInicial : this.props.dreConsolidado.saldoFinal

        list.forEach(item =>{

            var obj = {
                [contaBancariaLabel]: item.contaBancaria.nomeBanco,
            }

            this.props.dreConsolidado.descricaoMesesList.forEach(descricaoMes => {
                // console.log("descricaoMes: ", descricaoMes)
                // console.log("item.valorMap: ", item.valorMap)
                const valor = item.valorMap[descricaoMes].showTotal
                obj[descricaoMes] = valor
            })

            formattedList.push(obj)
        })

        return formattedList
    }

    generateWorkSheet(){
        const worksheet = GeneralServices.jsonToSheet(this.formattedDREMensaSaldoToExportByExcel());
        return worksheet
    }

    exportExcel = () => {
        const periodo = this.props.initialDate + " - " + this.props.finalDate
        const worksheet = this.generateWorkSheet()
        const sheetName = periodo
        const workbook = { Sheets: { [sheetName]: worksheet }, SheetNames: [[sheetName]] };
        this.props.saveAsExcelFile(workbook, `Fluxo de Caixa Mensal ${this.props.header} - ${periodo} - VisãoRJ`);
    }

    filterList = () => {
        var list = this.props.header === ConstantsUtil.saldoInicialLabel ? this.props.dreConsolidado.saldoInicial : this.props.dreConsolidado.saldoFinal

        if(!this.state.expanded && list){
            let listToBeReturned = list.filter(item => item.tipoDRESaldo === ConstantsUtil.tipoTotaisFichaBancariaLabel)
            this.props.resetTotalItem(listToBeReturned)
            return listToBeReturned
        }
        return list
    }

    updateValorMesFilter = async (descricaoMes, value) => {
        // console.log("updateValorMesFilter: ", descricaoMes, value)
        let selectedValoresMeses = this.state.selectedValoresMeses
        selectedValoresMeses[descricaoMes] = value
        await this.setState({selectedValoresMeses})
        // console.log("selectedValoresMeses: ", this.state.selectedValoresMeses)
    }

    renderMesTableFilter = (descricaoMes) => {
        // console.log("this.tableFilters: ", descricaoMes)
        return (
            <div className='right-align'>
              {this.tableFilters.renderTableFilter(
                    this.props.dt,
                    this.state.selectedValoresMeses[descricaoMes],
                    this.filterList(),
                    descricaoMes,
                    this.updateValorMesFilter,
                    descricaoMes,
                    '100%',
                    "Total"
                )}

            </div>
        )
    }        

    render (){

        const renderMesesColumn = () => {
            var mesesColumnList = []

            if(this.props.dreConsolidado && this.props.dreConsolidado.descricaoMesesList && this.props.dreConsolidado.descricaoMesesList.length !== 0){
                this.props.dreConsolidado.descricaoMesesList.forEach(descricaoMes => {
                    mesesColumnList.push(renderColumnItem(descricaoMes))
                });
            }


            return mesesColumnList
        }

        const renderColumnItem = (descricaoMes) => {
            return (
                <Column
                    field={descricaoMes}
                    // header={descricaoMes}
                    header={<div style={{height: ConstantsUtil.heightHeaderDREConsolidado}}>{descricaoMes}</div>}
                    style ={ {width: ConstantsUtil.widthColumDreConsolidado} }
                    filter={this.state.expanded} filterElement={this.renderMesTableFilter(descricaoMes)}
                    body={dreSaldoBancarioDTO =>
                    {
                        return(
                            <div className='right-align'>
                                {GeneralServices.showFormattedIfNotNull(dreSaldoBancarioDTO.valorMap[descricaoMes].showTotal, true)}
                            </div>
                        )

                    }}
                    // sortable
                    />
            )
        }


        const nomeFilterElement = this.tableFilters.renderTableFilter(
            this.props.dt,
            this.state.selectedNome,
            this.filterList(),
            "selectedNome",
            (name, value) => this.setState({[name]: value}),
            'nome',
            // ConstantsUtil.widthColumNomeDreConsolidado,
            '150px',
            "Nome"
        )         

        const renderTable = () => {
            return (
                <DataTable
                    ref={this.props.dt}
                    className="p-datatable-sm small-font"
                    value={this.filterList()}
                    onValueChange={(currentList) => this.props.onFilterTable(currentList, this.props.dt)}
                    // className="p-datatable-responsive-demo"
                    rowClassName={this.rowClass}
                    rowHover
                    showGridlines
                    selection={this.state.selectedContasBancaria}
                    onSelectionChange={this.handleSelectionChange}
                    // scrollable
                    // scrollHeight="500px"
                    style={{ maxWidth: '100vw' }}
                    frozenWidth={ConstantsUtil.widthColumNomeDreConsolidado}
                    loading={this.props.loading || this.state.loading}
                    dataKey="id"
                    emptyMessage="Nenhuma Conta Bancária encontrada."
                    // paginator rows={100}
                    // paginatorTemplate={GeneralServices.tablePaginatorTemplate(100, this.props.list ? this.props.list.length : '')}
                    // currentPageReportTemplate="Mostrando de {first} ao {last} de {totalRecords} Contas Bancárias"
                >
    
                <Column
                    field="nome"
                    header="Conta Bancária"
                    // header={<div style={{height: ConstantsUtil.heightHeaderDREConsolidado}}>{nomeFilterElement}</div>}
                    filter={this.state.expanded}
                    filterElement={nomeFilterElement}
                    style ={ {width: ConstantsUtil.widthColumNomeDreConsolidado} }
                    frozen
                    body =
                    { 
                        (dreSaldoBancarioConsolidadoDTO, rowIndex) => 
                            {
                                // const nome = GeneralServices.contaBancariaFormattedNameInDRE(dreSaldoBancarioConsolidadoDTO)
                                const nome = dreSaldoBancarioConsolidadoDTO.nome
                                return (
                                    <div
                                        style ={ {
                                            whiteSpace: 'nowrap', /* Impede que o texto quebre em várias linhas */
                                            overflow: 'hidden', /* Esconde qualquer conteúdo que exceda o width da coluna */
                                            textOverflow: 'ellipsis', /* Adiciona reticências (...) ao final do texto cortado */
                                        } }
                                        title={nome}
                                    >
                                        {nome}
                                    </div>
                                )
                            }
                    }
                />
    
                {/* <Column field="saldo" header="Saldo"
                    // style ={ {width: '200px'} }
                    body = {rowData => GeneralServices.showFormattedIfNotNull(rowData.saldo, true)}
                /> */}
    
                {renderMesesColumn()}
    
                </DataTable>
            )
        }

        const renderExportarButton = () => {
            if(this.state.expanded){
                return (
                    <Button label="Exportar"
                        icon={<i className="pi pi-file-excel" style={{fontSize: '1em', marginRight: '0.5em'}}></i>}
                        className="p-button-success small-font"
                        onClick={this.exportExcel}
                        tooltip="Exportar em excel"
                        tooltipOptions={{position: 'top'}}
                        style={{maxHeight: '15px'}}
                    />
                )
            }
        }

        return (
            <>
            <div
                ref={this.ref}
                className = "card-header header-small-height"
            >
            <div className='col-md-12'>
                
                <div className='row'>
                    <ExpansionTableButton
                        expanded={this.state.expanded}
                        toggleExpansionTable={async () => {
                            await this.setState({expanded: !this.state.expanded})
                            await this.setState({selectedNome: ''})
                            this.props.dt.current.reset(); //reseta filtro
                        }}
                    />            
                    <div className='col-md-4'>
                        <h5 className='small-font-size'>{this.props.header}</h5>            
                    </div>
                    <div className='col-md-4'>
                        {/* <h5 className='small-font-size'>{this.getTableHeaders().periodo}</h5> */}
                    </div>
                    <div className='col-md-2'>
                    </div>
                    {renderExportarButton()}
                </div>
                
                {/* <div className='row'>
                <div className='col-md-4'>
                    <Button label="Exportar" icon="pi pi-file-excel" className="p-button-success" onClick={this.exportExcel}
                        tooltip="Exportar em excel"
                        tooltipOptions={{position: 'top'}}
                    />
                </div>
                </div> */}
                
            </div>
            </div>

            {renderTable()}

            </>
        )
    }


}  

export default DRESaldoConsolidadoTable