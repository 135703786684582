import React from 'react'

import { Dialog } from 'primereact/dialog'
import DialogFooter from '../dialogFooter'

import ContasBancariasTable from './contasBancariasTable'
import GeneralServices from '../../app/service/generalServices'

class ChooseContaBancariaDialog extends React.Component {

    state = {

        selectedContaBancaria: '',
        contasBancariasList: [],
        filteredContasBancariasList: [],

        searchText: '',

        didUpdated: false,

    }

    componentDidUpdate(){
        this.setStates()
    }

    setStates = () => {
        if( (this.props.visible && !this.state.didUpdated) || this.props.forceUpdate ){
            this.setState({searchText: ''})
            this.setState({contasBancariasList: this.props.list})
            this.setState({filteredContasBancariasList: this.props.list})

                
            this.setState({didUpdated: true})
            if(this.props.doneForceUpdate){
                this.props.doneForceUpdate()
            }
        }
    }

    handleChange = async (event) => {
        const value = event.target.value
        const name = event.target.name
        await this.setState({ [name]: value })
        this.filter()
    }

    handleKeyPress = async (e) => {
        if (e.key === "Enter") {
            const filteredList = JSON.parse(JSON.stringify(this.state.filteredContasBancariasList))
            if(filteredList.length === 1){
                await this.setState({selectedContaBancaria: filteredList[0]})
                this.callSave()
            }
          }
    }

    handleSelectionContaBancariaChange = (value) => {
        if(value === null){ //significa que clicou em cima da Conta Bancária que estava selecionada antes (double click)
            this.callSave()
        } else{
            this.setState({selectedContaBancaria: value})
        }
    }

    hideDialog = () => {
        this.setState({didUpdated: false})
        this.props.hideDialog()
    }

    callSave = async () => {
        await this.props.handleSelectionContaBancariaChange(this.state.selectedContaBancaria)
        this.hideDialog()
    }

    filter  = () => {
        
        this.setState({
            filteredContasBancariasList:
                JSON.parse(
                    JSON.stringify(
                        this.state.contasBancariasList.filter(conta => {
                            return GeneralServices.includeString(conta.nomeBanco, this.state.searchText)
                            // return conta.nomeBanco.toLowerCase().includes(this.state.searchText.toLowerCase())
                        }
                        )
                    )
                )
        })
    }

    render () {

        const chooseContaBancariaDialogFooter = (
            <DialogFooter save = {this.callSave} hideDialog = {this.hideDialog} disabled={this.props.disabled}/>
        )

        return (
            <>
            <Dialog 
                    visible={this.props.visible}
                    style={{ width: '70vw' }}
                    header={this.props.header}
                    modal
                    className="p-fluid"
                    footer={chooseContaBancariaDialogFooter}
                    onHide={this.hideDialog}
            >

                <div className="p-field">
                    <label htmlFor="search conta">Busca</label>
                    <input style={{color: '#000'}}
                        autoFocus  
                        className={"form-control " }
                        type="text"
                        id="searchText"
                        name="searchText"
                        value={this.state.searchText}
                        placeholder="Pesquise pelo nome"
                        onChange = {this.handleChange}
                        onKeyPress={this.handleKeyPress}
                    />
                </div>

                <br />

                <ContasBancariasTable 
                    list = { this.state.filteredContasBancariasList }
                    simpleView
                    searchNomeConta={this.state.searchText}
                    search={this.searchContasBancarias}
                    handleSelectionContaBancariaChange={this.handleSelectionContaBancariaChange}
                    selectable={true}
                    loading = {this.props.loading}
                /> 


            </Dialog>
            </>
        )
    }


}

export default ChooseContaBancariaDialog