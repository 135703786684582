import WorkerApiService from "../workerApiServices"

class FraseService extends WorkerApiService {

    constructor() {
        super('/api/frases')
    }

    searchAllByTipoFrase(tipoFrase){
        let params = `?`
        params = `${params}&tipoFrase=${tipoFrase}`
        return this.get(`/searchAllByTipoFrase${params}`)
        
    }

    deleteByTextoAndTipoFrase(texto, tipoFrase){
        let params = `?`
        params = `${params}&texto=${texto}`
        params = `${params}&tipoFrase=${tipoFrase}`
        return this.delete(`/deleteByTextoAndTipoFrase${params}`)        
    }

}

export default FraseService