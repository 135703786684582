import React from 'react'

import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'

import { Button } from 'primereact/button'
import { Toolbar } from 'primereact/toolbar'

import Highlighter from "react-highlight-words"
import GeneralServices from '../../app/service/generalServices'
import CustomHighlighter from '../customHighlighter'
import ConstantsUtil from '../../context/constantsUtil'

class PlanoDeContasTable extends React.Component {

    state = {

        selectedConta: '',

        columns: [
            {field:"numeroDeChamada", header:"N° de Chamada"},
            {field:"codigo", header:"Código Interno"},
            {field:"nomeConta", header:"Nome"},
            {field:"tipoContaContabil", header:"Tipo"},
            {field:"natureza", header:"Natureza"}
        ],
        
    }

    exportPdf = () => {
        var exportColumns = this.state.columns.map(col => ({ title: col.header, dataKey: col.field }))
        var list = this.props.notFilteredList
        
        GeneralServices.exportPdf('plano de contas visaorj.pdf', exportColumns, list)

    }

    formattedPlanoDeContasToExportByExcel = () => {
        var formattedList = []
        this.props.notFilteredList.forEach(conta => {formattedList.push(
            {
                Código: conta.codigo,
                Tipo: conta.tipoContaContabil.charAt(0),
                Nome: conta.nomeConta,
                Natureza: conta.natureza.charAt(0)
            }
        )})
        return formattedList
    }

    exportExcel = () => {
        import('xlsx').then(xlsx => {
            const worksheet = GeneralServices.jsonToSheet(this.formattedPlanoDeContasToExportByExcel());
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, 'plano de contas visaorj');
        });
    }

    saveAsExcelFile(buffer, fileName) {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    }

    rowClass(conta) {
        return {
            'analitica': conta.tipoContaContabil === 'ANALITICA'
        }
    }


    render() {

        const leftToolbarTemplate = () => {
            return (
                <React.Fragment>
                    {/* <FileUpload mode="basic" accept="*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="p-mr-2 p-d-inline-block" /> */}
                    
                    <Button label="Exportar" icon="pi pi-file-excel" className="p-button-success small-font" onClick={this.exportExcel}
                        tooltip={ConstantsUtil.exportXlsxLabel}
                        tooltipOptions={{position: 'top'}}
                        // style={{maxHeight: '35px'}}
                    />

                    <Button label="Exportar" icon="pi pi-file-pdf" className="p-button-danger small-font" onClick={this.exportPdf}
                        style={{marginLeft: '10px'}}
                        tooltip={ConstantsUtil.exportPdfLabel}
                        tooltipOptions={{position: 'top'}}
                    />
                </React.Fragment>
            )
        }


        return (
            <div className="">
                <div className="card">
                <Toolbar className="p-mb-4" left={leftToolbarTemplate}  />
                    <DataTable
                        className="p-datatable-sm small-font"
                        value={this.props.list}
                        // value={this.state.testList}
                        selection={this.props.selectedConta}
                        onSelectionChange={this.props.handleSelectionChange}
                        rowClassName={this.rowClass}
                        scrollable
                        rowHover
                        emptyMessage="Nenhuma conta encontrada."
                        loading={this.props.loading}
                        // paginator rows={30} rowsPerPageOptions={[30, 100, 400]}
                        // paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        paginator rows={ConstantsUtil.initialTableQuantityOption}
                        paginatorTemplate={GeneralServices.tablePaginatorTemplate(ConstantsUtil.initialTableQuantityOption, this.props.list ? this.props.list.length : '')}
                        currentPageReportTemplate="Mostrando de {first} ao {last} de {totalRecords} contas"
                        // scrollable
                        // scrollHeight="500px"
                        // responsiveLayout="scroll"
                        >
                        <Column selectionMode="single"
                        headerStyle={{ width: '40px'}} 
                        style ={ {width: '140px'}}
                        />
                        <Column field="numeroDeChamada" header="N° de Chamada" style ={ {width: '140px'}}
                            body={rowData => 
                                <CustomHighlighter
                                    searchWords={[this.props.searchText]}
                                    textToHighlight={rowData.numeroDeChamada}
                                />
                            }    
                        />
                        <Column field="codigo" header="Código Interno" style ={ {width: '140px'}}/>
                        <Column field="nomeConta" header="Nome" style ={ {width: '140px'}} 
                            body={rowData => 
                                <CustomHighlighter
                                    searchWords={[this.props.searchText]}
                                    textToHighlight={rowData.nomeConta}
                                />
                            }    
                        />
                        <Column field="tipoContaContabil" header="Tipo" style ={ {width: '140px'}} />
                        <Column field="natureza" header="Natureza" style ={ {width: '140px'}} />
                        {/* <Column field="quantity" header="Quantity" body={this.stockBodyTemplate}></Column> */}
                    </DataTable>
                </div>
            </div>
        );
    }

}

export default PlanoDeContasTable