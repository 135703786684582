import React from 'react'

import { Dialog } from 'primereact/dialog'
import DialogFooter from '../../dialogFooter'
import CentralService from '../../../app/service/central/centralService'
import GeneralServices from '../../../app/service/generalServices'
import HandleErrorService from '../../../app/service/handleErrorService'
import ProvisaoService from '../../../app/service/contasAPagar/provisaoService'
import LancamentoProvisaoComponent from './lancamentoProvisaoComponent'
import { ProgressSpinner } from 'primereact/progressspinner'
import ItensNFComponent from '../../nf/itensNFComponent'
import * as popUp  from '../../toastr'

class EditProvisaoDialog extends React.Component {

    constructor(){
        super()
        this.centralService = new CentralService();
        this.provisaoService = new ProvisaoService();
        this.lancamentoProvisaoComponentRef = React.createRef();
    }

    state = {
        
        didUpdated: false,
        loading: false,

        provisaoToEdit: '',
        
    }

    componentDidUpdate(){
        if( this.props.visible && !this.state.didUpdated ){
            this.searchProvisao()
            this.setState({didUpdated: true})
        }
    }

    resolveSearchProvisao = () => {
        if(this.props.provisaoId){
            if(this.props.empresa){
                return this.centralService.searchProvisaoByIdAndEmpresa(this.props.provisaoId, this.props.empresa.id)
            }
            else{
                return this.provisaoService.searchById(this.props.provisaoId)
            }
        }
        else if(this.props.notaFiscal){
            return this.provisaoService.searchByNFId(this.props.notaFiscal.id)
        }
    }

    resetView = () => {
        this.setState({provisaoToEdit: ''})
    }

    searchProvisao = () => {
        // console.log("search: ", this.props.provisaoId)
        this.resetView()
        this.setState({loading: true})
        this.resolveSearchProvisao()
        .then(response => {
            this.trataDados(response.data)
        })
        .catch(error => {
            // console.log("error: ", error)
            HandleErrorService.handleError(error)
        })
        .finally(() => {
            this.setState({loading: false})
        })
    }

    trataDados = async (provisao) => {

        if(provisao.dataLancamento){
            /*
            Provisões de NF não validadas não possuem data de lançamento
            */
            provisao.dataLancamentoView = GeneralServices.convertBrStringToJsDate(provisao.dataLancamento)
        }
        provisao.parcelaProvisaoList.forEach(parcela => {
            parcela.dataView = GeneralServices.convertBrStringToJsDate(parcela.data)
        })

        // console.log("provisaoToEdit: ", provisao)
        await this.setState({provisaoToEdit: provisao})
        if(this.isProvisaoFromNFNaoValidada()){
            popUp.infoPopUp("Nota Fiscal ainda não validada!")
        }
    }

    isProvisaoFromNFNaoValidada = () => {
        return this.state.provisaoToEdit && this.state.provisaoToEdit.notaFiscal && this.state.provisaoToEdit.notaFiscal.pendente
    }

    hideDialog = () => {
        this.props.hideDialog()
        this.setState({didUpdated: false})
    }

    onCallEdit = () => {
        this.hideDialog()
        if(this.props.startLoadingTable){
            this.props.startLoadingTable()
        }
        
    }

    onSuccesEdit = () => {
        this.props.search(true)
    }

    onErrorEdit = () => {
        if(this.props.finishLoadingTable){
            this.props.finishLoadingTable()
        }
    }

    callSave = () => {
        this.lancamentoProvisaoComponentRef.current.callSave()
    }

    render(){

        
        const footer = (
            <DialogFooter save = {this.callSave} hideDialog = {this.hideDialog} disabled={this.isProvisaoFromNFNaoValidada()}
            />
        )

        const renderDialog = () => {
            if(this.state.loading){
                return (
                    <Dialog
                        visible
                    >
                        <ProgressSpinner className='custom-progress-spinner'/>
                    </Dialog>                      
                )
            }
            else{
                return (
                    <Dialog
                        visible={this.props.visible}
                        style={{ width: '95vw'}}
                        header="Editar Provisão"
                        modal
                        className="p-fluid"
                        footer={footer}
                        onHide={this.hideDialog}
                    >
                    <div className='col-md-12'> 
                        <LancamentoProvisaoComponent
                            ref={this.lancamentoProvisaoComponentRef}
                            insideEditDialog
                            provisao={this.state.provisaoToEdit}
                            empresa={this.props.empresa}
                            onSuccesEdit={this.onSuccesEdit}
                            onCallEdit={this.onCallEdit}
                            onErrorEdit={this.onErrorEdit}
                            disabled={this.isProvisaoFromNFNaoValidada()}
                        />
                    </div>
                    <ItensNFComponent
                        transactions={this.state.provisaoToEdit && this.state.provisaoToEdit.notaFiscal ? this.state.provisaoToEdit.notaFiscal.transactions : []}
                        insideEditProvisaoDialog
                        disabled={this.isProvisaoFromNFNaoValidada()}
                    />
    
                    </Dialog>
                )
            }
        }

        return(
            <>
                {renderDialog()}
            </>
        )
    }

}

export default EditProvisaoDialog