import React from 'react'
import ChooseTipoDePagamentoDialog from './chooseTipoDePagamentoDialog'

class InputChooseTipoDePagamento extends React.Component {

    state = {
        chooseTipoDePagamentoDialogVisible: false,

    }

    componentDidMount(){
        if(this.props.renderDialogOnMount){
            this.setState({chooseTipoDePagamentoDialogVisible: true})
        }
    }

    hideTipoDePagamentoDialog = () => {
        this.setState({chooseTipoDePagamentoDialogVisible: false})

        if(this.props.hideDialog){
            this.props.hideDialog()
        }
    }

    handleSelectionTipoDePagamentoChange = async (tipoDePagamento) => {
        const label = tipoDePagamento.nome
        this.props.handleSelectionTipoDePagamentoChange(tipoDePagamento, label)
    }

    handleKeyPress = async (e) => {
        if (e.key === "Enter") {
            this.inputClick()
          }
    }

    inputClick = () => {
        this.setState({chooseTipoDePagamentoDialogVisible: true})
    }

    generateLabel = () => {
        var label = "Tipo de Pagamento"

        if(this.props.optional){
            label+=" (Opcional)"
        }

        return label
    }

    render(){

        const renderInput = () => {
            return (
                <>
                <label htmlFor="tipoDePagamento">{this.generateLabel()}</label>
                <input
                // style={{color: '#000'}}
                    type="text"
                    className={"form-control " + this.props.inputTipoDePagamentoErrorClass}
                    readOnly
                    value = {this.props.selectedTipoDePagamentoLabel}
                    name="selectedTipoDePagamentoLabel"
                    id="selectedTipoDeRecebimentoLabel"
                    onClick={this.inputClick}
                    onKeyPress={this.handleKeyPress}
                    placeholder="Nenhum Tipo de Pagamento Selecionado..."
                    disabled={this.props.disabled}
                />
                {/* <div className="invalid-feedback">{this.props.errorTipoDePagaentoMessage}</div>  */}
                <div className="invalid-feedback">Selecione o Tipo de Pagamento</div> 
                </>
            )
        }

        const renderHTMLFormat = () => {
            if(this.props.noHTMLFormat){
                return (
                    <>
                    {renderInput()}
                    </>
                )
            }
            else {
                return (
                    <>
                    {/* <div className="row"> */}
                    <div className="col-md-4">
                    {renderInput()}
                    </div>
                    {/* </div> */}
                    </>
                )
            }
        }

        return(
            <>
                {/* <div className="col-md-4">
                   
                    </div> */}
                {renderHTMLFormat()}

                    
                <ChooseTipoDePagamentoDialog
                    header={this.generateLabel()}
                    visible={this.state.chooseTipoDePagamentoDialogVisible || this.props.forceShowDialog}
                    list={this.props.tipoDePagamentoList}
                    contaBancariaId={this.props.contaBancariaId}
                    search={this.props.search}
                    forceUpdate={this.props.forceUpdate}
                    doneForceUpdate={this.props.doneForceUpdate}
                    hideDialog={this.hideTipoDePagamentoDialog}
                    handleSelectionTipoDePagamentoChange={this.handleSelectionTipoDePagamentoChange}
                    provisoesAPagar={this.props.provisoesAPagar}
                    fichaDePagamento={this.props.fichaDePagamento}
                />

            </>
        )

    }

}

export default InputChooseTipoDePagamento