import React from 'react'

import { Dialog } from 'primereact/dialog'

import DialogFooter from './dialogFooter'
import * as popUp from '../components/toastr'
import HandleErrorService from '../app/service/handleErrorService'
import ConstantsUtil from '../context/constantsUtil'
import UserService from '../app/service/userService'
import FormGroup from './form-group'
import SelectMenu from './selectMenu'
import { MultiSelect } from 'primereact/multiselect'
import GrupoDeEmpresasService from '../app/service/central/grupoDeEmpresasService'
import CheckBoxComponent from './relatorios/checkBoxComponent'

class UserDialog extends React.Component {

    constructor(){
        super()
        this.userService = new UserService()
        this.grupoDeEmpresasService = new GrupoDeEmpresasService()
    }

    state = {
        id: null,

        allGruposList: [],
        gruposSelectList: [],
        selectedGrupos: [],
        inputGruposErrorClass: '',
        errorGruposMessage: '',

        empresasSelectList: [],
        selectedEmpresas: [],
        inputEmpresaErroClass: '',
        errorEmpresaMessage: '',

        nome: '',
        inputNomeErrorClass:'',
        errorNomeMessage:'Informe o nome',

        email:'',
        inputEmailErrorClass: '',
        errorEmailMessage: ConstantsUtil.invalidEmailMessage,

        perfil: '',
        inputPerfilErrorClass: null,
        errorPerfilMessage: ConstantsUtil.perfilErrorMessage,

        recebeNotificacao: false,

    }

    componentDidUpdate(){
        this.setStates()
    }

    setStates = () => {
        if( (this.props.visible && !this.state.didUpdated) || this.props.forceUpdate ){
            this.resetData()
            this.getGruposAndEmpresas()            
            this.resetView()

            if(!this.props.isCadastro){
                this.setState({id: this.props.userToEdit.id})
                this.setState({nome: this.props.userToEdit.name})
                this.setState({email: this.props.userToEdit.email})
                this.setState({perfil: this.props.userToEdit.perfil})
                this.setState({recebeNotificacao: this.props.userToEdit.recebeNotificacao})
            }

            this.resetView()
                
            this.setState({didUpdated: true})
            if(this.props.doneForceUpdate){
                this.props.doneForceUpdate()
            }
        }
    }

    getGruposAndEmpresas = () => {
        this.grupoDeEmpresasService.searchAllGrupos()
        .then(response => {
            var gruposSelectList = []

            this.setState({allGruposList: response.data})

            response.data.forEach(grupo => {
            
                gruposSelectList.push({label: grupo.nome, value: grupo.id})
                

            });

            this.setState({gruposSelectList})

            if(!this.props.isCadastro){
                this.preencheGruposAndEmpresas()
                // console.log("userToEdit: ", this.props.userToEdit)
            }
        })
        .catch(error => {
            console.log("error: ", error)
            HandleErrorService.handleError(error)
        })
    }

    preencheGruposAndEmpresas = async () => {
        let selectedGrupos = []
        let empresasIdListFromUser = this.props.userToEdit.empresasIdList
        this.state.allGruposList.forEach(grupo => {
            let empresasDoGrupoIdList = grupo.empresaList.map(empresa => empresa.id)
            let filteredList = empresasDoGrupoIdList.filter(empresaDoGrupo => empresasIdListFromUser.includes(empresaDoGrupo))
            if(filteredList.length > 0){
                //Alguma empresa deste grupo está associada ao usuário
                selectedGrupos.push(grupo.id)
            }
        })
        // console.log("selectedGrupos: ", selectedGrupos)
        // console.log("empresasIdListFromUser: ", empresasIdListFromUser)
        await this.setState({selectedGrupos})
        this.setPossibleEmpresasSelectList()
        this.setState({selectedEmpresas: empresasIdListFromUser})
    }

    handleChange = async (event) => {
        const name = event.target.name
        const value = event.target.value
        await this.setState({ [name]: value })
    }

    handleGrupoChange = async (event) => {
        // console.log("handleGrupoChange")
        
        await this.handleChange(event)

        await this.setPossibleEmpresasSelectList()

        this.filterPossibleSelectedEmpresas()

        // console.log("this.state.selectedEmpresas", this.state.selectedEmpresas)

    }

    setPossibleEmpresasSelectList = async () => {
        let selectedGrupos = this.state.allGruposList.filter(grupo => this.state.selectedGrupos.includes(grupo.id))
        // console.log("selectedGrupos: ", selectedGrupos)

        let possibleEmpresasSelectList = []
        selectedGrupos.forEach(grupo => {
            grupo.empresaList.forEach(empresa => {
                possibleEmpresasSelectList.push({label: empresa.nome, value: empresa.id})
            })
        })
        await this.setState({empresasSelectList: possibleEmpresasSelectList}) 

    }


    filterPossibleSelectedEmpresas = async () => {
        let possibleEmpresasSelectList = this.state.empresasSelectList
        let possibleEmpresasIds = possibleEmpresasSelectList.map(item => item.value)
        // console.log("possibleEmpresasIds: ", possibleEmpresasIds)
        // console.log("selectedEmpresas inicial: ", this.state.selectedEmpresas)
        let newSelectedEmpresas = []
        if(this.state.selectedEmpresas && this.state.selectedEmpresas.length > 0){
            this.state.selectedEmpresas.forEach(selectedEmpresa => {
                if(possibleEmpresasIds.includes(selectedEmpresa)){
                    newSelectedEmpresas.push(selectedEmpresa)
                }
            })
        }
        await this.setState({selectedEmpresas: newSelectedEmpresas})
        // console.log("selectedEmpresas final: ", this.state.selectedEmpresas)
    }

    hideDialog = () => {
        this.setState({didUpdated: false})
        this.props.hideDialog()
    }
    
    resetData = () => {
        this.setState({selectedGrupos: ""})
        this.setState({selectedEmpresas: ""})
        this.setState({nome: ""})
        this.setState({email: ""})
        this.setState({perfil: ""})
        this.setState({recebeNotificacao: false})
    }

    resetView = () => {
        this.setState({inputNomeErrorClass: ''})
        this.setState({inputEmailErrorClass: ''})
        this.setState({inputPerfilErrorClass: ''})
        
        this.setState({inputGruposErrorClass: ''})
        this.setState({errorGruposMessage: ''})

        this.setState({inputEmpresaErroClass: ''})
        this.setState({errorEmpresaMessage: ''})

    }

    checkData = () => {
        var check = true

        if(!this.state.nome){
            this.setState({inputNomeErrorClass: ConstantsUtil.bootstrapInputErrorClass})
            check = false
        }

        if (! ConstantsUtil.emailRegex.test(this.state.email) ){
            this.setState({inputEmailErrorClass: ConstantsUtil.bootstrapInputErrorClass})
            check = false
        }

        if(!this.state.perfil){
            this.setState({inputPerfilErrorClass: ConstantsUtil.bootstrapInputErrorClass})
            check = false
        }

        if(!this.state.selectedGrupos || this.state.selectedGrupos.length === 0){
            this.setState({inputGruposErrorClass: ConstantsUtil.primereactInputErrorClass})
            this.setState({errorGruposMessage: "Selecione os Grupos"})
            check = false
        }

        if(!this.state.selectedEmpresas || this.state.selectedEmpresas.length === 0){
            this.setState({inputEmpresaErroClass: ConstantsUtil.primereactInputErrorClass})
            this.setState({errorEmpresaMessage: 'Selecione as Empresas'})
            check = false
        }

        return check

    }

    callSave = () => {
        this.resetView()
        if(this.checkData()){
            if(this.props.isCadastro){
                this.signUp()
            }
            else{
                this.update()
            }
        }
    }

    signUp = () => {
        // this.setState({signUpButtonMessage: 'Cadastrando...'})
        // this.setState({disableSignUpButton: true})
        this.props.beginSignUp()
        this.userService.signUp({
            name: this.state.nome,
            email: this.state.email,
            perfil: this.state.perfil,
            url: window.location.href.replace(this.props.pathname, ConstantsUtil.recoverPasswordPath),
            empresasIdList: this.state.selectedEmpresas,
            recebeNotificacao: this.state.recebeNotificacao
        }).then(response => {
            popUp.successPopUp(`Um email foi enviado para ${this.state.email} para confirmação do cadastro.`)
            this.hideDialog()
            this.props.endSignUp()
        }).catch(error => {
            this.props.showDialog()
            HandleErrorService.handleError(error)
        })
    }

    update = () => {
        this.userService.update(
        {
            id: this.state.id,
            name: this.state.nome,
            email: this.state.email,
            perfil: this.state.perfil,
            empresasIdList: this.state.selectedEmpresas,
            recebeNotificacao: this.state.recebeNotificacao
        }
        )
        .then(response => {
            popUp.successPopUp("Usuário atualizado com sucesso!")
            this.props.search()
            this.hideDialog()
            this.setState({didUpdated: false})
        }).catch(error => {
            HandleErrorService.handleError(error)
        })
        this.setState({didUpdated: false})
    }

    render () {

        const editTipoRecebimentoDialogFooter = (
            <DialogFooter save = {this.callSave} hideDialog = {this.hideDialog} disabled={this.props.disabled}/>
        )

        return (
            <>
            <Dialog
                visible={this.props.visible} style={{ width: '450px' }}
                header={this.props.isCadastro ? "Cadastrar novo usuário" : "Editar Usuário"}
                modal
                className="p-fluid"
                footer={editTipoRecebimentoDialogFooter}
                onHide={this.hideDialog}
            >

                <div className="p-field">
                    <label htmlFor="nome">Nome</label>
                    <input style={{color: '#000'}}
                            type="text"
                            className={"form-control " + this.state.inputNomeErrorClass}
                            id="nome"
                            value = {this.state.nome}
                            name="nome"
                            onChange={this.handleChange}
                            // onKeyDown={this.handleKeyUp}
                            disabled={this.props.disabled}
                            />
                    <div className="invalid-feedback">{this.state.errorNomeMessage}</div>
                </div>

                <br/>

                <div className="p-field">
                    <label htmlFor="email">Email</label>
                    <input style={{color: '#000'}}   
                        className={"form-control " + this.state.inputEmailErrorClass}
                        type="text"
                        id="email"
                        name="email"
                        value={this.state.email}
                        onChange = {this.handleChange}
                        onKeyDown={this.handleKeyUp}
                        disabled={this.props.disabled}
                    />
                    <div className="invalid-feedback">{this.state.errorEmailMessage}</div>
                </div>

                <br />

                <FormGroup label = "Perfil: " htmlFor = "InputPerfil">
                    <SelectMenu
                        className={"form-control " + this.state.inputPerfilErrorClass }
                        id="perfil"
                        name="perfil"
                        list= { ConstantsUtil.perfilList }
                        value={this.state.perfil}
                        onChange={this.handleChange}
                        disabled={this.props.disabled}
                    />
                    <div className="invalid-feedback">{this.state.errorPerfilMessage}</div>
                    </FormGroup>

                    <FormGroup label = "Grupos" htmlFor = {this.props.htmlFor}>
                    <div className="p-field">
                    <MultiSelect
                        className={this.state.inputGruposErrorClass}
                        display="chip"
                        showClear
                        filter
                        filterBy="label"
                        value={this.state.selectedGrupos}
                        options={this.state.gruposSelectList}
                        name="selectedGrupos"
                        onChange={this.handleGrupoChange}
                        optionLabel="label"
                        placeholder="Selecione os grupos aos quais o usuário terá acesso"
                        disabled={this.props.disabled}
                    />
                    <small className="p-error">{this.state.errorGruposMessage}</small>
                    </div>
                    </FormGroup>

                    <FormGroup label = "Empresas" htmlFor = {this.props.htmlFor}>
                    <div className="p-field">
                    <MultiSelect
                        className={this.state.inputEmpresaErroClass}
                        display="chip"
                        showClear
                        filter
                        filterBy="label"
                        value={this.state.selectedEmpresas}
                        options={this.state.empresasSelectList}
                        name="selectedEmpresas"
                        onChange={this.handleChange}
                        optionLabel="label"
                        placeholder="Selecione as empresas às quais o usuário terá acesso"
                        disabled={this.props.disabled}
                    />
                    <small className="p-error">{this.state.errorEmpresaMessage}</small>
                    </div>
                    </FormGroup>

                    <div className="p-field col-md-12">
                    <CheckBoxComponent
                        label="Receber Notificações?"
                        checked={this.state.recebeNotificacao}
                        handleCheckBoxChange={(checked) => this.setState({recebeNotificacao: checked})}
                    />
                    </div>


            </Dialog>
            
            </>
        )    
    }

}

export default UserDialog