import React from 'react'

import { Dialog } from 'primereact/dialog'

import DialogFooter from '../dialogFooter'
import * as popUp from '../../components/toastr'
import HandleErrorService from '../../app/service/handleErrorService'
import ConstantsUtil from '../../context/constantsUtil'
import GrupoDeEmpresasService from '../../app/service/central/grupoDeEmpresasService'
import SelectMenu from '../selectMenu'
import CentralService from '../../app/service/central/centralService'
import GeneralServices from '../../app/service/generalServices'
import EmpresaService from '../../app/service/central/empresaService'
import CheckBoxComponent from '../relatorios/checkBoxComponent'
import TooltipButton from '../tooltipButton'

class EmpresaDialog extends React.Component {

    constructor(){
        super()
        this.grupoDeEmpresasService = new GrupoDeEmpresasService()
        this.empresaService = new EmpresaService()
        this.centralService = new CentralService()
    }

    state = {
        id: null,


        nome: '',
        inputNomeErrorClass:'',
        errorNomeMessage:'Informe o nome',

        nomeSimplificado: '',
        inputNomeSimplificadoErrorClass:'',
        errorNomeSimplificadoMessage:'Informe o nome simplificado',

        cnpj: '',
        inputCNPJErrorClass: '',
        errorCNPJMessage: 'CPF ou CNPJ inválido',

        nfstockLabel: '',
        inputNFStockLabelErrorClass: '',

        ativoNfStock: '',

        gruposSelectList: [{label: 'Selecione', value: ''}],
        gruposList: [],
        selectedGrupoId: '',
        selectedGrupo: '',
        inputGrupoErrorClass: '',
        errorGrupoMessage: 'Selecione o grupo',

        workersSelectList: [{label: 'Selecione', value: ''}],
        workersList: [],
        selectedWorkerId: '',
        selectedWorker: '',
        inputWorkerErrorClass: '',
        errorWorkerMessage: 'Selecione o worker',


    }

    componentDidUpdate(){
        this.setStates()
    }

    setStates = () => {
        if( (this.props.visible && !this.state.didUpdated) || this.props.forceUpdate ){
            this.resetView()

            this.searchGrupos()
            this.searchWorkers()

            if(!this.props.isCadastro){
                this.setState({id: this.props.empresaToEdit.id})
                this.setState({nome: this.props.empresaToEdit.nome})
                this.setState({nomeSimplificado: this.props.empresaToEdit.tenantName})
                this.setState({cnpj: this.props.empresaToEdit.cnpj})
                this.setState({nfstockLabel: this.props.empresaToEdit.nfstockLabel})
                this.setState({ativoNfStock: this.props.empresaToEdit.ativoNfStock})
                this.setState({selectedGrupoId: this.props.empresaToEdit.idGrupo})
                this.setState({selectedGrupo: {id: this.props.empresaToEdit.idGrupo}})
                this.setState({selectedWorkerId: this.props.empresaToEdit.worker.id})
                this.setState({selectedWorker: this.props.empresaToEdit.worker})
            }

            this.resetView()
                
            this.setState({didUpdated: true})
            if(this.props.doneForceUpdate){
                this.props.doneForceUpdate()
            }
        }
    }

    searchGrupos = () => {
        this.grupoDeEmpresasService.searchAllGrupos()
        .then(async response => {

            const gruposList = response.data;
            let gruposSelectList = [{label: 'Selecione', value: ''}]
            gruposList.forEach(grupo => {
                gruposSelectList.push({label: grupo.nome, value: grupo.id})
            })

            await this.setState({gruposList})
            await this.setState({gruposSelectList})

            if(gruposList.length === 1){
                const event={
                    target: {
                        value: gruposList[0].id
                    }
                }
                this.handleGrupoChange(event)
            }



        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
    }    

    searchWorkers = () => {
        this.centralService.searchWorkers()
        .then(async response => {

            const workersList = response.data;
            let workersSelectList = [{label: 'Selecione', value: ''}]
            workersList.forEach(worker => {
                workersSelectList.push({label: worker.contextPath, value: worker.id})
            })

            await this.setState({workersList})
            await this.setState({workersSelectList})

            if(workersList.length === 1){
                const event={
                    target: {
                        value: workersList[0].id
                    }
                }
                this.handleWorkerChange(event)
            }



        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
    }    

    handleGrupoChange = async (event) => {
        const idGrupo = event.target.value
        const selectedGrupo = this.state.gruposList.find(grupo => grupo.id == idGrupo)
        if(selectedGrupo === undefined){
            await this.setState({ selectedGrupoId: '' })
            await this.setState({selectedGrupo: ''})
        }
        else{
            await this.setState({ selectedGrupoId: selectedGrupo.id })
            await this.setState({selectedGrupo})
        }
    }

    handleWorkerChange = async (event) => {
        const idWorker = event.target.value
        const selectedWorker = this.state.workersList.find(worker => worker.id == idWorker)
        if(selectedWorker === undefined){
            await this.setState({ selectedWorkerId: '' })
            await this.setState({selectedWorker: ''})
        }
        else{
            await this.setState({ selectedWorkerId: selectedWorker.id })
            await this.setState({selectedWorker})
        }
    }

    handleChange = async (event) => {
        const name = event.target.name
        const value = event.target.value
        await this.setState({ [name]: value })
    }


    hideDialog = () => {
        this.setState({didUpdated: false})
        this.resetData()
        this.props.hideDialog()
    }

    onSuccessCreate = () => {
        popUp.successPopUp("Empresa cadastrada com sucesso!")
        this.hideDialog()
        this.props.onSuccessCreate()
    }

    resetData = () => {
        this.resetView()
        this.setState({id: ''})
        this.setState({nome: ''})
        this.setState({nomeSimplificado: ''})
        this.setState({cnpj: ''})
        this.setState({nfstockLabel: ''})
        this.setState({ativoNfStock: ''})
        this.setState({selectedGrupoId: ''})
        this.setState({selectedGrupo: ''})
        this.setState({selectedWorkerId: ''})
        this.setState({selectedWorker: ''})
        this.props.resetData()
    }    

    resetView = () => {
        this.setState({inputNomeErrorClass: ''})
        this.setState({inputNomeSimplificadoErrorClass: ''})
        this.setState({inputCNPJErrorClass: ''})
        this.setState({inputGrupoErrorClass: ''})
        this.setState({inputWorkerErrorClass: ''})
        this.setState({inputNFStockLabelErrorClass: ''})

    }

    checkData = () => {
        var check = true

        if(!this.state.nome){
            this.setState({inputNomeErrorClass: ConstantsUtil.bootstrapInputErrorClass})
            check = false
        }

        if(!this.state.nomeSimplificado){
            this.setState({inputNomeSimplificadoErrorClass: ConstantsUtil.bootstrapInputErrorClass})
            check = false
        }

        if(!GeneralServices.validarCPFOuCNPJ(this.state.cnpj)){
            this.setState({inputCNPJErrorClass: ConstantsUtil.bootstrapInputErrorClass})
            check = false
        }

        if(!this.state.selectedGrupo){
            this.setState({inputGrupoErrorClass: ConstantsUtil.bootstrapInputErrorClass})
            check = false
        }

        if(!this.state.selectedWorker){
            this.setState({inputWorkerErrorClass: ConstantsUtil.bootstrapInputErrorClass})
            check = false
            }
            
        if(this.state.ativoNfStock && !this.state.nfstockLabel){
            this.setState({inputNFStockLabelErrorClass: ConstantsUtil.bootstrapInputErrorClass})
            check = false
        }

        return check

    }

    callSave = () => {
        this.resetView()
        if(this.checkData()){
            this.createOrUpdate()
        }
    }

    createOrUpdate = () => {
        this.props.onCallCreate()
        this.empresaService.createOrUpdate({
            id: this.state.id,
            nome: this.state.nome.trim(),
            tenantName: this.state.nomeSimplificado.trim(),
            cnpj: this.state.cnpj.trim().replace(/[^\d]+/g,''),
            nfstockLabel: this.state.nfstockLabel ? this.state.nfstockLabel.trim() : this.state.nfstockLabel,
            ativoNfStock: this.state.ativoNfStock,
            idGrupo: this.state.selectedGrupoId,
            worker: this.state.selectedWorker
        }).then(response => {
            this.onSuccessCreate()
        }).catch(error => {
            // console.log("error: ", error)
            HandleErrorService.handleError(error)
            this.props.onErrorCreate()
        })
    }


    render () {

        const renderNFStockLabelInput = () => {
            if(this.state.ativoNfStock)
            return (
                <>
                <div
                    className="p-field"
                    style={ {marginTop: '0.5rem'} }
                >   
                <div className='row'>
                <div className='col-md-5'>
                    <label htmlFor="nfstockLabel">Descrição no NFStock</label>
                </div>
                <div className='col-md-5'>
                    <TooltipButton
                        className='p-button-rounded p-mr-2 medium-font'
                        icon={<i className="pi pi-question" style={{fontSize: '0.6em'}}></i>}
                        style={ {maxHeight: '25px', maxWidth: '25px'} }
                        tooltip={"Este campo deve conter o nome da empresa digitado de forma que a identifique unicamente ao pesquisar no NFStock.\nPode acabar sendo, de fato, igual ao próprio nome da empresa."}
                    />
                </div>
                </div>
                <input
                            type="text"
                            className={"form-control " + this.state.inputNFStockLabelErrorClass}
                            id="nfstockLabel"
                            value = {this.state.nfstockLabel}
                            name="nfstockLabel"
                            onChange={this.handleChange}
                            // onKeyDown={this.handleKeyUp}
                            // disabled={this.props.disabled}
                            />
                    <div className="invalid-feedback">{"Informe a descrição da empresa no NFStock"}</div>                
                </div>
                </> 
            )
        }

        const footer = (
            <DialogFooter save = {this.callSave} hideDialog = {this.hideDialog} disabled={this.props.disabled}/>
        )

        return (
            <>
            <Dialog
                visible={this.props.visible}
                style={{ width: '450px' }}
                header={this.props.isCadastro ? "Cadastrar Nova Empresa" : "Editar Empresa"}
                modal
                className="p-fluid"
                footer={footer}
                onHide={this.hideDialog}
            >

                <div className="p-field">
                    <label htmlFor="nome">Nome</label>
                    <input
                            type="text"
                            className={"form-control " + this.state.inputNomeErrorClass}
                            id="nome"
                            value = {this.state.nome}
                            name="nome"
                            onChange={this.handleChange}
                            // onKeyDown={this.handleKeyUp}
                            // disabled={this.props.disabled}
                            />
                    <div className="invalid-feedback">{this.state.errorNomeMessage}</div>
                </div>

                <br/>

                <div className="p-field">
                    <div className='row'>
                    <div className='col-md-5'>
                    <label>Nome Simplificado</label>
                    </div>
                    <div className='col-md-5'>
                        <TooltipButton
                            className='p-button-rounded p-mr-2 medium-font'
                            icon={<i className="pi pi-question" style={{fontSize: '0.6em'}}></i>}
                            style={ {maxHeight: '25px', maxWidth: '25px'} }
                            tooltipOptions={{
                                className: 'custom-tooltip',
                                position: 'right'
                            }}
                            tooltip={
                                "O nome simplificado pode conter apenas letras minúsculas sem acentuação e números. \n\n"+
                                "Além desses, apenas o caractere _ (underline) é permitido.\n\n"+
                                "O tamanho máximo permitido é de 50 caracteres.\n\n"+
                                "O desejável é que o nome simplificado seja o nome do grupo seguido do nome da empresa. Ex: visaorj_contabilidade ou visaorj_gestao.\n\n"+
                                "O nome simplificado será usado como nome para o schema do banco de dados da empresa."
                            }
                        />
                    </div>
                    </div>
                    <input
                        className={"form-control " + this.state.inputNomeSimplificadoErrorClass}
                        type="text"
                        name="nomeSimplificado"
                        value={this.state.nomeSimplificado}
                        onChange = {this.handleChange}
                        onKeyDown={this.handleKeyUp}
                        disabled={!this.props.isCadastro}
                    />                    
                    <div className="invalid-feedback">{this.state.errorNomeSimplificadoMessage}</div>
                </div>

                <br/>

                <div className="p-field">
                    <label>CPF ou CNPJ</label>
                    <input
                        className={"form-control " + this.state.inputCNPJErrorClass}
                        type="text"
                        name="cnpj"
                        value={this.state.cnpj}
                        onChange = {this.handleChange}
                        onKeyDown={this.handleKeyUp}
                        // disabled={this.props.disabled}
                    />
                    <div className="invalid-feedback">{this.state.errorCNPJMessage}</div>
                </div>

                <br />

                <div className="p-field">
                    <label>Grupo</label>
                    <SelectMenu
                        className={"form-control " + this.state.inputGrupoErrorClass}
                        name="selectedGrupo"
                        list={this.state.gruposSelectList} 
                        value={this.state.selectedGrupoId}
                        onChange={this.handleGrupoChange}
                        // disabled={this.state.disableTipoDeCorte}
                    />
                    <div className="invalid-feedback">{this.state.errorGrupoMessage}</div>
                </div>

                <br />

                <div className="p-field">
                    <label>Worker</label>
                    <SelectMenu
                        className={"form-control " + this.state.inputWorkerErrorClass}
                        name="selectedWorker"
                        list={this.state.workersSelectList} 
                        value={this.state.selectedWorkerId}
                        onChange={this.handleWorkerChange}
                        // disabled={this.state.disableTipoDeCorte}
                    />
                    <div className="invalid-feedback">{this.state.errorWorkerMessage}</div>
                </div>

                <br />

                <div className="col-md-12">
                <CheckBoxComponent
                    label="Importar Notas Fiscais de compra automaticamente? (NFStock)"
                    checked={this.state.ativoNfStock}
                    handleCheckBoxChange={(checked) => this.setState({ativoNfStock: checked})}
                />
                </div>

                {renderNFStockLabelInput()}                

            </Dialog>
            
            </>
        )    
    }

}

export default EmpresaDialog