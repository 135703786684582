import React from 'react'

import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button'

import PDVCaixaService from '../app/service/contasAReceber/pdvCaixaService'
import Card from '../components/card'
import * as popUp from '../components/toastr'
import HandleErrorService from '../app/service/handleErrorService'
import SelectMenu from '../components/selectMenu'
import FornecedorService from '../app/service/contasAPagar/fornecedorService'
import FornecedoresTable from '../components/fornecedores/fornecedoresTable'
import GeneralServices from '../app/service/generalServices';


class CadastrarFornecedorComponent extends React.Component{

    constructor(){
        super();
        // this.generalServices = new GeneralServices();
        this.pdvCaixaService = new PDVCaixaService();
        this.fornecedorService = new FornecedorService();
        this.generalServices = new GeneralServices();
        this.inputNome = React.createRef();
    }    

    state = {

        fornecedorList: [],
        filteredFornecedorList: [],

        nome: '',
        inputNomeErrorClass: '',
        errorNomeMessage: 'Informe o nome',

        cnpj: '',
        inputCNPJErrorClass: '',
        errorCNPJMessage: '',

        selectedTipoFornecedor: '',
        inoutTipoFornecedorErroClass: '',
        errorTipoFornecedorMessage: 'Informe o tipo de fornecedor',

        loading: false,
    }

    componentDidMount(){
        this.search()
    }

    search = () => {

        this.setState({loading: true})

        this.fornecedorService.search(false)
        .then(response => {
            // const list = response
            this.setState({fornecedorList: response.data})
            this.setState({filteredFornecedorList: JSON.parse(JSON.stringify(response.data))})
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
        .finally(() => {
            this.setState({loading: false})
        })
    }

    filter = () => {
        this.setState({
            filteredFornecedorList:
                JSON.parse(
                    JSON.stringify(
                        this.state.fornecedorList.filter(fornecedor => {
                            const nome = this.state.cnpj === '' && GeneralServices.includeString(fornecedor.nome, this.state.nome)
                            const cnpj = this.state.nome === '' && GeneralServices.includeString(fornecedor.cnpj, this.state.cnpj)
                            return (nome || cnpj)
                        }
                        )
                    )
                )
        })
    }

    handleChange = async (event) => {
        const value = event.target.value
        const name = event.target.name
        await this.setState({[name]: value})
        this.filter()
    }

    handleKeyPress = async (e) => {
        await this.generalServices.sleep(1) //essa função é chamada no mesmo instante que a handleChange.
                                            //Precisa esperar um tempo de forma que o valor seja alterado.
        //it triggers by pressing the enter key
        if (e.key === "Enter") {
            this.callSave();
        }
    }

    resetForm = () => {
        this.setState({nome: ''})
        this.setState({cnpj: ''})
        this.setState({selectedTipoFornecedor: ''})
    }

    resetView = () => {
        this.setState({inputNomeErrorClass: ''})

        this.setState({inputCNPJErrorClass: ''})
        this.setState({errorCNPJMessage: ''})

        this.setState({inputTipoFornecedorErrorClass: ''})
    }

    checkData = () => {
        var check = true

        if(!this.state.nome){
            this.setState({inputNomeErrorClass: "is-invalid"})
            check = false
        }

        if(!GeneralServices.validarCPFOuCNPJ(this.state.cnpj)){
            this.setState({inputCNPJErrorClass: "p-invalid"})
            this.setState({errorCNPJMessage: 'CPF ou CNPJ inválido'})
            check = false
        }

        if(!this.state.selectedTipoFornecedor){
            this.setState({inputTipoFornecedorErrorClass: 'is-invalid'})
            check = false
        }

        return check

    }

    callSave = () => {

        this.resetView()

        if(this.checkData()){
            this.save()
        }
    }

    save = () => {

        this.setState({loading: true})

        this.setState({isSavingNewFornecedor: true})
            this.fornecedorService.save(
                {
                    nome : this.state.nome.trim().toUpperCase(),
                    cnpj : this.state.cnpj.trim().replace(/[^\d]+/g,''),
                    tipoFornecedor: this.state.selectedTipoFornecedor
                }
            )
            .then(response => {
                popUp.successPopUp("Fornecedor cadastrado com sucesso!")
                this.resetForm()
                this.search()
                this.inputNome.current.focus()
                if(this.props.handleNewFornecedorCadastrado){
                    this.props.handleNewFornecedorCadastrado()
                }
                if(this.props.search){
                    this.props.search()
                }
                if(this.props.hideDialog){
                    this.props.hideDialog()
                }
            })
            .catch(error => {
                HandleErrorService.handleError(error)
                
            })
            .finally(() => {
                this.setState({loading: false})
                this.setState({isSavingNewFornecedor: false})
            })
    }

    render(){

        const renderTable = () => {
            if(!this.props.insideDialog){
                return(
                    <FornecedoresTable
                        list = { this.state.filteredFornecedorList }
                        search={this.search}
                        nome={this.state.nome}
                        cnpj={this.state.cnpj}
                        loading={this.state.loading}
                    />
                )
            }
            

        }

        return(
            <>
                <div className="row">

                <div className = "col-md-4">
                <label htmlFor="date">Nome do Fornecedor</label>
                    <input style={{color: '#000'}} type="text"
                        ref={this.inputNome}
                        className={"form-control " + this.state.inputNomeErrorClass}
                        value = {this.state.nome}
                        name="nome"
                        onChange={this.handleChange}
                        onKeyDown={this.handleKeyPress}
                        id="nome"
                        placeholder="Digite o nome do fornecedor"
                    />
                    <div className="invalid-feedback">{this.state.errorNomeMessage}</div>
                </div>
                
                <div className = "col-md-4">
                <label htmlFor="date">CPF ou CNPJ</label>
                        <InputText
                            id="cnpj"
                            className={this.state.inputCNPJErrorClass}
                            value = {this.state.cnpj}
                            name="cnpj"
                            onChange={this.handleChange}
                            onKeyDown={this.handleKeyPress}
                            style={{width: '100%'}}
                            placeholder="Digite o CPF ou CNPJ do fornecedor"
                        />
                    <small id="invalidCNPJ" className="p-error p-d-block">{this.state.errorCNPJMessage}</small>
                </div>

                <div className = "col-md-4">
                <label htmlFor="filtro">Tipo de Fornecedor</label>
                <SelectMenu 
                    className={"form-control " + this.state.inputTipoFornecedorErrorClass}
                    name="selectedTipoFornecedor"
                    list= {this.fornecedorService.tipoFornecedorList} 
                    value={this.state.selectedTipoFornecedor}
                    onChange={this.handleChange}
                    disabled={this.props.disabled}
                />
                <div className="invalid-feedback">{this.state.errorTipoFornecedorMessage}</div>  
                </div>
                
                
                </div>

                <br />

                <Button 
                    label="Cadastrar Fornecedor"
                    icon="pi pi-plus"
                    onClick = {this.callSave}
                    style={ {maxWidth: this.props.insideDialog ? '300px' : '' } }
                    // style={ {maxHeight: '35px', maxWidth: this.props.insideDialog ? '15vw' : '' } }
                    disabled = {this.state.isSavingNewFornecedor}
                />

                <br />

                <br />

                {renderTable()}

            </>
        )
    }

}

export default CadastrarFornecedorComponent