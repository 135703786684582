import axios from 'axios'
import ConstantsUtil from '../context/constantsUtil'
import AuthService from './service/authService'

class ApiService {

    constructor(endpoint_url){
        this.endpoint_url = endpoint_url
    }

    getEndpointUrl(){// /api/users -> /${context-path}/api/users
    }

    post(url, object, extraHeaderObj){
        
        let headerObj = {
            headers: {
                [ConstantsUtil.headerVersion]: process.env.REACT_APP_VERSION,
                [ConstantsUtil.accessToken]: AuthService.getAcessToken(),
                [ConstantsUtil.extraHeaderObj]: extraHeaderObj ? JSON.stringify(extraHeaderObj) : null
            }
        }

        return axios.post(
            this.getEndpointUrl() + url,
            object,
            headerObj   
        )

    }

    put(url, object){
        // const token = AuthService.token() ? AuthService.token() : ''
        return axios.put(this.getEndpointUrl() + url, object, {
            headers: {
                [ConstantsUtil.headerVersion]: process.env.REACT_APP_VERSION,
                //[ConstantsUtil.headerHostname]: window.location.hostname,
                [ConstantsUtil.accessToken]: AuthService.getAcessToken()
            //     'Authorization': `Bearer ${token}`,
            //     'Accept' : 'application/json',
            //     'Content-Type': 'application/json'
            }
        })
    }

    delete(url){
        // const token = AuthService.token() ? AuthService.token() : ''
        return axios.delete(this.getEndpointUrl() + url, {
            headers: {
                [ConstantsUtil.headerVersion]: process.env.REACT_APP_VERSION,
                //[ConstantsUtil.headerHostname]: window.location.hostname,
                [ConstantsUtil.accessToken]: AuthService.getAcessToken()
            //     'Authorization': `Bearer ${token}`,
            //     'Accept' : 'application/json',
            //     'Content-Type': 'application/json'
            }
        })
    }

    get(url){
        // const token = AuthService.token() ? AuthService.token() : ''
        return axios.get(this.getEndpointUrl() + url, {
            headers: {
                [ConstantsUtil.headerVersion]: process.env.REACT_APP_VERSION,
                //[ConstantsUtil.headerHostname]: window.location.hostname,
                [ConstantsUtil.accessToken]: AuthService.getAcessToken()
            //     'Authorization': `Bearer ${token}`,
            //     'Accept' : 'application/json',
            //     'Content-Type': 'application/json'
            }
        }
            )
    }
}

export default ApiService