import React from 'react'
import Card from '../components/card'
import FormGroup from '../components/form-group'
import * as popUp from '../components/toastr'
import { withRouter } from 'react-router-dom'
import { AuthContext } from '../main/authProvider'
import UserService from '../app/service/userService'
import HandleErrorService from '../app/service/handleErrorService'
import { Button } from 'primereact/button'
import PasswordInput from '../components/passwordInput'
import ConstantsUtil from '../context/constantsUtil'

class ChangePassword extends React.Component{

    constructor(){
        super()
        this.userService = new UserService()
    }

    state = {
        email: '',
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
        errorPasswordMessage: null, 
        inputPasswordErrorClass: null,
        errorNewPasswordMessage: null, 
        inputNewPasswordErrorClass: null,
        errorConfirmNewPasswordMessage: null,
        inputConfirmNewPasswordErrorClass: null,
        changeButtonMessage: "Confirmar",
        disbaleConfirmButton: false

    }

    componentDidMount(){
        this.setState({email: this.context.userLoggedIn().email})
    }

    handleChange = async (e) => {
        const name = e.target.name
        const value = e.target.value
        // console.log("name: ", name, "value: ", value)
        await this.setState( { [name]: value } )
        // console.log('this.state.currentPassword: ', this.state.currentPassword)
    }

    checkData = () => {
        var check = true

        if(!this.state.currentPassword){
            this.setState({errorPasswordMessage: "Campo senha é obrigatório"})
            this.setState({inputPasswordErrorClass: "is-invalid"})    
            check=false        
        }
        
        if(this.state.newPassword.length < 4){
            this.setState({errorNewPasswordMessage: "A senha deve ter mais de 4 caracteres."})
            this.setState({inputNewPasswordErrorClass: "is-invalid"})    
            check=false 
        }

        if(!this.state.newPassword){
            this.setState({errorNewPasswordMessage: "Campo nova senha é obrigatório"})
            this.setState({inputNewPasswordErrorClass: "is-invalid"})    
            check=false        
        }
        if(!this.state.confirmNewPassword){
            this.setState({errorConfirmNewPasswordMessage: "Campo confirmação de senha é obrigatório"})
            this.setState({inputConfirmNewPasswordErrorClass: "is-invalid"})    
            check=false  
        }


        if(this.state.newPassword && this.state.confirmNewPassword && this.state.newPassword !== this.state.confirmNewPassword){
            this.setState({errorConfirmNewPasswordMessage: "As senhas não conferem"})
            this.setState({inputConfirmNewPasswordErrorClass: "is-invalid"})
            check=false
        }
        return check
    }

    resetView = () => {
        this.setState( {errorPasswordMessage: null} )
        this.setState( {inputPasswordErrorClass: null} )
        this.setState( {errorNewPasswordMessage: null} )
        this.setState( {inputNewPasswordErrorClass: null} )
        this.setState( {errorConfirmNewPasswordMessage: null} )
        this.setState( {inputConfirmNewPasswordErrorClass: null} )
    }

    changePassword = () => {

        this.resetView()

        if(this.checkData()){
            this.setState({changeButtonMessage: 'Alterando...'})
            this.setState({disbaleConfirmButton: true})
            this.userService.changePassword({
                email: this.state.email,
                currentPassword: this.state.currentPassword,
                newPassword: this.state.newPassword
            }).then(response => {
                popUp.successPopUp("Senha alterada com sucesso.")
                this.props.history.push('/login')
                this.context.endSession()
            }).catch(error => {
                HandleErrorService.handleError(error)
                this.setState({changeButtonMessage: 'Confirmar'})
                this.setState({disbaleConfirmButton: false})
            })
        }
    }

    handleKeypress = e => {
        //it triggers by pressing the enter key
      if (e.key === "Enter") {
        this.changePassword();
      }
    }

    render(){
        return(
                    <div className="bs-docs-section">
                        <div className = "col-md-3 d-flex " style ={{margin: 'auto'}} >
                        <Card title = "Alterar Senha">
                            <fieldset>
                                <FormGroup label = "Email: " htmlFor = "email">
                                    <input style={{color: '#000'}} type="email"
                                    className={"form-control "}
                                    value = {this.state.email}
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                    placeholder="Digite o Email"
                                    disabled />
                                </FormGroup>
                                <PasswordInput
                                    label="Senha Atual:"
                                    value={this.state.currentPassword}
                                    name="currentPassword"
                                    handleChange={this.handleChange}
                                    handleKeypress={this.handleKeypress}
                                    inputPasswordErrorClass={this.state.inputPasswordErrorClass}
                                    errorPasswordMessage={this.state.errorPasswordMessage}
                                />                                
                                <PasswordInput
                                    label="Nova Senha:"
                                    value={this.state.newPassword}
                                    name="newPassword"
                                    handleChange={this.handleChange}
                                    handleKeypress={this.handleKeypress}
                                    inputPasswordErrorClass={this.state.inputNewPasswordErrorClass}
                                    errorPasswordMessage={this.state.errorNewPasswordMessage}
                                />                                   
                                <PasswordInput
                                    label="Confirmação da nova Senha:"
                                    value={this.state.confirmNewPassword}
                                    name="confirmNewPassword"
                                    handleChange={this.handleChange}
                                    handleKeypress={this.handleKeypress}
                                    inputPasswordErrorClass={this.state.inputConfirmNewPasswordErrorClass}
                                    errorPasswordMessage={this.state.errorConfirmNewPasswordMessage}
                                />                                  
                                <Button 
                                    label={this.state.changeButtonMessage}
                                    icon="pi pi-check-circle"
                                    onClick={this.changePassword}
                                    style={ {maxHeight: '35px'} }
                                    disabled={this.state.disbaleConfirmButton}
                                    />
                                <Button 
                                    label="Cancelar"
                                    className="p-button-danger"
                                    icon="pi pi-times"
                                    onClick={() => window.location=ConstantsUtil.homePathname}
                                    style={ {maxHeight: '35px', marginLeft: '8px'} }
                                    />
                            
                            </fieldset>
                        </Card>
                    </div>
                    </div>
        )
    }

}
ChangePassword.contextType = AuthContext
export default withRouter( ChangePassword )