import React from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import GeneralServices from '../../../../app/service/generalServices'
import LancarFechamentoBancarioService from '../../../../app/service/conciliacaoBancaria/lancarFechamentoBancarioService'
import ConstantsUtil from '../../../../context/constantsUtil'
import { Button } from 'primereact/button'
import { classNames } from 'primereact/utils'
import * as xlsx from 'xlsx'
import DREFichaBancariaDialog from '../DREFichaBancariaDialog'
import ExpansionTableButton from '../expansionTableButton'
import TableFilters from '../../../tableFilters'


class DRERecebimentoTable extends React.Component {

    state = {
        
        loading: false,

        displayFichaBancariaDialog: false,
        forceUpdateDialog: false,
        elementToView: null, //tipo de recebimento ou grupo de pagamento
        expanded: false,

    }

    constructor(){
        super();
        this.toast = React.createRef();
        this.tableFilters = new TableFilters();
        this.lancarFechamentoBancarioService = new LancarFechamentoBancarioService();

    }

    getTableHeaders = () => {
        
        const periodo = this.props.DRE ? this.props.DRE.initialDate + " - " + this.props.DRE.finalDate : ""
        const periodoLabel = "Período: " + periodo

        return {periodo: periodoLabel}
    }

    viewLancamentos = async (rowData) => {
                
        // var fichaBancaria = rowData.fichaBancariaUnificada
        // fichaBancaria.initialDate = this.props.DRE ? this.props.DRE.initialDate : ''
        // fichaBancaria.finalDate = this.props.DRE ? this.props.DRE.finalDate : ''

        // await this.setState({fichaBancariaToView: JSON.parse(JSON.stringify(fichaBancaria))})
        
        this.setState({elementToView: rowData})
        this.setState({displayFichaBancariaDialog: true})
        this.setState({forceUpdateDialog: true})
    }

    hideDialog = () => {
        this.setState({displayFichaBancariaDialog: false})
        // this.setState({fichaBancariaToView: ''})
    }

    rowClass(rowData) {
        const obj = {
            [ConstantsUtil.totaisTableLabel] : rowData.tipoLancamento === ConstantsUtil.tipoTotaisFichaBancariaLabel
        }

        return obj
    }

    getValorByContaBancaria(rowData, contaBancaria){
        const list = rowData.dreGrupoContaBancariaList
        const selectedItem = list.find(item => item.contaBancaria.id === contaBancaria.id)
        return GeneralServices.showFormattedIfNotNull(selectedItem.valorTotal, true)
    }

    calculaTotal(rowData){
        const list = rowData.dreGrupoContaBancariaList
        var total = 0
        list.forEach(item => total += item.valorTotal)
     
        return GeneralServices.showFormattedIfNotNull(total, true)
    }

    formattedDREMensalRecebimentoToExportByExcel(){
        var formattedList = []

        this.props.DRE.dreRecebimentoList.forEach(item =>{
            var obj = {
                Recebimento: item.nome,
            }
            item.dreGrupoContaBancariaList.forEach(contaBancariaColumn => {
                const nomeBanco = contaBancariaColumn.contaBancaria.formattedName
                var obj2 = { [nomeBanco]: contaBancariaColumn.valorTotal}
                Object.assign(obj, obj2);
            })
            obj.Total = item.total
            const porcentagem = '%'
            obj[porcentagem] = `${item.porcentagem}%`
            formattedList.push(obj)
        })

        return formattedList
    }

    generateWorkSheet(){
        const worksheet = GeneralServices.jsonToSheet(this.formattedDREMensalRecebimentoToExportByExcel());
        return worksheet
    }

    exportExcel = () => {
        const periodo = this.props.DRE.initialDate + " - " + this.props.DRE.finalDate
        const worksheet = this.generateWorkSheet()
        const sheetName = periodo
        const workbook = { Sheets: { [sheetName]: worksheet }, SheetNames: [[sheetName]] };
        this.props.saveAsExcelFile(workbook, `Fluxo de Caixa Mensal de Recebimento - ${periodo} - VisãoRJ`);
    }

    filterList = () => {
        if(!this.state.expanded && this.props.DRE){
            let listToBeReturned = this.props.DRE.dreRecebimentoList.filter(item => item.tipoLancamento ===  ConstantsUtil.tipoTotaisFichaBancariaLabel)
            this.props.resetTotalItem(listToBeReturned)
            return listToBeReturned
        }

        return this.props.DRE.dreRecebimentoList
    }

    render (){

        const renderContasBancariasColumn = () => {
            var contasBancariasColumnList = []

            if(this.props.DRE){
                this.props.DRE.contaBancariaList.forEach(contaBancaria => {
                    contasBancariasColumnList.push(renderColumnItem(contaBancaria))
                });
            }


            return contasBancariasColumnList
        }

        const renderColumnItem = (contaBancaria) => {
            return (
                <Column
                    field={contaBancaria.nomeBanco}
                    header={contaBancaria.nomeBanco}
                    style ={ {minWidth: '300px'} }
                    body={rowData => this.getValorByContaBancaria(rowData, contaBancaria)}
                    // sortable
                />
            )
        }

        const renderTotalColumn = (rowData) => {
            const valor = GeneralServices.showFormattedIfNotNull(rowData.showTotal, true)
            const valorNaoFormatado = rowData.showTotal
            return(
                <>
                    {renderViewLancamentosLink(rowData, valor, valorNaoFormatado)}
                </>
            )
        }

        const renderViewLancamentosLink = (rowData, valor, valorNaoFormatado) => {
            // const cond1 = rowData.fichaBancariaUnificada.fichaBancariaList && rowData.fichaBancariaUnificada.fichaBancariaList.length > 0
            const cond1 = rowData.total !== 0
            const cond2 = rowData.tipoLancamento !== ConstantsUtil.tipoTotaisFichaBancariaLabel
            if( cond1 && cond2 ){
                const classes = classNames({
                    // 'superavitValue': valorNaoFormatado >= 0,
                    'deficitValue': valorNaoFormatado < 0,
                    'linkValue': true
                    
                });
                return(
                    <a 
                        className={classes}
                        // href='/DRE'
                        onClick={() => this.viewLancamentos(rowData)}
                    >
                        {valor}
                    </a>
                )
            }
            else{
                return(
                    <>
                        {valor}
                    </>
                )
            }
        }

        const nomeFilterElement = this.tableFilters.renderTableFilter(
            this.props.dt,
            this.state.selectedNome,
            this.filterList(),
            "selectedNome",
            (name, value) => this.setState({[name]: value}),
            'nome',
            '200px',
            "Nome"
        )

        const totalFilterElement = this.tableFilters.renderTableFilter(
            this.props.dt,
            this.state.selectedTotal,
            this.filterList(),
            "selectedTotal",
            (name, value) => this.setState({[name]: value}),
            'total',
            '200px',
            "Total"
        )

        const porcentagemFilterElement = this.tableFilters.renderTableFilter(
            this.props.dt,
            this.state.selectedPorcentagem,
            this.filterList(),
            "selectedPorcentagem",
            (name, value) => this.setState({[name]: value}),
            'porcentagem',
            '200px',
            "Porcentagem"
        )

        const renderTable = () => {
            return (
                <DataTable
                    ref={this.props.dt}
                    className="p-datatable-sm small-font"
                    value={this.filterList()}
                    onValueChange={(currentList) => this.props.onFilterTable(currentList, this.props.dt)}
                    // className="p-datatable-responsive-demo"
                    rowClassName={this.rowClass}
                    rowHover
                    showGridlines
                    selection={this.state.selectedContasBancaria}
                    onSelectionChange={this.handleSelectionChange}
                    scrollable
                    style={{ maxWidth: '100vw' }}
                    scrollHeight="500px"
                    loading={this.props.loading || this.state.loading}
                    dataKey="id"
                    emptyMessage={ConstantsUtil.nenhumLancamentoEncontradoMessage}
                    // paginator rows={100}
                    // paginatorTemplate={GeneralServices.tablePaginatorTemplate(100, this.props.DRE && this.props.DRE.dreRecebimentoList ? this.props.DRE.dreRecebimentoList.length : '')}
                    // currentPageReportTemplate="Mostrando de {first} ao {last} de {totalRecords} Tipos de Recebimento"
                >
    
                <Column field="nome" header="Nome"
                    style ={ {minWidth: ConstantsUtil.widthColumNomeDreConsolidado} }
                    filter={this.state.expanded} filterElement={nomeFilterElement}
                    body = {rowData => rowData.showNome}
                />
    
                {/* {renderContasBancariasColumn()} */}
    
                <Column field="total" header="Total"
                    style ={ {minWidth: ConstantsUtil.widthColumNomeDreConsolidado} }
                    filter={this.state.expanded} filterElement={totalFilterElement}
                    body = {renderTotalColumn}
                />
    
                <Column field="porcentagem" header="%"
                    sortable
                    style ={ {minWidth: ConstantsUtil.widthColumNomeDreConsolidado} }
                    filter={this.state.expanded} filterElement={porcentagemFilterElement}
                    body = {rowData => `${rowData.showPorcentagem}%`}
                />

            </DataTable>
            )
        }

        const renderExportarButton = () => {
            if(this.state.expanded){
                return(
                    <Button
                        label="Exportar"
                        className="p-button-success small-font"
                        icon={<i className="pi pi-file-excel" style={{fontSize: '1em', marginRight: '0.5em'}}></i>}
                        tooltip="Exportar em excel"
                        tooltipOptions={{position: 'top'}}
                        style={{maxHeight: '10px'}}
                        onClick={this.exportExcel}
                    />                    
                )
            }
        }

        return (
            <>


            <div className = "card-header header-small-height">
            <div className='col-md-12'>

                <div className='row'>
                    <ExpansionTableButton
                        expanded={this.state.expanded}
                        toggleExpansionTable={async () => {
                            await this.setState({expanded: !this.state.expanded})
                            await this.setState({selectedNome: ''})
                            await this.setState({selectedTotal: ''})
                            await this.setState({selectedPorcentagem: ''})
                            this.props.dt.current.reset(); //reseta filtro
                        }}
                    />                     
                    <div className='col-md-4'>
                        <h5 className='small-font-size'>Recebimento</h5>            
                    </div>
                    <div className='col-md-4'>
                        {/* <h5 className='small-font-size'>{this.getTableHeaders().periodo}</h5> */}
                    </div>
                    <div className='col-md-2'>
                    </div>
                    {renderExportarButton()}
                </div>
                
                {/* <div className='row'>
                <div className='col-md-4'>
                    <Button label="Exportar" icon="pi pi-file-excel" className="p-button-success" onClick={this.exportExcel}
                        tooltip="Exportar em excel"
                        tooltipOptions={{position: 'top'}}
                    />
                </div>
                </div> */}
                
            </div>
            </div>

            
            {renderTable()}

            <DREFichaBancariaDialog
                visible={this.state.displayFichaBancariaDialog}
                initialDate={this.props.DRE ? this.props.DRE.initialDate : ''}
                finalDate={this.props.DRE ? this.props.DRE.finalDate : ''}
                element={this.state.elementToView}
                unificarEmpresas={this.props.unificarEmpresas}
                selectedEmpresas={this.props.selectedEmpresas}
                forceUpdate={this.state.forceUpdateDialog}
                doneForceUpdate={() => this.setState({forceUpdateDialog: false})}
                // fichaBancaria={this.state.fichaBancariaToView}
                search={this.props.search}
                recebimento
                saveAsExcelFile={this.props.saveAsExcelFile}
                loadingTable={false}
                hideDialog={this.hideDialog}
            />

            </>
        )
    }


}  

export default DRERecebimentoTable