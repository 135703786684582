import React from 'react'
import { Button } from 'primereact/button'
import { FileUpload } from 'primereact/fileupload'
import { Tag } from 'primereact/tag'
import FileService from '../app/service/fileService'
import HandleErrorService from '../app/service/handleErrorService'
import GeneralServices from '../app/service/generalServices'
import ProgressService from '../app/service/progressService'
import * as popUp from './toastr'
import { Dialog } from 'primereact/dialog'
import ProgressBarComponent from './progress/progressBar'

const maxTotalSizeInMB = 200

class FileUploadCard extends React.Component{

    constructor() {
        super();
        this.fileService = new FileService();
        this.progressService = new ProgressService();
        this.generalServices = new GeneralServices();
        this.hiddenFileInput = React.createRef();
        this.fileUploadRef = React.createRef();
        this.prorgressBarRef = React.createRef();
    }

    state = {
        files: [],
        selectedFiles: null,
        progress: 0,
        key: '',
        completedUpload: false,
        failUpload: false,
        uploadInProgress: false,
        enableProgressBar: false,
        getProgressError: 0,
        noticeSeverity:'',
        noticeMessage:'',
        notice: false,
        displayConfirmation: false,
        totalSize: 100,
    }

    handleClick = () => {
        this.setState({progress: 0})
        this.hiddenFileInput.current.click()
        this.setState({notice: false})
    }

    resetProgressBar = () => {
        this.setState({progress: 0})
        this.setState({notice: false})
        this.setState({enableProgressBar: false})
    }

    // onFileChange = async event => {
    //     var array = this.state.files
    //     array.push(...event.target.files)
    //     await this.setState({files: array})
    //     event.target.value = null
    //   }
    
    uploadInProgress = IsInProgress => {
        this.setState({notice: true})
        this.setState({uploadInProgress: IsInProgress})

        if(this.props.uploadInProgress){
            this.props.uploadInProgress(IsInProgress)
        }
    }

    doCompleteUpload = () => {
        this.setState({completedUpload: true})
        this.setState({progress: 100})
        this.setState({files: []})
        this.uploadInProgress(false)
        this.setState({noticeSeverity: "success"})
        this.setState({noticeMessage: "Upload concluído"})
        this.fileUploadRef.clear()

        if(this.props.onFinish){
            this.props.onFinish(this.state.key)
        }
    }

    doFailUpload = () => {
        this.setState({failUpload: true})
        // this.setState({files: []})
        // this.fileUploadRef.clear()
        this.uploadInProgress(false)
        this.setState({noticeSeverity: "error"})
        this.setState({noticeMessage: "Falha no upload"})
    }

    requestUpload = async (event) => {

        for(const file of event.files){
            const extension = file.name.split(".").pop()
            if(!this.props.accept.includes(extension.toLowerCase())){
                popUp.warningPopUp("São aceitos apenas arquivos de extensão " + this.props.accept)
                return
            }
        }

        if(!this.props.multiple && event.files.length > 1){
            popUp.infoPopUp("Só é permitido upload de 1 arquivo")
            return;
        }
        if(!this.state.uploadInProgress){
            await this.setState({progress: 0})
            await this.setState({notice: false})
            await this.setState({files: event.files})
        } else {
            popUp.infoPopUp("Já há um upload em andamento")
        }

        var totalSize = 0
        for(const file of event.files){
            totalSize += file.size
        }

        const totalSizeInMB = totalSize/1000000
        if(totalSizeInMB > maxTotalSizeInMB){
            popUp.infoPopUp("O tamanho total dos arquivos (" + totalSizeInMB.toFixed(1) + "MB) não pode ultrapassar " + maxTotalSizeInMB + "MB.")
            return;
        }

        // this.setState({displayConfirmation: true})
        this.newUpload()

    }

    newUpload = () => {
        this.setState({displayConfirmation: false})
        this.setState({enableProgressBar: true})
        this.setState({completedUpload: false})
        var bodyFormData = new FormData();
        for (const file of this.state.files) {
            bodyFormData.append('files', file);
          }

        if(this.props.extraObject){
            bodyFormData.append('extraObject', this.props.extraObject)
        }

        bodyFormData.append('tipoUpload', this.props.uploadDescription)
        this.uploadInProgress(true)

        this.fileService.mutipleUpload(bodyFormData)
        .then(async response => {
            await this.setState({key: response.data})
            // this.getProgress()
        }).catch(error => {
            this.prorgressBarRef.current.doFailProgress()
            HandleErrorService.handleError(error)
        })

    }

    onTemplateRemove(file, callback) {
        this.setState((prevState) => ({
            totalSize: prevState.totalSize - file.size
        }), callback);
    }

    render() {
        
        const renderConfirmationFooter = () => {
            return (
                <div>
                    <Button label="Confirmar" icon="pi pi-check"
                            onClick={this.newUpload} autoFocus />
                    <Button label="Cancelar" icon="pi pi-times" onClick={() => this.setState({displayConfirmation: false})}
                            className="p-button-text" />
                </div>
            );
        }

        const renderUploadButton = (uploadButton) => {
            if(!this.state.uploadInProgress){
                return uploadButton
            }
        }

        const renderCancelButton = (cancelButton) => {
            if(!this.state.uploadInProgress){
                return cancelButton
            }
        }

        const headerTemplate = (options) => {
            const { className, chooseButton, uploadButton, cancelButton } = options
            // const value = this.state.totalSize/10000;
            // const formatedValue = this.fileUploadRef ? (this.state.totalSize) : '0 B';

            return (
                <div className={className} style={{backgroundColor: 'transparent', display: 'flex', alignItems: 'center'}}>
                    {chooseButton}
                    {renderUploadButton(uploadButton)}
                    {renderCancelButton(cancelButton)}
                </div>
            );
        }

        const itemTemplate = (file, props) => {
            return (
                <div className="p-d-flex p-ai-center p-flex-wrap">
                    <div className="p-d-flex p-ai-center" style={{width: '40%'}}>
                        {/* <img alt={file.name} role="presentation" src={file.objectURL} width={100} /> */}
                        <span className="p-d-flex p-dir-col p-text-left p-ml-3">
                            {file.name}
                            {/* <small>{new Date().toLocaleDateString()}</small> */}
                        </span>
                    </div>
                    <Tag value={props.formatSize} severity="warning" className="p-px-3 p-py-2" />
                    {
                        this.state.uploadInProgress ? (
                            <div />
                        ) : (
                            <>
                            <Button type="button" icon="pi pi-times"
                                    className="p-button-outlined p-button-rounded p-button-danger p-ml-auto"
                                    onClick={() => this.onTemplateRemove(file, props.onRemove)} 
                            />
                            </>
                        )
                    }
                </div>
            )
        }

        const emptyTemplate = () => {
            return (
                <div className="p-d-flex p-ai-center p-dir-col">
                    {/* <i className="pi pi-image p-mt-3 p-p-5" style={{'fontSize': '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)'}}></i> */}
                    <span style={{'fontSize': '1.2em', color: 'var(--text-color-secondary)'}} className="p-my-5">Arraste os arquivos até aqui</span>
                </div>
            )
        }

        // const chooseOptions = {icon: 'pi pi-fw pi-images', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined'}
        const chooseOptions = {label: "Escolher arquivos", icon: 'pi pi-plus',
            className: 'custom-choose-btn p-button-rounded p-button-outlined'}
        
        const uploadOptions = {label: "Upload" , icon: 'pi pi-fw pi-cloud-upload',
            className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined'}
        
        const cancelOptions = {label: "Excluir arquivos" , icon: 'pi pi-fw pi-times',
            className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined'}

        const renderProgressBar = () => {
            if(this.state.enableProgressBar){
                return (
                    <ProgressBarComponent
                        ref={this.prorgressBarRef}
                        progressKey={this.state.key}
                        failProgress={this.doFailUpload}
                        endProgress={this.doCompleteUpload}
                        successNoticeMessage="Upload concluído com sucesso!"
                        failNoticeMessage="Falha no upload. Tente novamente."
                    />
                )
            }
        }

        return (
            <div className = "card mb-12">                

            <FileUpload ref={(el) => this.fileUploadRef = el} name="file[]"
                multiple={this.props.multiple}
                accept={this.props.accept}
                // maxFileSize={maxTotalSizeInMB}
                customUpload uploadHandler={this.requestUpload} onSelect = {this.resetProgressBar}
                headerTemplate={headerTemplate} itemTemplate={itemTemplate} emptyTemplate={emptyTemplate}
                chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions}
            />

                <Dialog header="Upload"
                        visible={this.state.displayConfirmation}
                        modal = {true} //congela restante da tela
                        style={{ width: '350px' }}
                        footer={renderConfirmationFooter()}
                        onHide={() => this.setState({displayConfirmation: false})}>
                    <div className="confirmation-content row" style={{marginLeft: '10px'}}>
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem', marginRight: '10px'}} />
                        <div style={{marginBottom: '10px'}}> Deseja confirmar upload? </div>
                    </div>
            </Dialog>
            
            {renderProgressBar()}

            {/* {this.state.enableProgressBar ? (
                <>
                    {this.state.progress === 0 && !this.state.failUpload ? (
                    <>
                        <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>
                    </>
                    ) : (
                    <>
                        <ProgressBar value={this.state.progress} />
                        {this.state.uploadInProgress || !this.state.notice ? (
                            <div />
                        ) : (
                            <EndProgressdNotice severity={this.state.noticeSeverity} message={this.state.noticeMessage}/>
                        )
                        }
                    </>
                    )}
                </>
                ) : (
                    <div/>
                )
            } */}

            </div>
        )
    }
}

export default FileUploadCard