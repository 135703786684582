import React from 'react'
import { withRouter } from 'react-router-dom'

import Card from '../../components/card'
import { AuthContext } from '../../main/authProvider'
import GeneralServices from '../../app/service/generalServices'
import { Button } from 'primereact/button'

import * as popUp from '../../components/toastr'
import DateIntervalCalendar from '../../components/calendar/dateIntervalCalendar'
import ConstantsUtil from '../../context/constantsUtil'
import FluxoCaixaPrevistoService from '../../app/service/relatorios/fluxoCaixaPrevistoService'
import HandleErrorService from '../../app/service/handleErrorService'
import FluxoCaixaPrevistoTable from '../../components/contasAPagar/provisoes/fluxoCaixaPrevistoTable'

class FlxuoCaixaPrevisto extends React.Component {

    constructor(){
        super()
        this.generalServices = new GeneralServices()
        this.fluxoCaixaPrevistoService = new FluxoCaixaPrevistoService()

    }

    state = {

        selectedFilterType: '',
        missingFilterType: false,
        missingAnyDate: false,
        // inputFilterErrorClass: '',
        // errorFilterErrorMessgae: '',

        initialDate: '',
        initialDateView: '',
        
        finalDate: '',
        finalDateView: '',

        tipoDeLancamento: '',

        loadingTable: false,
        isSearching: false,
        isFinishedSearch: false,

        habilitaBusca: true,

        fluxoCaixaPrevisto: {},

    }

    componentDidMount(){

    }

    handleDateChange = async (initialDate, finalDate) => {
        await this.setState({initialDate})
        
        await this.setState({initialDateView: GeneralServices.convertBrStringToJsDate(initialDate)})

        await this.setState({finalDate})
        await this.setState({finalDateView: GeneralServices.convertBrStringToJsDate(finalDate)})

    }

    handleFilterChange = (selectedFilterType) => {
        this.setState({selectedFilterType})
    }

    checkData = () => {
        
        var check = true;

        if(!this.state.selectedFilterType){
            this.setState({missingFilterType: true})
            check=false
        }
        
        if(!this.state.initialDate || !this.state.finalDate){
            this.setState({missingAnyDate: true})
            check=false
        }

        if(this.state.initialDate && this.state.finalDate){
            if(this.state.initialDateView > this.state.finalDateView){
                check = false
                popUp.infoPopUp(ConstantsUtil.dataInicialAnteriorFinalMessage)
           }
        }

        return check;
    }

    toggleButton = () => {
        this.setState({habilitaBusca: !this.state.habilitaBusca})
    }

    desabilitaBusca = () => {
        this.setState({habilitaBusca: false})
    }

    resetView = () => {
        this.setState({missingFilterType: false})
        this.setState({missingAnyDate: false})
    }

    callSearch = () => {
        this.resetView()
        if(this.checkData()){
            this.search()
        }
    }

    search = () => {

        this.setState({loadingTable: true})
        this.setState({isFinishedSearch: false})
        this.setState({isSearching: true})

        this.fluxoCaixaPrevistoService.getMensal(this.state.initialDate, this.state.finalDate)
        .then(async response => {
            const dadosTratados = this.trataDaddos(response.data)
            await this.setState({fluxoCaixaPrevisto: dadosTratados})
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
        .finally(() => {
            this.setState({loadingTable: false})
            this.setState({isFinishedSearch: true})
            this.setState({isSearching: false})
            this.toggleButton()
        })
        
    }

    trataDaddos = (fluxoCaixaPrevisto) => {
        fluxoCaixaPrevisto.saldoInicial.saldoContaBancariaList.forEach(saldoContaBancaria => {
            saldoContaBancaria.nome = saldoContaBancaria.contaBancariaDTO.formattedName
            saldoContaBancaria.valor = saldoContaBancaria.saldo
        })

        return fluxoCaixaPrevisto
    }


    render() {


        const renderTable = () => {
            if( (this.state.isSearching || this.state.isFinishedSearch) && !this.state.habilitaBusca ){
                return (
                    <FluxoCaixaPrevistoTable
                        fluxoCaixaPrevisto={this.state.fluxoCaixaPrevisto}
                        search={this.search}
                    />
                )
            }

            if(this.state.isSearching){
                return (
                    <FluxoCaixaPrevistoTable
                        fluxoCaixaPrevisto={[]}
                        // search={this.search}
                        loading={true}
                        // push={this.props.history.push}
                    />
                )
            }
        }

        const renderButtons = () => {

            if(this.state.isSearching){
                return (
                    <Button 
                        label="Buscando ..."
                        icon="pi pi-search"
                        disabled
                    />
                )
            }

            if(this.state.habilitaBusca){
                return (
                    <Button 
                        label="Gerar Relatório"
                        icon="pi pi-search"
                        onClick = {this.callSearch}
                        // style={ {maxHeight: '35px' } }
                    />
                )
            }
            return (
                <>
                    <Button 
                        label="Voltar"
                        icon="pi pi-undo"
                        className="p-button-danger"
                        onClick = {this.toggleButton}
                        // style={ {maxHeight: '35px' } }
                        // disabled = {this.state.isSearchingLancamentos}
                    />
                </>
            )
        }

        return (
            <div className="bs-docs-section" >
            <Card   
                title = "Fluxo de Caixa Previsto"
            >
                <div className = "col-md-12">

                    <div className='row'>

                        <DateIntervalCalendar
                            htmlFor="SelectDatePeriod"
                            handleFilterChange={this.handleFilterChange}
                            handleDateChange={this.handleDateChange}
                            missingFilterType={this.state.missingFilterType}
                            missingAnyDate={this.state.missingAnyDate}
                            disabled={!this.state.habilitaBusca}
                            preSetFilterType={ConstantsUtil.calendarPersonalizadoFilterLabel}
                            preSetDataInicial={GeneralServices.getAddedDays(-1)}
                        />
                        
                    </div>

                    <br />
                        
                    {renderButtons()}

                    <br />

                    <br />

                    {renderTable()}
                    
                </div>
            </Card>
            <div className="d-flex "/>
        </div>     
        )
    }

}


FlxuoCaixaPrevisto.contextType = AuthContext

export default withRouter(FlxuoCaixaPrevisto)