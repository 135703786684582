import React from 'react'
import Card from '../../components/card'
import { withRouter } from 'react-router-dom'

import { AuthContext } from '../../main/authProvider'
import PlanoDeContasService from '../../app/service/planoDeContasService'
import HandleErrorService from '../../app/service/handleErrorService'
import PlanoDeContasSearchTable from '../../components/planoDeContas/planoDeContasSearchTable'

class PlanoDeContas extends React.Component {

    constructor(){
        super();
        this.planoDeContasService = new PlanoDeContasService();
    }

    componentDidMount(){
        this.search()
    }

    state = {
        planoDeContasList: '',
        selectedContaContabil: '',

        loadingTable: false,

    }

    search = () => {

        this.setState({loadingTable: true})
        this.setState({nomeContaSearch: ''})

        this.planoDeContasService.search()
        .then(response => {
            this.setState({planoDeContasList: response.data})
            this.setState({finishedSearch: true})
            this.setState({loadingTable: false})
        }).catch(error => {
            HandleErrorService.handleError(error)
            this.setState({loadingTable: false})
        })
    }

    render() {

        return (
            <div className="bs-docs-section"
                    // style={{minHeight:'500px'}}
                >
                <Card title = "Plano de Contas">
                <div className = "col-md-12" >

                    <PlanoDeContasSearchTable
                        planoDeContasList={this.state.planoDeContasList}
                        selectedContaContabil={this.state.selectedContaContabil}
                        handleSelectionContaContabilChange={this.handleSelectionChange}
                        loading={this.state.loadingTable}
                        // handleKeyPress={this.handleKeyPress}
                        push={this.props.history.push}
                        renderAddButton={true}
                        context = {this.context}
                    />

                </div>
                </Card>
                <div className = "d-flex" />
            </div>
        )
        }

}

PlanoDeContas.contextType = AuthContext

export default withRouter(PlanoDeContas)