import React from 'react'

import InputChooseContaContabil from './inputChooseContaContabil'
import ContaContabilView from './contaContabilView'

class ContaContabilViewInsideDialog extends React.Component {


    render(){

        return (
            <>
            <div className="row">
                <div className="col-md-10">

                   <InputChooseContaContabil
                        noHTMLFormat
                        selectedContaContabilLabel={this.props.contaContabil.nomeConta}
                        push={this.props.push}
                        handleSelectionContaContabilChange={this.props.handleSelectionContaContabilChange}
                        inputContaContabilErrorClass={this.props.inputContaContabilErrorClass}
                        disabled={this.props.disabled}
                        />
                </div>

                    <div className="col-md-2"
                        style ={{
                            // display: 'flex',
                            // alignItems: 'center',
                            marginTop: '35px'
                        }}
                    >
                    <ContaContabilView
                        contaContabil={this.props.contaContabil}
                        hideCodigo
                        push={this.props.push}
                    />
                    </div>
                   
                    </div>

                    <br />
                </>
        )
    }

}

export default ContaContabilViewInsideDialog