import { Button } from 'primereact/button'
import React from 'react'

class TooltipButton extends React.Component {

    render () {
        return (
            <Button
                label={this.props.label}
                tooltip = {this.props.tooltip}
                tooltipOptions={ this.props.tooltipOptions ? this.props.tooltipOptions : { position: 'top', mouseTrack: true, mouseTrackTop: 15 } }
                icon={this.props.icon ? this.props.icon : "pi pi-pencil"}
                className={ this.props.className ? this.props.className : "p-button-rounded p-mr-2 " }
                style={this.props.style ? this.props.style : {}}
                onClick={this.props.onClick}
                disabled={this.props.disabled}
            />
        )
    }
}

export default TooltipButton