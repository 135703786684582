import CentralApiService from "../../centralApiServices";


class AnotacaoService extends CentralApiService {

    constructor() {
        super('/api/anotacao')
    }

    create(anotacao){
        return this.post("/create", anotacao)
    }

    edit(anotacao){
        return this.put("/edit", anotacao)
    }

    deleteAnotacao(anotacao){
        return this.delete(`/delete/${anotacao.id}`)
    }

    deleteAllAnotacao(anotacoesList){
        return this.post(`/deleteAll`, anotacoesList)
    }

}

export default AnotacaoService