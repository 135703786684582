import WorkerApiService from "../workerApiServices";
import GeneralServices from "./generalServices";


class TipoProdutoService extends WorkerApiService {

    constructor(endpoint_url) {
        super(endpoint_url || '/api/tipoProduto');
    }

    search(){
        return this.get(`/search`)
    }

    save(tipoDeProduto){
        return this.post(`/save`, tipoDeProduto)
    }


}


export default TipoProdutoService