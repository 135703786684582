import CentralApiService from "../../centralApiServices";


class NotificacaoService extends CentralApiService {

    constructor() {
        super('/api/notificacao')
    }

    count(){
        return this.get(`/count`)
    }

    search(){
        return this.get(`/search`)
    }

    marcarLida(notificacao){
        return this.post(`/marcarLida`, notificacao)
    }

    marcarComoNaoLida(notificacao){
        return this.post(`/marcarComoNaoLida`, notificacao)
    }

    conferePagamentosDia(empresaList){
        return this.post("/conferePagamentosDoDia", empresaList)
    }    

}

export default NotificacaoService