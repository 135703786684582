import CryptoJS from 'crypto-js';

const SECRET_KEY = '$2a$12$nITrfVd0A/ggk8gDiZjJq.6Tdys5xVYCAcIpOoL9B0Gv0mKaO1XDO'

class SessionStorageService {

    static addItem(key, value){
        // console.log(key, value)
        const encrypted = CryptoJS.AES.encrypt(JSON.stringify(value), SECRET_KEY).toString();
        // sessionStorage.setItem(key, JSON.stringify(value))
        sessionStorage.setItem(key, encrypted)
    }

    static getItem(key){
        try{
            const encrypted = sessionStorage.getItem(key);
            const decrypted = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(CryptoJS.enc.Utf8);
            return JSON.parse(decrypted);
            // const value = JSON.parse(sessionStorage.getItem(key))
            // return value
        } catch(e){
            return ''
        }
    }

    static removeItem(key){
        sessionStorage.removeItem(key)
    }

}

export default SessionStorageService