import toastr from 'toastr'
import ConstantsUtil from '../context/constantsUtil'

var options={
    closeButton: true,
    debug: false,
    newestOnTop: false,
    progressBar: true,
    positionClass: "toast-top-right",
    preventDuplicates: false,
    onclick: null,
    showDuration: "300",
    hideDuration: "1000",
    timeOut: "5000",
    extendedTimeOut: "1000",
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut"
}

toastr.options = options

  export function popUp(title, message, type){
    toastr[type](message, title)
  }

  export function successPopUp(message){
      popUp("Sucesso", message, "success")
  }

  export function warningPopUp(message){
      popUp("Alerta", message, "warning")
  }
  export function infoPopUp(message){
      popUp("Info", message, "info")
    }
    
    export function errorPopUp(message){
      popUp("Erro", message, "error")
      // popUp("Info", message, "info")
  }

  export function noticeParametrizePopUp(){
      //define toastr options para o popUp noticeParametrizePopUp. Basicamente coloca tempo inf.
      options.showDuration="inf"
      options.hideDuration="inf"
      options.timeOut="inf"
      options.extendedTimeOut="inf"
      
      popUp("", 
      "<div>Há produtos não parametrizados</div><a href=\"/parameterize\"> <button type=\"button\" id=\"okBtn\" class=\"btn btn-danger\">Ir para as parametrizações</button></a>"
      , "error")
      
      //volta às configurações iniciais para não alterar demais popUps
      options.showDuration="300" 
      options.hideDuration="1000"
      options.timeOut="5000"
      options.extendedTimeOut="1000"
  }

  export function noticeFalhaImportacaoNFsPopUp(){
      //define toastr options para o popUp noticeParametrizePopUp. Basicamente coloca tempo inf.
      options.showDuration="inf"
      options.hideDuration="inf"
      options.timeOut="inf"
      options.extendedTimeOut="inf"

      const pathname = ConstantsUtil.historicoImportacaoNFsPathname
      
      popUp("", 
      `<div>Há falha de importação de Notas Fiscais!</div><a href=\"${pathname}\"> <button type=\"button\" id=\"okBtn\" class=\"btn btn-danger\">Ir para o Histórico de Importação</button></a>`
      , "error")
      
      //volta às configurações iniciais para não alterar demais popUps
      options.showDuration="300" 
      options.hideDuration="1000"
      options.timeOut="5000"
      options.extendedTimeOut="1000"
  }

  export function noticePdvCaixaPopUp(){
      
      options.showDuration="inf"
      options.hideDuration="inf"
      options.timeOut="inf"
      options.extendedTimeOut="inf"

      popUp("", 
      "<div>Ainda não há nenhum Ponto de Venda (Caixa) cadastrado.</div><a href=\"/cadastrarPDVCaixa\"> <button type=\"button\" id=\"okBtn\" class=\"btn btn-info\">Cadastrar PDV(Caixa)</button></a>"
      , "info")
      
      options.showDuration="300"
      options.hideDuration="1000"
      options.timeOut="5000"
      options.extendedTimeOut="1000"
  }

  export function versaoDesatualizadaPopUp(){
      
      options.showDuration="inf"
      options.hideDuration="inf"
      options.timeOut="inf"
      options.extendedTimeOut="inf"

      popUp("", 
        "<div>" + 
          "Você está utilizando uma versão desatualizada do sistema!" + "<br />" +
          "Feche todas as abas do sistema e abra novamente."  + "<br />" +
          "Ou, caso esteja utilizando o computador, basta pressionar Ctrl+F5." +
        "</div>"
        , "warning"
      )
      
      options.showDuration="300"
      options.hideDuration="1000"
      options.timeOut="5000"
      options.extendedTimeOut="1000"
  }

//   export function reloadApp(){
      
//       options.showDuration="inf"
//       options.hideDuration="inf"
//       options.timeOut="inf"
//       options.extendedTimeOut="inf"

//       popUp("", 
//       "<div>A new version of this page is available.</div><a href=\"/home\"> <button type=\"button\" id=\"okBtn\" class=\"btn btn-info\">Refresh</button></a>"
//       , "info")
      
//       options.showDuration="300"
//       options.hideDuration="1000"
//       options.timeOut="5000"
//       options.extendedTimeOut="1000"
//   }