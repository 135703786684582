import React from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import GeneralServices from '../../../../app/service/generalServices'
import { Button } from 'primereact/button'
import { classNames } from 'primereact/utils'
import * as xlsx from 'xlsx'
import ConstantsUtil from '../../../../context/constantsUtil'


class DREResultadoConsolidadoTable extends React.Component {

    state = {
        
        loading: false,

    }

    getTableHeaders = () => {
        
        const periodo = this.props.initialDate ? this.props.initialDate + " - " + this.props.finalDate : ""
        const periodoLabel = "Período: " + periodo

        return {periodo: periodoLabel}
    }

    saldoClass(rowData) {
        var obj ={}
        if(rowData.total > 0){
            obj = {
                'superavit': true
            }
        }
        else if(rowData.total < 0){
            obj = {
                'deficit': true
            }
        }

        return obj
    }

    getList = () => {
        if(this.props.tipo === ConstantsUtil.resultadoOpracionalKey) {
            return this.props.dreConsolidado.resultado
        }
        else if(this.props.tipo === ConstantsUtil.resultadoFinalKey) {
            return this.props.dreConsolidado.resultadoFinal
        }
    }

    formattedDREMensalResultadoToExportByExcel(){

        var obj = {'': this.props.header}
        var formattedList = [obj]
        
        const resultado = JSON.parse(JSON.stringify(this.getList()))
        
        // console.log("resultado:", resultado)
        
        this.props.dreConsolidado.descricaoMesesList.forEach(descricaoMes =>{
            
            // console.log("descricaoMes:", descricaoMes)

            obj[descricaoMes] = resultado.resultadoMap[descricaoMes]
        })

        obj.Total = resultado.total
        const porcentagem = '%'
        obj[porcentagem] = `${resultado.porcentagem}%`
        return formattedList
    }

    generateWorkSheet(){
        const worksheet = GeneralServices.jsonToSheet(this.formattedDREMensalResultadoToExportByExcel());
        return worksheet
    }

    exportExcel = () => {
        const periodo = this.props.initialDate + " - " + this.props.finalDate
        const worksheet = this.generateWorkSheet()
        const sheetName = periodo
        const workbook = { Sheets: { [sheetName]: worksheet }, SheetNames: [[sheetName]] };
        this.props.saveAsExcelFile(workbook, `Fluxo de Caixa Mensal ${this.props.header} - ${periodo} - VisãoRJ`);
    }

    render (){

        const renderMesesColumn = () => {
            var mesesColumnList = []

            if(this.props.dreConsolidado && this.props.dreConsolidado.descricaoMesesList && this.props.dreConsolidado.descricaoMesesList.length !== 0){
                this.props.dreConsolidado.descricaoMesesList.forEach(descricaoMes => {
                    mesesColumnList.push(renderColumnItem(descricaoMes))
                });
            }

            return mesesColumnList
        }

        const renderColumnItem = (descricaoMes) => {
            return (
                <Column
                    field={descricaoMes}
                    header={descricaoMes}
                    style ={ {width: ConstantsUtil.widthColumDreConsolidado} }
                    body={resultado => {
                        return (renderColumn(resultado.resultadoMap[descricaoMes]))
                     }
                    }
                    // sortable
                />
            )
        }

        const renderColumn = (mesValor) => {

            return(
                <div className='right-align'>
                {renderStyledValue(mesValor)}
                </div>
            )
        }

        const renderStyledValue = (valor) => {

            const classes = classNames({
                'superavitValue': valor >= 0,
                'deficitValue': valor<0
            });
    
            return (
                <div className={classes}>
                    {GeneralServices.showFormattedIfNotNull(valor, true)}
                </div>
            );
        }

        // const renderExportarButton = () => {
        //     if(this.state.expanded){
        //         return (
        //             <Button label="Exportar"
        //                 icon={<i className="pi pi-file-excel" style={{fontSize: '1em', marginRight: '0.5em'}}></i>}
        //                 className="p-button-success small-font" onClick={this.exportExcel}
        //                 tooltip={ConstantsUtil.exportXlsxLabel}
        //                 tooltipOptions={{position: 'top'}}
        //                 style={{maxHeight: '15px'}}
        //             />                    
        //         )
        //     }
        // }

        return (
            <>
            <div className = "card-header header-small-height" >
            <div className='col-md-12'>
                
                <div className='row'>
                    <div className='col-md-4' style={{marginLeft: '1.05rem'}}>
                        <h5 className='small-font-size'>{this.props.header}</h5>            
                    </div>
                    <div className='col-md-4'>
                        {/* <h5 className='small-font-size'>{this.getTableHeaders().periodo}</h5> */}
                    </div>
                    <div className='col-md-2'>
                    </div>
                    {/* {renderExportarButton()} */}
                </div>
                
                {/* <div className='row'>
                <div className='col-md-4'>
                    <Button label="Exportar" icon="pi pi-file-excel" className="p-button-success" onClick={this.exportExcel}
                        tooltip={ConstantsUtil.exportXlsxLabel}
                        tooltipOptions={{position: 'top'}}
                    />
                </div>
                </div> */}
                
            </div>
            </div>

            
            <DataTable
                ref={this.dt}
                value={[this.getList()]}
                className="p-datatable-sm small-font"
                // className="p-datatable-responsive-demo"
                // rowClassName={this.saldoClass}
                // autoLayout
                rowHover
                showGridlines
                // selection={this.state.selectedContasBancaria}
                // onSelectionChange={this.handleSelectionChange}
                // scrollable
                // scrollHeight="500px"
                style={{ maxWidth: '100vw' }}
                frozenWidth={ConstantsUtil.widthColumNomeDreConsolidado}
                loading={this.props.loading || this.state.loading}
                dataKey="id"
                emptyMessage="Nenhum Resultado encontrado."
                // paginator rows={ConstantsUtil.initialTableQuantityOption}
                // paginatorTemplate={GeneralServices.tablePaginatorTemplate(ConstantsUtil.initialTableQuantityOption, this.props.list ? this.props.list.length : '')}
                // currentPageReportTemplate="Mostrando de {first} ao {last} de {totalRecords} Tipos de Pagamento"
            >

            <Column field="nome" header="Nome"
                style ={ {width: ConstantsUtil.widthColumNomeDreConsolidado} }
                frozen
                body = {rowData => "RESULTADO"}
            />

            {renderMesesColumn()}

            <Column field="total" header="Total"
                style ={ {width: ConstantsUtil.widthColumDreConsolidado} }
                body = {rowData => {
                    return(
                        <div className='right-align'>
                            {rowData ? renderStyledValue(rowData.total) : ''}
                        </div>
                    )
                }}
            />

            <Column field="porcentagem" header="%"
                style ={ {width: ConstantsUtil.widthColumDreConsolidado} }
                body = {rowData =>  {
                    return (
                        <div className='right-align'>
                            {rowData ? `${rowData.porcentagem}%` : ''}
                        </div>
                    )
                }}
            />

            </DataTable>

            </>
        )
    }


}  

export default DREResultadoConsolidadoTable