import { warningPopUp, errorPopUp, versaoDesatualizadaPopUp } from "../../components/toastr"
import ConstantsUtil from "../../context/constantsUtil"

class HandleErrorService {

    static handleError(error){
        const response = error.response
        if(response){
            if(response.status === 401){
                warningPopUp('Login expirado.')
                window.location=ConstantsUtil.logoutPath
            }
            else if(response.status === 504){
                warningPopUp('Falha de conexão ao servidor. Tente novamente.')
            }
            else if(response.status === 403){
                errorPopUp('Usuário sem permissão a esta funcionalidade.')
                setTimeout(
                    () => window.location=ConstantsUtil.logoutPath, 
                    2000
                  );
        }
            else if(response.status === 420){
                /*
                    420 é um código de erro não utilizado como padrão para nenhuma situação segundo https://www.semrush.com/blog/http-status-codes/?kw=&cmp=BR_POR_SRCH_DSA_Blog_EN&label=dsa_pagefeed&Network=g&Device=c&utm_content=622460801193&kwid=dsa-1754723155433&cmpid=18361973175&agpid=144288907351&BU=Core&extid=60109531726&adpos=&gclid=Cj0KCQjw_r6hBhDdARIsAMIDhV9ZakIrhbGtXffdAwt4iQFj5ewOCznHnr-CuXAuOIUEmOzbI6vwRmIaAp8mEALw_wcB
                    Sendo assim, utilizo ele como sendo um código específico da minha aplicação para representar a situação
                    em que a versão do frontend do computador do usuário está desatualizada, sendo necessário dar um hard refresh
                    ou fechar as abas do site/app do navegador.
                */
                versaoDesatualizadaPopUp()
            }
            else {
                errorPopUp(response.data)
            }
            return
        }
        if(!error.status){
            warningPopUp('Falha de conexão ao servidor. Tente novamente.')
            return
        }
    }

}

export default HandleErrorService