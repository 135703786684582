import WorkerApiService from "../../workerApiServices";

class FichaBancariaService extends WorkerApiService {
 
    constructor() {
        super('/api/fichaBancaria')
    }

    getFichaBancariaByDateInterval(obj){
        return this.post("/searchByDateInterval", obj)
    }

    getFichaBancariaByValue(valor){
        let params = `?`
        params = `${params}&valor=${valor}`
        return this.get(`/searchByValue${params}`)
    }

    getFichaBancariaUnificada(data){
        return this.post(`/getFichaBancariaUnificada`, data)
    }

    getUploadExtratoOFXResult(progressKey){
        let params = `?`
        params = `${params}&progressKey=${progressKey}`
        return this.get(`/getUploadExtratoOFXResult${params}`)
    }
    
}

export default FichaBancariaService