import axios from 'axios'
import ConstantsUtil from '../context/constantsUtil'
import AuthService from './service/authService'
import ApiService from './apiServices'

class WorkerApiService extends ApiService {

    constructor(endpoint_url){
        super(endpoint_url)
    }

    getEndpointUrl(){ // transforma /api/users em /${context-path}/api/users
        var apiContextPath = AuthService.getCurrentContextPath()
        return `${apiContextPath}${this.endpoint_url}`
    }

}

export default WorkerApiService