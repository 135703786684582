import React from 'react'
import { Button } from 'primereact/button'
import { Toolbar } from 'primereact/toolbar'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'

import ProductService from '../../app/service/productService'
import * as popUp from '../toastr'
import { Dialog } from 'primereact/dialog'
import GeneralServices from '../../app/service/generalServices'
import TableFilters from '../tableFilters'
import ConstantsUtil from '../../context/constantsUtil'
import { InputNumber } from 'primereact/inputnumber'

class ParametrizeProductTable extends React.Component {

    state = {
        codigo: null,
        descricao: '',
        ncm: null,
        tipo: '',
        unidadeComercializada: '',
        proportion: null,
        selectedProducts: null,
        productDialog: false,
        updateStockDialog: false,
        displayConfirmation: false,
        editId: null,
        updateStockId: null,
        inputProportionErrorClass: '',
        errorProportionMessage: '',
        codigoField: 'codigo',
        selectedCodes: null,
        descricaoField: 'descricao',
        selectedDescriptions: null,
        expandedRows: [], 

    }
    constructor(){
        super();
        this.dt = React.createRef()
        this.productService = new ProductService();
        this.tableFilters = new TableFilters();
        this.generalServices = new GeneralServices()

    }

    handleSelectionChange = async (e) => {
        if(e.value.length > 1 ) {
            popUp.warningPopUp("Apenas um produto pode ser selecionado")
            return
        }
        await this.setState({selectedProducts: e.value})
       if(!this.state.selectedProducts || this.state.selectedProducts.length === 0){
           this.props.search()
       } else{
           this.props.setParametrizeFilteredProducts(this.state.selectedProducts)
       }
    }

    handleProportionChange = (event) => {
        let value = event.value
        if(value === 0){
            value = null
        }
        const name = event.target.name
        this.setState({ [name]: value })
    }

    handleKeypress = async e => {
        /*
            A função é chamada no mesmo instante que a handleProportionChange.
            Precisa esperar um tempo de forma que o valor seja alterado.
        */
        await this.generalServices.sleep(1)
        if (e.key === "Enter") {
            this.parametrize();
        }
    }

    requestParametrize = () => {
        if(this.state.selectedProducts.length === 1){
            this.resetView()
            this.setState({displayConfirmation: true})
        } else{
            popUp.warningPopUp('Mais de um produto selecionado')
        }
    }
    
    resetView = () =>{
        this.setState({inputProportionErrorClass: ''})
        this.setState({errorProportionMessage: ''})
    }

    parametrize = () => {
        if(!this.state.proportion){
            this.setState({inputProportionErrorClass: ConstantsUtil.primereactInputErrorClass})
            this.setState({errorProportionMessage: 'Informe a conversão'})
        }
        else{
            this.props.parametrize(this.state.selectedProducts[0], this.state.proportion)
            this.setState({displayConfirmation: false})
        }
    }

    onFilterChange = async (event, filterField) => {
        if(this.state.expandedRows && this.state.expandedRows.length!==0){
            await this.setState({expandedRows: []})
        }
        const name = event.target.name
        if(this.dt.current) {
            this.dt.current.filter(event.value, filterField, 'in');
        } else{
            this.state.current.filter(event.value, filterField, 'in');
        }
        this.setState({[name]: event.value})
    }

    nomeProdutoEscolhido = () => {
        // console.log("this.state.selectedProducts: ", this.state.selectedProducts)
        if(this.state.selectedProducts){
            return this.state.selectedProducts[0].descricao
        }
        return ""
    }

    render (){

        const leftToolbarTemplate = () => {
            return (
                <React.Fragment>
                    <Button
                        label="Confirmar" icon="pi pi-check"
                        className="p-button-success small-font"
                        style={{maxHeight: '25px'}}
                        disabled = { !this.state.selectedProducts || this.state.selectedProducts.length === 0 }
                        // onClick = {this.parametrize}
                        onClick = {this.requestParametrize}
                    />
                </React.Fragment>
            )
        }

        const renderConfirmationFooter = () => {
            return (
                <div>
                    <Button label="Confirmar" icon="pi pi-check"
                            onClick={this.parametrize} />

                    <Button label="Cancelar" icon="pi pi-times" onClick={() => this.setState({displayConfirmation: false})}
                            className="p-button-text" />
                </div>
            );
        }

        //Código filter
        const codeFilterElement = this.tableFilters.renderCodigoFilter(this.state.selectedCodes, this.productService.getCodeList,
            this.props.list, "selectedCodes", this.onFilterChange, this.state.codigoField)

        //Descrição Filter
        const descriptionFilterElement = this.tableFilters.renderDescriptionFilter(this.state.selectedDescriptions,
            this.productService.getDescriptionList, this.props.list, "selectedDescriptions", this.onFilterChange,
            this.state.descricaoField, '300px')

        const nomeFormatado = (nome) => {
            return (
                <div>
                    - {nome}
                    <br />
                </div>
            )
        }

        const formataNomesProdutosCompra = () => {
            // console.log(this.props.selectedBuyProducts)
            let nomesFormatadosList = []
            this.props.selectedBuyProducts.forEach(selectedBuyProduct => {
                nomesFormatadosList.push(nomeFormatado(selectedBuyProduct.descricao))
            })
            return nomesFormatadosList
        }            

        return (
            <>
            <div className="datatable-crud-demo">

            <div className="card">
                <Toolbar className="p-mb-4" left={leftToolbarTemplate} ></Toolbar>

                <DataTable
                    ref={this.dt}
                    value={this.props.list} 
                    className="p-datatable-sm small-font"
                    rowHover
                    selection={this.state.selectedProducts}
                    onSelectionChange={this.handleSelectionChange}
                    scrollable={!this.props.insideDialog}
                    scrollHeight="500px"
                    loading={this.props.loading}
                    dataKey="id"
                    paginator rows={ConstantsUtil.initialTableQuantityOption}
                    // rowsPerPageOptions={[5, 10, 25]}
                    paginatorTemplate={GeneralServices.tablePaginatorTemplate(ConstantsUtil.initialTableQuantityOption, this.props.list ? this.props.list.length : '')}
                    // paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Mostrando de {first} ao {last} de {totalRecords} produtos"                        
                >

                    <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}/>
                    
                    <Column field={this.state.codigoField} header="Código" sortable style ={ {width: '140px'} }
                            filter filterElement={codeFilterElement}
                    />
                    <Column field={this.state.descricaoField} header="Descrição" sortable style ={ {width: '140px'} }
                            filter filterElement={descriptionFilterElement}
                    />
                    <Column field="ncm" header="NCM" body={rowData => GeneralServices.adjustNCM(rowData.ncm)} sortable style ={ {width: '140px'} }/>
                    <Column field="tipo" header="Tipo" sortable style ={ {width: '140px'} }/>
                    <Column field="unidadeComercializada" header="Unidade" sortable style ={ {width: '140px'} }/>
                </DataTable>
            </div>
        </div>

        <Dialog
            header="Parametrizar produto"
            visible={this.state.displayConfirmation}
            modal = {true} //congela restante da tela
            style={{ width: '90vw' }}
            footer={renderConfirmationFooter()}
            onHide={() => this.setState({displayConfirmation: false})}>
            {/* <div className="confirmation-content row" style={{marginLeft: '10px'}}>
                <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem', marginRight: '10px'}} />
                <div style={{marginBottom: '10px'}}> Deseja confirmar parametrização? </div>
            </div> */}

            {/* <div className="p-field"> */}
            <div >
            <div className="row">
                <div className="col-md-12">
                    A unidade de cada um do(s) produto(s) de compra:
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <strong>{formataNomesProdutosCompra()}</strong>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    equivale a quantas unidades do produto de venda <strong>{`${this.nomeProdutoEscolhido()}`}</strong>?
                </div>
            </div>
            <br /> 
            <div className="row">
                <div className="col-md-2">
                    <label htmlFor="proporcao">Conversão</label>
                    <InputNumber
                        // ref={this.inputValorRef}
                        className={this.state.inputProportionErrorClass}
                        name="proportion"
                        inputId="proportion"
                        autoFocus
                        value={this.state.proportion}
                        onValueChange={this.handleProportionChange}
                        onKeyDown={this.handleKeypress}
                        // mode="currency" currency="BRL" locale="pt-BR"
                        mode="decimal" locale="pt-BR" minFractionDigits={2}
                    />
                    {/* <div className="invalid-feedback">{this.state.errorProportionMessage}</div> */}
                </div>
                </div>
                <div className="row">
                <div className="col-md-12">
                <small id="valorError" className="p-error">{this.state.errorProportionMessage}</small>
                </div>
                </div>
                </div>
        </Dialog>
        </>
        )
    }


}  

export default ParametrizeProductTable