import React from 'react'
import Card from '../../components/card'
import { withRouter } from 'react-router-dom'

import { AuthContext } from '../../main/authProvider'
import FileUploadCard from '../../components/fileUploadCard'
import FileService from '../../app/service/fileService'

class ResgisterProduct extends React.Component {

    constructor(){
        super();
        this.fileService = new FileService();
    }

    state = {
        uploadInProgress: false,
    }

    setIfIsInProgress = (inProgress) => {
        this.setState({uploadInProgress: inProgress})
    }

    render() {
            return (
                <div className="bs-docs-section" style={{minHeight:'500px'}} >
                <div className = "col-md-12">
                <Card title = "Importação de Arquivos">
                        <FileUploadCard 
                            push = {this.props.history.push}
                            uploadInProgress = {this.setIfIsInProgress}
                            multiple={true}
                            accept=".zip, .xml"
                            uploadDescription="NOTA_FISCAL" //tem que ser escrito assim para equivaler com o enum do backend
                        />
                </Card>
                <div className = "d-flex" />
                </div>
            </div>
            )
        }

}

ResgisterProduct.contextType = AuthContext

export default withRouter(ResgisterProduct)