import React from "react";
import { MultiSelect } from "primereact/multiselect";
import TiposDeRecebimentoService from "../../app/service/tiposDeRecebimentoService";
import FormGroup from "../form-group";

class DiasDoMesMultiSelect extends React.Component {

    constructor(){
        super()
        this.tiposDeRecebimentoService = new TiposDeRecebimentoService()
    }

    render() {
        return (
            <div>
                <FormGroup label = "Dia(s) do Mês " htmlFor = {this.props.htmlFor}>
                <div className="p-field">
                    <MultiSelect
                        className={this.props.inputDiasDoMesClass}
                        display="chip"
                        showClear
                        value={this.tiposDeRecebimentoService.sortSelectedDiasDoMesList(this.props.diasDoMes)} 
                        options={this.tiposDeRecebimentoService.diasDoMesList()}
                        name={this.props.name}
                        onChange={this.props.handleChange}
                        optionLabel="label"
                        placeholder="Selecione os dias de corte"
                        disabled={this.props.disabled}
                    />
                </div>
                </FormGroup>
                <small id="inputDiaDoMes-help" className="p-error p-d-block">{this.props.errorDiasDoMesMessage}</small>
            </div>
        )
    }

}

export default DiasDoMesMultiSelect