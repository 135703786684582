import React from 'react'

import { AuthContext } from '../main/authProvider'

import { Toolbar } from 'primereact/toolbar'
import { Button } from 'primereact/button'
import SystemDataService from '../app/service/systemDataService'
import HandleErrorService from '../app/service/handleErrorService'
import CentralSystemDataService from '../app/service/central/centralSystemDataService'
import EmpresasTableSearchDialog from './empresas/EmpresasTableSearchDialog'
import GeneralServices from '../app/service/generalServices'
import AuthService from '../app/service/authService'

class BottomBar extends React.Component {
    
    constructor(){
        super()
        this.systemDataService = new SystemDataService()
        this.centralSystemDataService = new CentralSystemDataService()
        this.generalServices = new GeneralServices()

        this.timerRef = null; // Referência para o temporizador

    }

    state = {
        nomeEmpresa: '',
        backendVersion: '',
        displayEmpresasDialog: false,
        
        ctrlPressed: false,
        existeOutraTeclaPressionada: false,
    }

    componentDidMount(){
        
        /*
        Adicionando event listeners para os eventos de pressionar e liberar a tecla Ctrl.
        Ao pressionar Ctrl por 0,5s, abre a tela de escolha de empresa, que é a mesma função de clicar no botão com o nome
        da empresa logada.
        */
        // document.addEventListener('keydown', this.handleKeyDown);
        // document.addEventListener('keyup', this.handleKeyUp);

        if(this.props.loggedInfo){
            this.getLoggedSystemData()
        } else{
            this.getSystemData()
        }
    }

    componentWillUnmount() {
        // Removendo event listeners quando o componente é desmontado
        document.removeEventListener('keydown', this.handleKeyDown);
        document.removeEventListener('keyup', this.handleKeyUp);
    }    

    // Função para lidar com o evento de pressionar uma tecla
    handleKeyDown = (event) => {
        // Verifique se a tecla Ctrl está pressionada
        if (event.ctrlKey && !this.state.existeOutraTeclaPressionada && this.context.isAuth) {

            if(event.key !== 'Control') {
                this.cancelaContagem()
                return;
            }
            
            if(this.state.ctrlPressed){
                return;
            }

            // console.log("Começa a marcar!")
            // Marque que a tecla Ctrl está sendo pressionada
            this.setState({ ctrlPressed: true });

            // Configurar um temporizador para verificar se a tecla Ctrl permanece pressionada por 2 segundos
            this.timerRef = setTimeout(() => {
                // Se a tecla Ctrl ainda estiver pressionada após 2 segundos, faça algo
                if (this.state.ctrlPressed) {
                    this.openDialog()
                }
            }, 1000);
        }
        else {
            
            if(!this.state.existeOutraTeclaPressionada){
                this.setState({existeOutraTeclaPressionada: true})
            }

            if(this.state.ctrlPressed){
                this.cancelaContagem()
            }
        }
    }

    // Função para lidar com o evento de liberar uma tecla
    handleKeyUp = (event) => {
        // Verifique se a tecla Ctrl foi liberada
        if (event.key === 'Control') {
            // Se a tecla Ctrl foi liberada, redefina o status e limpe o temporizador
            this.cancelaContagem()
        }
        else {
            if(this.state.existeOutraTeclaPressionada){
                this.setState({existeOutraTeclaPressionada: false})
            }
        }
    }

    cancelaContagem = () => {
        // console.log("Cancela Tempo!")
        this.setState({ ctrlPressed: false });
        clearTimeout(this.timerRef);
    }

    setStates = (response) => {
        this.setState({nomeEmpresa: response.data.nomeEmpresa})
        this.setState({backendVersion: response.data.versao})
    }

    getSystemData(){
        // this.systemDataService.getSystemData()
        this.centralSystemDataService.getSystemData()
        .then(response => {
            this.setStates(response)  
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
    }

    getLoggedSystemData(){
        this.generalServices.getLoggedSystemData((response)=> this.setStates(response))
    }

    openDialog = () => {
        if(AuthService.isAuth()){
            if(!this.state.displayEmpresasDialog) {
                this.setState({displayEmpresasDialog: true})
            }
        }
    }

    hideDialog = () => {
        this.setState({displayEmpresasDialog: false})
        this.cancelaContagem()
    }

    render(){

        const leftContents = (
            <React.Fragment>
                <Button
                    label= {this.state.nomeEmpresa}
                    className="mr-2 small-button"
                    onClick={this.openDialog}
                />
            </React.Fragment>
        );

        const rightContents = (
            <React.Fragment>
                <Button
                    className="p-button-success small-button"
                    label= {"Versão: " + process.env.REACT_APP_VERSION}
                />
            </React.Fragment>
        );

        return(
            <>
            <div
                style={{
                    position: 'fixed',
                    left: '1%',
                    bottom: '1%',
                    // right: '0',

                }}
            >
                
                <Toolbar
                    left={leftContents}
                    right={rightContents}
                    style={
                        {
                            minWidth: '15rem',
                            height: '2rem'
                        }
                    }
                />
                {/* <div
                    className='centraliza-conteudo-div'
                    style={
                        {
                            backgroundColor: 'white',
                            minWidth: '5rem',
                            height: '2rem'
                        }
                }
                >
                    {leftContents}
                    {rightContents}
                </div> */}
                
            </div>

            <EmpresasTableSearchDialog
                visible={this.state.displayEmpresasDialog}
                context={this.context}
                hideDialog={this.hideDialog}
            />

            </>
        )
    }


}

BottomBar.contextType = AuthContext

export default BottomBar