import React from 'react'
import FileUploadCard from './fileUploadCard'
import DialogFooter from './dialogFooter'
import { Dialog } from 'primereact/dialog'
import Card from './card'
import { Button } from 'primereact/button'
import GeneralServices from '../app/service/generalServices'


class UploadDialog extends React.Component {

    render(){

        const dialogFooter = (
            <DialogFooter hideDialog={this.props.hideDialog} disabled/>
        )

        return (
            <>
            <Dialog
                visible={this.props.visible}
                style={{ width: '85vw'}}
                header={this.props.header}
                modal
                className="p-fluid"
                footer={dialogFooter}
                onHide={this.props.hideDialog}>
                <div className="p-field">
                <Card title = {this.props.title}>
                    <div className = "col-md-3">
                    <Button
                        label="Baixar Modelo"
                        icon="pi pi-download"
                        className="p-button-success"
                        onClick={() => GeneralServices.exportExcel(this.props.templateToExport(), "Página 1", this.props.nomeArquivoModelo)}
                        style={{maxHeight: '30px', marginLeft: '10px'}}
                    />
                    </div>
                    <br />
                    <FileUploadCard
                        // uploadInProgress = {this.setIfIsInProgress}
                        // multiple={true}
                        accept=".xlsx"
                        uploadDescription={this.props.uploadDescription}
                        extraObject={this.props.extraObject}
                        onFinish={this.props.onFinish}
                    />
                </Card>
                </div>

            </Dialog>            
            </>
        )
    }

    
}

export default UploadDialog