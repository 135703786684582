import React from 'react'

import { Dialog } from 'primereact/dialog'

import DialogFooter from '../dialogFooter'
import * as popUp from '../../components/toastr'
import HandleErrorService from '../../app/service/handleErrorService'
import ConstantsUtil from '../../context/constantsUtil'
import EmpresaService from '../../app/service/central/empresaService'
import PasswordInput from '../passwordInput'
import AuthService from '../../app/service/authService'

class SyncEmpresaNFsVendaDialog extends React.Component {

    constructor(){
        super()
        this.empresaService = new EmpresaService()
    }

    state = {
        
        idIntegracao: null,

        usuario: '',
        inputUsuarioErrorClass:'',

        senha: '',
        inputSenhaErrorClass:'',

        numeroSerialLoja: '',
        inputNumeroSerialLojaErrorClass: '',

        codigoLoja: '',
        inputCodigoLojaErrorClass: '',


    }

    componentDidMount(){
        this.buscaDadosSyncNFsVenda()
    }

    buscaDadosSyncNFsVenda = () => {
        if( (this.props.visible && !this.state.didUpdated) || this.props.forceUpdate ){
                
            this.empresaService.buscaDadosSyncNFsVenda(this.props.empresa)
            .then(response => {
                // console.log("response: ", response)
                this.setStates(response.data)
                this.setState({didUpdated: true})
                if(this.props.doneForceUpdate){
                    this.props.doneForceUpdate()
                }
            })
            .catch(error => {
                // console.log("error: ", error)
                HandleErrorService.handleError(error)
            })

        }
    }

    setStates = (dadosIntegracao) => {
        // console.log("dadosIntegracao: ", dadosIntegracao)
        if(dadosIntegracao.possuiIntegracao){
            this.setState({idIntegracao: dadosIntegracao.totvs.id})
            this.setState({usuario: dadosIntegracao.totvs.usuario})
            this.setState({senha: dadosIntegracao.totvs.senha})
            this.setState({numeroSerialLoja: dadosIntegracao.totvs.numeroSerialLoja})
            this.setState({codigoLoja: dadosIntegracao.totvs.codigoLoja})
        }
    }

    handleGrupoChange = async (event) => {
        const idGrupo = event.target.value
        const selectedGrupo = this.state.gruposList.find(grupo => grupo.id == idGrupo)
        if(selectedGrupo === undefined){
            await this.setState({ selectedGrupoId: '' })
            await this.setState({selectedGrupo: ''})
        }
        else{
            await this.setState({ selectedGrupoId: selectedGrupo.id })
            await this.setState({selectedGrupo})
        }
    }

    handleWorkerChange = async (event) => {
        const idWorker = event.target.value
        const selectedWorker = this.state.workersList.find(worker => worker.id == idWorker)
        if(selectedWorker === undefined){
            await this.setState({ selectedWorkerId: '' })
            await this.setState({selectedWorker: ''})
        }
        else{
            await this.setState({ selectedWorkerId: selectedWorker.id })
            await this.setState({selectedWorker})
        }
    }

    handleChange = async (event) => {
        const name = event.target.name
        const value = event.target.value
        await this.setState({ [name]: value })
    }


    hideDialog = () => {
        this.resetView()
        this.setState({didUpdated: false})
        this.props.hideDialog()
    }

    resetData = () => {
        this.resetView()
        this.setState({id: ''})
        this.setState({nome: ''})
        this.setState({nomeSimplificado: ''})
        this.setState({cnpj: ''})
        this.setState({nfstockLabel: ''})
        this.setState({ativoNfStock: ''})
        this.setState({selectedGrupoId: ''})
        this.setState({selectedGrupo: ''})
        this.setState({selectedWorkerId: ''})
        this.setState({selectedWorker: ''})
        this.props.resetData()
    }    

    resetView = () => {
        this.setState({inputUsuarioErrorClass: ''})
        this.setState({inputSenhaErrorClass: ''})
        this.setState({inputNumeroSerialLojaErrorClass: ''})
        this.setState({inputCodigoLojaErrorClass: ''})
    }

    checkData = () => {
        var check = true

        if(!this.state.usuario){
            this.setState({inputUsuarioErrorClass: ConstantsUtil.bootstrapInputErrorClass})
            check = false
        }

        if(!this.state.senha){
            this.setState({inputSenhaErrorClass: ConstantsUtil.bootstrapInputErrorClass})
            check = false
        }

        if(!this.state.numeroSerialLoja){
            this.setState({inputNumeroSerialLojaErrorClass: ConstantsUtil.bootstrapInputErrorClass})
            check = false
        }

        if(!this.state.codigoLoja){
            this.setState({inputCodigoLojaErrorClass: ConstantsUtil.bootstrapInputErrorClass})
            check = false
        }

        return check

    }

    callSync = () => {
        this.resetView()
        if(this.checkData()){
            
            AuthService.startBlockUi()
            let totvs = this.state
            totvs.empresa = this.props.empresa
            totvs.id = this.state.idIntegracao

            this.empresaService.criaDadosSyncNFsVenda({
                tipo: ConstantsUtil.tipoSyncNFsVendaTotvs,
                totvs
            })
            .then(response => {
                popUp.successPopUp("Alterações salvas com sucesso.")
                this.hideDialog()
                this.props.search()
            })
            .catch(error => {
                HandleErrorService.handleError(error)
            })
            .finally(() => {
                AuthService.stopBlockUi()
            })
        }
    }

    render () {

        const footer = (
            <DialogFooter save = {this.callSync} hideDialog = {this.hideDialog} disabled={this.props.disabled}/>
        )

        return (
            <>
            <Dialog
                visible={this.props.visible}
                style={{ width: '40vw' }}
                header={"Sincronizar vendas com sistema emissor de NFs"}
                modal
                className="p-fluid"
                footer={footer}
                onHide={this.hideDialog}
            >

                <div className="p-field">
                    <label htmlFor="usuario">Empresa</label>
                    <input
                        type="text"
                        className={"form-control " + this.state.inputUsuarioErrorClass}
                        // id="usuario"
                        value = {this.props.empresa.nome}
                        // name="usuario"
                        // onChange={this.handleChange}
                        disabled
                    />
                </div>

                <br />

                <div className="p-field">
                    <label htmlFor="usuario">Usuário</label>
                    <input
                            type="text"
                            className={"form-control " + this.state.inputUsuarioErrorClass}
                            id="usuario"
                            value = {this.state.usuario}
                            name="usuario"
                            onChange={this.handleChange}
                            />
                    <div className="invalid-feedback">{ConstantsUtil.preecnhaLabel}</div>
                </div>

                <br/>

                <div className="p-field">
                    <PasswordInput
                        value={this.state.senha}
                        id="senhaSyncNFsVenda"
                        name="senha"
                        handleChange={this.handleChange}
                        // handleKeypress={this.handleKeypress}
                        inputPasswordErrorClass={this.state.inputSenhaErrorClass}
                        errorPasswordMessage={ConstantsUtil.preecnhaLabel}
                    />
                    <div className="invalid-feedback">{ConstantsUtil.preecnhaLabel}</div>
                </div>

                <br />

                <div className="p-field">
                    <label>Número Serial Loja</label>
                    <input
                        className={"form-control " + this.state.inputNumeroSerialLojaErrorClass}
                        type="text"
                        name="numeroSerialLoja"
                        value={this.state.numeroSerialLoja}
                        onChange = {this.handleChange}
                        // onKeyDown={this.handleKeyUp}
                        // disabled={this.props.disabled}
                    />
                    <div className="invalid-feedback">{ConstantsUtil.preecnhaLabel}</div>
                </div>

                <br />

                <div className="p-field">
                    <label>Código da Loja</label>
                    <input
                        className={"form-control " + this.state.inputCodigoLojaErrorClass}
                        type="text"
                        name="codigoLoja"
                        value={this.state.codigoLoja}
                        onChange = {this.handleChange}
                        // onKeyDown={this.handleKeyUp}
                        // disabled={this.props.disabled}
                    />
                    <div className="invalid-feedback">{ConstantsUtil.preecnhaLabel}</div>
                </div>                


            </Dialog>
            
            </>
        )    
    }

}

export default SyncEmpresaNFsVendaDialog