import React from 'react'

import { Dialog } from 'primereact/dialog'
import SelectMenu from '../selectMenu'
import DialogFooter from '../dialogFooter'
import FormGroup from '../form-group'

import * as popUp from '../../components/toastr'
import HandleErrorService from '../../app/service/handleErrorService'
import PlanoDeContasService from '../../app/service/planoDeContasService'
import GeneralServices from '../../app/service/generalServices'

class PlanoDeContasDialog extends React.Component {
    
    constructor(){
        super();
        this.planoDeContasService = new PlanoDeContasService();
        this.generalServices = new GeneralServices();
    }

    state = {

        // id: null,

        nomeContaPai: '',
        inputNomeContaPaiErrorClass: '',
        errorNomeContaPaiMessage: '',

        numeroChamada: '',
        inputNumeroChamadaErrorClass: '',
        errorNumeroChamadaMessage: '',
        
        codigoInterno: '',
        inputCodigoInternoErrorClass: '',
        errorCodigoInternoMessage: '',

        tipoContaContabil: '',
        inputTipoErrorClass: '',
        errorTipoMessage: '',
        
        nomeConta: '',
        inputNomeContaNovaErrorClass: '',
        errorNomeContaNovaMessage: '',

        natureza: '',
        inputNaturezaErrorClass: '',
        errorNaturezaMessage: '',

        contaPaiNumeroDeChamada: '',
        
        didUpdated: false,
        visibleConfirmDialog: false
    }


    componentDidUpdate(){
        this.setStates()
    }

    setStates = () => {
        if( (this.props.visible && !this.state.didUpdated) || this.props.forceUpdate ){

            if(!this.props.disabled){
                this.setState({nomeContaPai: this.props.state.contaPai.codigo + " " + this.props.state.contaPai.nomeConta})
                this.setState({numeroChamada: this.props.state.novoNumeroDeChamada})
                this.setState({codigoInterno: this.props.state.novoCodigo})
                this.setState({tipoContaContabil: this.props.state.tipoContaContabil})
                this.setState({contaPaiNumeroDeChamada: this.props.state.contaPai.numeroDeChamada})
                this.resetView()
            }
            else{
                // this.setState({nomeContaPai: this.props.state.contaPai.codigo + " " + this.props.state.contaPai.nomeConta})
                this.setState({nomeContaPai: this.props.state.codigoContaPai + " " + this.props.state.nomeContaPai})
                this.setState({nomeConta: this.props.state.nomeConta})
                this.setState({numeroChamada: this.props.state.numeroDeChamada})
                this.setState({codigoInterno: this.props.state.codigo})
                this.setState({tipoContaContabil: this.props.state.tipoContaContabil})
                this.setState({natureza:  this.props.state.natureza})
                // this.setState({contaPaiNumeroDeChamada: this.props.state.contaPai.numeroDeChamada})
                this.resetView()
            }

            this.setState({didUpdated: true})
            if(this.props.doneForceUpdate){
                this.props.doneForceUpdate()
            }
        }
    }

    resetView = () => {
        this.setState({inputNomeContaNovaErrorClass: ''})
        this.setState({inputNaturezaErrorClass: ''})
    }

    handleChange = async (event) => {
        const value = event.target.value
        const name = event.target.name
        this.setState({ [name]: value })
    }

    hideDialog = () => {
        this.setState({nomeConta: ''})
        this.setState({natureza: ''})
        this.setState({didUpdated: false})
        this.props.hideDialog()

    } 
    
    checkData = () => {
        var check = true

        if(!this.state.nomeConta){
            this.setState({inputNomeContaNovaErrorClass: "is-invalid"})
            this.setState({errorNomeContaNovaMessage: 'Informe o nome da conta a ser criada'})
            check = false
        }
        
        if(!this.state.natureza){
            this.setState({inputNaturezaErrorClass: "is-invalid"})
            this.setState({errorNaturezaMessage: 'Informe a natureza da conta a ser criada'})
            check = false

        }

        return check
    }

    callSave = () => {
        this.resetView()
        if(this.checkData()){
            this.save()
        }
    }
    
    save = () => {
        
        this.resetView()
        this.props.resetSelectionChange()

        this.planoDeContasService.create(
        {
            numeroChamada: this.state.numeroChamada,
            codigo: this.state.codigoInterno,
            nomeConta: this.state.nomeConta,
            tipoContaContabil: this.state.tipoContaContabil,
            natureza: this.state.natureza,
            contaPaiNumeroDeChamada: this.state.contaPaiNumeroDeChamada
        }
        )
        .then(async response => {
            this.hideDialog()
            popUp.successPopUp("Conta criada com sucesso!")
            await this.generalServices.sleep(500)
            // this.setState({didUpdated: false})
            // this.props.search()
            window.location.reload()
        }).catch(error => {
            HandleErrorService.handleError(error)
        })
        this.setState({didUpdated: false})
        this.setState({visibleConfirmDialog: false})
    }
    
    render () {

        const planoDeContasDialogFooter = () => {

           return(
            <DialogFooter save = {this.callSave} hideDialog = {this.hideDialog} 
                disabled={this.props.disabled}
            />
           ) 
        }

        const renderGrupoSintetico = () => {
            // if(!this.props.disabled)
            return(
                <>
                <div className="p-field">
                    <label htmlFor="nomeContaPai">Grupo Sintético</label>
                    <input style={{color: '#000'}} type="text"
                            className={"form-control " + this.state.inputNomeContaPaiErrorClass}
                            value = {this.state.nomeContaPai}
                            name="nomeContaPai"
                            onChange={this.handleChange}
                            id="inputnomeContaPai"
                            placeholder="Digite o nome"
                            disabled
                            />
                    <div className="invalid-feedback">{this.state.errorNomeContaPaiMessage}</div>
                </div>
                <br/>
                </>
            )
        }

        return (
            <>
            {/* <input style={{color: '#000'}} type="text"
                                className="form-control "
                                value = {this.state.nomeContaSearch}
                                name="nomeContaSearch"
                                onChange={this.handleChange}
                                id="nomeContaSearch"
                                placeholder="Busca"
                        /> */}
            <Dialog visible={this.props.visible} style={{ width: '450px' }}
                    header={this.props.header}
                    modal
                    className="p-fluid"
                    footer={planoDeContasDialogFooter}
                    onHide={this.hideDialog}>
                

                {renderGrupoSintetico()}

                <div className="p-field">
                    <label htmlFor="nomeConta">Nome da Conta</label>
                    <input style={{color: '#000'}}   className={"form-control " + this.state.inputNomeContaNovaErrorClass}
                                id="nomeConta"
                                name="nomeConta"
                                value={this.state.nomeConta}
                                // style={{marginTop: '0px', marginBottom: '0px', height: '80px'}}
                                placeholder="Digite o nome da conta"
                                onChange = {this.handleChange}
                                disabled = {this.props.disabled}
                                />
                    <div className="invalid-feedback">{this.state.errorNomeContaNovaMessage}</div>
                </div>

                <br />
                
                <div className="p-field">
                    <label htmlFor="numeroChamadaEdit">Número de Chamada</label>
                    <input style={{color: '#000'}}   className={"form-control " + this.state.inputNumeroChamadaErrorClass}
                                id="numeroChamada"
                                name="numeroChamada"
                                value={this.state.numeroChamada}
                                // style={{marginTop: '0px', marginBottom: '0px', height: '80px'}}
                                onChange = {this.handleChange}
                                disabled
                                />
                    <div className="invalid-feedback">{this.state.errorNumeroChamadaMessage}</div>
                </div>

                <br/>
                <div className="p-field">
                    <label htmlFor="codigoInterno">Código Interno</label>
                    <input style={{color: '#000'}} type="text"
                            className={"form-control " + this.state.inputCodigoInternoErrorClass}
                            value = {this.state.codigoInterno}
                            name="codigoInterno"
                            onChange={this.handleChange}
                            id="inputcodigoInterno"
                            disabled
                            />
                    <div className="invalid-feedback">{this.state.errorCodigoInternoMessage}</div>
                </div>

                <br />
                <div className="p-field">
                    <label htmlFor="tipoContaContabil">Tipo</label>
                    <input style={{color: '#000'}}   className={"form-control " + this.state.inputTipoErrorClass}
                                id="tipoContaContabil"
                                name="tipoContaContabil"
                                value={this.state.tipoContaContabil}
                                // style={{marginTop: '0px', marginBottom: '0px', height: '80px'}}
                                onChange = {this.handleChange}
                                disabled
                                />
                    <div className="invalid-feedback">{this.state.errorTipoMessage}</div>
                </div>

                <br />
                
                <div className="p-field">
                    <FormGroup label = "Natureza " htmlFor = "InputNatureza">
                        <SelectMenu
                            className={"form-control " + this.state.inputNaturezaErrorClass}
                            name="natureza"
                            list={this.planoDeContasService.naturezaList} 
                            value={this.state.natureza}
                            onChange={this.handleChange}
                            disabled = {this.props.disabled}
                        /> 
                        <div className="invalid-feedback">{this.state.errorNaturezaMessage}</div>  
                    </FormGroup> 
                </div>

                <br />

            </Dialog>
            
            </>

        )
    }
}

export default PlanoDeContasDialog