import React from 'react'

import { Dialog } from 'primereact/dialog'
import ProductService from '../../app/service/productService'
import DialogFooter from '../dialogFooter'
import InputChooseFornecedor from '../contasAPagar/inputChooseFornecedor'
import ConstantsUtil from '../../context/constantsUtil'
import GeneralServices from '../../app/service/generalServices'
import BuyProductService from '../../app/service/buyProductService'
import HandleErrorService from '../../app/service/handleErrorService'
import * as popUp from '../toastr'
import InputChooseTipoProduto from './tipoProduto/inputChooseTipoProduto'

class ProductDialog extends React.Component {

    state = {
        codigo: null,
        inputCodigoErrorClass: '',
        errorCodigoMessage: '',
        
        descricao: '',
        inputDescricaoErrorClass: '',
        errorDescricaoMessage: '',
        
        quantidade: null,
        
        ncm: null,
        inputNCMErrorClass: '',
        errorNCMMessage: '',
        
        cfop: null,
        inputCFOPErrorClass: '',
        errorCFOPMessage: '',
        
        tipo: {},
        inputTipoErrorClass: '',
        errorTipoMessage: '',
        
        unidadeComercializada: '',
        inputUnidadeErrorClass: '',
        errorUnidadeMessage: '',
        
        proporcao: null,
        didUpdated: false,
        // visibleConfirmDialog: false,

        fornecedor: {},
        inputFornecedorErrorClass: '',
        visible: true,
    }

    constructor(){
        super();
        this.productService = new ProductService();
        this.buyProductService = new BuyProductService();
        
    }

    componentDidMount(){
        this.setStates()
    }

    componentDidUpdate(){
        this.setStates()
    }

    setStates = () => {
        if( (this.props.visible && !this.state.didUpdated) || this.props.forceUpdate ){
            
            if(this.props.state){
                this.setState({codigo: this.props.state.codigo})
                this.setState({descricao: this.props.state.descricao})
                this.setState({ncm: this.props.state.ncm})
                this.setState({cfop: this.props.state.cfop})
                this.setState({tipo: this.props.state.tipo})
                this.setState({unidadeComercializada: this.props.state.unidadeComercializada})
                this.setState({proporcao: this.props.state.proporcao})
            }

            if(this.props.fornecedor){
                this.setState({fornecedor: this.props.fornecedor})
            }

            this.resetView()
            
            this.setState({didUpdated: true})
            if(this.props.doneForceUpdate){
                this.props.doneForceUpdate()
            }
        }
    }

    resetView = () => {
        this.setState({inputCodigoErrorClass: ''})
        this.setState({errorCodigoMessage: ''})
        this.setState({inputDescricaoErrorClass: ''})
        this.setState({errorDescricaoMessage: ''})
        this.setState({inputNCMErrorClass: ''})
        this.setState({errorNCMMessage: ''})
        this.setState({inputCFOPErrorClass: ''})
        this.setState({errorCFOPMessage: ''})
        this.setState({inputTipoErrorClass: ''})
        this.setState({errorTipoMessage: ''})
        this.setState({inputUnidadeErrorClass: ''})
        this.setState({errorUnidadeMessage: ''})

        this.setState({inputFornecedorErrorClass: ''})
    }

    handleChange = (event) => {
        const value = event.target.value
        const name = event.target.name
        this.setState({ [name]: value })
    }

    handleTipoProdutoChange = (selectedTipoProduto) => {
        this.setState({tipo: selectedTipoProduto})
    }

    hideDialog = (clearData) => {
        this.setState({didUpdated: false})
        if(clearData){
            this.props.hideDialog()
        }
        else{
            this.setState({visible: false})
        }

    }

    checkData = () => {
        var check = true
        if(!this.props.isProdutoCompra && !this.state.codigo){
            this.setState({inputCodigoErrorClass: ConstantsUtil.bootstrapInputErrorClass})
            this.setState({errorCodigoMessage:"Código é obrigatório"})
            check = false
        }
        if(!this.state.descricao){
            this.setState({inputDescricaoErrorClass: ConstantsUtil.bootstrapInputErrorClass})
            this.setState({errorDescricaoMessage:"Nome é obrigatório"})
            check = false
        }
        if(!this.props.isProdutoCompra && !this.state.ncm && this.state.ncm!==0){
            this.setState({inputNCMErrorClass: ConstantsUtil.bootstrapInputErrorClass})
            this.setState({errorNCMMessage:"NCM é obrigatório"})
            check = false
        }
        if(!this.props.isProdutoCompra && !this.state.cfop && this.state.cfop!==0){
            this.setState({inputCFOPErrorClass: ConstantsUtil.bootstrapInputErrorClass})
            this.setState({errorCFOPMessage:"CFOP é obrigatório"})
            check = false
        }
        if(!this.state.tipo || !this.state.tipo.descricao){
            this.setState({inputTipoErrorClass: ConstantsUtil.bootstrapInputErrorClass})
            this.setState({errorTipoMessage:"Tipo é obrigatório"})
            check = false
        }
        if(!this.state.unidadeComercializada){
            this.setState({inputUnidadeErrorClass: ConstantsUtil.bootstrapInputErrorClass})
            this.setState({errorUnidadeMessage:"Unidade é obrigatória"})
            check = false
        }
        
        if(this.props.isProdutoCompra && GeneralServices.isEmptyObject(this.state.fornecedor)){
            this.setState({inputFornecedorErrorClass: ConstantsUtil.bootstrapInputErrorClass})
            check = false
        }

        return check
    }

    callSave = () => {
        this.resetView()
        if(this.checkData()){
            // this.setState({visibleConfirmDialog: true})
            this.save()
        }
    }

    // hideConfirmDialog = () => {
    //     this.setState({visibleConfirmDialog: false})
    // }

    save = () => {
        if(this.props.isProdutoCompra){
            this.saveProdutoCompra()
        }
        else{
            this.saveProdutoVenda()
        }
        this.setState({didUpdated: false})
        // this.setState({visibleConfirmDialog: false})
    }

    saveProdutoCompra = () => {

        this.hideDialog(false)

        const produtoCompra = {
            descricao: this.state.descricao,
            tipoProduto: this.state.tipo,
            unidadeComercializada: this.state.unidadeComercializada,
            cnpjFornecedor: this.state.fornecedor.cnpj,
        }
        this.buyProductService.save(produtoCompra)
        .then(response => {
            popUp.successPopUp("Produto de Compra cadastrado com Sucesso!")
            this.hideDialog(true)
            if(this.props.search){
                this.props.search()
            }
        })
        .catch(error => {
            HandleErrorService.handleError(error)
            this.setState({visible: true})
        })
    }

    saveProdutoVenda = () => {
        var {codigo, descricao, ncm, cfop, tipo, unidadeComercializada} = this.state
        this.props.saveProdutoVenda({codigo, descricao, ncm, cfop, tipoProduto: tipo, unidadeComercializada})
        this.setState({didUpdated: false})        
     }

    render () {

        const renderCodigoInput = () => {
            if(!this.props.isProdutoCompra){
                return(
                    <div className="p-field">
                    <label htmlFor="codigo">Código</label>
                    <input style={{color: '#000'}} 
                        type="number"
                        className={"form-control " + this.state.inputCodigoErrorClass}
                        value = {this.state.codigo}
                        name="codigo"
                        onChange={this.handleChange}
                        id="inputCodigo"
                        placeholder="Digite o código"
                        disabled={this.props.disabled} 
                    />
                    <div className="invalid-feedback">{this.state.errorCodigoMessage}</div>
                    <br />
                </div>
                )
            }
        }

        const renderNCMInput = () => {
            if(!this.props.isProdutoCompra){
                return(
                    <>
                    <div className="p-field">
                        <label htmlFor="ncm">NCM</label>
                        <input style={{color: '#000'}} type="number"
                                className={"form-control " + this.state.inputNCMErrorClass}
                                value = {this.state.ncm}
                                name="ncm"
                                onChange={this.handleChange}
                                id="inputNCM"
                                placeholder="Digite o NCM"
                                disabled={this.props.disabled}  
                                />
                        <div className="invalid-feedback">{this.state.errorNCMMessage}</div>
                    </div>
                    <br/>                    
                    </>
                )
           }
        }

        const renderCFOPnput = () => {
            if(!this.props.isProdutoCompra){
                return(
                    <>
                    <div className="p-field">
                        <label htmlFor="cfop">CFOP</label>
                        <input style={{color: '#000'}} type="number"
                                className={"form-control " + this.state.inputCFOPErrorClass}
                                value = {this.state.cfop}
                                name="cfop"
                                onChange={this.handleChange}
                                id="inputCFOP"
                                placeholder="Digite o CFOP"
                                disabled={this.props.disabled}  
                                />
                        <div className="invalid-feedback">{this.state.errorCFOPMessage}</div>
                    </div>
                    <br/>                    
                    </>
                )
            }
        }

        const renderFornecedorInput = () => {
            if(this.props.isProdutoCompra){
                return (
                    // <div className = "col-md-3">
                    <div >
                        <InputChooseFornecedor
                            noHTMLFormat
                            selectedFornecedorLabel={this.state.fornecedor.nome}
                            handleSelectionFornecedorChange={(fornecedor) => this.setState({fornecedor})}
                            inputFornecedorErrorClass={this.state.inputFornecedorErrorClass}
                        />

                        <br />

                    </div>
                )
            }
        }        


        const productDialogFooter = (
            <DialogFooter save = {this.callSave} hideDialog = {() => this.hideDialog(true)} disabled={this.props.disabled} />
        )

        return (
            <>
            <Dialog
                visible={this.props.visible && this.state.visible}
                style={{ width: '450px' }}
                header={this.props.header}
                modal
                className="p-fluid"
                footer={productDialogFooter}
                onHide={() => this.hideDialog(true)}
            >

                {renderCodigoInput()}

                {renderNCMInput()}

                {renderCFOPnput()}

                <div className="p-field">
                    <label htmlFor="description">Nome do Produto</label>
                    <input
                        className={"form-control " + this.state.inputDescricaoErrorClass}
                        id="description"
                        name="descricao"
                        value={this.state.descricao}
                        placeholder="Digite o nome"
                        onChange = {this.handleChange}
                        disabled={this.props.disabled} 
                    />
                    <div className="invalid-feedback">{this.state.errorDescricaoMessage}</div>
                </div>
                <br />

                {renderFornecedorInput()}                         

                <div className="p-field">
                        <InputChooseTipoProduto
                            label={`Tipo do Produto`}
                            noHTMLFormat
                            selectedTipoProdutoLabel={this.state.tipo ? this.state.tipo.descricao : ''}
                            handleSelectionChange={this.handleTipoProdutoChange}
                            disabled={this.props.disabled} 
                            inputErrorClass={this.state.inputTipoErrorClass}
                        />                        
                </div>
                
                <br/>

                <div className="p-field">
                    <label htmlFor="status">Unidade</label>
                    <input style={{color: '#000'}} type="text"
                                    className={"form-control " + this.state.inputUnidadeErrorClass}
                                    value = {this.state.unidadeComercializada}
                                    name="unidadeComercializada"
                                    onChange={this.handleChange}
                                    id="inputUnidade"
                                    placeholder="Digite a unidade"
                                    disabled={this.props.disabled} 
                                    />
                    <div className="invalid-feedback">{this.state.errorUnidadeMessage}</div>
                </div>

            </Dialog>
            
            {/* <ConfirmationDialog
                header={ this.props.register ? "Cadastrar produto" : "Editar produto"}
                confimationMessage={ this.props.register ? "Confirmar cadastro do produto?" : "Confirmar modificação no produto?"}
                visible={this.state.visibleConfirmDialog && this.props.visible}
                confirm={this.save}
                hide={this.hideConfirmDialog}
            /> */}

            </>

        )
    }
}

export default ProductDialog