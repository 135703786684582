import React from 'react'

import { Dialog } from 'primereact/dialog'
import DialogFooter from '../dialogFooter'

import FornecedoresTable from '../fornecedores/fornecedoresTable'
import { Button } from 'primereact/button'
import CreateFornecedorDialog from './createFornecedorDialog'
import GeneralServices from '../../app/service/generalServices'

class ChooseFornecedorDialog extends React.Component {

    state = {

        selectedFornecedor: '',
        fornecedorList: [],
        filteredFornecedorList: [],

        searchText: '',

        displayCadastroFornecedorDialog: false,

        didUpdated: false,

    }

    componentDidUpdate(){
        this.setStates()
    }

    setStates = () => {
        if( (this.props.visible && !this.state.didUpdated) || this.props.forceUpdate ){
        this.setState({searchText: ''})
        this.setState({fornecedorList: this.props.list})
        this.setState({filteredFornecedorList: this.props.list})

        if(this.props.doneForceUpdate){
            this.props.doneForceUpdate()
        }
            
        this.setState({didUpdated: true})
        }
    }

    handleChange = async (event) => {
        const value = event.target.value
        const name = event.target.name
        await this.setState({ [name]: value })
        this.filter()
    }

    handleKeyPress = async (e) => {
        if (e.key === "Enter") {
            const filteredList = JSON.parse(JSON.stringify(this.state.filteredFornecedorList))
            if(filteredList.length === 1){
                await this.setState({selectedFornecedor: filteredList[0]})
                this.callSave()
            }
          }
    }

    handleSelectionFornecedorChange = (value) => {
        if(value === null){ //significa que clicou em cima do Fornecedor que estava selecionado antes (double click)
            this.callSave()
        } else{
            this.setState({selectedFornecedor: value})
        }
    }

    hideDialog = () => {
        this.setState({didUpdated: false})
        this.props.hideDialog()
    }

    cadastrarFornecedor = () => {
        this.setState({displayCadastroFornecedorDialog: true})
    }

    hideCadastroFornecedorDialog = () => {
        this.setState({displayCadastroFornecedorDialog: false})
    }

    callSave = async () => {
        await this.props.handleSelectionFornecedorChange(this.state.selectedFornecedor)
        this.hideDialog()
    }

    filter  = () => {
        
        this.setState({
            filteredFornecedorList:
                JSON.parse(
                    JSON.stringify(
                        this.state.fornecedorList.filter(fornecedor => {
                            return (
                                GeneralServices.includeString(fornecedor.nome, this.state.searchText)
                                // fornecedor.nome.toLowerCase().includes(this.state.searchText.toLowerCase()) 
                                ||
                                GeneralServices.includeString(fornecedor.cnpj, this.state.searchText)
                                // fornecedor.cnpj.toLowerCase().includes(this.state.searchText.toLowerCase()) 
                            )
                        }
                        )
                    )
                )
        })
    }

    render () {

        const chooseFornecedorDialogFooter = (
            <DialogFooter save = {this.callSave} hideDialog = {this.hideDialog} disabled={this.props.disabled}/>
        )

        return (
            <>
            <Dialog 
                    visible={this.props.visible}
                    style={{ width: '70vw' }}
                    header={this.props.header}
                    modal
                    className="p-fluid"
                    footer={chooseFornecedorDialogFooter}
                    onHide={this.hideDialog}
            >

                <div className="p-field">
                    <label htmlFor="searchFornecedor">Busca</label>
                    <input style={{color: '#000'}}
                        autoFocus  
                        className={"form-control " }
                        type="text"
                        id="searchText"
                        name="searchText"
                        value={this.state.searchText}
                        placeholder="Pesquise pelo nome ou CNPJ"
                        onChange = {this.handleChange}
                        onKeyPress={this.handleKeyPress}
                    />
                </div>

                <br />

                <Button
                    label="Cadastrar"
                    icon="pi pi-plus"
                    onClick = {this.cadastrarFornecedor}
                    style={ {maxWidth: '200px' } }
                />

                <br />

                <br />

                <FornecedoresTable
                    list = {this.state.filteredFornecedorList}
                    nome={this.state.searchText}
                    cnpj={this.state.searchText}
                    search={this.search}
                    selectable
                    handleSelectionFornecedorChange={this.handleSelectionFornecedorChange}
                    push={this.props.push}
                    loading = {this.props.loading}
                />

                <CreateFornecedorDialog
                    visible={this.state.displayCadastroFornecedorDialog}
                    search={this.props.search}
                    hideDialog={this.hideCadastroFornecedorDialog}
                    handleNewFornecedorCadastrado={this.props.handleNewFornecedorCadastrado}
                />


            </Dialog>
            </>
        )
    }


}

export default ChooseFornecedorDialog