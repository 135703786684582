import React from 'react'
import TipoProdutoService from '../../../app/service/tipoProdutoService'
import ChooseTipoProdutoDialog from './chooseTipoProdutoDialog'

class InputChooseTipoProduto extends React.Component {

    constructor()
    {
        super()
        this.tipoProdutoService = new TipoProdutoService()
    }

    state = {
        displayDialog: false,
        
    }

    hideDialog = () => {
        this.setState({displayDialog: false})
        
        if(this.props.doNotForceContaBancariaDialog)
            this.props.doNotForceContaBancariaDialog()
    }

    handleChange = (value) => {
        this.props.handleSelectionChange(value)
    }

    handleKeyPress = async (e) => {
        if (e.key === "Enter") {
            this.inputClick()
          }
    }

    inputClick = () => {
        if(!this.props.disabled){
            this.setState({displayDialog: true})
        }
    }

    render(){

        const renderInput = () => {
            
            return (
                <>
                <label htmlFor="tipoProduto">{this.props.label ? this.props.label : 'Tipo de Produto'}</label>
                    <input 
                        type="text"
                        className={"form-control " + this.props.inputErrorClass}
                        readOnly
                        value = {this.props.selectedTipoProdutoLabel ? this.props.selectedTipoProdutoLabel : ''}
                        // name="selectedTipoProdutoLabel"
                        id="selectedTipoProduto"
                        placeholder="Selecione..."
                        onClick={this.inputClick}
                        onKeyPress={this.handleKeyPress}
                        disabled={this.props.disabled}
                    />
                    <div className="invalid-feedback">
                        {"Informe o Tipo de Produto"}
                    </div>
                </>
            )
        }

        const renderHTMLFormat = () => {
            if(this.props.noHTMLFormat){
                return (
                    <>
                    {renderInput()}
                    </>
                )
            }
            else {
                return (
                    <>
                    <div className="row">
                    <div className="col-md-4">
                    {renderInput()}
                    </div>
                    </div>
                    </>
                )
            }
        }

        return(
            <>
            
                {renderHTMLFormat()}
                
                <ChooseTipoProdutoDialog
                    header={this.props.label ? this.props.label : 'Tipo de Produto'}
                    visible={this.state.displayDialog || this.props.forceShowDialog}
                    list={this.props.productList ? this.props.productList : this.state.productList}
                    hideDialog={this.hideDialog}
                    handleSelectionChange={this.handleChange}
                    isBuyProducts={this.props.isBuyProducts}
                    searchProdutosCompra={this.props.searchProdutosCompra}
                    forceUpdate={this.props.forceUpdate}
                    doneForceUpadte={this.props.doneForceUpadte}
                    fornecedor={this.props.fornecedor}
                />


            </>
        )

    }


}

export default InputChooseTipoProduto