import React from 'react'
import { withRouter } from 'react-router-dom'

import Card from '../../components/card'
import { AuthContext } from '../../main/authProvider'
import GeneralServices from '../../app/service/generalServices'
import HandleErrorService from '../../app/service/handleErrorService'
import { Button } from 'primereact/button'
import { Autocomplete, TextField } from '@mui/material'
import ContaBancariaService from '../../app/service/conciliacaoBancaria/contaBancariaService'
import * as popUp from '../../components/toastr'
import ContasBancariasTable from '../../components/conciliacaoBancaria/contasBancariasTable'

class CadastrarContas extends React.Component {

    constructor(){
        super();
        this.generalServices = new GeneralServices();
        this.contaBancariaService = new ContaBancariaService();
        this.inputBanco = React.createRef();
    }
    
    state = {
        loading: false,

        contasBancariasList: [],

        nomeBanco: '',
        inputContaBancariaErrorClass: false,
        errorContaBancariaMessage: '',

        nomeBancoFinal: '',
        inputContaBancariaFinalErrorClass: false,
        errorContaBancariaFinalMessage: '',
        
        numConta: '',
        inputNumContaErrorClass: false,
        errorNumContaMessage: '',
        
        numDigitoConta: '',
        inputnumDigitoContaErrorClass: false,
        errornumDigitoContaMessage: '',

        numAgencia: '',
        inputNumAgenciaErrorClass: false,
        errorNumAgenciaMessage: '',

        descricao: '',

        isSavingNewContaBancaria: false

    }


    componentDidMount(){
        this.searchContasBancarias()
    }

    searchContasBancarias = () => {
        this.setState({loading: true})
        this.contaBancariaService.search(false, true)
        .then(response => {
            this.setState({contasBancariasList: response.data})
            this.setState({loading: false})
        }).catch(error => {
            HandleErrorService.handleError(error)
            this.setState({loading: false})
        })
    }

    filterByName = () => {
        var filteredList = JSON.parse(JSON.stringify(this.state.contasBancariasList))
        if(this.state.nomeBanco){
            filteredList = this.state.contasBancariasList.filter(contaBancaria => contaBancaria.nomeBanco === this.state.nomeBanco)
        }
        return filteredList
    }

    handleNumberChange =  (event) => {
        let value = event.target.value
        const name = event.target.name
        if(value < 0){
            value = -value
        }
        this.setState({[name]: value})
    }

    handleTextChange =  (event) => {
        let value = event.target.value
        const name = event.target.name
        this.setState({[name]: value})
    }

    handleDigitoChange = (event) => {
        const value = event.target.value
        const name = event.target.name
        if(value >= 0 &&  value < 10){
            this.setState({[name]: value})
        }
    }

    handleMuiKeyUp = (event) => {
        if(event.code === "Enter" || event.code === "NumpadEnter"){
            this.callSave()
        }
    }

    resetForm = () => {
        this.setState({nomeBanco: ''})
        this.setState({numConta: ''})
        this.setState({numDigitoConta: ''})
        this.setState({numAgencia: ''})
        this.setState({descricao: ''})
    }

    resetView = () => {
        this.setState({inputContaBancariaErrorClass: false})
        this.setState({errorContaBancariaMessage:''})

        this.setState({inputContaBancariaFinalErrorClass: false})
        this.setState({errorContaBancariaFinalMessage:''})

        this.setState({inputNumContaErrorClass: false})
        this.setState({errorNumContaMessage:''})

        this.setState({inputnumDigitoContaErrorClass: false})
        this.setState({errornumDigitoContaMessage:''})

        this.setState({inputNumAgenciaErrorClass: false})
        this.setState({errorNumAgenciaMessage:''})
    }

    checkData = () => {
        var check = true

        if(!this.state.nomeBanco){
            this.setState({inputContaBancariaErrorClass: true})
            this.setState({errorContaBancariaMessage:'É necessário escolher o Banco'})
            check = false
        }

        if(!this.state.nomeBancoFinal){
            this.setState({inputContaBancariaFinalErrorClass: true})
            this.setState({errorContaBancariaFinalMessage:'É necessário informar o nome do Banco'})
            check = false
        }

        if(!this.state.numConta){
            this.setState({inputNumContaErrorClass: true})
            this.setState({errorNumContaMessage:'Número da conta é obrigatório'})
            check=false
        }
        
        if(!this.state.numDigitoConta){
            this.setState({inputnumDigitoContaErrorClass: true})
            this.setState({errornumDigitoContaMessage:'Dígito é obrigatório'})
            check=false
        }

        if(!this.state.numAgencia){
            this.setState({inputNumAgenciaErrorClass: true})
            this.setState({errorNumAgenciaMessage:'Agência é obrigatória'})
            check=false
        }

        return check
    }

    callSave = () => {

        this.resetView()

        if(this.checkData() && !this.state.isSavingNewContaBancaria){ //para evitar que duplo clique salve 2x.
            this.setState({isSavingNewContaBancaria: true})
            const obj =
                {
                    nomeBanco : this.state.nomeBancoFinal,
                    numConta : this.state.numConta,
                    numDigitoConta : this.state.numDigitoConta,
                    numAgencia : this.state.numAgencia,
                    descricao: this.state.descricao.trim()
                }
            // console.log("obj: ", obj)
            this.contaBancariaService.save(obj)
            .then(response => {
                popUp.successPopUp("Conta cadastrada com sucesso!")
                this.setState({isSavingNewContaBancaria: false})
                this.resetForm()
                this.searchContasBancarias()
                this.inputBanco.current.focus()
            })
            .catch(error => {
                HandleErrorService.handleError(error)
                this.setState({isSavingNewContaBancaria: false})
            })
        }
    }

    handleNomeBancoChange = async (event, value) => {
        const realValue = value ? value : ''
        await this.setState({ nomeBanco: realValue })

        if(realValue !== this.contaBancariaService.outroLabel){
            this.setState({nomeBancoFinal: realValue})
        } else{
            this.setState({nomeBancoFinal: ''})
        }
    }

    render() {

        const renderInputNomeBancoFinal = () => {
            if(this.state.nomeBanco === this.contaBancariaService.outroLabel){
                return (
                    <div className = "col-md-2">
                        <TextField
                            label="Nome do Banco"
                            type="string"
                            name="nomeBancoFinal"
                            size="small"
                            value={this.state.nomeBancoFinal}
                            onChange={this.handleTextChange}
                            onKeyUp={this.handleMuiKeyUp}
                            required
                            error={this.state.inputContaBancariaFinalErrorClass}
                            helperText={this.state.errorContaBancariaFinalMessage}
                        />
                    </div>  
                )
            }
        }

        return(
            <div className="bs-docs-section" >
            <Card title = "Contas Bancárias">
                <div className = "col-md-12">
                <div className="row">
                    <div className = "col-md-5">
                    {/* <label htmlFor="nomeSelectTipoDeRecebimento">Tipo De Recebimento *</label> */}
                    <Autocomplete
                        disablePortal
                        id="combo-box-conta-bancaria"
                        value={this.state.nomeBanco}
                        options={this.contaBancariaService.getAllContasBancarias()}
                        sx={{ maxWidth: 500 }}
                        size="small"
                        onChange={this.handleNomeBancoChange}
                        onKeyUp={this.handleMuiKeyUp}
                        renderInput={(params) => 
                            <TextField
                                label="Banco"
                                required
                                error={this.state.inputContaBancariaErrorClass}
                                helperText={this.state.errorContaBancariaMessage}
                                {...params}
                                autoFocus
                                inputRef={this.inputBanco}
                            />
                        }
                    />
                    </div>

                    {renderInputNomeBancoFinal()}

                </div>

                <br />

                <div className="row">

                <div className = "col-md-2">
                <TextField
                    label="Agência"
                    type="number"
                    name="numAgencia"
                    size="small"
                    value={this.state.numAgencia}
                    onChange={this.handleNumberChange}
                    onKeyUp={this.handleMuiKeyUp}
                    required
                    error={this.state.inputNumAgenciaErrorClass}
                    helperText={this.state.errorNumAgenciaMessage}
                />
                </div>

                <div className = "col-md-2">
                <TextField
                    label="Conta"
                    type="number"
                    size="small"
                    name="numConta"
                    value={this.state.numConta}
                    onChange={this.handleNumberChange}
                    onKeyUp={this.handleMuiKeyUp}
                    required
                    error={this.state.inputNumContaErrorClass}
                    helperText={this.state.errorNumContaMessage}
                />
                </div>
                <div className = "col-md-1">
                <TextField
                    label="Dígito"
                    type="number"
                    size="small"
                    name="numDigitoConta"
                    value={this.state.numDigitoConta}
                    onChange={this.handleTextChange}
                    onKeyUp={this.handleMuiKeyUp}
                    required
                    error={this.state.inputnumDigitoContaErrorClass}
                    helperText={this.state.errornumDigitoContaMessage}
                />
                </div>
                
                </div>

                <br />

                <div className="row">

                <div className = "col-md-12">
                    <TextField
                        label="Descrição/Apelido (Este campo aparecerá nas buscas)"
                        type="text"
                        name="descricao"
                        size="small"
                        sx={{ width: 500 }}
                        value={this.state.descricao}
                        onChange={this.handleNumberChange}
                        onKeyUp={this.handleMuiKeyUp}
                    />
                </div>
                </div>

                <br />

                <Button 
                    label="Cadastrar Conta"
                    icon="pi pi-plus"
                    onClick = {this.callSave}
                    disabled = {this.state.isSavingNewContaBancaria}
                />

                </div> {/* fechamendo da div col-md-12 */}

                <br />

                <ContasBancariasTable
                    list = { this.filterByName() }
                    searchNomeConta={this.state.nomeBanco}
                    // deleteMultiple = {this.deleteMultipleTiposDeRecebimento}
                    search={this.searchContasBancarias}
                    push={this.props.history.push}
                    loading = {this.state.loading}
                    allowEdit={true}
                    // disableDeleteButton = {this.state.disableDeleteButton || this.props.disableDeleteButton}
                />

            </Card>
            <div className="d-flex "/>
        </div>              
        )
    }

}

CadastrarContas.contextType = AuthContext

export default withRouter(CadastrarContas)