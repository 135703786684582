import React from 'react'
import { Toast } from 'primereact/toast'
import { Button } from 'primereact/button'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import EditOrCreateGrupoDePagamentoDialog from './editOrCreateGrupoDePagamentoDialog'
import ConstantsUtil from '../../context/constantsUtil'
import { Dialog } from 'primereact/dialog'
import CustomHighlighter from '../customHighlighter'
import ChooseTipoDePagamentoDialog from './chooseTipoDePagamentoDialog'
import TipoDePagamentoService from '../../app/service/contasAPagar/tipoDePagamentoService'
import HandleErrorService from '../../app/service/handleErrorService'
import TooltipButton from '../tooltipButton'

class GrupoDePagamentoTable extends React.Component {

    constructor(){
        super();
        this.tipoDePagamentoService = new TipoDePagamentoService();
    }

    state = {
        gruporDePagamentoToEdit: '',
        gruporDePagamentoToDelete: '',
        selectedGrupoDePagamento: null,

        viewTiposDePagamentoDialog: false,
        tipoDePagamentoList: [],
        forceUpdateTipoDePagamentoDialog: true,

        editGrupoDePagamentoDialog: false,

        displayDeleteConfirmation: false,
    }

    viewTiposDePagamento = (gruporDePagamento) => {

        // console.log("gruporDePagamento: ", gruporDePagamento)
        
        this.tipoDePagamentoService.searchByGrupoId(gruporDePagamento.id)
        .then(async response => {
            await this.setState({tipoDePagamentoList: response.data})
            await this.setState({forceUpdateTipoDePagamentoDialog: true})
            this.setState({viewTiposDePagamentoDialog: true})
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })

    }

    editGrupoDePagamento = (gruporDePagamento) => {

        this.setState({gruporDePagamentoToEdit: JSON.parse(JSON.stringify(gruporDePagamento))})

        this.setState({editGrupoDePagamentoDialog: true})
    }

    hideDialog = () => {
        this.setState({editGrupoDePagamentoDialog: false})
    }
    
    hideTipoDePagamentoDialog = () => {
        this.setState({viewTiposDePagamentoDialog: false})
    }

    handleSelectionChange = (event) => {
        this.setState({selectedGrupoDePagamento: event.value})
        this.props.handleSelectionGrupoDePagamentoChange(event.value)
    }

    callDelete = (grupo) => {
        this.setState({gruporDePagamentoToDelete: grupo})
        this.setState({displayDeleteConfirmation: true})
    }

    cancelDelete = () => {
        this.setState({gruporDePagamentoToDelete: null})
        this.setState({displayDeleteConfirmation: false})
    }

    delete = () => {
        this.props.delete(this.state.gruporDePagamentoToDelete.id)
        this.setState({displayDeleteConfirmation: false})
    }

    render() {

        const renderEditAndDeleteAction = (grupoDePagamento) => {
            // if(grupoDePagamento.nome !== ConstantsUtil.naoIdentificadoLabel){
            if(!grupoDePagamento.fixoSistema){
                return (
                    <>
                    <TooltipButton
                        tooltip='Editar'
                        icon="pi pi-pencil"
                        className={ "p-button-rounded p-mr-2 right-button" }
                        onClick={() => this.editGrupoDePagamento(grupoDePagamento)}
                    />

                    <TooltipButton
                        tooltip='Deletar Grupo'
                        icon="pi pi-trash"
                        className={ "p-button-rounded p-mr-2 p-button-danger right-button" }
                        onClick={() => this.callDelete(grupoDePagamento)}
                    />
                    </>
                )
            }
        }

        const actionBody = (grupoDePagamento) => {
            return (
                <React.Fragment>
                    <TooltipButton
                        tooltip='Ver Tipos de Pagamento Associados'
                        icon="pi pi-eye"
                        onClick={() => this.viewTiposDePagamento(grupoDePagamento)}
                    />

                    {renderEditAndDeleteAction(grupoDePagamento)}

                </React.Fragment>
            );
        }

        const renderSelectionColumn = () => {
            if(this.props.selectable){
                return(
                    <Column selectionMode="single" headerStyle={{ width: '3rem' }}></Column>
                )
            }
        }

        const renderEditColumn = () => {
            if(!this.props.selectable){
                return(
                    <Column header="Ações" body={actionBody} style ={ {width: '140px'} } />
                )
            }
        }

        const renderDeleteConfirmationFooter = () => {
            return (
                <div>
                    <Button label="Confirmar" icon="pi pi-check"
                        // onClick={this.deleteLancamentoFechamentoBancario} 
                        onClick={this.delete} 
                        autoFocus
                    />
                    <Button label="Cancelar" icon="pi pi-times" onClick={this.cancelDelete}
                            className="p-button-text" />
                </div>
            );
        }

        return (
            <div className="datatable-crud-demo">
            <Toast ref={this.toast} />

            <div className="card">
                {/* <Toolbar className="p-mb-4" left={leftToolbarTemplate} 
                // right={rightToolbarTemplate}
                /> */}

                <DataTable ref={this.dt} value={this.props.list}
                            className="p-datatable-sm small-font"
                            rowHover
                            emptyMessage="Não há Grupos de Pagamento cadastrados."
                            selection={this.state.selectedGrupoDePagamento}
                            onSelectionChange={this.handleSelectionChange}
                            // scrollable
                            // scrollHeight="500px"
                            loading={this.props.loading}
                            dataKey="id"
                            // paginator rows={25} rowsPerPageOptions={[5, 10, 25]}
                            // paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            // currentPageReportTemplate="Mostrando de {first} ao {last} de {totalRecords} contas bancárias"
                    >


                    {renderSelectionColumn()}

                    <Column field="nome" header="Nome" sortable
                        body={rowData => 
                            <CustomHighlighter
                                searchWords={[this.props.searchText]}
                                textToHighlight={rowData.nome}
                            />
                        }
                    />

                    <Column field="destacadoDRE" header="Destacado no Flxuo de Caixa" sortable
                        body={rowData => rowData.destacadoDRE ? "Sim" : "Não"}
                    />

                    <Column field="ausenteDRECompetencia" header="Ignorar no DRE Por Competência" sortable
                        body={rowData => rowData.ausenteDRECompetencia ? "Sim" : "Não"}
                    />

                    <Column field="fixoSistema" header="Fixo do Sistema" sortable
                        body={rowData => rowData.fixoSistema ? "Sim" : "Não"}
                    />

                    {renderEditColumn()}

                </DataTable>
            </div>

            <EditOrCreateGrupoDePagamentoDialog 
                hideDialog={this.hideDialog}
                visible={this.state.editGrupoDePagamentoDialog}
                header="Editar Grupo De Pagamento"
                dialogType={ConstantsUtil.tipoDialogGrupoDePagamentoEditLabel}
                state={this.state.gruporDePagamentoToEdit}
                search={this.props.search}
                push={this.props.push}
            />

            <ChooseTipoDePagamentoDialog
                header="Tipo De Pagamento"
                visible={this.state.viewTiposDePagamentoDialog}
                simpleView
                disabled
                list={this.state.tipoDePagamentoList}
                isFromGrupoDePagamentoTable
                waitToDidMount={!this.state.viewTiposDePagamentoDialog}
                // search={this.props.search}
                forceUpdate={this.state.forceUpdateTipoDePagamentoDialog}
                doneForceUpdate={() => this.setState({forceUpdateTipoDePagamentoDialog: false})}
                hideDialog={this.hideTipoDePagamentoDialog}
                // handleSelectionTipoDePagamentoChange={this.handleSelectionTipoDePagamentoChange}
            />


            <Dialog
                header="Excluir Grupo de Pagamento"
                visible={this.state.displayDeleteConfirmation}
                modal = {true} //congela restante da tela
                style={{ width: '350px' }}
                footer={renderDeleteConfirmationFooter()}
                onHide={this.cancelDelete}
                >
                <div className="confirmation-content row" style={{marginLeft: '10px'}}>
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem', marginRight: '10px'}} />
                <div style={{marginBottom: '10px'}}> Deseja confirmar? </div>
                </div>
            </Dialog>


            </div>

            
        )
    }

}

export default GrupoDePagamentoTable