import React from 'react'

import { Dialog } from 'primereact/dialog'

import DialogFooter from '../dialogFooter'
import * as popUp from '../../components/toastr'
import HandleErrorService from '../../app/service/handleErrorService'
import ConstantsUtil from '../../context/constantsUtil'
import GrupoDeEmpresasService from '../../app/service/central/grupoDeEmpresasService'
import CentralService from '../../app/service/central/centralService'

class GruposDeEmpresasDialog extends React.Component {

    constructor(){
        super()
        this.grupoDeEmpresasService = new GrupoDeEmpresasService()
        this.centralService = new CentralService()
    }

    state = {
        id: null,


        nome: '',
        inputNomeErrorClass:'',
        errorNomeMessage:'Informe o nome',

    }

    componentDidUpdate(){
        this.setStates()
    }

    setStates = () => {
        if( (this.props.visible && !this.state.didUpdated) || this.props.forceUpdate ){
            this.resetView()

            if(!this.props.isCadastro){
                this.setState({id: this.props.grupoToEdit.id})
                this.setState({nome: this.props.grupoToEdit.nome})
            }

            this.resetView()
                
            this.setState({didUpdated: true})
            if(this.props.doneForceUpdate){
                this.props.doneForceUpdate()
            }
        }
    }

    handleChange = async (event) => {
        const name = event.target.name
        const value = event.target.value
        await this.setState({ [name]: value })
    }


    hideDialog = () => {
        this.setState({didUpdated: false})
        this.resetData()
        this.props.hideDialog()
    }

    onSuccessCreate = (acao) => {
        popUp.successPopUp(`Grupo ${acao} com sucesso!`)
        this.hideDialog()
        this.props.onSuccessCreate()
    }

    resetData = () => {
        this.resetView()
        this.setState({id: ''})
        this.setState({nome: ''})
        this.props.resetData()
    }    

    resetView = () => {
        this.setState({inputNomeErrorClass: ''})
    }

    checkData = () => {
        var check = true

        if(!this.state.nome){
            this.setState({inputNomeErrorClass: ConstantsUtil.bootstrapInputErrorClass})
            check = false
        }

        return check

    }

    callSave = () => {
        this.resetView()
        if(this.checkData()){
            this.createOrUpdate()
        }
    }

    createOrUpdate = () => {
        this.props.onCallCreate()
        this.grupoDeEmpresasService.createOrUpdate({
            id: this.state.id,
            nome: this.state.nome.trim(),
        }).then(response => {
            this.onSuccessCreate("cadastrado")
        }).catch(error => {
            HandleErrorService.handleError(error)
            this.props.onErrorCreate()
        })
    }

    render () {

        const footer = (
            <DialogFooter save = {this.callSave} hideDialog = {this.hideDialog} disabled={this.props.disabled}/>
        )

        return (
            <>
            <Dialog visible={this.props.visible} style={{ width: '450px' }}
                    header={this.props.isCadastro ? "Cadastrar Novo Grupo" : "Editar Grupo"}
                    modal
                    className="p-fluid"
                    footer={footer}
                    onHide={this.hideDialog}>

                <div className="p-field">
                    <label htmlFor="nome">Nome</label>
                    <input
                            type="text"
                            className={"form-control " + this.state.inputNomeErrorClass}
                            id="nome"
                            value = {this.state.nome}
                            name="nome"
                            onChange={this.handleChange}
                            // onKeyDown={this.handleKeyUp}
                            // disabled={this.props.disabled}
                            />
                    <div className="invalid-feedback">{this.state.errorNomeMessage}</div>
                </div>

            </Dialog>
            
            </>
        )    
    }

}

export default GruposDeEmpresasDialog