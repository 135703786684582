import React from 'react'
import { withRouter } from 'react-router-dom'

import { AuthContext } from '../../main/authProvider'

import Card from '../../components/card'
import TipoDePagamentoService from '../../app/service/contasAPagar/tipoDePagamentoService'
import TipoDePagamentoComponent from './tiposDePagamentoComponent'

class TipoDePagamento extends React.Component{

    constructor(){
        super();
        this.tipoDePagamentoService = new TipoDePagamentoService();
        this.inputNome = React.createRef();
    }    

    state = {

    }


    render(){

        return(
            <div className="bs-docs-section" >
            <Card title = "Cadastrar Tipos De Pagamento">
            <div className = "col-md-12">
                
            <TipoDePagamentoComponent
                push={this.props.history.push}
            />

            </div>
            </Card>
            <div className="d-flex "/>
            </div>
        )
    }

}

TipoDePagamento.contextType = AuthContext
export default withRouter( TipoDePagamento )