import WorkerApiService from "../../workerApiServices";


class FichaDeRecebimentoService extends WorkerApiService {
    constructor() {
        super('/api/fichaDeRecebimento')
        this.dataLancamentoValue = "DATA_LANCAMENTO"
        this.dataCreditoValue = "DATA_CREDITO"
    }

    getLancamentosByDateInterval(obj){
        return this.post("/searchByDateInterval", obj)
    }

    getFichaConsolidadaByDateInterval(obj, extraHeader){
        return this.post("/searchConsolidadaByDateInterval", obj, extraHeader)
    }

    getDataLancamentoValue(){
        return this.dataLancamentoValue
    }

    getDataCreditoValue(){
        return this.dataCreditoValue
    }    

    getTiposDeRecebimentoSelectList(tiposDeRecebimento){
        var selectList = [
            {label: 'Selecione...', value: ''}
        ]
        tiposDeRecebimento.forEach(tipoDeRecebimento => {
            selectList.push({
                label: tipoDeRecebimento.nomeTipoDeRecebimento, value: tipoDeRecebimento.nomeTipoDeRecebimento
            })
        });
        return selectList
    }

    getFilterTypeList(){
        return [
            {label: 'Selecione...', value: ''},
            {label:'Data de Lançamento', value: this.dataLancamentoValue},
	        {label:'Data de Crédito', value: this.dataCreditoValue},
        ]
    }

    // save(object){
    //     return this.post("/save", object)
    // }
}

export default FichaDeRecebimentoService