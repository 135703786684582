import WorkerApiService from "../../workerApiServices";

class TipoDePagamentoService extends WorkerApiService {

    constructor() {
        super('/api/tipoDePagamento')

        this.naturezaFornecedorValue = 'FORNECEDOR'
        this.naturezaTarifaValue = 'TARIFA_BANCARIA'
        this.naturezaAplicacaoFinanceiraValue = 'APLICACAO_FINANCEIRA'
        this.naturezaOutrosValue = 'OUTROS_PAGAMENTOS'
        this.naturezaAutomaticoValue = 'AUTOMATICO'

        this.avulsoLabel = 'AVULSO'
        
        this.naturezaTipoDePagamentoList =
        [
                {label: 'Selecione...', value: ''},
                {label: 'Fornecedor', value: this.naturezaFornecedorValue},
                {label: 'Tarifa Bancária', value: this.naturezaTarifaValue},
                // {label: 'Aplicação Financeira', value: this.naturezaAplicacaoFinanceiraValue},
                {label: 'Outros Pagamentos', value: this.naturezaOutrosValue},
        ]

        // this.naturezaTipoDePagamentoCompleteList = this.naturezaTipoDePagamentoList.push({label: 'Aplicação Financeira', value: this.naturezaAplicacaoFinanceiraValue})
        this.naturezaTipoDePagamentoCompleteList = 
        [
            {label: 'Selecione...', value: ''},
            {label: 'Fornecedor', value: this.naturezaFornecedorValue},
            {label: 'Tarifa Bancária', value: this.naturezaTarifaValue},
            {label: 'Aplicação Financeira', value: this.naturezaAplicacaoFinanceiraValue},
            {label: 'Outros Pagamentos', value: this.naturezaOutrosValue},
            {label: 'Automático', value: this.naturezaAutomaticoValue},
     ]
    }




    search() {
        return this.get("/search")
    }

    searchByContaBancariaId(idContaBancaria, excluiInativos) {
        let params = `?`
        params = `${params}&idContaBancaria=${idContaBancaria}`
        if(excluiInativos){
            params = `${params}&excluiInativos=${excluiInativos}`
        }
        return this.get(`/searchByContaBancariaId${params}`)
    }

    searchForProvisoesAPagar() {
        return this.get(`/searchForProvisoesAPagar`)
    }

    searchForFichaDePagamento() {
        return this.get(`/searchForFichaDePagamento`)
    }

    searchByGrupoId(idGrupo) {
        let params = `?`
        params = `${params}&idGrupo=${idGrupo}`
        return this.get(`/searchByGrupoId${params}`)
    }

    searchByGrupoNome(obj) {
        return this.post(`/searchByGrupoNome`, obj)
    }

    save(object){
        return this.post("/save", object)
    }

    update(object){
        return this.put("/update", object)
    }

    deleteTipoDePagamento(object){
        return this.post("/delete", object)
    }

    reativarTipoDePagamento(object){
        return this.post("/reativar", object)
    }

}

export default TipoDePagamentoService