import WorkerApiService from "../../workerApiServices";

class SaldoContaContabilService extends WorkerApiService {
 
    constructor() {
        super('/api/saldoContaContabil')
    }

    reprocess(dataLancamento){
        let params = `?`
        params = `${params}&dataInicial=${dataLancamento}`
        return this.post(`/reprocess${params}`)
    }
}

export default SaldoContaContabilService