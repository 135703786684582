import ConstantsUtil from "../../../context/constantsUtil";
import WorkerApiService from "../../workerApiServices";

class LancarFechamentoBancarioService extends WorkerApiService {
    
    constructor() {
        super('/api/lancamentoFechamentoBancario')

        this.tipoDeLancamentoList = [
    
            {label: 'Selecione...', value: ''},
            {label: 'Recebimento', value: ConstantsUtil.lancamentoBancarioRecebimentoLabel},
            {label: 'Pagamento', value: ConstantsUtil.lancamentoBancarioPagamentoLabel},
            {label: 'Transferência - Saída', value: ConstantsUtil.lancamentoBancarioTransferenciaSaidaLabel},
            {label: 'Transferência - Entrada', value: ConstantsUtil.lancamentoBancarioTransferenciaEntradaLabel},
        ]

        this.simpleTipoDeLancamentoList = [
    
            {label: 'Selecione...', value: ''},
            {label: 'Recebimento', value: 'RECEBIMENTO'},
            {label: 'Pagamento', value: 'PAGAMENTO'},
        ]

    }

    save(object){
        return this.post("/save", object)
    }

    saveListFromOFX(object){
        return this.post("/saveListFromOFX", object)
    }

    implantacaoSaldo(object){
        return this.post("/implantacaoSaldo", object)
    }

    delete(object){
        return this.post("/delete", object)
    }
    
    update(object){
        return this.put("/update", object)
    }

}

export default LancarFechamentoBancarioService