import React from 'react'
import { Toast } from 'primereact/toast'
import { Button } from 'primereact/button'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'

import UserService from '../app/service/userService'
import EmailService from '../app/service/emailService'
import HandleErrorService from '../app/service/handleErrorService'
import * as popUp from './toastr'
import ConstantsUtil from '../context/constantsUtil'
import CustomHighlighter from './customHighlighter'
import GeneralServices from '../app/service/generalServices'
import UserDialog from './userDialog'

class UsersTable extends React.Component {

    constructor(){
        super()
        this.userService = new UserService();
        this.emailService = new EmailService();
    }

    state = {
        list: [],

        userToEdit: '',

        displayUserDialog: false,
        forceUpdateUserDialog: false,
    }

    componentDidMount(){
        this.searchUsers()
    }

    searchUsers = () => {
        this.setState({loading: true})
        this.userService.getAllUsers()
        .then(response => {
            // console.log("response data", response.data)
            this.setState({list: response.data})
            this.setState({loading: false})
        })
        .catch(error => {
            HandleErrorService.handleError(error)
            this.setState({loading: false})
        })
    }

    editUser = (user) => {
        this.setState({userToEdit: JSON.parse(JSON.stringify(user))})

        this.setState({displayUserDialog: true})
        this.setState({forceUpdateUserDialog: true})
    }

    hideDialog = () => {
        this.setState({displayUserDialog: false})
    }

    recoverPassword = (user) => {
        this.setState({loading: true})
        const obj = {
            email: user.email,
            url: window.location.href.replace(this.props.pathname, ConstantsUtil.recoverPasswordPath)
        }
        this.emailService.recoverPassword(obj)
        .then(response => {
            this.setState({loading: false})
            popUp.successPopUp(ConstantsUtil.mensagemEnvioEmail)
        }).catch(error => {
            this.setState({loading: false})
            HandleErrorService.handleError(error)
        })
    }

    changeUserStatus = (user, activate) => {
        this.setState({loading: true})
        this.userService.changeUserStatus(user)
        .then(response => {
            this.setState({loading: false})
            this.searchUsers()
            const action = activate ? 'ativado' : 'inativado'
            popUp.successPopUp(`Usuário ${action} com sucesso!`)
        }).catch(error => {
            this.setState({loading: false})
            HandleErrorService.handleError(error)
        })
    }

    deleteUser = (user) => {
        this.setState({loading: true})
        this.userService.delete(user)
        .then(response => {
            this.setState({loading: false})
            this.searchUsers()
            popUp.successPopUp(`Usuário deletado com sucesso!`)
        }).catch(error => {
            this.setState({loading: false})
            HandleErrorService.handleError(error)
        })
    }

    filter = () => {
        var filteredList = []
        this.state.list.forEach(element => {
            if(
                GeneralServices.includeString(element.name, this.props.searchText)
                ||
                GeneralServices.includeString(element.email, this.props.searchText)
            )
            filteredList.push(element)
        })
        return filteredList
    }

    render() {

        const renderActivateUserButton = (user) => {
            if(user.status){
                return (
                    <>
                    <Button
                        tooltip={"Inativar Usuário." + "\n" + "O usuário não terá mais permissão para acessar o sistema.\n No entanto, poderá ser ativado futuramente"}
                        // tooltip="Inativar Usuário"
                        tooltipOptions={{position: 'top'}}
                        icon="pi pi-times"
                        className={ "p-button-rounded p-mr-2 p-button-danger" }
                        style ={{marginLeft: '10px', maxHeight: '30px', maxWidth: '30px'}}
                        onClick={() => this.changeUserStatus(user, false)}
                    />
                    </>
                )
            }
            return (
                <>
                    <Button
                        tooltip={"Ativar Usuário.\n O usuário terá permissão para acessar o sistema.\n Novos usuários cadastrados serão automaticamente ativados quando confirmarem seu cadastro, definindo a senha de acesso."}
                        // tooltip="Inativar Usuário"
                        tooltipOptions={{position: 'top'}}
                        icon="pi pi-check"
                        className={ "p-button-rounded p-mr-2 p-button-success" }
                        style ={{marginLeft: '10px', maxHeight: '30px', maxWidth: '30px'}}
                        onClick={() => this.changeUserStatus(user, true)}
                    />                
                </>
            )
        }

        const actionBody = (user) => {
            if(user.id === ConstantsUtil.systemUserId){
                return
            }
            return (
                <React.Fragment>
                    <Button 
                        tooltip="Editar dados do usuário."
                        tooltipOptions={{position: 'top'}}
                        icon="pi pi-pencil"
                        className={ "p-button-rounded p-mr-2 " }
                        onClick={() => this.editUser(user)}
                        style={ {maxHeight: '30px', maxWidth: '30px'} }
                    />

                    <Button 
                        tooltip={"Recuperar Senha." + "\n" + "Um email será enviado para que o usuário redefina a senha."}
                        tooltipOptions={{position: 'top'}}
                        icon="pi pi-refresh"
                        className={ "p-button-rounded p-mr-2 " }
                        style={ {marginLeft: '10px', maxHeight: '30px', maxWidth: '30px'} }
                        onClick={() => this.recoverPassword(user)}
                    />

                    {renderActivateUserButton(user)}

                    <Button
                        tooltip={"Deletar Usuário." + "\n" + "O usuário será excluído da base de dados."}
                        // tooltip="Inativar Usuário"
                        tooltipOptions={{position: 'top'}}
                        icon="pi pi-trash"
                        className={ "p-button-rounded p-mr-2 p-button-danger" }
                        style ={{marginLeft: '10px', maxHeight: '30px', maxWidth: '30px'}}
                        onClick={() => this.deleteUser(user)}
                    />
 
                </React.Fragment>
            );
        }

        const definedPassword = (user) => {
            if(user.definedPassword){
                return(
                    <>
                    Sim
                    </>
                )
            }
            return (
                <>
                Não
                </>
            )
        }

        return (
            <div className="datatable-crud-demo">
            <Toast ref={this.toast} />

            <div className="card">
                {/* <Toolbar className="p-mb-4" left={leftToolbarTemplate} 
                // right={rightToolbarTemplate}
                /> */}

                <DataTable ref={this.dt} value={this.filter()}
                    className="p-datatable-sm small-font"
                    rowHover
                    emptyMessage="Não há usuários cadastrados."
                    // selection={this.state.selectedContasBancaria}
                    // onSelectionChange={this.handleSelectionChange}
                    // scrollable
                    // scrollHeight="500px"
                    loading={this.state.loading || this.props.loading}
                    dataKey="id"
                    // paginator rows={25} rowsPerPageOptions={[5, 10, 25]}
                    // paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    // currentPageReportTemplate="Mostrando de {first} ao {last} de {totalRecords} contas bancárias"
                >

                    {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column> */}

                    <Column field="name" header="Nome"
                        style ={ {width: '200px'} }
                        body={rowData => 
                            <CustomHighlighter
                                searchWords={[this.props.searchText]}
                                textToHighlight={rowData.name}
                            />
                        }                      
                    />

                    <Column field="email" header="Email"
                        style ={ {width: '200px'} }
                        body={rowData => 
                            <CustomHighlighter
                                searchWords={[this.props.searchText]}
                                textToHighlight={rowData.email}
                            />
                        }                     
                    />

                    <Column field="perfil" header="Perfil" sortable
                        style ={ {width: '200px'} }
                    />

                    <Column field="recebeNotificacao" header="Notificações" sortable
                        style ={ {width: '200px'} }
                        body={rowData => {
                            return GeneralServices.renderStyledColorTableColumn(rowData.recebeNotificacao ? 'Sim' : 'Não', rowData.recebeNotificacao)
                        }}
                    />

                    <Column field="status" header="Status" sortable
                        style ={ {width: '200px'} }
                        body={rowData => {
                            return GeneralServices.renderStyledColorTableColumn(rowData.status ? 'ATIVO' : 'INATIVO', rowData.status)
                        }}
                    />

                    <Column field="definedPassword" header="Senha Definida"
                        style ={ {width: '200px'} }
                        body={definedPassword} 
                    />

                    <Column header="Ações" body={actionBody} style ={ {width: '200px'} }></Column>
                </DataTable>
            </div>
            
            <UserDialog  
                visible={this.state.displayUserDialog}
                forceUpdate={this.state.forceUpdateUserDialog}
                doneForceUpdate={() => this.setState({forceUpdateUserDialog: false})}
                hideDialog={this.hideDialog}
                userToEdit={this.state.userToEdit}
                search={this.searchUsers}
                pathname={this.props.pathname}
            />

            </div>
        )
    }

}

export default UsersTable