import React from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import GeneralServices from '../../../../app/service/generalServices'
import LancarFechamentoBancarioService from '../../../../app/service/conciliacaoBancaria/lancarFechamentoBancarioService'
import ConstantsUtil from '../../../../context/constantsUtil'
import { Button } from 'primereact/button'
import * as xlsx from 'xlsx'
import ExpansionTableButton from '../expansionTableButton'
import TableFilters from '../../../tableFilters'


class DRESaldoTable extends React.Component {

    state = {
        
        loading: false,
        expanded: false,

    }

    constructor(){
        super();
        this.lancarFechamentoBancarioService = new LancarFechamentoBancarioService();
        this.tableFilters = new TableFilters();
        this.ref = React.createRef()
        this.generalServices = new GeneralServices()

    }

    componentDidMount(){
        this.scroll()
    }    

    scroll = async () => {
        await this.generalServices.sleep(300)
        if(this.props.header === ConstantsUtil.saldoInicialLabel){
            this.ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }

    getTableHeaders = () => {
        
        const periodo = this.props.initialDate ? this.props.initialDate + " - " + this.props.finalDate : ""
        const periodoLabel = "Período: " + periodo

        return {periodo: periodoLabel}
    }

    rowClass(rowData) {
        const obj = {
            [ConstantsUtil.totaisTableLabel] : rowData.tipoDRESaldo === ConstantsUtil.tipoTotaisFichaBancariaLabel
        }

        return obj
    }

    formattedDREMensaSaldoToExportByExcel(){
        var formattedList = []

        const contaBancariaLabel = "Conta Bancária"

        this.props.list.forEach(item =>{
            var obj = {
                [contaBancariaLabel]: item.contaBancaria.nomeBanco,
                Saldo: item.saldo
            }
            formattedList.push(obj)
        })

        return formattedList
    }

    generateWorkSheet(){
        const worksheet = GeneralServices.jsonToSheet(this.formattedDREMensaSaldoToExportByExcel());
        return worksheet
    }

    exportExcel = () => {
        const periodo = this.props.initialDate + " - " + this.props.finalDate
        const worksheet = this.generateWorkSheet()
        const sheetName = periodo
        const workbook = { Sheets: { [sheetName]: worksheet }, SheetNames: [[sheetName]] };
        this.props.saveAsExcelFile(workbook, `Fluxo de Caixa Mensal ${this.props.header} - ${periodo} - VisãoRJ`);
    }

    filterList = () => {
        if(!this.state.expanded){
            let listToBeReturned = this.props.list.filter(item => item.tipoDRESaldo === ConstantsUtil.tipoTotaisFichaBancariaLabel)
            this.props.resetTotalItem(listToBeReturned)
            return listToBeReturned
        }
        return this.props.list
    }

    render (){
        
        const nomeContaBancariaFilterElement = this.tableFilters.renderTableFilter(
            this.props.dt,
            this.state.selectedNome,
            this.filterList(),
            "selectedNome",
            (name, value) => this.setState({[name]: value}),
            'nome',
            '200px',
            "Nome"
        )


        const totalFilterElement = this.tableFilters.renderTableFilter(
            this.props.dt,
            this.state.selectedTotal,
            this.filterList(),
            "selectedTotal",
            (name, value) => this.setState({[name]: value}),
            'total',
            '200px',
            "Total"
        )        
        
        const renderTable = () => {
            return (
                <DataTable
                    ref={this.props.dt}
                    className="p-datatable-sm small-font"
                    value={this.filterList()}
                    onValueChange={(currentList) => this.props.onFilterTable(currentList, this.props.dt)}
                    rowClassName={this.rowClass}
                    rowHover
                    showGridlines
                    selection={this.state.selectedContasBancaria}
                    onSelectionChange={this.handleSelectionChange}
                    scrollable
                    style={{ maxWidth: '100vw' }}
                    scrollHeight="500px"
                    loading={this.props.loading || this.state.loading}
                    dataKey="id"
                    emptyMessage="Nenhuma Conta Bancária encontrada."
                    // paginator rows={ConstantsUtil.initialTableQuantityOption}
                    // paginatorTemplate={GeneralServices.tablePaginatorTemplate(ConstantsUtil.initialTableQuantityOption, this.props.list ? this.props.list.length : '')}
                    // currentPageReportTemplate="Mostrando de {first} ao {last} de {totalRecords} Contas Bancárias"
                >
    
                <Column field="nome" header="Conta Bancária"
                    // style ={ {minWidth: '300px'} }
                    filter={this.state.expanded} filterElement={nomeContaBancariaFilterElement}
                    // body = {dreSaldoBancarioDTO => GeneralServices.contaBancariaFormattedNameInDRE(dreSaldoBancarioDTO)}
                    body = {rowData => rowData.showNome}
                />
    
                <Column field="total" header="Saldo"
                    // style ={ {width: '200px'} }
                    filter={this.state.expanded} filterElement={totalFilterElement}
                    body = {rowData => GeneralServices.showFormattedIfNotNull(rowData.showTotal, true)}
                />
    
                </DataTable>
            )
        }

        const renderExportarButton = () => {
            if(this.state.expanded){
                return (
                    <Button
                        label="Exportar"
                        // icon="pi pi-file-excel"
                        icon={<i className="pi pi-file-excel" style={{fontSize: '1em', marginRight: '0.5em'}}></i>}
                        className="p-button-success small-font"
                        onClick={this.exportExcel}
                        tooltip="Exportar em excel"
                        tooltipOptions={{position: 'top'}}
                        style={{maxHeight: '10px'}}
                    />                    
                )
            }
        }

        return (
            <>

            <div
                ref={this.ref}
                className = "card-header header-small-height"
            >
            <div className='col-md-12'>
                

                <div className='row'>
                    <ExpansionTableButton
                        expanded={this.state.expanded}
                        toggleExpansionTable={async () => {
                            await this.setState({expanded: !this.state.expanded})
                            await this.setState({selectedNome: ''})
                            await this.setState({selectedTotal: ''})
                            // await this.setState({selectedPorcentagem: ''})
                            this.props.dt.current.reset(); //reseta filtro
                        }}
                    />                    
                    <div className='col-md-4'>
                        <h5 className='small-font-size'>{this.props.header}</h5>            
                    </div>
                    <div className='col-md-4'>
                        {/* <h5 className='small-font-size'>{this.getTableHeaders().periodo}</h5> */}
                    </div>
                    <div className='col-md-2'>
                    </div>
                    {renderExportarButton()}
                </div>
                
                {/* <div className='row'>
                <div className='col-md-4'>
                    <Button label="Exportar" icon="pi pi-file-excel" className="p-button-success" onClick={this.exportExcel}
                        tooltip="Exportar em excel"
                        tooltipOptions={{position: 'top'}}
                    />
                </div>
                </div> */}
                
            </div>
            </div>

            {renderTable()}


            </>
        )
    }


}  

export default DRESaldoTable