import React from 'react'
import { withRouter } from 'react-router-dom'

import Card from '../../components/card'
import { AuthContext } from '../../main/authProvider'
import GeneralServices from '../../app/service/generalServices'
import HandleErrorService from '../../app/service/handleErrorService'
import { Button } from 'primereact/button'

import * as popUp from '../../components/toastr'
import ContaBancariaService from '../../app/service/conciliacaoBancaria/contaBancariaService'
import LancarFechamentoBancarioService from '../../app/service/conciliacaoBancaria/lancarFechamentoBancarioService'
import TiposDeRecebimentoService from '../../app/service/tiposDeRecebimentoService'
import { InputNumber } from 'primereact/inputnumber'
import InputChooseContaBancaria from '../../components/conciliacaoBancaria/inputChooseContaBancaria'
import ConstantsUtil from '../../context/constantsUtil'
import FichaBancariaService from '../../app/service/conciliacaoBancaria/fichaBancariaService'
import CustomCalendar from '../../components/calendar/customCalendar'
import TipoDePagamentoService from '../../app/service/contasAPagar/tipoDePagamentoService'
import FormGroup from '../../components/form-group'
import SelectMenu from '../../components/selectMenu'
import InputChosseAplicacaoFinanceira from '../../components/conciliacaoBancaria/inputChosseAplicacaoFinanceira'
import AplicacaoFinanceiraService from '../../app/service/conciliacaoBancaria/aplicacaoFinanceiraService'


class ImplantacaoContaBancaria extends React.Component {

    constructor(){
        super();
        this.generalServices = new GeneralServices();
        this.contaBancariaService = new ContaBancariaService();
        this.lancarFechamentoBancarioService = new LancarFechamentoBancarioService();
        this.tiposDeRecebimentoService = new TiposDeRecebimentoService();
        this.tipoDePagamentoService = new TipoDePagamentoService();
        this.fichaBancariaService = new FichaBancariaService();
        this.aplicacaoFinanceiraService = new AplicacaoFinanceiraService();

        this.dateInputRef = React.createRef()
        this.inputValorRef = React.createRef();
        this.historicoRef = React.createRef();
        this.complementoHistoricoRef = React.createRef();
    }

    state = {

        selectedTipoImplantacao: '',
        inputTipoDeImplantacaoErrorClass: '',
        errorTipoDeImplantacaoMessage: 'Escolha o Tipo de Implantação',

        dataLancamento: '',
        dataLancamentoView: '',
        inputDateErrorClass:'',
        errorDateMessage:'',

        selectedContaBancaria: '',
        selectedContaBancariaLabel: '',
        inputContaBancariaErrorClass:'',
        errorContaBancariaMessage:'Selecione uma Conta Bancária',
        forceShowContaBancariaDialog: false,

        selectedAplicacaoFinanceira: '',
        selectedAplicacaoFinanceiraLabel: '',
        inputAplicacaoFinanceiraErrorClass:'',
        errorAplicacaoFinanceiraMessage:'Selecione uma Aplicação Financeira',
        forceShowAplicacaoFinanceiraDialog: false,

        valor: null,
        inputValorErrorClass: '',
        errorValorMessage: '',

        isSavingLancamentos: false,

    }

    handleChange = async (event) => {
        const value = event.target.value
        const name = event.target.name
        await this.setState({ [name]: value })
        
        await this.generalServices.sleep(200)
        
        if(this.state.selectedTipoImplantacao === ConstantsUtil.tipoImplantacaoSaldoSelectList[1].value){
            this.setState({forceShowContaBancariaDialog: true})
        }else{
            this.setState({forceShowAplicacaoFinanceiraDialog: true})
        }
    }

    handleDateChange = async (event) => {

        const previousValue = JSON.parse(JSON.stringify(this.state.dataLancamento))

        const value = event.target.value
        const name = event.target.name
        await this.setState({ [name]: GeneralServices.convertJSDateToBrDate(value) })
        const nameView = name+"View"
        await this.setState({[nameView]: value })
        
        const change = this.state.dataLancamento !== '' && (this.state.dataLancamento !== previousValue)
        if(change){
            this.inputValorRef.current.inputRef.current.focus()
        }


    }

    handleNavigatorDateChange = (event, e) => {
        e.onChange(event.originalEvent, event.value)
    }

    handleSelectionContaBancariaChange = async (selectedContaBancaria, selectedContaBancariaLabel) => {
        
        await this.setState({selectedContaBancaria})
        await this.setState({selectedContaBancariaLabel})

        this.dateInputRef.current.inputRef.current.focus()

    }

    handleSelectionAplicacaoFinanceiraChange = async (selectedAplicacaoFinanceira, selectedAplicacaoFinanceiraLabel) => {
        
        await this.setState({selectedAplicacaoFinanceira})
        await this.setState({selectedAplicacaoFinanceiraLabel})

        this.dateInputRef.current.inputRef.current.focus()

    }

    handleValorChange = (event) => {
        let value = event.value
        // if(value === 0){
        //     value = null
        // }
        const name = event.target.name
        this.setState({ [name]: value })
    }

    handleKeyPress = async e => {
        await this.generalServices.sleep(1) //essa função é chamada no mesmo instante que a handleValorChange.
                                            //Precisa esperar um tempo de forma que o valor seja alterado.
        //it triggers by pressing the enter key
        if (e.key === "Enter" && !this.state.isSavingLancamentos) {
            this.callSave();
        }
    }

    callSave = () => {
        this.resetView()
        if(this.checkData()){
            if(this.state.selectedTipoImplantacao === ConstantsUtil.tipoImplantacaoSaldoSelectList[1].value){
                this.saveImplantacaoContaBancaria()
            }
            else{
                this.saveImplantacaoAplicacaoFinanceira()
            }
        }
    }

    saveImplantacaoAplicacaoFinanceira = () => {
        const obj = {
            data: this.state.dataLancamento,
            aplicacaoFinanceira: this.state.selectedAplicacaoFinanceira,
            saldo: this.state.valor
        }
        this.setState({isSavingLancamentos: true})
        this.aplicacaoFinanceiraService.implantacaoSaldo(obj)
        .then(response => {
            this.resetInputs()
            popUp.successPopUp("Saldo definido com sucesso!")
            this.setState({isSavingLancamentos: false})
        })
        .catch(error => {
            HandleErrorService.handleError(error)
            this.setState({isSavingLancamentos: false})
        })
    }

    saveImplantacaoContaBancaria = () => {

        const obj = {
            data: this.state.dataLancamento,
            contaBancaria: this.state.selectedContaBancaria,
            saldo: this.state.valor
        }
        this.setState({isSavingLancamentos: true})
        this.lancarFechamentoBancarioService.implantacaoSaldo(obj)
        .then(response => {
            this.resetInputs()
            popUp.successPopUp("Saldo definido com sucesso!")
            this.setState({isSavingLancamentos: false})
        })
        .catch(error => {
            HandleErrorService.handleError(error)
            this.setState({isSavingLancamentos: false})
        })
    }

    resetInputs = () => {
        this.setState({dataLancamento: ''})
        this.setState({dataLancamentoView: ''})

        this.setState({selectedContaBancaria: ''})
        this.setState({selectedContaBancariaLabel: ''})
        
        this.setState({valor: null})
    }

    resetView = () => {

        this.setState({inputTipoDeImplantacaoErrorClass: ''})

        this.setState({inputDateErrorClass: ""})
        this.setState({errorDateMessage: ""})

        this.setState({inputContaBancariaErrorClass: ""})

        this.setState({inputAplicacaoFinanceiraErrorClass: ""})


        this.setState({inputTipoDeLancamentoErrorClass: ""})
        this.setState({inputTipoDeRecebimentoErrorClass: ""})

        this.setState({inputValorErrorClass: ""})
        this.setState({errorValorMessage: ""})

    }

    checkData = () => {

        var check = true

        if(!this.state.dataLancamento){
            this.setState({inputDateErrorClass: ConstantsUtil.primereactInputErrorClass})
            this.setState({errorDateMessage: "Informe a data"})
            check = false
        }

        if(!this.state.selectedTipoImplantacao){
            check = false
            this.setState({inputTipoDeImplantacaoErrorClass: ConstantsUtil.bootstrapInputErrorClass})

        }
        else{

            if(this.state.selectedTipoImplantacao === ConstantsUtil.tipoImplantacaoSaldoSelectList[1].value && !this.state.selectedContaBancaria){
                this.setState({inputContaBancariaErrorClass: ConstantsUtil.bootstrapInputErrorClass})
                check = false
            }

            if(this.state.selectedTipoImplantacao === ConstantsUtil.tipoImplantacaoSaldoSelectList[2].value && !this.state.selectedAplicacaoFinanceira){
                this.setState({inputAplicacaoFinanceiraErrorClass: ConstantsUtil.bootstrapInputErrorClass})
                check = false
            }
    
        }
        
        if(this.state.valor === null || this.state.valor === undefined || this.state.valor === ''){
            this.setState({inputValorErrorClass: ConstantsUtil.primereactInputErrorClass})
            this.setState({errorValorMessage: "Informe o valor do saldo inicial"})
            check = false
        }

        return check
    }

    doNotForceContaBancariaDialog = () => {
        this.setState({forceShowContaBancariaDialog: false})
    }

    doNotForceAplicacaoFinanceiraDialog = () => {
        this.setState({forceShowAplicacaoFinanceiraDialog: false})
    }

    render() {

        const renderInputChoose = () => {
            if(this.state.selectedTipoImplantacao === ConstantsUtil.tipoImplantacaoSaldoSelectList[1].value){
                return(
                    <div className="col-md-4">
                    <InputChooseContaBancaria
                        label="Conta Bancária"
                        readOnly
                        excludeAplicacaoFinanceira
                        // autofocus
                        forceShowDialog={this.state.forceShowContaBancariaDialog}
                        doNotForceContaBancariaDialog={this.doNotForceContaBancariaDialog}
                        selectedContaBancariaLabel={this.state.selectedContaBancariaLabel}
                        handleSelectionContaBancariaChange={this.handleSelectionContaBancariaChange}
                        inputContaBancariaErrorClass={this.state.inputContaBancariaErrorClass}
                        errorContaBancariaMessage={this.state.errorContaBancariaMessage}
                        noHTMLFormat
                    />
    
                    </div>
                )
            }
            else if(this.state.selectedTipoImplantacao === ConstantsUtil.tipoImplantacaoSaldoSelectList[2].value){
                return (

                    <div className="col-md-4">
                    <InputChosseAplicacaoFinanceira
                        readOnly
                        // autofocus
                        forceShowDialog={this.state.forceShowAplicacaoFinanceiraDialog}
                        doNotForceDialog={this.doNotForceAplicacaoFinanceiraDialog}
                        selectedAplicacaoFinanceiraLabel={this.state.selectedAplicacaoFinanceiraLabel}
                        handleSelectionChange={this.handleSelectionAplicacaoFinanceiraChange}
                        inputErrorClass={this.state.inputAplicacaoFinanceiraErrorClass}
                        errorMessage={this.state.errorAplicacaoFinanceiraMessage}
                        noHTMLFormat
                    />
                    </div>
                )

            }
        }
        
        return (
            <div className="bs-docs-section" >

            <Card title = "Implantação de Saldo">
                <div className="col-md-12">
                    
                <div className="row">
                <div className="col-md-2">
                <FormGroup label = "Tipo de Implantação " htmlFor = "InputTipoLancamento">
                    <SelectMenu
                    id="selectedTipoImplantacao"
                    className={"form-control " + this.state.inputTipoDeImplantacaoErrorClass}
                    name="selectedTipoImplantacao"
                    list={ConstantsUtil.tipoImplantacaoSaldoSelectList} 
                    value={this.state.selectedTipoImplantacao}
                    onChange={this.handleChange}
                    // disabled={this.state.disableTipoDeCorte}
                    /> 
                    <div className="invalid-feedback">{this.state.errorTipoDeImplantacaoMessage}</div>  
                </FormGroup>
                </div>  

                {renderInputChoose()}
            
                <CustomCalendar
                    ref={this.dateInputRef}
                    id="dataLancamentoFechamentoCaixa"
                    htmlFor="icon"
                    label="Data do Lançamento"
                    inputDateErrorClass={this.state.inputDateErrorClass}
                    value={this.state.dataLancamentoView}
                    name="dataLancamento"
                    onChange={this.handleDateChange}
                    errorDateMessage={this.state.errorDateMessage}
                />

                <div className="col-md-2">
                    <label htmlFor="valor">Saldo</label>
                    <InputNumber 
                        ref={this.inputValorRef}
                        className={this.state.inputValorErrorClass}
                        name="valor"
                        inputId="valorFechamentoBancario"
                        value={this.state.valor}
                        onValueChange={this.handleValorChange}
                        onKeyDown={this.handleKeyPress}
                        // mode="currency" currency="BRL" locale="pt-BR"
                        mode="decimal" locale="pt-BR" minFractionDigits={2}
                    />
                    <small id="valorError" className="p-error">{this.state.errorValorMessage}</small>
                </div>

                </div>

                <br />

                <Button 
                    label="Salvar Lançamento"
                    icon="pi pi-save"
                    onClick = {this.callSave}
                    disabled = {this.state.isSavingLancamentos}
                />

                </div>
            </Card>
            <div className="d-flex "/>

        </div>
        )

    }

}

ImplantacaoContaBancaria.contextType = AuthContext

export default withRouter(ImplantacaoContaBancaria)
