import React from 'react'

import { Dialog } from 'primereact/dialog'

import DialogFooter from '../dialogFooter'
import * as popUp from '../toastr'
import HandleErrorService from '../../app/service/handleErrorService'
import GeneralServices from '../../app/service/generalServices'
import TipoDePagamentoService from '../../app/service/contasAPagar/tipoDePagamentoService'
import InputChooseFornecedor from './inputChooseFornecedor'
import InputChooseGrupoDePagamento from './inputChooseGrupoDePagamento'
import SelectMenu from '../selectMenu'
import InputChooseContaContabil from '../planoDeContas/inputChooseContaContabil'
import InputChooseContaBancaria from '../conciliacaoBancaria/inputChooseContaBancaria'
import ConstantsUtil from '../../context/constantsUtil'
import ContaContabilView from '../planoDeContas/contaContabilView'
import ContaContabilViewInsideDialog from '../planoDeContas/contaContabilViewInsideDialog'
import CheckBoxComponent from '../relatorios/checkBoxComponent'

class EditTipoDePagamentoDialog extends React.Component {

    constructor(){
        super()
        this.tipoDePagamentoService = new TipoDePagamentoService()
        this.generalServices = new GeneralServices()
    }

    state = {
        id: null,

        nome: '',
        inputNomeErrorClass:'',
        errorNomeMessage:'Digite o nome',

        natureza: '',
        inputNaturezaErrorClass: '',
        errorNaturezaMessage: 'Informe a natureza',

        fornecedor: '',
        inputFornecedorErrorClass: '',

        selectedContaContabil: '',
        selectedContaContabilLabel: '',
        inputContaContabilErrorClass: '',

        selectedContaBancaria: '',
        selectedContaBancariaLabel: '',
        inputContaBancariaLabelErrorClass: '',

        grupoDePagamento: '',
        grupoDePagamentoLabel: '',
        inputGrupoDePagamentoErrorClass: '',

        ausenteDRECompetencia: '',

        isSaving: false,

        didUpdated: false,
        allowUpdate: true,

    }

    componentDidUpdate(){
        this.setStates()
    }

    setStates = () => {
        if( ((this.props.visible && !this.state.didUpdated) || this.props.forceUpdate) && this.state.allowUpdate ){
            
            if(this.props.state){

                this.resetView()

                // console.log("state: ", this.props.state)

                this.setState({id: this.props.state.id})
                this.setState({nome: this.props.state.nome})

                this.setState({natureza: this.props.state.natureza})

                if(this.props.state.fornecedor){ //Tipo de Pagamento AVULSO não está associado a nenhum fornecedor.
                    this.setState({fornecedor: this.props.state.fornecedor})
                }

                this.setState({selectedContaContabil: this.props.state.contaContabil})
                this.setState({selectedContaContabilLabel: this.props.state.nomeContaContabil})

                this.setState({selectedContaBancaria: {id: this.props.state.idContaBancaria}})
                this.setState({selectedContaBancariaLabel: this.props.state.nomeContaBancaria})

                this.setState({grupoDePagamento: this.props.state.grupoDePagamento})
                this.setState({grupoDePagamentoLabel: this.props.state.grupoDePagamento.nome})

                this.setState({ausenteDRECompetencia: this.props.state.ausenteDRECompetencia})
            }
                
            this.setState({didUpdated: true})

            if(this.props.doneForceUpdate){
                this.props.doneForceUpdate()
            }
        }
    }

    hideDialog = () => {
        this.setState({didUpdated: false})
        this.setState({allowUpdate: true})
        this.props.hideDialog()
    }

    handleChange = (event) => {
        const value = event.target.value
        const name = event.target.name
        this.setState({ [name]: value })
    }

    handleKeyUp = async (event) => {
        
        await this.generalServices.sleep(100)

        if(this.state.isSaving){
            return
        }

        if(event.code === "Enter" || event.code === "NumpadEnter"){
            this.callSave()
        }
    }

    handleSelectionGrupoDePagamentoChange = (value) => {
        if(value === null){ //significa que clicou em cima do Grupo De Pagamento que estava selecionado antes (double click)
            this.callSave()
        }
        else{ //vem aqui no 1° clique.
            this.setState({grupoDePagamento: value})
            this.setState({grupoDePagamentoLabel: value.nome})
        }
    }

    handleNaturezaChange = async (event) => {
        const value = event.target.value
        const name = event.target.name
        
        await this.setState({fornecedor: {}})

        await this.setState({[name]: value})

        this.setState({selectedContaContabil: ''})
        this.setState({selectedContaContabilLabel: ''})

        this.setState({selectedContaBancaria: ''})
        this.setState({selectedContaBancariaLabel: ''})
    }

    handleSelectionContaContabilChange = (selectedContaContabil, selectedContaContabilLabel) => {
        // console.log("conta contabil: ", selectedContaContabil)
        this.setState({selectedContaContabil})
        this.setState({selectedContaContabilLabel})
    }

    handleSelectionFornecedorChange = (fornecedor) => {
        this.setState({fornecedor})
    }

    handleSelectionContaBancariaDestinoChange = async (selectedContaBancaria, selectedContaBancariaLabel) => {
        this.setState({selectedContaBancaria})
        this.setState({selectedContaBancariaLabel})
    }

    resetView = () => {
        this.setState({inputNomeErrorClass: ''})
        this.setState({inputFornecedorErrorClass: ''})
        this.setState({inputContaContabilErrorClass: ''})
        this.setState({inputContaBancariaLabelErrorClass: ''})
        this.setState({inputGrupoDePagamentoErrorClass: ''})
    }

    resetData = () => {
        this.setState({nome: ''})
        this.setState({ausenteDRECompetencia: ''})
    }

    checkData = () => {
        
        var check = true

        if(!this.state.nome){
            this.setState({inputNomeErrorClass: ConstantsUtil.bootstrapInputErrorClass})
            check = false
        }

        if(!this.state.natureza){
            this.setState({inputNaturezaErrorClass: ConstantsUtil.bootstrapInputErrorClass})
            check = false
        }
        else{ //Definiu natureza
            
            if(this.state.natureza === this.tipoDePagamentoService.naturezaFornecedorValue){
                if(!this.state.fornecedor || !this.state.fornecedor.nome){
                    this.setState({inputFornecedorErrorClass: ConstantsUtil.bootstrapInputErrorClass})
                    check = false
                }
            }
                
            else if(this.state.natureza === this.tipoDePagamentoService.naturezaOutrosValue){
                if(!this.state.selectedContaContabil){
                    this.setState({inputContaContabilErrorClass: ConstantsUtil.bootstrapInputErrorClass})
                    check = false
                }    
            }

            else if(this.state.natureza === this.tipoDePagamentoService.naturezaTarifaValue){
                if(!this.state.selectedContaBancaria){
                    this.setState({inputContaBancariaLabelErrorClass: ConstantsUtil.bootstrapInputErrorClass})
                    check = false
                }
            }

        }

        if(!this.state.grupoDePagamento || !this.state.grupoDePagamento.nome){
            this.setState({inputGrupoDePagamentoErrorClass: ConstantsUtil.bootstrapInputErrorClass})
            check = false
        }

        return check
    }

    callSave = async () => {
        this.resetView()
        if(this.checkData()){

            await this.generalServices.sleep(100)
            if(this.state.isSaving){
                return
            }

            this.update()
        }
    }

    update = () => {

        this.setState({isSaving: true})
        this.setState({allowUpdate: false})

        // console.log("fornecedor: ", this.state.fornecedor)

        // console.log("conta contabil: ", this.state.selectedContaContabil)
        // console.log("conta contabilLabel: ", this.state.selectedContaContabilLabel)

        this.tipoDePagamentoService.update(
        {
            id: this.state.id,
            nome: this.state.nome.trim().toUpperCase(),
            natureza: this.state.natureza,
            idContaContabil: parseInt(this.state.selectedContaContabil.numeroDeChamada)/5,
            idContaBancaria: this.state.selectedContaBancaria ? this.state.selectedContaBancaria.id : '',
            fornecedor: this.state.fornecedor ? this.state.fornecedor : {},
            grupoDePagamento: this.state.grupoDePagamento,
            ausenteDRECompetencia: this.state.ausenteDRECompetencia
        }
        )
        .then(async response => {
            popUp.successPopUp("Tipo de Pagamento alterado com sucesso!")
            this.props.search()
            this.hideDialog()
            this.setState({didUpdated: false})

        }).catch(async error => {
            HandleErrorService.handleError(error)
        })

        .finally( async () => {
            await this.generalServices.sleep(1000)
            this.setState({isSaving: false})
        })
        this.setState({didUpdated: false})
    }

    render () {

        const editTipoRecebimentoDialogFooter = (
            <DialogFooter save = {this.callSave} hideDialog = {this.hideDialog} disabled={this.props.disabled}/>
        )

        const renderFornecedorInput = () => {
            if(this.state.natureza === this.tipoDePagamentoService.naturezaFornecedorValue){
                return (
                    <>
                        <InputChooseFornecedor 
                            noHTMLFormat
                            selectedFornecedorLabel={this.state.fornecedor.nome}
                            handleSelectionFornecedorChange={this.handleSelectionFornecedorChange}
                            inputFornecedorErrorClass={this.state.inputFornecedorErrorClass}
                        />
                        <br />
                    </>
                )
            }
        }

        const renderContaContabilInput = () => {
            if(
                (this.state.natureza === this.tipoDePagamentoService.naturezaOutrosValue)

            )
            return (
                <ContaContabilViewInsideDialog
                    contaContabil={this.state.selectedContaContabil}
                    handleSelectionContaContabilChange={this.handleSelectionContaContabilChange}
                    inputContaContabilErrorClass={this.state.inputContaContabilErrorClass}
                    push={this.props.push}
                
                />
                    
            )
        }

        const renderContaBancariaInput = () => {
            if(
                this.state.natureza === this.tipoDePagamentoService.naturezaTarifaValue
                || this.state.natureza === this.tipoDePagamentoService.naturezaAplicacaoFinanceiraValue

            )
            return (
                <>
                    <InputChooseContaBancaria
                        label="Conta Bancária"
                        noHTMLFormat
                        readOnly
                        excludeFundosFixos
                        excludeAplicacaoFinanceira
                        // autofocus
                        // forceShowDialog={this.state.forceShowContaBancariaDialog}
                        // doNotForceContaBancariaDialog={this.doNotForceContaBancariaDialog}
                        selectedContaBancariaLabel={this.state.selectedContaBancariaLabel}
                        handleSelectionContaBancariaChange={this.handleSelectionContaBancariaDestinoChange}
                        inputContaBancariaErrorClass={this.state.inputContaBancariaLabelErrorClass}
                        // errorContaBancariaMessage={this.state.errorContaBancariaLabelMessage}
                        // disabled
                    />
                    <br />
                </>  
            )
        }

        return (
            <>
            <Dialog
                visible={this.props.visible}
                style={{ width: '450px' }}
                header={this.props.header}
                modal
                className="p-fluid"
                footer={editTipoRecebimentoDialogFooter}
                onHide={this.hideDialog}
            >

                <div className="p-field">
                    <label htmlFor="nome">Nome</label>
                    <input style={{color: '#000'}}
                            type="text"
                            className={"form-control " + this.state.inputNomeErrorClass}
                            id="nome"
                            autoFocus
                            value = {this.state.nome}
                            name="nome"
                            onChange={this.handleChange}
                            onKeyDown={this.handleKeyUp}
                            // onKeyDown={this.handleKeyUp}
                            disabled={
                                this.props.state.natureza === this.tipoDePagamentoService.naturezaAplicacaoFinanceiraValue
                                || this.props.state.natureza === this.tipoDePagamentoService.naturezaTarifaValue
                            }
                            />
                    <div className="invalid-feedback">{this.state.errorNomeMessage}</div>
                </div>

                <br />

                <div className="p-field">
                    <label htmlFor="natureza">Natureza</label>
                    <SelectMenu
                        className={"form-control " + this.state.inputNaturezaErrorClass}
                        name="natureza"
                        list={this.tipoDePagamentoService.naturezaTipoDePagamentoList} 
                        value={this.state.natureza}
                        onChange={this.handleNaturezaChange}
                        // disabled
                    /> 
                    <div className="invalid-feedback">{this.state.errorNaturezaMessage}</div>

                </div>

                <br />

               {renderFornecedorInput()}

               {renderContaContabilInput()}

               {renderContaBancariaInput()}

                <InputChooseGrupoDePagamento
                    noHTMLFormat
                    selectedGrupoDePagamentoLabel={this.state.grupoDePagamentoLabel}
                    handleSelectionGrupoDePagamentoChange={this.handleSelectionGrupoDePagamentoChange}
                    push={this.props.push}
                    // inputGrupoDePagamentoErrorClass={this.state.inputGrupoDePagamentoErrorClass}
                />

                <br />

                <div className="col-md-12">
                <CheckBoxComponent
                    label={ConstantsUtil.ausenteNoDREPorCompetenciaCheckBoxLabel}
                    checked={this.state.ausenteDRECompetencia}
                    handleCheckBoxChange={(checked) => this.setState({ausenteDRECompetencia: checked})}
                />
                </div>

            </Dialog>
            
            </>
        )    
    }

}

export default EditTipoDePagamentoDialog