import WorkerApiService from "../../workerApiServices";

class GrupoDePagamentoService extends WorkerApiService {

    constructor() {
        super('/api/gurpoDePagamento')
    }


    search() {
        return this.get("/search")
    }

    save(object){
        return this.post("/save", object)
    }

    update(object){
        return this.put("/update", object)
    }

    deleteById(id){
        return this.delete(`/delete/${id}`)
    }

}

export default GrupoDePagamentoService