import React from 'react'
import { withRouter } from 'react-router-dom'

import { TextField } from '@mui/material'
import { Button } from 'primereact/button'

import { AuthContext } from '../../main/authProvider'

import Card from '../../components/card'
import * as popUp from '../../components/toastr'
import HandleErrorService from '../../app/service/handleErrorService'
import GrupoDePagamentoService from '../../app/service/contasAPagar/grupoDePagamentoService'
import GrupoDePagamentoTable from '../../components/contasAPagar/grupoDePagamentoTable'
import GeneralServices from '../../app/service/generalServices'
import ConstantsUtil from '../../context/constantsUtil';
import CheckBoxComponent from '../../components/relatorios/checkBoxComponent';


class GrupoDePagamento extends React.Component{

    constructor(){
        super();
        // this.generalServices = new GeneralServices();
        this.grupoDePagamentoService = new GrupoDePagamentoService()
        this.generalServices = new GeneralServices()
        this.inputNome = React.createRef();
    }    

    state = {

        grupoDePagamentoList: [],
        filteredGrupoDePagamentoList: [],

        nome: '',
        inputNomeErrorClass: false,
        errorNomeMessage: '',

        destacadoFluxoDeCaixa: false,
        ausenteDreCompetencia: false,
        // inputCheckBoxErrorClass: '',
        // errorCheckBoxMessage: '',
        
        isSaving: false,
        loading: false,
    }

    componentDidMount(){
        this.search()
    }

    search = () => {
        
        this.setState({loading: true})
        
        this.grupoDePagamentoService.search()
        .then(response => {
            this.setState({grupoDePagamentoList: response.data})
            this.setState({filteredGrupoDePagamentoList: response.data})
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
        .finally(() => {
            this.setState({loading: false})
        })
    }

    handleChange =  async (event) => {
        const value = event.target.value
        const name = event.target.name
        await this.setState({[name]: value})
        this.filter()
    }

    filter = () => {
        this.setState({
            filteredGrupoDePagamentoList:
                JSON.parse(
                    JSON.stringify(
                        this.state.grupoDePagamentoList.filter(grupoDePagamento => {
                            return GeneralServices.includeString(grupoDePagamento.nome, this.state.nome)
                            // return grupoDePagamento.nome.toUpperCase().includes(this.state.nome.toUpperCase())
                        }
                        )
                    )
                )
        })
    }

    handleMuiKeyUp = async (event) => {

        await this.generalServices.sleep(100)

        if(this.state.isSaving){
            return
        }

        if(event.code === "Enter" || event.code === "NumpadEnter"){
            this.callSave()
        }
    }

    resetForm = () => {
        this.setState({nome: ''})
    }

    resetView = () => {
        this.setState({inputNomeErrorClass: false})
        this.setState({errorNomeMessage: ''})

        this.setState({inputDescricaoErrorClass: false})
        this.setState({errorDescricaoMessage: ''})
    }

    checkData = () => {
        var check = true

        if(!this.state.nome){
            this.setState({inputNomeErrorClass: true})
            this.setState({errorNomeMessage: 'O nome é obrigatório'})
            check = false
        }

        return check

    }

    callSave = () => {

        this.resetView()

        if(this.checkData()){
            this.save()
        }
    }

    save = () => {
        this.setState({isSaving: true})
            this.grupoDePagamentoService.save(
                {
                    nome : this.state.nome.trim().toUpperCase(),
                    destacadoDRE: this.state.destacadoFluxoDeCaixa,
                    ausenteDRECompetencia: this.state.ausenteDreCompetencia,

                }
            )
            .then(async response => {
                popUp.successPopUp("Grupo de Pagamento cadastrado com sucesso!")
                await this.generalServices.sleep(1000)
                window.location.reload()
                // this.setState({isSaving: false})
                // this.resetForm()
                // this.search()
                // this.inputNome.current.focus()
            })
            .catch(async error => {
                // await this.generalServices.sleep(1000)
                this.setState({isSaving: false})
                HandleErrorService.handleError(error)
            })
    }

    delete = (id) => {
        this.setState({isSaving: true})
        this.grupoDePagamentoService.deleteById(id)
        .then(async response => {
            popUp.successPopUp("Grupo de Pagamento deletado com sucesso!")
            // await this.generalServices.sleep(1000)
            this.resetForm()
            this.search()
            this.inputNome.current.focus()
        })
        .catch(async error => {
            // await this.generalServices.sleep(1000)
            HandleErrorService.handleError(error)
        })
        .finally(() => {
            this.setState({isSaving: false})
        })
    }

    render(){
        return(
            <div className="bs-docs-section" >
            <Card title = "Cadastrar Grupos de Pagamento">
                
            <div className = "col-md-12">

                <div className="row">
                
                <div className = "col-md-4">

                <div className="row">
                {/* <div className = "col-md-4"> */}
                <TextField
                    autoFocus
                    type="text"
                    size="small"
                    name="nome"
                    style ={{width: '100%'}}
                    value={this.state.nome}
                    onChange={this.handleChange}
                    onKeyUp={this.handleMuiKeyUp}
                    required
                    error={this.state.inputNomeErrorClass}
                    helperText={this.state.errorNomeMessage}
                    label="Nome"
                    inputRef={this.inputNome}
                />
                {/* </div> */}
                
                </div>

                <br />
                <CheckBoxComponent
                    label={ConstantsUtil.destacadoNoDRECheckBoxLabel}
                    checked={this.state.destacadoFluxoDeCaixa}
                    handleCheckBoxChange={(destacadoFluxoDeCaixa) => this.setState({destacadoFluxoDeCaixa})}
                />

                <br />
                <CheckBoxComponent
                    label={ConstantsUtil.ausenteNoDREPorCompetenciaCheckBoxLabel}
                    checked={this.state.ausenteDreCompetencia}
                    handleCheckBoxChange={(ausenteDreCompetencia) => this.setState({ausenteDreCompetencia})}
                />

                 <br />

                <div className="row">
                <Button 
                    label="Cadastrar Grupo de Pagamento"
                    icon="pi pi-plus"
                    onClick = {this.callSave}
                    disabled = {this.state.isSaving}
                />
                </div>

                </div>

                <div className = "col-md-8">
                <GrupoDePagamentoTable
                    // list = { this.filterByName() }
                    list = { this.state.filteredGrupoDePagamentoList}
                    // deleteMultiple = {this.deleteMultipleTiposDeRecebimento}
                    search={this.search}
                    delete={this.delete}
                    searchText={this.state.nome}
                    push={this.props.history.push}
                    loading = {this.state.loading}
                    />
                </div>

                </div>

            </div>

            </Card>
            <div className="d-flex "/>
            </div>
        )
    }

}

GrupoDePagamento.contextType = AuthContext
export default withRouter( GrupoDePagamento )