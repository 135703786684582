import WorkerApiService from "../workerApiServices";


class PlanoDeContasService extends WorkerApiService {

    constructor() {
        super('/api/planoDeContas')
        this.naturezaList =
        [
            {label: 'Selecione', value: ''},
            {label: 'Credora', value: 'CREDORA'},
            {label: 'Devedora', value: 'DEVEDORA'},
        ]

        this.tipoContaContabilSinteticaLabel = 'SINTETICA'
        this.tipoContaContabilAnaliticaLabel = 'ANALITICA'
    }

    search(){
        return this.get('/search')
    }

    callSave(selectedConta){
        return this.post('/callSave', selectedConta)
    }

    create(newConta){
        return this.post('/create', newConta)
    }

}

export default PlanoDeContasService