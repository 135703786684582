import { ProgressBar } from 'primereact/progressbar'
import { ProgressSpinner } from 'primereact/progressspinner'
import React from 'react'
import GeneralServices from '../../app/service/generalServices'
import HandleErrorService from '../../app/service/handleErrorService'
import ProgressService from '../../app/service/progressService'
import EndProgressdNotice from './endProgressNotice'

class ProgressBarComponent extends React.Component{

    constructor(){
        super()
        this.generalServices = new GeneralServices()
        this.progressService = new ProgressService()
    }

    state = {

        progress: 0,
        getProgressError: 0,
        failProgress: false,
        // uploadInProgress: false,
        completeProgress: false,

        notice: false,
        noticeSeverity: '',
        noticeMessage: '',
        successNoticeMessage: '',
        failNoticeMessage: '',


    }

    componentDidMount(){
        // this.doCompleteProgress()
        // this.doFailProgress()
        this.initProgress()
    }

    initProgress = async () => {
        this.setState({successNoticeMessage: this.props.successNoticeMessage})
        this.setState({failNoticeMessage: this.props.failNoticeMessage})
        this.getProgress()
    }

    // uploadInProgress = IsInProgress => {
    //     this.setState({notice: true})
    //     this.setState({uploadInProgress: IsInProgress})
    //     // this.props.uploadInProgress(IsInProgress)
    // }

    doCompleteProgress = () => {
        this.setState({completeProgress: true})
        this.setState({progress: 100})
        // this.setState({files: []})
        // this.uploadInProgress(false)
        this.setState({notice: true})
        this.setState({noticeSeverity: "success"})
        this.setState({noticeMessage: this.state.successNoticeMessage})
        // this.fileUploadRef.clear()

        if(this.props.endProgress){
            this.props.endProgress()
        }
    }

    doFailProgress = (backendFailMessage) => {
        this.setState({failProgress: true})
        // this.uploadInProgress(false)
        this.setState({notice: true})
        this.setState({noticeSeverity: "error"})

        var failMessage = this.state.failNoticeMessage
        if(backendFailMessage){
            failMessage = `Falha: ${backendFailMessage}`
        }

        this.setState({noticeMessage: failMessage})

        if(this.props.failProgress){
            this.props.failProgress()
        }
    }

    getProgress = async () => {
        await this.generalServices.sleep(2.5*1000)
        if(this.state.failProgress)
            return;

        if(!this.state.completeProgress)
        {
            this.progressService.getProgress(this.props.progressKey)
            .then(async response => {
                if(response.data.failed){
                    const error={
                        response: {
                            data: response.data.failMessage
                        }
                    }
                    HandleErrorService.handleError(error)
                    this.doFailProgress(response.data.failMessage)
                    return;
                }
                var progress = response.data.progress
                if(parseInt(this.state.progress,10) !== 100){
                    if(progress > 1) {
                        progress = parseInt(progress, 10)
                    } else if(progress > 0){
                        progress = progress.toFixed(1)
                    }
                    this.setState({progress})
                    await this.generalServices.sleep(2.5*1000)
                    this.getProgress()
                } else{ // progress ===100
                    this.doCompleteProgress()
                    // this.deleteProgress(progressKey)
                }
                
            }).catch(async error => {
                await this.generalServices.sleep(2.5*1000)
                this.setState({getProgressError: this.state.getProgressError+1})
                if(this.state.getProgressError < 20){
                    this.getProgress()
                } else{
                    HandleErrorService.handleError(error)
                    this.doFailProgress()
                    this.setState({getProgressError: 0})
                }
            })
        }
        else {
            this.setState({progress: 100})
        }
    }


    render() {

        const renderProgressSpinner = () => {
            // console.log("renderProgressSpinner init: ", this.state.completeProgress, this.state.failProgress)
            if( !this.state.completeProgress && !this.state.failProgress ){
                // console.log("renderProgressSpinner de verdade")
                return(
                    <div className="col-md-1">
                        <ProgressSpinner
                            className="custom-progress-spinner"
                            style={{width: '50px', height: '50px', marginBottom:'0px'}}
                            strokeWidth="5"
                        />
                    </div>
                )
            }
        }

        const renderProgressBar = () => {
            if(this.state.progress === 0 && !this.state.failProgress){
                return(
                
                    <>
                        <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>
                    </>
                    
                )
            } else{
                return(
                
                    <>
                    <div className="row">
                        <div className="col-md-11">
                            <ProgressBar value={this.state.progress}/>
                        </div>

                        {renderProgressSpinner()}

                    </div>
                    </>
                    
                )
            }
        }

        const renderProgressNotice = () => {
            if( (this.state.completeProgress || this.state.failProgress) && this.state.notice) {
                return(
                    <>
                    <EndProgressdNotice severity={this.state.noticeSeverity} message={this.state.noticeMessage}/>
                    </>
                )
            }
        }

        return(
            
            // <div className = "card mb-12">
            <div>
                
                {renderProgressBar()}

                {renderProgressNotice()}


                

            </div>

        )

    }

}

export default ProgressBarComponent