import React from 'react'

import { Button } from 'primereact/button'

import PlanoDeContasDialog from "./planoDeContasDialog"

class ContaContabilView extends React.Component {

    state = {
        displayContaContabilDialog: false,
    }


    hideDialog = () => {
        this.setState({displayContaContabilDialog: false})
    }


    render(){

        const renderCodigo = () => {
            if(!this.props.hideCodigo){
                return(
                    <>
                    {this.props.contaContabil.codigo}
                    </>
                )
            }
        }

        if(!this.props.contaContabil){
            return <>-</>
        }

        return (
        <div>
            {renderCodigo()}
            <Button
                tooltip="Dados da Conta" 
                tooltipOptions={{position: 'top'}}
                icon="pi pi-eye"
                className={ "p-button-rounded p-mr-2 " }
                // style={ {display: this.state.selectedTipoDeRecebimentoValue === '' ? 'none' : ''} }
                style={ {marginLeft: '10px', maxHeight: '25px', maxWidth: '25px'} }
                // onClick={() => this.viewContaContabil(this.props.contaContabil)}
                onClick={() => this.setState({displayContaContabilDialog: true})}
            />


            <PlanoDeContasDialog
                header="Dados da Conta"
                disabled
                hideDialog={this.hideDialog}
                visible={this.state.displayContaContabilDialog}
                state={this.props.contaContabil}
            />


        </div>
        )
    }

}

export default ContaContabilView