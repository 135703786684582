import React from 'react'
import { withRouter } from 'react-router-dom'

import Card from '../../../components/card'
import { AuthContext } from '../../../main/authProvider'
import { Button } from 'primereact/button'

import LancamentoProvisaoComponent from '../../../components/contasAPagar/provisoes/lancamentoProvisaoComponent'


class LancarProvisoesAPagar extends React.Component {

    constructor(){
        super();
        this.lancamentoProvisaoComponentRef = React.createRef();

    }

    state = {

        disableSaveButton: false,

    }



    callSave = () => {
        this.lancamentoProvisaoComponentRef.current.callSave()
    }

    toogleSaveButton = () => {
        this.setState({disableSaveButton: !this.state.disableSaveButton})
    }


    render() {
        
        return (
            <div className="bs-docs-section" >

            <Card title = "Lançar Provisões a Pagar">
                <div className="col-md-12">

                    <LancamentoProvisaoComponent
                        ref={this.lancamentoProvisaoComponentRef}
                        toogleSaveButton={this.toogleSaveButton}
                        isLancamento
                    />
                    
                    <br />
                    
                    <Button 
                        label="Salvar Lançamento"
                        icon="pi pi-save"
                        onClick = {this.callSave}
                        // style={ {maxHeight: '35px' } }
                        disabled = {this.state.disableSaveButton}
                    />

                    <br />

                    <br />

                </div>
            </Card>
            <div className="d-flex "/>

        </div>
        )

    }

}

LancarProvisoesAPagar.contextType = AuthContext

export default withRouter(LancarProvisoesAPagar)
