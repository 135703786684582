import React from 'react'
import Card from '../../components/card'
import { withRouter } from 'react-router-dom'

import { AuthContext } from '../../main/authProvider'
import TransactionService from '../../app/service/estoque/transactionService'
import GeneralServices from '../../app/service/generalServices'
import TransactionByNFTable from './transactionByNFTable'
import ProgressService from '../../app/service/progressService'
import NFService from '../../app/service/estoque/NFService'

class ValidacaoNFsEntrada extends React.Component {

    constructor(){
        super();
        this.generalServices = new GeneralServices();
        this.progressService = new ProgressService();
        this.transactionService = new TransactionService();
        this.NFService = new NFService();
    }

    state = {

    }

    render() {

        return (
                   
            <div className="bs-docs-section" >
                
                <Card title = "Validação das NFs de Entrada"> 
                    <TransactionByNFTable
                        validateNFs
                    />
                </Card>
                <div className="d-flex "/>
            </div>
              
        )
    }


}

ValidacaoNFsEntrada.contextType = AuthContext

export default withRouter(ValidacaoNFsEntrada)