import WorkerApiService from "../../workerApiServices";

class FichaDePagamentoService extends WorkerApiService {

    constructor() {
        super('/api/fichaDePagamento')
    }

    generate(dataInicial, dataFinal){
        const body = {
            // ipoDePagamento: selectedTipoDePagamento,
            dataInicial,
            dataFinal
        }
        return this.post(`/generate`, body)
    }

}

export default FichaDePagamentoService