
import React from 'react'


class MenuItem extends React.Component {

    /*
    Este template MenuItem foi criado basicamente com o intuito de conseguir desabilitar os itens do menu do frontend, quando necessário.
    Ao passar a prop disabled, ele fica "apagado" e sem link.
    Criei esse template copiando o original do primereact.
    */

    constructor() {
        super()
    }

    getItemIcon = () => {
        return this.props.item.icon ? this.props.item.icon : ""
    }

    handleClick = event => {

        /*
            Objetivo: Em renderização para celular/tablet, os itens do menuModulesBar que têm subitens e utilizam "template: menuItem",
            não estavam abrindo o sublink.

            Quando não usa o template template, ao clicar no item, que é um elemento <a> do html, seu <li> pai adicionava/removia
            a classe p-menuitem-active, de forma que os subitens apareciam/ocultavam-se.

            Ao usar o template, isto não estava ocorrendo.
            Então, no template (esta classe), adicionei um onClick ao <a> que busca seu <li> pai e faz o funcionamento correto com a classe.
        */

        // Encontra o elemento <li> pai do link <a>
        const liElement = event.target.closest('li');

        // Verifica se o <li> foi encontrado
        if (liElement) {
            // Adiciona ou remove (toggle) a classe.
            liElement.classList.toggle('p-menuitem-active');
        }
    }

    render(){
        
        const renderArrowToSubitems = () => {
            if(this.props.item.items && this.props.item.items.length > 0) {
                return (
                    <span class="p-submenu-icon pi pi-angle-right"></span>
                )
            }
        }

        const renderCommon = () => {
            // console.log("this.props.item: ", this.props.item)
            return (
                <>
                <span className={this.props.item.disabled ? "my-menuitem-icon " + this.getItemIcon() :  "p-menuitem-icon " + this.getItemIcon()} />
                <span className={this.props.item.disabled ? "my-menuitem-text" : "p-menuitem-text"}>{this.props.item.label}</span>
                {renderArrowToSubitems()}
                </>
            )
        }

        const renderLink = () => {
            if(this.props.item.disabled){
                return (
                    <>
                    <p
                        // role="menuitem"
                        className="p-menuitem-link"
                        style={{marginBottom: '0px'}}
                        // aria-haspopup="false"
                    >
                        {renderCommon()}
                    </p>
                    </>
                )
            }
            else{
                return (
                    <>
                    <a
                        id={this.props.item.label}
                        href={this.props.item.disabled ? "/" : this.props.item.url} 
                        role="menuitem"
                        className="p-menuitem-link"
                        aria-haspopup="false"
                        onClick={this.handleClick}
                    >
                        {renderCommon()}
                    </a>                    
                    </>
                )
            }
        }

        return (
            <>
            {renderLink()}
            </>
        )
    }

}

export default MenuItem