import { Button } from 'primereact/button'
import React from 'react'
import GeneralServices from '../../app/service/generalServices';
import HandleErrorService from '../../app/service/handleErrorService'
import PlanoDeContasService from '../../app/service/planoDeContasService';
import ConstantsUtil from '../../context/constantsUtil';
import * as popUp from '../toastr';
import PlanoDeContasDialog from './planoDeContasDialog';
import PlanoDeContasTable from './planoDeContasTable'

class PlanoDeContasSearchTable extends React.Component{

    constructor(){
        super();
        this.planoDeContasService = new PlanoDeContasService();
    }

    state = {

        searchText: '',

        planoDeContasList: [],
        filteredPlanoDeContasList: [],

        selectedConta: '',

        callSaveResponse: {},
        createPlanoDeContasDialog: false,
        
        didUpdated: false,

    }

    // componentDidUpdate(){
    //     this.setStates()
    // }

    componentDidMount(){
        this.setState({filteredPlanoDeContasList: this.props.planoDeContasList})
    }

    setStates = () => {
        // if( (!this.state.didUpdated) || this.props.forceUpdate ){
        // // this.setState({searchText: ''})
        // this.setState({planoDeContasList: this.props.list})
        // this.setState({filteredPlanoDeContasList: this.props.list})

            
        // this.setState({didUpdated: true})

        // // if(this.props.doneForceUpdate){
        // //     this.props.doneForceUpdate()
        // // }

        // }
    }

    handleChange = async (event) => {
        const value = event.target.value
        const name = event.target.name
        await this.setState({ [name]: value })
        this.filter()
    }

    handleSelectionChange = (event) => {
        this.setState({selectedConta: event.value})

        if(this.props.handleSelectionContaContabilChange){
            this.props.handleSelectionContaContabilChange(event)
        }
    }

    handleKeyPress = async (e) => {
        if(this.props.handleKeyPress){
            if (e.key === "Enter") {
                // const filteredList = JSON.parse(JSON.stringify(this.state.filteredPlanoDeContasList))
                // if(filteredList.length === 1){
                //     await this.setState({selectedContaContabil: filteredList[0]})
                //     this.callSave()
                // }
                this.props.handleKeyPress(JSON.parse(JSON.stringify(this.state.filteredPlanoDeContasList)))
              }
        }
    }

    filter = () => {
        
        this.setState({
            filteredPlanoDeContasList:
                JSON.parse(
                    JSON.stringify(
                        this.props.planoDeContasList.filter(contaContabil => {
                            return (
                                GeneralServices.includeString(contaContabil.nomeConta, this.state.searchText)
                                ||
                                GeneralServices.includeString(contaContabil.numeroDeChamada, this.state.searchText)
                            ) 
                        }
                        )
                    )
                )
        })
    }

    chooseList = () => {
        if(this.state.searchText === ''){
            return this.props.planoDeContasList
        }

        return this.state.filteredPlanoDeContasList
    }

    callSave = () => {
        if(!this.props.context.perfil.isAdmin){
            popUp.errorPopUp(ConstantsUtil.accessDeniedMessage)
            return
        }

        if(!this.state.selectedConta){
            popUp.infoPopUp("Primeiro, selecione o grupo sintético da conta a ser criada.")
            return
        }

        this.planoDeContasService.callSave(this.state.selectedConta)
        .then(respose => {
            this.setState({callSaveResponse: respose.data})
            this.setState({createPlanoDeContasDialog: true})
        }).catch(error => {
            HandleErrorService.handleError(error)
        })
    }


    hideDialog = () => {
        this.setState({createPlanoDeContasDialog: false})
        this.setState({callSaveResponse: {}})
    }

    resetSelectionChange = () => {
        this.setState({selectedConta: ''})
    }

    render (){

        const renderAdicionarContaButton = () => {
            if(this.props.renderAddButton)
            return (
                <>

                <Button 
                    label="Adicionar Nova Conta" 
                    icon="pi pi-plus"
                    // style={ {maxHeight: '35px', marginLeft: '8px'} }
                    onClick={this.callSave}
                    // disabled={!this.state.selectedConta}
                    />
                
                <br />

                <br />

                </>
            )
        }

        const renderCreateContaDialog = () => {
            return(
                <>
                    <PlanoDeContasDialog  
                        header="Criar nova conta"
                        hideDialog={this.hideDialog}
                        visible={this.state.createPlanoDeContasDialog}
                        state={this.state.callSaveResponse}
                        // search={this.search}
                        resetSelectionChange={this.resetSelectionChange}
                        push={this.props.push}
                    />
                </>
            )
        }

        return(
            <>
            
            <div className="p-field">
                        <label htmlFor="search conta">Busca</label>
                        <input style={{color: '#000'}}
                            autoFocus   
                            className={"form-control " }
                            type="text"
                            id="searchText"
                            name="searchText"
                            value={this.state.searchText}
                            placeholder="Pesquise pelo nome ou pelo número de chamada"
                            onChange={this.handleChange}
                            onKeyPress={this.handleKeyPress}
                        />
                    </div>
    
                    <br />

                    {renderAdicionarContaButton()}
    
                    <PlanoDeContasTable
                        // list={this.props.planoDeContasList}
                        list={this.chooseList()}
                        notFilteredList={this.props.planoDeContasList}
                        selectedConta={this.state.selectedConta}
                        handleSelectionChange={this.handleSelectionChange}
                        // search={this.search}
                        loading={this.state.loadingTable ? true : this.props.loading}
                        searchText={this.state.searchText}
                        // lazy
                    />

                    {renderCreateContaDialog()}

                </>
        )
    }

}

export default PlanoDeContasSearchTable