import WorkerApiService from "../../workerApiServices";

class PDVCaixaService extends WorkerApiService {

    constructor() {
        super('/api/pdvCaixa')
    }

    // getPDV(dataLancamento){
    //     let params = `?`
    //     params = `${params}&dataLancamento=${dataLancamento}`
    //     return this.get(`/search${params}`)
    // }

    check(){
        return this.get("/check")
    }

    search() {
        return this.get("/search")
    }

    save(object){
        return this.post("/save", object)
    }

    update(object){
        return this.put("/update", object)
    }

}

export default PDVCaixaService