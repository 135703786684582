import React from 'react'
import NotificacaoService from '../../../app/service/central/notificacaoService'
import HandleErrorService from '../../../app/service/handleErrorService'
import { DataTable } from 'primereact/datatable'
import ConstantsUtil from '../../../context/constantsUtil'
import GeneralServices from '../../../app/service/generalServices'
import TooltipButton from '../../tooltipButton'
import { Column } from 'primereact/column'

class NotificacaoTable extends React.Component {

    constructor(){
        super()
        this.notificacaoService = new NotificacaoService()
        this.dt = React.createRef()
    }

    state = {

        loading: false,
        notificacoesList: [],

    }

    componentDidMount(){
        this.search()
    }

    search = () => {
        this.setState({loading: true})
        this.notificacaoService.search()
        .then(response => {
            this.setState({notificacoesList: response.data})
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
        .finally(() => {
            this.setState({loading: false})
        })
    }

    goToNotificacao = notificacao => {
        this.notificacaoService.marcarLida(notificacao)
        .then(response => {

        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
        .finally(() => {
            switch(notificacao.chave){
                case ConstantsUtil.pagamentoParaHojeChave:
                    sessionStorage.setItem(ConstantsUtil.checkProvisoesAPagarIsFromNotificacaoSessionStorageKey, `${notificacao.data} - 00:00`)
                    window.location.href = '/checkProvisoesAPagar'
                    break;
            }
        })
    }

    marcarComoNaoLida = (notificacao) => {
        this.notificacaoService.marcarComoNaoLida(notificacao)
        .then(response => {
            window.location.reload()
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
    }

    render() {

        const renderAcaoColumn = () => {
            return (
                <Column
                    header="Ações"
                    style ={ {width: '100px'} }
                    body={actionBody}
                />   
            )
        }

        const renderMarcarComoNaoLida = (notificacao) => {
            if(notificacao.lida){
                return (
                    <React.Fragment>
                        <TooltipButton
                            tooltip = "Marcar como não lida"
                            className={"p-button-rounded p-mr-2 right-button"}
                            icon={"pi pi-undo"}
                            onClick={() => this.marcarComoNaoLida(notificacao)}
                        />
                    </React.Fragment>                
                )
            }
        }

        const actionBody = (notificacao) => {
            return (
                <>
                    <React.Fragment>
                        <TooltipButton
                            tooltip = "Analisar Notificação"
                            className={"p-button-rounded p-mr-2 right-button"}
                            icon={"pi pi-eye"}
                            onClick={() => this.goToNotificacao(notificacao)}
                        />
                    </React.Fragment>
                    {renderMarcarComoNaoLida(notificacao)}
                </>
            );
        }        

        return (
            <>
                <DataTable
                    ref={this.dt}
                    value={this.state.notificacoesList}
                    className="p-datatable-sm small-font"
                    rowHover
                    emptyMessage="Não há notificações."
                    scrollable
                    scrollHeight="500px"
                    loading={this.state.loading}
                    dataKey="idNotificacaoUsuario"
                    paginator rows={ConstantsUtil.initialTableQuantityOption}
                    paginatorTemplate={GeneralServices.tablePaginatorTemplate(ConstantsUtil.initialTableQuantityOption, this.state.notificacoesList.length)}
                    currentPageReportTemplate="Mostrando de {first} ao {last} de {totalRecords} Empresas"
                >

                    <Column field="data" header="Data da Notificação" sortable
                    />

                    <Column field="mensagem" header="Mensagem" sortable
                    />

                    <Column field="lida" header="Lida" sortable
                        body={rowData => {
                            return GeneralServices.renderStyledColorTableColumn(rowData.lida ? 'Sim' : 'Não', rowData.lida)
                        }}               
                    />

                    {renderAcaoColumn()}

                </DataTable>            
            </>
        )
    }

}

export default NotificacaoTable