import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';

const height = '30px'

const theme = createTheme({
    components: {
      MuiAutocomplete: {
        styleOverrides: {
          inputRoot: {
            height: height, // ajuste conforme necessário
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          InputProps: {
            style: {
              height: height, // ajuste conforme necessário
            },
          },
        },
      },
    },
  });

export const CustomThemeMUIProvider = ({ children }) => (
<ThemeProvider theme={theme}>
    <CssBaseline />
    {children}
</ThemeProvider>
);  