import ApiService from './apiServices'

class CentralApiService extends ApiService {

    constructor(endpoint_url){
        super(endpoint_url)
    }

    getEndpointUrl(){// /api/users -> /central/api/users
        return `/central${this.endpoint_url}`
    }

}

export default CentralApiService