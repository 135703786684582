import React from 'react'

import { Button } from "primereact/button"
import TooltipButton from '../tooltipButton'

class EditLancamentoBancarioButton extends React.Component {

    render(){
        return (
            <TooltipButton
                tooltip='Editar Lançamento'
                icon="pi pi-pencil"
                onClick={() => this.props.editLancamentoBancario(this.props.lancamento)}
            />
        )
    }


}

export default EditLancamentoBancarioButton