import CentralApiService from "../../centralApiServices";


class CentralSystemDataService extends CentralApiService {

    constructor() {
        super('/api/systemData')
    }

    getSystemData(){
        return this.get('/getSystemData')
    }

    // getLoggedSystemData(){
    //     return this.get('/getLoggedSystemData')
    // }

}

export default CentralSystemDataService