import React from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import GeneralServices from '../../../../app/service/generalServices'
import LancarFechamentoBancarioService from '../../../../app/service/conciliacaoBancaria/lancarFechamentoBancarioService'
import ConstantsUtil from '../../../../context/constantsUtil'
import { Button } from 'primereact/button'
import { classNames } from 'primereact/utils'
import * as xlsx from 'xlsx'
import DREFichaBancariaDialog from '../DREFichaBancariaDialog'
import ExpansionTableButton from '../expansionTableButton'
import TableFilters from '../../../tableFilters'


class DREDestacadoConsolidadoTable extends React.Component {

    state = {
        
        loading: false,
        elementToView: null,
        displayFichaBancariaDialog: false,
        // fichaBancariaToView: [],

        selectedValoresMeses: {},
        
        expanded: false,

        didUpdate: false,        

    }

    componentDidUpdate(){
        if(!this.state.didUpdate){
            let selectedValoresMeses = {}
            if(this.props.dreConsolidado.descricaoMesesList){
                this.props.dreConsolidado.descricaoMesesList.forEach(descricaoMes => {
                    selectedValoresMeses[descricaoMes] = null
                })
            }
            this.setState({selectedValoresMeses})
            this.setState({didUpdate: true})

        }
    }    

    constructor(){
        super();
        this.lancarFechamentoBancarioService = new LancarFechamentoBancarioService();
        this.tableFilters = new TableFilters();

    }

    getTableHeaders = () => {
        
        const periodo = this.props.dreConsolidado && this.props.dreConsolidado.initialDate ? this.props.dreConsolidado.initialDate + " - " + this.props.dreConsolidado.finalDate : ""
        const periodoLabel = "Período: " + periodo

        return {periodo: periodoLabel}
    }

    viewLancamentos = async (rowData, descricaoMes) => {
                
        // var fichaBancaria = rowData.dreMensalConsolidadoMap[descricaoMes].fichaBancariaUnificada
        // fichaBancaria.initialDate = this.props.dreConsolidado ? this.props.dreConsolidado.initialDate : ''
        // fichaBancaria.finalDate = this.props.dreConsolidado ? this.props.dreConsolidado.finalDate : ''

        // await this.setState({fichaBancariaToView: JSON.parse(JSON.stringify(fichaBancaria))})

        // this.setState({fichaBancariaRecebimento: rowData.total > 0})
        this.setState({elementToView: rowData.dreMensalConsolidadoMap[descricaoMes]})
        this.setState({forceUpdateDialog: true})
        this.setState({displayFichaBancariaDialog: true})
    }

    hideDialog = () => {
        this.setState({displayFichaBancariaDialog: false})
        // this.setState({fichaBancariaToView: ''})
        this.setState({fichaBancariaRecebimento: ''})
    }

    rowClass(rowData) {
        const obj = {
            [ConstantsUtil.totaisTableLabel] : rowData.tipoLancamento === ConstantsUtil.tipoTotaisFichaBancariaLabel,
            'small-font': true
            // 'small-height': true
        }

        return obj
    }

    formattedDREMensalPagamentoToExportByExcel(){
        var formattedList = []

        this.props.dreConsolidado.dreDestacadoList.forEach(item =>{
            var obj = {
                Nome: item.nome,
            }

            // console.log("item:", item)

            this.props.dreConsolidado.descricaoMesesList.forEach(descricaoMes => {
                // console.log("descricaoMes", descricaoMes)
                const mesItem = item.dreMensalConsolidadoMap[descricaoMes]
                // console.log("mesItem: ", mesItem)
                let valor = ''
                if(mesItem){
                    valor = mesItem.total
                }
                else{
                    valor = 0
                }
                obj[descricaoMes] = valor
            })

            obj.Total = item.total

            formattedList.push(obj)
        })

        return formattedList
    }

    formattedFichaBancariaToExport = (list, workbook) => {
        list.forEach(drePagamento => {
            if(drePagamento.tipoLancamento !== ConstantsUtil.tipoTotaisFichaBancariaLabel){
                this.props.dreConsolidado.contaBancariaList.forEach(contaBancaria => {
                    //as funções biblioteca xlsx exigem que o nome do sheet não exceda 31 caracteres.
                    var newSheetName = (drePagamento.grupoDePagamento.nome + " - " + contaBancaria.nomeBanco).slice(0, 31)
                    var fichaBancaria = drePagamento[contaBancaria.id].fichaBancaria.fichaBancariaList
                    if(fichaBancaria.length !== 0){
                        var formattedFichaBancaria = fichaBancaria.map(lancamento => {
                            const {data, descricao, saida, saldo, historico} = lancamento
                            return {data, descricao, saida, saldo, historico}
                        })
                    
                        // console.log("formatted: ", formattedFichaBancaria)
                        // console.log("ficha bancaria: ", fichaBancaria)
                        var newWorksheet = GeneralServices.jsonToSheet(formattedFichaBancaria)
                        var newSheet = {[newSheetName]: newWorksheet}
                        Object.assign(workbook.Sheets, JSON.parse(JSON.stringify(newSheet)));
                        workbook.SheetNames.push(newSheetName)
                    }
                })
            }
        })
    }

    generateWorkSheet(){
        const worksheet = GeneralServices.jsonToSheet(this.formattedDREMensalPagamentoToExportByExcel());
        return worksheet
    }

    exportExcel = () => {
        const periodo = this.props.dreConsolidado.initialDate + " - " + this.props.dreConsolidado.finalDate
            const worksheet = this.generateWorkSheet()
            const sheetName = periodo
            var workbook = { Sheets: { [sheetName]: worksheet }, SheetNames: [sheetName] };
            // this.formattedFichaBancariaToExport(this.props.dreConsolidado.dreDestacadoList, workbook)
            this.props.saveAsExcelFile(workbook, `Fluxo de Caixa Mensal de Resultados Não Operacionais - ${periodo} - VisãoRJ`);
    }

    filterList = () => {
        if(!this.state.expanded && this.props.dreConsolidado && this.props.dreConsolidado.dreDestacadoList){
            let listToBeReturned = this.props.dreConsolidado.dreDestacadoList.filter(item => item.tipoLancamento ===  ConstantsUtil.tipoTotaisFichaBancariaLabel)
            this.props.resetTotalItem(listToBeReturned)
            return listToBeReturned
        }
        return this.props.dreConsolidado.dreDestacadoList
    }

    updateValorMesFilter = async (descricaoMes, value) => {
        // console.log("updateValorMesFilter: ", descricaoMes, value)
        let selectedValoresMeses = this.state.selectedValoresMeses
        selectedValoresMeses[descricaoMes] = value
        await this.setState({selectedValoresMeses})
        // console.log("selectedValoresMeses: ", this.state.selectedValoresMeses)
    }

    renderMesTableFilter = (descricaoMes) => {
        // console.log("this.tableFilters: ", descricaoMes)
        return (
            <div className='right-align'>
                {this.tableFilters.renderTableFilter(
                    this.props.dt,
                    this.state.selectedValoresMeses[descricaoMes],
                    this.filterList(),
                    descricaoMes,
                    this.updateValorMesFilter,
                    descricaoMes,
                    '100%',
                    "Total"
                )}
                
            </div>
        )
    }        

    render (){

        const renderMesesColumn = () => {
            var mesesColumnList = []

            if(this.props.dreConsolidado && this.props.dreConsolidado.descricaoMesesList && this.props.dreConsolidado.descricaoMesesList.length !== 0){
                this.props.dreConsolidado.descricaoMesesList.forEach(descricaoMes => {
                    mesesColumnList.push(renderColumnItem(descricaoMes))
                });
            }

            return mesesColumnList
        }

        const renderColumnItem = (descricaoMes) => {
            return (
                <Column
                    field={descricaoMes}
                    // header={descricaoMes}
                    header={<div style={{height: ConstantsUtil.heightHeaderDREConsolidado}}>{descricaoMes}</div>}
                    style ={ {width: ConstantsUtil.widthColumDreConsolidado} }
                    filter={this.state.expanded} filterElement={this.renderMesTableFilter(descricaoMes)}
                    body={dreDestacadoDTO => {
                        const mesValor = dreDestacadoDTO.dreMensalConsolidadoMap[descricaoMes]
                        return (
                            <div className='right-align'>
                                {renderViewLancamentosLink(dreDestacadoDTO, descricaoMes, mesValor)}
                            </div>
                        )
                     }
                    }
                />
            )
        }

        const renderViewLancamentosLink = (dreDestacadoDTO, descricaoMes, mesValor) => {
            
            const classes = classNames({
                'superavitValue': mesValor.showTotal >= 0,
                'deficitValue': mesValor.showTotal <0
            });

            const cond1 = dreDestacadoDTO.dreMensalConsolidadoMap[descricaoMes] && dreDestacadoDTO.dreMensalConsolidadoMap[descricaoMes].total && dreDestacadoDTO.dreMensalConsolidadoMap[descricaoMes].total !== 0
            const cond2 = dreDestacadoDTO.tipoLancamento !== ConstantsUtil.tipoTotaisFichaBancariaLabel
            if( cond1 && cond2 ){


                return(
                    <a 
                        className={classes}
                        // href='/DREConsolidado'
                        onClick={() => this.viewLancamentos(dreDestacadoDTO, descricaoMes)}
                    >
                        {mesValor ? GeneralServices.showFormattedIfNotNull(mesValor.total, true) : ConstantsUtil.valueFormattedZero}
                    </a>
                )
            }
            else{
                if(dreDestacadoDTO.tipoLancamento ===  ConstantsUtil.tipoTotaisFichaBancariaLabel){
                    return (
                        <div className={classes}>
                            {mesValor ? GeneralServices.showFormattedIfNotNull(mesValor.showTotal, true) : ConstantsUtil.valueFormattedZero}
                        </div>
                    )
                }
                return(
                    <div >
                        {mesValor && mesValor.total ? GeneralServices.showFormattedIfNotNull(mesValor.total, true) : ConstantsUtil.valueFormattedZero}
                    </div>
                )
            }
        }


        const renderTotalColumn = (rowData) => {
            const valor = GeneralServices.showFormattedIfNotNull(rowData.showTotal, true)
            // const valor = renderStyledValue(rowData)

            return(
                <div className='right-align'>
                    {valor}
                </div>
            )
        }

        // const renderViewLancamentosButton = (rowData, valor) => {
        //     const cond1 = rowData.tipoLancamento !== ConstantsUtil.tipoTotaisFichaBancariaLabel
        //     const cond2 = rowData.fichaBancariaUnificada.fichaBancariaList && rowData.fichaBancariaUnificada.fichaBancariaList.length > 0
        //     if(cond1 && cond2){
        //         return(
        //             <div className='col-md-1'>
        //             <Button
        //                 tooltip="Ver Lançamentos" 
        //                 tooltipOptions={{position: 'top'}}
        //                 icon="pi pi-eye"
        //                 className={ "p-button-rounded p-mr-2 " }
        //                 // style={ {display: this.state.selectedTipoDeRecebimentoValue === '' ? 'none' : ''} }
        //                 style={ {marginLeft: '10px', maxHeight: '25px', maxWidth: '25px'} }
        //                 // onClick={() => this.viewContaContabil(this.props.contaContabil)}
        //                 // onClick={() => this.setState({displayFichaBancariaDialog: true})}
        //                 onClick={() => this.viewLancamentos(rowData)}
        //                 />
        //             </div>
        //         )
        //     }
        // }

        const renderStyledValue = (rowData) => {
            const valor = rowData.total
            const formattedValue = GeneralServices.showFormattedIfNotNull(valor, true)
            const isTotais = rowData.nome === ConstantsUtil.tipoTotaisFichaBancariaLabel
            const classes = classNames({
                'superavitValue': valor >= 0,
                'deficitValue': valor<0
            });
    
            return (
                <div className={classes}>
                    {formattedValue}
                </div>
            );
        }

        const renderStyledName = (rowData) => {
            // const valor = rowData.total
            // const formattedValue = GeneralServices.showFormattedIfNotNull(valor, true)
            const isTotais = rowData.tipoLancamento === ConstantsUtil.tipoTotaisFichaBancariaLabel
            const classes = classNames({
                'totaisLabel': isTotais && this.state.expanded,
            });
            const nome = isTotais ? ConstantsUtil.resultadoNaoOperacionalLabel : rowData.nome
            return (
                <div className={classes}
                    style ={ {
                        whiteSpace: 'nowrap', /* Impede que o texto quebre em várias linhas */
                        overflow: 'hidden', /* Esconde qualquer conteúdo que exceda o width da coluna */
                        textOverflow: 'ellipsis', /* Adiciona reticências (...) ao final do texto cortado */
                    } }
                    title={nome}
                >
                    {nome}
                </div>
            );
        }


        const nomeFilterElement = this.tableFilters.renderTableFilter(
            this.props.dt,
            this.state.selectedNome,
            this.filterList(),
            "selectedNome",
            (name, value) => this.setState({[name]: value}),
            'nome',
            // ConstantsUtil.widthColumNomeDreConsolidado,
            '150px',
            "Nome"
        )         

        const renderTable = () => {
            return (
                <DataTable
                    ref={this.props.dt}
                    className="p-datatable-sm small-font"
                    value={this.filterList()}
                    onValueChange={(currentList) => this.props.onFilterTable(currentList, this.props.dt)}
                    // rowClassName={this.rowClass}
                    // autoLayout
                    rowHover
                    showGridlines
                    // selection={this.state.selectedContasBancaria}
                    // onSelectionChange={this.handleSelectionChange}
                    // scrollable
                    // scrollHeight="500px"
                    style={{ maxWidth: '100vw'}}
                    frozenWidth={ConstantsUtil.widthColumNomeDreConsolidado}
                    loading={this.props.loading || this.state.loading}
                    dataKey="id"
                    emptyMessage="Nenhum Resultado encontrado."
                    // paginator rows={100}
                    // paginatorTemplate={GeneralServices.tablePaginatorTemplate(100, this.props.dreConsolidado && this.props.dreConsolidado.dreDestacadoList ? this.props.dreConsolidado.dreDestacadoList.length : '')}
                    // currentPageReportTemplate="Mostrando de {first} ao {last} de {totalRecords} Resultados Destacados"
                >
    
                <Column
                    field="nome"
                    header="Nome"
                    filter={this.state.expanded} filterElement={nomeFilterElement}
                    style ={ {width: ConstantsUtil.widthColumNomeDreConsolidado} }
                    frozen
                    body = {renderStyledName}
                />
    
                {renderMesesColumn()}
    
                <Column field="total"
                    // header="Total"
                    header={<div style={{height: ConstantsUtil.heightHeaderDREConsolidado}}>Total</div>}
                    style ={ {width: ConstantsUtil.widthColumDreConsolidado} }
                    // body = {rowData => GeneralServices.showFormattedIfNotNull(rowData.total, true)}
                    // body = {rowData => renderStyledValue(rowData)}
                    body = {renderTotalColumn}
                />

                <Column field="porcentagem" header="%"
                    style ={ {width: ConstantsUtil.widthColumDrePorcentagemConsolidado} }
                    body = {rowData =>  {
                        return (
                            <div className='right-align'>
                                {rowData ? `${rowData.porcentagem}%` : ''}
                            </div>
                        )
                    }}
                />
    
                </DataTable>
            )
        }

        const renderExportarButton = () => {
            if(this.state.expanded){
                return (
                    <Button label="Exportar"
                        icon={<i className="pi pi-file-excel" style={{fontSize: '1em', marginRight: '0.5em'}}></i>}
                        className="p-button-success small-font" onClick={this.exportExcel}
                        tooltip="Exportar em excel"
                        tooltipOptions={{position: 'top'}}
                        style={{maxHeight: '15px'}}
                    />                
                )            
            }
        }

        return (
            <>


            <div className = "card-header header-small-height" >
            <div className='col-md-12'>
                
                <div className='row'>
                    <ExpansionTableButton
                        expanded={this.state.expanded}
                        toggleExpansionTable={async () => {
                            await this.setState({expanded: !this.state.expanded})
                            await this.setState({selectedNome: ''})
                            this.props.dt.current.reset(); //reseta filtro
                        }}
                    />
                    <div className='col-md-4'>
                        <h5
                            className='small-font-size'
                        >Resultado Não Operacional</h5>            
                    </div>
                    <div className='col-md-4'>
                        {/* <h5 className='small-font-size'>{this.getTableHeaders().periodo}</h5> */}
                    </div>
                    <div className='col-md-2'>
                    </div>
                    {renderExportarButton()}
                </div>
                
                {/* <div className='row'>
                <div className='col-md-4'>
                    <Button label="Exportar" icon="pi pi-file-excel" className="p-button-success" onClick={this.exportExcel}
                        tooltip="Exportar em excel"
                        tooltipOptions={{position: 'top'}}
                    />
                </div>
                </div> */}
                
            </div>
            </div>

            {renderTable()}

            <DREFichaBancariaDialog
                visible={this.state.displayFichaBancariaDialog}
                initialDate={this.state.elementToView ? this.state.elementToView.descricaoMes.initialDate : ''}
                finalDate={this.state.elementToView ? this.state.elementToView.descricaoMes.finalDate : ''}
                element={this.state.elementToView}
                unificarEmpresas={this.props.unificarEmpresas}
                selectedEmpresas={this.props.selectedEmpresas}
                forceUpdate={this.state.forceUpdateDialog}
                doneForceUpdate={() => this.setState({forceUpdateDialog: false})}
                // fichaBancaria={this.state.fichaBancariaToView}
                search={this.props.search}
                recebimento={this.state.elementToView ? this.state.elementToView.tipo === ConstantsUtil.dreDestacadoEntrada : false}
                saveAsExcelFile={this.props.saveAsExcelFile}
                loadingTable={false}
                hideDialog={this.hideDialog}
                push={this.props.push}
            />

            </>
        )
    }


}  

export default DREDestacadoConsolidadoTable