import React from 'react'

import { Dialog } from 'primereact/dialog'
import DialogFooter from '../dialogFooter'

import ContasBancariasTable from './contasBancariasTable'
import AplicacaoFinanceiraTable from './aplicacaoFinanceiraTable'
import aplicacaoFinanceira from '../../views/conciliacaoBancaria/aplicacaoFinanceira'
import GeneralServices from '../../app/service/generalServices'

class ChooseAplicacaoFinanceiraDialog extends React.Component {

    constructor(){
        super();
        this.generalServices = new GeneralServices();
    }

    state = {

        selectedAplicacaoFinanceira: '',
        aplicacaoFinanceiraList: [],
        filteredAplicacaoFinanceiraList: [],

        searchText: '',

        didUpdated: false,
        forceUpdate: false

    }

    componentDidUpdate(){
        this.setStates()
    }

    setStates = () => {
        if( (this.props.visible && !this.state.didUpdated) || this.props.forceUpdate ){
        this.setState({searchText: ''})
        this.setState({aplicacaoFinanceiraList: this.props.list})
        this.setState({filteredAplicacaoFinanceiraList: this.props.list})

        if(this.props.doneForceUpdate){
            this.props.doneForceUpdate()
        }
        this.setState({forceUpdate: true})
        this.setState({didUpdated: true})
        }
    }

    handleChange = async (event) => {
        const value = event.target.value
        const name = event.target.name
        await this.setState({ [name]: value })
        this.filter()
    }

    handleKeyPress = async (e) => {
        if (e.key === "Enter") {
            const filteredList = JSON.parse(JSON.stringify(this.state.filteredAplicacaoFinanceiraList))
            if(filteredList.length === 1){
                await this.setState({selectedAplicacaoFinanceira: filteredList[0]})
                this.callSave()
            }
          }
    }

    handleSelectionChange = (value) => {
        if(value === null){ //significa que clicou em cima da Conta Bancária que estava selecionada antes (double click)
            this.callSave()
        } else{
            this.setState({selectedAplicacaoFinanceira: value})
        }
    }

    hideDialog = () => {
        this.setState({didUpdated: false})
        this.props.hideDialog()
    }

    callSave = async () => {
        await this.props.handleSelectionChange(this.state.selectedAplicacaoFinanceira)
        this.hideDialog()
    }

    filter  = async () => {
        
        await this.setState({
            filteredAplicacaoFinanceiraList:
                JSON.parse(
                    JSON.stringify(
                        this.state.aplicacaoFinanceiraList.filter(aplicacaoFinanceira => {
                            return aplicacaoFinanceira.nome.toLowerCase().includes(this.state.searchText.toLowerCase())
                        }
                        )
                    )
                )
        })

        this.setState({forceUpdate: true})

    }

    doneForceUpdate = async () =>{
        this.generalServices.sleep(100)
        this.setState({forceUpdate: false})
    }

    render () {

        const chooseContaBancariaDialogFooter = (
            <DialogFooter save = {this.callSave} hideDialog = {this.hideDialog} disabled={this.props.disabled}/>
        )

        return (
            <>
            <Dialog 
                    visible={this.props.visible}
                    style={{ width: '70vw' }}
                    header={this.props.header ? this.props.header : 'Aplicação Financeira'}
                    modal
                    className="p-fluid"
                    footer={chooseContaBancariaDialogFooter}
                    onHide={this.hideDialog}
            >

                <div className="p-field">
                    <label htmlFor="search conta">Busca</label>
                    <input style={{color: '#000'}}
                        autoFocus  
                        className={"form-control " }
                        type="text"
                        id="searchText"
                        name="searchText"
                        value={this.state.searchText}
                        placeholder="Pesquise pelo nome"
                        onChange = {this.handleChange}
                        onKeyDown={this.handleKeyPress}
                    />
                </div>

                <br />

                <AplicacaoFinanceiraTable
                    // list = { this.filterByName() }
                    list = { this.state.filteredAplicacaoFinanceiraList }
                    selectable
                    updateList={this.updateList}
                    searchNome={this.state.searchText}
                    handleSelectionChange={this.handleSelectionChange}
                    // deleteMultiple = {this.deleteMultipleTiposDeRecebimento}
                    push={this.props.push}
                    loading = {this.state.loading}
                    forceUpdate={this.state.forceUpdate}
                    doneForceUpdate={this.doneForceUpdate}
                    // disableDeleteButton = {this.state.disableDeleteButton || this.props.disableDeleteButton}
                />


            </Dialog>
            </>
        )
    }


}

export default ChooseAplicacaoFinanceiraDialog