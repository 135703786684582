import React from 'react'
import { withRouter } from 'react-router-dom'

import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button'

import { AuthContext } from '../main/authProvider'

import PDVCaixaService from '../app/service/contasAReceber/pdvCaixaService'
import Card from '../components/card'
import * as popUp from '../components/toastr'
import HandleErrorService from '../app/service/handleErrorService'
import SelectMenu from '../components/selectMenu'
import FornecedorService from '../app/service/contasAPagar/fornecedorService'
import FornecedoresTable from '../components/fornecedores/fornecedoresTable'
import GeneralServices from '../app/service/generalServices';
import CadastrarFornecedorComponent from './cadastrarFornecedorComponent';


class Fornecedor extends React.Component{

    constructor(){
        super();
        // this.generalServices = new GeneralServices();
        this.pdvCaixaService = new PDVCaixaService();
        this.fornecedorService = new FornecedorService();
        this.generalServices = new GeneralServices();
        this.inputNome = React.createRef();
    }    

    state = {

        fornecedorList: [],
        filteredFornecedorList: [],

        nome: '',
        inputNomeErrorClass: '',
        errorNomeMessage: 'Informe o nome',

        cnpj: '',
        inputCNPJErrorClass: '',
        errorCNPJMessage: '',

        selectedTipoFornecedor: '',
        inoutTipoFornecedorErroClass: '',
        errorTipoFornecedorMessage: 'Informe o tipo de fornecedor',

        loading: false,
    }

    componentDidMount(){
        this.search()
    }

    search = () => {

        this.setState({loading: true})

        this.fornecedorService.search(false)
        .then(response => {
            // const list = response
            this.setState({fornecedorList: response.data})
            this.setState({filteredFornecedorList: JSON.parse(JSON.stringify(response.data))})
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
        .finally(() => {
            this.setState({loading: false})
        })
    }

    filter = () => {
        this.setState({
            filteredFornecedorList:
                JSON.parse(
                    JSON.stringify(
                        this.state.fornecedorList.filter(fornecedor => {
                            const nome = this.state.cnpj === '' && fornecedor.nome.toLowerCase().includes(this.state.nome.toLowerCase())
                            const cnpj = this.state.nome === '' && fornecedor.cnpj.toLowerCase().includes(this.state.cnpj.toLowerCase()) 
                            return (nome || cnpj)
                        }
                        )
                    )
                )
        })
    }

    handleChange = async (event) => {
        const value = event.target.value
        const name = event.target.name
        await this.setState({[name]: value})
        this.filter()
    }

    handleKeyPress = async (e) => {
        await this.generalServices.sleep(1) //essa função é chamada no mesmo instante que a handleChange.
                                            //Precisa esperar um tempo de forma que o valor seja alterado.
        //it triggers by pressing the enter key
        if (e.key === "Enter") {
            this.callSave();
        }
    }

    resetForm = () => {
        this.setState({nome: ''})
        this.setState({cnpj: ''})
        this.setState({selectedTipoFornecedor: ''})
    }

    resetView = () => {
        this.setState({inputNomeErrorClass: ''})

        this.setState({inputCNPJErrorClass: ''})
        this.setState({errorCNPJMessage: ''})

        this.setState({inputTipoFornecedorErrorClass: ''})
    }

    checkData = () => {
        var check = true

        if(!this.state.nome){
            this.setState({inputNomeErrorClass: "is-invalid"})
            check = false
        }

        if(!GeneralServices.validarCPFOuCNPJ(this.state.cnpj)){
            this.setState({inputCNPJErrorClass: "p-invalid"})
            this.setState({errorCNPJMessage: 'CPF ou CNPJ inválido'})
            check = false
        }

        if(!this.state.selectedTipoFornecedor){
            this.setState({inputTipoFornecedorErrorClass: 'is-invalid'})
            check = false
        }

        return check

    }

    callSave = () => {

        this.resetView()

        if(this.checkData()){
            this.save()
        }
    }

    save = () => {

        this.setState({loading: true})

        this.setState({isSavingNewFornecedor: true})
            this.fornecedorService.save(
                {
                    nome : this.state.nome.trim().toUpperCase(),
                    cnpj : this.state.cnpj.trim().replace(/[^\d]+/g,''),
                    tipoFornecedor: this.state.selectedTipoFornecedor
                }
            )
            .then(response => {
                popUp.successPopUp("Fornecedor cadastrado com sucesso!")
                this.resetForm()
                this.search()
                this.inputNome.current.focus()
            })
            .catch(error => {
                HandleErrorService.handleError(error)
                
            })
            .finally(() => {
                this.setState({loading: false})
                this.setState({isSavingNewFornecedor: false})
            })
    }

    render(){
        return(

            <div className="bs-docs-section" >
            <Card title = "Cadastrar Fornecedores">
                
            <div className = "col-md-12">
            
                <CadastrarFornecedorComponent
                    push={this.props.history.push}
                />
            
            </div>

            </Card>
            <div className="d-flex "/>
            </div>
        )
    }

}

Fornecedor.contextType = AuthContext
export default withRouter( Fornecedor )