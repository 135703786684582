import CentralApiService from "../../centralApiServices";


class EmpresaService extends CentralApiService {

    constructor() {
        super('/api/empresa')
    }

    getAllEmpresas(){
        return this.get('/empresas')
    }

    getLoggedEmpresas(){
        return this.get('/loggedEmpresas')
    }

    createOrUpdate(empresa){
        return this.post("/createOrUpdate", empresa)
    }
    
    changeStatus(empresa){
        return this.put("/changeStatus", empresa)
    }

    buscaDadosSyncNFsVenda(empresa){
        let params = `?`
        params = `${params}&empresaId=${empresa.id}`
        return this.get(`/buscaDadosSyncNFsVenda${params}`)
    }

    criaDadosSyncNFsVenda(dados){
        return this.post(`/criaDadosSyncNFsVenda`, dados)
    }

    runSyncNFsVenda(dataInicial, dataFinal){
        let params = `?`
        params = `${params}&dataInicial=${dataInicial}`
        params = `${params}&dataFinal=${dataFinal}`
        return this.get(`/runSyncNFsVenda${params}`)
    }

}

export default EmpresaService