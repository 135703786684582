import { addLocale } from 'primereact/api'
import { Calendar } from 'primereact/calendar'
import { Dropdown } from 'primereact/dropdown'
import React from 'react'
import GeneralServices from '../../app/service/generalServices'

class CustomCalendar extends React.Component{


    handleNavigatorDateChange = (event, e) => {
        e.onChange(event.originalEvent, event.value)
    }

    render() {

        const monthNavigatorTemplate = (e) => {
            return  (
                <>
                    <Dropdown
                        value={e.value}
                        options={e.options}
                        // onChange={(event) => e.onChange(event.originalEvent, event.value)}
                        onChange={event => this.handleNavigatorDateChange(event, e)}
                        style={{ lineHeight: 1 }}
                    />
                </>
            )
        }

        const yearNavigatorTemplate = (e) => {
            return <Dropdown
                        value={e.value}
                        options={e.options}
                        // onChange={(event) => e.onChange(event.originalEvent, event.value)}
                        onChange={event => this.handleNavigatorDateChange(event, e)}
                        style={{ lineHeight: 1 }}
                    />
        }

        addLocale('pt-BR', {
            firstDayOfWeek: 0,
            dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
            dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
            dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
            monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
            monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
            today: 'Hoje',
            clear: 'Limpar'
        })

        const className = this.props.noHTMLFormat ? '' : "col-md-2"

        return (
            <div className = {className}>
            <label htmlFor={this.props.htmlFor}>{this.props.label}</label>
            <Calendar
                ref={this.props.innerRef}
                id={this.props.id}
                className={this.props.inputDateErrorClass}
                value={this.props.value}
                name={this.props.name}
                onChange={this.props.onChange}
                disabled={this.props.disabled}
                showIcon
                showButtonBar
                locale="pt-BR"
                numberOfMonths={this.props.showMultipleMonths ? 2 : 1}
                dateFormat="dd/mm/yy"
                mask="99/99/9999"
                monthNavigator={!this.props.showMultipleMonths}
                yearNavigator
                yearRange={GeneralServices.yearRange()}
                monthNavigatorTemplate={monthNavigatorTemplate}
                yearNavigatorTemplate={yearNavigatorTemplate}
            />
            <small id="dateErrorNotice" className="p-error">{this.props.errorDateMessage}</small>
            </div>
        )
    }

}

export default React.forwardRef((props, ref) => <CustomCalendar innerRef={ref} {...props} />);