import React from 'react'
import Highlighter from 'react-highlight-words'
import GeneralServices from '../app/service/generalServices'

class CustomHighlighter extends React.Component{

    render(){
        return(
            <Highlighter
                highlightClassName="highlight-search-text"
                searchWords={this.props.searchWords.map(item => GeneralServices.replaceSpecialCharacters(item))}
                autoEscape={true}
                textToHighlight={GeneralServices.replaceSpecialCharactersForSeach(this.props.textToHighlight, this.props.searchWords)}
            />
        )
    }

}

export default CustomHighlighter