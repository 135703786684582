import React from 'react'
import { Dialog } from 'primereact/dialog'
import AnotacaoService from '../app/service/central/anotacaoService'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import TooltipButton from './tooltipButton'
import GeneralServices from '../app/service/generalServices'
import ConfirmationDialog from './confirmationDialog'
import * as popUp from './toastr'
import HandleErrorService from '../app/service/handleErrorService'
import { InputTextarea } from 'primereact/inputtextarea'
import ConstantsUtil from '../context/constantsUtil'
import AuthService from '../app/service/authService'

class AnotacoesDialog extends React.Component {
    
    constructor(){
        super()
        this.inputTextoAnotacaoRef = React.createRef()
        this.anotacaoService = new AnotacaoService()
    }

    state = {

        anotacaoToEdit: null,
        displayAdicionaAnotacoesDialog: false,


        isDeleteAll: false,
        anotacaoToDelete: null,
        displayConfirmationDeleteDialog: false,

        inputTextoAnotacaoErrorClass: '',

        didUpdated: false,
    }

    componentDidUpdate(){
        if(this.props.visible && !this.state.didUpdated){
            // console.log("componentDidUpdate")
            this.setState({displayAdicionaAnotacoesDialog: this.props.creationMode})
            this.setState({didUpdated: true})
        }
    }

    getNomeTipoDePagamento = () => {
        // console.log("getNomeTipoDePagamento: ", this.props.fichaDePagamentoItemToViewAnotacoes)
        return this.props.fichaDePagamentoItemToViewAnotacoes
            && this.props.fichaDePagamentoItemToViewAnotacoes.tipoDePagamento ?
            this.props.fichaDePagamentoItemToViewAnotacoes.tipoDePagamento.nome
            : ""
    }

    getDataDePagamento = () => {
        // console.log("getDataDePagamento: ", this.props.fichaDePagamentoItemToViewAnotacoes)
        return this.props.fichaDePagamentoItemToViewAnotacoes ?
            this.props.fichaDePagamentoItemToViewAnotacoes.dataPagamentoString
            : ""
    }

    edit = async anotacao => {
        await this.setState({anotacaoToEdit: anotacao})
        await this.setState({displayAdicionaAnotacoesDialog: true})
        this.inputTextoAnotacaoRef.current.value = anotacao.texto
    }
    
    hideAnotacoesViewDialog = async () => {
        await this.setState({anotacaoToEdit: null})
        this.props.hideAnotacoesViewDialog()
        this.setState({didUpdated: false})
    }

    hideAdicionarAnotacaoDialog = async () => {
        await this.setState({displayAdicionaAnotacoesDialog: false})
        await this.setState({anotacaoToEdit: null})
        this.props.hideAdicionarAnotacaoDialog()
        this.setState({didUpdated: false})

    }

    hideConfirmationDeleteDialog = () => {
        this.setState({isDeleteAll: false})
        this.setState({displayConfirmationDeleteDialog: false})
    }

    callDeleteAll = () => {
        // console.log("callDelete: ", anotacao)
        this.setState({isDeleteAll: true})
        this.setState({displayConfirmationDeleteDialog: true})
    }

    callDelete = (anotacao) => {
        // console.log("callDelete: ", anotacao)
        this.setState({anotacaoToDelete: anotacao})
        this.setState({displayConfirmationDeleteDialog: true})
    }

    handleDelete = () => {
        if(this.state.isDeleteAll){
            this.deleteAll()
        }
        else{
            this.delete()
        }
    }

    deleteAll = () => {
        // console.log("deleteAll: ", this.props.fichaDePagamentoItemToViewAnotacoes.anotacoes)
        AuthService.startBlockUi()
        this.setState({displayConfirmationDeleteDialog: false})
        this.anotacaoService.deleteAllAnotacao(this.props.fichaDePagamentoItemToViewAnotacoes.anotacoes)
        .then(response => {
            popUp.successPopUp("Anotações deletadas com sucesso!")
            if(this.props.research){
                this.props.research()
            }
            this.hideAnotacoesViewDialog()
        })
        .catch(error => {
            HandleErrorService.handleError(error)
            AuthService.stopBlockUi()
        })
        .finally(() => {
            this.setState({isDeleteAll: false})
        })
    }

    delete = () => {
        AuthService.startBlockUi()
        this.setState({displayConfirmationDeleteDialog: false})
        this.anotacaoService.deleteAnotacao(this.state.anotacaoToDelete)
        .then(response => {
            popUp.successPopUp("Anotação deletada com sucesso!")
            if(this.props.research){
                this.props.research()
            }
            this.hideAnotacoesViewDialog()
        })
        .catch(error => {
            HandleErrorService.handleError(error)
            AuthService.stopBlockUi()
        })
    }

    resetView = () => {
        this.setState({inputTextoAnotacaoErrorClass: ''})
    }

    checkTextoAnotacao = () => {
        let check = true
        if(!this.inputTextoAnotacaoRef.current.value){
            this.setState({inputTextoAnotacaoErrorClass: ConstantsUtil.primereactInputErrorClass})
            check = false
        }
        return check
    }

    saveAnotacao = () => {
        
        this.resetView()

        if(this.checkTextoAnotacao()){
            AuthService.startBlockUi()
            this.setState({displayAdicionaAnotacoesDialog: false})
            if(this.state.anotacaoToEdit){
                this.editAnotacao()
            }
            else{
                this.createAnotacao()
            }
        }

    }

    editAnotacao = () => {
        // console.log("this.state.anotacaoToEdit: ", this.state.anotacaoToEdit)
        let obj = {
            id: this.state.anotacaoToEdit.id,
            texto: this.inputTextoAnotacaoRef.current.value,
            tipoRecurso: ConstantsUtil.fichaDePagamentoRecurso,
            dataNoRecurso: this.state.anotacaoToEdit.dataNoRecurso,
            nomeNoRecurso: this.state.anotacaoToEdit.nomeNoRecurso
        }
        // console.log("obj: ", obj)
        this.anotacaoService.edit(obj)
        .then(response => {
            popUp.successPopUp("Anotação editada com sucesso!")
            this.hideAdicionarAnotacaoDialog()
            this.hideAnotacoesViewDialog()
            this.props.research()
        })
        .catch(error => {
            HandleErrorService.handleError(error)
            AuthService.stopBlockUi()
        })
    }

    createAnotacao = () => {
        
        // console.log('this.props.fichaDePagamentoItemToViewAnotacoes: ', this.props.fichaDePagamentoItemToViewAnotacoes)
        let fichaDePagamentoItem = !GeneralServices.isEmptyObject(this.props.fichaDePagamentoItemToViewAnotacoes) ? 
            this.props.fichaDePagamentoItemToViewAnotacoes
            : this.props.fichaDePagamentoItemAdicionarAnotacao

        // console.log("fichaDePagamentoItemFinal: ", fichaDePagamentoItem)

        let obj = {
            texto: this.inputTextoAnotacaoRef.current.value,
            tipoRecurso: ConstantsUtil.fichaDePagamentoRecurso,
            dataNoRecurso: fichaDePagamentoItem.dataPagamentoString,
            nomeNoRecurso: fichaDePagamentoItem.tipoDePagamento.nome
        }

        this.anotacaoService.create(obj)
        .then(response => {
            popUp.successPopUp("Anotação adicionada com sucesso!")
            this.hideAdicionarAnotacaoDialog()
            this.hideAnotacoesViewDialog()
            this.props.research()
        })
        .catch(error => {
            HandleErrorService.handleError(error)
            AuthService.stopBlockUi()
        })

    }

    render(){

        const renderConfirmationDialog = () => {
            return (
                <ConfirmationDialog
                    header={"Excluir Anotação"}
                    visible={this.state.displayConfirmationDeleteDialog}
                    confimationMessage={`Deseja realmente excluir ${this.state.isDeleteAll ? 'todas as anotações' : 'esta anotação'} ?`}
                    confirm={this.handleDelete}
                    width={'40%'}
                    hide={this.hideConfirmationDeleteDialog}
                />
            )
        }

        const actionBody = (anotacao) => {
            return (
                <React.Fragment>
                    <TooltipButton
                        tooltip="Editar"
                        icon="pi pi-pencil"
                        onClick={() => this.edit(anotacao)}
                        // style={ {maxHeight: '30px', maxWidth: '30px'} }
                    />
                    <TooltipButton
                        tooltip="Excluir"
                        icon="pi pi-times"
                        className="p-button-rounded p-mr-2 p-button-danger right-button" 
                        onClick={() => this.callDelete(anotacao)}
                        // style={ {maxHeight: '30px', maxWidth: '30px'} }
                    />
                </React.Fragment>
            );
        }

        const getTextoTratado = (anotacao) => {
            let textoTratadoList = []
            if(anotacao && anotacao.texto){
                textoTratadoList = anotacao.texto.split("\n")
            }
            // console.log("textoTratadoList: ", textoTratadoList, anotacao.texto)
            let componentsList = []
            textoTratadoList.forEach(textoTratado => componentsList.push(renderTextoLine(textoTratado)))
            return componentsList
        }

        const renderTextoLine = texto => {
            return (
                <div>{texto}<br/></div>
            )
        }

        const renderTable = () => {
            // console.log("this.props.fichaDePagamentoItemToViewAnotacoes: ", this.props.fichaDePagamentoItemToViewAnotacoes)
            return (
                <div className="card">
                <DataTable
                    value={this.props.fichaDePagamentoItemToViewAnotacoes.anotacoes}
                    className="p-datatable-sm small-font"
                    rowHover
                    emptyMessage="Não há anotações."
                    showGridlines
                    dataKey="id"
                >

                    <Column
                        header="Data da Anotação"
                        field="dataCriacao"
                        style ={ {width: '90px'} }
                    />

                    <Column
                        header="Anotação"
                        field="texto"
                        // body = { (rowData, rowIndex) => GeneralServices.renderBodyColumnInOneLine(this.getTextoTratado(rowData), rowIndex)}
                        body={rowData => getTextoTratado(rowData)}
                        style ={ {width: '450px'} }
                    />

                    <Column
                        header="Usuário"
                        field="user.name"
                        style ={ {width: '50px'} }
                        body = { (rowData, rowIndex) => GeneralServices.renderBodyColumnInOneLine(rowData.user.name, rowIndex)}
                    />

                    <Column
                        header="Editar"
                        body={actionBody}
                        style ={ {width: '60px'} }
                    />

                </DataTable>
                </div>
            )
        }

        const renderConfirmationAnotacaoFooter = () => {
            return (
                <div>
                    <Button label="Confirmar" icon="pi pi-check"
                            onClick={this.saveAnotacao} autoFocus />
                    <Button label="Cancelar" icon="pi pi-times" onClick={this.hideAdicionarAnotacaoDialog}
                            className="p-button-text" />
                </div>
            );
        } 

        const renderAdicionaAnotacaoDialog = () => {
            return (
                <Dialog
                    header={"Escreva a anotação"}
                    visible={this.state.displayAdicionaAnotacoesDialog}
                    modal
                    style={{ width: '50%' }}
                    onHide={this.hideAdicionarAnotacaoDialog}
                    footer={renderConfirmationAnotacaoFooter()}
                >
                    <div 
                        style={{marginTop: '1rem'}}
                    >
                        <InputTextarea
                            ref={this.inputTextoAnotacaoRef}
                            className={this.state.inputTextoAnotacaoErrorClass}
                            rows={3} cols={100}
                            autoFocus
                            autoResize
                            placeholder={"Escreva a anotação"}
                        />
                    </div>
                    <small className="p-error">{this.state.inputTextoAnotacaoErrorClass ? "Digite o texto da anotação": ""}</small>

                </Dialog>
            )
        }

        return (
            <>
                <Dialog
                    header={`Anotações - ${this.getNomeTipoDePagamento()} - Pagamento ${this.getDataDePagamento()}`}
                    visible={this.props.visible && !this.props.creationMode}
                    modal
                    style={{ width: '90vw' }}
                    onHide={this.hideAnotacoesViewDialog}
                >
                    <Button 
                        label="Adicionar Anotação"
                        icon="pi pi-plus"
                        onClick = {() => this.setState({displayAdicionaAnotacoesDialog: true})}
                        style={ {maxHeight: '25px', marginBottom: '10px' } }
                    />                  
                    <Button 
                        label="Deletar Todas"
                        icon="pi pi-trash"
                        className='p-button-danger alinha-direita-div'
                        onClick = {this.callDeleteAll}
                        style={ {maxHeight: '25px', marginBottom: '10px'} }
                    />                  
                    {renderTable()}

                </Dialog>

                {renderAdicionaAnotacaoDialog()}

                {renderConfirmationDialog()}
            </>
        )
    }
    

}

export default AnotacoesDialog