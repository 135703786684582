import React from 'react'
import HandleErrorService from '../../app/service/handleErrorService'
import ProductService from '../../app/service/productService'
import ChooseProductDialog from './chooseProductDialog'
import BuyProductService from '../../app/service/buyProductService'
import { ProgressSpinner } from 'primereact/progressspinner'
import { Dialog } from 'primereact/dialog'

class InputChooseProduct extends React.Component {

    constructor(){
        super()
        this.productService = new ProductService()
        this.buyProductService = new BuyProductService()
    }

    state = {

        productList: [],
        chooseProductDialogVisible: false,
        loading: false,
        isFinishedSearch: false,
        
    }

    componentDidMount(){
        this.searchProducts()
    }

    searchProducts = () => {

        if(this.props.productList){
            this.setState({isFinishedSearch: true})
            return
        }

        if(this.props.fornecedor){
            this.searchProdutosCompra()
        }
        else if(!this.props.disabled) {
            this.searchProdutosProprios()
        }
    }

    searchProdutosCompra = () => {
        this.setState({isFinishedSearch: false})
        this.setState({loading: true})
        this.buyProductService.searchBuyProductByFornecedor(this.props.fornecedor.id)
        .then(async response => {
            var productList = response.data
            await this.setState({productList})
            this.setState({isFinishedSearch: true})
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
        .finally(() => {
            this.setState({loading: false})
        })
    }

    searchProdutosProprios = () => {
        this.setState({isFinishedSearch: false})
        this.setState({loading: true})
        this.productService.search()
        .then(async response => {
            var productList = response.data
            await this.setState({productList})
            this.setState({isFinishedSearch: true})
        }).catch(error => {
            HandleErrorService.handleError(error)
        })
        .finally(() => {
            this.setState({loading: false})
        })        
        
    }

    hideProductDialog = () => {
        this.setState({chooseProductDialogVisible: false})
        
        if(this.props.doNotForceContaBancariaDialog)
            this.props.doNotForceContaBancariaDialog()
    }

    handleChange = (value) => {
        this.props.handleSelectionChange(value)
    }

    handleKeyPress = async (e) => {
        if (e.key === "Enter") {
            this.inputClick()
          }
    }

    inputClick = () => {
        this.setState({chooseProductDialogVisible: true})
    }

    render(){

        const renderInput = () => {
            
            return (
                <>
                <label htmlFor="product">{this.props.label ? this.props.label : 'Produto'}</label>
                    <input 
                        type="text"
                        className={"form-control " + this.props.inputProductErrorClass}
                        readOnly
                        value = {this.props.selectedProductLabel ? this.props.selectedProductLabel : ''}
                        // name="selectedProductLabel"
                        id="selectedProduct"
                        placeholder="Nenhum Produto Selecionado..."
                        onClick={this.inputClick}
                        onKeyPress={this.handleKeyPress}
                        disabled={this.props.disabled}
                    />
                    <div className="invalid-feedback">
                        {this.props.errorProductMessage ? this.props.errorProductMessage : "Informe o Produto"}
                    </div>
                </>
            )
        }

        const renderHTMLFormat = () => {
            if(this.props.noHTMLFormat){
                return (
                    <>
                    {renderInput()}
                    </>
                )
            }
            else {
                return (
                    <>
                    <div className="row">
                    <div className="col-md-4">
                    {renderInput()}
                    </div>
                    </div>
                    </>
                )
            }
        }

        const renderBody = () => {
            if(this.state.isFinishedSearch){
                return(
                    <>
                        <ChooseProductDialog
                            header={this.props.label ? this.props.label : 'Produto'}
                            visible={this.state.chooseProductDialogVisible || this.props.forceShowDialog}
                            list={this.props.productList ? this.props.productList : this.state.productList}
                            hideDialog={this.hideProductDialog}
                            handleSelectionChange={this.handleChange}
                            isBuyProducts={this.props.isBuyProducts}
                            searchProdutosCompra={this.props.searchProdutosCompra}
                            forceUpdate={this.props.forceUpdate}
                            doneForceUpadte={this.props.doneForceUpadte}
                            fornecedor={this.props.fornecedor}
                        />
                    </>
                )
            }
            else{
                return (
                    <Dialog
                        visible={this.state.chooseProductDialogVisible}
                        onHide={this.hideProductDialog}
                    >
                        <ProgressSpinner />
                    </Dialog>                      
                )                
            }
        }            



        return(
            <>
                {renderHTMLFormat()}
                {renderBody()}
            </>
        )

    }


}

export default InputChooseProduct