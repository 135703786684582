import React from 'react'

import { Dialog } from 'primereact/dialog'
import DialogFooter from '../dialogFooter'

import * as popUp from '../../components/toastr'
import HandleErrorService from '../../app/service/handleErrorService'
import { Autocomplete, TextField } from '@mui/material'
import ContaBancariaService from '../../app/service/conciliacaoBancaria/contaBancariaService'
import ConstantsUtil from '../../context/constantsUtil'

class EditContaBancariaDialog extends React.Component {
    
    constructor(){
        super();
        this.contaBancariaService = new ContaBancariaService()
    }

    state = {

        id: null,

        nomeTipoDenomeBanco: '',
        inputContaBancariaErrorClass: false,
        errorContaBancariaMessage: '',
        
        numConta: '',
        inputNumContaErrorClass: false,
        errorNumContaMessage: '',
        
        numDigitoConta: '',
        inputnumDigitoContaErrorClass: false,
        errornumDigitoContaMessage: '',

        numAgencia: '',
        inputNumAgenciaErrorClass: false,
        errorNumAgenciaMessage: '',

        descricao: '',
        
        didUpdated: false,
    }


    componentDidUpdate(){
        this.setStates()
    }

    setStates = () => {
        if( (this.props.visible && !this.state.didUpdated) || this.props.forceUpdate ){
        this.setState({id: this.props.state.id})
        this.setState({descricao: this.props.state.descricao})
        this.setState({nomeBanco: this.props.state.nomeBanco})
        this.setState({numConta: this.props.state.numConta})
        this.setState({numDigitoConta: this.props.state.numDigitoConta})
        this.setState({numAgencia: this.props.state.numAgencia})

        this.resetView()
            
        this.setState({didUpdated: true})
        if(this.props.doneForceUpdate){
            this.props.doneForceUpdate()
        }
        }
    }

    handleMuiChange = async (event, value) => {
        const realValue = value ? value : ''
        await this.setState({ nomeBanco: realValue })
    }

    handleKeyUp = (event) => { //melhor forçar salvamento pelo botão
        // if(event.code === "Enter" || event.code === "NumpadEnter"){
        //     this.callSave()
        // }
    }
    
    handleDigitoChange = (event) => {
        const value = event.target.value
        const name = event.target.name
        if(value >= 0 &&  value < 10){
            this.setState({[name]: value})
        }
    }

    handleChange = (event) => {
        const value = event.target.value
        const name = event.target.name
        this.setState({ [name]: value })
    }

    hideDialog = () => {
        this.setState({didUpdated: false})
        this.props.hideDialog()
    }

    resetView = () => {
        this.setState({inputContaBancariaErrorClass: false})
        this.setState({errorContaBancariaMessage:''})

        this.setState({inputNumContaErrorClass: false})
        this.setState({errorNumContaMessage:''})

        this.setState({inputnumDigitoContaErrorClass: false})
        this.setState({errornumDigitoContaMessage:''})

        this.setState({inputNumAgenciaErrorClass: false})
        this.setState({errorNumAgenciaMessage:''})
    } 
    
    checkData = () => {
        var check = true

        if(!this.state.nomeBanco){
            this.setState({inputContaBancariaErrorClass: true})
            this.setState({errorContaBancariaMessage:'É necessário escolher o Banco'})
            check = false
        }

        if(!this.state.numConta){
            this.setState({inputNumContaErrorClass: ConstantsUtil.bootstrapInputErrorClass})
            this.setState({errorNumContaMessage:'Número da conta é obrigatório'})
            check=false
        }
        
        if(!this.state.numDigitoConta && parseInt(this.state.numDigitoConta) !== 0){
            this.setState({inputnumDigitoContaErrorClass: "is-invalid"})
            this.setState({errornumDigitoContaMessage:'Dígito é obrigatório'})
            check=false
        }

        if(!this.state.numAgencia){
            this.setState({inputNumAgenciaErrorClass: "is-invalid"})
            this.setState({errorNumAgenciaMessage:'Agência é obrigatória'})
            check=false
        }

        return check
    }

    callSave = () => {
        this.resetView()
        if(this.checkData()){
            this.save()
        }
    }
    
    save = () => {
        this.contaBancariaService.update(
        {
            id: this.state.id,
            nomeBanco: this.state.nomeBanco,
            numConta: this.state.numConta,
            numDigitoConta: this.state.numDigitoConta,
            numAgencia: this.state.numAgencia,
            descricao: this.state.descricao.trim()
        }
        )
        .then(response => {
            popUp.successPopUp("Conta bancária alterada com sucesso")
            this.props.search()
            this.hideDialog()
            this.setState({didUpdated: false})
        }).catch(error => {
            HandleErrorService.handleError(error)
        })
        this.setState({didUpdated: false})
    }
    
    render () {

        const editTipoRecebimentoDialogFooter = (
            <DialogFooter save = {this.callSave} hideDialog = {this.hideDialog} disabled={this.props.disabled}/>
        )

        return (
            <>
            <Dialog visible={this.props.visible} style={{ width: '450px' }}
                    header={this.props.header}
                    modal
                    className="p-fluid"
                    footer={editTipoRecebimentoDialogFooter}
                    onHide={this.hideDialog}
            >
                <div className="p-field">
                <label htmlFor="banco">Banco</label>
                    <Autocomplete
                        disablePortal
                        id="combo-box-tipo-de-recebimento"
                        value={this.state.nomeBanco}
                        options={this.contaBancariaService.getAllContasBancarias()}
                        size="small"
                        // style={{marginTop: '5px'}}
                        onChange={this.handleMuiChange}
                        onKeyUp={this.handleKeyUp}
                        disabled
                        renderInput={(params) => 
                            <TextField
                                // required
                                error={this.state.inputContaBancariaErrorClass}
                                helperText={this.state.errorContaBancariaMessage}
                                {...params}
                                label=" "
                            />
                        }
                        />
                </div>
                
                <br/>

                <div className="p-field">
                    <label htmlFor="descricaoEdit">Descrição</label>
                    <input style={{color: '#000'}}   
                        className={"form-control " }
                        type="text"
                        id="descricao"
                        name="descricao"
                        value={this.state.descricao}
                        // style={{marginTop: '0px', marginBottom: '0px', height: '80px'}}
                        // placeholder="Digite a descrição"
                        onChange = {this.handleChange}
                        onKeyDown={this.handleKeyUp}
                        disabled={this.props.disabled}
                    />
                    <div className="invalid-feedback">{this.state.errorNumAgenciaMessage}</div>
                </div>
                
                <br/>

                <div className="p-field">
                    <label htmlFor="prazoEdit">Agência</label>
                    <input style={{color: '#000'}}   
                        className={"form-control " + this.state.inputNumAgenciaErrorClass}
                        type="number"
                        id="numAgencia"
                        name="numAgencia"
                        value={this.state.numAgencia}
                        // style={{marginTop: '0px', marginBottom: '0px', height: '80px'}}
                        // placeholder="Digite a descrição"
                        onChange = {this.handleChange}
                        onKeyDown={this.handleKeyUp}
                        disabled={this.props.disabled}
                    />
                    <div className="invalid-feedback">{this.state.errorNumAgenciaMessage}</div>
                </div>

                <br />
                
                <div className="p-field">
                    <label htmlFor="taxa">Conta</label>
                    <input style={{color: '#000'}} type="number"
                            className={"form-control " + this.state.inputNumContaErrorClass}
                            // style={{color: '#000'}}
                            id="inputNumConta"
                            value = {this.state.numConta}
                            name="numConta"
                            onChange={this.handleChange}
                            onKeyDown={this.handleKeyUp}
                            // placeholder="Digite o Taxa"
                            disabled={this.props.disabled}
                            />
                    <div className="invalid-feedback">{this.state.errorNumContaMessage}</div>
                </div>

                <br/>
                <div className="p-field">
                    <label htmlFor="prazoEdit">Dígito</label>
                    <input style={{color: '#000'}}   
                        className={"form-control " + this.state.inputnumDigitoContaErrorClass}
                        type="number"
                        id="numDigitoConta"
                        name="numDigitoConta"
                        value={this.state.numDigitoConta}
                        // style={{marginTop: '0px', marginBottom: '0px', height: '80px'}}
                        // placeholder="Digite a descrição"
                        onChange = {this.handleDigitoChange}
                        onKeyDown={this.handleKeyUp}
                        disabled={this.props.disabled}
                    />
                    <div className="invalid-feedback">{this.state.errornumDigitoContaMessage}</div>
                </div>
                

            </Dialog>
            
            </>

        )
    }
}

export default EditContaBancariaDialog