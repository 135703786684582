import React from 'react'
import { withRouter } from 'react-router-dom'

import { TextField } from '@mui/material'
import { Button } from 'primereact/button'

import { AuthContext } from '../../../main/authProvider'

import PDVCaixaService from '../../../app/service/contasAReceber/pdvCaixaService'
import Card from '../../../components/card'
import * as popUp from '../../../components/toastr'
import HandleErrorService from '../../../app/service/handleErrorService'
import PDVCaixaTable from '../../../components/pdvCaixa/pdvCaixaTable'


class CadastrarPDVCaixa extends React.Component{

    constructor(){
        super();
        // this.generalServices = new GeneralServices();
        this.pdvCaixaService = new PDVCaixaService();
        this.inputNome = React.createRef();
    }    

    state = {

        pdvCaixaList:'',

        nomePdvCaixa: '',
        inputNomeErrorClass: false,
        errorNomeMessage: '',
        
        descricaoPdvCaixa: '',
        inputDescricaoErrorClass: false,
        errorDescricaoMessage: '',

        isSavingNewPDVCaixa: false,
    }

    componentDidMount(){
        this.search()
    }

    search = () => {
        this.pdvCaixaService.search()
        .then(response => {
            this.setState({pdvCaixaList: response.data})
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
    }

    handleChange =  (event) => {
        const value = event.target.value
        const name = event.target.name
        this.setState({[name]: value})
    }

    handleMuiKeyUp = (event) => {
        if(event.code === "Enter" || event.code === "NumpadEnter"){
            this.callSave()
        }
    }

    resetForm = () => {
        this.setState({nomePdvCaixa: ''})
        this.setState({descricaoPdvCaixa: ''})
    }

    resetView = () => {
        this.setState({inputNomeErrorClass: false})
        this.setState({errorNomeMessage: ''})

        this.setState({inputDescricaoErrorClass: false})
        this.setState({errorDescricaoMessage: ''})
    }

    checkData = () => {
        var check = true

        if(!this.state.nomePdvCaixa){
            this.setState({inputNomeErrorClass: true})
            this.setState({errorNomeMessage: 'O nome é obrigatório'})
            check = false
        }

        // if(!this.state.descricaoPdvCaixa){
        //     this.setState({inputDescricaoErrorClass: true})
        //     this.setState({errorDescricaoMessage: 'A descrição é obrigatória'})
        //     check = false
        // }

        return check

    }

    callSave = () => {

        this.resetView()

        if(this.checkData()){
            this.setState({isSavingNewPDVCaixa: true})
            this.pdvCaixaService.save(
                {
                    nome : this.state.nomePdvCaixa.trim().toUpperCase(),
                    descricao : this.state.descricaoPdvCaixa,
                }
            )
            .then(response => {
                popUp.successPopUp("Ponto de Venda (Caixa) cadastrado com sucesso!")
                this.setState({isSavingNewPDVCaixa: false})
                this.resetForm()
                this.search()
                this.inputNome.current.focus()
            })
            .catch(error => {
                HandleErrorService.handleError(error)
                this.setState({isSavingNewPDVCaixa: false})
            })
        }
    }

    render(){
        return(
            <div className="bs-docs-section" >
            <Card title = "Cadastrar Ponto de Venda (Caixa)">
                
            <div className = "col-md-12">

                <div className="row">
                
                <div className = "col-md-4">

                <div className="row">
                {/* <div className = "col-md-4"> */}
                <TextField
                    autoFocus
                    type="text"
                    size="small"
                    name="nomePdvCaixa"
                    style ={{width: '100%'}}
                    value={this.state.nomePdvCaixa}
                    onChange={this.handleChange}
                    onKeyUp={this.handleMuiKeyUp}
                    required
                    error={this.state.inputNomeErrorClass}
                    helperText={this.state.errorNomeMessage}
                    label="Nome"
                    inputRef={this.inputNome}
                />
                {/* </div> */}
                
                </div>

                <br />

                <div className="row">
                {/* <div className = "col-md-4"> */}
                <TextField
                    type="text"
                    size="small"
                    name="descricaoPdvCaixa"
                    style ={{width: '100%'}}
                    value={this.state.descricaoPdvCaixa}
                    onChange={this.handleChange}
                    onKeyUp={this.handleMuiKeyUp}
                    // required
                    error={this.state.inputDescricaoErrorClass}
                    helperText={this.state.errorDescricaoMessage}
                    label="Descrição"
                />
                {/* </div> */}
                </div>

                <br />

                <Button 
                    label="Cadastrar Caixa"
                    icon="pi pi-plus"
                    onClick = {this.callSave}
                    disabled = {this.state.isSavingNewPDVCaixa}
                />

                </div>

                <div className = "col-md-8">
                <PDVCaixaTable
                    // list = { this.filterByName() }
                    list = { this.state.pdvCaixaList}
                    // deleteMultiple = {this.deleteMultipleTiposDeRecebimento}
                    search={this.search}
                    push={this.props.history.push}
                    loading = {this.state.loading}
                />
                </div>

                </div>

            </div>

            </Card>
            <div className="d-flex "/>
            </div>
        )
    }

}

CadastrarPDVCaixa.contextType = AuthContext
export default withRouter( CadastrarPDVCaixa )