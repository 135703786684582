import React from 'react'
import SelectMenu from '../selectMenu'
import ProductService from '../../app/service/productService'
import FormGroup from '../form-group'
import ConstantsUtil from '../../context/constantsUtil'
import InputChooseProduct from '../product/inputChooseProduct'
import { InputNumber } from 'primereact/inputnumber'
import InputChooseTipoProduto from '../product/tipoProduto/inputChooseTipoProduto'

class ItensNFComponent extends React.Component {

    constructor() {
        super()
        this.productService = new ProductService()
    }


    render() {

        const renderItensLabel = () => {
            if(this.props.insideValidateNFDialog || this.props.insideEditProvisaoDialog){
                return (
                    <>
                    <br />
                    <h5>Itens</h5>
                    </>
                )
            }
        }

        const renderItens = () => {
            // console.log("this.props.NFToValidate: ", this.props.NFToValidate)
            // console.log("this.props.transactions: ", this.props.transactions)
            if( 
                
                (!this.props.transactions || this.props.transactions.length === 0) )
            {
                return 
            }

            return (
                <>
                    {renderItensLabel()}
                    {renderItensList()}
                </>
            )

        }

        const renderItensList = () => {
            let renderItemList = []
            for(let i = 0; i < this.props.transactions.length; i++){
                renderItemList.push(renderItem(i))
            }
            // console.log("renderItemList: ", renderItemList)
            return renderItemList
        }



        const renderItem = index => {
            let currentTransaction = this.props.transactions[index]
            // console.log("currentTransaction renderItem: ", currentTransaction)

            let colMdSelectProduto = 3
            let colMdTipoProduto = 3

            return (
                <div className='col-md-12'> 
                <div className='row'>
    
                <div className = {`col-md-${colMdSelectProduto}`}>
                    <InputChooseProduct
                        label={`Produto ${index+1}`}
                        readOnly
                        // excludeAplicacaoFinanceira
                        noHTMLFormat
                        productList={this.props.isNFSaida ? this.props.produtosPropriosList : this.props.produtosCompraList}
                        selectedProductLabel={currentTransaction.productInfo.descricao}
                        fornecedor={this.props.fornecedor}
                        handleSelectionChange={(selectedProduct) => this.props.handleSelectionProductChange(selectedProduct, currentTransaction, index)}
                        inputProductErrorClass={currentTransaction.inputSelectProdutoErrorClass}
                        errorProductMessage="Selecione o Produto"
                        isBuyProducts={!this.props.isNFSaida}
                        searchProdutosCompra={this.props.searchProdutosCompra}
                        forceUpdate={this.props.forceProductListUpdate}
                        doneForceUpadte={this.props.doneForceUpadte}
                        disabled={this.props.insideValidateNFDialog || this.props.insideEditProvisaoDialog || this.props.disabled}
                    />                    
                </div>
    
                <div className = {`col-md-${colMdTipoProduto}`}>
                    <InputChooseTipoProduto
                        label={`Tipo do Produto ${index+1}`}
                        noHTMLFormat
                        selectedTipoProdutoLabel={currentTransaction && currentTransaction.tipoProduto? currentTransaction.tipoProduto.descricao : ''}
                        handleSelectionChange={(selectedTipoProduto) => this.props.handleTipoProdutoChange(index, selectedTipoProduto)}
                        disabled={this.props.insideEditProvisaoDialog || this.props.isNFSaida}
                    />
                </div>

                {renderQuantidadeItem(index, currentTransaction)}

                {renderValorItem(index, currentTransaction)}

                </div>
                </div>                
            )
        }



        const renderQuantidadeItem = (index, currentTransaction) => {
            // if(!this.props.insideValidateNFDialog){
                return (
                    <div 
                        className = {`col-md-3`}
                    >
                    <FormGroup label = {`Quantidade do Produto ${index+1}`} >
                        <input 
                            type="number"
                            className={"form-control " + currentTransaction.inputQuantidadeProdutoErrorClass}
                            // name="qtdParcelas"
                            value = {currentTransaction.quantidade}
                            onChange={ event => this.props.handleQuantidadeItemLoopChange(event, index)}
                            // onKeyPress={this.handleKeypress}
                            placeholder="Digite a quantidade do Produto"
                            disabled={this.props.insideValidateNFDialog || this.props.insideEditProvisaoDialog}
                        />
                        <div className="invalid-feedback">{'Informe a quantidade de Parcelas'}</div>
                    </FormGroup>        
                    </div>
                )
            // }
        }
        
        
        const renderValorItem = (index, currentTransaction) => {
            if(!this.props.emprestimo){
                return (
                    <div 
                        className = {`col-md-3`}
                        style={{
                            maxWidth: '15rem',
                            // marginLeft: '1rem'
                        }}
                    >
                    <label htmlFor="valor">{`Valor Total do Produto ${index+1}`}</label>
                        <InputNumber 
                            className={currentTransaction.inputValorErrorClass}
                            inputId="valorFechamentoBancario"
                            value={currentTransaction.valor}
                            onValueChange={(event) => this.props.handleValorItemLoopChange(event, index)}
                            // onKeyDown={this.handleKeyPress}
                            mode="decimal" locale="pt-BR" minFractionDigits={2} maxFractionDigits={2}
                            disabled={this.props.insideValidateNFDialog || this.props.insideEditProvisaoDialog}
                        />                        
                    <div className="row">
                        <div className="col-md-12">
                        <small id="valorError" className="p-error">{currentTransaction.inputValorErrorClass ? ConstantsUtil.informeValorErrorMenssage: ""}</small>
                        </div>
                    </div>          
                    </div>
                )
            }
        }        



        return (
            <>
                {renderItens()}
            </>
        )


    }


}


export default ItensNFComponent