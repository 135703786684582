import React from 'react'

import { Dialog } from 'primereact/dialog'

import DialogFooter from '../dialogFooter'
import * as popUp from '../../components/toastr'
import HandleErrorService from '../../app/service/handleErrorService'
import PDVCaixaService from '../../app/service/contasAReceber/pdvCaixaService'

class EditPDVCaixaDialog extends React.Component {

    constructor(){
        super()
        this.pdvCaixaService = new PDVCaixaService()
    }

    state = {
        id: null,

        nome: '',
        inputNomeErrorClass:'',
        errorNomeMessage:'Digite o nome',

        descricao:'',
        inputDescricaoErrorClass: '',
        errorDescricaoMessage: 'Digite a descição',

    }

    componentDidUpdate(){
        this.setStates()
    }

    setStates = () => {
        if( (this.props.visible && !this.state.didUpdated) || this.props.forceUpdate ){
        this.setState({id: this.props.state.id})
        this.setState({nome: this.props.state.nome})
        this.setState({descricao: this.props.state.descricao})

        // this.resetView()
            
        this.setState({didUpdated: true})
        if(this.props.doneForceUpdate){
            this.props.doneForceUpdate()
        }
        }
    }

    hideDialog = () => {
        this.setState({didUpdated: false})
        this.props.hideDialog()
    }

    handleChange = (event) => {
        const value = event.target.value
        const name = event.target.name
        this.setState({ [name]: value })
    }

    resetView = () => {
        this.setState({inputNomeErrorClass: ''})
        this.setState({inputDescricaoErrorClass: ''})
    }

    checkData = () => {
        var check = true

        if(!this.state.nome){
            this.setState({inputNomeErrorClass: "is-invalid"})
            check = false
        }

        if(!this.state.descricao){
            this.setState({inputDescricaoErrorClass: "is-invalid"})
            check = false
        }

        return check

    }

    callSave = () => {
        this.resetView()
        if(this.checkData()){
            this.save()
        }
    }

    save = () => {
        this.pdvCaixaService.update(
        {
            id: this.state.id,
            nome: this.state.nome,
            descricao: this.state.descricao,
        }
        )
        .then(response => {
            popUp.successPopUp("Ponto de Venda alterado com sucesso")
            this.props.search()
            this.hideDialog()
            this.setState({didUpdated: false})
        }).catch(error => {
            HandleErrorService.handleError(error)
        })
        this.setState({didUpdated: false})
    }

    render () {

        const editTipoRecebimentoDialogFooter = (
            <DialogFooter save = {this.callSave} hideDialog = {this.hideDialog} disabled={this.props.disabled}/>
        )

        return (
            <>
            <Dialog visible={this.props.visible} style={{ width: '450px' }}
                    header={this.props.header}
                    modal
                    className="p-fluid"
                    footer={editTipoRecebimentoDialogFooter}
                    onHide={this.hideDialog}>

                <div className="p-field">
                    <label htmlFor="nome">Nome</label>
                    <input style={{color: '#000'}}
                            type="text"
                            className={"form-control " + this.state.inputNomeErrorClass}
                            id="nome"
                            value = {this.state.nome}
                            name="nome"
                            onChange={this.handleChange}
                            // onKeyDown={this.handleKeyUp}
                            disabled={this.props.disabled}
                            />
                    <div className="invalid-feedback">{this.state.errorNomeMessage}</div>
                </div>

                <br/>

                <div className="p-field">
                    <label htmlFor="descricao">Descrição</label>
                    <input style={{color: '#000'}}   
                        className={"form-control " + this.state.inputDescricaoErrorClass}
                        type="text"
                        id="descricao"
                        name="descricao"
                        value={this.state.descricao}
                        onChange = {this.handleChange}
                        onKeyDown={this.handleKeyUp}
                        disabled={this.props.disabled}
                    />
                    <div className="invalid-feedback">{this.state.errorDescricaoMessage}</div>
                </div>

                <br />

            </Dialog>
            
            </>
        )    
    }

}

export default EditPDVCaixaDialog