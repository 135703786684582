import WorkerApiService from "../../workerApiServices";


class ContaBancariaService extends WorkerApiService {
    constructor() {
        super('/api/contaBancaria')
        this.outroLabel = 'Outro'
    }

    search(excludeFundosFixos, excludeAplicacaoFinanceira){
        let params = `?`
        params = `${params}&excludeFundosFixos=${excludeFundosFixos}`
        params = `${params}&excludeAplicacaoFinanceira=${excludeAplicacaoFinanceira}`
        return this.get(`/search${params}`)
    }

    save(contaBancaria){
        return this.post('/save', contaBancaria)
    }

    update(contaBancaria){
        return this.put('/update', contaBancaria)
    }

    formatNumConta(contaBancaria){
        if(contaBancaria.numConta){
            return contaBancaria.numConta + "-" + contaBancaria.numDigitoConta
        }
        return ''
    }

    getAllContasBancarias(){
        return [
            '197    Stone Pagamentos',
            '748	Banco Cooperativo Sicredi S.A.',
            '117    Advanced Cc Ltda',
            '735	Neon Pagamentos',
            '340	Superdigital',
            '290	PagBank',
            '121	Banco Agibank S.A.',
            '237	Next',
            '212	Banco Original',
            '280	Banco Willbank',
            '172	Albatross Ccv S.A',
            '323	Mercado Pago',
            '188	Ativa Investimentos S.A',
            '380	PicPay',
            '280	Avista S.A. Crédito, Financiamento e Investimento',
            '80	B&T Cc Ltda',
            '654	Banco A.J.Renner S.A.',
            '246	Banco ABC Brasil S.A.',
            '75	Banco ABN AMRO S.A',
            '25	Banco Alfa S.A.',
            '641	Banco Alvorada S.A.',
            '65	Banco Andbank (Brasil) S.A.',
            '213	Banco Arbi S.A.',
            '96	Banco B3 S.A.',
            '33	Banco SANTANDER',
            '318	Banco BMG S.A.',
            '752	Banco BNP Paribas Brasil S.A.',
            '107	Banco BOCOM BBM S.A.',
            '63	Banco Bradescard S.A.',
            '31	Código Banco Beg S.A.',
            '122	Banco Bradesco BERJ S.A.',
            '204	Banco Bradesco Cartões S.A.',
            '394	Banco Bradesco Financiamentos S.A.',
            '237	Banco Bradesco S.A.',
            '218	Banco BS2 S.A.',
            '208	Banco BTG Pactual S.A.',
            '336	Banco C6 S.A – C6 Bank',
            '473	Banco Caixa Geral – Brasil S.A.',
            '104	Banco Caixa Econômica Federal',
            '412	Banco Capital S.A.',
            '40	Banco Cargill S.A.',
            '368	Banco Carrefour',
            '266	Banco Cédula S.A.',
            '739	Banco Cetelem S.A.',
            '233	Banco Cifra S.A.',
            '745	Banco Citibank S.A.',
            '241	Banco Clássico S.A.',
            '756	Banco Cooperativo do Brasil S.A. – BANCOOB',
            '222	Banco Credit Agricole Brasil S.A.',
            '505	Banco Credit Suisse (Brasil) S.A.',
            '69	Banco Crefisa S.A.',
            '3	Banco da Amazônia S.A.',
            '83	Banco da China Brasil S.A.',
            '707	Banco Daycoval S.A.',
            '51	Banco de Desenvolvimento do Espírito Santo S.A.',
            '300	Banco de La Nacion Argentina',
            '495	Banco de La Provincia de Buenos Aires',
            '494	Banco de La Republica Oriental del Uruguay',
            '335	Banco Digio S.A',
            '348	BCO XP S.A.',
            '1	Banco do Brasil S.A.',
            '47	Banco do Estado de Sergipe S.A.',
            '37	Banco do Estado do Pará S.A.',
            '41	Banco do Estado do Rio Grande do Sul S.A.',
            '4	Banco do Nordeste do Brasil S.A.',
            '196	Banco Fair Corretora de Câmbio S.A',
            '265	Banco Fator S.A.',
            '260	NU PAGAMENTOS - IP',
            '224	Banco Fibra S.A.',
            '626	Banco Ficsa S.A.',
            '94	Banco Finaxis S.A.',
            '612	Banco Guanabara S.A.',
            '12	Banco Inbursa S.A.',
            '604	Banco Industrial do Brasil S.A.',
            '653	Banco Indusval S.A.',
            '77	Banco Inter S.A.',
            '249	Banco Investcred Unibanco S.A.',
            '184	Banco Itaú BBA S.A.',
            '29	Banco Itaú Consignado S.A.',
            '479	Banco ItauBank S.A',
            '341	Banco Itaú',
            '376	Banco J. P. Morgan S.A.',
            '422	Banco Safra',
            '217	Banco John Deere S.A.',
            '76	Banco KDB S.A.',
            '757	Banco KEB HANA do Brasil S.A.',
            '600	Banco Luso Brasileiro S.A.',
            '243	Banco Máxima S.A.',
            '720	Banco Maxinvest S.A.',
            '389	Banco Mercantil do Brasil S.A.',
            '370	Banco Mizuho do Brasil S.A.',
            '746	Banco Modal S.A.',
            '66	Banco Morgan Stanley S.A.',
            '456	Banco MUFG Brasil S.A.',
            '7	Banco Nacional de Desenvolvimento Econômico e Social – BNDES',
            'Bling Conta',
            this.outroLabel,

        ]
    }

}

export default ContaBancariaService