import React from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import GeneralServices from '../../../../app/service/generalServices'
import LancarFechamentoBancarioService from '../../../../app/service/conciliacaoBancaria/lancarFechamentoBancarioService'
import ConstantsUtil from '../../../../context/constantsUtil'
import { Button } from 'primereact/button'
import * as xlsx from 'xlsx'
import DREFichaBancariaDialog from '../DREFichaBancariaDialog'
import { classNames } from 'primereact/utils'
import ExpansionTableButton from '../expansionTableButton'
import TableFilters from '../../../tableFilters'


class DREPagamentoConsolidadoTable extends React.Component {

    state = {
        
        loading: false,

        forceUpdateDialog: false,
        elementToView: null,
        displayFichaBancariaDialog: false,
        // fichaBancariaToView: [],
        selectedValoresMeses: {},
        
        expanded: false,
    
        didUpdate: false,

        tableFilteredList: [],
        primaryFilter: '',


    }

    componentDidUpdate(){
        if(!this.state.didUpdate){
            let selectedValoresMeses = {}
            if(this.props.dreConsolidado.descricaoMesesList){
                this.props.dreConsolidado.descricaoMesesList.forEach(descricaoMes => {
                    selectedValoresMeses[descricaoMes] = null
                })
            }
            this.setState({selectedValoresMeses})
            this.setState({didUpdate: true})
            this.setState({tableFilteredList: this.props.dreConsolidado.drePagamentoList})

        }
    }    

    constructor(){
        super();
        this.toast = React.createRef();
        this.lancarFechamentoBancarioService = new LancarFechamentoBancarioService();
        this.tableFilters = new TableFilters();

    }

    getTableHeaders = () => {
        
        const periodo = this.props.dreConsolidado && this.props.dreConsolidado.initialDate ? this.props.dreConsolidado.initialDate + " - " + this.props.dreConsolidado.finalDate : ""
        const periodoLabel = "Período: " + periodo

        return {periodo: periodoLabel}
    }

    viewLancamentos = async (rowData, descricaoMes) => {
                
        // var fichaBancaria = rowData.dreMensalConsolidadoMap[descricaoMes].fichaBancariaUnificada
        // fichaBancaria.initialDate = this.props.dreConsolidado ? this.props.dreConsolidado.initialDate : ''
        // fichaBancaria.finalDate = this.props.dreConsolidado ? this.props.dreConsolidado.finalDate : ''

        // await this.setState({fichaBancariaToView: JSON.parse(JSON.stringify(fichaBancaria))})

        this.setState({elementToView: rowData.dreMensalConsolidadoMap[descricaoMes]})
        this.setState({forceUpdateDialog: true})
        this.setState({displayFichaBancariaDialog: true})
    }

    hideDialog = () => {
        this.setState({displayFichaBancariaDialog: false})
        // this.setState({fichaBancariaToView: ''})
    }

    rowClass(rowData) {
        const obj = {
            [ConstantsUtil.totaisTableLabel] : rowData.tipoLancamento === ConstantsUtil.tipoTotaisFichaBancariaLabel
        }

        return obj
    }

    formattedDREMensalPagamentoToExportByExcel(){
        var formattedList = []

        this.props.dreConsolidado.drePagamentoList.forEach(item =>{

            // console.log("item: ", item)
            
            var obj = {
                Pagamento: item.nome,
            }

            this.props.dreConsolidado.descricaoMesesList.forEach(descricaoMes => {
                const mesItem = item.dreMensalConsolidadoMap[descricaoMes]
                let valor = ''
                if(mesItem){
                    valor = mesItem.total
                }
                else{
                    valor = 0
                }
                obj[descricaoMes] = valor
            })

            obj.Total = item.total
            const porcentagem = '%'
            obj[porcentagem] = `${item.porcentagem}%`
            formattedList.push(obj)
        })

        return formattedList
    }

    generateWorkSheet(){
        const worksheet = GeneralServices.jsonToSheet(this.formattedDREMensalPagamentoToExportByExcel());
        return worksheet
    }

    exportExcel = () => {
        const periodo = this.props.dreConsolidado.initialDate + " - " + this.props.dreConsolidado.finalDate
            const worksheet = this.generateWorkSheet()
            const sheetName = periodo
            var workbook = { Sheets: { [sheetName]: worksheet }, SheetNames: [sheetName] };
            // this.formattedFichaBancariaToExport(this.props.dreConsolidado.drePagamentoList, workbook)
            this.props.saveAsExcelFile(workbook, `Fluxo de Caixa Mensal de Pagamento - ${periodo} - VisãoRJ`);
    }

    filterList = (filterColumnName) => {
        if(!this.state.expanded && this.props.dreConsolidado && this.props.dreConsolidado.drePagamentoList){
            let listToBeReturned = this.props.dreConsolidado.drePagamentoList.filter(item => item.tipoLancamento ===  ConstantsUtil.tipoTotaisFichaBancariaLabel)
            this.props.resetTotalItem(listToBeReturned)
            return listToBeReturned
        }

        if(filterColumnName && this.state.primaryFilter && (this.state.primaryFilter !== filterColumnName)){
            /*
            Se a coluna que está sendo filtrada é o filtro primário, então envia toda a lista.
            Caso contrário, envia apenas a lista filtrada.
            */
            return this.state.tableFilteredList
        }


        return this.props.dreConsolidado.drePagamentoList
    }

    updateValorMesFilter = async (descricaoMes, value) => {
        // console.log("updateValorMesFilter: ", descricaoMes, value)
        let selectedValoresMeses = this.state.selectedValoresMeses
        selectedValoresMeses[descricaoMes] = value

        this.checkPrimaryFilter(descricaoMes)

        await this.setState({selectedValoresMeses})
        // console.log("selectedValoresMeses: ", this.state.selectedValoresMeses)
    }

    checkPrimaryFilter = async (filterColumn) => {
        if(this.state.tableFilteredList.length === this.filterList().length){
            await this.setState({primaryFilter: filterColumn})
            // console.log("primaryFilter: ", this.state.primaryFilter)
        }
    }


    renderMesTableFilter = (descricaoMes) => {
        // console.log("this.tableFilters: ", descricaoMes)
        return (
            <div className='right-align'>
                {this.tableFilters.renderTableFilter(
                this.props.dt,
                this.state.selectedValoresMeses[descricaoMes],
                this.filterList(descricaoMes),
                descricaoMes,
                this.updateValorMesFilter,
                descricaoMes,
                '100%',
                "Total")}
            </div>
        )
    }
    
    onFilterTable = async (currentList) => {
        // console.log("currentList: ", currentList)
        this.props.onFilterTable(currentList, this.props.dt)

        this.setState({tableFilteredList: currentList})
    }
    

    render (){

        const renderMesesColumn = () => {
            var mesesColumnList = []

            if(this.props.dreConsolidado && this.props.dreConsolidado.descricaoMesesList && this.props.dreConsolidado.descricaoMesesList.length !== 0){
                this.props.dreConsolidado.descricaoMesesList.forEach(descricaoMes => {
                    mesesColumnList.push(renderColumnItem(descricaoMes))
                });
            }

            return mesesColumnList
        }

        const renderColumnItem = (descricaoMes) => {
            return (
                <Column
                    field={descricaoMes}
                    header={<div style={{height: ConstantsUtil.heightHeaderDREConsolidado}}>{descricaoMes}</div>}
                    style ={ {width: ConstantsUtil.widthColumDreConsolidado} }
                    filter={this.state.expanded} filterElement={this.renderMesTableFilter(descricaoMes)}
                    body={drePagamentoDTO => {
                        const mesValor = drePagamentoDTO.dreMensalConsolidadoMap[descricaoMes]
                        return (
                            <div className='right-align'>
                                {renderViewLancamentosLink(drePagamentoDTO, descricaoMes, mesValor)}
                            </div>
                        )
                     }
                    }
                />
            )
        }

        const renderViewLancamentosLink = (drePagamentoDTO, descricaoMes, mesValor) => {
            const cond1 = drePagamentoDTO.dreMensalConsolidadoMap[descricaoMes] && drePagamentoDTO.dreMensalConsolidadoMap[descricaoMes].total && drePagamentoDTO.dreMensalConsolidadoMap[descricaoMes].total !== 0
            const cond2 = drePagamentoDTO.tipoLancamento !== ConstantsUtil.tipoTotaisFichaBancariaLabel
            if( cond1 && cond2 ){
                const classes = classNames({
                    // 'superavitValue': valorNaoFormatado >= 0,
                    'deficitValue': mesValor.total < 0,
                    'linkValue': true,
                    "small-font": true
                    
                });
                return(
                    <a 
                    className={classes}
                        // href='/DREConsolidado'
                        onClick={() => this.viewLancamentos(drePagamentoDTO, descricaoMes)}
                    >
                        {mesValor ? GeneralServices.showFormattedIfNotNull(mesValor.total, true) : ConstantsUtil.valueFormattedZero}
                    </a>
                )
            }
            else{
                return(
                    <>
                        {mesValor && mesValor.total ? GeneralServices.showFormattedIfNotNull(mesValor.showTotal, true) : ConstantsUtil.valueFormattedZero}
                    </>
                )
            }
        }

        const renderTotalColumn = (rowData) => {
            const valor = GeneralServices.showFormattedIfNotNull(rowData.showTotal, true)

            return (
                <div className='right-align'>
                {valor}
                </div>
            )

        }

        const nomeFilterElement = this.tableFilters.renderTableFilter(
            this.props.dt,
            this.state.selectedNome,
            this.filterList("selectedNome"),
            "selectedNome",
            (name, value) => {
                this.setState({[name]: value})
                this.checkPrimaryFilter(name)
            },
            'nome',
            // ConstantsUtil.widthColumNomeDreConsolidado,
            '100%',
            "Nome"
        )        

        const renderTable = () => {
            return (
                <DataTable
                    ref={this.props.dt}
                    className="p-datatable-sm small-font"
                    value={this.filterList()}
                    // onValueChange={(currentList) => this.props.onFilterTable(currentList, this.props.dt)}
                    onValueChange={this.onFilterTable}
                    // className="p-datatable-responsive-demo"
                    rowClassName={this.rowClass}
                    // autoLayout
                    rowHover
                    showGridlines
                    // selection={this.state.selectedContasBancaria}
                    // onSelectionChange={this.handleSelectionChange}
                    // scrollable
                    // scrollHeight="500px"
                    style={{ maxWidth: '100vw'}}
                    frozenWidth={ConstantsUtil.widthColumNomeDreConsolidado}
                    loading={this.props.loading || this.state.loading}
                    dataKey="id"
                    emptyMessage={ConstantsUtil.nenhumLancamentoEncontradoMessage}
                    // paginator rows={100}
                    // paginatorTemplate={GeneralServices.tablePaginatorTemplate(100, this.props.dreConsolidado && this.props.dreConsolidado.drePagamentoList ? this.props.dreConsolidado.drePagamentoList.length : '')}
                    // currentPageReportTemplate="Mostrando de {first} ao {last} de {totalRecords} Grupos de Pagamento"
                >
    
                <Column
                    field="nome"
                    header="Nome"
                    filter={this.state.expanded} filterElement={nomeFilterElement}
                    style ={ {width: ConstantsUtil.widthColumNomeDreConsolidado} }
                    frozen
                    body = { (rowData, rowIndex) => {
                        const uniqueId = `name_${rowIndex.rowIndex}`;
                        return(
                            <div
                                style ={ {
                                    whiteSpace: 'nowrap', /* Impede que o texto quebre em várias linhas */
                                    overflow: 'hidden', /* Esconde qualquer conteúdo que exceda o width da coluna */
                                    textOverflow: 'ellipsis', /* Adiciona reticências (...) ao final do texto cortado */
                                } }
                                title={rowData.showNome}
                            >
    
                        <span id={uniqueId}>{rowData.showNome}</span>
    
                            </div>
                        )
                        
                    }}
                />
    
                {renderMesesColumn()}
    
                <Column field="total"
                    // header="Total"
                    header={<div style={{height: ConstantsUtil.heightHeaderDREConsolidado}}>Total</div>}
                    style ={ {width: ConstantsUtil.widthColumDreConsolidado} }
                    // body = {rowData => GeneralServices.showFormattedIfNotNull(rowData.total, true)}
                    body = {renderTotalColumn}
                />
    
                <Column field="porcentagem"
                    // header="%"
                    header={<div style={{height: ConstantsUtil.heightHeaderDREConsolidado}}>%</div>}
                    style ={ {width: ConstantsUtil.widthColumDrePorcentagemConsolidado} }
                    body = {rowData => {
                        return (
                            <div className='right-align'>
                            {`${rowData.showPorcentagem}%`}
                            </div>
                        )
                    }}
                />
    
                </DataTable>                    
            )
        }

        const renderExportarButton = () => {
            if(this.state.expanded)
            return (
                <Button label="Exportar"
                    icon={<i className="pi pi-file-excel" style={{fontSize: '1em', marginRight: '0.5em'}}></i>}
                    className="p-button-success small-font"
                    onClick={this.exportExcel}
                    tooltip="Exportar em excel"
                    tooltipOptions={{position: 'top'}}
                    style={{maxHeight: '15px'}}
                />                
            )
        }

        return (
            <>


            <div className = "card-header header-small-height" >
            <div className='col-md-12'>
                
                <div className='row'>
                    <ExpansionTableButton
                        expanded={this.state.expanded}
                        toggleExpansionTable={async () => {
                            await this.setState({expanded: !this.state.expanded})
                            await this.setState({selectedNome: ''})
                            this.props.dt.current.reset(); //reseta filtro
                        }}
                    />
                    <div className='col-md-4'>
                        <h5 className='small-font-size'>Pagamento</h5>            
                    </div>
                    <div className='col-md-4'>
                        {/* <h5 className='small-font-size'>{this.getTableHeaders().periodo}</h5> */}
                    </div>
                    <div className='col-md-2'>
                    </div>
                    {renderExportarButton()}
                </div>
                
                {/* <div className='row'>
                <div className='col-md-4'>
                    <Button label="Exportar" icon="pi pi-file-excel" className="p-button-success" onClick={this.exportExcel}
                        tooltip="Exportar em excel"
                        tooltipOptions={{position: 'top'}}
                    />
                </div>
                </div> */}
                
            </div>
            </div>

            {renderTable()}

            <DREFichaBancariaDialog
                visible={this.state.displayFichaBancariaDialog}
                initialDate={this.state.elementToView ? this.state.elementToView.descricaoMes.initialDate : ''}
                finalDate={this.state.elementToView ? this.state.elementToView.descricaoMes.finalDate : ''}
                element={this.state.elementToView}
                unificarEmpresas={this.props.unificarEmpresas}
                selectedEmpresas={this.props.selectedEmpresas}
                forceUpdate={this.state.forceUpdateDialog}
                doneForceUpdate={() => this.setState({forceUpdateDialog: false})}
                // fichaBancaria={this.state.fichaBancariaToView}
                search={this.props.search}
                saveAsExcelFile={this.props.saveAsExcelFile}
                loadingTable={false}
                hideDialog={this.hideDialog}
                push={this.props.push}
            />

            </>
        )
    }


}  

export default DREPagamentoConsolidadoTable