import { Dialog } from 'primereact/dialog'
import { ProgressSpinner } from 'primereact/progressspinner'
import React from 'react'
import TransactionByNFTable from '../../views/transactions/transactionByNFTable'
import { Button } from 'primereact/button'
import GeneralServices from '../../app/service/generalServices'
import HandleErrorService from '../../app/service/handleErrorService'
import ConstantsUtil from '../../context/constantsUtil'
import FichaDeEstoqueService from '../../app/service/relatorios/fichaDeEstoqueService'
import CentralService from '../../app/service/central/centralService'

class DetalhamentoMovimentacaoProductDialog extends React.Component {

    constructor(){
        super();
        this.fichaDeEstoqueService = new FichaDeEstoqueService();
        this.centralService = new CentralService();
    }

    state = {

        fichaDeEstoqueDetalhamento: null,
        tipoDetalhamento: '',
        nfsDetalhamento: null,
        nfsDetalhamentoTableFilter: null,
        loadingDetalhamento: false,

        nfQuantidadeValorMap: null,

        didUpdated: false,

    }

    componentDidMount(){
        // console.log("componentDidMount")
        this.viewDetalhamentoQuantidade()
    }

    // componentDidUpdate(){
    //     console.log("componentDidUpdate")
    //     if(this.props.visible && !this.props.didUpdated){
    //         console.log("search detalhamento!")
    //         this.viewDetalhamentoQuantidade()
    //     }
    // }

    viewDetalhamentoQuantidade = async () => {
        // console.log("viewDetalhamentoQuantidade", rowData)
        
        await this.setState({loadingDetalhamento: true})
        // this.setState({displayDetalhamentoDialog: true})
        // this.setState({fichaDeEstoqueDetalhamento: rowData})
        this.setState({tipoDetalhamento: this.props.tipo})
        
        this.handleDetalhamento()
        .then(response => {
            // console.log("response: ", response.data)
            let detalhamentoResponse = response.data
            this.setState({nfQuantidadeValorMap: detalhamentoResponse.nfQuantidadeValorMap})
            let nfsResponseList = detalhamentoResponse.nfsList

            GeneralServices.trataDadosNF(nfsResponseList)
            this.setValorAndQuantidadeProdutoNaNota(nfsResponseList)
            this.setConversaoNaNota(nfsResponseList)

            this.setState({nfsDetalhamento: nfsResponseList})
            this.setState({nfsDetalhamentoTableFilter: JSON.parse(JSON.stringify(nfsResponseList))})
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
        .finally(() => {
            this.setState({loadingDetalhamento: false})
            this.setState({didUpdated: true})
        })
    }

    handleDetalhamento = () => {
        // console.log("handleDetalhamento: ", rowData, tipo)
        let detalhamentoRequestBody = {
            product: this.props.product,
            dataInicial: this.props.dataInicial,
            dataFinal: this.props.dataFinal,
            tipo: this.props.tipo,
            fromFichaDeEstoque: this.props.fromFichaDeEstoque,
            tipoAtualizacaoFichaDeEstoque: this.props.tipoAtualizacaoFichaDeEstoque
        }

        if(this.props.unificar){
            return this.centralService.getDetalhamentoFichaDeEstoque(detalhamentoRequestBody)
        }
        else{
            return this.fichaDeEstoqueService.detalhamento(detalhamentoRequestBody)
        }
    }    

    setValorAndQuantidadeProdutoNaNota = (nfsResponseList) => {
        nfsResponseList.forEach(nfResponse => {
            
            // let quantidadeProdutoNaNota = 0.0
            // let valorProdutoNaNota = 0.0

            let quantidadeProdutoNaNota = this.state.nfQuantidadeValorMap[nfResponse.uuid].quantidade
            let valorProdutoNaNota = this.state.nfQuantidadeValorMap[nfResponse.uuid].valor
            
            // nfResponse.transactions.forEach(transaction => {
            //     // console.log("setValorAndQuantidadeProdutoNaNota transaction: ", transaction)
            //     if(this.props.product.uuid){
            //         if(this.props.product.uuid === transaction.productInfo.uuid){
            //             // console.log("transaction: ", transaction)
            //             quantidadeProdutoNaNota += transaction.quantidade
            //             valorProdutoNaNota += transaction.valor
            //         }
            //     }
            //     else{
            //         if(this.props.product.id === transaction.productInfo.id){
            //             quantidadeProdutoNaNota += transaction.quantidade
            //             valorProdutoNaNota += transaction.valor
            //         }
            //     }
            // })

            nfResponse.quantidadeProdutoNaNota = quantidadeProdutoNaNota
            nfResponse.valorUnitarioProdutoNaNota = quantidadeProdutoNaNota ?  GeneralServices.formatTo2CasasDecimais(valorProdutoNaNota/quantidadeProdutoNaNota) : 0.0
            nfResponse.valorTotalProdutoNaNota = GeneralServices.formatTo2CasasDecimais(valorProdutoNaNota)
        })
    }

    setConversaoNaNota = (nfsResponseList) => {
        
        let product = this.props.product
        // console.log("uuid: ", product.uuid)

        nfsResponseList.forEach(nfResponse => {
            nfResponse.conversao =  this.state.nfQuantidadeValorMap[nfResponse.uuid].conversao
            // nfResponse.transactions.forEach(transaction => {
            //     if(product.uuid){
            //         // console.log("setConversaoNaNota transaction: ", transaction.productInfo.uuid)
            //         if(product.uuid === transaction.productInfo.uuid){
            //             nfResponse.conversao = transaction.productInfo.conversao
            //             // console.log("match!", nfResponse)
            //             return;
            //         }
            //     }
            //     else{
            //         if(product.id === transaction.productInfo.idProdutoProprio){
            //             nfResponse.conversao = transaction.productInfo.conversao
            //             return;
            //         }
            //     }
            // })

        })

    }

    getDetalhamentoInfo = () => {
        let tipoDetalhamento = this.state.tipoDetalhamento === ConstantsUtil.saidaLabel ? "Saída" : "Entrada"
        let nomeProduto = this.props.product.descricao
        return `${tipoDetalhamento} ${nomeProduto} (${this.props.dataInicial} - ${this.props.dataFinal})`

    }
    
    getTotal = () => {
        let total = 0.0

        if(!this.state.nfsDetalhamentoTableFilter){
            return total
        }

        this.state.nfsDetalhamentoTableFilter.forEach(nfDetalhamento => {
            if(nfDetalhamento.conversao){
                total += nfDetalhamento.quantidadeProdutoNaNota*nfDetalhamento.conversao
            }
            else{
                total += nfDetalhamento.quantidadeProdutoNaNota
            }
        })
        return total
    }

    hideDialog = () => {
        this.setState({didUpdated: false})
        this.props.hideDialog()
    }

    onTableFilter = currentTableValue => {
        this.setState({nfsDetalhamentoTableFilter: currentTableValue})
    }

    render(){

        const renderDialog = () => {
            if(this.props.visible){
                if(this.state.loadingDetalhamento){
                    return (
                        <Dialog
                            visible
                            onHide={this.hideDialog}
                        >
                            <ProgressSpinner className='custom-progress-spinner' />
                        </Dialog>
                    )
                }
                else{
                    return (
                        <Dialog
                            header={
                                <div
                                    className='row'
                                >
                                    <div className='col-md-9'>
                                        {this.getDetalhamentoInfo()}
                                    </div>
                                    <div
                                        // className='right-align'
                                        className='col-md-3'
                                    >
                                        <Button
                                            label={
                                                `Quantidade Total: ${GeneralServices.formatTo2CasasDecimais(this.getTotal())}`}
                                        />
                                    </div>
                                </div>
                            }
                            visible={this.props.visible}
                            modal
                            style={{ width: '95%', height: '95%' }}
                            onHide={this.hideDialog}
                        >
                            <TransactionByNFTable
                                scrollHeight="400px"
                                list = {this.state.nfsDetalhamento}
                                product={this.props.product}
                                search={this.search}
                                startLoadingTable={() => this.setState({loadingNFTable: true})}
                                finishLoadingTable={() => this.setState({loadingNFTable: false})}
                                isSearchByNumero={this.state.isSearchByNumero}
                                setInfo={this.setInfo}
                                loading={this.state.loadingNFTable}
                                conferenciaNF
                                situacao={this.state.situacao}
                                simpleView
                                insideFichaDeEstoqueDetalhamento
                                tipoDetalhamento={this.state.tipoDetalhamento}
                                tipoNF={this.state.tipoDetalhamento}
                                unificar={this.props.unificar}
                                onTableFilter={this.onTableFilter}
                            />                             
                        </Dialog>
                    )
                }
            }
        }

        return(
            <>
                {renderDialog()}
            </>
        )

    }

}

export default DetalhamentoMovimentacaoProductDialog