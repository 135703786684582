import React from 'react'

import { Dialog } from 'primereact/dialog'
import CadastrarFornecedorComponent from '../../views/cadastrarFornecedorComponent'

class CreateFornecedorDialog extends React.Component {

    constructor(){
        super()
    }

    hideDialog = () => {
        this.props.hideDialog()
    }

    render(){

        return(
            <Dialog 
                visible={this.props.visible}
                // style={{ maxWidth: '90vw' }}
                header={this.props.header ? this.props.header : 'Cadastrar Fornecedor'}
                modal
                className="p-fluid"
                // footer={editTipoRecebimentoDialogFooter}
                onHide={this.hideDialog}>

                <CadastrarFornecedorComponent
                    insideDialog
                    search={this.props.search}
                    hideDialog={this.props.hideDialog}
                    handleNewFornecedorCadastrado={this.props.handleNewFornecedorCadastrado}
                />

            </Dialog>
        )

       


    }


}

export default CreateFornecedorDialog