import React from 'react'
import { Dialog } from 'primereact/dialog';
import NotificacaoTable from './notificacaoTable';
import DialogFooter from '../../dialogFooter';

class NotificacaoDialog extends React.Component {

    constructor(){
        super()
    }


    hideDialog = () => {
        this.props.hideDialog()
    }


    render() {

        const footer = (
            <DialogFooter hideDialog = {this.hideDialog} disabled/>
        )

        return (
            <>
                <Dialog
                    visible={this.props.visible}
                    style={{ width: '95%' }}
                    header={"Notificações"}
                    modal
                    className="p-fluid"
                    footer={footer}
                    onHide={this.hideDialog}          
                >

                    <NotificacaoTable
                    />

                </Dialog>
            </>
        )
    }

}

export default NotificacaoDialog