import React from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import GeneralServices from '../../../../app/service/generalServices'
import { Button } from 'primereact/button'
import { classNames } from 'primereact/utils'
import * as xlsx from 'xlsx'
import ConstantsUtil from '../../../../context/constantsUtil'
import DREFichaBancariaDialog from '../DREFichaBancariaDialog'


class DREDiferencaTable extends React.Component {

    state = {
        
        loading: false,

        displayFichaBancariaDialog: false,

        forceUpdateDialog: false,

    }

    getTableHeaders = () => {
        
        const periodo = this.props.DRE ? this.props.DRE.initialDate + " - " + this.props.DRE.finalDate : ""
        const periodoLabel = "Período: " + periodo

        return {periodo: periodoLabel}
    }

    // saldoClass(rowData) {
    //     var obj ={}
    //     if(rowData.total > 0){
    //         obj = {
    //             'superavit': true
    //         }
    //     }
    //     else if(rowData.total < 0){
    //         obj = {
    //             'deficit': true
    //         }
    //     }

    //     return obj
    // }

    formattedDREMensalDiferencaToExportByExcel(){
        var obj = {'': this.props.header}
        Object.assign(obj, {Valor: this.props.list[0].total});
        var formattedList = [obj]
        return formattedList
    }

    generateWorkSheet(){
        const worksheet = GeneralServices.jsonToSheet(this.formattedDREMensalDiferencaToExportByExcel());
        return worksheet
    }

    exportExcel = () => {
        const periodo = this.props.DRE.initialDate + " - " + this.props.DRE.finalDate
        const worksheet = this.generateWorkSheet()
        const sheetName = periodo
        const workbook = { Sheets: { [sheetName]: worksheet }, SheetNames: [[sheetName]] };
        this.props.saveAsExcelFile(workbook, `Fluxo de Caixa Mensal ${this.props.header} - ${periodo} - VisãoRJ`);
    }

    saveAsExcelFile(buffer, fileName) {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + EXCEL_EXTENSION);
            }
        });
    }

    viewLancamentos = async () => {
                
        this.setState({displayFichaBancariaDialog: true})
        this.setState({forceUpdateDialog: true})

    }
    
    hideDialog = () => {
        this.setState({displayFichaBancariaDialog: false})
    }

    render (){

        const renderStyledValue = (rowData) => {

            // var valor = rowData.total

            // const classes = classNames({
            //     'superavitValue': valor >= 0,
            //     'deficitValue': valor<0
            // });

            return (
                <>
                    {renderViewLancamentosLink(rowData)}
                </>
            );
        }

        const renderViewLancamentosLink = (rowData) => {
            // const cond1 = rowData.fichaBancariaUnificada.fichaBancariaList && rowData.fichaBancariaUnificada.fichaBancariaList.length > 0
            const valor = rowData.total

            const cond1 = rowData.total !== 0
            const cond2 = rowData.tipoLancamento !== ConstantsUtil.tipoTotaisFichaBancariaLabel
            if( cond1 && cond2 ){

                const classes = classNames({
                    'superavitValue': valor >= 0,
                    'deficitValue': valor<0
                });

                return(
                    <a 
                        className={classes}
                        // href='/DRE'
                        onClick={() => this.viewLancamentos(rowData)}
                    >
                        {/* {valor} */}
                        {valor ? GeneralServices.showFormattedIfNotNull(valor, true) : ''}
                    </a>
                )
            }
            else{
                return(
                    <>
                        {valor}
                    </>
                )
            }
        }        


        return (
            <>
            <div className = "card-header header-small-height">
            <div className='col-md-12'>
                
                <div className='row'>
                    <div className='col-md-4' style={{marginLeft: '1.05rem'}}>
                        <h5 className='small-font-size'>{this.props.header}</h5>            
                    </div>
                    <div className='col-md-4'>
                        {/* <h5 className='small-font-size'>{this.getTableHeaders().periodo}</h5> */}
                    </div>
                    <div className='col-md-2'>
                    </div>
                    <div className='col-md-2'>
                        {/* <Button label="Exportar"
                            icon={<i className="pi pi-file-excel" style={{fontSize: '1em', marginRight: '0.5em'}}></i>}
                            className="p-button-success small-font"
                            tooltip={ConstantsUtil.exportXlsxLabel}
                            tooltipOptions={{position: 'top'}}
                            style={{maxHeight: '15px'}}
                            onClick={this.exportExcel}
                        /> */}
                    </div>
                </div>
                
                {/* <div className='row'>
                <div className='col-md-4'>
                    <Button label="Exportar" icon="pi pi-file-excel" className="p-button-success" onClick={this.exportExcel}
                        tooltip={ConstantsUtil.exportXlsxLabel}
                        tooltipOptions={{position: 'top'}}
                    />
                </div>
                </div> */}
                
            </div>
            </div>

            
            <DataTable
                ref={this.dt}
                value={this.props.list}
                className="p-datatable-sm small-font"
                // className="p-datatable-responsive-demo"
                // rowClassName={this.saldoClass}
                rowHover
                showGridlines
                // selection={this.state.selectedContasBancaria}
                // onSelectionChange={this.handleSelectionChange}
                scrollable
                style={{ maxWidth: '100vw' }}
                scrollHeight="500px"
                loading={this.props.loading || this.state.loading}
                dataKey="id"
                emptyMessage="Nenhum Resultado encontrado."
                // paginator rows={ConstantsUtil.initialTableQuantityOption}
                // paginatorTemplate={GeneralServices.tablePaginatorTemplate(ConstantsUtil.initialTableQuantityOption, this.props.list ? this.props.list.length : '')}
                // currentPageReportTemplate="Mostrando de {first} ao {last} de {totalRecords} Tipos de Pagamento"
            >

            <Column field="nome" header="Nome"
                style ={ {minWidth: '200px'} }
                body = {rowData => "DIFERENÇA"}
            />

            <Column field="total" header="Valor"
                style ={ {minWidth: '200px'} }
                body = {rowData => renderStyledValue(rowData)}
            />

            </DataTable>

            <DREFichaBancariaDialog
                visible={this.state.displayFichaBancariaDialog}
                initialDate={this.props.DRE ? this.props.DRE.initialDate : ''}
                finalDate={this.props.DRE ? this.props.DRE.finalDate : ''}
                // element={this.state.elementToView}
                diferencaDRE={this.state.displayFichaBancariaDialog}
                unificarEmpresas={this.props.unificarEmpresas}
                selectedEmpresas={this.props.selectedEmpresas}
                forceUpdate={this.state.forceUpdateDialog}
                doneForceUpdate={() => this.setState({forceUpdateDialog: false})}
                // recebimento={this.state.fichaBancariaRecebimento}
                saveAsExcelFile={this.props.saveAsExcelFile}
                loadingTable={false}
                hideDialog={this.hideDialog}
                push={this.props.push}
            />            

            </>
        )
    }


}  

export default DREDiferencaTable