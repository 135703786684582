import React from 'react'
import { Checkbox } from 'primereact/checkbox'

class CheckBoxComponent extends React.Component {

    render(){
        return(
            <>
                <div className="row">
                <Checkbox inputId="checkBoxComponent"
                    checked={this.props.checked}
                    // onChange={e => this.setState({ checked: e.checked })}
                    onChange={e => this.props.handleCheckBoxChange(e.checked)}
                />
                 <label htmlFor="binary" style ={{marginLeft: '10px'}}
                    className={this.props.inputCheckBoxErrorClass}
                 >
                    {/* {ConstantsUtil.destacadoNoDRECheckBoxLabel} */}
                    {this.props.label}
                </label>
                 <div className="invalid-feedback">{this.props.errorCheckBoxMessage}</div>
                 </div>
            </>
        )
    }

}

export default CheckBoxComponent