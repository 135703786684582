import React from 'react'

import { Dialog } from 'primereact/dialog'

import DialogFooter from '../dialogFooter'
import * as popUp from '../../components/toastr'
import HandleErrorService from '../../app/service/handleErrorService'
import FornecedorService from '../../app/service/contasAPagar/fornecedorService'
import GeneralServices from '../../app/service/generalServices'
import SelectMenu from '../selectMenu'

class EditFornecedorDialog extends React.Component {

    constructor(){
        super();
        this.fornecedorService = new FornecedorService();
        this.generalServices = new GeneralServices();
        
    }

    state = {
        id: null,

        nome: '',
        inputNomeErrorClass:'',
        errorNomeMessage:'Informe o nome',

        cnpj:'',

        tipoFornecedor: '',
        inputTipoFornecedorErrorClass:'',

        isSaving: false,
        allowUpdate: true,

    }

    componentDidUpdate(){
        this.setStates()
    }

    setStates = () => {
        if( ((this.props.visible && !this.state.didUpdated) || this.props.forceUpdate) && this.state.allowUpdate){
            this.setState({id: this.props.state.id})
            this.setState({nome: this.props.state.nome})
            this.setState({cnpj: this.props.state.cnpj})
            this.setState({tipoFornecedor: this.props.state.tipoFornecedor})
            this.resetView()
                
            this.setState({didUpdated: true})
            if(this.props.doneForceUpdate){
                this.props.doneForceUpdate()
            }
        }
    }

    hideDialog = () => {
        this.setState({didUpdated: false})
        this.setState({allowUpdate: true})
        this.props.hideDialog()
    }

    handleChange = (event) => {
        const value = event.target.value
        const name = event.target.name
        this.setState({ [name]: value })
    }

    handleKeyUp = async (event) => {

        await this.generalServices.sleep(100)

        if(this.state.isSaving){
            return
        }

        if(event.code === "Enter" || event.code === "NumpadEnter"){
            this.callSave()
        }
    }

    resetView = () => {
        this.setState({inputNomeErrorClass: ''})
        this.setState({inputTipoFornecedorErrorClass: ''})
    }

    checkData = () => {
        var check = true

        if(!this.state.nome){
            this.setState({inputNomeErrorClass: "is-invalid"})
            check = false
        }
        
        if(!this.state.tipoFornecedor){
            this.setState({inputTipoFornecedorErrorClass: "is-invalid"})
            check = false
        }

        return check

    }

    callSave = async () => {
        this.resetView()
        if(this.checkData()){

            await this.generalServices.sleep(100)
            if(this.state.isSaving){
                return
            }

            this.save()
        }
    }

    save = () => {

        this.setState({isSaving: true})
        this.setState({allowUpdate: false})

        this.fornecedorService.update(
        {
            id: this.state.id,
            nome: this.state.nome,
            tipoFornecedor: this.state.tipoFornecedor
        }
        )
        .then(async response => {
            popUp.successPopUp("Fornecedor alterado com sucesso!")
            this.props.search()
            this.hideDialog()
            this.setState({didUpdated: false})

            await this.generalServices.sleep(1000)
            this.setState({isSaving: false})

        }).catch(async error => {
            await this.generalServices.sleep(1000)
            this.setState({isSaving: false})
            HandleErrorService.handleError(error)
        })
        this.setState({didUpdated: false})
    }

    render () {

        const editFornecedorDialogFooter = (
            <DialogFooter save = {this.callSave} hideDialog = {this.hideDialog} disabled={this.props.disabled} />
        )

        return (
            <>
            <Dialog visible={this.props.visible} style={{ width: '450px' }}
                    header={this.props.header}
                    modal
                    className="p-fluid"
                    footer={editFornecedorDialogFooter}
                    onHide={this.hideDialog}
                    >

                <div className="p-field">
                    <label htmlFor="nome">Nome</label>
                    <input style={{color: '#000'}}
                            type="text"
                            className={"form-control " + this.state.inputNomeErrorClass}
                            id="nome"
                            value = {this.state.nome}
                            name="nome"
                            onChange={this.handleChange}
                            onKeyDown={this.handleKeyUp}
                            disabled={this.props.disabled}
                            />
                    <div className="invalid-feedback">{this.state.errorNomeMessage}</div>
                </div>

                <br/>

                <div className="p-field">
                    <label htmlFor="descricao">CNPJ</label>
                    <input style={{color: '#000'}}   
                        className={"form-control " }
                        type="text"
                        id="cnpj"
                        name="cnpj"
                        value={this.state.cnpj}
                        // onChange = {this.handleChange}
                        // onKeyDown={this.handleKeyUp}
                        disabled
                    />
                    {/* <div className="invalid-feedback">{this.state.errorCNPJMessage}</div> */}
                </div>

                <br />

                <div className="p-field">
                    {/* <label htmlFor="tipoFornecedor">Tipo de Fornecedor</label>
                    <input style={{color: '#000'}}   
                        className={"form-control " }
                        type="text"
                        id="tipoFornecedor"
                        name="tipoFornecedor"
                        value={this.state.tipoFornecedor}
                        // onChange = {this.handleChange}
                        // onKeyDown={this.handleKeyUp}
                        // disabled
                    /> */}
                    {/* <div className="invalid-feedback">{this.state.errorCNPJMessage}</div> */}
                <label htmlFor="filtro">Tipo de Fornecedor</label>
                <SelectMenu 
                    className={"form-control " + this.state.inputTipoFornecedorErrorClass}
                    name="tipoFornecedor"
                    list= {this.fornecedorService.tipoFornecedorList} 
                    value={this.state.tipoFornecedor}
                    onChange={this.handleChange}
                    disabled={this.props.disabled}
                />
                <div className="invalid-feedback">Informe o Tipo do Fornecedor</div>  
                </div>

                <br />

            </Dialog>
            
            </>
        )    
    }

}

export default EditFornecedorDialog