import React from 'react'
import { withRouter } from 'react-router-dom'

import Card from '../../../components/card'
import { AuthContext } from '../../../main/authProvider'
import GeneralServices from '../../../app/service/generalServices'
import { Button } from 'primereact/button'

import * as popUp from '../../../components/toastr'
import ConstantsUtil from '../../../context/constantsUtil'
import ProvisaoService from '../../../app/service/contasAPagar/provisaoService'
import DateIntervalCalendar from '../../../components/calendar/dateIntervalCalendar'
import SelectMenu from '../../../components/selectMenu'
import ProvisaoTable from '../../../components/contasAPagar/provisoes/provisaoTable'


class CheckProvisoesAPagar extends React.Component {

    constructor(){
        super();
        this.generalServices = new GeneralServices();
        this.provisaoService = new ProvisaoService();
        this.generalServices = new GeneralServices();



    }

    state = {

        selectedFilterType: '',
        inputFilterTypeErrorClass: '',

        selectedDateFilterType: '',
        missingFilterType: false,
        missingAnyDate: false,

        initialDate: '',
        initialDateView: '',
        
        finalDate: '',
        finalDateView: '',

        searchProvisaoResult: '',
        valorTotal: '',

        loadingTable: false,
        isSearching: false,
        isFinishedSearch: false,

        habilitaBusca: true,

        forceDateIntervalCalendarUpdate: false,
        isFromNotificacao: false,
        preSetFilterType: '',
        preSetDataInicial: '',
        preSetDataFinal: '',


    }

    componentDidMount(){
        this.checkOrigem()
    }
    
    checkOrigem = async () => {
        let dataParcelaPagamentoFromNotificacao = sessionStorage.getItem(ConstantsUtil.checkProvisoesAPagarIsFromNotificacaoSessionStorageKey)
        if(dataParcelaPagamentoFromNotificacao){
            sessionStorage.removeItem(ConstantsUtil.checkProvisoesAPagarIsFromNotificacaoSessionStorageKey)
            let jsDate = GeneralServices.convertCompleteBrStringToJsDate(dataParcelaPagamentoFromNotificacao)
            // console.log("jsDate: ", jsDate)
            await this.setState({isFromNotificacao: true})
            await this.setState({selectedFilterType: this.getFilterTypeList()[2].value})
            await this.setState({preSetFilterType: ConstantsUtil.calendarPersonalizadoFilterLabel})
            await this.setState({preSetDataInicial: jsDate})
            await this.setState({preSetDataFinal: jsDate})

            await this.setState({forceDateIntervalCalendarUpdate: true})

        }
        else{
            this.setState({selectedFilterType: this.getFilterTypeList()[1].value})
        }
    }

    getFilterTypeList(){
        return [
            {label: 'Selecione...', value: ''},
            {label: ConstantsUtil.dataLancamentoLabel, value: ConstantsUtil.dataLancamentoValue},
	        {label: ConstantsUtil.dataPagamentoLabel, value: ConstantsUtil.dataPagamentoValue},
        ]
    }    

    handleFilterTypeChange = async (event) => {
        const value = event.target.value
        const name = event.target.name
        await this.setState({ [name]: value })
        // this.resetDateView()
    }

    handleFilterDateChange = (selectedDateFilterType) => {
        this.setState({selectedDateFilterType})
    }

    handleDateChange = async (initialDate, finalDate) => {

        await this.setState({initialDate})
        await this.setState({initialDateView: GeneralServices.convertBrStringToJsDate(initialDate)})

        await this.setState({finalDate})
        await this.setState({finalDateView: GeneralServices.convertBrStringToJsDate(finalDate)})

    }


    resetView = () => {

        this.setState({inputFilterTypeErrorClass: ''})

        this.setState({missingFilterType: false})
        this.setState({missingAnyDate: false})
        
    }

    checkData = () => {
        var check = true

        if(!this.state.selectedFilterType){
            this.setState({inputFilterTypeErrorClass: ConstantsUtil.bootstrapInputErrorClass})
            check = false
        }

        if(!this.state.selectedDateFilterType){
            this.setState({missingFilterType: true})
            check=false
        }
        
        if(!this.state.initialDate || !this.state.finalDate){
            this.setState({missingAnyDate: true})
            check=false
        }

        if(this.state.initialDate && this.state.finalDate){
            if(this.state.initialDateView > this.state.finalDateView){
                check = false
                popUp.infoPopUp(ConstantsUtil.dataInicialAnteriorFinalMessage)
           }
        }

        return check;
    }


    callSearch = () => {
        this.resetView()
        if(this.checkData()){
            this.search()
        }
    }

    search = (fromTable) => {

        if(this.state.isSearching){
            return
        }

        this.setState({isSearching: true})
        this.setState({loadingTable: true})
        this.setState({isFinishedSearch: false})

        const obj = {
            filterTypeDate: this.state.selectedFilterType,
            initialDate: this.state.initialDate,
            finalDate: this.state.finalDate,
        }

        let onSuccess = async (responseData) => {
            this.setState({searchProvisaoResult: responseData.dados})
            this.setState({valorTotal: responseData.valorTotal})
        }

        let onFinally = () => {
            this.setState({loadingTable: false})
            this.setState({isFinishedSearch: true})
            this.setState({isSearching: false})
            this.setState({isFromNotificacao: false})

            if(!fromTable){
                this.toggleButton()
            }
        }

        this.generalServices.searchProvisoes(
            obj,
            onSuccess,
            onFinally
        )

    }


    voltar = () => {
        this.setState({searchProvisaoResult: ''})
        this.toggleButton()
    }

    toggleButton = () => {
        this.setState({habilitaBusca: !this.state.habilitaBusca})
    }

    render() {

        const renderDateIntervalCalendar = () => {
                return (
                    <DateIntervalCalendar
                        htmlFor="SelectDatePeriod"
                        label="Escolher período"
                        handleFilterChange={this.handleFilterDateChange}
                        handleDateChange={this.handleDateChange}
                        missingFilterType={this.state.missingFilterType}
                        missingAnyDate={this.state.missingAnyDate}
                        disabled={!this.state.habilitaBusca}
                        forceUpdate={this.state.forceDateIntervalCalendarUpdate}
                        doneForceUpdate={() => this.setState({forceDateIntervalCalendarUpdate: false})}
                        preSetFilterType={this.state.preSetFilterType}
                        preSetDataInicial={this.state.preSetDataInicial}
                        preSetDataFinal={this.state.preSetDataFinal}
                        onFinishSetValues={() => {
                            if(this.state.isFromNotificacao){
                                this.search()
                            }
                        }}
                    />
                )
        }

        const renderButtons = () => {

            if(this.state.isSearching){
                return (
                    <Button 
                        label="Buscando ..."
                        icon="pi pi-search"
                        disabled
                    />
                )
            }

            if(this.state.habilitaBusca){
                return (
                    <Button 
                        label="Buscar"
                        icon="pi pi-search"
                        onClick = {this.callSearch}
                        // style={ {maxHeight: '35px' } }
                    />
                )
            }
            return (
                <>
                    <Button 
                        label="Voltar"
                        icon="pi pi-undo"
                        className="p-button-danger"
                        onClick = {this.voltar}
                        // style={ {maxHeight: '35px' } }
                        // disabled = {this.state.isSearchingLancamentos}
                    />
                </>
            )
        }

        const renderTable = () => {
            if( (this.state.isSearching || this.state.isFinishedSearch) && !this.state.habilitaBusca ){
                return (
                    <ProvisaoTable
                        searchProvisaoResult={this.state.searchProvisaoResult}
                        loading={this.state.loadingTable}
                        perfil = {this.context.perfil}
                        search={this.search}
                        valorTotal={this.state.valorTotal}
                        initialDate={this.state.initialDate}
                        finalDate={this.state.finalDate}
                    />
                )
            }

            if(this.state.isSearching){
                return (
                    <ProvisaoTable
                        loading={true}
                    />
                )
            }
        }        
        
        return (
            <div className="bs-docs-section" >

            <Card title = "Provisões de Pagamento">
                <div className="col-md-12">
                    
                <div className="row">

                <div className = "col-md-3">
                    <label htmlFor="filtro">Filtrar Por</label>
                    <SelectMenu
                        className={"form-control " + this.state.inputFilterTypeErrorClass }
                        name="selectedFilterType"
                        list= {this.getFilterTypeList()} 
                        value={this.state.selectedFilterType}
                        onChange={this.handleFilterTypeChange}
                        disabled={!this.state.habilitaBusca}
                    />
                    <div className="invalid-feedback">{ConstantsUtil.preecnhaLabel}</div>
                </div>
                
                    {renderDateIntervalCalendar()}

                </div>

                <br />
                    
                    
                {renderButtons()}


                <br />

                <br />                

                {renderTable()}

                </div>
            </Card>
            <div className="d-flex "/>

        </div>
        )

    }

}

CheckProvisoesAPagar.contextType = AuthContext

export default withRouter(CheckProvisoesAPagar)
