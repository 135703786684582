import React from 'react'
import Card from '../../components/card'
import { withRouter } from 'react-router-dom'

import { Button } from 'primereact/button'

import { AuthContext } from '../../main/authProvider'
import TransactionService from '../../app/service/estoque/transactionService'
import FormGroup from '../../components/form-group'
import SelectMenu from '../../components/selectMenu'
import * as popUp from '../../components/toastr'
import GeneralServices from '../../app/service/generalServices'
import HandleErrorService from '../../app/service/handleErrorService'
import TransactionByProductTable from './transactionByProductTable'
import ProgressService from '../../app/service/progressService'
import DateIntervalCalendar from '../../components/calendar/dateIntervalCalendar'
import ConstantsUtil from '../../context/constantsUtil'
import NFService from '../../app/service/estoque/NFService'
import DialogFooter from '../../components/dialogFooter'
import { Dialog } from 'primereact/dialog'
import AuthService from '../../app/service/authService'
import TransactionByNFTablePageable from './transactionByNFTablePageable'

const defaultFirstInTable = 0
const defaultSortField = ''
const defaultSortOrder = 1
class ConferenciaNF extends React.Component {

    constructor(){
        super();
        this.transactionService = new TransactionService();
        this.generalServices = new GeneralServices();
        this.progressService = new ProgressService();
        this.NFService = new NFService();
    }

    state = {

        selectedFiltrarPor: '',
        inputNFFilterTypeErrorClass: '',

        initialDate: '',
        initialDateView: '',
        finalDate: '',
        finalDateView: '',

        selectedFilterType: '',
        missingFilterType: false,
        missingAnyDate: false,

        productLabel: 'Por produto',
        NFLabel: 'Por nota',
        currentLabel: '',
        transactionList: [],
        tipoNF: '',
        situacao: '',
        errorTypeMessage: null,
        inputTypeErrorClass: null,
        errorSituationMessage: null,
        inputSituationErrorClass: null,
        
        loadingNFTable: false,
        loadingProductTable: false,

        totalValue: GeneralServices.valueBodyTemplate(0),
        quantidadeItems: 0,

        habilitaBusca: true,
        isFinishedNFSearch: false,
        isFinishedProductSearch: false,

        infoData: {},
        NFData: {},
        productTransactionData: {},

        pesquisaNumeroDialogVisible: false,
        numeroNFToSearch: null,

        isSearchByNumero: false,

        transactionGroupByProductList: [],

        dataEmissaoSemHorarioFiltroList: [],
        dataEntradaSemHorarioFiltroList: [],
        numeroFiltroList: [],
        nomeFornecedorOuClienteFiltroList: [],
        valorTotalFiltroList: [],
        tipoNFFiltroList: [],
        chaveFiltroList: [],
        tipoCriacaoFiltroList: [],
        fonteFiltroList: [],
        sefazFiltroList: [],
        situacaoTratadaFiltroList: [],
        
        firstInNFTable: defaultFirstInTable,
        rowsInNFTable: ConstantsUtil.initialRowsInNFtable,
        sortFieldNFTable: defaultSortField,
        sortOrderNFTable: defaultSortOrder,
        filterNFTableState: {},

        deveApresentarAvisoFiltroSituacaoNF: false,

    }

    componentDidMount(){
        this.setState({currentLabel: this.state.NFLabel})
        this.setState({situacao: this.transactionService.getSituationList()[1].value})
        this.setState({tipoNF: this.transactionService.getTypeList()[1].value})
    }

    changeLabel = (label) => {
        this.setState({currentLabel: label})

        switch(label){
            case this.state.NFLabel:
                this.setInfo(this.state.NFData)
                break
            case this.state.productLabel:
                // this.setInfo(this.state.productTransactionData)
                break
        }
    }

    handleFiltrarPorChange = async (event) => {
        const value = event.target.value
        const name = event.target.name
        await this.setState({ [name]: value })
        if(value === ConstantsUtil.filtroDataEntradaLabel){
            popUp.infoPopUp(`Ao filtrar por Data de Entrada, apenas as notas "Validadas" serão listadas.<br/>
                Para ver as notas "Excluídas" após a validação (se houver), altere o filtro "Situação" na tabela que será gerada abaixo
                e clique no botão "Filtrar".
            `)
        }

        if(value){
            var select = document.getElementById(ConstantsUtil.componenteEscolherPeriodoId)
            select.focus();
        }

        if(value === ConstantsUtil.filtroDataEntradaLabel){
            let tipoNotaFiscalEvent = {target: {name: 'tipoNF', value: ConstantsUtil.entradaLabel}}
            this.handleTipoNotaFiscalChange(tipoNotaFiscalEvent)
        }

    }

    handleTipoNotaFiscalChange = async (event) => {
        const value = event.target.value
        const name = event.target.name
        await this.setState({ [name]: value })
    }

    handleDateFilterChange = (selectedFilterType) => {
        this.setState({selectedFilterType})
    }

    handleDateChange = async (initialDate, finalDate) => {
        await this.setState({initialDate})
        
        await this.setState({initialDateView: GeneralServices.convertBrStringToJsDate(initialDate)})

        await this.setState({finalDate})
        await this.setState({finalDateView: GeneralServices.convertBrStringToJsDate(finalDate)})

    }    

    resetView = async () => {

        this.setState({inputNFFilterTypeErrorClass: ''})

        if(this.state.tipoNF) {
            this.setState({inputTypeErrorClass: ""})
            this.setState({errorTypeMessage:""})
        }
        if(this.state.situacao){
            this.setState({inputSituationErrorClass: ""})
            this.setState({errorSituationMessage:""})
        }

        this.setState({missingFilterType: false})
        this.setState({missingAnyDate: false})

        await this.setState({
            firstInNFTable: defaultFirstInTable,
            rowsInNFTable: ConstantsUtil.initialRowsInNFtable,
            sortFieldNFTable: defaultSortField,
            sortOrderNFTable: defaultSortOrder
        })

    }

    checkData = () => {
        var check = true

        if(!this.state.selectedFiltrarPor){
            this.setState({inputNFFilterTypeErrorClass: ConstantsUtil.bootstrapInputErrorClass})
            check = false
        }
        
        if(!this.state.tipoNF){
            this.setState({inputTypeErrorClass: ConstantsUtil.bootstrapInputErrorClass})
            this.setState({errorTypeMessage:"Selecione o tipo da Nota Fiscal"})
            check = false
        }
        if(!this.state.situacao){
            this.setState({inputSituationErrorClass:  ConstantsUtil.bootstrapInputErrorClass})
            this.setState({errorSituationMessage:"Selecione a situação"})
            check = false
        }

        if(!this.state.selectedFilterType){
            this.setState({missingFilterType: true})
            check=false
        }
        
        if(!this.state.initialDate || !this.state.finalDate){
            this.setState({missingAnyDate: true})
            check=false
        }

        if(this.state.initialDate && this.state.finalDate){
            if(this.state.initialDateView > this.state.finalDateView){
                check = false
                popUp.infoPopUp(ConstantsUtil.dataInicialAnteriorFinalMessage)
           }
        }        

        return check
    }

    buscarButtonClick = async () => {
        await this.setStateTableFunction("selectedSituacao", [ConstantsUtil.situacaoRegularValidadaLabel, ConstantsUtil.situacaoRegularAguardandoValidacaoLabel])
        let filtro = this.montaFiltrosColunasDropwdown()
        this.callSearch(filtro)
        this.setState({deveApresentarAvisoFiltroSituacaoNF: true})
    }

    callSearch = (filtro) => {
        this.resetView()
        if(this.checkData()){
            this.search(filtro)
        }
    }

    search = (filtro) => {

        if(this.state.isSearchByNumero){
            /*
            Após o fluxo de deletar ou reativar NF pela tabela, é chamado este método.
            Caso isSearchByNumero seja verdadeiro, refaz então a busca pelo número.
            Correção de "Corrigir exclusão/reativação de NF (https://trello.com/c/CuHG1vNK/531-corrigir-exlcus%C3%A3o-reativa%C3%A7%C3%A3o-de-nf)"
            */
            this.callSearchPesquisaNumero()
            return
        }

        this.setState({habilitaBusca: false})
        this.setState({currentLabel: this.state.NFLabel})
        this.searchByNF(filtro)

        if(this.state.tipoNF === ConstantsUtil.saidaLabel){
            this.searchSaidaGroupByProduct()
        }
    }

    researchOnPage = event => {
        this.setState({
            firstInNFTable: event.first,
            rowsInNFTable: event.rows,
        })

        let filtroPage = {
            pageIndex: event.page,
            pageSize: event.rows
        }

        //Monta o filtro final com o que já foi filtrado antes
        let filterNFTableStateTotal = this.montaFiltrosColunasDropwdown()
        filterNFTableStateTotal = Object.assign(filterNFTableStateTotal, filtroPage)

        this.searchByNF(filterNFTableStateTotal)
    }

    researchOnSort = async event => {
        
        await this.setState({
            sortFieldNFTable: event.sortField,
            sortOrderNFTable: event.sortOrder,
            firstInNFTable: 0,
        })
        

        let sortField = event.sortField
        let deParaFieldList = [
            {de: 'dhEmiJsDate', para: 'dhEmi'},
            {de: 'dataEntradaJsDate', para: 'dataHoraEntrada'},
            {de: 'situacaoLabel', para: 'situacao'},
        ]
        for(let dePara of deParaFieldList) {
            if(sortField === dePara.de){
                sortField = dePara.para
                break;
            }
        }

        let filtroOrdenacao = {
            sortField: sortField,
            sortDirection: event.sortOrder > 0 ? ConstantsUtil.sortDirectionAsc : ConstantsUtil.sortDirectionDesc
        }

        //Monta o filtro final com o que já foi filtrado antes
        let filterNFTableStateTotal = this.montaFiltrosColunasDropwdown()
        filterNFTableStateTotal = Object.assign(filterNFTableStateTotal, filtroOrdenacao)

        this.searchByNF(filterNFTableStateTotal)
    }

    montaFiltrosColunasDropwdown = () => {


        let deParaFiltroList = [
            {de: "selectedDataEmissaoNF", para: "dataEmissaoSemHorarioFiltroList"},
            {de: "selectedDataEntradaNF", para: "dataEntradaSemHorarioFiltroList"},
            {de: "selectedNumeroNF", para: "numeroFiltroList"},
            {de: "selectedFornecedorOuCliente", para: "nomeFornecedorOuClienteFiltroList"},
            {de: "selectedvalorTotalNF", para: "valorTotalFiltroList"},
            {de: "selectedTipoNF", para: "tipoNFFiltroList"},
            {de: "selectedChaveNF", para: "chaveFiltroList"},
            {de: "selectedTipoCriacaoNF", para: "tipoCriacaoFiltroList"},
            {de: "selectedFonteNF", para: "fonteFiltroList"},
            {de: "selectedSefaz", para: "sefazFiltroList"},
            {de: "selectedSituacao", para: "situacaoTratadaFiltroList"},
        ]

        let filterNFTableState = this.state.filterNFTableState

        let filterKeys = Object.keys(filterNFTableState)
        filterKeys.forEach(key => {
            let deParaFiltro = deParaFiltroList.find(deParaFiltro => {
                let result = deParaFiltro.de === key
                return result
            })
            if(deParaFiltro){
                filterNFTableState[deParaFiltro.para] = filterNFTableState[key]
            }
        })

        return filterNFTableState
    }

    onFilter = async event => {

        // console.log('researchOnFilter event: ', event)

        //Seta o que foi filtrado agora
        let filterNFTableState = this.state.filterNFTableState
        // console.log("this.state.filterNFTableState: ", this.state.filterNFTableState)
        filterNFTableState[event.target.name] = event.value
        await this.setState({
            filterNFTableState,
            firstInNFTable: 0
        })

    }
    
    researchOnFilter = () => {
        //Monta o filtro final com o que já foi filtrado antes
        let filterNFTableStateTotal = this.montaFiltrosColunasDropwdown()
        // console.log("onFilter filterNFTableStateTotal: ", filterNFTableStateTotal)
        filterNFTableStateTotal['pageIndex'] = 0

        this.searchByNF(filterNFTableStateTotal)
    }

    limparFiltros = async () => {
        await this.setState({filterNFTableState: {}})
        await this.setStateTableFunction("selectedSituacao", [ConstantsUtil.situacaoRegularValidadaLabel, ConstantsUtil.situacaoRegularAguardandoValidacaoLabel])
        this.researchOnFilter()
    }
    

    searchByNF = (filtro) => {

        this.setState({
            loadingNFTable: true,
            isFinishedNFSearch: false,
            NFData: {}
        })
        
        AuthService.startBlockUi()

        let filtroBasico = {
            filtroTipoData: this.state.selectedFiltrarPor,
            dataInicial: this.state.initialDate,
            dataFinal: this.state.finalDate,
            tipoNF: this.state.tipoNF,
        }
        
        if(GeneralServices.isEmptyObject(filtro)){
            filtro = {}
        }

        let filtroTotal = Object.assign(filtroBasico, filtro)

        const pageIndexToUse = filtro && filtro.pageIndex ? filtro.pageIndex : 0
        const pageSizeToUse = filtro && filtro.pageSize ? filtro.pageSize : ConstantsUtil.initialRowsInNFtable
        
        const sortFieldToUse = filtro && filtro.sortField ? filtro.sortField :
            (this.state.selectedFiltrarPor === ConstantsUtil.filtroDataEmissaoLabel ? 'dhEmi' : 'dataHoraEntrada')

        const sortDirectionToUse = filtro && filtro.sortDirection ? filtro.sortDirection : ConstantsUtil.sortDirectionDesc

        this.NFService.commomSearch(filtroTotal, pageIndexToUse, pageSizeToUse, sortFieldToUse, sortDirectionToUse)
        .then(response => {
            this.trataDadosNF(response.data)
            this.apresentarAvisoFiltroSituacaoNF()
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
        .finally(async () => {
            await this.setState({loadingNFTable: false})
            await this.setState({isFinishedNFSearch: true})
            AuthService.stopBlockUi()

        })        
    }

    apresentarAvisoFiltroSituacaoNF = () => {
        if(
            this.state.deveApresentarAvisoFiltroSituacaoNF
            && this.state.selectedFiltrarPor === ConstantsUtil.filtroDataEmissaoLabel
            && this.state.tipoNF === ConstantsUtil.tipoNFEntradaLabel)
        {
            popUp.infoPopUp(`Apenas as notas "Validadas" e "Aguardando Validação" estão listadas.<br/>
                Para ver as demais notas, altere o filtro "Situação" na tabela abaixo e clique no botão "Filtrar".
            `)

            this.setState({deveApresentarAvisoFiltroSituacaoNF: false})

        }
    }

    searchSaidaGroupByProduct = () => {
        this.setState({loadingProductTable: true})
        this.setState({isFinishedProductSearch: false})
        this.setInfo({})
        this.setState({productTransactionData: {}})
        this.transactionService.searchSaidaGroupByProduct(
            {
                filtroTipoData: this.state.selectedFiltrarPor,
                dataInicial: this.state.initialDate,
                dataFinal: this.state.finalDate,
                tipoNF: this.state.tipoNF,
                situacaoNF: this.state.situacao,
            }
        )
        .then(response => {
            this.trataDadosProdutos(response.data)
            this.setState({transactionGroupByProductList: response.data})
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
        .finally(() => {
            this.setState({loadingProductTable: false})
            this.setState({isFinishedProductSearch: true})
        })        
    }

    trataDadosProdutos(vendasList){
        vendasList.forEach(item => {
            if(!item.valor){
                item.valor = '' //Para permitir filtro pelos valores 0
            }
        })
    }

    toggleButton = () => {
        if(!this.state.habilitaBusca && this.state.isSearchByNumero){
            this.setState({isSearchByNumero: false})
            this.setState({numeroNFToSearch: ''})
        }
        this.setState({habilitaBusca: !this.state.habilitaBusca})
    }

    trataDadosNF(dados){
        // console.log("trataDadosNF: " , dados)


        GeneralServices.trataDadosNF(dados.notasFiciaisList)
        
        this.setInfo(dados)
        this.setState({
            NFData: JSON.parse(JSON.stringify(dados)),
            dataEmissaoSemHorarioFiltroList:  dados.dataEmissaoSemHorarioFiltroList,
            dataEntradaSemHorarioFiltroList:  dados.dataEntradaSemHorarioFiltroList,
            numeroFiltroList: dados.numeroFiltroList,
            nomeFornecedorOuClienteFiltroList: dados.nomeFornecedorOuClienteFiltroList,
            valorTotalFiltroList: dados.valorTotalFiltroList,
            tipoNFFiltroList: dados.tipoNFFiltroList,
            chaveFiltroList: dados.chaveFiltroList,
            tipoCriacaoFiltroList: dados.tipoCriacaoFiltroList,
            fonteFiltroList: dados.fonteFiltroList,
            sefazFiltroList: dados.sefazFiltroList,
            situacaoTratadaFiltroList: dados.situacaoTratadaFiltroList

        })
        
        //PARA DEBUG
        // let transactionsIdsByNF = []
        // let totalValorTotalByNF = 0
        // dados.notasFiciaisList.forEach(notaFiscal => {
        //     let valorTotalByNF = 0
        //     notaFiscal.transactions.forEach(transaction => {
        //         transactionsIdsByNF.push(transaction.id)
        //         // console.log("transaction: ", transaction)
        //         valorTotalByNF += transaction.valor
        //     })
        //     totalValorTotalByNF += notaFiscal.valorTotal
        //     let diff = valorTotalByNF - notaFiscal.valorTotal
        //     // console.log("diff: ", diff)
        //     if( Math.abs(diff) > 1 ){
        //         console.log("NF com Valor Total Divergente: ", notaFiscal.id, notaFiscal.valorTotal, valorTotalByNF, diff)
        //     }
        // })
        // transactionsIdsByNF = transactionsIdsByNF.sort( (a, b) => parseInt(a) - parseInt(b) )
        // console.log("transactionsIdsByNF: ", transactionsIdsByNF)
        // console.log("valor total by NF: ", totalValorTotalByNF)
    }

    setInfo = (info) => {
        // console.log("setInfo: ", info)
        this.setState({totalValue: GeneralServices.valueBodyTemplate(info.valorTotal)})
        this.setState({quantidadeItems: info && info.quantidade ? info.quantidade : 0})
    }

    getQuantidadeLabel = () => {
        switch(this.state.currentLabel){
            case this.state.NFLabel:
                return 'Quantidade de Notas:'
            case this.state.productLabel:
                return 'Quantidade de Lançamentos:'
        }        
    }

    searchByNumero = () => {
        this.setState({pesquisaNumeroDialogVisible: true})
    }

    handleNumeroChange = async (event) => {
        let value = event.target.value
        const name = event.target.name

        if(value && value < 0){
            value*=-1
        }

        this.setState({[name]: value})
    }

    handlePesquisaNumeroKeyPress = async (e) => {
        if (e.key === "Enter") {
            this.callSearchPesquisaNumero()
          }
    }    

    hidePesquisaNumeroDialog = () => {
        this.setState({pesquisaNumeroDialogVisible: false})
        this.setState({inputSearchNumeroErrorClass: ''})
        // this.setState({numeroNFToSearch: ''})
    }

    checkPesquisaNumeroData = () => {
        let check = true
        if(!this.state.numeroNFToSearch){
            check = false
            this.setState({inputSearchNumeroErrorClass: ConstantsUtil.bootstrapInputErrorClass})
        }

        return check
    }

    callSearchPesquisaNumero = () => {
        this.setState({inputSearchNumeroErrorClass: ''})
        if(this.checkPesquisaNumeroData()){
            this.searchPesquisaByNumero()
        }
    }

    searchPesquisaByNumero(){
        this.setState({habilitaBusca: false})
        this.setState({loadingNFTable: true})
        this.setState({isFinishedNFSearch: false})
        this.setState({isSearchByNumero: true})
        this.NFService.searchByNumero(this.state.numeroNFToSearch)
        .then(response => {
            this.trataDadosNF(response.data)
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
        .finally(() => {
            this.setState({loadingNFTable: false})
            this.setState({isFinishedNFSearch: true})
            this.hidePesquisaNumeroDialog()
        })
    }

    setStateTableFunction = (name, value) => {
        // console.log("name: ", name)
        // console.log("value: ", value)
        let filterNFTableState = this.state.filterNFTableState
        filterNFTableState[name] = value
        this.setState({filterNFTableState})
        // console.log("filterNFTableState: ", filterNFTableState)
    }

    render() {

        const renderButtons = () => {
            if(this.state.habilitaBusca){
                return (
                <div className = "col-md-12">
                    <Button 
                        label="Buscar"
                        icon="pi pi-search"
                        onClick = {this.buscarButtonClick}
                    />
                </div>
                )
            }
            return (
                <>
                <div className = "col-md-12">
                    <Button 
                        label="Voltar"
                        icon="pi pi-undo"
                        className="p-button-danger"
                        onClick = {() => {
                            this.setState({filterNFTableState: {}})
                            this.toggleButton()
                        }}
                    />
                </div>
                </>
            )            
        }

        const renderViewType = () => {
            if(!this.state.habilitaBusca){
                return (
                    <div
                        className="p-col-12 p-md-3 "
                        style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'}}
                    >
                    
                    <Button
                        label={this.state.NFLabel}
                        className={this.state.currentLabel === this.state.NFLabel ? " " : "p-button-text"}
                        onClick={ () => this.changeLabel(this.state.NFLabel)}
                        disabled={!this.state.isFinishedNFSearch}
                    />
    
                    <Button
                        label={this.state.productLabel}
                        className={this.state.currentLabel === this.state.productLabel ? " " : "p-button-text"}
                        onClick={ () => this.changeLabel(this.state.productLabel)}
                        disabled={!this.state.isFinishedProductSearch || this.state.isSearchByNumero}
                    />                    
    
                    </div>
                )
            }
        }

        const renderNFTable = () => {
            if(this.state.isFinishedNFSearch){
                return (
                    <TransactionByNFTablePageable
                        list = {this.state.NFData.notasFiciaisList}
                        search={this.search}
                        startLoadingTable={() => this.setState({loadingNFTable: true})}
                        finishLoadingTable={() => this.setState({loadingNFTable: false})}
                        tipoNF={this.state.tipoNF}
                        isSearchByNumero={this.state.isSearchByNumero}
                        setInfo={this.setInfo}
                        loading={this.state.loadingNFTable}
                        conferenciaNF
                        situacao={this.state.situacao}
                        quantidadeNotas={this.state.quantidadeItems}
                        dataEmissaoSemHorarioFiltroList={this.state.dataEmissaoSemHorarioFiltroList}
                        dataEntradaSemHorarioFiltroList={this.state.dataEntradaSemHorarioFiltroList}
                        numeroFiltroList={this.state.numeroFiltroList}
                        nomeFornecedorOuClienteFiltroList={this.state.nomeFornecedorOuClienteFiltroList}
                        valorTotalFiltroList={this.state.valorTotalFiltroList}
                        tipoNFFiltroList={this.state.tipoNFFiltroList}
                        chaveFiltroList={this.state.chaveFiltroList}
                        tipoCriacaoFiltroList={this.state.tipoCriacaoFiltroList}
                        fonteFiltroList={this.state.fonteFiltroList}
                        sefazFiltroList={this.state.sefazFiltroList}
                        situacaoTratadaFiltroList={this.state.situacaoTratadaFiltroList}
                        firstInTable={this.state.firstInNFTable}
                        rowsInTable={this.state.rowsInNFTable}
                        sortField={this.state.sortFieldNFTable}
                        sortOrder={this.state.sortOrderNFTable}
                        researchOnPage={this.researchOnPage}
                        researchOnSort={this.researchOnSort}
                        onFilter={this.onFilter}
                        researchOnFilter={this.researchOnFilter}
                        setStateFunction={this.setStateTableFunction}
                        filterState={this.state.filterNFTableState}
                    />
                )
            }
        }

        const renderProductTable = () => {
            return (
                <TransactionByProductTable
                    list = {this.state.transactionGroupByProductList}
                    loading = {this.state.loadingProductTable}
                    tipo={this.state.tipoNF}
                    initialDate = {this.state.initialDate}
                    finalDate = {this.state.finalDate}
                />                
            )
        }

        const renderTables = () => {
            if(this.state.currentLabel === this.state.NFLabel){
                return renderNFTable()
            }
            else{
                return renderProductTable()
            }
        }

        const renderPreHeader = () => { 
            if(this.state.currentLabel === this.state.NFLabel){
                return (
                    <div className = "card mb-3">
                        <div className = "card-header d-flex justify-content-between align-items-center">
                            <Button
                                label={`${this.getQuantidadeLabel()} ${this.state.quantidadeItems}` }
                            />

                        <div className="flex-grow-1 text-center">
                            <Button
                                label="Filtrar"
                                icon="pi pi-filter"
                                onClick={this.researchOnFilter}
                            />
                            <Button
                                className='right-button p-button-danger'
                                label="Limpar Filtros"
                                icon="pi pi-times"
                                onClick={this.limparFiltros}
                            />
                        </div>

                            <Button
                                className="p-button-success ml-auto"
                                label={'Valor Total: ' + this.state.totalValue }
                            />

                        </div>
                    </div>
                )
            }
        }

        const renderResult = () => {
            if(!this.state.habilitaBusca){
                return (
                    <>
                    {renderPreHeader()}
                    {renderTables()}
                    </>               
                )
            }

        }

        const renderPesquisaPorNumeroButton = () => {
            return (
            <Button 
                label="Pesquisar Por Número"
                icon="pi pi-search"
                onClick = {this.searchByNumero}
                disabled={!this.state.habilitaBusca}
            />
            )
        }

        const dialogFooter = (
            <DialogFooter save = {this.callSearchPesquisaNumero} hideDialog = {this.hidePesquisaNumeroDialog}/>
        )

        const renderPesquisaNumeroDialog = () => {
            return (
                <Dialog
                    visible={this.state.pesquisaNumeroDialogVisible}
                    header={"Pesquisar Por Número"}
                    modal = {true}
                    style={{ width: '350px' }}
                    footer={dialogFooter}
                    onHide={this.hidePesquisaNumeroDialog}
                >
                    <div className="p-field">
                        {renderValueInput(true)}                     
                    </div>
                </Dialog>
            )
        }

        const renderValueInput = () => {
            return (
                <>
                <div className="row">
                <div className="col-md-8">
                <label htmlFor="numeroNF">Número da Nota Fiscal</label>
                <input
                    type="number"
                    className={"form-control " + this.state.inputSearchNumeroErrorClass}
                    autoFocus
                    value = {this.state.numeroNFToSearch}
                    name="numeroNFToSearch"
                    onChange={this.handleNumeroChange}
                    onKeyPress={this.handlePesquisaNumeroKeyPress}
                    id="numeroNFToSearch"
                />
                <div className="invalid-feedback">Preencha</div>                
                </div>
                </div>
                <div className="row">
                <div className="col-md-12">
                <small id="valorError" className="p-error">{this.state.errorSearchValueMessage}</small>
                </div>
                </div>
                </>
            )
        }

        const renderCommonSearch = () => {
            if(!this.state.isSearchByNumero){
                return (
                    <>
                    <div className="row">

                        <div className = "col-md-2">
                            <label htmlFor="filtro">Filtrar Por</label>
                            <SelectMenu
                                className={"form-control " + this.state.inputNFFilterTypeErrorClass }
                                name="selectedFiltrarPor"
                                list= {ConstantsUtil.filtroDataNFOptionsList} 
                                value={this.state.selectedFiltrarPor}
                                onChange={this.handleFiltrarPorChange}
                                disabled={!this.state.habilitaBusca}
                            />
                            <div className="invalid-feedback">{ConstantsUtil.preecnhaLabel}</div>
                        </div>                        
    
                        <DateIntervalCalendar
                            htmlFor="SelectDatePeriod"
                            label="Escolher período"
                            handleFilterChange={this.handleDateFilterChange}
                            handleDateChange={this.handleDateChange}
                            missingFilterType={this.state.missingFilterType}
                            missingAnyDate={this.state.missingAnyDate}
                            disabled={!this.state.habilitaBusca}
                            // tooltip={"O período selecionado neste filtro será em relação à Data de Emissão da Nota Fiscal. \n Caso queira filtrar por outras datas (como entrada ou importação), utilize os filtros da tabela que será gerada abaixo."}
                        />                    
    
                        <div className = "col-md-2">
                        <FormGroup label = "Tipo da Nota Fiscal" htmlFor = "InputType">
                            <SelectMenu
                                className={"form-control " + this.state.inputTypeErrorClass}
                                name="tipoNF"
                                list= {this.transactionService.getTypeList()}
                                value={this.state.tipoNF}
                                onChange={this.handleTipoNotaFiscalChange}
                                disabled={!this.state.habilitaBusca || this.state.selectedFiltrarPor === ConstantsUtil.filtroDataEntradaLabel}
                                />
                            <div className="invalid-feedback">{this.state.errorTypeMessage}</div>
                        </FormGroup>
                        </div>
                    </div>
                    </>
                )
            }
        }

        const renderSearchByNumero = () => {
            if(this.state.isSearchByNumero){
                return (
                    <div className="row">
                        <div className="col-md-12">
                        <label htmlFor="valor">Pesquisa pela NF de Número:</label>
                        <input
                            style={{marginLeft: '0.5rem'}}
                            type="text"
                            name="numeroNFToSearch"
                            inputId="numeroNFToSearch"
                            autoFocus
                            value={this.state.numeroNFToSearch}
                            disabled
                        />
                        </div>
                        <br />
                    </div>                    
                )
            }
        }

        return (
                   
            <div className="bs-docs-section" >
                
                <Card
                    title = "Conferência de NFs"
                    rightComponent={renderPesquisaPorNumeroButton()}
                > 
                
                <div className = "col-md-12">
                    {renderCommonSearch()}
                    {renderSearchByNumero()}
                </div>
                    
                {renderButtons()}

                {renderViewType()}

                {renderResult()}

                {renderPesquisaNumeroDialog()}

                </Card>
                <div className="d-flex "/>
            </div>
              
        )
    }


}

ConferenciaNF.contextType = AuthContext

export default withRouter(ConferenciaNF)