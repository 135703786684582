import React from 'react'
import { Toast } from 'primereact/toast'
import { Button } from 'primereact/button'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'

import TiposDeRecebimentoService from '../../app/service/tiposDeRecebimentoService'
import EditTipoDeRecebimentoDialog from '../../components/tipoDeRecebimento/editTpoDeRecebimentoDialog'
import '../../css/primereactTable.css'
import ConstantsUtil from '../../context/constantsUtil'
import CustomHighlighter from '../../components/customHighlighter'
import { Dialog } from 'primereact/dialog'
import HandleErrorService from '../../app/service/handleErrorService'
import * as popUp from '../../components/toastr'
import GeneralServices from '../../app/service/generalServices'

class TiposDeRecebimentoTable extends React.Component {
    
    constructor(){
        super()
        this.dt = React.createRef()
        this.tipoDeRecebimentoService = new TiposDeRecebimentoService()
    }

    state = {
        id: null,

        numeroDeChamada: null,

        nomeTipoDeRecebimento: '',

        natureza: '',

        prazo: '',
        
        taxa: '',

        tipoDeCorte: '',
        
        diaDaSemana: '',

        diasDoMes: [],

        tiposDeRecebimento: [],

        naturezaField: 'natureza',
        idField: 'id',
        nameField: 'nomeTipoDeRecebimento',
        taxaField: 'taxa',
        prazoFiled: "prazo",
        tipoDeCorteField: "tipoDeCorte",
        diaDaSemanaField: "diaDaSemana",
        diasDoMesField: "diasDoMes",

        selectedTipoDeRecebimento: null,
        editTipoDeRecebimentoDialog: false,
        editTipoDeRecebimentoDialogHeader: "Editar Tipo De Recebimento",

        contaBancariaId: null,

        contaContabil: '',

        tipoDeRecebimentoToDelete: '',
        displayDeleteConfirmation: false,

        loading: false,

    }

    hideDialog = () => {
        this.setState({editTipoDeRecebimentoDialog: false})
    }

    handleSelectionChange = (event) => {
        this.setState({selectedTipoDeRecebimento: event.value})
        this.props.handleSelectionTipoDeRecebimentoChange(event.value)
    }

    // exportCSV = async () => {
    //     await this.setState({dateView: "dhEmi"})
    //     this.dt.current.exportCSV();
    //     this.setState({dateView: "timestamp"})
    // }

    editTipoDeRecebimento = (tipoDeRecebimento) => {
            this.setState({id: tipoDeRecebimento.id})
            this.setState({numeroDeChamada: tipoDeRecebimento.numeroDeChamada})
            this.setState({nomeTipoDeRecebimento: tipoDeRecebimento.nomeTipoDeRecebimento})
            this.setState({natureza: tipoDeRecebimento.natureza})
            this.setState({taxa: tipoDeRecebimento.taxa})
            this.setState({prazo: tipoDeRecebimento.prazo})
            this.setState({tipoDeCorte: tipoDeRecebimento.tipoDeCorte})
            this.setState({diaDaSemana: tipoDeRecebimento.diaDaSemana})
            this.setState({diasDoMes: tipoDeRecebimento.diasDoMes})
            this.setState({contaBancariaId: tipoDeRecebimento.contaBancariaId})
            this.setState({contaContabil: tipoDeRecebimento.contaContabil})
            this.setState({destacadoDRE: tipoDeRecebimento.destacadoDRE})

            this.setState({editTipoDeRecebimentoDialog: true})
    }

    callDelete = (tipoDeRecebimentoToDelete) => {
        this.setState({tipoDeRecebimentoToDelete})
        this.setState({displayDeleteConfirmation: true})
    }

    hideDeleteDialog = () => {
        this.setState({tipoDeRecebimentoToDelete: null})
        this.setState({displayDeleteConfirmation: false})
    }

    reativarTipoDeRecebimento = tipoDeRecebimento => {
        this.setState({loading: true})
        this.tipoDeRecebimentoService.reativar(tipoDeRecebimento)
        .then(response => {
            popUp.successPopUp("Tipo de Recebimento reativado com sucesso!")
            this.props.search()
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
        .finally( () => {
            this.setState({loading: false})
        })        
    }

    delete = () => {
        
        this.setState({loading: true})
        this.setState({displayDeleteConfirmation: false})
        
        this.tipoDeRecebimentoService.delete(this.state.tipoDeRecebimentoToDelete)
        .then(async response => {
            popUp.successPopUp("Tipo de Recebimento deletado com sucesso!")
            this.props.search()
            this.hideDeleteDialog()
        }).catch(async error => {
            HandleErrorService.handleError(error)
        })
        .finally( () => {
            this.setState({loading: false})
        })
    }

    verificaPermissaoAlterarTipoDeRecebimento = tipoDeRecebimento => {
        return (
            ![
                ConstantsUtil.naturezaTipoDeRecebimentoAutomaticoLabel,
                ConstantsUtil.naturezaTipoDeRecebimentoBoletoLabel,
                ConstantsUtil.naturezaTipoDeRecebimentoAplicacaoFinanceiraLabel
            ].includes(tipoDeRecebimento.natureza)
            &&
            tipoDeRecebimento.nomeTipoDeRecebimento !== ConstantsUtil.naoIdentificadoLabel
        )
    }

    render (){

        // const leftToolbarTemplate = () => {
        //     return (
        //         <React.Fragment>
        //             {/* <FileUpload mode="basic" accept="*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="p-mr-2 p-d-inline-block" /> */}
        //             <Button label="Exportar" icon="pi pi-upload" className="p-button-help" onClick={this.exportCSV} />
        //         </React.Fragment>
        //     )
        // }

        const actionBody = (tipoDeRecebimento) => {
            if(tipoDeRecebimento.ativo){
                return actionBodyAtivo(tipoDeRecebimento)
            }
            return actionBodyInativo(tipoDeRecebimento)
        }

        const actionBodyInativo = (tipoDeRecebimento) => {
            if(this.verificaPermissaoAlterarTipoDeRecebimento(tipoDeRecebimento)){
                return (
                    <>
                        <Button
                            tooltip="Reativar Tipo de Recebimento"
                            tooltipOptions={{position: 'left'}}
                            icon="pi pi-undo"
                            className={ "p-button-rounded p-mr-2 " }
                            onClick={() => this.reativarTipoDeRecebimento(tipoDeRecebimento)}
                            style={ {maxHeight: '30px', maxWidth: '30px'} }
                        />
                    </>
                )
            }
        }

        const actionBodyAtivo = (tipoDeRecebimento) => {
            if(this.verificaPermissaoAlterarTipoDeRecebimento(tipoDeRecebimento))
            return (
                <React.Fragment>
                    <Button
                        tooltip="Editar Tipo de Recebimento"
                        tooltipOptions={{position: 'left'}}
                        icon="pi pi-pencil"
                        className={ "p-button-rounded p-mr-2 " }
                        onClick={() => this.editTipoDeRecebimento(tipoDeRecebimento)}
                        style={ {maxHeight: '30px', maxWidth: '30px'} }
                    />
                    <Button
                        tooltip={"Inativar Tipo de Recebimento.\nEle continuará existindo no sistema e com os lançamentos passados matidos.\nNo entanto, este tipo de recebimento não mais aparecerá nas listagens para lançamentos."}
                        tooltipOptions={{position: 'left'}}
                        icon="pi pi-trash"
                        className={ "p-button-rounded p-mr-2 p-button-danger" }
                        style ={{marginLeft: '10px', maxHeight: '30px', maxWidth: '30px'}}
                        onClick={() => this.callDelete(tipoDeRecebimento)}
                    />
                </React.Fragment>
            );
        }

        const renderNumeroDeChamadaColumn = () => {
            return (
                <Column field="numeroDeChamada" header="N° Chamada" sortable style ={ {width: '80px'} }
                    body={rowData => 
                        // rowData.id
                        <CustomHighlighter
                            searchWords={[this.props.nomeTipoDeRecebimentoSearch]}
                            textToHighlight={rowData.numeroDeChamada}
                        />
                    }    
                />
            )
        }

        const renderSituacaoColumn = () => {
            return (
                <Column
                    header="Situação"
                    field="ativo"
                    sortable
                    body={GeneralServices.renderStyledSituacaoTableColumn}
                    style ={ {width: '60px'} }
                />
            )
        }

        const renderAllTable = () => {
            return(
                <DataTable
                    ref={this.dt}
                    value={this.props.list}
                    className="p-datatable-sm small-font"
                    // className="p-datatable-lg"
                    rowHover
                    // selection={this.state.selectedTiposDeRecebimento}
                    // onSelectionChange={(e) => this.setState({selectedTiposDeRecebimento: e.value})}
                    scrollable
                    // scrollHeight="500px"
                    loading={this.props.loading || this.state.loading}
                    emptyMessage="Nenhum tipo de recebimento encontrado."
                    dataKey="id"
                    paginator rows={ConstantsUtil.initialTableQuantityOption}
                    paginatorTemplate={GeneralServices.tablePaginatorTemplate(ConstantsUtil.initialTableQuantityOption, this.props.list ? this.props.list.length : '')}
                    currentPageReportTemplate="Mostrando de {first} ao {last} de {totalRecords} Itens"
                >

                    {renderNumeroDeChamadaColumn()}

                    <Column field={this.state.nameField} header="Nome" sortable
                        style ={ {width: '160px'} }
                        body={rowData => 
                        <CustomHighlighter
                            searchWords={[this.props.nomeTipoDeRecebimentoSearch]}
                            textToHighlight={rowData.nomeTipoDeRecebimento}
                        />
                    }    
                    />

                    <Column field={this.state.naturezaField} header="Natureza" sortable
                        style ={ {width: '180px'} }
                    />

                    <Column field={this.state.taxaField} header="Taxa (%)" sortable
                        style ={ {width: '60px'} } 
                        render={true}
                    />

                    <Column field={this.state.prazoFiled} header="Prazo" sortable
                        style ={ {width: '60px'} }
                    />

                    <Column field={this.state.tipoDeCorteField} header="Tipo de Corte" sortable
                        style ={ {width: '170px'} } 
                    />

                    <Column field = {this.state.diaDaSemanaField} header="Dia da Semana (corte)"
                        style ={ {width: '100px'}}
                    />
                    
                    <Column field={this.state.diasDoMesField} header="Dia(s) do Mês (corte)"
                    body={rowData => this.tipoDeRecebimentoService.adjustDiasDoMesVisualization(rowData.diasDoMes)}
                        style ={ {width: '100px'} }
                    />

                    {renderSituacaoColumn()}
                    
                    <Column header="Destacado no DRE" field='destacadoDRE'
                        body={rowData => rowData.destacadoDRE ? "Sim" : "Não"}
                        style ={ {width: '80px'}}
                    />

                    <Column header="Editar" body={actionBody}
                        style ={ {width: '80px'} }
                    />

                </DataTable>
            )
        }

        const renderSimpleTable = () => {
            return(
                <DataTable ref={this.dt} value={this.props.list}
                            className="p-datatable-sm small-font" //small table
                            rowHover
                            selection={this.state.selectedTipoDeRecebimento}
                            // onSelectionChange={(e) => this.setState({selectedTiposDeRecebimento: e.value})}
                            onSelectionChange={this.handleSelectionChange}
                            // scrollable
                            // scrollHeight="500px"
                            loading={this.props.loading}
                            emptyMessage="Nenhum tipo de recebimento encontrado."
                            dataKey="id"
                    >

                    <Column selectionMode="single" headerStyle={{ width: '3rem' }}></Column>

                    {renderNumeroDeChamadaColumn()}

                    <Column field={this.state.nameField} header="Nome" sortable style ={ {width: '140px'} }
                        body={rowData => 
                        <CustomHighlighter
                            searchWords={[this.props.nomeTipoDeRecebimentoSearch]}
                            textToHighlight={rowData.nomeTipoDeRecebimento}
                        />
                        }    
                    />

                    <Column field={this.state.naturezaField} header="Natureza" sortable style ={ {width: '140px'} }
                    />

                    {renderSituacaoColumn()}

                </DataTable>
            )
        }

        const renderTable = () => {
            if(!this.props.simpleView){ //nonSimpleView
                return renderAllTable()
            }

            else{ //simpleView
                return renderSimpleTable()
            }
        }

        const renderDeleteConfirmationFooter = () => {
            return (
                <div>
                    <Button label="Confirmar" icon="pi pi-check"
                        // onClick={this.deleteLancamentoFechamentoBancario} 
                        onClick={this.delete} 
                        autoFocus
                    />
                    <Button label="Cancelar" icon="pi pi-times" onClick={this.hideDeleteDialog}
                            className="p-button-text" />
                </div>
            );
        }


        return (
            <div className="datatable-crud-demo">
            <Toast ref={this.toast} />

            <div className="card">
                {/* <Toolbar className="p-mb-4" 
                    left={leftToolbarTemplate} 
                    right={rightToolbarTemplate}
                /> */}

                {renderTable()}

            </div>

            <EditTipoDeRecebimentoDialog
                hideDialog={this.hideDialog}
                visible={this.state.editTipoDeRecebimentoDialog}
                header={this.state.editTipoDeRecebimentoDialogHeader}
                state={this.state}
                search={this.props.search}
                push={this.props.push}
                // disabled={true}
            />

            <Dialog
                header="Deletar Tipo de Recebimento"
                visible={this.state.displayDeleteConfirmation}
                modal = {true} //congela restante da tela
                style={{ width: '350px' }}
                footer={renderDeleteConfirmationFooter()}
                onHide={this.hideDeleteDialog}
                >
                <div className="confirmation-content row" style={{marginLeft: '10px'}}>
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem', marginRight: '10px'}} />
                <div style={{marginBottom: '10px'}}> Deseja confirmar? </div>
                </div>
            </Dialog>            


        </div>
        )
    }


}  

export default TiposDeRecebimentoTable