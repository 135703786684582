import WorkerApiService from "../workerApiServices";


class SystemDataService extends WorkerApiService {

    constructor() {
        super('/api/systemData')
    }

    getSystemData(){
        return this.get('/getSystemData')
    }

    getLoggedSystemData(){
        return this.get('/getLoggedSystemData')
    }

}

export default SystemDataService