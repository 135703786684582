import React from 'react'
import Card from '../../components/card'
import { withRouter } from 'react-router-dom'

import { AuthContext } from '../../main/authProvider'
import GeneralServices from '../../app/service/generalServices'
import HandleErrorService from '../../app/service/handleErrorService'
import { Button } from 'primereact/button'
import DateIntervalCalendar from '../../components/calendar/dateIntervalCalendar'
import ConstantsUtil from '../../context/constantsUtil'
import DREService from '../../app/service/relatorios/DREService'
import * as xlsx from 'xlsx'
import DRESaldoConsolidadoTable from '../../components/relatorios/DRE/DRE_Consolidado/DRESaldoConsolidadoTable'
import DRERecebimentoConsolidadoTable from '../../components/relatorios/DRE/DRE_Consolidado/DRERecebimentoConsolidadoTable'
import DREPagamentoConsolidadoTable from '../../components/relatorios/DRE/DRE_Consolidado/DREPagamentoConsolidadoTable'
import DREResultadoConsolidadoTable from '../../components/relatorios/DRE/DRE_Consolidado/DREResultadoConsolidadoTable'
import DREDestacadoConsolidadoTable from '../../components/relatorios/DRE/DRE_Consolidado/DREDestacadoConsolidadoTable'
import DREDiferencaConsolidadoTable from '../../components/relatorios/DRE/DRE_Consolidado/DREDiferencaConsolidadoTable'
import FormGroup from '../../components/form-group'
import EmpresasMultiSelect from '../../components/empresas/empresasMultiSelect'
import { InputSwitch } from 'primereact/inputswitch'
import CentralService from '../../app/service/central/centralService'
import AuthService from '../../app/service/authService'

class DREConsolidado extends React.Component {

    constructor(){
        super();
        this.generalServices = new GeneralServices();
        this.DREService = new DREService();
        this.centralService = new CentralService();
        
        this.saldoInicialRef = React.createRef()
        this.saldoInicialDtRef = React.createRef()

        this.recebimentoRef = React.createRef()
        this.recebimentoDtRef = React.createRef()

        this.pagamentoRef = React.createRef()
        this.pagamentoDtRef = React.createRef()

        this.destacadoRef = React.createRef()

        this.resultadoRef = React.createRef()

        this.resultadoDestacadoRef = React.createRef()
        this.resultadoDestacadoDtRef = React.createRef()

        this.saldoFinalRef = React.createRef()
        this.saldoFinalDtRef = React.createRef()

        this.diferencaRef = React.createRef()

        this.buttonRef = React.createRef()


    }
    
    state = {

        loading: false,

        DREConsolidado: '',

        selectedDateFilterType: '',
        missingFilterType: false,
        missingAnyDate: false,
        initialDate: '',
        initialDateView: '',
        finalDate: '',
        finalDateView: '',

        unificarEmpresas: '',
        selectedEmpresas: [],
        inputEmpresasErrorClass: '',
        errorEmpresasErrorMessage: '',

        isSearchingLancamentos: false,
        isFinishedSearch: false,

        habilitaBusca: true,

    }

    resetDateView = () => {
        this.setState({initialDate: ''})
        this.setState({initialDateView: ''})
        this.setState({finalDate: ''})
        this.setState({finalDateView: ''})
    }

    handleNavigatorDateChange = (event, e) => {
        e.onChange(event.originalEvent, event.value)
    }

    handleDateFilterChange = (selectedDateFilterType) => {
        this.setState({selectedDateFilterType})
        // this.buttonRef.current.focus()
    }

    handleInitialMonthDateChange = async (initialDate) => {
        await this.setState({initialDate})
        await this.setState({initialDateView: GeneralServices.convertBrStringToJsDate(initialDate)})

        // console.log("initialDate: ", this.state.initialDate)
        // console.log("finalDate: ", this.state.finalDate)
    }

    handleFinalMonthDateChange = async (finalDate) => {
        await this.setState({finalDate})
        await this.setState({finalDateView: GeneralServices.convertBrStringToJsDate(finalDate)})

        // console.log("initialDate: ", this.state.initialDate)
        // console.log("finalDate: ", this.state.finalDate)
    }

    handleDateChange = async (initialDate, finalDate) => {
        // await this.setState({initialDate})
        
        // await this.setState({initialDateView: GeneralServices.convertBrStringToJsDate(initialDate)})
        await this.handleInitialMonthDateChange(initialDate)

        // await this.setState({finalDate})
        // await this.setState({finalDateView: GeneralServices.convertBrStringToJsDate(finalDate)})
        await this.handleFinalMonthDateChange(finalDate)

        // console.log("initialDate: ", this.state.initialDate)
        // console.log("finalDate: ", this.state.finalDate)

    }

    resetView = () => {
        this.setState({inputContaContabilErrorClass: ''})

        this.setState({DRE: {}})
        this.setState({missingFilterType: false})
        this.setState({missingAnyDate: false})

        this.setState({inputEmpresasErrorClass: ''})
        this.setState({errorEmpresasErrorMessage: ''})

    }

    checkData = () => {

        var check = true

        if(!this.state.selectedDateFilterType){
            this.setState({missingFilterType: true})
            check=false
        }
        
        if(!this.state.initialDate || !this.state.finalDate){
            this.setState({missingAnyDate: true})
            check=false
        }

        if(this.state.unificarEmpresas){
            if(!this.state.selectedEmpresas || this.state.selectedEmpresas.length === 0){
                this.setState({inputEmpresasErrorClass: ConstantsUtil.primereactInputErrorClass})
                this.setState({errorEmpresasErrorMessage: ConstantsUtil.errorSelecioneEmpresasMessage})
                check = false
            }
        }        

        return check
    }

    toggleButton = async () => {
        if(!this.state.habilitaBusca){
            //Clicou em "Voltar"
            await this.setState({DREConsolidado: ''})
        }
        await this.setState({habilitaBusca: !this.state.habilitaBusca})
    }

    reSearch = () => {
        this.toggleButton()
        this.callSearch()
    }

    callSearch = () => {

        this.resetView()

        if(this.checkData()){
            this.search()
        }
    }

    getDREConsolidado = () => {
        if(this.state.unificarEmpresas){
            return this.centralService.getDREConsolidadoUnificado(this.state.initialDate, this.state.finalDate, this.state.selectedEmpresas)
        }
        else{
            return this.DREService.getDREConsolidado(this.state.initialDate, this.state.finalDate)
        }
    }

    search = () => {
        this.setState({loading: true})
        this.toggleButton()
        this.setState({isSearchingLancamentos: true})
        this.setState({isFinishedSearch: false})

        // this.DREService.getDREConsolidado(this.state.initialDate, this.state.finalDate)
        this.getDREConsolidado()
        .then(async response => {
            // const data = this.format(response.data)
            // console.log("response.data: ", response.data)
            await this.setState({DREConsolidado: response.data})
            this.trataLabelsDRE()
            // console.log("DREConsolidado: ", this.state.DREConsolidado)
            await this.generalServices.sleep(100)
            this.setState({isSearchingLancamentos: false})
        })
        .catch(error => {
            console.log("error: ", error)
            HandleErrorService.handleError(error)
            this.setState({isSearchingLancamentos: false})
        })
        .finally(() => {
            this.setState({isFinishedSearch: true})
            this.setState({loading: false})
        })
    }

    trataLabelsDRE = () => {
        this.trataLabelsDREInList(this.state.DREConsolidado.dreRecebimentoList, this.state.DREConsolidado.descricaoMesesList)
        this.trataLabelsDREInList(this.state.DREConsolidado.drePagamentoList, this.state.DREConsolidado.descricaoMesesList)
        this.trataLabelsDREInList(this.state.DREConsolidado.dreDestacadoList, this.state.DREConsolidado.descricaoMesesList)

        this.trataLabelsDRESaldo(this.state.DREConsolidado.saldoInicial, this.state.DREConsolidado.descricaoMesesList)
        this.trataLabelsDRESaldo(this.state.DREConsolidado.saldoFinal, this.state.DREConsolidado.descricaoMesesList)
    }

    trataLabelsDRESaldo(list, descricaoMesesList){
        // console.log("trataLabelsDRESaldo: ", list)
        list.forEach(item => {

            item.nome = GeneralServices.contaBancariaFormattedNameInDRE(item)
            item.realNome = item.nome
            item.showNome = item.nome

            this.state.DREConsolidado.descricaoMesesList.forEach(descricaoMes => {
                // console.log("descricaoMes: ", descricaoMes)
                // console.log("item: ", item)
                const mesValor = item.valorMap[descricaoMes]
                item.valorMap[descricaoMes] =
                {
                    total: mesValor,
                    showTotal: mesValor,
                    realTotal: mesValor
                }
            })

        })

        list.forEach(item => {
            descricaoMesesList.forEach(descricaoMes => {
                let total = item.valorMap[descricaoMes].total
                item[descricaoMes] = total == 0 ? '' : total
            })
        })        

        // console.log("lista tratada: ", list)
    }

    onFilterSaldoTable = (currentList, dt) => {
        if(currentList && currentList.length > 0){
            //caso tenha lista após filtro, coloca os dados de um dos itens na linha de totais, para que ele também apareça no filtro
            const templateItem = currentList[0]
            let totaisItem = GeneralServices.findTotalItem(dt.current.props.value)
            //Iguala linha de totais a um dos itens resultantes do filtro, para que totais apareça também na lista filtrada.
            totaisItem.nome =  templateItem.nome
            // totaisItem.total =  templateItem.total
            // totaisItem.porcentagem =  templateItem.porcentagem
            this.state.DREConsolidado.descricaoMesesList.forEach(descricaoMes => {
                const totalMesObj = totaisItem.valorMap[descricaoMes]
                const templateMesObj = templateItem.valorMap[descricaoMes]
                if(totalMesObj && templateMesObj){
                    totalMesObj.total = templateMesObj.total
                }
            })

            let totalObjFiltrado = this.calculaTotalSaldoFiltrado(currentList)
            this.state.DREConsolidado.descricaoMesesList.forEach(descricaoMes => {
                const totalSomaMes = totalObjFiltrado.objTotalMeses[descricaoMes];
                let mesInTotalItem = totaisItem.valorMap[descricaoMes]
                if(!mesInTotalItem){
                    mesInTotalItem={}
                }
                mesInTotalItem.showTotal = totalSomaMes
            })

            // console.log("totaisItem: ", totaisItem)

        }
    }
    
    calculaTotalSaldoFiltrado = list => {
        let objTotalMeses = {}
        this.state.DREConsolidado.descricaoMesesList.forEach(descricaoMes => {
            objTotalMeses[descricaoMes] = 0
        })

        list.forEach(item => {
            if(item.tipoLancamento !== ConstantsUtil.tipoTotaisFichaBancariaLabel && item.tipoDRESaldo !== ConstantsUtil.tipoTotaisFichaBancariaLabel){
                this.state.DREConsolidado.descricaoMesesList.forEach(descricaoMes => {
                    let itemMesObj = item.valorMap[descricaoMes]
                    if(itemMesObj && itemMesObj.total){
                        let currentValorInObjTotalMeses = objTotalMeses[descricaoMes]
                        objTotalMeses[descricaoMes] = currentValorInObjTotalMeses+=itemMesObj.total
                    }
                })
            }
        })
        // valorTotalFiltrado = GeneralServices.formatTo2CasasDecimais(valorTotalFiltrado)
        // porcentagemTotalFiltrada = GeneralServices.formatTo2CasasDecimais(porcentagemTotalFiltrada)
        this.state.DREConsolidado.descricaoMesesList.forEach(descricaoMes => {
            objTotalMeses[descricaoMes] = GeneralServices.formatTo2CasasDecimais(objTotalMeses[descricaoMes])
        })
        const returnObj = {objTotalMeses}
        // console.log("returnObj: ", returnObj)
        return returnObj
    }
 
    resetTotalSaldoItem =  (list) => {

        let totalItem = GeneralServices.findTotalItem(list)
        if(totalItem){
            // console.log("totalItem: ", totalItem)
            totalItem.nome = totalItem.realNome
            totalItem.showNome = totalItem.realNome

            this.state.DREConsolidado.descricaoMesesList.forEach(descricaoMes => {
                let totalItemMesObj = totalItem.valorMap[descricaoMes]
                if(totalItemMesObj){
                    totalItemMesObj.showTotal = totalItemMesObj.realTotal
                }
            })
            
        }
    }     

    trataLabelsDREInList(list, descricaoMesesList){
        // console.log("trataLabelsDREInList: ", descricaoMesesList)
        list.forEach(item => {

            item.realNome = item.nome
            item.showNome = item.nome
    
            item.realTotal = item.total
            item.showTotal = item.total
    
            item.realPorcentagem = item.porcentagem
            item.showPorcentagem = item.porcentagem

            this.state.DREConsolidado.descricaoMesesList.forEach(descricaoMes => {
                // console.log("descricaoMes: ", descricaoMes)
                // console.log("item: ", item)
                let mesObj = item.dreMensalConsolidadoMap[descricaoMes]
                if(mesObj && mesObj.total){
                    mesObj.showTotal = mesObj.total
                    mesObj.realTotal = mesObj.total
                }
                else{
                    mesObj = {total: ''}
                    item.dreMensalConsolidadoMap[descricaoMes] = mesObj
                }
            })
        })

        list.forEach(item => {
            descricaoMesesList.forEach(descricaoMes => {
                item[descricaoMes] = item.dreMensalConsolidadoMap[descricaoMes].total
            })
        })

        // console.log("list tratada: ", list)
    }

    onFilterTable = (currentList, dt) => {
        if(currentList && currentList.length > 0){
            //caso tenha lista após filtro, coloca os dados de um dos itens na linha de totais, para que ele também apareça no filtro
            const templateItem = currentList[0]
            // console.log("templateItem: ", templateItem)
            let totaisItem = GeneralServices.findTotalItem(dt.current.props.value)
            // console.log("totaisItem inicial: ", templateItem)
            //Iguala linha de totais a um dos itens resultantes do filtro, para que totais apareça também na lista filtrada.
            totaisItem.nome =  templateItem.nome
            totaisItem.total =  templateItem.total
            totaisItem.porcentagem =  templateItem.porcentagem
            this.state.DREConsolidado.descricaoMesesList.forEach(descricaoMes => {
                const totalMesObj = totaisItem.dreMensalConsolidadoMap[descricaoMes]
                const templateMesObj = templateItem.dreMensalConsolidadoMap[descricaoMes]
                if(totalMesObj && templateMesObj){
                    if(!templateMesObj.showTotal){
                        templateMesObj.showTotal = ''
                    }
                    totaisItem[descricaoMes] = templateMesObj.showTotal
                    totalMesObj.total = templateMesObj.total
                }
            })
            // console.log("totaisItem: ", totaisItem)

            let totalObjFiltrado = this.calculaTotalFiltrado(currentList)
            // console.log("totalObjFiltrado: ", totalObjFiltrado)
            totaisItem.showTotal = totalObjFiltrado.valorTotalFiltrado
            totaisItem.showPorcentagem = totalObjFiltrado.porcentagemTotalFiltrada

            this.state.DREConsolidado.descricaoMesesList.forEach(descricaoMes => {
                const totalSomaMes = totalObjFiltrado.objTotalMeses[descricaoMes];
                let mesInTotalItem = totaisItem.dreMensalConsolidadoMap[descricaoMes]
                if(!mesInTotalItem){
                    mesInTotalItem={}
                }
                mesInTotalItem.showTotal = totalSomaMes
                mesInTotalItem.total = totalSomaMes
                // totaisItem[descricaoMes] = totalSomaMes
            })

            // console.log("totaisItem: ", totaisItem)

        }
    }

    calculaTotalFiltrado = list => {
        let valorTotalFiltrado = 0
        let porcentagemTotalFiltrada = 0
        
        let objTotalMeses = {}
        this.state.DREConsolidado.descricaoMesesList.forEach(descricaoMes => {
            objTotalMeses[descricaoMes] = 0
        })

        list.forEach(item => {
            if(item.tipoLancamento !== ConstantsUtil.tipoTotaisFichaBancariaLabel && item.tipoDRESaldo !== ConstantsUtil.tipoTotaisFichaBancariaLabel){
                valorTotalFiltrado+=item.total
                porcentagemTotalFiltrada+=item.porcentagem

                this.state.DREConsolidado.descricaoMesesList.forEach(descricaoMes => {
                    let itemMesObj = item.dreMensalConsolidadoMap[descricaoMes]
                    if(itemMesObj && itemMesObj.total){
                        let currentValorInObjTotalMeses = objTotalMeses[descricaoMes]
                        objTotalMeses[descricaoMes] = currentValorInObjTotalMeses+=itemMesObj.total
                    }
                })
            }
        })
        valorTotalFiltrado = GeneralServices.formatTo2CasasDecimais(valorTotalFiltrado)
        porcentagemTotalFiltrada = GeneralServices.formatTo2CasasDecimais(porcentagemTotalFiltrada)
        this.state.DREConsolidado.descricaoMesesList.forEach(descricaoMes => {
            objTotalMeses[descricaoMes] = GeneralServices.formatTo2CasasDecimais(objTotalMeses[descricaoMes])
        })
        const returnObj = {valorTotalFiltrado, porcentagemTotalFiltrada, objTotalMeses}
        // console.log("returnObj: ", returnObj)
        return returnObj
    }

    resetTotalItem =  (list) => {

        let totalItem = GeneralServices.findTotalItem(list)
        if(totalItem){
            // console.log("totalItem: ", totalItem)
            totalItem.nome = totalItem.realNome
            totalItem.showNome = totalItem.realNome

            totalItem.total = totalItem.realTotal
            totalItem.showTotal = totalItem.realTotal

            totalItem.porcentagem = totalItem.realPorcentagem
            totalItem.showPorcentagem = totalItem.realPorcentagem

            this.state.DREConsolidado.descricaoMesesList.forEach(descricaoMes => {
                let totalItemMesObj = totalItem.dreMensalConsolidadoMap[descricaoMes]
                if(totalItemMesObj){
                    totalItemMesObj.showTotal = totalItemMesObj.realTotal
                }
            })
            
        }
    }    

    exportExcel = () => {
        const periodo = this.state.DREConsolidado.initialDate + " - " + this.state.DREConsolidado.finalDate
        var workbook = { Sheets: {  }, SheetNames: [] };
        
        const saldoInicialWorkSheet = this.saldoInicialRef.current.generateWorkSheet()
        const saldoInicialSheetName = 'Saldo Inicial'
        const saldoInicialworkbook = { Sheets: { [saldoInicialSheetName]: saldoInicialWorkSheet }, SheetNames: [[saldoInicialSheetName]] };
        Object.assign(workbook.Sheets, saldoInicialworkbook.Sheets)
        workbook.SheetNames.push(saldoInicialworkbook.SheetNames)

        const recebimentoWorkSheet = this.recebimentoRef.current.generateWorkSheet()
        const recebimentoSheetName = 'Recebimentos'
        const recebimentoworkbook = { Sheets: { [recebimentoSheetName]: recebimentoWorkSheet }, SheetNames: [[recebimentoSheetName]] };
        Object.assign(workbook.Sheets, recebimentoworkbook.Sheets)
        workbook.SheetNames.push(recebimentoworkbook.SheetNames)

        const pagamentoWorkSheet = this.pagamentoRef.current.generateWorkSheet()
        const pagamentoSheetName = 'Pagamentos'
        const pagamentoworkbook = { Sheets: { [pagamentoSheetName]: pagamentoWorkSheet }, SheetNames: [[pagamentoSheetName]] };
        Object.assign(workbook.Sheets, pagamentoworkbook.Sheets)
        workbook.SheetNames.push(pagamentoworkbook.SheetNames)

        const resultadoWorkSheet = this.resultadoRef.current.generateWorkSheet()
        const resultadoSheetName = 'Resultado'
        const resultadoworkbook = { Sheets: { [resultadoSheetName]: resultadoWorkSheet }, SheetNames: [[resultadoSheetName]] };
        Object.assign(workbook.Sheets, resultadoworkbook.Sheets)
        workbook.SheetNames.push(resultadoworkbook.SheetNames)

        const resultadoDestacadoWorkSheet = this.resultadoDestacadoRef.current.generateWorkSheet()
        const resultadoDestacadoSheetName = 'Resultado Não Operacional'
        const resultadoDestacadoworkbook = { Sheets: { [resultadoDestacadoSheetName]: resultadoDestacadoWorkSheet }, SheetNames: [[resultadoDestacadoSheetName]] };
        Object.assign(workbook.Sheets, resultadoDestacadoworkbook.Sheets)
        workbook.SheetNames.push(resultadoDestacadoworkbook.SheetNames)

        const saldoFinalWorkSheet = this.saldoFinalRef.current.generateWorkSheet()
        const saldoFinalSheetName = 'Saldo Final'
        const saldoFinalworkbook = { Sheets: { [saldoFinalSheetName]: saldoFinalWorkSheet }, SheetNames: [[saldoFinalSheetName]] };
        Object.assign(workbook.Sheets, saldoFinalworkbook.Sheets)
        workbook.SheetNames.push(saldoFinalworkbook.SheetNames)
        
        const diferencaWorkSheet = this.diferencaRef.current.generateWorkSheet()
        const diferencaSheetName = 'Diferença'
        const diferencaworkbook = { Sheets: { [diferencaSheetName]: diferencaWorkSheet }, SheetNames: [[diferencaSheetName]] };
        Object.assign(workbook.Sheets, diferencaworkbook.Sheets)
        workbook.SheetNames.push(diferencaworkbook.SheetNames)

        this.saveAsExcelFile(workbook, `Fluxo de Caixa Consolidado - ${periodo} - VisãoRJ`);
    }

    saveAsExcelFile(workbook, fileName) {
        import('file-saver').then(module => {
            if (module && module.default) {
                const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([excelBuffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + EXCEL_EXTENSION);
            }
        });
    }

    handleUnificarChange = (e) => {
        this.setState({unificarEmpresas: e.value})
        if(!e.value){
            this.setState({selectedEmpresas: []})
        }
    }


    render() {

        const renderEmpresasMultiSelect = () => {
            if(this.state.unificarEmpresas){
                return (
                    <div className = "col-md-2">
                    <FormGroup label = "Empresas" htmlFor = {this.props.htmlFor}>
                    <EmpresasMultiSelect
                        handleChange={(selectedEmpresas) => this.setState({selectedEmpresas})}
                        placeholder="Selecione as empresas para unificar"
                        disabled={!this.state.habilitaBusca}
                        inputErrorClass={this.state.inputEmpresasErrorClass}
                        errorMessage={this.state.errorEmpresasErrorMessage}
                    />
                    </FormGroup>
                    </div>
                )
            }
        }        

        const renderCalendarFilters = () => {
                return (
                    <div className = "col-md-12">
                        <div className="row">
                            <DateIntervalCalendar
                                htmlFor="SelectDatePeriod"
                                label="Escolher período"
                                ano
                                defaultValue={ConstantsUtil.calendarAnoCompletoFilterLabel}
                                handleFilterChange={this.handleDateFilterChange}
                                handleDateChange={this.handleDateChange}
                                handleInitialMonthDateChange={this.handleInitialMonthDateChange}
                                handleFinalMonthDateChange={this.handleFinalMonthDateChange}
                                missingFilterType={this.state.missingFilterType}
                                missingAnyDate={this.state.missingAnyDate}
                                disabled={!this.state.habilitaBusca}
                            />
                        {/* </div>

                        <br />

                        <div className="row"> */}
                            <div
                                style={{
                                    marginTop: '0.3rem', marginLeft: '0.5rem', marginRight: '0.5rem'
                                }}
                                className = "col-md-1.5"
                            >
                                <h6>Unificar Empresas?</h6>
                                <InputSwitch
                                    checked={this.state.unificarEmpresas}
                                    onChange={this.handleUnificarChange}
                                    disabled={!this.state.habilitaBusca}
                                />
                            </div>

                            {renderEmpresasMultiSelect()}

                        </div>
                    </div>
                )
        }

        const renderDRERecebimentoTable = (dreConsolidado) => {
            if( (this.state.isFinishedSearch && !this.state.habilitaBusca) || this.state.loading )
            return (
                <>

                {/* <br /> */}
                <div className="" 
                    style={{paddingBottom: ConstantsUtil.paddingBeetwenTablesDREConsolidado}}
                >
                    <div className='borda'>
                    <DRERecebimentoConsolidadoTable
                        ref={this.recebimentoRef}
                        dt={this.recebimentoDtRef}
                        dreConsolidado = {dreConsolidado}
                        unificarEmpresas = {this.state.unificarEmpresas}
                        selectedEmpresas={this.state.selectedEmpresas}
                        saveAsExcelFile={this.saveAsExcelFile}
                        search={this.reSearch}
                        loading={this.state.loading}
                        push={this.props.push}
                        onFilterTable={this.onFilterTable}
                        resetTotalItem={this.resetTotalItem}
                    />
                </div>
                </div>
                </>
            )
        }

        const renderDREPagamentoTable = (dreConsolidado) => {
            // console.log("this.state.isFinishedSearch: ", this.state.isFinishedSearch)
            // console.log("this.state.habilitaBusca: ", this.state.habilitaBusca)
            // console.log("this.state.loading: ", this.state.loading)
            let cond = ((this.state.isFinishedSearch && !this.state.habilitaBusca) || this.state.loading)
            // console.log("cond: ", cond)

            if( cond )
            // if( (this.state.isFinishedSearch && !this.state.habilitaBusca) || this.state.loading )
            return (
                <>
                
                {/* <br /> */}
                <div className="" 
                    style={{paddingBottom: ConstantsUtil.paddingBeetwenTablesDREConsolidado}}
                >
                    <div className='borda'>
                    <DREPagamentoConsolidadoTable
                        ref={this.pagamentoRef}
                        dt={this.pagamentoDtRef}
                        dreConsolidado = {dreConsolidado}
                        unificarEmpresas = {this.state.unificarEmpresas}
                        selectedEmpresas={this.state.selectedEmpresas}
                        saveAsExcelFile={this.saveAsExcelFile}
                        search={this.reSearch}
                        loading={this.state.loading}
                        push={this.props.push}
                        onFilterTable={this.onFilterTable}
                        resetTotalItem={this.resetTotalItem}
                    />
                </div>
                </div>
                </>
            )
        }

        const renderDREDestacadoTable = (dreConsolidado) => {
            if( (this.state.isFinishedSearch && !this.state.habilitaBusca) || this.state.loading )
            return (
                <>
                
                {/* <br /> */}
                <div className="" 
                    style={{paddingBottom: ConstantsUtil.paddingBeetwenTablesDREConsolidado}}
                >
                    <div className='borda'>
                    <DREDestacadoConsolidadoTable
                        ref={this.resultadoDestacadoRef}
                        dt={this.resultadoDestacadoDtRef}
                        dreConsolidado = {dreConsolidado}
                        unificarEmpresas = {this.state.unificarEmpresas}
                        selectedEmpresas={this.state.selectedEmpresas}
                        saveAsExcelFile={this.saveAsExcelFile}
                        search={this.reSearch}
                        loading={this.state.loading}
                        push={this.props.push}
                        onFilterTable={this.onFilterTable}
                        resetTotalItem={this.resetTotalItem}
                    />
                </div>
                </div>
                </>
            )
        }

        const renderDRESaldoTable = (header, dreConsolidado, ref, dtRef) => {
            // console.log("renderDRESaldoTable: ", dreConsolidado)
            if( (this.state.isFinishedSearch && !this.state.habilitaBusca) || this.state.loading )
            return (
                <>
                
                {/* <br /> */}
                <div className="" 
                    style={{paddingBottom: ConstantsUtil.paddingBeetwenTablesDREConsolidado}}
                >
                    <div className='borda'>
                    <DRESaldoConsolidadoTable
                        ref={ref}
                        dt={dtRef}
                        header={header}
                        dreConsolidado = {dreConsolidado}
                        initialDate={this.state.DREConsolidado ? this.state.DREConsolidado.initialDate : ""}
                        finalDate={this.state.DREConsolidado ? this.state.DREConsolidado.finalDate : ""}
                        saveAsExcelFile={this.saveAsExcelFile}
                        loading={this.state.loading}
                        push={this.props.push}
                        onFilterTable={this.onFilterSaldoTable}
                        resetTotalItem={this.resetTotalSaldoItem}
                    />
                    </div>
                </div>
                </>
            )
        }

        const renderDREResultadoTable = (header, dreConsolidado, tipo) => {
            if( (this.state.isFinishedSearch && !this.state.habilitaBusca) || this.state.loading )
            return (
                <>
                
                {/* <br /> */}
                <div className="" 
                    style={{paddingBottom: ConstantsUtil.paddingBeetwenTablesDREConsolidado}}
                >
                    <div className='borda'>
                    <DREResultadoConsolidadoTable
                        ref={this.resultadoRef}
                        header={header}
                        dreConsolidado = {dreConsolidado}
                        tipo={tipo}
                        initialDate={this.state.DREConsolidado ? this.state.DREConsolidado.initialDate : ""}
                        finalDate={this.state.DREConsolidado ? this.state.DREConsolidado.finalDate : ""}
                        saveAsExcelFile={this.saveAsExcelFile}
                        loading={this.state.loading}
                        push={this.props.push} 
                    />
                    </div>
                </div>
                </>
            )
        }

        const renderDREDiferencaTable = (header, dreConsolidado) => {
            if( ((this.state.isFinishedSearch && !this.state.habilitaBusca) || this.state.loading) && AuthService.getPerfil().isAdmin)
            return (
                <>
                
                {/* <br /> */}
                <div className=""
                    style={{paddingBottom: ConstantsUtil.paddingBeetwenTablesDREConsolidado}}
                >
                <div className='borda'>
                    <DREDiferencaConsolidadoTable
                        ref={this.diferencaRef}
                        header={header}
                        dreConsolidado = {dreConsolidado}
                        unificarEmpresas = {this.state.unificarEmpresas}
                        selectedEmpresas={this.state.selectedEmpresas}
                        initialDate={this.state.DREConsolidado ? this.state.DREConsolidado.initialDate : ""}
                        finalDate={this.state.DREConsolidado ? this.state.DREConsolidado.finalDate : ""}
                        saveAsExcelFile={this.saveAsExcelFile}
                        loading={this.state.loading}
                        push={this.props.push} 
                    />
                </div>
                </div>
                </>
            )
        }

        const renderButtons = () => {
            if(this.state.habilitaBusca){
                return (
                    <div
                        // style={{marginTop: '0.8rem'}}
                    >
                        <Button
                            label="Gerar Fluxo de Caixa"
                            className='small-button'
                            ref={this.buttonRef}
                            icon="pi pi-search"
                            onClick = {this.callSearch}
                            // style={ {maxHeight: '35px' } }
                            disabled = {this.state.isSearchingLancamentos}
                        />
                    </div>
                )
            }
            return (
                <div
                    style={{marginBottom: '0.8rem' } }
                >
                    <Button
                        label="Voltar"
                        className="p-button-danger small-button"
                        icon="pi pi-undo"
                        onClick = {this.toggleButton}
                        // onClick = {() => this.recebimentoRef.current.exportExcel()}
                        // disabled = {this.state.isSearchingLancamentos}
                    />
                    <Button
                        label="Exportar Tudo"
                        className="p-button-success small-button"
                        icon="pi pi-file-excel"
                        onClick={this.exportExcel}
                        tooltip={ConstantsUtil.exportXlsxLabel}
                        style ={{marginLeft: '10px'}}
                        tooltipOptions={{position: 'top'}}
                    />
                </div>
            )
        }

        return(
            <div className="bs-docs-section " >
            <Card title = "Fluxo de Caixa Consolidado">
                <div className = "col-md-12">

                <div
                    className="row"
                    // style={{marginBottom: '0.3rem'}}
                >

                    {renderCalendarFilters()}

                </div>  
                {/* <br /> */}

                {renderButtons()}
                
                </div> {/* col-md-12 */}

                <div className="visaorj-small">

                {renderDRESaldoTable(
                    ConstantsUtil.saldoInicialLabel,
                    this.state.DREConsolidado ? this.state.DREConsolidado : {},
                    this.saldoInicialRef,
                    this.saldoInicialDtRef
                    )}

                {renderDRERecebimentoTable(this.state.DREConsolidado ? this.state.DREConsolidado : {})}

                {renderDREPagamentoTable(this.state.DREConsolidado ? this.state.DREConsolidado : {})}

                {renderDREResultadoTable(ConstantsUtil.resultadoOpracionalLabel,  this.state.DREConsolidado ? this.state.DREConsolidado : {}, ConstantsUtil.resultadoOpracionalKey)}

                {renderDREDestacadoTable(this.state.DREConsolidado ? this.state.DREConsolidado : {})}

                {renderDREResultadoTable(ConstantsUtil.resultadoFinalLabel,  this.state.DREConsolidado ? this.state.DREConsolidado : {}, ConstantsUtil.resultadoFinalKey)}

                {renderDRESaldoTable(
                    ConstantsUtil.saldoFinalLabel,
                    this.state.DREConsolidado ? this.state.DREConsolidado : {},
                    this.saldoFinalRef,
                    this.saldoFinalDtRef
                    )
                }

                {renderDREDiferencaTable("Diferença", this.state.DREConsolidado ? this.state.DREConsolidado : {})}
                </div>
            </Card>
            <div className="d-flex "/>
        </div>              
        )
    }

}

DREConsolidado.contextType = AuthContext

export default withRouter(DREConsolidado)