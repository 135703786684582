import React from 'react'

import { Dialog } from 'primereact/dialog'
import DialogFooter from '../dialogFooter'
import ConfirmationDialog from '../confirmationDialog'
// import { Calendar } from 'primereact/calendar'
import { InputMask } from 'primereact/inputmask'
import { InputNumber } from 'primereact/inputnumber'
import GeneralServices from '../../app/service/generalServices'
import CustomCalendar from '../calendar/customCalendar'
import ConstantsUtil from '../../context/constantsUtil'
import ProductService from '../../app/service/productService'
import HandleErrorService from '../../app/service/handleErrorService'
import CentralService from '../../app/service/central/centralService'

class UpdateStockDialog extends React.Component {

    state = {
        quantidade: '',
        inputQuantidadeErrorClass: '',
        errorQuantidadeMessage: '',

        valorUnitario: '',
        inputValorUnitarioErrorClass: '',
        errorValorUnitarioMessage: '',
        disableValorUnitario: false,

        aberturaInventario: false,
        aberturaInventarioChecked: false,

        dataLancamento: '',
        dataLancamentoView: '',
        inputDateErrorClass:'',
        errorDateMessage:'',

        updateStockHour: '',
        inputUpadateHourErrorClass: '',
        errorUpadateHourMessage: '',

        didUpdated: false,
        visibleConfirmDialog: false,
        calendarFocus: false

    }

    constructor(){
        super();
        this.generalServices = new GeneralServices();
        this.productService = new ProductService();
        this.centralService = new CentralService()
    }

    componentDidUpdate(){
        if(this.props.visible && !this.state.didUpdated){
            
            this.resetData()
            if(this.props.fichaDeEstoqueToEdit){
                const date = GeneralServices.convertBrStringToJsDate(
                    GeneralServices.formatDateFromFichaDeEstoque(this.props.fichaDeEstoqueToEdit.dataHoraAtualizacaoManual)
                )

                this.setState({dataLancamentoView: date})
                
                this.setState({dataLancamento: GeneralServices.convertJSDateToBrDate(date)})

                //Transforma 2019-12-30T14:35:00.000-03:0 em 14:35, ou seja, pega as horas até os minutos
                let hour = this.props.fichaDeEstoqueToEdit.dataHoraAtualizacaoManual.split("T")[1].split(".")[0].substring(0, 5)
                this.setState({updateStockHour: hour})
    
                this.setState({quantidade: this.props.fichaDeEstoqueToEdit.quantidadeSaldo})

                if(this.props.fichaDeEstoqueToEdit.aberturaInventario){
                    this.setState({valorUnitario: this.props.fichaDeEstoqueToEdit.valorUnitarioSaldo})
                    this.setState({aberturaInventarioChecked: true})
                    this.setState({aberturaInventario: true})
                }

                else{
                    this.checkAberturaInventario()
                }


            }
            else{
                this.checkAberturaInventario()
            }


            this.resetView()
        }
    }

    resetData = () => {
        this.setState({dataLancamento: null})
        this.setState({dataLancamentoView: null})

        this.setState({updateStockHour: null})

        this.setState({quantidade: null})
        this.setState({valorUnitario: null})
        this.setState({didUpdated: true})
        this.setState({disableValorUnitario: false})
        this.setState({valorUnitario: null})

        this.setState({aberturaInventario: false})
    }

    handleDateChange = async (event) => {
        // console.log("handleDateChange: ", event.target.value)
        const value = event.target.value
        const name = event.target.name
        await this.setState({ [name]: GeneralServices.convertJSDateToBrDate(value) })
        const nameView = name+"View"
        await this.setState({[nameView]: value })
    }

    hideDialog = () => {
        this.setState({didUpdated: false})
        this.setState({updateStockDate: ''})
        this.props.hideDialog()
    }

    handleCheckAberturaInventario = () => {
        if(this.props.productUuid){
            //Deve pedir ao central
            return this.centralService.checkAberturaInventario(this.props.productUuid)
        }

        //Deve pedir ao worker.
        return this.productService.checkAberturaInventario(this.props.productId)
    }

    checkAberturaInventario = async() => {
        // console.log("this.props.productUuid: ", this.props.productUuid)
        await this.setState({aberturaInventarioChecked: false})

        this.handleCheckAberturaInventario()
        .then(response => {
            const aberturaInvetarioOutputInfoDTO = response.data
            if(!aberturaInvetarioOutputInfoDTO.hasAberturaInventario){
                /*
                Singifica que o produto selecionado não possui abertura de inventário ainda.
                Logo, este lançamento manual será o de abertura de inventário.
                */
                this.setState({aberturaInventario: true})
            }
            else{
                this.setState({aberturaInventario: false})
                this.disableValorUnitario(aberturaInvetarioOutputInfoDTO.valorUnitario)
            }

            this.setState({aberturaInventarioChecked: true})
            
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
    }

    disableValorUnitario = valorUnitario => {
        this.setState({valorUnitario})
        this.setState({disableValorUnitario: true})
    }

    callSave = () => {
        this.resetView()
        if(this.checkData()){
            this.setState({visibleConfirmDialog: true})
        }
    }

    save = () => {
        var stockInfo = {
            id: this.props.productId ? this.props.productId : this.props.fichaDeEstoqueToEdit.product.id,
            quantidade: this.state.quantidade,
            valorUnitario: this.state.valorUnitario,
            dataHoraAtualizacaoEstoque: this.state.dataLancamento + " - " + this.state.updateStockHour,
            aberturaInventario: this.state.aberturaInventario
        }
        this.props.save(stockInfo)
        this.setState({didUpdated: false})
        this.setState({visibleConfirmDialog: false})
    }

    hideConfirmDialog = () => {
        this.setState({visibleConfirmDialog: false})
    }

    checkData = () => {
        // console.log(this.state.quantidade)
        var check = true
        if(!this.state.quantidade && this.state.quantidade != 0){
            this.setState({inputQuantidadeErrorClass: "p-invalid p-d-block"})
            this.setState({errorQuantidadeMessage:"Informe a quantidade"})
            check = false
        }
        if(!this.state.valorUnitario){
            this.setState({inputValorUnitarioErrorClass: "p-invalid p-d-block"})
            this.setState({errorValorUnitarioMessage:"Informe o valor"})
            check = false
        }

        // if(isNaN(new Date(GeneralServices.convertToUsDate(this.state.updateStockDate)).getTime())){
        //     this.setState({inputUpadateDateErrorClass: "is-invalid"})
        //     this.setState({errorUpadateDateMessage:"Informe uma data válida"})
        //     check = false
        // }

        if(!this.state.dataLancamento){
            this.setState({inputDateErrorClass: ConstantsUtil.primereactInputErrorClass})
            this.setState({errorDateMessage: "Informe a data"})
            check = false
        }

        if(!GeneralServices.checkValidHour(this.state.updateStockHour)){
            this.setState({inputUpadateHourErrorClass: ConstantsUtil.bootstrapInputErrorClass})
            this.setState({errorUpadateHourMessage:"Informe um horário válido"})
            check = false
        }
        return check;
    }

    resetView = () => {
        this.setState({inputQuantidadeErrorClass: ''})
        this.setState({errorQuantidadeMessage: ''})

        this.setState({inputValorUnitarioErrorClass: ''})
        this.setState({errorValorUnitarioMessage: ''})

        this.setState({inputDateErrorClass: ''})
        this.setState({errorDateMessage: ''})

        this.setState({inputUpadateHourErrorClass: ''})
        this.setState({errorUpadateHourMessage: ''})
    }

    handleChange = (e) => {
        // console.log('handleChange: ', e.target.value)
        const value = e.target.value
        const name = e.target.name
        this.setState({[name]: value})
    }

    handleNumberChange = async (e) => {
        const value = e.target.value
        const name = e.target.name
        if(value < 0)
            return
        await this.setState({[name]: value})
    }

    handleKeypress = async e => {
        await this.generalServices.sleep(1) //essa função é chamada no mesmo instante que a handleNumberChange.
                                            //Precisa esperar um tempo de forma que o valor seja alterado.
        //it triggers by pressing the enter key
      if (e.key === "Enter") {
        this.callSave();
      }
    }

    // calendarFocus = () => {
    //     this.setState({calendarFocus: true})
    // }

    render() {

        const updateDialogFooter = (
            <DialogFooter save = {this.callSave} hideDialog={this.hideDialog} />
        )

        // const currentYear = GeneralServices.getCurrentYear()

        return(
            <>
            <Dialog
                visible={this.props.visible && !this.state.calendarFocus && this.state.aberturaInventarioChecked}
                style={{ width: '450px'}}
                header={this.props.header}
                modal
                className="p-fluid"
                footer={updateDialogFooter}
                onHide={this.hideDialog}>
                <div className="p-field">
                    <label htmlFor="quantidade">Quantidade Em Estoque</label>
                    <InputNumber inputId="inputQuantidade" 
                                className={this.state.inputQuantidadeErrorClass}
                                name="quantidade"
                                value={this.state.quantidade}
                                onValueChange={this.handleNumberChange}
                                onKeyDown={this.handleKeypress}
                                placeholder="Digite a quantidade"
                    />
                    <small id="inputQuantidade-help" className="p-error p-d-block">{this.state.errorQuantidadeMessage}</small>
                </div>
                <br/>
                <div className="p-field">
                    <label htmlFor="valorUnitario">Valor Unitário do Produto</label>
                    <InputNumber inputId="inputValorUnitario" 
                                className={this.state.inputValorUnitarioErrorClass}
                                name="valorUnitario"
                                value={this.state.valorUnitario}
                                onValueChange={this.handleNumberChange}
                                onKeyDown={this.handleKeypress}
                                placeholder="Digite o valor"
                                mode="currency" currency="BRL" locale="pt-BR"
                                disabled={this.state.disableValorUnitario}
                    />
                    <small id="inputValorUnitario-help" className="p-error p-d-block">{this.state.errorValorUnitarioMessage}</small>
                </div>
                <br/>
                <div className="p-field">
                        <CustomCalendar
                            label="Data da contagem de estoque"
                            ref={this.dateInputRef}
                            id="dataLancamentoEstoque"
                            htmlFor="icon"
                            noHTMLFormat
                            inputDateErrorClass={this.state.inputDateErrorClass}
                            value={this.state.dataLancamentoView}
                            name="dataLancamento"
                            onChange={this.handleDateChange}
                            errorDateMessage={this.state.errorDateMessage}
                        />                        
                    <br />
                        <label htmlFor="hour">Horário da contagem de estoque</label>
                        <InputMask id="hour"
                                name="updateStockHour"
                                // disabled={this.props.hour}
                                className={"form-control " + this.state.inputUpadateHourErrorClass }
                                mask="99:99"
                                value={this.state.updateStockHour}
                                placeholder="hh:mm"
                                onChange={this.handleChange}
                        />
                        <div className="invalid-feedback"> {this.state.errorUpadateHourMessage} </div>

                </div>
            </Dialog>

            <ConfirmationDialog
                header="Atualizar estoque"
                confimationMessage="Confirmar atualização de estoque?"
                visible={this.state.visibleConfirmDialog && this.props.visible}
                confirm={this.save}
                hide={this.hideConfirmDialog}
            />
            </>
            
        )
    }

}

export default UpdateStockDialog