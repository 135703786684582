import React from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import GeneralServices from '../../../app/service/generalServices'
import ConstantsUtil from '../../../context/constantsUtil'
import FichaDeEstoqueService from '../../../app/service/relatorios/fichaDeEstoqueService'
import { classNames } from 'primereact/utils'
import { Button } from 'primereact/button'
import DetalhamentoFluxoCaixaPrevistoDialog from '../../relatorios/fluxoCaixaPrevisto/detalhamentoFluxoCaixaPrevistoDialog'

class FluxoCaixaPrevistoTable extends React.Component {

    state = {
        visibleConfirmDeleteDialog: false,
        fichaDeEstoqueToDelete: '',

        loading: false,

        displayDetalhamentoDialog: false,
        elementToView: '',
    }

    constructor(){
        super();
        this.fichaDeEstoqueService = new FichaDeEstoqueService();
    }

    getList(){
        const retorno = this.props.fluxoCaixaPrevisto && this.props.fluxoCaixaPrevisto.fluxoCaixaPrevistoList ? this.props.fluxoCaixaPrevisto.fluxoCaixaPrevistoList : []
        return retorno
    }

    viewDetalhamento = (rowData, from) => {
        this.setState({displayDetalhamentoDialog: true})
        rowData.tipoDetalhamento = from
        this.setState({elementToView: rowData})
    }
    
    hideDialog = async () => {
        await this.setState({displayDetalhamentoDialog: false})
        await  this.setState({elementToView: ''})
    }

    getDialogLabel = () => {
        if(!this.state.elementToView){
            return ""
        }
        switch (this.state.elementToView.tipoDetalhamento) {

            case ConstantsUtil.saldoLabel:
              return "Saldos Bancários"

            case ConstantsUtil.entradaLabel:
                return "Lançamentos de Contas a Receber"

            case ConstantsUtil.saidaLabel:
                return "Lançamentos de Provisão de Pagamento"

        }

    }

    rowClass(rowData) {
        return {
            [ConstantsUtil.totaisTableLabel]: rowData.tipo === ConstantsUtil.totaisTableUpperCaseLabel
        }
    }

    getListToExport = () => {
        var finalList = []
        this.getList().forEach(item => {
            finalList.push({
                ['Data de Lançamento']: item.dataString ? item.dataString : item.tipo,
                Entrada: item.entrada,
                Saída: item.saida,
                Saldo: item.saldo
            })
        })
        return finalList
    }

    render(){

        const renderViewLancamentosLink = (rowData, valor, condToColor, condToLink, from) => {

            const condNonZero = valor > 0 || valor < 0

            const isTotais = rowData.tipo === ConstantsUtil.totaisTableUpperCaseLabel
            condToLink = condToLink && !isTotais
            
            const classes = classNames({
                'superavitValue': condToColor && valor >= 0,
                'deficitValue': condToColor && valor<0,
                'linkValue': condToLink && condNonZero
            });
    
            if( condToLink && condNonZero){
                return(
                    <a 
                        className={classes}
                        onClick={() => this.viewDetalhamento(rowData, from)}
                    >
                        {GeneralServices.showFormattedIfNotNull(valor, true)}
                    </a>
                )
            }
            else{
                return(
                    <div className={classes}>
                        {condToLink ? GeneralServices.showFormattedIfNotNull(valor, true) : GeneralServices.showFormattedIfNonZero(valor, true)}
                    </div>
                )
            }
        }
        
        return (
            <>

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    label="Exportar"
                    icon="pi pi-file-excel"
                    className="p-button-success"
                    onClick={() => GeneralServices.exportExcel(this.getListToExport(), "Fluxo de Caixa Previsto", "Fluxo de Caixa Previsto")}
                />
            </div>

            <DataTable 
                ref={this.dt}
                value={this.getList()}
                className="p-datatable-sm small-font"
                rowHover
                rowClassName={this.rowClass}
                showGridlines
                scrollable
                scrollHeight="500px"
                emptyMessage={ConstantsUtil.nenhumLancamentoEncontradoMessage}
                loading={this.props.loading || this.state.loading}
                dataKey="id"
                paginator rows={ConstantsUtil.initialTableQuantityOption}
                paginatorTemplate={GeneralServices.tablePaginatorTemplate(ConstantsUtil.initialTableQuantityOption, this.getList().length)}
                currentPageReportTemplate="Mostrando de {first} ao {last} de {totalRecords} lançamentos"
            >

            <Column
                header="Data"
                field="data"
                body={rowData => rowData.tipo === ConstantsUtil.totaisTableUpperCaseLabel ? ConstantsUtil.totaisTableUpperCaseLabel : rowData.dataString}
                sortable style ={ {width: '80px'} }
                // filter filterElement={codeFilterElement}
             />

            <Column
                header="Entrada"
                field="entrada" 
                body = {rowData => renderViewLancamentosLink(rowData, rowData.entrada, false, rowData.tipo !== ConstantsUtil.saldoLabel, ConstantsUtil.entradaLabel)}
                sortable style ={ {width: '80px'} }
            />

            <Column
                header="Saída" 
                field="saida" 
                body = {rowData => renderViewLancamentosLink(rowData, rowData.saida, false, rowData.tipo !== ConstantsUtil.saldoLabel, ConstantsUtil.saidaLabel)}
                sortable style ={ {width: '80px'} }
            />

            <Column
                header="Saldo" 
                field="saldo" 
                sortable style ={ {width: '80px'} }
                body = {rowData => renderViewLancamentosLink(rowData, rowData.saldo, true, rowData.tipo === ConstantsUtil.saldoLabel, ConstantsUtil.saldoLabel)}
            />

            {/* <Column body={actionBody} style ={ {width: '80px'} }
            /> */}

        </DataTable>

            <DetalhamentoFluxoCaixaPrevistoDialog
                visible={this.state.displayDetalhamentoDialog}
                header={this.getDialogLabel()}
                fluxoCaixaPrevisto={this.props.fluxoCaixaPrevisto}
                element={this.state.elementToView}
                loadingTable={false}
                hideDialog={this.hideDialog}
            />            

        </>
        )
    }

}


export default FluxoCaixaPrevistoTable