import React from 'react'
import GrupoDePagamentoService from '../../app/service/contasAPagar/grupoDePagamentoService'
import HandleErrorService from '../../app/service/handleErrorService'
import ChooseGrupoDePagamentoDialog from './chooseGrupoDePagamentoDialog'

class InputChooseGrupoDePagamento extends React.Component {

    constructor(){
        super()
        this.grupoDePagamentoService = new GrupoDePagamentoService()
    }

    state = {

        grupoDePagamentoList: [],
        chooseGrupoDePagamentoDialogVisible: false,
        loading: false,
        forceUpdateGrupoDePagamentoDialog: false,
        
    }

    componentDidMount(){
        this.searchGrupoDePagamento()
    }

    handleSelectedGrupoDePagamentoChange = () => {
        this.setState({chooseGrupoDePagamentoDialogVisible: false})
        
        if(this.props.doNotForceGrupoDePagamentoDialog)
            this.props.doNotForceGrupoDePagamentoDialog()
    }

    handleChange = (value) => {
        const fornecedor = value
        this.props.handleSelectionGrupoDePagamentoChange(fornecedor)
    }

    searchGrupoDePagamento = () => {
        this.setState({loading: true})
        this.grupoDePagamentoService.search()
        .then(response => {
            this.setState({grupoDePagamentoList: response.data})
            this.checkById()
            if(this.props.autofocus){
                this.inputClick()
            }
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
        .finally(() => {
            this.setState({forceUpdateGrupoDePagamentoDialog: true})
            this.setState({loading: false})
        })
    }

    doneForceUpdate = () => {
        this.setState({forceUpdateGrupoDePagamentoDialog: false})
    }

    checkById = () => {
        if(this.props.searchGrupoDePagamentoById){
            const id = this.props.grupoDePagamentoId
            const grupoDePagamento = this.state.grupoDePagamentoList.find(grupoDePagamento => grupoDePagamento.id === id)
            this.handleChange(grupoDePagamento)
        }
    }

    handleKeyPress = async (e) => {
        if (e.key === "Enter") {
            this.inputClick()
          }
    }

    inputClick = () => {
        this.setState({chooseGrupoDePagamentoDialogVisible: true})
    }

    render(){

        const renderInvalidFeedbackMessage = () => {
            if(this.props.inputGrupoDePagamentoErrorClass){
                return <div className="invalid-feedback">Selecione o Grupo de Pagamento</div>
            }

            return <></>
        }

        const renderInput = () => {
            return (
                <>
                <label htmlFor="grupoDePagamento">Grupo de Pagamento</label>
                    <input style={{color: '#000'}} type="text"
                        className={"form-control " + this.props.inputGrupoDePagamentoErrorClass}
                        readOnly
                        value = {this.props.selectedGrupoDePagamentoLabel}
                        name="selectedGrupoDePagamentoLabel"
                        id="selectedGrupoDePagamento"
                        placeholder="Nenhum Grupo de Pagamento Selecionado..."
                        onClick={this.inputClick}
                        onKeyPress={this.handleKeyPress}
                        disabled={this.props.disabled}
                    />
                    {/* <div className="invalid-feedback">Selecione o Grupo de Pagamento</div> */}
                    {renderInvalidFeedbackMessage()}
                </>
            )
        }

        const renderHTMLFormat = () => {
            if(this.props.noHTMLFormat){
                return (
                    <>
                    {renderInput()}
                    </>
                )
            }
            else {
                return (
                    <>
                    <div className="row">
                    <div className="col-md-4">
                    {renderInput()}
                    </div>
                    </div>
                    </>
                )
            }
        }

        return(
            <>
            
                {renderHTMLFormat()}
                
                <ChooseGrupoDePagamentoDialog 
                    header="Grupos de Pagamento"
                    visible={this.state.chooseGrupoDePagamentoDialogVisible || this.props.forceShowDialog}
                    list={this.state.grupoDePagamentoList}
                    search={this.searchGrupoDePagamento}
                    hideDialog={this.handleSelectedGrupoDePagamentoChange}
                    handleSelectionGrupoDePagamentoChange={this.handleChange}
                    loading={this.state.loading}
                    forceUpdate={this.state.forceUpdateGrupoDePagamentoDialog}
                    doneForceUpdate={this.doneForceUpdate}
                />

            </>
        )

    }


}

export default InputChooseGrupoDePagamento