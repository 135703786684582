import React from 'react';

class Card extends React.Component {
  renderTitle() {
    if (this.props.title) {
      return (
        <h3 className="card-title align-self-center " style={{ fontSize: '1.25rem', margin: 0 }}>
        {/* <h3 className="card-title text-center" style={{ fontSize: '1.25rem', margin: 0, flex: '1'}}> */}
          {this.props.title}
        </h3>
      );
    }
  }

  renderRightComponent() {
    if (this.props.rightComponent) {
      return (
        <div className="ml-auto">
          {this.props.rightComponent}
        </div>
      );
    }
  }

  renderHeader() {
    if (this.props.title) {
      return (
        <div className="card-header d-flex justify-content-between align-items-center" style={{ height: '2rem' }}>
          {this.renderTitle()}
          {this.renderRightComponent()}
        </div>
      );
    }
    return null;
  }

  render() {
    return (
      <div className="card mb-3">
        {this.renderHeader()}
        <div className="card-body">
          <div className="row">
            <div className="col-lg-12">
              <div className="bs-component">
                {this.props.children}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Card;
