import React from 'react'
import Card from '../../components/card'
import { withRouter } from 'react-router-dom'

import { AuthContext } from '../../main/authProvider'
import GeneralServices from '../../app/service/generalServices'
import HandleErrorService from '../../app/service/handleErrorService'
import { Button } from 'primereact/button'
import DateIntervalCalendar from '../../components/calendar/dateIntervalCalendar'
import ConstantsUtil from '../../context/constantsUtil'
import DREService from '../../app/service/relatorios/DREService'
import DRERecebimentoTable from '../../components/relatorios/DRE/DRE_Mensal/DRERecebimentoTable'
import DREPagamentoTable from '../../components/relatorios/DRE/DRE_Mensal/DREPagamentoTable'
import DRESaldoTable from '../../components/relatorios/DRE/DRE_Mensal/DRESaldoTable'
import DREResultadoTable from '../../components/relatorios/DRE/DRE_Mensal/DREResultadoTable'
import DREDiferencaTable from '../../components/relatorios/DRE/DRE_Mensal/DREDiferencaTable'
import * as xlsx from 'xlsx'
import DREDestacadoTable from '../../components/relatorios/DRE/DRE_Mensal/DREDestacadoTable'
import { InputSwitch } from 'primereact/inputswitch'
import FormGroup from '../../components/form-group'
import EmpresasMultiSelect from '../../components/empresas/empresasMultiSelect'
import CentralService from '../../app/service/central/centralService'
import AuthService from '../../app/service/authService'

class DREMensal extends React.Component {

    constructor(){
        super();
        this.generalServices = new GeneralServices();
        this.DREService = new DREService();
        this.centralService = new CentralService();

        this.saldoInicialRef = React.createRef()
        this.saldoInicialDtRef = React.createRef()

        this.recebimentoRef = React.createRef()
        this.recebimentoDtRef = React.createRef() 

        this.pagamentoRef = React.createRef()
        this.pagamentoDtRef = React.createRef() 

        this.destacadoRef = React.createRef()
        this.destacadoDtRef = React.createRef()

        this.resultadoRef = React.createRef()
        this.resultadoDestacadoRef = React.createRef()

        this.saldoFinalRef = React.createRef()
        this.saldoFinalDtRef = React.createRef()

        this.diferencaRef = React.createRef()

    }
    
    state = {

        loading: false,

        DREMensal: '',

        selectedDateFilterType: '',
        missingFilterType: false,
        missingAnyDate: false,
        initialDate: '',
        initialDateView: '',
        finalDate: '',
        finalDateView: '',

        unificar: '',
        selectedEmpresas: [],
        inputEmpresasErrorClass: '',
        errorEmpresasErrorMessage: '',

        isSearchingLancamentos: false,
        isFinishedSearch: false,

        habilitaBusca: true,

    }

    resetDateView = () => {
        this.setState({initialDate: ''})
        this.setState({initialDateView: ''})
        this.setState({finalDate: ''})
        this.setState({finalDateView: ''})
    }

    handleNavigatorDateChange = (event, e) => {
        e.onChange(event.originalEvent, event.value)
    }

    handleDateFilterChange = (selectedDateFilterType) => {
        this.setState({selectedDateFilterType})
    }

    handleDateChange = async (initialDate, finalDate) => {
        await this.setState({initialDate})
        
        await this.setState({initialDateView: GeneralServices.convertBrStringToJsDate(initialDate)})

        await this.setState({finalDate})
        await this.setState({finalDateView: GeneralServices.convertBrStringToJsDate(finalDate)})

    }

    handleUnificarChange = (e) => {
        this.setState({unificar: e.value})
        if(!e.value){
            this.setState({selectedEmpresas: []})
        }
    }

    resetView = () => {
        this.setState({inputContaContabilErrorClass: ''})

        this.setState({DRE: {}})
        this.setState({missingFilterType: false})
        this.setState({missingAnyDate: false})

        this.setState({inputEmpresasErrorClass: ''})
        this.setState({errorEmpresasErrorMessage: ''})

    }

    checkData = () => {

        var check = true

        if(!this.state.selectedDateFilterType){
            this.setState({missingFilterType: true})
            check=false
        }
        
        if(!this.state.initialDate || !this.state.finalDate){
            this.setState({missingAnyDate: true})
            check=false
        }

        if(this.state.unificar){
            if(!this.state.selectedEmpresas || this.state.selectedEmpresas.length === 0){
                this.setState({inputEmpresasErrorClass: ConstantsUtil.primereactInputErrorClass})
                this.setState({errorEmpresasErrorMessage: ConstantsUtil.errorSelecioneEmpresasMessage})
                check = false
            }
        }

        return check
    }

    toggleButton = async () => {
        if(!this.state.habilitaBusca){
            //Clicou em "Voltar"
            await this.setState({DREMensal: ''})
        }
        await this.setState({habilitaBusca: !this.state.habilitaBusca})
    }

    reSearch = () => {
        this.toggleButton()
        this.callSearch()
    }

    callSearch = () => {

        this.resetView()

        if(this.checkData()){
            this.search()
        }
    }

    format = (DRE) => {
        DRE.drePagamentoList.forEach(element => {
            element.dreGrupoContaBancariaList.forEach(item => {
                var obj = { [item.contaBancaria.id]: {valor: item.valorTotal, fichaBancaria: item.fichaBancaria}}
                Object.assign(element, JSON.parse(JSON.stringify(obj)));
            })
        })

        // var resultado = DRE.resultado
        // console.log("resultado: ", resultado)
        // resultado.resultadoList.forEach(item => {
        //     // console.log("item: ", item)
        //     var obj = { [item.contaBancaria.id]: {valor: item.valorTotal}}
        //     Object.assign(resultado, JSON.parse(JSON.stringify(obj)));
        //     // console.log("resultado: ", resultado)
        // })

        // console.log("format: ", DRE)

        return DRE
    }

    getDREMensal = () => {
        if(this.state.unificar){
            return this.centralService.getDREMensalUnificado(this.state.initialDate, this.state.finalDate, this.state.selectedEmpresas)
        }
        else{
            return this.DREService.getDREMensal(this.state.initialDate, this.state.finalDate)
        }
    }

    search = () => {
        this.setState({loading: true})
        this.toggleButton()
        this.setState({isSearchingLancamentos: true})
        this.setState({isFinishedSearch: false})

        // this.DREService.getDREMensal(this.state.initialDate, this.state.finalDate)
        this.getDREMensal()
        .then(async response => {
            const data = this.format(response.data)
            await this.setState({DREMensal: data})
            this.trataLabelsDRE()
        })
        .catch(error => {
            HandleErrorService.handleError(error)

        })
        .finally(() => {
            this.setState({isFinishedSearch: true})
            this.setState({loading: false})
            this.setState({isSearchingLancamentos: false})
        })
    }

    trataLabelsDRE = () => {
        this.trataLabelsDREInList(this.state.DREMensal.dreRecebimentoList)
        this.trataLabelsDREInList(this.state.DREMensal.drePagamentoList)
        this.trataLabelsDREInList(this.state.DREMensal.dreDestacadoList)

        this.trataLabelsDRESaldo(this.state.DREMensal.saldoInicial)
        this.trataLabelsDRESaldo(this.state.DREMensal.saldoFinal)
    }

    trataLabelsDREInList(list){
        list.forEach(item => {
            item.realNome = item.nome
            item.showNome = item.nome
    
            item.realTotal = item.total
            item.showTotal = item.total
    
            item.realPorcentagem = item.porcentagem
            item.showPorcentagem = item.porcentagem
        })
    }

    trataLabelsDRESaldo(list){
        // console.log("trataLabelsDRESaldo: ", list)
        list.forEach(item => {

            item.nome = GeneralServices.contaBancariaFormattedNameInDRE(item)
            item.realNome = item.nome
            item.showNome = item.nome
    
            item.total = item.saldo
            item.realTotal = item.total
            item.showTotal = item.total
    
            item.porcentagem = 0 //apenas para não ficar null
            item.realPorcentagem = item.porcentagem
            item.showPorcentagem = item.porcentagem
        })

        // console.log("lista tratada: ", list)
    }


    resetTotalItem =  (list) => {

        let totalItem = GeneralServices.findTotalItem(list)
        if(totalItem){
            // console.log("totalItem: ", totalItem)
            totalItem.nome = totalItem.realNome
            totalItem.showNome = totalItem.realNome

            totalItem.total = totalItem.realTotal
            totalItem.showTotal = totalItem.realTotal

            totalItem.porcentagem = totalItem.realPorcentagem
            totalItem.showPorcentagem = totalItem.realPorcentagem
        }
    }


    onFilterTable = (currentList, dt) => {
        if(currentList && currentList.length > 0){
            /*
                A ideia é que a linha de totais sempre apareça na tabela, mesmo com um filtro.
                Para a tabela, a linha de totais é um registro como qualquer outro, então caso seja realizado um filtro por algum(ns) item(ns),
                naturalmente a linha de totais desapareceria pois não é um dos itens selecionados no filtro.

                Mas é interesasnte que o usuário consiga saber o valor total do que filtrou.

                Então, altero o objeto da linha de totais para que ele possua as propriedades (colunas) de filtro com valores iguais a de um dos
                elementos filtraods (peguei o 1°, mas poderia ser qualquer um).

                Assim, ele vai aparecer no filtro. Mas os atributos showTotal e showPorcentagem são modificados para apresentarem
                o valor total respectivo dos itens filtrados.
            */
            const templateItem = currentList[0]
            let totaisItem = GeneralServices.findTotalItem(dt.current.props.value)
            totaisItem.nome =  templateItem.nome
            totaisItem.total =  templateItem.total
            totaisItem.porcentagem =  templateItem.porcentagem

            let totalObjFiltrado = this.calculaTotalFiltrado(currentList)
            totaisItem.showTotal = totalObjFiltrado.valorTotalFiltrado
            totaisItem.showPorcentagem = totalObjFiltrado.porcentagemTotalFiltrada

        }
    }
    
    calculaTotalFiltrado = list => {
        let valorTotalFiltrado = 0
        let porcentagemTotalFiltrada = 0
        list.forEach(item => {
            if(item.tipoLancamento !== ConstantsUtil.tipoTotaisFichaBancariaLabel && item.tipoDRESaldo !== ConstantsUtil.tipoTotaisFichaBancariaLabel){
                valorTotalFiltrado+=item.total
                porcentagemTotalFiltrada+=item.porcentagem
            }
        })
        valorTotalFiltrado = GeneralServices.formatTo2CasasDecimais(valorTotalFiltrado)
        porcentagemTotalFiltrada = GeneralServices.formatTo2CasasDecimais(porcentagemTotalFiltrada)
        const returnObj = {valorTotalFiltrado, porcentagemTotalFiltrada}
        return returnObj
    }

    exportExcel = () => {
        const periodo = this.state.DREMensal.initialDate + " - " + this.state.DREMensal.finalDate
        var workbook = { Sheets: {  }, SheetNames: [] };
        
        const saldoInicialWorkSheet = this.saldoInicialRef.current.generateWorkSheet()
        const saldoInicialSheetName = 'Saldo Inicial'
        const saldoInicialworkbook = { Sheets: { [saldoInicialSheetName]: saldoInicialWorkSheet }, SheetNames: [[saldoInicialSheetName]] };
        Object.assign(workbook.Sheets, saldoInicialworkbook.Sheets)
        workbook.SheetNames.push(saldoInicialworkbook.SheetNames)

        const recebimentoWorkSheet = this.recebimentoRef.current.generateWorkSheet()
        const recebimentoSheetName = 'Recebimentos'
        const recebimentoworkbook = { Sheets: { [recebimentoSheetName]: recebimentoWorkSheet }, SheetNames: [[recebimentoSheetName]] };
        Object.assign(workbook.Sheets, recebimentoworkbook.Sheets)
        workbook.SheetNames.push(recebimentoworkbook.SheetNames)

        const pagamentoWorkSheet = this.pagamentoRef.current.generateWorkSheet()
        const pagamentoSheetName = 'Pagamentos'
        const pagamentoworkbook = { Sheets: { [pagamentoSheetName]: pagamentoWorkSheet }, SheetNames: [[pagamentoSheetName]] };
        Object.assign(workbook.Sheets, pagamentoworkbook.Sheets)
        workbook.SheetNames.push(pagamentoworkbook.SheetNames)

        const resultadoWorkSheet = this.resultadoRef.current.generateWorkSheet()
        const resultadoSheetName = 'Resultado'
        const resultadoworkbook = { Sheets: { [resultadoSheetName]: resultadoWorkSheet }, SheetNames: [[resultadoSheetName]] };
        Object.assign(workbook.Sheets, resultadoworkbook.Sheets)
        workbook.SheetNames.push(resultadoworkbook.SheetNames)

        const resultadoDestacadoWorkSheet = this.resultadoDestacadoRef.current.generateWorkSheet()
        const resultadoDestacadoSheetName = 'Resultado Não Operacional'
        const resultadoDestacadoworkbook = { Sheets: { [resultadoDestacadoSheetName]: resultadoDestacadoWorkSheet }, SheetNames: [[resultadoDestacadoSheetName]] };
        Object.assign(workbook.Sheets, resultadoDestacadoworkbook.Sheets)
        workbook.SheetNames.push(resultadoDestacadoworkbook.SheetNames)

        const saldoFinalWorkSheet = this.saldoFinalRef.current.generateWorkSheet()
        const saldoFinalSheetName = 'Saldo Final'
        const saldoFinalworkbook = { Sheets: { [saldoFinalSheetName]: saldoFinalWorkSheet }, SheetNames: [[saldoFinalSheetName]] };
        Object.assign(workbook.Sheets, saldoFinalworkbook.Sheets)
        workbook.SheetNames.push(saldoFinalworkbook.SheetNames)
        
        const diferencaWorkSheet = this.diferencaRef.current.generateWorkSheet()
        const diferencaSheetName = 'Diferença'
        const diferencaworkbook = { Sheets: { [diferencaSheetName]: diferencaWorkSheet }, SheetNames: [[diferencaSheetName]] };
        Object.assign(workbook.Sheets, diferencaworkbook.Sheets)
        workbook.SheetNames.push(diferencaworkbook.SheetNames)

        this.saveAsExcelFile(workbook, `Fluxo de Caixa Mensal - ${periodo} - VisãoRJ`);
    }

    saveAsExcelFile(workbook, fileName) {
        import('file-saver').then(module => {
            if (module && module.default) {
                const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([excelBuffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + EXCEL_EXTENSION);
            }
        });
    }

    render() {

        const renderEmpresasMultiSelect = () => {
            if(this.state.unificar){
                return (
                    <div className = "col-md-1">
                    <FormGroup label = "Empresas" htmlFor = {this.props.htmlFor}>
                    <EmpresasMultiSelect
                        handleChange={(selectedEmpresas) => this.setState({selectedEmpresas})}
                        placeholder="Selecione as empresas para unificar"
                        disabled={!this.state.habilitaBusca}
                        inputErrorClass={this.state.inputEmpresasErrorClass}
                        errorMessage={this.state.errorEmpresasErrorMessage}

                    />
                    </FormGroup>
                    </div>
                )
            }
        }

        const renderCalendarFilters = () => {
            return (
                <>
                <div className = "col-md-12">
                    <div className="row">
                    <DateIntervalCalendar
                        htmlFor="SelectDatePeriod"
                        label="Escolher período"
                        defaultValue={ConstantsUtil.calendarMesCompletoFilterLabel}
                        handleFilterChange={this.handleDateFilterChange}
                        handleDateChange={this.handleDateChange}
                        missingFilterType={this.state.missingFilterType}
                        missingAnyDate={this.state.missingAnyDate}
                        disabled={!this.state.habilitaBusca}
                    />
                    {/* </div>

                    <br />

                    <div className="row"> */}
                    <div
                        style={{
                            marginTop: '0.3rem', marginLeft: '0.5rem', marginRight: '0.5rem'
                        }}
                        className = "col-md-1.5"
                    >
                        <h6>Unificar Empresas?</h6>
                        <InputSwitch 
                            checked={this.state.unificar}
                            onChange={this.handleUnificarChange}
                            disabled={!this.state.habilitaBusca}
                        />
                    </div>

                    {renderEmpresasMultiSelect()}

                    {/* <div className = "col-md-8">
                    </div> */}

                    </div>
                </div>
                </>
            )
        }

        const renderDRERecebimentoTable = () => {
            if( (this.state.isFinishedSearch && !this.state.habilitaBusca) || this.state.loading )
            return (
                <>


                <div className="" 
                    style={{paddingBottom: ConstantsUtil.paddingBeetwenTablesDREConsolidado}}
                >
                <div className="borda" >
                {/* <br /> */}
                    <DRERecebimentoTable
                        ref={this.recebimentoRef}
                        dt={this.recebimentoDtRef}
                        DRE = {this.state.DREMensal}
                        unificarEmpresas={this.state.unificar}
                        selectedEmpresas={this.state.selectedEmpresas}
                        saveAsExcelFile={this.saveAsExcelFile}
                        search={this.reSearch}
                        loading={this.state.loading}
                        push={this.props.push}
                        resetTotalItem={this.resetTotalItem}
                        onFilterTable={this.onFilterTable}
                    />
                </div>
                </div>
                </>
            )
        }

        const renderDREPagamentoTable = () => {
            let cond = (this.state.isFinishedSearch && !this.state.habilitaBusca) || this.state.loading
            // console.log("cond: ", cond)
            // if( (this.state.isFinishedSearch && !this.state.habilitaBusca) || this.state.loading )
            if( cond )
            return (
                <>
                
                <div className="" 
                    style={{paddingBottom: ConstantsUtil.paddingBeetwenTablesDREConsolidado}}
                >
                <div className="borda" >
                {/* <br /> */}
                    <DREPagamentoTable
                        ref={this.pagamentoRef}
                        dt={this.pagamentoDtRef}
                        DRE = {this.state.DREMensal}
                        unificarEmpresas={this.state.unificar}
                        selectedEmpresas={this.state.selectedEmpresas}
                        saveAsExcelFile={this.saveAsExcelFile}
                        loading={this.state.loading}
                        search={this.reSearch}
                        push={this.props.push}
                        resetTotalItem={this.resetTotalItem}
                        onFilterTable={this.onFilterTable}
                    />
                </div>
                </div>
                </>
            )
        }

        const renderDREDestacadoTable = () => {
            if( (this.state.isFinishedSearch && !this.state.habilitaBusca) || this.state.loading )
            return (
                <>
                
                <div className="" 
                    style={{paddingBottom: ConstantsUtil.paddingBeetwenTablesDREConsolidado}}
                >
                <div className="borda" >
                {/* <br /> */}
                    <DREDestacadoTable
                        ref={this.resultadoDestacadoRef}
                        dt={this.destacadoDtRef}
                        DRE = {this.state.DREMensal}
                        unificarEmpresas={this.state.unificar}
                        selectedEmpresas={this.state.selectedEmpresas}
                        saveAsExcelFile={this.saveAsExcelFile}
                        loading={this.state.loading}
                        search={this.reSearch}
                        push={this.props.push}
                        resetTotalItem={this.resetTotalItem}
                        onFilterTable={this.onFilterTable}
                    />
                </div>
                </div>
                </>
            )
        }

        const renderDRESaldoTable = (header, list, ref, dtRef) => {
            if( (this.state.isFinishedSearch && !this.state.habilitaBusca) || this.state.loading )
            return (
                <>
                
                <div className="" 
                    style={{paddingBottom: ConstantsUtil.paddingBeetwenTablesDREConsolidado}}
                >
                <div className="borda" >
                {/* <br /> */}
                    <DRESaldoTable
                        ref={ref}
                        dt={dtRef}
                        header={header}
                        list = {list}
                        initialDate={this.state.DREMensal ? this.state.DREMensal.initialDate : ""}
                        finalDate={this.state.DREMensal ? this.state.DREMensal.finalDate : ""}
                        saveAsExcelFile={this.saveAsExcelFile}
                        loading={this.state.loading}
                        push={this.props.push}
                        resetTotalItem={this.resetTotalItem}
                        onFilterTable={this.onFilterTable}
                    />
                </div>
                </div>
                </>
            )
        }

        const renderDREResultadoTable = (header, list) => {
            if( (this.state.isFinishedSearch && !this.state.habilitaBusca) || this.state.loading )
            return (
                <>
                
                <div className="" 
                    style={{paddingBottom: ConstantsUtil.paddingBeetwenTablesDREConsolidado}}
                >
                <div className="borda" >
                {/* <br /> */}
                    <DREResultadoTable
                        ref={this.resultadoRef}
                        header={header}
                        list = {list}
                        initialDate={this.state.DREMensal ? this.state.DREMensal.initialDate : ""}
                        finalDate={this.state.DREMensal ? this.state.DREMensal.finalDate : ""}
                        saveAsExcelFile={this.saveAsExcelFile}
                        loading={this.state.loading}
                        push={this.props.push} 
                    />
                </div>
                </div>
                </>
            )
        }

        const renderDREDiferencaTable = (header, list) => {
            if( ((this.state.isFinishedSearch && !this.state.habilitaBusca) || this.state.loading) && AuthService.getPerfil().isAdmin)
            return (
                <>
                <div className="" 
                    style={{paddingBottom: ConstantsUtil.paddingBeetwenTablesDREConsolidado}}
                >
                <div className="borda" >
                {/* <br /> */}
                    <DREDiferencaTable
                        header={header}
                        ref={this.diferencaRef}
                        DRE = {this.state.DREMensal}
                        unificarEmpresas={this.state.unificar}
                        selectedEmpresas={this.state.selectedEmpresas}
                        list = {list}
                        saveAsExcelFile={this.saveAsExcelFile}
                        loading={this.state.loading}
                        push={this.props.push} 
                    />
                </div>
                </div>
                </>
            )
        }

        const renderButtons = () => {
            if(this.state.habilitaBusca){
                return (
                    <Button 
                        label="Gerar Flxuo de Caixa"
                        className='small-button'
                        icon="pi pi-search"
                        onClick = {this.callSearch}
                        // style={ {maxHeight: '35px' } }
                        disabled = {this.state.isSearchingLancamentos}
                    />
                )
            }
            return (
                <>
                    <Button 
                        label="Voltar"
                        icon="pi pi-undo"
                        className="p-button-danger small-button"
                        onClick = {this.toggleButton}
                        // onClick = {() => this.recebimentoRef.current.exportExcel()}
                        // style={ {maxHeight: '35px' } }
                        // disabled = {this.state.isSearchingLancamentos}
                    />
                    <Button
                        label="Exportar Tudo"
                        icon="pi pi-file-excel"
                        className="p-button-success small-button"
                        onClick={this.exportExcel}
                        tooltip={ConstantsUtil.exportXlsxLabel}
                        style ={{marginLeft: '10px'}}
                        tooltipOptions={{position: 'top'}}
                    />
                </>
            )
        }

        return(
            <div className="bs-docs-section " >
            <Card title = "Fluxo de Caixa Mensal">
                <div className = "col-md-12">

                <div className="row">

                    {renderCalendarFilters()}

                </div>  

                <div
                    style={{marginBottom: '0.5rem'}}
                >
                    {renderButtons()}
                </div>
                
                </div> {/* col-md-12 */}

                <div className="visaorj-small">

                {renderDRESaldoTable
                (
                    "Saldo Inicial", this.state.DREMensal ? this.state.DREMensal.saldoInicial : [],
                    this.saldoInicialRef,
                    this.saldoInicialDtRef
                )}

                {renderDRERecebimentoTable()}

                {renderDREPagamentoTable()}

                {renderDREResultadoTable(ConstantsUtil.resultadoOpracionalLabel,  this.state.DREMensal ? [this.state.DREMensal.resultado] : [])}

                {renderDREDestacadoTable()}

                {renderDREResultadoTable(ConstantsUtil.resultadoFinalLabel,  this.state.DREMensal ? [this.state.DREMensal.resultadoFinal] : [])}

                {renderDRESaldoTable
                (
                    "Saldo Final",
                    this.state.DREMensal ? this.state.DREMensal.saldoFinal : [],
                    this.saldoFinalRef,
                    this.saldoFinalDtRef,
                )}

                {renderDREDiferencaTable("Diferença", this.state.DREMensal ? [{total: this.state.DREMensal.diferenca}] : [])}
                </div>
            </Card>
            <div className="d-flex "/>
        </div>              
        )
    }

}

DREMensal.contextType = AuthContext

export default withRouter(DREMensal)