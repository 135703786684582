import GeneralServices from "./generalServices";
import ProductService from "./productService";

class BuyProductService extends ProductService {

    constructor() {
        super('/api/buyProducts')
    }

    getProviderNamesList(list){
        var providerNamesList = []
        list.forEach(buyProduct => {
            var nomeFornecedor = buyProduct.nomeFornecedor
            if(!providerNamesList.some( element => element.value === nomeFornecedor )){
                var object = {label: nomeFornecedor, value: nomeFornecedor}
                providerNamesList.push(object)
            }
        })
        providerNamesList.sort( GeneralServices.compareGenericValues );
        return providerNamesList
    }

    getProviderCNPJList(list){
        var providerCNPJList = []
        list.forEach(buyProduct => {
            var cnpjFornecedor = buyProduct.cnpjFornecedor
            if(!providerCNPJList.some( element => element.value === cnpjFornecedor )){
                var object = {label: cnpjFornecedor, value: cnpjFornecedor}
                providerCNPJList.push(object)
            }
        })
        providerCNPJList.sort( GeneralServices.compareGenericValues );
        return providerCNPJList
    }


    getParametrizedDescriptionList(list){
        var parametrizedDescriptionList = []
        list.forEach(buyProduct => {
            if(buyProduct.productDTO){
                if(!parametrizedDescriptionList.some(element => element.value === buyProduct.productDTO.descricao)){
                    var object = {label: buyProduct.productDTO.descricao, value: buyProduct.productDTO.descricao}
                    parametrizedDescriptionList.push(object)
                }
            }
        })
        parametrizedDescriptionList.sort( GeneralServices.compareGenericValues );
        return parametrizedDescriptionList
    }

    searchBuyProductByFornecedor(idFornecedor) {
        let params = `?`
        params = `${params}&idFornecedor=${idFornecedor}`
        return this.get(`/searchByFornecedor${params}`)
    }

    save(product){
        return this.post(`/save`, product)
    }

    getUploadCadastroProdutosResult(progressKey){
        let params = `?`
        params = `${params}&progressKey=${progressKey}`
        return this.get(`/getUploadCadastroProdutosResult${params}`)
    }    

}

export default BuyProductService