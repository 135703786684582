import React from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import GeneralServices from '../../app/service/generalServices'
import ConstantsUtil from '../../context/constantsUtil'
import ContaContabilView from '../planoDeContas/contaContabilView'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import LivroRazaoService from '../../app/service/relatorios/livroRazaoService'
import HandleErrorService from '../../app/service/handleErrorService'
import * as popUp from '../toastr'

class LivroRazaoTable extends React.Component {

    state = {
        
        loading: false,
        lancamentoToDelete: '',
        displayDeleteConfirmation: false,

    }

    constructor(){
        super();
        this.toast = React.createRef();
        this.dt = React.createRef();
        this.livroRazaoService = new LivroRazaoService();
    }

    getTableHeaders = () => {
        //Para não mostrar "undefined" no header enquanto o backend está processando a requisição
        const contaContabil = this.props.livroRazao.contaContabil ? this.props.livroRazao.contaContabil : ""
        const contaContabilLabel = "Conta Contábil: " + contaContabil
        
        const periodo = this.props.livroRazao.initialDate ? this.props.livroRazao.initialDate + " - " + this.props.livroRazao.finalDate : ""
        const periodoLabel = "Período: " + periodo

        return {contaContabil: contaContabilLabel, periodo: periodoLabel}
    }

    rowClass(rowData) {
        const obj = {
            [ConstantsUtil.totaisTableLabel]: rowData.tipoLancamento === ConstantsUtil.tipoTotaisFichaBancariaLabel
        }

        return obj
    }

    callDelete = (lancamento) => {
        this.setState({lancamentoToDelete: lancamento})
        this.setState({displayDeleteConfirmation: true})
    }
    
    cancelDelete = () => {
        this.setState({lancamentoToDelete: null})
        this.setState({displayDeleteConfirmation: false})
    }

    deleteLancamento = () => {
        const obj = {
            idLivroDiario: this.state.lancamentoToDelete.idLivroDiario
        }
        this.livroRazaoService.delete(obj)
        .then(response => {
            popUp.successPopUp("Lançamento deletado com sucesso!")
            this.setState({lancamentoToDelete: ''})
            this.setState({displayDeleteConfirmation: false})
            this.props.search()
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
    }

    render (){

        const renderContraPartida = (rowData) => {
            return(
                <ContaContabilView
                    contaContabil={rowData.contaContabilContrapartida}
                    push={this.props.push}
                />
            )
        }

        const renderActionColumn = () => {
            if(!this.props.disabled){
                return (
                    <Column header="Ações" body={actionBody} style ={ {width: '70px'} }
                    />
                )
            }
        }

        const actionBody = (lancamento) => {
            if(lancamento.implantacaoSaldo){
                return (
                    <Button
                        tooltip='Excluir Lançamento'
                        tooltipOptions={{position: 'top'}}
                        icon="pi pi-times"
                        className={ "p-button-rounded p-mr-2 p-button-danger" }
                        // style ={{marginLeft: '10px'}}
                        onClick={() => this.callDelete(lancamento)}
                    />
                )
            }
        }

        const renderDeleteConfirmationFooter = () => {
            return (
                <div>
                    <Button label="Confirmar" icon="pi pi-check"
                        // onClick={this.deleteLancamentoFechamentoBancario} 
                        onClick={this.deleteLancamento} 
                        autoFocus
                    />
                    <Button label="Cancelar" icon="pi pi-times" onClick={this.cancelDelete}
                            className="p-button-text" />
                </div>
            );
        }

        return (
            <>
            <div className = "card-header">
            <div className='col-md-12'>
                <div className='row'>
                    <div className='col-md-8'>
                        <h5 className='medium-font-size'>{this.getTableHeaders().contaContabil}</h5>            
                    </div>
                    <div className='col-md-4'>
                        <h5 className='medium-font-size'>{this.getTableHeaders().periodo}</h5>
                    </div>
                </div>
                
            </div>
            </div>

            <DataTable
                ref={this.dt}
                value={this.props.livroRazao.livroRazaoOutputDTO}
                className="p-datatable-sm small-font"
                // className="p-datatable-responsive-demo"
                rowClassName={this.rowClass}
                rowHover
                showGridlines
                selection={this.state.selectedContasBancaria}
                onSelectionChange={this.handleSelectionChange}
                style={{ maxWidth: '100vw' }}
                scrollable
                scrollHeight="500px"
                loading={this.props.loading || this.state.loading}
                dataKey="id"
                emptyMessage="Nenhum Lançamento encontrado."
                paginator rows={ConstantsUtil.initialTableQuantityOption}
                paginatorTemplate={GeneralServices.tablePaginatorTemplate(ConstantsUtil.initialTableQuantityOption, this.props.livroRazao && this.props.livroRazao.livroRazaoOutputDTO ? this.props.livroRazao.livroRazaoOutputDTO.length : '')}
                currentPageReportTemplate="Mostrando de {first} ao {last} de {totalRecords} lançamentos"
            >

            <Column field="data" header="Data"
                style ={ {width: '100px'} }
            />

            <Column field="historico" header="Histórico"
                style ={ {width: '400px'} }
            />

            <Column field="codigoContaContabilContrapartida" header="Contrapartida"
                style ={ {width: '120px'} }
                body = { renderContraPartida }
            />

            <Column field="debito" header="Debito"
                style ={ {width: '80px'} }
                body = {rowData => GeneralServices.showFormattedIfNonZero(rowData.debito, true) }
            />

            <Column field="credito" header="Credito"
                style ={ {width: '80px'} }
                body = {rowData => GeneralServices.showFormattedIfNonZero(rowData.credito, true) }
            />

            <Column field="saldo" header="Saldo" 
                style ={ {width: '100px'} }
                body = {rowData => GeneralServices.showFormattedIfNotNull(rowData.saldo, true) }
            />

            {renderActionColumn()}

            </DataTable>

            <Dialog header="Excluir Lançamento"
                visible={this.state.displayDeleteConfirmation}
                modal = {true} //congela restante da tela
                style={{ width: '350px' }}
                footer={renderDeleteConfirmationFooter()}
                onHide={this.cancelDelete}>
                <div className="confirmation-content row" style={{marginLeft: '10px'}}>
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem', marginRight: '10px'}} />
                    <div style={{marginBottom: '10px'}}> Deseja confirmar? </div>
                </div>
            </Dialog>

            </>
        )
    }


}  

export default LivroRazaoTable