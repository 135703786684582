import React from 'react'
import { Toast } from 'primereact/toast'
import { Button } from 'primereact/button'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Dialog } from 'primereact/dialog'

import { Prompt } from 'react-router'

import ContaBancariaService from '../../app/service/conciliacaoBancaria/contaBancariaService'

import Highlighter from 'react-highlight-words'
import '../../css/primereactTable.css'
import AplicacaoFinanceiraService from '../../app/service/conciliacaoBancaria/aplicacaoFinanceiraService'
import { InputTextarea } from 'primereact/inputtextarea'
import CustomHighlighter from '../customHighlighter'

class AplicacaoFinanceiraTable extends React.Component {
    
    constructor(){
        super()
        this.dt = React.createRef()
        this.contaBancariaService = new ContaBancariaService()
        this.aplicacaoFinanceiraService = new AplicacaoFinanceiraService()
        
    }

    state = {
        id: null,

        // nomeBanco: '',

        // numConta: '',
        
        // numDigitoConta: '',
        
        // numAgencia: '',

        aplicacaoFinanceiraList: [],
        
        nameField: 'contaBancaria.nomeBanco',
        numContaField: 'contaBancaria.numConta',
        numAgenciaField: 'contaBancaria.numAgencia',

        selectedContaBancaria: null,
        editContaBancariaDialog: false,
        editContaBancariaDialogHeader: "Editar Conta Bancária",

        didUpdated: false,

    }

    componentDidUpdate(){
        this.setStates()
    }

    componentDidMount(){
        this.setStates()
    }

    setStates = () => {
        if( !this.state.didUpdated || this.props.forceUpdate){
            var list = JSON.parse(JSON.stringify(this.props.list))
            list.forEach(element => { element.updated = false })
            this.setState({aplicacaoFinanceiraList: list})
            this.setState({didUpdated: true})
            
            if(this.props.doneForceUpdate){
                this.props.doneForceUpdate()
            }
        }
    }

    handleSelectionChange = (event) => {
        this.setState({selectedAplicacaoFinanceira: event.value})
        this.props.handleSelectionChange(event.value)
    }    

    codeEditor(props) {
        return this.inputTextEditor(props, 'nome');
    }

    inputTextEditor = (props, field) => {
        return (
            <InputTextarea
                className={this.state.inputHistoricoErrorClass}
                value={props.rowData[field]}
                onChange={(e) => this.onEditorValueChange(props, e.target.value)}
                onKeyDown={(e) => this.onEditorValueChange(props, e.target.value)}
                rows={3} cols={50}
                style={{width: '80%'}}
                autoResize
            />
        )
    }

    onEditorValueChange(props, value) {
        let updatedProducts = [...props.value];
        updatedProducts[props.rowIndex][props.field] = value;
        updatedProducts[props.rowIndex].updated = true;
        this.updateList(updatedProducts)
    }

    updateList = (updatedList) => {
        this.setState({aplicacaoFinanceiraList: updatedList})
    }

    onRowEditCancel = () => {
        this.setState({didUpdated: false})
    }

    save = () => {
        this.props.updateList(this.state.aplicacaoFinanceiraList)
        // window.location.reload()
    }

    sholdPreventLeave = () => {

        var shouldPrevent = false

        this.state.aplicacaoFinanceiraList.forEach(item => {
            if(item.updated){
                shouldPrevent = true
                return
            }
        })

        return shouldPrevent
    }

    render (){


        const renderDeleteConfirmationFooter = () => {
            return (
                <div>
                    <Button label="Confirmar" icon="pi pi-check"
                            onClick={this.confirmDelete} autoFocus />
                    <Button label="Cancelar" icon="pi pi-times" onClick={() => this.setState({displayConfirmation: false})}
                            className="p-button-text" />
                </div>
            );
        }

        const renderSelectionColumn = () => {
            if(this.props.selectable){
                return(
                    <Column selectionMode="single" headerStyle={{ width: '3rem' }}></Column>
                )
            }
        }

        const renderEditColumn = () => {
            if(this.props.allowEdit){
                return(
                    <Column
                        header="Editar"
                        rowEditor
                        // body={actionBodyTemplate}
                        style ={ {width: '40px'} }
                        />
                )
            }
        }

        const renderSaveButton = () => {
            if(this.props.allowEdit){
                return(

                <>
                <br />

                <Button 
                    label="Salvar Alterações"
                    icon="pi pi-save"
                    onClick = {this.save}
                    disabled = {this.state.isSavingLancamentos}
                    />
                </>
                )
            }
        }

        return (
            <div className="datatable-crud-demo">
            <Prompt
                when={this.sholdPreventLeave()}
                message='Há dados não salvos. Deseja continuar?'
            />
            <Toast ref={this.toast} />

            <div className="card">
                {/* <Toolbar className="p-mb-4" left={leftToolbarTemplate} 
                // right={rightToolbarTemplate}
                /> */}

                <DataTable ref={this.dt} value={this.state.aplicacaoFinanceiraList}
                    className="p-datatable-sm small-font"
                    editMode="row" onRowEditInit={this.onRowEditInit} onRowEditCancel={this.onRowEditCancel}
                    rowHover
                    showGridlines
                    selection={this.state.selectedAplicacaoFinanceira}
                    onSelectionChange={this.handleSelectionChange}
                    // scrollable
                    // scrollHeight="500px"
                    loading={this.props.loading}
                    dataKey="id"
                    emptyMessage="Nenhuma Aplicação Financeira cadastrada."
                    paginator rows={30} rowsPerPageOptions={[30, 100, 400]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Mostrando de {first} ao {last} de {totalRecords} Aplicações Financeiras"
                >

                    {renderSelectionColumn()}
                    
                    <Column field="nome" header="Nome" style ={ {width: '200px'} }
                        editor={(props) => this.codeEditor(props)}
                        body={rowData => 
                            <CustomHighlighter
                                searchWords={[this.props.searchNome]}
                                textToHighlight={rowData.nome}
                            />
                        } 
                    />

                    <Column field={this.state.nameField} header="Banco" style ={ {width: '120px'} }
                        // body={rowData => 
                        //     <CustomHighlighter
                        //         searchWords={[this.props.searchNomeConta]}
                        //         textToHighlight={rowData.contaBancaria.nomeBanco}
                        //     />
                        // }
                    />

                    <Column field={this.state.numAgenciaField} header="Agência" style ={ {width: '40px'} }
                    />

                    <Column field={this.state.numContaField} header="Conta" style ={ {width: '40px'} } 
                            body={rowData => this.contaBancariaService.formatNumConta(rowData.contaBancaria)}
                    />

                    {/* <Column header="Editar" body={actionBodyTemplate} style ={ {width: '140px'} }></Column> */}

                    {renderEditColumn()}
                    
                    
                </DataTable>

                
            </div>
            
            {renderSaveButton()}

            <Dialog 
                header="Deletar Conta Bancária"
                visible={this.state.displayConfirmation}
                modal = {true} //congela restante da tela
                style={{ width: '350px' }}
                footer={renderDeleteConfirmationFooter()}
                onHide={() => this.setState({displayConfirmation: false})}>
                <div className="confirmation-content row" style={{marginLeft: '10px'}}>
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem', marginRight: '10px'}} />
                    <div style={{marginBottom: '10px'}}> Deseja confirmar exclusão? </div>
                </div>
            </Dialog>
        </div>
        )
    }


}  

export default AplicacaoFinanceiraTable