import React from 'react'
import { Button } from 'primereact/button'
import { Toolbar } from 'primereact/toolbar'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Dialog } from 'primereact/dialog'

import currecyFormater from 'currency-formatter'
import ProductDialog from './product/productDialog'
import BuyProductService from '../app/service/buyProductService'
import * as popUp from './toastr'
import GeneralServices from '../app/service/generalServices'
import TableFilters from './tableFilters'
import ConstantsUtil from '../context/constantsUtil'
import UploadDialog from './uploadDialog'
import HandleErrorService from '../app/service/handleErrorService'


class BuyProductCrudTable extends React.Component {

    state = {
        codigo: null,
        descricao: '',
        ncm: null,
        tipo: '',
        unidadeComercializada: '',
        quantidade: null,
        selectedProducts: null,
        productDialog: false,
        updateStockDialog: false,
        displayConfirmation: false,
        displayDeparameterizeConfirmation: false,
        editId: null,
        updateStockId: null,
        updateLastFilteredProducts: false,
        parametrizeButton: true,
        parametrizeProductState: {},
        codigoField: 'codigo',
        selectedCodes: null,

        descricaoField: 'descricao',
        selectedDescriptions: null,

        parametrizedDescricaoField: 'productDTO.descricao',
        selectedParametrziedDescriptions: null,

        NCMField: 'ncm',
        selectedNCM: null,
        unitField: 'unidadeComercializada',
        selectedUnits: null,
        providerNamesField: 'nomeFornecedor',
        selectedProviderNames: null,
        providerCNPJField: 'cnpjFornecedor',
        selectedProviderCNPJ: null,
        expandedRows: [],

        displayProductDialog: false,
    }
    constructor(){
        super()
        this.dt = React.createRef()
        this.buyProductService = new BuyProductService();
        this.tableFilters = new TableFilters();

    }

    componentDidUpdate(){
        if(this.props.list && this.props.list.length !== 0 && !this.state.updateLastFilteredProducts){
            this.setState({lastFilteredProducts: this.props.list})
            this.setState({updateLastFilteredProducts: true})
        }
    }

    valueBodyTemplate = (rowData) => {
        return currecyFormater.format(rowData.value, {locale: 'pt-BR'})
    }

    parametrize = () => {
        if(!this.state.selectedProducts || this.state.selectedProducts.length === 0){
            popUp.infoPopUp(ConstantsUtil.selecioneOsProdutosMessage)
            return
        }
        this.props.setFilteredProducts(this.state.selectedProducts)
        this.setState({parametrizeButton: false})
        this.props.requestParametrize(true)
    }

    // markParameterizable = (toMarkAsNonParametrizable) => {
    //     if(!this.state.selectedProducts || this.state.selectedProducts.length === 0){
    //         popUp.infoPopUp(ConstantsUtil.selecioneOsProdutosMessage)
    //         return
    //     }
    //     var listOfId = []
    //     Array.from(this.state.selectedProducts).forEach(selectedProduct => {
    //         listOfId.push(selectedProduct.id)
    //     })
    //     this.props.markParameterizable(listOfId, toMarkAsNonParametrizable)
    //     this.setState({selectedProducts: null})
    // }

    cancelParametrize = () => {
        this.props.search()
        this.setState({selectedProducts: []})
        this.setState({parametrizeButton: true})
        this.props.requestParametrize(false)
    }

    handleSelectionChange = async (e) => {

       await this.setState({selectedProducts: e.value})

       if(!this.state.selectedProducts || this.state.selectedProducts.length === 0 ){
           this.props.search()
           this.setState({parametrizeButton: true})
           this.props.requestParametrize(false)
       }
    }

    
    openCadastroProdutoCompraDialog = () => { 
        this.setState({displayProductDialog: true})
    }

    hideCadastroProdutoCompraDialog = () => {
        this.setState({displayProductDialog: false})
    }

    hideImportacaoProdutoCompraDialog = () => {
        this.setState({uploadDialogVisible: false})
    }

    exportCSV = () => {
        this.dt.current.exportCSV();
    }

    delete = () => {
        if(this.state.selectedProducts){
            this.setState({displayConfirmation: true})
        } else {
            popUp.warningPopUp("Nenhum produto foi selecionado para exclusão")
        }
    }

    deparameterize = () => {
        if(this.state.selectedProducts){
            for(var i = 0; i < this.state.selectedProducts.length; i++){
                var selectedProduct = this.state.selectedProducts[i]
                if(!(selectedProduct.situacaoParametrizacao === ConstantsUtil.situacaoParametrizacaJaParametrizado)){
                    popUp.infoPopUp("Produto " + selectedProduct.descricao + " foi selecionado mas não está parametrizado")
                    return
            }
            this.setState({displayDeparameterizeConfirmation: true})
        }
        } else {
            popUp.infoPopUp("Nenhum produto foi selecionado para desparametrização")
        }
    }
    
    confirmDelete = () => {
        this.setState({displayConfirmation: false})
        this.setState({selectedProducts: null})
        if(this.state.selectedProducts){
            var listOfId = []
            Array.from(this.state.selectedProducts).forEach(selectedProduct => {
                listOfId.push(selectedProduct.id)
            })
        }
        this.props.deleteMultiple(listOfId)
    }

    confirmDeparameterize = () => {
        this.setState({displayDeparameterizeConfirmation: false})
        if(this.state.selectedProducts){
            var listOfId = []
            Array.from(this.state.selectedProducts).forEach(selectedProduct => {
                listOfId.push(selectedProduct.id)
            })
        }
        this.props.deparameterize(listOfId)
        this.setState({selectedProducts: null})
    }

    viewProduct = async (buyProduct) => {
        var product = buyProduct.productDTO
        if(product){
            var object = {
                codigo: product.codigo,
                descricao: product.descricao,
                ncm: product.ncm,
                cfop: product.cfop,
                tipo: product.tipo,
                unidadeComercializada: product.unidadeComercializada,
                proporcao: buyProduct.proportion
            }
            await this.setState({parametrizeProductState: object})
            this.setState({productDialog: true})
        } else{
            popUp.warningPopUp("O produto envolvido não consta no banco de dados")
        }
        
    }

    parametrized = (parametrized) => {
        return this.state.selectedProducts.some(buyProduct => buyProduct.parametrized === parametrized)
    }

    checkSelectedProducts = () => {
        var selected = this.state.selectedProducts
        if(this.props.resetSelectedProducts){
            selected = null
            this.setState({selectedProducts: null})
            this.props.completeResetedSelectedBuyProducts()
        }
        return selected
    }

    onFilterChange = async (event, filterField) => {
        if(this.state.expandedRows && this.state.expandedRows.length!==0){
            await this.setState({expandedRows: []})
        }
        const name = event.target.name
        if(this.dt.current) {
            this.dt.current.filter(event.value, filterField, 'in');
        } else{
            this.state.current.filter(event.value, filterField, 'in');
        }
        this.setState({[name]: event.value})
    }

    produtosCompraToExportTemplate = () => {
        const nomeDoProdutoLabel = "Nome do Produto"
        const nomeFornecedorLabel = "Nome do Fornecedor"
        const cnpjFornecedorLabel = "CNPJ do Fornecedor"
        const unidadeLabel = "Unidade"

        var finalList = [{
            [nomeDoProdutoLabel]: '',
            [nomeFornecedorLabel]: '',
            [cnpjFornecedorLabel]: '',
            [unidadeLabel]: ''
        }]
        return finalList
    }

    onFinishUpload = (progressKey) => {

        this.buyProductService.getUploadCadastroProdutosResult(progressKey)
        .then(response => {
            const quantidadeProdutosCadastrados = response.data
            popUp.successPopUp(`${quantidadeProdutosCadastrados} Produto(s) Cadastrado(s)!`)
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })

        this.hideImportacaoProdutoCompraDialog()
        this.props.search()
    }

    render (){

        const rightToolbarTemplate = () => {
            return (
                <React.Fragment>
                    {/* <Button label="Desparametrizar" icon="pi pi-undo" className="p-button-warning"
                            onClick={this.deparameterize}
                            disabled = { !this.state.selectedProducts || this.state.selectedProducts.length === 0 || this.props.disableDeleteButton || !this.state.parametrizeButton}
                            // style={{marginRight: '10px'}}
                            /> */}
                    
                    <Button
                        label="Deletar"
                        className="p-button-danger small-font"
                        style={{maxHeight: '25px'}}
                        icon="pi pi-trash"
                        onClick={this.delete}
                        // disabled = { !this.state.selectedProducts || this.state.selectedProducts.length === 0 || this.props.disableDeleteButton || !this.state.parametrizeButton}
                        disabled
                    />
                </React.Fragment>
            )
        }

        const leftToolbarTemplate = () => {
            return (
                <>
                <div className='col-md-12'>
                <div className='row'>

                <div className='col-md-12'>
                    <h5 
                        className='medium-font-size'
                        style={{marginBottom: '20px'}}
                    >
                        Produtos de Compra
                    </h5>            
                </div>

                </div>
                
                <div className='row'>
                <div className='col-md-12'>
                    {leftToolbarTemplateSpecific()}
                </div>
                </div>

                </div>

                </>
            )

        }

        const leftToolbarTemplateSpecific = () => {
            if(this.state.parametrizeButton){
                if(this.props.situacaoParametrizacao === ConstantsUtil.situacaoParametrizacaAindaNaoParametrizado){
                    return (
                    <>
                        <Button
                            label="Parametrizar"
                            icon="pi pi-link"
                            className="p-button-success small-font"
                            style={{maxHeight: '25px'}}
                            onClick={this.parametrize}
                        />
                        <Button
                            label="Cadastrar Produto de Compra"
                            tooltip = {ConstantsUtil.tooltipCadastroManualProdutoCompra}
                            tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }}
                            icon="pi pi-save"
                            className="small-font right-button"
                            style={{maxHeight: '25px'}}
                            onClick={this.openCadastroProdutoCompraDialog}
                        />
                        <Button 
                            label="Importar Produtos de Compra"
                            icon="pi pi-upload"
                            tooltip = {ConstantsUtil.tooltipCadastroManualProdutoCompra}
                            tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }}
                            className="p-button-success small-font right-button"
                            onClick={() => this.setState({uploadDialogVisible: true})}
                            style={{maxHeight: '25px'}}
                        />                        
                    </>
                    )
                }
                else if(this.props.situacaoParametrizacao === ConstantsUtil.situacaoParametrizacaJaParametrizado){
                    return (
                        <Button
                            label="Desparametrizar"
                            icon="pi pi-undo"
                            className="p-button-danger small-font"
                            style={{maxHeight: '25px'}}
                            onClick={this.deparameterize}
                        />
                    )
                }
                else{
                    return (
                        <Button
                            label="Desmarcar Como Não Parametrizável"
                            icon="pi pi-times"
                            className="p-button-danger small-font"
                            style={{maxHeight: '25px'}}
                            onClick={() => this.markParameterizable(false)}
                        />
                    )
                }
            }
            else{
                return (
                    <Button
                        label="Cancelar"
                        icon="pi pi-times"
                        className="p-button-danger small-font"
                        style={{maxHeight: '25px'}}
                        onClick={() => this.cancelParametrize()}
                    />
                )
            }
        }

        const renderDeleteConfirmationFooter = () => {
            return (
                <div>
                    <Button label="Confirmar" icon="pi pi-check"
                            onClick={this.confirmDelete} autoFocus />
                    <Button label="Cancelar" icon="pi pi-times" onClick={() => this.setState({displayConfirmation: false})}
                            className="p-button-text" />
                </div>
            );
        
        }
        const renderDeparameterizeConfirmationFooter = () => {
            return (
                <div>
                    <Button label="Confirmar" icon="pi pi-check"
                            onClick={this.confirmDeparameterize} autoFocus />
                    <Button label="Cancelar" icon="pi pi-times" onClick={() => this.setState({displayDeparameterizeConfirmation: false})}
                            className="p-button-text" />
                </div>
            );
        }

        const renderProductDialog = () => {
            if(this.state.displayProductDialog){
                return (
                    <ProductDialog
                        visible={this.state.displayProductDialog}
                        header="Cadastrar Produto de Compra"
                        hideDialog={this.hideCadastroProdutoCompraDialog}
                        isProdutoCompra
                        search={this.props.search}
                    />
                )
            }
        }

        //Código filter
        const codeFilterElement =
        // this.tableFilters.renderCodigoFilter(
        this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedCodes,
            this.props.list,
            "selectedCodes",
            (name, value) => this.setState({[name]: value}),
            this.state.codigoField,
            '100px',
            "Código"
        )

        //Descrição Filter
        const descriptionFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedDescriptions,
            this.props.list,
            "selectedDescriptions",
            (name, value) => this.setState({[name]: value}),
            this.state.descricaoField,
            '150px',
            "Nome"
        )

        //TipoProduto Filter
        const tipoProdutoFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedTipoProdutoDescricao,
            this.props.list,
            "selectedTipoProdutoDescricao",
            (name, value) => this.setState({[name]: value}),
            "tipoProduto.descricao",
            '150px',
            "Tipo"
        )

        //Descrição Produtos Parametrizados Filter
        const parametrizedDescriptionFilterElement = this.tableFilters.renderDescriptionFilter(
            this.state.selectedParametrziedDescriptions,
            this.buyProductService.getParametrizedDescriptionList,
            this.props.list,
            "selectedParametrziedDescriptions",
            this.onFilterChange,
            this.state.parametrizedDescricaoField,
            '150px',
            "Produto"
        )
        
        //Fornecedor Filter
        const nomeFornecedorFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedNomeFornecedor,
            this.props.list,
            "selectedNomeFornecedor",
            (name, value) => this.setState({[name]: value}),
            this.state.providerNamesField,
            '100%',
            "Fornecedor"            
        )

        //CNPJ Filter
        const cnpjFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedCNPJFornecedor,
            this.props.list,
            "selectedCNPJFornecedor",
            (name, value) => this.setState({[name]: value}),
            this.state.providerCNPJField,
            '100%',
            "CNPJ"            
        )

        //NCM Filter
        // const NCMFilterElement = this.tableFilters.renderNCMFilter(this.state.selectedNCM, this.buyProductService.getNCMList,
        //     this.props.list, "selectedNCM", this.onFilterChange, this.state.NCMField)

        //TipoDeCadastro Filter
        const tipoCadastroFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedTipoCadastro,
            this.props.list,
            "selectedTipoCadastro",
            (name, value) => this.setState({[name]: value}),
            'tipoCadastro',
            '60px',
            "Cadastro"
        )

        //Unidade Filter      
        const unitFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedUnidade,
            this.props.list,
            "selectedUnidade",
            (name, value) => this.setState({[name]: value}),
            this.state.unitField,
            '100%',
            "Unidade"            
        )


        const renderProdutoDeVendaParametrizado = () => {
            if(this.props.situacaoParametrizacao === ConstantsUtil.situacaoParametrizacaJaParametrizado){
                return (
                    [
                    <Column 
                        header="Produto Próprio parametrizado" sortable style ={ {width: '140px'} }
                        field={this.state.parametrizedDescricaoField}
                        filter filterElement={parametrizedDescriptionFilterElement}
                        body = {rowData => rowData.productDTO ? `${rowData.productDTO.codigo} - ${rowData.productDTO.descricao}` : ''}
                        // body = {rowData => rowData.productDTO ? rowData.productDTO.descricao : ''}

                    />,
                    <Column 
                        header="Conversão" sortable style ={ {width: '50px'} }
                        field="proportion"
                        // filter filterElement={parametrizedDescriptionFilterElement}
                        // body = {rowData => rowData.productDTO ? `${rowData.productDTO.codigo} - ${rowData.productDTO.descricao}` : ''}
                        // body = {rowData => rowData.productDTO ? rowData.productDTO.descricao : ''}

                    />
                    ]
                )
            }
        }

        return (
            <div >

            <div className="card">

                <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                <DataTable
                    ref={this.dt}
                    value={this.props.list}
                    className="p-datatable-sm small-font"
                    rowHover
                    selection={this.checkSelectedProducts()}
                    onSelectionChange={this.handleSelectionChange}
                    scrollable
                    scrollHeight="500px"
                    loading={this.props.loading}
                    emptyMessage="Nenhum produto de compra encontrado"
                    dataKey="id"
                    paginator rows={ConstantsUtil.initialTableQuantityOption}
                    // rowsPerPageOptions={[5, 10, 25]}
                    paginatorTemplate={GeneralServices.tablePaginatorTemplate(ConstantsUtil.initialTableQuantityOption, this.props.list ? this.props.list.length : '')}
                    // paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Mostrando de {first} ao {last} de {totalRecords} produtos"
                    >

                    <Column selectionMode="multiple" headerStyle={{ width: '30px' }}/>

                    <Column field={this.state.codigoField} header="Código do Fornecedor"
                            // sortable
                            style ={ {width: '60px'} }
                            filter filterElement={codeFilterElement}
                    />

                    <Column
                        header="Nome do Produto"
                        field={this.state.descricaoField} 
                        sortable style ={ {width: '140px'} }
                        filter filterElement={descriptionFilterElement}
                    />

                    <Column
                        header="Tipo do Produto"
                        field="tipoProduto.descricao"
                        sortable style ={ {width: '100px'} }
                        filter filterElement={tipoProdutoFilterElement}
                    />

                    {renderProdutoDeVendaParametrizado()}

                    <Column field={this.state.providerNamesField} header="Nome do Fornecedor" sortable style ={ {width: '140px'} }
                            filter filterElement={nomeFornecedorFilterElement}
                    />
                    <Column field={this.state.providerCNPJField} header="CNPJ do Fornecedor" sortable style ={ {width: '60px'} }
                            filter filterElement={cnpjFilterElement}
                    />

                    {/* <Column field={this.state.NCMField} header="NCM" body={rowData => GeneralServices.adjustNCM(rowData.ncm)}
                        // sortable
                        style ={ {width: '40px'} }
                        filter filterElement={NCMFilterElement} 
                    /> */}
                    <Column
                        header="Cadastro"
                        field="tipoCadastro"
                        style ={ {width: '40px'} }
                        filter filterElement={tipoCadastroFilterElement} 
                    />
                    {/* <Column field="parametrized" header="Parametrizado" body={rowData => rowData.parametrized ? 'Sim' : 'Não'} sortable style ={ {width: '100px'} }/> */}
                    
                    <Column field={this.state.unitField} header="Unidade"
                        // sortable
                        style ={ {width: '40px'} }
                        filter filterElement={unitFilterElement}
                    />
                    {/* <Column body={actionBodyTemplate} style ={ {width: '160px'} }/> */}
                </DataTable>


                {renderProductDialog()}

            </div>

            <Dialog header="Deletar Produto"
                        visible={this.state.displayConfirmation}
                        modal = {true} //congela restante da tela
                        style={{ width: '350px' }}
                        footer={renderDeleteConfirmationFooter()}
                        onHide={() => this.setState({displayConfirmation: false})}>
                    <div className="confirmation-content row" style={{marginLeft: '10px'}}>
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem', marginRight: '10px'}} />
                        <div style={{marginBottom: '10px'}}> Deseja confirmar exclusão? </div>
                    </div>
            </Dialog>

            <Dialog header="Desparametrizar Produto"
                        visible={this.state.displayDeparameterizeConfirmation}
                        modal = {true} //congela restante da tela
                        style={{ width: '350px' }}
                        footer={renderDeparameterizeConfirmationFooter()}
                        onHide={() => this.setState({displayDeparameterizeConfirmation: false})}
            >
                    <div className="confirmation-content row" style={{marginLeft: '10px'}}>
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem', marginRight: '10px'}} />
                        <div style={{marginBottom: '10px'}}> Deseja confirmar desparametrização? </div>
                    </div>
            </Dialog>

            <UploadDialog
                visible={this.state.uploadDialogVisible}
                title="Importação de Produtos de Compra"
                list = {this.props.list}
                templateToExport={this.produtosCompraToExportTemplate}
                nomeArquivoModelo="modelo_produtos_compra"
                uploadDescription={ConstantsUtil.importacaoArquivoProdutosDeCompra}
                // extraObject={`${this.props.dataLancamento} - ${this.props.updateStockHour}`}
                hideDialog={() => this.setState({uploadDialogVisible: false})}
                onFinish={this.onFinishUpload}
            />

        </div>
        )
    }


}  

export default BuyProductCrudTable