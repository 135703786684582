import React from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import HandleErrorService from '../../app/service/handleErrorService'
import AuthService from '../../app/service/authService'
import GeneralServices from "../../app/service/generalServices";
import CustomHighlighter from '../customHighlighter'
import GrupoDeEmpresasService from '../../app/service/central/grupoDeEmpresasService'
import TooltipButton from '../tooltipButton'
import ConstantsUtil from '../../context/constantsUtil'
import { Toolbar } from 'primereact/toolbar'
import { Button } from 'primereact/button'
import * as popUp from '../toastr'

class GruposDeEmpresasTable extends React.Component {

    constructor(){
        super()
        this.grupoDeEmpresasService = new GrupoDeEmpresasService()
    }

    state = {

        grupoDeEmpresasList: [],
        selectedGruposList: [],
        loading: false,

    }

    componentDidMount(){
        this.search()
    }

    search = () => {
        this.setState({loading: true})
        this.grupoDeEmpresasService.searchAllGrupos()
        .then(response => {
            this.setState({grupoDeEmpresasList: response.data})
            this.setState({loading: false})
        })
        .catch(error => {
            HandleErrorService.handleError(error)
            this.setState({loading: false})
        })
    }

    changeContextPath = (empresa) => {
        AuthService.changeCurrentContextPath(empresa.contextPath)
        window.location.reload()
    }

    filter(){
        return this.state.grupoDeEmpresasList.filter(empresa => GeneralServices.includeString(empresa.nome, this.props.searchText))
    }

    editGrupo = grupo => {
        this.props.edit(grupo)
    }

    inativarGrupo = grupo => {
        this.grupoDeEmpresasService.inativar(grupo)
        .then(response => {
            popUp.successPopUp("Grupo inativado com sucesso!")
            this.search()
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
    }

    handleSelectionChange = async (e) => {
        await this.setState({selectedGruposList: e.value})
        console.log("handleSelectionChange: ", this.state.selectedGruposList)
    }

    sync = () => {
        this.setState({loading: true})
        this.grupoDeEmpresasService.sync(this.state.selectedGruposList)
        .then(response => {
            popUp.successPopUp("Grupo(s) sincronizado(s) com sucesso")
            this.setState({selectedGruposList: []})
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
        .finally(() => {
            this.setState({loading: false})
        })
    }

    render() {

        const renderHighlightNome = (rowData) => {

            return(
                <CustomHighlighter
                    searchWords={[this.props.searchText]}
                    textToHighlight={rowData.nome}
                />
            )
        }

        const renderInativarGrupoButton = (grupo) => {
            if(grupo.ativo)
            return (
                <TooltipButton
                    className="p-button-rounded p-mr-2 right-button p-button-danger "
                    icon = "pi pi-times"
                    tooltip = {
                        `Inativar Grupo.\n`
                        + `Todas as empresas do grupo serão inativadas automaticamente.\n`
                        + `As empresas poderão ser ativadas individualmente depois.`
                    }
                    tooltipOptions={{
                        className: 'custom-tooltip',
                        position: 'left'
                    }}                    
                    onClick={() => this.inativarGrupo(grupo)}
                />                
            )
        }

        const actionBody = (grupo) => {
            if(grupo.id === ConstantsUtil.idEmpresaGrupoSistema){
                return;
            }
            return (
                <React.Fragment>
                    <TooltipButton
                        tooltip = "Editar Grupo"
                        onClick={() => this.editGrupo(grupo)}
                    />
                    {renderInativarGrupoButton(grupo)}
                </React.Fragment>
            );
        }

        const leftToolbarTemplate = () => {
            return (
                <React.Fragment>
                    <TooltipButton
                        label="Sincronizar" icon="pi pi-refresh"
                        tooltip={
                            `A sincronização faz com que as empresas do mesmo grupo passem a se enxergar mutuamente como recebimento e pagamento.\n`
                            + `Quando uma nova empresa é criada no grupo, isso já é feito automaticamente.`
                        }
                        tooltipOptions={{
                            className: 'custom-tooltip',
                            position: 'left'
                        }}                        
                        className="small-font custom-tooltip"
                        style={{maxHeight: '20px'}}
                        disabled = { !this.state.selectedGruposList || this.state.selectedGruposList.length === 0 }
                        onClick = {this.sync}
                    />
                </React.Fragment>
            )
        }        

        return (
            <div className="datatable-crud-demo">
            <div className="card">

                <Toolbar
                    className="p-mb-4"
                    right={leftToolbarTemplate}
                />

                <DataTable
                    ref={this.dt}
                    value={this.filter()}
                    className="p-datatable-sm small-font"
                    rowHover
                    emptyMessage="Não há Empresas cadastradas."
                    onSelectionChange={this.handleSelectionChange}
                    selection={this.state.selectedGruposList}
                    // scrollable
                    // scrollHeight="500px"
                    loading={this.state.loading || this.props.loading}
                    dataKey="id"
                    paginator rows={ConstantsUtil.initialTableQuantityOption}
                    paginatorTemplate={GeneralServices.tablePaginatorTemplate(ConstantsUtil.initialTableQuantityOption, this.filter() ? this.filter().length : '')}
                    currentPageReportTemplate="Mostrando de {first} ao {last} de {totalRecords} Grupos Empresas"
                >

                    <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}/>

                    <Column field="nome" header="Nome" sortable
                        body = {renderHighlightNome}
                    />

                    <Column
                        header={
                            <div>
                                Ativo
                                <TooltipButton
                                    tooltip={
                                        "Um Grupo de Empresas é considerado ativo se ele possuir ao menos 1 empresa ativa."
                                    }
                                    tooltipOptions={{
                                        className: 'custom-tooltip',
                                        position: 'top'
                                    }}
                                    icon={<i className="pi pi-question" style={{fontSize: '0.6em'}}></i>}
                                    style ={{maxHeight: '20px', maxWidth: '20px', marginLeft: '10px'}}
                                    className="p-button-rounded p-mr-2"
                                />
                            </div>
                        }
                        field="ativo"
                        sortable
                        body={rowData => {
                            return (
                                <div tooltip="teste">
                                    {GeneralServices.renderStyledColorTableColumn(rowData.ativo ? 'Sim' : 'Não', rowData.ativo)}
                                </div>
                            )
                        }}
                    />

                    <Column
                        header="Ações"
                        style ={ {width: '140px'} }
                        body={actionBody}
                    />

                </DataTable>
            </div>

            </div>
        )
    }

}

export default GruposDeEmpresasTable