import React from 'react'

import { Button } from "primereact/button"
import TooltipButton from '../tooltipButton'


class CancelLancamentoBancarioButton extends React.Component {

    render(){
        return (
            <TooltipButton
                tooltip='Excluir Lançamento'
                icon="pi pi-times"
                className={ "p-button-rounded p-mr-2 p-button-danger right-button" }
                onClick={() => this.props.callDelete(this.props.lancamento)}
            />
        )
    }


}

export default CancelLancamentoBancarioButton