import React from 'react'
import { withRouter } from 'react-router-dom'

import Card from '../../components/card'
import * as popUp from '../../components/toastr'
import GeneralServices from '../../app/service/generalServices'
import { AuthContext } from '../../main/authProvider'
import { Button } from 'primereact/button'
import HandleErrorService from '../../app/service/handleErrorService'
import ProgressBarComponent from '../../components/progress/progressBar'
import CustomCalendar from '../../components/calendar/customCalendar'
import ConstantsUtil from '../../context/constantsUtil'
import SaldoContaContabilService from '../../app/service/relatorios/saldoContaContabilService'

class ReprocessarLivroDiario extends React.Component {

    constructor(){
        super();
        this.saldoContaContabilService = new SaldoContaContabilService();
    }

    state = {

        dataLancamento: '',
        dataLancamentoView: '',
        inputDateErrorClass:'',
        errorDateMessage:'',

        beginReprocess: false,
        reprocessing: false,
        progressKey: '',

        disableButton: false,

    }

    componentDidMount(){
    }

    handleDateChange = async (event) => {
        const value = event.target.value
        const name = event.target.name
        await this.setState({ [name]: GeneralServices.convertJSDateToBrDate(value) })
        const nameView = name+"View"
        await this.setState({[nameView]: value })
        
    }

    handleNavigatorDateChange = (event, e) => {
        e.onChange(event.originalEvent, event.value)
    }

    handleSelectionTipoDeRecebimentoChange = (tipoDeRecebimento, label) => {
        this.setState({selectedTipoDeRecebimento: tipoDeRecebimento})
        this.setState({selectedTipoDeRecebimentoLabel: label})
    }

    callReprocessLancamentos = () => {
        this.resetView()
        if(this.checkData()){
            this.reprocessLancamentos()
        }
    }

    resetView = () => {
        this.setState({inputDateErrorClass: ""})
        this.setState({errorDateMessage: ""})

        this.setState({progressKey: ''})
        this.setState({beginReprocess: false})
        this.setState({reprocessing: false})
    }

    checkData = () => {
        var check = true

        if(!this.state.dataLancamento){
            this.setState({inputDateErrorClass: "p-invalid"})
            this.setState({errorDateMessage: "Informe a data"})
            check = false
        }

        return check
    }

    reprocessLancamentos = () => {

        this.setState({disableButton: true})
        
        this.saldoContaContabilService.reprocess(this.state.dataLancamento)
        .then(async response => {
            await this.setState({progressKey: response.data})
            this.setState({beginReprocess: true})
            this.setState({reprocessing: true})
        })
        .catch(error => {
            HandleErrorService.handleError(error)
            this.setState({disableButton: false})
            this.setState({reprocessing: false})
        })
    }

    endReprocess = () => {
        this.setState({reprocessing: false})
        this.setState({disableButton: false})
        popUp.successPopUp("Lançamentos reprocessados com sucesso!")
    }


    render() {

        const renderProgressBar = () => {
            if(this.state.beginReprocess){
                return(
                    <>
                    <ProgressBarComponent
                        progressKey={this.state.progressKey}
                        endProgress={this.endReprocess}
                        successNoticeMessage="Reprocessamento concluído com sucesso!"
                        failNoticeMessage="Falha no reprocessamento. Tente novamente."
                    />
                    </>
                )
            }

        }

        return (

            <div className="bs-docs-section" >
                <Card title = "Reprocessar Livro Diário">
                    <div className="col-md-12">
                        
                        <div className = "row">
                            
                            <div className="col-md-2">
    
                                <CustomCalendar
                                    id="dataLancamentoFechamentoCaixa"
                                    htmlFor="icon"
                                    label="A partir de:"
                                    inputDateErrorClass={this.state.inputDateErrorClass}
                                    value={this.state.dataLancamentoView}
                                    name="dataLancamento"
                                    // style={{minWidth:'490px'}}
                                    onChange={this.handleDateChange}
                                    disabled={this.state.reprocessing}
                                    noHTMLFormat
                                    errorDateMessage={this.state.errorDateMessage}
                                />

                            </div>

                                </div>

                                <br />
                                
                                <div className="row">
                                <div className="col-md-4">
                                <Button 
                                    label="Reprocessar Lançamentos"
                                    icon="pi pi-refresh"
                                    onClick = {this.callReprocessLancamentos}
                                    disabled={this.state.reprocessing || this.state.disableButton}
                                />
                                </div>
                                </div>

                                <br />

                                {renderProgressBar()}

                                </div>
                </Card>
            <div className="d-flex "/>
        </div>    

        )

    }

}

ReprocessarLivroDiario.contextType = AuthContext

export default withRouter(ReprocessarLivroDiario)