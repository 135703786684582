import ConstantsUtil from "../../context/constantsUtil";

class NotaFiscalDTO {

    constructor
    (
        dataHora,
        fornecedor,
        numero,
        valorTotal,
        valorAcrescimoDesconto,
        valorDescontoPorBonificacao,
        valorDescontoPorDevolucao,
        tipo,
        modelo,
        transactions,
        provisao,
        complementoDeHistorico,
        emprestimo
    )
    {
        if(tipo === ConstantsUtil.entradaLabel){
            this.dataHoraEntrada = dataHora; //data de ENTRADA na empresa, informado pelo usuário durante a validação/lançamento manual da NF.
        }
        else{
            this.dataHoraSaida = dataHora; //data de SAIDA da empresa, informado pelo usuário durante o lançamento manual da NF.
        }

        this.fornecedor = fornecedor
        this.nomeFornecedorOuCliente = fornecedor.nome;
        this.numero = numero;
        this.valorTotal = valorTotal;
        this.valorAcrescimoDesconto = valorAcrescimoDesconto;
        this.valorDescontoPorBonificacao = valorDescontoPorBonificacao;
        this.valorDescontoPorDevolucao = valorDescontoPorDevolucao;
        this.tipo = tipo;
        this.modelo = modelo;
        this.transactions = transactions;
        this.tipoCriacao = 'MANUAL';
        this.provisao = provisao;
        this.complementoDeHistorico = complementoDeHistorico;
        this.fonte = ConstantsUtil.fonteNFUsuario
        this.sefaz = false
        this.emprestimo = emprestimo


    }


}

export default NotaFiscalDTO