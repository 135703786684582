import React from 'react'
import { Toast } from 'primereact/toast'
import { Button } from 'primereact/button'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import Highlighter from 'react-highlight-words'
import * as popUp from '../toastr'
import EditTipoDePagamentoDialog from './editTipoDePagamentoDialog'
import ContaContabilView from '../planoDeContas/contaContabilView'
import GeneralServices from '../../app/service/generalServices'
import TipoDePagamentoService from '../../app/service/contasAPagar/tipoDePagamentoService'
import HandleErrorService from '../../app/service/handleErrorService'
import { Dialog } from 'primereact/dialog'
import CustomHighlighter from '../customHighlighter'
import ConstantsUtil from '../../context/constantsUtil'
import { classNames } from 'primereact/utils'
import TooltipButton from '../tooltipButton'
import TableFilters from '../tableFilters'

class TipoDePagamentoTable extends React.Component {

    constructor(){
        super()
        this.dt = React.createRef()
        this.tipoDePagamentoService = new TipoDePagamentoService()
        this.tableFilters = new TableFilters()
    }

    state = {

        tipoDePagamentoToEdit: '',
        selectedTipoDePagamento: null,

        editTipoDePagamentoDialogVisible: false,
        forceUpdateEditTipoDePagamentoDialog: false,

        displayDeleteConfirmation: false,
        tipoDePagamentoToDelete: '',

        initialRowsQuantity: 30,
        
    }

    editTipoDePagamento = async (tipoDePagamento) => {
        
        await this.setState({tipoDePagamentoToEdit: JSON.parse(JSON.stringify(tipoDePagamento))})

        await this.setState({forceUpdateEditTipoDePagamentoDialog: true})

        this.setState({editTipoDePagamentoDialogVisible: true})
    }

    hideDialog = () => {
        this.setState({editTipoDePagamentoDialogVisible: false})
    }

    handleSelectionChange = (event) => {
        this.setState({selectedTipoDePagamento: event.value})
        this.props.handleSelectionTipoDePagamentoChange(event.value)
    }

    callDelete = (tipoDePagamentoToDelete) => {
        this.setState({tipoDePagamentoToDelete})
        this.setState({displayDeleteConfirmation: true})
    }

    hideDeleteDialog = () => {
        this.setState({tipoDePagamentoToDelete: null})
        this.setState({displayDeleteConfirmation: false})
    }

    delete = () => {
        this.tipoDePagamentoService.deleteTipoDePagamento(this.state.tipoDePagamentoToDelete)
        .then(async response => {
            popUp.successPopUp("Tipo de Pagamento inativado com sucesso!")
            this.props.search()
            this.hideDeleteDialog()
        }).catch(async error => {
            HandleErrorService.handleError(error)
        })
    }

    
    reativarTipoDePagamento = (tipoDePagamento) => {
        this.tipoDePagamentoService.reativarTipoDePagamento(tipoDePagamento)
        .then(async response => {
            popUp.successPopUp("Tipo de Pagamento reativado com sucesso!")
            this.props.search()
            // this.hideDeleteDialog()
        }).catch(async error => {
            HandleErrorService.handleError(error)
        })
    }

    render() {

        const actionBody = (tipoDePagamento) => {
            // console.log("tipoDePagamento: ", tipoDePagamento)
            if(
                !ConstantsUtil.tiposDePagamentoNotAllowedToEdit.includes(tipoDePagamento.nome)
                && tipoDePagamento.natureza !== ConstantsUtil.naturezaTipoDeRecebimentoAplicacaoFinanceiraLabel
            )
            {
                if(tipoDePagamento.ativo){
                    return (
                        <React.Fragment>
                            <TooltipButton
                                tooltip="Editar Tipo de Pagamento"
                                icon="pi pi-pencil"
                                onClick={() => this.editTipoDePagamento(tipoDePagamento)}
                            />

                            <TooltipButton
                                tooltip={"Inativar Tipo de Pagamento.\nEle continuará existindo no sistema e com os lançamentos passados matidos.\nNo entanto, este tipo de pagametno não mais aparecerá nas listagens para lançamentos."}
                                icon="pi pi-trash"
                                className={ "p-button-rounded p-mr-2 p-button-danger right-button " }
                                onClick={() => this.callDelete(tipoDePagamento)}
                            />
                        </React.Fragment>
                    );
                }
                else{
                    return (
                        <React.Fragment>
                            <TooltipButton
                                tooltip="Reativar Tipo de Pagamento"
                                icon="pi pi-undo"
                                onClick={() => this.reativarTipoDePagamento(tipoDePagamento)}
                            />
                        </React.Fragment> 
                    )
                }
            }
        }

        const renderSelectionColumn = () => {
            if(this.props.selectable){
                return(
                    <Column selectionMode="single" headerStyle={{ width: '3rem' }}></Column>
                )
            }
        }

       const renderEditColumn = () => {
            if(!this.props.selectable){
                return(
                    <Column header="Ações" body={actionBody} style ={ {width: '140px'} }></Column>
                )
            }
        }

        const renderContraPartida = (rowData) => {
            return(
                <ContaContabilView
                    contaContabil={rowData.contaContabil}
                />
            )
        }

        const renderDeleteConfirmationFooter = () => {
            return (
                <div>
                    <Button label="Confirmar" icon="pi pi-check"
                        // onClick={this.deleteLancamentoFechamentoBancario} 
                        onClick={this.delete} 
                        autoFocus
                    />
                    <Button label="Cancelar" icon="pi pi-times" onClick={this.hideDeleteDialog}
                            className="p-button-text" />
                </div>
            );
        }


        //Nome filter
        const nomeFilterElement =
        this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedNomes,
            this.props.list,
            "selectedNomes",
            (name, value) => this.setState({[name]: value}),
            'nome',
            '100%',
            "Nome"
        )

        //Natureza filter
        const naturezaFilterElement =
        this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedNaturezas,
            this.props.list,
            "selectedNaturezas",
            (name, value) => this.setState({[name]: value}),
            'natureza',
            '100%',
            "Natureza"
        )

        //Fornecedor filter
        const fornecedorFilterElement =
        this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedFornecedores,
            this.props.list,
            "selectedFornecedores",
            (name, value) => this.setState({[name]: value}),
            'fornecedor.nome',
            '100%',
            "Fornecedor"
        )

        //Grupo filter
        const grupoFilterElement =
        this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedGrupos,
            this.props.list,
            "selectedGrupos",
            (name, value) => this.setState({[name]: value}),
            'grupoDePagamento.nome',
            '100%',
            "Grupo"
        )

        //Situação filter
        const situacaoFilterElement =
        this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedSituacao,
            this.props.list,
            "selectedSituacao",
            (name, value) => this.setState({[name]: value}),
            'situacaoLabel',
            '100%',
            "Situação"
        )

        return (
            <div className="datatable-crud-demo">
            <Toast ref={this.toast} />

            <div className="card">
                {/* <Toolbar className="p-mb-4" left={leftToolbarTemplate} 
                // right={rightToolbarTemplate}
                /> */}

                <DataTable
                    ref={this.dt}
                    value={this.props.list}
                    className="p-datatable-sm small-font"
                    rowHover
                    emptyMessage={ this.props.emptyMessage ? this.props.emptyMessage : "Não há tipos de pagamento cadastrados."}
                    selection={this.state.selectedTipoDePagamento}
                    onSelectionChange={this.handleSelectionChange}
                    // scrollable
                    // scrollHeight="500px"
                    loading={this.props.loading}
                    dataKey="id"
                    paginator rows={this.state.initialRowsQuantity}
                    paginatorTemplate={GeneralServices.tablePaginatorTemplate(this.state.initialRowsQuantity, this.props.list ? this.props.list.length : '')}
                    currentPageReportTemplate="Mostrando de {first} ao {last} de {totalRecords} tipos de pagamento"
                    >

                    {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column> */}

                    {renderSelectionColumn()}

                    <Column field="id" header="N° de Chamada" sortable style ={ {width: '140px'} }
                        body={rowData => 
                            <CustomHighlighter
                                searchWords={[this.props.searchText]}
                                textToHighlight={ rowData.id ? (rowData.id*5).toString() : "-"}
                            />
                        }    
                    />

                    <Column field="nome" header="Nome" sortable
                        filter filterElement={nomeFilterElement}
                        body={rowData => 
                            <CustomHighlighter
                                searchWords={[this.props.searchText]}
                                textToHighlight={rowData.nome}
                            />
                        }
                    />

                    <Column field="natureza" header="Natureza" sortable
                        filter filterElement={naturezaFilterElement}
                    />

                    <Column field="fornecedor.nome" header="Fornecedor" sortable
                        filter filterElement={fornecedorFilterElement}
                    />

                    {/* {renderColumnList()} */}

                    <Column field="contaContabil" header="Conta Contábil"
                        // style ={ {width: '200px'} }
                        body = { renderContraPartida }
                    />

                    <Column field="grupoDePagamento.nome" header="Grupo De Pagamento" sortable
                        filter filterElement={grupoFilterElement}
                    />

                    <Column field="situacaoLabel" header="Situação" sortable
                        body={GeneralServices.renderStyledSituacaoTableColumn}
                        filter filterElement={situacaoFilterElement}
                    />

                    {renderEditColumn()}

                </DataTable>
            </div>

            <EditTipoDePagamentoDialog
                hideDialog={this.hideDialog}
                state={this.state.tipoDePagamentoToEdit}
                visible={this.state.editTipoDePagamentoDialogVisible}
                header="Editar Tipo De Pagamento"
                forceUpdate={this.state.forceUpdateEditTipoDePagamentoDialog}
                doneForceUpdate={() => this.setState({forceUpdateEditTipoDePagamentoDialog: false})}
                // dialogType={ConstantsUtil.tipoDialogGrupoDePagamentoEditLabel}
                search={this.props.search}
                push={this.props.push}
            />

            <Dialog
                header="Deletar Tipo de Pagamento"
                visible={this.state.displayDeleteConfirmation}
                modal = {true} //congela restante da tela
                style={{ width: '350px' }}
                footer={renderDeleteConfirmationFooter()}
                onHide={this.hideDeleteDialog}
                >
                <div className="confirmation-content row" style={{marginLeft: '10px'}}>
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem', marginRight: '10px'}} />
                <div style={{marginBottom: '10px'}}> Deseja confirmar? </div>
                </div>
            </Dialog>

            </div>
        )
    }

}

export default TipoDePagamentoTable