import React from 'react'
import { withRouter } from 'react-router-dom'
import Card from '../../../components/card'
import { AuthContext } from '../../../main/authProvider'
import HistoricoImportacaoNFsTable from '../../../components/relatorios/estoque/historicoImportacaoNFsTable'

class HistoricoImportacaoNFs extends React.Component {

    constructor(){
        super()

    }

    render(){
        return (
            <div className="bs-docs-section" >
            <Card title = "Histórico de Importação de NFs">
                <div className = "col-md-12">
                    
                    <HistoricoImportacaoNFsTable
                    />

                </div>
            </Card>
            <div className="d-flex "/>
        </div>     
        )
    }

}

HistoricoImportacaoNFs.contextType = AuthContext

export default withRouter(HistoricoImportacaoNFs)