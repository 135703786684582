import React from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import GeneralServices from '../../app/service/generalServices'
import LancarFechamentoBancarioService from '../../app/service/conciliacaoBancaria/lancarFechamentoBancarioService'
import ConstantsUtil from '../../context/constantsUtil'
import { classNames } from 'primereact/utils'
import { Dialog } from 'primereact/dialog'
import FichaDeRecebimentoTable from './fichaDeRecebimentoTable'
import HandleErrorService from '../../app/service/handleErrorService'
import FichaDeRecebimentoService from '../../app/service/contasAReceber/fichaDeRecebimentoService'


class FichaDeRecebimentoConsolidadaTable extends React.Component {
    
    constructor() {
        super();
        this.toast = React.createRef();
        this.dt = React.createRef();
        this.lancarFechamentoBancarioService = new LancarFechamentoBancarioService();
        this.fichaDeRecebimentoService = new FichaDeRecebimentoService()
    
    }

    state = {
        
        fichaDeRecebimentoToView: {},
        displayFichaBancariaIndividualDialog: false,
        loading: false,

    }

    componentDidMount(){
        // console.log("componentDidMount FichaDeRecebimentoConsolidadaTable")
        this.setProps()
    }

    setProps = () => {
        // console.log('setProps: ', this.props.fichaDeRecebimento, this.props.fichaDeRecebimento.doAutoSearch)
        // console.log("this.props.fichaDeRecebimento inicial: ", this.props.fichaDeRecebimento)
        if(this.props.fichaDeRecebimento && this.props.fichaDeRecebimento.doAutoSearch) {
            this.doAutoSearch()
        }
    }
    
    doAutoSearch = () => {
        // console.log("doAutoSearch: ", this.props.specificEmpresaId)
        this.setState({loading: true})

        let extraHeaderObj= null
        if(this.props.specificEmpresaId) {
            extraHeaderObj = {specificEmpresaId: this.props.specificEmpresaId}
        }

        this.fichaDeRecebimentoService.getFichaConsolidadaByDateInterval(
            {
                initialLancamentoDate : this.props.fichaDeRecebimento.initialDate,
                finalLancamentoDate : this.props.fichaDeRecebimento.finalDate,
                dateFilterType : this.props.fichaDeRecebimento.dateFilterType,
                sortType: this.props.sortType ? this.props.sortType : ''
            },
            extraHeaderObj
        )
        .then(response => {
            if(this.props.updateProps){
                this.props.updateProps(response.data)
            }
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
        .finally(() => {
            this.setState({loading: false})
        })
    }        

    beginLoadingFichaDeRecebimentoTable = () => {
        this.setState({loading: true})
    }

    endLoadingFichaDeRecebimentoTable = () => {
        this.setState({loading: false})
    }

    getTableHeaders = () => {
        // console.log("getTableHeaders")
        const periodo = this.props.fichaDeRecebimento && this.props.fichaDeRecebimento.initialDate ? this.props.fichaDeRecebimento.initialDate + " - " + this.props.fichaDeRecebimento.finalDate : ""
        const periodoLabel = "Período: " + periodo

        const dateFilterType = this.props.fichaDeRecebimento && this.props.fichaDeRecebimento.dateFilterType ? this.props.fichaDeRecebimento.dateFilterType : ''
        const dateFilterTypeLabel = "Filtrado por: " + dateFilterType

        return {dateFilterType: dateFilterTypeLabel, periodo: periodoLabel}
    }

    rowClass(rowData) {
        return {
            [ConstantsUtil.totaisTableLabel]: rowData.nomeTipoDeRecebimento === ConstantsUtil.tipoTotaisFichaDeRecebimentoLabel
        }
    }

    viewFichaDeRecebimentoIndividual = rowData => {
        // console.log("viewFichaDeRecebimentoIndividual: ", rowData)
        // console.log("this.props.fichaDeRecebimento: ", this.props.fichaDeRecebimento)
        // console.log("this.state: ", this.state)

        let fichaDeRecebimentoToView = JSON.parse(JSON.stringify(this.props.fichaDeRecebimento))
        fichaDeRecebimentoToView.tipoDeRecebimento = {id: rowData.id, nomeTipoDeRecebimento: rowData.nomeTipoDeRecebimento}
        this.setState({fichaDeRecebimentoToView})
        this.setState({displayFichaBancariaIndividualDialog: true})
        this.setState({forceUpdateDialog: true})
    }

    hideDialog = () => {
        this.setState({fichaDeRecebimentoToView: {}})
        this.setState({displayFichaBancariaIndividualDialog: false})        
    }


    render (){

        const renderNomeTipoDeRecebimentoLink = (rowData) => {
            const classes = classNames({
                'linkValue': true
                
            });
            return(
                <a
                    className={classes}
                    // href='/DRE'
                    onClick={() => this.viewFichaDeRecebimentoIndividual(rowData)}
                >
                    {rowData.nomeTipoDeRecebimento}
                </a>
            )            
        }

        const renderFichaBancariaIndividualDialog = () => {
            return (
                <Dialog
                    visible={this.state.displayFichaBancariaIndividualDialog}
                    style={{ width: '95vw' }}
                    header={`Ficha de Recebimento Individual (${this.state.fichaDeRecebimentoToView.tipoDeRecebimento ? this.state.fichaDeRecebimentoToView.tipoDeRecebimento.nomeTipoDeRecebimento : ""})`}
                    modal
                    className="p-fluid"
                    // footer={chooseContaBancariaDialogFooter}
                    onHide={this.hideDialog}
                >

                <FichaDeRecebimentoTable
                    fichaDeRecebimento={this.state.fichaDeRecebimentoToView}
                    doAutoSearch
                    insideDialog
                />

                </Dialog>
            )
        }

        return (
            <>
            <div className = "card-header">
            <div className='col-md-12'>
                <div className='row'>
                    <div className='col-md-4'>
                        <h5 className='medium-font-size'>{this.getTableHeaders().dateFilterType}</h5>
                    </div>
                    <div className='col-md-4'>
                        <h5 className='medium-font-size'>{this.getTableHeaders().periodo}</h5>
                    </div>
                </div>
                
            </div>
            </div>
            
            <div className="orders-subtable">
                <DataTable
                    ref={this.dt}
                    value={this.props.fichaDeRecebimento ? this.props.fichaDeRecebimento.lancamentos : []}
                    className="p-datatable-sm small-font"
                    loading={this.props.loading || this.state.loading}
                    rowClassName={this.rowClass}
                    showGridlines
                    rowHover
                    scrollable
                    scrollHeight={this.props.insideDialog ? "50vh" : "500px"}
                    emptyMessage={ConstantsUtil.nenhumLancamentoEncontradoMessage}
                    dataKey="id"
                    // paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                    // paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    // currentPageReportTemplate="Mostrando de {first} ao {last} de {totalRecords} movimentações"
                >

                    <Column
                        header="Tipo de Recebimento"
                        field="nomeTipoDeRecebimento"
                        style ={ {width: '140px'} }
                        body={renderNomeTipoDeRecebimentoLink}
                    />

                    <Column 
                        header="Bruto"
                        field="valorBruto"
                        body = {rowData => GeneralServices.showFormattedIfNonZero(rowData.valorBruto, true) }
                        style ={ {width: '100px'} }
                    />

                    <Column
                        header="Comissão"
                        field="comissao" 
                        body = {rowData => GeneralServices.showFormattedIfNonZero(rowData.comissao, true) }
                        style ={ {width: '100px'} }
                    />

                    <Column 
                        header="Líquido"
                        field="valorLiquido" 
                        body = {rowData => GeneralServices.showFormattedIfNonZero(rowData.valorLiquido, true) }
                        style ={ {width: '100px'} }
                    />

                    <Column
                        header="Crédito"
                        field="credito" 
                        body = {rowData => GeneralServices.showFormattedIfNonZero(rowData.credito, true) }
                        style ={ {width: '100px'} }
                    />

                    <Column
                        header="Ajuste"
                        field="ajuste"
                        body = {rowData =>
                            GeneralServices.showFormattedIfNonZero(rowData.ajuste, true) 
                        }
                        style ={ {width: '100px'} }
                    />

                    <Column
                        header="Saldo"
                        field="saldo"
                        body = {rowData => GeneralServices.showFormattedIfNotNull(rowData.saldo, true) }
                        style ={ {width: '100px'} } 
                    />

                </DataTable>
            </div>

            {renderFichaBancariaIndividualDialog()}

            </>
        )
    }


}  

export default FichaDeRecebimentoConsolidadaTable