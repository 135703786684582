import React from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { ColumnGroup } from 'primereact/columngroup'
import { Row } from 'primereact/row'
import GeneralServices from '../../app/service/generalServices'
import ConstantsUtil from '../../context/constantsUtil'
import { Button } from 'primereact/button'
import ConfirmationDialog from '../../components/confirmationDialog'
import FichaDeEstoqueService from '../../app/service/relatorios/fichaDeEstoqueService'
import HandleErrorService from '../../app/service/handleErrorService'
import * as popUp from '../../components/toastr'
import UpdateStockDialog from '../../components/product/updateStockDialog'
import { classNames } from 'primereact/utils'
import CentralService from '../../app/service/central/centralService'
import DetalhamentoMovimentacaoProductDialog from '../../components/product/detalhamentoMovimentacaoProductDialog'

class FichaDeEstoqueTable extends React.Component {

    state = {
        visibleConfirmDeleteDialog: false,
        fichaDeEstoqueToDelete: '',

        visibleEditDialog: false,
        fichaDeEstoqueToEdit: '',

        loading: false,

        displayDetalhamentoDialog: false,
        fichaDeEstoqueDetalhamento: null,
        tipoDetalhamento: '',
    
    }

    constructor(){
        super();
        this.fichaDeEstoqueService = new FichaDeEstoqueService();
        this.centralService = new CentralService();
    }

    getTableHeaders = () => {
        //Para não mostrar "undefined" no header enquanto o backend está processando a requisição
        var label = "Produto: "
        var descricao = this.props.fichaDeEstoqueOutputListDTO && this.props.fichaDeEstoqueOutputListDTO.product && this.props.fichaDeEstoqueOutputListDTO.product.descricao ? this.props.fichaDeEstoqueOutputListDTO.product.descricao : ""

        const productLabel =  label + descricao
        
        const periodo = this.props.fichaDeEstoqueOutputListDTO && this.props.fichaDeEstoqueOutputListDTO.initialDate ? this.props.fichaDeEstoqueOutputListDTO.initialDate + " - " + this.props.fichaDeEstoqueOutputListDTO.finalDate : ""
        const periodoLabel = "Período: " + periodo

        return {product: productLabel, periodo: periodoLabel}
    }


    rowClass(rowData) {
        const obj = {
            [ConstantsUtil.ajusteFichaDeEstoqueSobraClass] : rowData.tipoAtualizacao.includes("AJUSTE_SOBRA"),
            [ConstantsUtil.ajusteFichaDeEstoquePerdaClass] : rowData.tipoAtualizacao.includes("AJUSTE_PERDA")
        }
        return obj
    }

    callEdit = (rowData) => {
        // console.log("rowData: ", rowData)
        this.setState({visibleEditDialog: true})
        this.setState({fichaDeEstoqueToEdit: rowData})
    }

    confirmEdit = (stockInfo) => {

        this.setState({loading: true})
        
        const fichaDeEstoqueToEdit = JSON.parse(JSON.stringify(this.state.fichaDeEstoqueToEdit))

        this.hideEditDialog()

        this.fichaDeEstoqueService.update(
            {
                originalFichaDeEstoqueToBeEditedId: fichaDeEstoqueToEdit.id,
                newStockInfo: stockInfo
            }
        )
        .then(response => {
            popUp.successPopUp("Lançamento Manual alterado com sucesso!")
            this.setState({fichaDeEstoqueToEdit: ''})
            this.props.search(true)
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
        .finally(() => {
            this.setState({loading: false})
        })
    }

    hideEditDialog = () => {
        this.setState({visibleEditDialog: false})
        this.setState({fichaDeEstoqueToEdit: ''})
    }

    callDelete = (rowData) => {
        this.setState({visibleConfirmDeleteDialog: true})
        this.setState({fichaDeEstoqueToDelete: rowData})
    }

    hideConfirmDeleteDialog = () => {
        this.setState({visibleConfirmDeleteDialog: false})
        this.setState({fichaDeEstoqueToDelete: ''})
    }

    confirmDelete = () => {
        this.hideConfirmDeleteDialog()
        this.setState({loading: true})
        this.fichaDeEstoqueService.delete(this.state.fichaDeEstoqueToDelete)
        .then(response => {
            popUp.successPopUp("Lançamento Manual deletado com sucesso!")
            this.props.search(true)
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
        .finally(() => {
            this.setState({loading: false})
        })
    }

    viewDetalhamentoQuantidade = async (rowData, tipo) => {
        
        this.setState({displayDetalhamentoDialog: true})
        this.setState({fichaDeEstoqueDetalhamento: rowData})
        this.setState({tipoDetalhamento: tipo})
        
    }


    hideDetalhamentoDialog = () => {
        this.setState({displayDetalhamentoDialog: false})
        this.setState({fichaDeEstoqueDetalhamento: null})
        this.setState({tipoDetalhamento: ''})
    }


    getList = () => {
        return this.props.fichaDeEstoqueOutputListDTO ? this.props.fichaDeEstoqueOutputListDTO.fichaDeEstoqueList : []
    }

    getListToExport = () => {
        // console.log("getListToExport: ", this.getList())
        return this.getList().map(item => {
            return {
                ['Data']: GeneralServices.formatDateFromFichaDeEstoque(item.data),
                ['Qtd Entrada']: item.quantidadeEntrada,
                ['Vr Unit Entrada']: item.valorUnitarioEntrada,
                ['Qtd Saída']: item.quantidadeSaida,
                ['Vr Unit Saída']: item.valorUnitarioSaida,
                ['Qtd Saldo']: item.quantidadeSaldo,
                ['Vr Total Saldo']: item.valorTotalSaldo,
                ['Dif Vr Total']: GeneralServices.formatTo2CasasDecimais(item.diferencaValorTotalSaldoAjuste),
                ['Tipo de Atualização']: GeneralServices.formataTipoDeAtualizacaoFichaDeEstoque(item)

            }
        })
    }

    getFilenameToExport = () => {
        return `Ficha_de_estoque_${this.props.fichaDeEstoqueOutputListDTO.product.descricao}_de_${this.props.fichaDeEstoqueOutputListDTO.initialDate}_a_${this.props.fichaDeEstoqueOutputListDTO.finalDate}`
    }

    exportXLSX = () => {
        // console.log("initialDate: ", this.props.initialDate)
        
        let exportList = this.getListToExport()
        GeneralServices.exportExcel(exportList, "Ficha de Estoque", this.getFilenameToExport())

    }

    exportPDF = () => {
        let columns = [
            {field: ['Data'], header: 'Data'},
            {field: ['Qtd Entrada'], header: 'Qtd Entrada'},
            {field: ['Vr Unit Entrada'], header: 'Vr Unit Entrada'},
            {field: ['Qtd Saída'], header: 'Qtd Saída'},
            {field: ['Vr Unit Saída'], header: 'Vr Unit Saída'},
            {field: ['Qtd Saldo'], header: 'Qtd Saldo'},
            {field: ['Vr Total Saldo'], header: 'Vr Total Saldo'},
            {field: ['Dif Vr Total'], header: 'Dif Vr Total'},
            {field: ['Tipo de Atualização'], header: 'Tipo de Atualização'},
        ]
        let exportColumns = columns.map(col => ({ title: col.header, dataKey: col.field }))

        let exportList = this.getListToExport()
        GeneralServices.exportPdf(this.getFilenameToExport(), exportColumns, exportList)
    }

    render(){

        const renderProductHeader = () => {
            return (
            <div className='col-md-4'>
                <h5 className='medium-font-size'>{this.getTableHeaders().product}</h5>            
            </div>
            )
        }

        const headerTemplate = label => {
            return (
                <div className='centraliza-conteudo-div'>{label}</div>
            )
        }

        let headerGroup =
        <ColumnGroup>
            <Row>
                <Column header={headerTemplate("Data")} rowSpan={2} field="data"/>
                <Column
                    header={headerTemplate("Entrada")}
                    colSpan={2}
                />
                <Column header={headerTemplate("Saída")} colSpan={2} />
                <Column header={headerTemplate("Saldo")} colSpan={3} />
                <Column header={headerTemplate("Tipo de Atualização")} rowSpan={2} />
                <Column header={headerTemplate("Ações")} rowSpan={2} />
            </Row>
            <Row>
                <Column header="Qtd" field="quantidadeEntrada" />
                <Column header="Vr Unit" field="valUnitEntrada"/>
                <Column header="Qtd" field="quantidadeSaida" />
                <Column header="Vr Unit" field="valUnitSaida"/>
                <Column header="Qtd" field="quantidadeSaldo" />
                <Column header="Vr Total" field="valorTotalSaldo"/>
                <Column header="Dif Vr Total" field="diferencaValorTotalSaldoAjuste"/>
            </Row>
        </ColumnGroup>

        
        const renderStyledTipo = (rowData) => {

            const classes = classNames({
                // 'superavitValue': tipo >= 0,
                'deficitValue': rowData.tipoAtualizacao === ConstantsUtil.tipoFichaDeEstoqueDevolucaoLabel
            });

            return (
                <div className={classes}>
                    {GeneralServices.formataTipoDeAtualizacaoFichaDeEstoque(rowData)}
                </div>
            );
        }

        const renderViewQuantidadeLink = (rowData, tipo) => {
            // console.log("renderViewQuantidadeLink rowData: ", rowData)
            // console.log("renderViewQuantidadeLink tipo: ", tipo)
            let quantidade = tipo === ConstantsUtil.entradaLabel ? rowData.quantidadeEntrada : rowData.quantidadeSaida
            // const cond1 = rowData.fichaBancariaUnificada.fichaBancariaList && rowData.fichaBancariaUnificada.fichaBancariaList.length > 0
            const cond1 = quantidade && quantidade !== 0
            const cond2 = !rowData.tipoAtualizacao.includes("AJUSTE")
            if( cond1 && cond2 ){
                const classes = classNames({
                    // 'superavitValue': valorNaoFormatado >= 0,
                    'deficitValue': quantidade < 0,
                    'linkValue': true
                    
                });
                return(
                    <a 
                        className={classes}
                        onClick={() => this.viewDetalhamentoQuantidade(rowData, tipo)}
                    >
                        {quantidade}
                    </a>
                )
            }
            else{
                return(
                    <>
                        {GeneralServices.showFormattedIfNonZero(quantidade, false)}
                    </>
                )
            }
        }

        const actionBody = (rowData) => {
            if(rowData.lastLancamentoMaual){
                return (
                    <React.Fragment>
                        <Button
                            tooltip="Editar lançamento manual"
                            tooltipOptions={{position: 'top'}}
                            className={ "p-button-rounded p-mr-2 " }
                            style={ {maxHeight: '25px', maxWidth: '25px'} }
                            icon={<i className="pi pi-pencil" style={{fontSize: '0.9em'}}></i>}
                            onClick={() => this.callEdit(rowData)}
                        />
                        <Button
                            tooltip="Deletar lançamento manual"
                            tooltipOptions={{position: 'top'}}
                            className={ "p-button-rounded p-mr-2 p-button-danger" }
                            style={ {maxHeight: '25px', maxWidth: '25px', marginLeft: '5px'} }
                            icon={<i className="pi pi-trash" style={{fontSize: '0.9em'}}></i>}
                            onClick={() => this.callDelete(rowData)}
                        />
                    </React.Fragment>
                );
            }
        }

        const renderDetalhamentoDialog = () => {
            if(this.state.displayDetalhamentoDialog)
                return (
                    <DetalhamentoMovimentacaoProductDialog
                        visible={this.state.displayDetalhamentoDialog}
                        tipo={this.state.tipoDetalhamento}
                        product={this.state.fichaDeEstoqueDetalhamento.product}
                        dataInicial={GeneralServices.formatDateFromFichaDeEstoque(this.state.fichaDeEstoqueDetalhamento.data)}
                        dataFinal={GeneralServices.formatDateFromFichaDeEstoque(this.state.fichaDeEstoqueDetalhamento.data)}
                        fromFichaDeEstoque={true}
                        tipoAtualizacaoFichaDeEstoque={this.state.fichaDeEstoqueDetalhamento.tipoAtualizacao}
                        unificar={this.props.unificar}
                        hideDialog={this.hideDetalhamentoDialog}
                    />
                )
        }


        const exportHeader = () => {
            return (
                <React.Fragment>
                    
                    <Button
                        label="Exportar"
                        icon="pi pi-file-excel"
                        className="p-button-success small-font small-button"
                        onClick={this.exportXLSX}
                        tooltip={ConstantsUtil.exportXlsxLabel}
                        tooltipOptions={{position: 'top'}}
                    />

                    <Button
                        label="Exportar"
                        icon="pi pi-file-pdf"
                        className="p-button-danger small-font small-button"
                        onClick={this.exportPDF}
                        style={{marginLeft: '10px'}}
                        tooltip={ConstantsUtil.exportPdfLabel}
                        tooltipOptions={{position: 'top'}}
                    />
                </React.Fragment>
            )
        }

        return (
            <>
                <div className = "card-header">
                <div className='col-md-12'>
                    <div className='row'>

                        {renderProductHeader()}

                        <div className='col-md-4'>
                            <h5 className='medium-font-size'>{this.getTableHeaders().periodo}</h5>
                        </div>

                        {exportHeader()}

                    </div>
                    
                </div>
                </div>
                <DataTable 
                    ref={this.dt}
                    // value={GeneralServices.calculateBalance(product.stockSheet)}
                    value={this.getList()}
                    headerColumnGroup={headerGroup}
                    className="p-datatable-sm small-font"
                    rowClassName={this.rowClass}
                    rowHover
                    showGridlines
                    scrollable
                    scrollHeight="500px"
                    emptyMessage={ConstantsUtil.nenhumLancamentoEncontradoMessage}
                    loading={this.props.loading || this.state.loading}
                    dataKey="id"
                    paginator rows={ConstantsUtil.initialTableQuantityOption}
                    paginatorTemplate={GeneralServices.tablePaginatorTemplate(ConstantsUtil.initialTableQuantityOption, this.getList().length)}
                    currentPageReportTemplate="Mostrando de {first} ao {last} de {totalRecords} lançamentos"
                >

                <Column field="data"
                    body={rowData => GeneralServices.formatDateFromFichaDeEstoque(rowData.data)}
                    sortable style ={ {width: '140px'} }
                // filter filterElement={codeFilterElement}
                />

                <Column field="quantidadeEntrada" 
                        sortable style ={ {width: '80px'} }
                        // body={rowData => GeneralServices.showFormattedIfNonZero(rowData.quantidadeEntrada, false)}
                        body={rowData => {
                            return renderViewQuantidadeLink(rowData, ConstantsUtil.entradaLabel)
                        }}
                />

                <Column field="valUnitEntrada" 
                        body={rowData => GeneralServices.showFormattedIfNonZero(rowData.valorUnitarioEntrada, true)} 
                        sortable style ={ {width: '100px'} }
                />

                <Column field="quantidadeSaida" 
                        sortable style ={ {width: '80px'} }
                        // body={rowData => GeneralServices.showFormattedIfNonZero(rowData.quantidadeSaida, false)}
                        body={rowData => {
                            return renderViewQuantidadeLink(rowData, ConstantsUtil.saidaLabel)
                        }}
                />

                <Column field="valUnitSaida" 
                        body={rowData => GeneralServices.showFormattedIfNonZero(rowData.valorUnitarioSaida, true)}
                        sortable style ={ {width: '100px'} }
                />

                <Column field="quantidadeSaldo" 
                        body={rowData => GeneralServices.showFormattedIfNotNull(rowData.quantidadeSaldo, false)}
                        sortable style ={ {width: '80px'} }
                />

                <Column field="valorTotalSaldo"
                        body={rowData => GeneralServices.showFormattedIfNotNull(rowData.valorTotalSaldo, true)}
                        sortable style ={ {width: '100px'} }
                />

                <Column field="diferencaValorTotalSaldoAjuste"
                        body={rowData => GeneralServices.showFormattedIfNotNull(rowData.diferencaValorTotalSaldoAjuste, true)}
                        sortable style ={ {width: '100px'} }
                />

                <Column field="tipoAtualizacao" header="Tipo de Atualização" sortable style ={ {width: '450px'} } 
                // filter filterElement={cfopFilterElement}
                    body = {rowData => renderStyledTipo(rowData)}
                />

                <Column body={actionBody} style ={ {width: '80px'} }
                />

            </DataTable>

            <ConfirmationDialog
                header={"Deletar Lançamento Manual"}
                confimationMessage={ "Confirmar exclusão?"}
                visible={this.state.visibleConfirmDeleteDialog}
                confirm={this.confirmDelete}
                hide={this.hideConfirmDeleteDialog}
            />

            <UpdateStockDialog
                header="Atualizar Estoque"
                visible={this.state.visibleEditDialog}
                productId={this.state.fichaDeEstoqueToEdit ? this.state.fichaDeEstoqueToEdit.product.id : ''}
                productUuid={this.state.fichaDeEstoqueToEdit ? this.state.fichaDeEstoqueToEdit.product.uuid : ''}
                fichaDeEstoqueToEdit={this.state.fichaDeEstoqueToEdit}
                save={this.confirmEdit}
                hideDialog={this.hideEditDialog}
            />

            {renderDetalhamentoDialog()}

        </>
        )
    }

}


export default FichaDeEstoqueTable