import React from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import TipoProdutoService from '../../../app/service/tipoProdutoService'
import HandleErrorService from '../../../app/service/handleErrorService'
import GeneralServices from '../../../app/service/generalServices'
import CustomHighlighter from '../../customHighlighter'
import TooltipButton from '../../tooltipButton'
import CreateOrUpdateTipoProdutoDialog from './createOrUpdateTipoProdutoDialog'

class TipoProdutoTable extends React.Component {

    constructor(){
        super()
        this.tipoProdutoService = new TipoProdutoService()
    }

    state = {
        list: [],
        filteredList: [],
        selectedItem: {},
        tipoProdutoToEdit: '',
        displayEditDialog: false,
        loading: false,

    }


    componentDidMount(){
        this.search()
    }

    search = () => {
        this.setState({loading: true})
        this.tipoProdutoService.search()
        .then(response => {
            this.setState({list: response.data})
            this.setState({filteredList: response.data})
        })
        .catch(error => {
            HandleErrorService.handleChange(error)
        })
        .finally(() => {
            this.setState({loading: false})
        })
    }  

    filter  = () => {
        
        this.setState({
            filteredList:
                JSON.parse(
                    JSON.stringify(
                        this.state.list.filter(tipoProduto => {
                            return(
                                GeneralServices.includeString(tipoProduto.descricao, this.props.searchText)
                            )
                        }
                        )
                    )
                )
        })
    }

    edit = (tipoProduto) => {
        this.setState({tipoProdutoToEdit: JSON.parse(JSON.stringify(tipoProduto))})
        this.setState({displayEditDialog: true})
    }

    isEditDialogOpen = () => {
        return this.state.displayEditDialog
    }

    hideDialog = () => {
        this.setState({displayEditDialog: false})
    }

    handleSelectionChange = (event) => {
        this.setState({selectedItem: event.value})
        this.props.handleSelectionChange(event.value)
    }    

    render() {

        const actionBody = (selectedTipoDeProduto) => {
            if(!selectedTipoDeProduto.fixoSistema)
            return (
                <React.Fragment>
                    <TooltipButton
                        tooltip = "Editar Tipo de Produto"
                        icon={<i className="pi pi-pencil" style={{fontSize: '1em'}}></i>}
                        onClick={() => this.edit(selectedTipoDeProduto)}
                        style={ {maxHeight: '25px', maxWidth: '25px'} }
                    />
                </React.Fragment>
            );
        }

        return (
            <div className="">
            <div className="card">

                <DataTable
                    // ref={this.dt}
                    value={this.state.filteredList}
                    className="p-datatable-sm small-font"
                    rowHover
                    emptyMessage="Não há Tipo de Produto Cadastrado"
                    selection={this.state.selectedItem}
                    onSelectionChange={this.handleSelectionChange}
                    // scrollable
                    // scrollHeight="500px"
                    loading={this.state.loading}
                    dataKey="id"
                    // paginator rows={25} rowsPerPageOptions={[5, 10, 25]}
                    // paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    // currentPageReportTemplate="Mostrando de {first} ao {last} de {totalRecords} contas bancárias"
                >

                    <Column selectionMode="single" headerStyle={{ width: '3rem' }}
                    />

                    <Column field="descricao" header="Descrição" sortable
                        body={rowData => 
                            <CustomHighlighter
                                searchWords={[this.props.searchText]}
                                textToHighlight={rowData.descricao}
                            />
                        }                    
                    />

                    <Column field="fixoSistema" header="Fixo do Sistema" sortable
                        body={rowData => rowData.fixoSistema ? 'Sim' : 'Não'}
                    />

                    <Column header="Editar" body={actionBody} style ={ {width: '140px'} }
                    />

                </DataTable>
            </div>

            <CreateOrUpdateTipoProdutoDialog
                visible={this.state.displayEditDialog}
                isEdit
                hideDialog={this.hideDialog}
                tipoDeProduto={this.state.tipoProdutoToEdit}
                search={this.search}
            />
            </div>
        )
    }
}

export default TipoProdutoTable