import React from 'react'
import ContaBancariaService from '../../app/service/conciliacaoBancaria/contaBancariaService'
import HandleErrorService from '../../app/service/handleErrorService'
import ChooseContaBancariaDialog from './chooseContaBancariaDialog'

class InputChooseContaBancaria extends React.Component {

    constructor(){
        super()
        this.contaBancariaService = new ContaBancariaService()
    }

    state = {

        contasBancariasList: [],
        chooseContaBancariaDialogVisible: false,
        loadingContaBancariaTable: false,
        forceUpdate: false,
        
    }

    componentDidMount(){
        this.searchContasBancarias()
    }

    hideContaBancariaDialog = () => {
        this.setState({chooseContaBancariaDialogVisible: false})
        
        if(this.props.doNotForceContaBancariaDialog)
            this.props.doNotForceContaBancariaDialog()
    }

    handleChange = (value) => {
        const conta = value
        var label = ''
        if(conta){
            if(conta.numAgencia){
                label = conta.nomeBanco + " - Ag: " + conta.numAgencia + " - Conta: " + conta.numConta + "-" + conta.numDigitoConta
            }
            else{
                label = conta.nomeBanco
            }
        }
        this.props.handleSelectionContaBancariaChange(conta, label)
    }

    searchContasBancarias = () => {
        
        this.setState({loadingContaBancariaTable: true})

        const excludeFundosFixos = this.props.excludeFundosFixos ? this.props.excludeFundosFixos : false
        const excludeAplicacaoFinanceira = this.props.excludeAplicacaoFinanceira ? this.props.excludeAplicacaoFinanceira : false
        this.contaBancariaService.search(excludeFundosFixos, excludeAplicacaoFinanceira)
        .then(async response => {
            await this.setState({contasBancariasList: response.data})
            this.checkById()
            if(this.props.autofocus){
                this.inputClick()
            }
            this.setState({forceUpdate: true})
        }).catch(error => {
            HandleErrorService.handleError(error)
        })
        .finally(() => {
            this.setState({loadingContaBancariaTable: false})
        })
    }

    checkById = () => {
        if(this.props.searchContaBancariaById){
            const id = this.props.contaBancariaId
            const conta = this.state.contasBancariasList.find(conta => conta.id === id)
            this.handleChange(conta)
        }
    }

    handleKeyPress = async (e) => {
        if (e.key === "Enter") {
            this.inputClick()
          }
    }

    inputClick = () => {
        this.setState({chooseContaBancariaDialogVisible: true})
    }

    render(){

        const renderInput = () => {
            return (
                <>
                <label htmlFor="contaBancaria">{this.props.label ? this.props.label : 'Conta Bancária'}</label>
                    <input style={{color: '#000'}} type="text"
                        className={"form-control " + this.props.inputContaBancariaErrorClass}
                        readOnly
                        value = {this.props.selectedContaBancariaLabel}
                        name="selectedContaBancariaLabel"
                        id="selectedContaBancaria"
                        placeholder="Nenhuma Conta Bancária Selecionada..."
                        onClick={this.inputClick}
                        onKeyPress={this.handleKeyPress}
                        disabled={this.props.disabled}
                    />
                    <div className="invalid-feedback">
                        {this.props.errorContaBancariaMessage ? this.props.errorContaBancariaMessage : "Informe a Conta Bancária"}
                    </div>
                </>
            )
        }

        const renderHTMLFormat = () => {
            if(this.props.noHTMLFormat){
                return (
                    <>
                    {renderInput()}
                    </>
                )
            }
            else {
                return (
                    <>
                    <div className="row">
                    <div className="col-md-4">
                    {renderInput()}
                    </div>
                    </div>
                    </>
                )
            }
        }

        return(
            <>
            
                {renderHTMLFormat()}
                
                <ChooseContaBancariaDialog
                    // header="Conta Bancária"
                    header={this.props.label ? this.props.label : 'Conta Bancária'}
                    visible={this.state.chooseContaBancariaDialogVisible || this.props.forceShowDialog}
                    forceUpdate={this.state.forceUpdate}
                    doneForceUpdate={() => this.setState({forceUpdate:false})}
                    loading={this.state.loadingContaBancariaTable}
                    list={this.state.contasBancariasList}
                    hideDialog={this.hideContaBancariaDialog}
                    handleSelectionContaBancariaChange={this.handleChange}
                />


            </>
        )

    }


}

export default InputChooseContaBancaria